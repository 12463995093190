// ReSharper disable InconsistentNaming
export class Translations_sv_FI {
	static DeletingRecordUserParameterWasSuccessful = 'Användarposten \'{0}\' har tagits bort!';
	static SavingEditableDocumentParameterApprovalWasSuccessful = 'Dokumentet \'{0}\' har godkänts!';
	static DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort företagsgruppen \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static CompanyNumber = 'Företagsnummer';
	static SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara dokumentet \'{0}\' eftersom data i dokumentet har ändrats samtidigt av en annan användare!';
	static DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments = 'Vill du verkligen ta bort sökordet "{0}"? Nyckelord som används i {1} filer/dokument.';
	static UserRecords = 'Användarregister';
	static MobileAppInUse = 'Mobilapp används';
	static SendingPushNotificationsToParameter = 'Skickar push-meddelanden till användaren \'{0}\'...';
	static Competency = 'Kompetens';
	static DoYouReallyWantToClearLog = 'Vill du verkligen rensa loggen?';
	static And = 'och';
	static UpdatingGroupCodeWasNotSuccessfulErrorParameter = 'Det gick inte att uppdatera gruppkoden! Fel: \'{0}\'';
	static End = 'Slutet';
	static UserReports = 'Användarregisterrapporter';
	static GroupByFamiliarization = 'Gruppera efter bekantskap';
	static NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded = 'Ny användarpost måste sparas innan bilagor kan läggas till!';
	static DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort brådskande klass \'{0}\', eftersom data i brådskande klass har ändrats samtidigt av en annan användare!';
	static ShowOnMap = 'Visa på kartan';
	static CloseSystem = 'Stäng systemet';
	static SaveReport = 'Spara rapport';
	static AddToFavorites = 'Lägg till i favoriter';
	static ClickToChangeTheLogo = 'Klicka för att ändra logotypen';
	static DoYouReallyWantToRemoveEventPartnerParameter = 'Vill du verkligen ta bort incidentpartner \'{0}\'?';
	static RemoveTaskPriority = 'Ta bort uppgiftsprioritet';
	static ANew = 'Ny';
	static FamiliarizationTrainer = 'Tränare';
	static NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded = 'Ny användarpost måste sparas innan kvalifikationer kan läggas till!';
	static StartDate = 'Synlig från';
	static ResetDocumentVersion = 'Återställ dokumentversion';
	static TogglePreviewPanel = 'Visa/dölj förhandsgranskning';
	static WhistleBlowSeverityLow = 'Låg';
	static RemoveDefaultFurtherEducation = 'Ta bort standard vidareutbildning';
	static RemoveDefaultTaskPriority = 'Ta bort standarduppgiftsprioritet';
	static TasksFound = 'Uppgifter hittades';
	static SavingWhistleBlowMessageWasNotSuccessfulErrorParameter = 'Det gick inte att spara meddelandet! Fel: \'{0}\'';
	static FirstName = 'Förnamn';
	static DocumentApproved = 'Godkänd av';
	static RecordUsersFound = 'Användarposter hittades';
	static UserFirstNameMustBeDefined = 'Användarens förnamn måste definieras!';
	static RecordUserReport = 'Användarpostrapport';
	static AddLogisticVoucherTransportCompany = 'Lägg till transportföretag';
	static RemoveTarget = 'Ta bort mål';
	static Comment = 'Kommentar';
	static EventReport = 'Skaderapport';
	static DeletingRecordBasicEducationsParameterWasSuccessful = 'Grundutbildningen \'{0}\' har tagits bort!';
	static EventApprovalWasSuccessful = 'Incidenten godkänd!';
	static Visibility = 'Synlighet';
	static LockedEventParameterCannotBeDeleted = 'Låst incident \'{0}\' kan inte tas bort!';
	static WhistleBlowStatisticsResolvedRecords = 'Lösta rapporter';
	static AccessRightReadTasks = 'Hantering av uppgifter';
	static Copy = 'Kopiera';
	static UserGroupCodeMustBeDefined = 'Användargruppskod måste definieras!';
	static WhistleBlowUrl = 'Whistle Blow Channel webbadress';
	static Favorites = 'Favoriter';
	static SavingRecordUserQualificationParameterWasSuccessful = 'Kvalifikationen \'{0}\' har sparats!';
	static NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded = 'Ny chatt måste sparas innan deltagare kan läggas till!';
	static AddingRecordUserFamiliarizationsWasSuccessful = 'Bekantskaper har lagts till!';
	static DoYouReallyWantToRemoveSelectedTaskTypes = 'Vill du verkligen ta bort valda uppgiftstyper?';
	static AccountIBANNumber = 'Bankkonto IBAN-nummer';
	static PersonalIdentityCode = 'Personlig identitetskod';
	static FileStorageQuotaGB = 'Fillagringskvot (GB)';
	static Archived = 'Arkiverad';
	static LockedTaskParameterCannotBeDeleted = 'Den låsta uppgiften \'{0}\' kan inte tas bort!';
	static SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara whistle blow-kategorin \'{0}\', eftersom data i whistleblow-kategorin har ändrats samtidigt av en annan användare!';
	static TaskHandlers = 'Handlare';
	static AddEventTypeBsToEvent = 'Lägg till kategori 2 genom att trycka på plusknappen.';
	static RemoveRecordUser = 'Ta bort användarpost';
	static DefaultEventUrgencyClassParameterCannotBeDeleted = 'Standard brådskande klass kan inte tas bort!';
	static Saved = 'Sparad av';
	static SavingUnitPermissionsWasNotSuccessfulConcurrencyError = 'Det gick inte att spara enhetsbehörigheter eftersom behörigheterna har ändrats samtidigt av en annan användare!';
	static SaveReportAs = 'Spara rapport som';
	static OK = 'Ok';
	static Rows = 'rader';
	static AddUserGroupParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till användargruppen \'{0}\'! Fel: \'{1}\'';
	static MovedToTrashBin = 'Flyttade till papperskorgen';
	static AllUsersOfTheUnit = 'Alla användare av enheten';
	static ChangedEventUnit = 'Bytt incidentenhet';
	static SavingApplicationPermissionsWasNotSuccessfulErrorParameter = 'Det gick inte att spara systembehörighetersi! Visar: \'{0}\'';
	static PanelVisible = 'Panel synlig';
	static Effectiveness = 'Effektivitet';
	static AddChildEventTypeB = 'Lägg till underkategori 2';
	static AddNew = 'Lägg till ny';
	static AdditionalFunctions = 'Ytterligare funktioner';
	static DeletingUserGroupsParameterWasSuccessful = 'Användargrupperna \'{0}\' har tagits bort!';
	static MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att flytta mappen \'{0}\' till mappen \'{1}\'! Fel: \'{2}\'';
	static SelectUser = 'Välj Användarkonto';
	static WhistleBlowStatisticsAnonymousRecords = 'Anonyma rapporter';
	static OldPasswordIsIncorrect = 'Det gamla lösenordet är felaktigt!';
	static StandardFiltersAndReports = 'Standardfilter och rapporter';
	static ClassParameterHasNotBeenInitialized = 'Klassen \'{0}\' har inte initierats!';
	static Permissions = 'Behörigheter';
	static NoEventsToShow = 'Inga incidenter att visa!';
	static RemoveRow = 'Ta bort rad';
	static ZipFileParameterNotFound = 'Zip-filen \'{0}\' hittades inte!';
	static EventIsApprovedByUser = 'Användaren har godkänt incidenten';
	static DocumentNameConstainsIllegalCharacters = 'Dokumentnamnet innehåller förbjudna tecken (<, >, :, ", /, \, |, ? eller *) eller slutar med förbjudna tecken (., \' \')!';
	static WhistleBlowStatisticsReceivedRecords = 'Fick rapporter';
	static SavingEventTypeBsWasSuccessful = 'Kategori 2 har sparats framgångsrikt!';
	static AddImageFromClipboard = 'Lägg till bild från urklipp';
	static DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort incidentpartnern \'{0}\' eftersom data i incidentpartnern har ändrats samtidigt av en annan användare!';
	static EditEventType = 'Redigera kategori 1';
	static DeletingEventTypeParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort kategori 1 \'{0}\'! Fel: \'{1}\'';
	static AddFamiliarizations = 'Lägg till bekantskaper';
	static SelectFurtherEducation = 'Välj Vidareutbildning';
	static DeletingWhistleBlowCategoriesParameterWasSuccessful = 'Visselslagskategorierna \'{0}\' har tagits bort!';
	static MessageVisibilityPeriodNotActive = 'Meddelandets synlighetsperiod är inte aktiv!';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful = 'Mappen \'{0}\' har kopierats till mappen \'{1}\'!';
	static EditPermission = 'Redigera behörighet';
	static RemovingWhistleBlowHandlersWasSuccessful = 'Hanterare har tagits bort!';
	static AddPrint = 'Lägg till utskrift';
	static RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError = 'Det gick inte att återställa uppgiften \'{0}\' från papperskorgen, eftersom data i uppgiften har ändrats samtidigt av en annan användare!';
	static PaymentSettings = 'Betalningsinställningar';
	static LockedTaskParameterCannotBeMovedToTrashBin = 'Den låsta uppgiften \'{0}\' kan inte flyttas till papperskorgen!';
	static ChooseHowYouWantToReport = 'Välj hur du vill rapportera';
	static CompanyInstantMessageChannels = 'Företagschattar';
	static DeletingUsersParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort användarkonton "{0}", eftersom data har ändrats samtidigt av en annan användare!';
	static AddEventType = 'Lägg till kategori 1';
	static Day = 'Dag';
	static DoYouReallyWantToRemoveDocumentParameter = 'Vill du verkligen ta bort filen/dokumentet "{0}"?';
	static EditFileFilter = 'Redigera filfilter';
	static DeletingFileParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort filen \'{0}\' i filsystemet! Fel: \'{1}\'';
	static UserEndDate = 'Giltighet löper ut';
	static SavingOneTimePasswordParameterWasSuccessful = 'Engångslösenordet har sparats!';
	static NewVersionAvailable = 'Ny applikationsversion tillgänglig! Applikationen kan inte användas innan den har uppdaterats.';
	static DeletingUserGroupParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort användargruppen \'{0}\' eftersom data i användargruppen har ändrats samtidigt av en annan användare!';
	static NotRepeated = 'Inte upprepad';
	static ReplyToInstantMessage = 'Svara på meddelande';
	static IgnoreFilePatterns = 'Ignorera filmönster';
	static CopyPermissionToAllSameLevelFolders = 'Kopiera behörighet till alla mappar på samma nivå';
	static SelectState = 'Välj stat';
	static UserFilterActive = 'Aktiva användarkonton';
	static ProjectNumber = 'Projektnummer';
	static EditableDocumentCodeMustBeDefined = 'Dokumentnamn måste definieras!';
	static DoYouReallyWantToRemoveRecordUserEmploymentParameter = 'Vill du verkligen ta bort anställning \'{0}\'?';
	static UserRecordStatisticsExpiringInSixMonthsFamiliarizations = 'Bekantskaper som löper ut om sex månader';
	static PublishVoucherToFileSystem = 'Publicera voucher till filsystem';
	static WhistleBlowStatisticsNewRecords = 'Nya rapporter';
	static NewInstantMessageChannelGroupInternal = 'Ny grupp (företagsintern)';
	static ArchiveExpiredFamiliarizations = 'Arkivera utgångna bekantskaper';
	static AuthorizationLevel = 'Behörighetsnivå';
	static Locked = 'Låst';
	static MarkMessageAsRead = 'Markera meddelandet som läst';
	static AuthorizationLevelAdmin = 'Administratörer';
	static TaskTypesMustBeDefinedBeforeAddingTasks = 'Uppgiftstyper måste definieras innan du lägger till uppgifter!';
	static SavingEventTypeBParameterWasSuccessful = 'Kategori 2 \'{0}\' har sparats!';
	static InstantMessageAttachmentFolderIsNotSpecified = 'Programbilaga för snabbmeddelanden är inte specificerad!';
	static DirectoryItemFolderParameterNotFound = 'Mappen \'{0}\' hittades inte!';
	static TasksExcelByTaskRows = 'Uppgifter - Excel - Filtrerat efter uppgiftsrader';
	static UserRecordStatisticsExpiringInSixMonthsQualifications = 'Kvalifikationer som löper ut om sex månader';
	static WhistleBlowStateInProgress = 'Pågående';
	static LockingTaskDataRowWasNotSuccessful = 'Det gick inte att låsa uppgiftsraden!';
	static ExpiredPlural = 'Utgånget';
	static ACopy = 'Kopiera';
	static EventPartnerIsAlreadyInDefined = 'Incidentpartner är redan definierad!';
	static PublishingTaskTypeParameterWasSuccessful = 'Uppgiftstypen \'{0}\' har publicerats!';
	static WhistleBlowInformantTitle = 'Vad handlar din rapport om?';
	static ChartType = 'Diagramtyp';
	static AddCommentsToEvent = 'Lägg till kommentar genom att trycka på plusknappen.';
	static DeletingCompanyParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort företaget \'{0}\'! Fel: \'{1}\'';
	static WhistleBlowCategoryParameterNotFound = 'Det gick inte att hitta kategorin "{0}" för visselblåsning!';
	static UnlockingEventParameterWasNotSuccessful = 'Det gick inte att låsa upp incidenten \'{0}\'!';
	static AddEventHandlersToEvent = 'Lägg till hanterare genom att trycka på plusknappen.';
	static File = 'Fil';
	static EditEventTypeCs = 'Redigera kategori 3';
	static SavingEventTypeCsWasSuccessful = 'Kategori 3 har sparats framgångsrikt!';
	static WhistleBlowRecords = 'Uppgifter';
	static SavingInstantMessageChannelUserUserWasSuccessful = 'Deltagaren har sparats!';
	static DoCleanLog = 'Rensa logg';
	static KeywordsOrCondition = 'Något av de valda sökorden';
	static FilterCondition = 'Filtertillstånd';
	static RemoveDefaultHandler = 'Ta bort standardhanteraren';
	static SavingKeywordParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara sökordet "{0}", eftersom data i sökordet har ändrats samtidigt av en annan användare!';
	static DeletingEventTypeCParameterWasSuccessful = 'Kategori 3 \'{0}\' har tagits bort!';
	static FolderParameterAlreadyExists = 'Mappen \'{0}\' finns redan!';
	static EventUrgencyClasses = 'Brådskande klasser';
	static EventTypeBCodeIsAlreadyInUse = 'Kategori 2-namnet används redan!';
	static WhistleBlowDashboard = 'Whistleblower instrumentbräda';
	static WhistleBlowAnonymityNonAnonymous = 'Icke anonym';
	static InheritedUserRights = 'Ärvda behörigheter';
	static Entrepreneur = 'Entreprenör';
	static DeletingWhistleBlowChannelAdminsParameterWasSuccessful = 'Administratörer "{0}" har tagits bort!';
	static SavingFilterWasSuccessful = 'Filtret har sparats!';
	static UserIsCompanyAdminClaimNotFound = 'Användaranspråket "userIsCompanyAdmin" hittades inte!';
	static ThisMessageWasDeleted = 'Detta meddelande raderades';
	static DeletingAttachmentsWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort bilagor! Fel: \'{0}\'';
	static YouHaveNotSavedChangesDoYouWantToSaveChanges = 'Du har inte sparat ändringar som du har gjort. Vill du spara ändringar?';
	static SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError = 'Det gick inte att spara deltagare, eftersom data i deltagaren har ändrats samtidigt av en annan användare!';
	static DependencyResolvingFailedForInterfaceParameter = 'Det gick inte att hitta implementeringen för gränssnittet \'{0}\'!';
	static WhistleBlowInformantMessageConfirmation = 'Vill du skicka ett nytt meddelande till uppgiftslämnaren?';
	static WriteBelowFieldWordParameterToConfirmTheOperation = 'Skriv ordet \'{0}\' i fältet nedan för att bekräfta åtgärden';
	static FrontPage = 'instrumentbräda';
	static UserHasUsedMobile = 'Användarkontot har varit aktivt i mobilapplikationen under de senaste 30 dagarna.';
	static Administrator = 'Administratör';
	static UserFilterInActive = 'Används inte';
	static MessageParameterNotFound = 'Meddelandet \'{0}\' hittades inte!';
	static EditEventTypes = 'Redigera kategori 1';
	static IdCard = 'ID-kort';
	static Users = 'Användarkonton';
	static RenewPersonalizationWithUser = 'Ersätt anpassning med underanvändare';
	static AccessRightWriteEvents = 'Skapa och hantera incidenter';
	static AdditionalPermissionAMessage = 'Tillstånd att läsa meddelanden publicerade av partners';
	static SavingEventPartnerParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara incidentpartnern \'{0}\' eftersom data i incidentpartnern har ändrats samtidigt av en annan användare!';
	static DocumentModified = 'Senast ändrad av';
	static DocumentNameParameterIsIllegal = 'Dokumentnamn \'{0}\' är inte tillåtet (reserverat namn)!';
	static DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort vidareutbildningar \'{0}\', eftersom data i vidareutbildningar har ändrats samtidigt av en annan användare!';
	static RestoringInstantMessageWasNotSuccessfulErrorParameter = 'Det gick inte att återställa snabbmeddelanden! Fel: \'{0}\'';
	static EditableDocumentUnpublishConfirmation = 'Vill du ta bort den publicerade filen?';
	static SelectCompanyGroup = 'Välj Företagsgrupp';
	static ActiveState = 'stat';
	static TaskTypeCodeMustBeDefined = 'Uppgiftstypskod måste definieras!';
	static DeletingInstantMessageUserParameterWasSuccessful = 'Deltagare \'{0}\' har tagits bort!';
	static BasicInformation = 'Allmän';
	static WorkShift = 'Arbetsskift';
	static InvalidValueInArgumentParameter = 'Ogiltigt värde i argumentet \'{0}\'!';
	static NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded = 'Ny användarpost måste sparas innan anställningar kan läggas till!';
	static WhistleBlowSourceOral = 'Oral';
	static AddingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till uppgiftstypen \'{0}\'! Fel: \'{1}\'';
	static WageGroup = 'Lönegrupp';
	static Valid = 'Giltig';
	static Line = 'Linje';
	static RecordUserLogEntryTypeSave = 'Modifiering';
	static ChangeSystemLanguageToEnglish = 'Ändra språk till engelska';
	static WhistleBlowInformantHint = 'Tänk på att beskriva ditt ärende så noggrant som möjligt för att underlätta vår utredning.';
	static CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits = 'Kopiera standardhanterare och anmälda personer till alla enheter på samma nivå';
	static SavingCompanyParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara företaget \'{0}\'! Fel: \'{1}\'';
	static Intern = 'Internera';
	static UserGroupAll = '\'Alla användare\'';
	static SavingEventTypeParameterWasSuccessful = 'Kategori 1 \'{0}\' har sparats!';
	static SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara bekantskapen \'{0}\'! Fel: \'{1}\'';
	static DoYouReallyWantToRestoreThisInstantMessage = 'Vill du verkligen återställa det här snabbmeddelandet?';
	static AddPanel = 'Lägg till panel';
	static NotInUse = 'Används inte';
	static CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att kontrollera existensen av mappen \'{0}\' i filsystemet! Fel: \'{1}\'';
	static DoYouReallyWantToUnlockTask = 'Vill du verkligen låsa upp uppgiften \'{0}\'?';
	static SavingApplicationPermissionsWasSuccessful = 'Systembehörigheterna har sparats!';
	static Competencies = 'Kompetenser';
	static EventUrgencyClass = 'Brådskande klass';
	static SavingEditableDocumentParameterWasSuccessful = 'Dokumentet \'{0}\' har sparats!';
	static UserRecordStatisticsSavedCards = 'Sparade kort';
	static AllDocumentTypes = 'Visa alla dokumenttyper';
	static SavePrintAs = 'Spara utskrift som';
	static EmailingMessageWasNotSuccessfulErrorParameter = 'Det gick inte att skicka meddelande via e-post! Fel: \'{0}\'';
	static DoYouReallyWantToRemoveTaskParameterPermanenty = 'Vill du verkligen ta bort uppgiften "{0}" permanent? Den borttagna uppgiften kan inte återställas?';
	static NewInstantMessageChannelGroup = 'Ny grupp';
	static FileRemovalWasSuccessful = 'Filen har tagits bort!';
	static SavingRecordUserParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara användarposten "{0}", eftersom användarpostens data har ändrats samtidigt av en annan användare!';
	static FamiliarizationStartDateDateEnd = 'Bekantskap Startdatum - Slut';
	static InUse = 'I användning';
	static MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter = 'Det gick inte att flytta uppgiften \'{0}\' till papperskorgen! Fel: \'{1}\'';
	static Number = 'siffra';
	static DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort anställningar "{0}", eftersom data i anställningar har ändrats samtidigt av en annan användare!';
	static RecordUserLogEntryTypeReport = 'Rapportering';
	static Panels = 'Paneler';
	static OthersLastModifications = 'Senaste ändringar av företag';
	static DoYouReallyWantToRemoveSharedDocumentTemplateParameter = 'Vill du verkligen ta bort den delade dokumentmall \'{0}\'?';
	static EditDefaultHandlers = 'Redigera standardhanterare';
	static AddQualifications = 'Lägg till kvalifikationer';
	static DeletingFileFilterParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort filfiltret \'{0}\'! Fel: \'{1}\'';
	static Add = 'Lägg till';
	static EventUrgencyClassParameterNotFound = 'Brådskande klass \'{0}\' hittades inte!';
	static EditEventTypeC = 'Redigera kategori 3';
	static AddLogisticVoucherCustomer = 'Lägg till kund';
	static SelectInstantMessageChannelType = 'Välj Chattyp';
	static DoYouReallyWantToRemoveEventTypeBParameter = 'Vill du verkligen ta bort kategori 2 \'{0}\'?';
	static CompanyName = 'Företagsnamn';
	static RecordUserLogEntryTypeOpen = 'Läsning';
	static RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort användargruppslänkar från externa system! Fel: \'{0}\'';
	static AddPartnersByClickingPlusButton = 'Lägg till partners genom att klicka på plusknappen.';
	static SavingChartWasNotSuccessfulConcurrencyError = 'Det gick inte att spara diagrammet eftersom data i diagrammet har ändrats samtidigt av en annan användare!';
	static DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort uppgiftsprioritet \'{0}\'! Fel: \'{1}\'';
	static ContentFiltering = 'Innehållsfiltrering';
	static ApproveEventResolution = 'Godkänn incidentlösning';
	static LockingEventParameterWasNotSuccessful = 'Det gick inte att låsa incidenten \'{0}\'!';
	static Report = 'Rapportera';
	static SourceFolderAndTargetFolderAreSame = 'Källmapp och målmapp är lika!';
	static SavingCompanyParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara företaget \'{0}\' eftersom data i företaget har ändrats samtidigt av en annan användare!';
	static AddRecordUserWithoutUserAccount = 'Lägg till användarpost utan användarkonto';
	static WhistleBlowCategoryCodeIsAlreadyInUse = 'Namnet på visselblåsningskategorin används redan!';
	static SavingChartWasNotSuccessfulErrorParameter = 'Det gick inte att spara diagrammet! Fel: \'{0}\'';
	static SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara standardincidenthanteraren \'{0}\', eftersom data i standardincidenthanteraren har ändrats samtidigt av en annan användare!';
	static LockedTaskParameterCannotBeRestored = 'Den låsta uppgiften \'{0}\' kan inte återställas!';
	static UserFilterExpired = 'Utgånget';
	static DoYouReallyWantToRemoveSelectedRecordBasicEducations = 'Vill du verkligen ta bort utvalda grundutbildningar?';
	static AddCompanyGroups = 'Lägg till företagsgrupper';
	static DoYouReallyWantToRemoveAttachmentParameter = 'Vill du verkligen ta bort bilagan \'{0}\'?';
	static SavingOneTimePasswordWasNotSuccessfulConcurrencyError = 'Det gick inte att spara engångslösenordet eftersom data i engångslösenordet har ändrats samtidigt av en annan användare!';
	static SaveEvent = 'Spara incidenten';
	static Select = 'Välj';
	static WhistleBlowNonAnonymousReportInfo = 'Ditt namn kommer endast att vara känt för dem som hanterar din anmälan, och det kommer att förbli anonymt för alla andra.';
	static AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till bekantskaper! Fel: \'{0}\'';
	static EditLogisticVoucherPaymentSetting = 'Redigera betalningsinställning';
	static ReadImp = 'Läsa';
	static AddKeyword = 'Lägg till nyckelord';
	static DocumentRootPath = 'Dokumentrotsökväg';
	static DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort bilagorna \'{0}\', eftersom data har ändrats samtidigt av en annan användare!';
	static UserRecordStatisticsExpiredQualifications = 'Utgångna kvalifikationer';
	static PropertyTransferred = 'Överförd';
	static Images = 'Bilder';
	static CompanyCodeIsAlreadyInUse = 'Koden för företaget är redan i bruk!';
	static ToDo = 'Saker att göra';
	static DeletingRecordUserTransferredPropertyParameterWasSuccessful = 'Den överförda egenskapen \'{0}\' har tagits bort!';
	static CreatingNotifications = 'Skapar aviseringar...';
	static RemoveRemainder = 'Ta bort resten';
	static Events = 'Incidenter';
	static EditableDocumentApproveConfirmation = 'Vill du godkänna dokumentet?';
	static DocumentTemplates = 'Dokumentmallar';
	static SavingWhistleBlowWasNotSuccessfulErrorParameter = 'Misslyckades med att spara whistle blow rapport! Fel: \'{0}\'';
	static Theme = 'Tema';
	static AddUsers = 'Lägg till användarkonton';
	static SaveUserFurtherEducation = 'Spara vidareutbildning';
	static DeletingMessageParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort meddelandet \'{0}\'! Fel: \'{1}\'';
	static Open = 'Öppen';
	static DeletingTaskPriorityParameterWasSuccessful = 'Uppgiftsprioritet \'{0}\' har tagits bort!';
	static DoYouReallyWantToRemoveSelectedEventUrgencyClasses = 'Vill du verkligen ta bort utvalda brådskande klasser?';
	static SavingImagesNotSuccessfulErrorParameter = 'Det gick inte att spara bilder! Fel: \'{0}\'';
	static City = 'Stad';
	static SavingUserParametersWasSuccessful = 'Användaralternativ har sparats!';
	static ShowInheritedPermissions = 'Visa ärvda behörigheter';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att kopiera mappen \'{0}\' till mappen \'{1}\'! Fel: \'{2}\'';
	static WhistleBlowNewReport = 'Ny rapport';
	static UnitHandlingIncomingEvents = 'Enhet som hanterar incidenter som skickas av partnern';
	static ExpiredFurtherEducations = 'Utgångna vidareutbildningar';
	static SaveAndSend = 'Spara och skicka';
	static DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort uppgiftstyperna "{0}", eftersom data i uppgiftstyperna har ändrats samtidigt av en annan användare!';
	static FurtherEducationsMustBeDefinedBeforeAddingRecordUsers = 'Vidareutbildningar måste definieras innan användarposter läggs till!';
	static DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort den överförda egenskapen \'{0}\'! Fel: \'{1}\'';
	static AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till administratören \'{0}\'! Fel: \'{1}\'';
	static DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort uppgiftstillstånd \'{0}\', eftersom data i uppgiftstillstånd har ändrats samtidigt av en annan användare!';
	static Familiarization = 'Bekantskap';
	static Draft = 'Förslag';
	static JobTypeEventNotification = 'Incidentmeddelande';
	static DateMustBeDefined = 'Datum måste anges!';
	static SetPermissions = 'Ange behörigheter';
	static WhistleBlowStateReceived = 'Mottagen';
	static GroupByPerson = 'Gruppera efter person';
	static EventTypeCodeIsAlreadyInUse = 'Kategori 1-namn används redan!';
	static DoYouReallyWantToRemoveFolderParameter = 'Vill du verkligen ta bort mappen \'{0}\'?';
	static AuthorizationLevelSuperUser = 'Superanvändare';
	static DoYouReallyWantToRemovePartnersParameter = 'Vill du verkligen ta bort partner "{0}"?';
	static Print = 'Skriva ut';
	static DoYouReallyWantToRemoveRemainderParameter = 'Vill du verkligen ta bort resten "{0}"?';
	static FamiliarizationStartDate = 'Startdatum för bekantskap';
	static SearchCurrentTopics = 'Sök efter aktuella ämnen';
	static DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort bekantskapen \'{0}\'! Fel: \'{1}\'';
	static RemoveFolder = 'Ta bort mapp';
	static ComparingFileSystemToDatabase = 'Jämför filsystem med databas...';
	static SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara grundläggande utbildning \'{0}\'! Fel: \'{1}\'';
	static MissingPlural = 'Saknas';
	static DeletingOneTimePasswordWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort engångslösenordet eftersom data i engångslösenordet har ändrats samtidigt av en annan användare!';
	static NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded = 'Ny anställning måste sparas innan bilagor kan läggas till!';
	static UserRecordStatisticsExpiredFurtherEducations = 'Utgångna vidareutbildningar';
	static RecordBasicEducationParameterNotFound = 'Grundläggande utbildning \'{0}\' hittades inte!';
	static AdditionalPermissionAEvents = 'Agerar som hanterare';
	static EInvoiceOperator = 'E-fakturaoperatör';
	static AccountNumberTkbOutDebit = 'Leverantörsskuldkonto';
	static Settings = 'inställningar';
	static FileFilterCodeMustBeDefined = 'Filfilterkod måste definieras!';
	static DoYouReallyWantToRemoveChartPanelParameter = 'Vill du verkligen ta bort diagrammet \'{0}\'?';
	static ClosedEvent = 'Stängd Incidenten';
	static FamiliarizationTime = 'Bekantskapstid';
	static AddPartnerParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att lägga till partner \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static EmailSendDate = 'E-post skickat datum';
	static Employment = 'Sysselsättning';
	static DeletingKeywordParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort sökordet \'{0}\'! Fel: \'{1}\'';
	static PageFooterText = 'Systemsidans sidfotstext';
	static InstantMessageChannelUsers = 'Deltagare';
	static DeletingPartnersParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort partner \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static TaskPriorities = 'Uppgiftsprioriteringar';
	static DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort överförda egenskaper "{0}", eftersom data i överförda egenskaper har ändrats samtidigt av en annan användare!';
	static EditMode = 'Redigera';
	static NotificationsSentSuccessfully = 'Aviseringar har skickats!';
	static DoYouReallyWantToRemoveTaskDataRow = 'Vill du verkligen ta bort rad?';
	static CompanyGroupCodeMustBeDefined = 'Koden för företagsgruppen måste definieras!';
	static DoYouReallyWantToRemoveTaskTypeParameter = 'Vill du verkligen ta bort uppgiftstypen \'{0}\'?';
	static FileParameterDownloaded = 'Filen \'{0}\' har laddats ned.';
	static AddPartnerCompanies = 'Lägg till partners som innehåll kan delas med';
	static RemoveRecordUserAndUserSetExpired = 'Ta bort användarpost och ange användarkonto har löpt ut';
	static SavingUnitPermissionsWasSuccessful = 'Enhetsbehörigheterna har sparats!';
	static SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara grundutbildningen \'{0}\', eftersom data i grundutbildningen har ändrats samtidigt av en annan användare!';
	static Companies = 'Företag';
	static NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded = 'Ny användarpost måste sparas innan bekantskaper kan läggas till!';
	static DoYouReallyWantToRemoveEventUrgencyClassParameter = 'Vill du verkligen ta bort brådskande klass \'{0}\'?';
	static SavingWhistleBlowWasNotSuccessfulConcurrencyError = 'Det gick inte att spara whistle blow-rapporten eftersom data i rapporten har ändrats samtidigt av en annan användare!';
	static ThisPartOfApplicationIsNotInUse = 'Denna del av applikationen används inte!';
	static TimeSpanHalfYear = 'Halvår';
	static EnableExternalIntraSystemConfirmation = 'Vill du verkligen aktivera företag i systemet? Företagsdata kommer att sparas i systemet när företaget sparas.';
	static AccessRightReadEvents = 'Skapar incidenter';
	static Priority = 'Prioritet';
	static DocumentCreated = 'Skapad av';
	static TaskStatesMustBeDefinedBeforeAddingTasks = 'Uppgiftstillstånd måste definieras innan uppgifter läggs till!';
	static PrivacyPolicy = 'Integritetspolicy';
	static Keywords = 'Nyckelord';
	static DoYouReallyWantToRemoveKeywordParameter = 'Vill du verkligen ta bort sökordet "{0}"?';
	static DefaultLanguageHasBeenChanged = 'Språket har ändrats!';
	static DeletingEventParameterWasSuccessful = 'Incidenten \'{0}\' har tagits bort!';
	static DefaultSettings = 'Standardinställningar';
	static CompanyGroupModuleProperties = 'Företagsgruppers fastigheter';
	static WhistleBlowNotFound = 'Visslingsrapport hittades inte!';
	static DoYouReallyWantToRemoveMessageTypeParameter = 'Vill du verkligen ta bort meddelandetypen \'{0}\'?';
	static StateOpen = 'Öppen';
	static CompanyGroupParameterNotFound = 'Företagsgruppen \'{0}\' hittades inte!';
	static DeletingTaskTypesParameterWasSuccessful = 'Uppgiftstyperna \'{0}\' har tagits bort!';
	static JobTitle = 'Jobbtitel';
	static RemoveFile = 'Ta bort fil';
	static SaveMessage = 'Spara meddelande';
	static AdministratorPasswordMustBeDefined = 'Administratörens lösenord måste definieras!';
	static ExternalSystemCodeClaimNotFound = 'Användarens anspråk \'externalSystemCode\' hittades inte!';
	static DoYouReallyWantToRemoveSelectedHandlers = 'Vill du verkligen ta bort valda hanterare?';
	static DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort mappen \'{0}\' eftersom data i mappen har ändrats samtidigt av en annan användare!';
	static LogCleaning = 'Rensa logg';
	static EventsFound = 'Incidenter hittades';
	static Change = 'Förändra';
	static ExitingInstantMessageChannelWasNotSuccessfulErrorParameter = 'Det gick inte att avsluta chatten! Fel: \'{0}\'';
	static DoYouReallyWantToRemoveEditableDocumentParameter = 'Vill du verkligen ta bort dokumentet \'{0}\'? Alla filer som är relaterade till dokumentet kommer att tas bort.';
	static Archive = 'Arkivera';
	static Empty = 'Tömma';
	static Resources = 'Resurser';
	static AccessRightWriteWhistleBlow = 'Fullständig rapporthantering';
	static LockedDocumentParameterCannotBeMoved = 'Det låsta dokumentet \'{0}\' kan inte flyttas!';
	static SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara dokumentmallen \'{0}\'! Fel: \'{1}\'';
	static SavingReadReceiptWasSuccessful = 'Läskvitto har sparats!';
	static NoTasksToShow = 'Inga uppgifter att visa!';
	static AllInstantMessageUsersHaveReadMessage = 'Alla mottagare har läst ditt meddelande';
	static DeletingTaskStatesParameterWasSuccessful = 'Uppgiftstillstånden \'{0}\' har tagits bort!';
	static Cancel = 'Annullera';
	static AdditionalPermissionBEvents = 'Ta bort';
	static ReportDialogInfoText = 'Rapporten kommer att baseras på sökta/filtrerade rader.';
	static Continue = 'Fortsätta';
	static TaskDataRowParameterNotFound = 'Uppgiftsraden \'{0}\' hittades inte!';
	static RemovingEventHandlerWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort hanterare! Fel: \'{0}\'';
	static RemoveDefaultBasicEducation = 'Ta bort Standard Basic Education';
	static VoucherInOutReportSavePath = 'Verifikationssammanfattning Skriv ut Spara sökväg';
	static InstantMessageChannelUserParameterNotFound = 'Chattpartiet \'{0}\' hittades inte!';
	static EditMessageType = 'Redigera meddelandetyp';
	static SearchProperties = 'avancerad sökning';
	static AllUnits = 'Alla enheter';
	static EventTypesGeneric = 'Typer';
	static SavingTaskStateParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara uppgiftstillståndet \'{0}\', eftersom data i uppgiftstillståndet har ändrats samtidigt av en annan användare!';
	static ChangeSystemLanguageToFinnish = 'Byt språk till finska';
	static SavingRecordEmploymentParameterWasSuccessful = 'Anställningen \'{0}\' har sparats!';
	static Folders = 'Mappar';
	static RequireReadReceipt = 'Kräver läskvitto';
	static Required = 'Nödvändig';
	static UnreadMessagesParameter = 'Olästa meddelanden: {0}';
	static WhistleBlowArchivingTypeChangeState = 'Ändra tillstånd för rapport till "Arkiverad"';
	static DoYouReallyWantToRemoveCompanyGroupParameter = 'Vill du verkligen ta bort företagsgruppen \'{0}\'?';
	static AddToHomeScreen = 'Lägg till på hemskärmen';
	static ForwardMessage = 'Fram';
	static Files = 'Filer';
	static WhistleBlowCreateTime = 'Rapportera skickad tid';
	static DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort kategori 3 \'{0}\', eftersom data i kategori 3 har ändrats samtidigt av en annan användare!';
	static ModifiedActions = 'Ändrade åtgärder';
	static ChartProperties = 'Diagramegenskaper';
	static EditableDocumentPublishConfirmation = 'Vill du publicera dokument till fil?';
	static Password = 'Lösenord';
	static RemoveLogisticTransportCompany = 'Ta bort Transport Company';
	static DoYouReallyWantToPublishTaskTypeConfirmation = 'Vill du verkligen publicera uppgiftstypen \'{0}\' till alla företag som inte har det?';
	static SavingTaskDataRowWasSuccessful = 'Uppgiftsraden har sparats!';
	static AddCompanyToCompanyGroup = 'Lägg till företag i företagsgruppen';
	static AddingAddRecordUserFurtherEducationsWasSuccessful = 'Ytterligare utbildningar har lagts till framgångsrikt!';
	static EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Brådskande klass \'{0}\' används i {1} incidenter och kan inte tas bort!';
	static RemoveEmployment = 'Ta bort anställning';
	static UserCodeClaimNotFound = 'Användarens anspråkskod hittades inte!';
	static DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort ytterligare utbildningar \'{0}\'! Fel: \'{1}\'';
	static TimeSpanYear = 'År';
	static EditableDocumentCodeIsAlreadyInUse = 'Dokumentnamnet används redan!';
	static PrintTask = 'Utskriftsuppgift';
	static NewComment = 'Ny kommentar';
	static Log = 'Loggar';
	static WaitingToBeApproved = 'Väntar på att bli godkänd';
	static Properties = 'Egenskaper';
	static PasswordMustContainLowercaseChar = 'Lösenordet måste innehålla gemener!';
	static PublishDate = 'Utgivningsdatum';
	static CurrentTopicsAbbr = 'Aktuell';
	static DeletingCompanyGroupParameterWasSuccessful = 'Företagsgruppen \'{0}\' har tagits bort!';
	static ShowDraftVersion = 'Visa utkastinnehåll';
	static TaskPriorityCodeIsAlreadyInUse = 'Namnet på uppgiftsprioriteten används redan!';
	static WhistleBlowCategories = 'Whistle Blow kategorier';
	static DetectingLocation = 'Upptäcker plats...';
	static NotificationsQueuedSuccessfully = 'Avisering planerad att skickas!';
	static DeletingKeywordParameterWasSuccessful = 'Sökordet "{0}" har tagits bort!';
	static Browse = 'Bläddra';
	static ThisMessageWasDeletedByInstantMessageChannelAdministrator = 'Det här meddelandet raderades av administratören, \'{0}\'';
	static UserClaimsNotFound = 'Användaranspråk hittades inte!';
	static Relation = 'Relation';
	static NoName = 'Anonym';
	static AddEmployment = 'Lägg till anställning';
	static RemoveDefaultEventHandler = 'Ta bort standardincidenthanteraren';
	static CopyOrMoveFolderParameter = 'Kopiera eller flytta mapp \'{0}\'';
	static DefaultStyle = 'Standard';
	static UnitNameMustBeDefined = 'Enhetens namn måste definieras!';
	static DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort kategorierna "{0}"! Fel: \'{1}\'';
	static TransportCompanies = 'Transportföretag';
	static SendinEmailNotificationsWasNotSuccessfulErrorParameter = 'Det gick inte att skicka aviseringar till via e-post! Fel: \'{0}\'';
	static Unkown = 'Okänt';
	static WhistleBlowInformantDescription = 'Beskriv vad som hänt';
	static InternalMessages = 'Interna meddelanden';
	static SavingCompanyParameterWasSuccessful = 'Företaget \'{0}\' har sparats!';
	static ProjectName = 'Projektnamn';
	static InstantMessageChannelInternal = 'Företag internt';
	static Modified = 'Senast ändrad av';
	static DocumentIsEmpty = 'Dokumentet är tomt!';
	static WhistleBlowInformantButtonHint = 'Inkludera inte känsliga personuppgifter om personer du nämner i ditt meddelande, ifall de inte är nödvändiga för att beskriva ditt ärende.';
	static SavingEventTypesWasNotSuccessfulErrorParameter = 'Det gick inte att spara kategori 1! Fel: \'{0}\'';
	static DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter = 'Brådskande klass \'{0}\' har tagits bort!';
	static AddMessageType = 'Lägg till meddelandetyp';
	static SavingJobParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara jobbet \'{0}\'! Fel: \'{1}\'';
	static RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Bekantskap \'{0}\' används i {1} användarposter och kan inte tas bort!';
	static DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort överförda egenskaper \'{0}\'! Fel: \'{1}\'';
	static ReportsModified = 'Favoritrapporter';
	static DoYouReallyWantToRemoveSelectedRecordFamiliarizations = 'Vill du verkligen ta bort valda ytterligare bekantskaper?';
	static InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation = 'Vill du verkligen ta bort dig själv från administratörer? Endast administratörer kan hantera chatten.';
	static UserRecordStatisticsExpiringInSixMonthsFurtherEducations = 'Vidareutbildningar som löper ut om sex månader';
	static DefaultTaskPriorities = 'Standarduppgiftsprioriteter';
	static NewMessage = 'Nytt meddelande';
	static AddLogisticVoucherCompanyPaymentSetting = 'Lägg till betalningsinställning';
	static RemoveWhistleBlowCategory = 'Ta bort kategorin Whistle Blow';
	static DoYouReallyWantToRemoveUnitParameter = 'Vill du verkligen ta bort enheten \'{0}\'?';
	static SelectSubject = 'Välj Ämne';
	static SelectRemovingMethod = 'Välj ta bort metod';
	static EditPayerSettings = 'Redigera betalarinställningar';
	static InvalidValueParameter = 'Ogiltigt värde \'{0}\'!';
	static NormalSize = 'Normal storlek';
	static DeletingTaskDataRowWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort uppgiftsraden eftersom data i uppgiftsraden har ändrats samtidigt av en annan användare!';
	static DoYouReallyWantToRemoveFileFilterParameter = 'Vill du verkligen ta bort filfiltret \'{0}\'?';
	static PermissionsForSection = 'Sektionsbehörigheter';
	static TargetsOtherCompanies = 'Synlighet - Andra företag';
	static UserGroupModuleProperties = 'Egenskaper för användargrupper';
	static WhistleBlowEventTypeModified = 'Ändrad';
	static SomeEventHandlersHaveReadMessage = 'Vissa incidenthanterare har läst din kommentar';
	static Scope = 'Omfattning';
	static UserRecordStatisticsActiveCards = 'Aktiva kort';
	static ChangedObserverAction = 'Ändrad händelseobservatörsåtgärd';
	static SavingUserGroupParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara användargruppen \'{0}\'! Fel: \'{1}\'';
	static UnitMustBeSelected = 'Enhet måste väljas!';
	static CompanyCodeMustBeDefined = 'Koden för företaget måste definieras!';
	static SavingEventParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara incidenten \'{0}\'! Fel: \'{1}\'';
	static WhistleBlowChannel = 'Whistle Blow Channel';
	static WhistleBlowWasNotFoundExplanation = 'Kontrollera lösenordet för whistleblow-rapporten.';
	static DocumentTemplateParameterNotFound = 'Dokumentmallen \'{0}\' hittades inte!';
	static Sorting = 'Sortering';
	static MessageTypeCodeIsAlreadyInUse = 'Meddelandetypskoden används redan!';
	static SelectDocumentTemplate = 'Välj Dokumentmall';
	static SavingTaskDataRowWasNotSuccessfulConcurrencyError = 'Det gick inte att spara uppgiftsraden eftersom uppgiftsraden har ändrats samtidigt av en annan användare!';
	static WhistleBlowInternalMessageConfirmation = 'Vill du spara ett nytt internt meddelande?';
	static DeletingCompanyGroupsParameterWasSuccessful = 'Företagsgrupperna \'{0}\' har tagits bort!';
	static DeletingTaskDataRowWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort uppgiftsraden! Fel: \'{0}\'';
	static FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations = 'Företagsbekännelser måste definieras innan bekantskaper med användarposter läggs till!';
	static DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort den överförda egenskapen \'{0}\', eftersom data i den överförda egenskapen har ändrats samtidigt av en annan användare!';
	static Unlock = 'Låsa upp';
	static Tasks = 'Uppgifter';
	static RestoreTaskFromTrashBin = 'Återställ uppgift från papperskorgen';
	static SavingWhistleBlowMessageWasSuccessful = 'Meddelandet har sparats!';
	static Test = 'Testa';
	static TaskPriorityCodeMustBeDefined = 'Uppgiftsprioritetsnamn måste definieras!';
	static DeletingOneTimePasswordWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort engångslösenordet! Fel: \'{0}\'';
	static DoYouReallyWantToRemoveTaskStateParameter = 'Vill du verkligen ta bort uppgiftstillståndet \'{0}\'?';
	static DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort visselstötsrapporten \'{0}\'! Fel: \'{1}\'';
	static ReadingFolderParameter = 'Läser mappen \'{0}\'...';
	static SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara kvalifikationen \'{0}\'! Fel: \'{1}\'';
	static PublishingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Det gick inte att publicera aktivitetstypen \'{0}\'! Fel: \'{1}\'';
	static RemoveBasicEducation = 'Ta bort grundutbildning';
	static WhistleBlowEventTypeAttachmentsAdded = 'Bilagor har lagts till';
	static AddEventMessageConfirmation = 'Vill du lägga till kommentaren? Kommentaren kan inte redigeras efter att den har lagts till.';
	static SavingEventUrgencyClassParameterWasSuccessful = 'Brådskande klass \'{0}\' har sparats!';
	static DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort filen \'{0}\'! Fel: \'{1}\'';
	static InstantMessageChannelGroupExternal = 'Grupp (intercompany)';
	static CompanyIdClaimNotFound = 'Användaranspråket "företags-id" hittades inte!';
	static WhistleBlowReportIHaveSavedThePassword = 'Jag har lagrat lösenordet i den säkra lagringen så att jag kan använda det för att kontrollera status för min rapport.';
	static UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels = 'Användaren är bara chattadministratör i {0} chattar!';
	static Unlimited = 'Obegränsat';
	static RemoveDefaultEventType = 'Ta bort standardkategori 1';
	static SavingOneTimePasswordWasNotSuccessfulErrorParameter = 'Det gick inte att spara engångslösenordet! Fel: \'{0}\'';
	static NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded = 'Ny vidareutbildning måste sparas innan bilagor kan läggas till!';
	static SavingFileFilterParameterWasSuccessful = 'Filfiltret \'{0}\' har sparats!';
	static PermissionToCreateEventsToPartners = 'Tillstånd att skapa incidenter för partners';
	static ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError = 'Det gick inte att avsluta chatten eftersom data i chatten har ändrats samtidigt av en annan användare!';
	static FieldsToBePrinted = 'Fält som ska skrivas ut';
	static WhistleBlowSourceOther = 'Övrig';
	static DoYouReallyWantToSave = 'Vill du spara?';
	static MovingTaskParameterToTrashBinWasSuccessful = 'Uppgiften \'{0}\' har flyttats till papperskorgen!';
	static DisableExternalIntraSystemConfirmation = 'Vill du verkligen stänga av företaget från systemet? Företaget kommer att tas bort från systemet när företaget har sparats.';
	static WhistleBlowMustBeReceivedLatestAtParameter = 'Visselslagsrapport måste markeras som mottagen {0} senast!';
	static UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter = 'Det gick inte att länka användaren \'{0}\' till externa system! Fel: \'{1}\'';
	static SavingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara uppgiftstypen \'{0}\'! Fel: \'{1}\'';
	static TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion = 'Detta system stöder inte din webbläsare. Uppdatera din webbläsare till senaste versionen.';
	static UserRecordStatisticsMissingQualifications = 'Saknade kvalifikationer';
	static ObserverActionMustBeDefined = 'Observatörens agerande måste definieras!';
	static CanShareContentToCompanies = 'Innehåll kan delas med dessa partner';
	static SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara whistle blow-kanalen \'{0}\', eftersom data i whistle blow-kanalen har ändrats samtidigt av en annan användare!';
	static AddDefaultHandler = 'Lägg till standardhanterare';
	static WhistleBlowInformation = 'Rapportera information';
	static DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort grundläggande utbildningar \'{0}\'! Fel: \'{1}\'';
	static EditWhistleBlow = 'Redigera Whistle Blow Report';
	static NewRecordUserMustBeSavedBeforeRowsCanBeAdded = 'Ny användarpost måste sparas innan rader kan läggas till!';
	static ReadingFileParameterWasNotSuccessfulFileNotFound = 'Det gick inte att läsa filen \'{0}\' i filsystemet! Filen hittades inte!';
	static ChangingPasswordWasSuccessful = 'Lösenordet har ändrats!';
	static WhistleBlowSeverityHigh = 'Hög';
	static WhistleBlowEventTypeHandlersRemoved = 'Handlare borttagna';
	static AdditionalPermissions = 'Ytterligare behörigheter';
	static Aprint = 'Utskrift';
	static CreatingCompressedFileWasNotSuccessfulErrorParameter = 'Filkomprimering misslyckades! Fel: \'{0}\'';
	static WhistleBlowWasNotFound = 'Visslingsrapport hittades inte!';
	static ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError = 'Det gick inte att arkivera vidareutbildningar, eftersom data i vidareutbildningar har ändrats samtidigt av en annan användare!';
	static AddCompany = 'Lägg till företag';
	static PostalAddress = 'Gatuadress';
	static AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel = 'Lägg till minst en deltagare i chatten!';
	static NewPrint = 'Nytt utskrift';
	static ExternalIntraSystems = 'Externa eSalkku-system';
	static SavingCompanyGroupParameterWasSuccessful = 'Företagsgruppen \'{0}\' har sparats!';
	static TaskHandler = 'Hanterare';
	static AccessRightReadInstantMessages = 'Företagsintern chatt';
	static FolderProperties = 'Mappegenskaper';
	static WhistleBlowSourceUnspecified = 'Ospecificerad';
	static UserIsSystemAdminClaimNotFound = 'Användaranspråket "userIsSystemAdmin" hittades inte!';
	static DocumentParameterAlreadyExists = 'Dokumentet \'{0}\' finns redan i mappen!';
	static ImageProperties = 'Bildegenskaper';
	static RefreshList = 'Uppdatera listan';
	static EmptyTaskTrashBinConfirmation = 'Vill du verkligen ta bort alla uppgifter permanent från papperskorgen? Det går inte att återställa borttagna uppgifter?';
	static ImmediateAction = 'Avhjälpande åtgärd';
	static AddUserParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att lägga till användarkontot \'{0}\', eftersom data har ändrats samtidigt av en annan användare!';
	static EventTypeTitle = 'Incidentidentifieringskategori 1';
	static MovingFilesAndDocumentsToFolderParameterWasSuccessful = 'Filer/dokument har flyttats till mappen \'{0}\' framgångsrikt!';
	static UserCanBeSelectedAsEventHandler = 'Användare kan väljas som incidenthanterare';
	static Expand = 'Bygga ut';
	static ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att arkivera rapporterna "{0}"! Fel: \'{1}\'';
	static RecordUser = 'Användarpost';
	static SavingEditableDocumentParameterPublishWasSuccessful = 'Dokumentet \'{0}\' har publicerats framgångsrikt!';
	static AddingAttachmentParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till bilagan \'{0}\'! Fel: \'{1}\'';
	static GroupByQuarter = 'Gruppera efter kvartal';
	static SavingEventMessageWasNotSuccessfulErrorParameter = 'Det gick inte att spara kommentaren! Fel: \'{0}\'';
	static WorkShiftType = 'Arbetsskiftstyp';
	static WhistleBlowAnonymityAnonymous = 'Anonym';
	static EventTypeBCodeMustBeDefined = 'Kategori 2 namn måste definieras!';
	static MyFiltersAndReports = 'Användarens filter och rapporter';
	static DeletingRecordFurtherEducationParameterWasSuccessful = 'Vidareutbildning \'{0}\' har tagits bort!';
	static ProcessingFolderParameter = '* Bearbetar mappen \'{0}\'...';
	static RemoveDocumentTemplate = 'Ta bort dokumentmall';
	static NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded = 'Ny användarpost måste sparas innan ytterligare utbildningar kan läggas till!';
	static SavingEventSettingsWasNotSuccessfulErrorParameter = 'Det gick inte att spara incidentinställningarna! Fel: \'{0}\'';
	static DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort kvalifikationen \'{0}\' eftersom data i kvalificeringen har ändrats samtidigt av en annan användare!';
	static AdditionalPermissionAWhistleBlow = 'Kan kommunicera med informatör';
	static UserAccountMustBeSelected = 'Användarkonto måste väljas!';
	static ProcessingTime = 'Behandlingstid';
	static SavingPrintWasNotSuccessfulErrorParameter = 'Det gick inte att spara utskriften! Fel: \'{1}\'';
	static AddTarget = 'Lägg till mål';
	static DeletingEventTypeBParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort kategori 2 \'{0}\'! Fel: \'{1}\'';
	static RecordFurtherEducationParameterNotFound = 'Vidareutbildning \'{0}\' hittades inte!';
	static RemoveParameterDocuments = 'Ta bort {0} filer/dokument';
	static EditEvent = 'Redigera incident';
	static RemoveRowConfirmation = 'Vill du verkligen ta bort rad {0}?';
	static Addition = 'Tillägg';
	static MiddleName = 'Mellannamn';
	static SendReadReceiptConfirmation = 'Vill du skicka läskvitto?';
	static EditEventTypeBs = 'Redigera kategori 2';
	static NotifiedPersons = 'Aviseringar';
	static AddFile = 'Lägg till fil';
	static ChangeLanguage = 'Ändra språk';
	static DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort uppgiftsprioritet \'{0}\', eftersom data i uppgiftsprioriteten har ändrats samtidigt av en annan användare!';
	static ExpiringIn90Days = 'Inkludera utbildningar som löper ut om 90 dagar';
	static TaskRemainderCheckingAndNotificationStarted = 'Kontroll av resterande uppgifter och avisering startade';
	static SavingOptionParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara alternativet \'{0}\', eftersom data i alternativet har ändrats samtidigt av en annan användare!';
	static IpAddress = 'IP-adress';
	static AddAttachementsToEvent = 'Lägg till bilaga genom att trycka på plusknapparna.';
	static AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till lönerader! Fel: \'{0}\'';
	static SendingEmailToParameter = 'Skickar e-post till adressen \'{0}\'...';
	static ParameterPercentOfFileStorageQuotaUsed = '{0}% av fillagringskvoten använd';
	static AddPartnersParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till partner \'{0}\'! Fel: \'{1}\'';
	static WhistleBlowInformant = 'Informant';
	static DefaultEventHandlers = 'Standardincidenthanterare';
	static FieldParameterConstainsIllegalCharactersForFileName = 'Värdet på fältet \'{0}\' innehåller tecken som är olagliga i filnamn (<, >, :, ", /, \, |, ? tai *) eller så slutar det med olagliga tecken (., \' \')!';
	static AccessRightWriteTasks = 'Skapa och hantera uppgifter';
	static DeletingEventPartnerParameterWasSuccessful = 'Incidentpartnern \'{0}\' har tagits bort!';
	static State = 'stat';
	static OtherInformation = 'Annan information';
	static CompanyThatIsRelatedToEventsCannotBeDeleted = 'Företag som har skickat eller tagit emot incidenter kan inte tas bort!';
	static SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara brådskande klass \'{0}\'! Fel: \'{1}\'';
	static PreviousCalendarYear = 'Föregående kalenderår';
	static SavingChartWasSuccessful = 'Diagrammet har sparats!';
	static DeletingTaskTypesParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort uppgiftstyperna \'{0}\'! Fel: \'{1}\'';
	static DoYouReallyWantToRemoveSelectedRecordFurtherEducations = 'Vill du verkligen ta bort utvalda vidareutbildningar?';
	static EditDocumentTemplate = 'Redigera dokumentmall';
	static ByName = 'Vid namn';
	static AddChildEventTypeC = 'Lägg till underkategori 3';
	static ChartGenerationAborted = 'Skapandet av diagram avbröts!';
	static OtherSettings = 'Övriga egenskaper';
	static DefaultHandlers = 'Standardhanterare';
	static SavingUnitsWasNotSuccessfulErrorParameter = 'Det gick inte att spara enheter! Fel: \'{0}\'';
	static PasswordMustContainUppercaseChar = 'Lösenordet måste innehålla versaler!';
	static ActionIsNotAuthorized = 'Åtgärd är inte tillåten!';
	static UserGroupsMustBeDefined = 'Användargrupper måste definieras!';
	static FieldParameterValueCanOnlyContainNumbers = 'Värdet på fältet \'{0}\' kan bara innehålla siffror!';
	static WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted = 'Whistle blow kategori "{0}" används i {1} whistle blow records och kan inte tas bort!';
	static SavingEventTypeParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara kategori 1 \'{0}\', eftersom data i kategori 1 har ändrats samtidigt av en annan användare!';
	static CustomStyleClass = 'Klassnamn för anpassad stil';
	static SavingDirectoryItemPermissionsWasSuccessful = 'Mappbehörigheterna har sparats!';
	static SharedReport = 'Delad rapport';
	static Operation = 'Drift';
	static RecordUserQualificationParameterNotFound = 'Användarpostens kvalificeringspost \'{0}\' hittades inte!';
	static DocumentNameParameterConstainsIllegalCharacters = 'Dokumentnamnet \'{0}\' innehåller förbjudna tecken (<, >, :, ", /, \, |, ? eller *) eller slutar med förbjudna tecken (., \' \')!';
	static ExitInstantMessageChannel = 'Avsluta chatten';
	static AddDefaultBasicEducation = 'Lägg till standard grundutbildning';
	static SavingTaskPriorityParameterWasSuccessful = 'Uppgiftsprioritet \'{0}\' har sparats!';
	static EditEventTypeB = 'Redigera kategori 2';
	static ActiveTasks = 'Aktiva uppgifter';
	static BannerPath = 'Banner mapp';
	static ClearSubUnitDefaultHandlers = 'Rensa underenhets standardhanterare';
	static SavingEventSettingsWasSuccessful = 'Incidentinställningarna har sparats!';
	static CopyGroupPermissions = 'Kopiera gruppbehörigheter till minnet';
	static JobTypeEmail = 'E-mailar';
	static UnpublishingEditableDocumentParameterWasSuccessful = 'Dokumentet \'{0}\' har avpublicerats!';
	static DoYouReallyWantToRemoveWhistleBlowParameter = 'Vill du verkligen ta bort whistle blow-rapporten \'{0}\'?';
	static DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort kategorin \'{0}\'! Fel: \'{1}\'';
	static ModifyDateEndDate = 'Senast ändrad till';
	static AdminMustBeDefined = 'Administratör måste definieras!';
	static SaveAsFavorite = 'Spara som favorit';
	static Handlers = 'Hanterare';
	static RenamingFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att byta namn på mappen (\'{0}\' => \'{1}\') i filsystemet! Fel: \'{2}\'';
	static MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att flytta mappen \'{0}\' till mappen \'{1}\', eftersom data i mappen har ändrats samtidigt av en annan användare!';
	static SearchCondition = 'sökkriterier';
	static SavingWhistleBlowWasSuccessful = 'Whistle blow-rapporten har sparats!';
	static LogEventTime = 'Tid';
	static DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort kvalifikationerna "{0}", eftersom data i kvalifikationer har ändrats samtidigt av en annan användare!';
	static NewUserMustBeSavedBeforeUserGroupsCanBeAdded = 'Ny användare måste sparas innan användargrupper kan läggas till!';
	static InheritedContentFilter = '(ärvt innehållsfilter)';
	static ActiveUserTasks = 'Aktiva användarskapade uppgifter';
	static DeletingTaskPrioritiesParameterWasSuccessful = 'Uppgiftsprioriteterna \'{0}\' har tagits bort!';
	static TrainingStartDateEnd = 'Utbildningens startdatum - slut';
	static DocumentsNotFound = 'Dokumenten hittades inte!';
	static PushEndpoint = 'Tryck på Endpoint';
	static NewUserGroupMustBeSavedBeforeUsersCanBeAdded = 'Ny användargrupp måste sparas innan användare kan läggas till!';
	static ReadReceiptGivenPlur = 'Läskvitto skickat';
	static AdministratorUserCodeMustBeDefined = 'Användarnamnet för administratören måste definieras!';
	static AddFurtherEducation = 'Lägg till vidareutbildning';
	static LargeSize = 'Stor storlek';
	static DoYouReallyWantToMoveTaskParameterToTrashBin = 'Vill du flytta uppgiften \'{0}\' till papperskorgen?';
	static SavingEventParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara incidenten \'{0}\' eftersom data i incidenten har ändrats samtidigt av en annan användare!';
	static LanguageFinnish = 'finska';
	static DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort brådskande klasser "{0}", eftersom data i brådskande klasser har ändrats samtidigt av en annan användare!';
	static MaxSearchResultCount = 'Max antal sökresultat';
	static SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara dokumentmallen \'{0}\', eftersom data i dokumentmallen har ändrats samtidigt av en annan användare!';
	static Hour = 'Timme';
	static UnitCodeIsAlreadyInUse = 'Enhetens namn används redan!';
	static CostCentreNumber = 'Kostnadsställenummer';
	static CopyDefaultHandlers = 'Kopiera standardhanterare';
	static ArgumentParameterIsNULL = 'Värdet på argumentet \'{0}\' är NULL!';
	static SelectEventPartner = 'Välj Incident Partner';
	static CompanyGroupNameMustBeDefined = 'Företagsgruppens namn måste anges!';
	static SavingUserGroupParameterWasSuccessful = 'Användargruppen \'{0}\' har sparats!';
	static AddChildDefaultEventType = 'Lägg till standardunderkategori 1';
	static EmploymentType = 'Anställnings typ';
	static RemoveMessage = 'Ta bort meddelande';
	static UserInformation = 'Användarkonto';
	static MessageTitleMustBeDefined = 'Meddelandets titel till datum måste definieras!';
	static DoYouReallyWantToRemoveDefaultEventHandlerParameter = 'Vill du verkligen ta bort standardincidenthanteraren \'{0}\'?';
	static SavingMessageTypeParameterWasSuccessful = 'Meddelandetypen \'{0}\' har sparats!';
	static FileNameConstainsIllegalCharacters = 'Filnamnet innehåller förbjudna tecken (<, >, :, ", /, \, |, ? eller *) eller slutar med förbjudna tecken (., \' \')!';
	static AddEventTypeC = 'Lägg till kategori 3';
	static AddEventTypeB = 'Lägg till kategori 2';
	static DeletingDocumentTemplateParameterWasSuccessful = 'Dokumentmallen \'{0}\' har tagits bort!';
	static SavingFilterWasNotSuccessfulErrorParameter = 'Det gick inte att spara filtret! Fel: \'{1}\'';
	static View = 'Se';
	static EmailsSentSuccessfully = 'E-postmeddelanden har skickats!';
	static ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError = 'Det gick inte att arkivera ytterligare bekantskaper, eftersom data i bekantskaper har ändrats samtidigt av en annan användare!';
	static LockingTaskParameterWasNotSuccessful = 'Det gick inte att låsa uppgiften \'{0}\'!';
	static UnitParameterNotFound = 'Enheten \'{0}\' hittades inte!';
	static MessageTypeCodeMustBeDefined = 'Meddelandetypskod måste definieras!';
	static DocumentModuleProperties = 'Dokumentegenskaper';
	static AdminMessage = 'Meddelande till chefer';
	static DeletingOptionParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort alternativet \'{0}\'! Fel: \'{1}\'';
	static DeletingDirectoryItemDocumentParameterWasSuccessful = 'Filen \'{0}\' har tagits bort!';
	static AddAttachment = 'Bifoga fil';
	static DoYouWantToPublishEvent = 'Vill du publicera händelsen?';
	static EventTypeCCodeIsAlreadyInUse = 'Kategori 3-namnet används redan!';
	static EditUser = 'Redigera användarkonto';
	static SendMessage = 'Skicka meddelande';
	static UnlockingEditableDocumentParameterWasNotSuccessful = 'Det gick inte att låsa upp dokumentet \'{0}\'!';
	static JobTitleMustBeDefined = 'Arbetstitel måste definieras!';
	static AddRecordUser = 'Lägg till användarpost';
	static JobParameterNotFound = 'Jobbet \'{0}\' hittades inte!';
	static DefaultDeductionWithoutTax = 'Standardavdrag (moms 0 %)';
	static Location = 'Plats';
	static AddEventHandler = 'Lägg till hanterare';
	static RecordUserModuleProperties = 'Egenskaper för användarposter';
	static Parameters = 'Parametrar';
	static ApproveEventHandlerConfirmation2 = 'Vill du verkligen godkänna incidenten?';
	static RemoveLogisticVoucherCustomer = 'Ta bort kund';
	static DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort anställningen \'{0}\'! Fel: \'{1}\'';
	static JobLineDataIsEmpty = 'Arbetslinjedata saknas!';
	static PanelSize4 = '2 x 2';
	static RecordUserFamiliarizationParameterNotFound = 'Användarpost bekantskapspost \'{0}\' hittades inte!';
	static DraftCannotBeApprovedDoYouWantToSaveDraftAnyway = 'Utkast kan inte godkännas! Vill du ändå spara utkast (godkännande kommer inte att sparas)?';
	static AddDefaultEventType = 'Lägg till standardkategori 1';
	static UserProfile = 'Användarkonto';
	static DeletingUserGroupUsersOfCompanyParameterWasSuccessful = 'Användargruppsanslutningar relaterade till företaget \'{0}\' har tagits bort!';
	static IgnoreFolderPatterns = 'Ignorera mappmönster';
	static AddPartner = 'Lägg till partner';
	static DoYouReallyWantToRemoveRecordQualificationParameter = 'Vill du verkligen ta bort kvalifikationen \'{0}\'?';
	static RemoveCompanyFromCompanyGroup = 'Ta bort företag från företagsgruppen';
	static DeletingEventTypeParameterWasSuccessful = 'Kategori 1 \'{0}\' har tagits bort!';
	static DeletingCompanyParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort företaget \'{0}\' eftersom data i företaget har ändrats samtidigt av en annan användare!';
	static Portrait = 'Porträtt';
	static ApprovedEvent = 'Godkände Incidenten';
	static DoYouReallyWantToReplaceDocumentTemplateParameter = 'Vill du verkligen ersätta dokumentmallen \'{0}\'?';
	static ReadImpPlur = 'Läsa';
	static RemoveCompanyGroup = 'Ta bort företagsgrupp';
	static LogOut = 'Logga ut';
	static UserCodeUpdateWasNotSuccessfulErrorParameter = 'Det gick inte att uppdatera användarkoden! Fel: \'{0}\'';
	static Target = 'Mål';
	static BankBICCode = 'Bankens BIC-kod';
	static RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter = 'Det gick inte att återställa uppgiften \'{0}\' från papperskorgen! Fel: \'{1}\'';
	static UserRecordStatisticsUserAccountConnectedCards = 'Användarkonto anslutna kort';
	static SavingEventPartnerParameterWasSuccessful = 'Incidentpartner \'{0}\' har sparats!';
	static NewRow = 'Ny rad';
	static WhistleBlowAnonymousReport = 'Anmäl anonymt';
	static Code = 'Koda';
	static UpdateUserGroupParameterExternalSystemsWasSuccessful = 'Användargruppen \'{0}\' har länkats till externa system framgångsrikt!';
	static Path = 'Väg';
	static EmptyingTaskTrashBinWasSuccessfulErrorParameter = 'Det gick inte att tömma papperskorgen! Fel: \'{1}\'';
	static WhistleBlowChannelMustHaveAtLeastOneAdministrator = 'Whistle blow-kanal måste ha minst en administratör!';
	static SavingKeywordParameterWasSuccessful = 'Sökordet \'{0}\' har sparats!';
	static AddWhistleBlowChannelAdminsParameterWasSuccessful = 'Administratörer "{0}" har lagts till!';
	static SavingInstantMessageChannelParameterWasSuccessful = 'Chatten "{0}" har sparats!';
	static SelectTaskState = 'Välj Task State';
	static ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter = 'Aktiva behörigheter kan inte kopieras till mappen \'{0}\'!';
	static MoneyOut = 'Pengar ut';
	static SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara mappen \'{0}\'! Fel: \'{1}\'';
	static SearchTasks = 'Sök uppgifter';
	static SomeInstantMessageUsersHaveReadMessage = 'Vissa mottagare har läst ditt meddelande';
	static TaskState = 'Uppgiftsstat';
	static AmountOfComments = 'Antal kommentarer';
	static MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB = 'Den maximala totala storleken på filer som kan läggas till i batch är {0} MB!';
	static FamiliarizationEndDate = 'Slutdatum för bekantskap';
	static MessageDateMustBeDefined = 'Meddelandedatum måste anges!';
	static GroupByMonth = 'Gruppera efter månad';
	static CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter = 'Det gick inte att kontrollera existensen av filen \'{0}\' i filsystemet! Fel: \'{1}\'';
	static YouDeletedThisMessage = 'Du tog bort det här meddelandet';
	static EditRemainder = 'Redigera resten';
	static PrintParameterDownloaded = 'Utskriften har laddats ner.';
	static PushNotificationInUse = 'Jag vill ta emot push-meddelanden (eSalkku Mobile)';
	static NewPanel = 'Ny panel';
	static UserRecordCards = 'Användarregister';
	static ReadReceipts = 'Läsa kvitton';
	static DoYouReallyWantToRemoveSelectedTaskStates = 'Vill du verkligen ta bort valda ytterligare uppgiftsuppgifter?';
	static WhistleBlowResolutionOther = 'Övrig';
	static WhistleBlowSeverityVeryLow = 'Väldigt låg';
	static SentEmails = 'Skickade mejl';
	static SavingWhistleBlowChannelParameterWasSuccessful = 'Whistle blow-kanalen \'{0}\' har sparats!';
	static UserTypeClaimNotFound = 'Användaranspråket "användartyp" hittades inte!';
	static DoYouReallyWantToRemoveThisInstantMessage = 'Vill du verkligen ta bort det här snabbmeddelandet? Snabbmeddelanden kommer att tas bort från alla parter.';
	static DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort visselblåsningsrapporten "{0}", eftersom data i visselblåsningsrapporten har ändrats samtidigt av en annan användare!';
	static Rejected = 'avvisade';
	static SavingEventPartnerParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara incidentpartnern \'{0}\'! Fel: \'{1}\'';
	static DeletingAttachmentsParameterWasSuccessful = 'Bilagorna \'{0}\' har tagits bort!';
	static DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort grundläggande utbildningar \'{0}\', eftersom data i grundläggande utbildningar har ändrats samtidigt av en annan användare!';
	static Reminders = 'Påminnelser';
	static NoMessagesToShow = 'Inga meddelanden att visa!';
	static Returned = 'Returnerad';
	static DeletingInstantMessageWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort snabbmeddelandet eftersom data i snabbmeddelandet har ändrats samtidigt av en annan användare!';
	static SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara chatten "{0}", eftersom data i chatten har ändrats samtidigt av en annan användare!';
	static InternalAttachments = 'Interna bilagor';
	static RemoveReport = 'Ta bort rapport';
	static DoYouReallyWantToRemoveUserGroupParameter = 'Vill du verkligen ta bort användargruppen \'{0}\'?';
	static SavingPrintWasNotSuccessfulConcurrencyError = 'Det gick inte att spara utskriften "{0}", eftersom data i utskriften har ändrats samtidigt av en annan användare!';
	static LoadMore = 'Ladda mer';
	static Category = 'Kategori';
	static AddCompanyGroupsParameterWasSuccessful = 'Företagsgrupper "{0}" har lagts till!';
	static AuthorizationLevelNone = 'Inga rättigheter';
	static SavingUserGroupParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara användargruppen \'{0}\', eftersom data i användargruppen har ändrats samtidigt av en annan användare!';
	static ClearSubUnitDefaultHandlersAndNotifiedPersons = 'Rensa underenhets standardhanterare och anmälda personer';
	static WhistleBlowChannelWasNotFoundExplanation = 'Kontrollera internetadressen som angavs i webbläsarens adressfält.';
	static AddingEventHandlerWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till hanterare! Fel: \'{0}\'';
	static NameIsAlreadyInUse = 'Namnet används redan!';
	static Color = 'Färg';
	static DeletingFileFilterParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort filfiltret \'{0}\' eftersom data i filfiltret har ändrats samtidigt av en annan användare!';
	static Unpublish = 'Avpublicera';
	static DeletingUserParametersWasSuccessful = 'Användarparametrar har tagits bort!';
	static Schema = 'Schema';
	static SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara anställningen \'{0}\', eftersom data i anställningen har ändrats samtidigt av en annan användare!';
	static EditDefaultBasicEducation = 'Redigera Standard Grundutbildning';
	static ExternalSystemUserCodeMustBeDefined = 'Användarkod i externt system måste definieras!';
	static Yesterday = 'I går';
	static WhistleBlowNonAnonymousReport = 'Avsändarinformation ingår i rapporten';
	static SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter = 'Det gick inte att spara deltagare! Fel: \'{0}\'';
	static DocumentTypes = 'Dokumenttyper';
	static ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter = 'Misslyckades med att arkivera vidareutbildningar! Fel: \'{0}\'';
	static DocumentRootPathMustBeDefined = 'Dokumentets rotsökväg måste definieras!';
	static WhistleBlowResolutionResolved = 'Löst';
	static RemoveEvent = 'Ta bort incidenten';
	static EditBasicEducation = 'Redigera Grundutbildning';
	static DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort uppgiftsprioriteterna \'{0}\'! Fel: \'{1}\'';
	static SavingFileFilterParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara filfiltret \'{0}\', eftersom data i filfiltret har ändrats samtidigt av en annan användare!';
	static PermissionsForSystems = 'Systembehörigheter';
	static DraftCannotBeApproved = 'Utkast kan inte godkännas!';
	static AllWhistleBlowHandlersHaveReadMessage = 'Alla som hanterar whistleblow-rapporter har läst ditt meddelande';
	static TaskTypeCodeIsAlreadyInUse = 'Uppgiftstypens namn används redan!';
	static AdditionalPermissionARecords = 'Arkivering och borttagning';
	static UseObserverAction = 'Visa observatörsåtgärdsfält';
	static Order = 'Beställa';
	static EventCreator = 'Skapare';
	static AddUserGroup = 'Lägg till användargrupp';
	static DeletingOneTimePasswordWasSuccessful = 'Engångslösenordet har tagits bort!';
	static SendingNotificationsWasNotSuccessfulErrorParameter = 'Det gick inte att skicka aviseringar! Fel: \'{0}\'';
	static DeletingEventPartnerParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort incidentpartnern \'{0}\'! Fel: \'{1}\'';
	static UserReport = 'Användarens rapport';
	static UserPasswordMustBeDefined = 'Användarlösenord måste definieras!';
	static DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort användargruppsanslutningar relaterade till företaget \'{0}\'! Fel: \'{1}\'';
	static CkickToChangeThePicture = 'Klicka för att ändra bilden';
	static WhistleBlowChannelWasNotFound = 'Whistle blow kanal hittades inte!';
	static AddingAddRecordUserTransferredPropertiesWasSuccessful = 'Överförda egenskaper har lagts till!';
	static SavingTaskStateParameterWasSuccessful = 'Uppgiftstillstånd \'{0}\' har sparats!';
	static CompanyCodeClaimNotFound = 'Användaranspråket "företagskod" hittades inte!';
	static AddFurtherEducations = 'Lägg till vidareutbildningar';
	static Info = 'Info';
	static AddHandlers = 'Lägg till hanterare';
	static Date = 'Datum';
	static EditEditableDocument = 'Redigera dokument';
	static AdditionalPermissionBWhistleBlow = 'Tillståndshantering';
	static SavingFileFilterParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara filfiltret \'{0}\'! Fel: \'{1}\'';
	static Error = 'Fel';
	static UserParameterHasInvitedYouToJoinInstantMessageChannelParameter = 'Användaren \'{0}\' har bjudit in dig att gå med i chatten';
	static FileChangesCheckingAndEmailingFinishedInParameterSeconds = 'Filändringskontroll och e-post har slutförts (varaktighet: {0:N0}s, fel: {1}, filer: {2}, e-postmeddelanden: {3})';
	static InstantMessageUserInvitationAcceptTypeCanInvite = 'Jag kan bli inbjuden att gå med i chatten';
	static ValidPasswordMustBeDefined = 'Giltigt lösenord måste definieras!';
	static DoYouReallyWantToRemoveEventTypeParameter = 'Vill du verkligen ta bort kategori 1 \'{0}\'?';
	static DownloadPdf = 'Ladda ner pdf';
	static EventPartnerParameterNotFound = 'Incidentpartner \'{0}\' hittades inte!';
	static WhistleBlowInformantHasReadMessage = 'Informatören i en visselblåsningsrapport har läst ditt meddelande';
	static UserIdClaimNotFound = 'Användarens anspråk \'id\' hittades inte!';
	static Other = 'Övrig';
	static AddTaskType = 'Lägg till uppgiftstyp';
	static SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara standardincidenthanteraren \'{0}\'! Fel: \'{1}\'';
	static ReportGenerationFailed = 'Det gick inte att skapa rapporten!';
	static AccessRightNone = 'inga tillstånd';
	static ExternalIntraSystemUserTokenMustBeDefined = 'Användartoken i externt eSalkku-system måste definieras!';
	static EventTypesMustBeDefinedBeforeAddingEvents = 'Kategori 1 måste definieras innan incidenter läggs till!';
	static DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter = 'Brådskande klasser \'{0}\' har tagits bort!';
	static InstantMessagePartners = 'Instant Message Partners';
	static MyFilters = 'Användarens filter';
	static DefineRecordUserFurtherEducation = 'Definiera vidareutbildning';
	static InstantMessageAttachmentFolderParameterNotFound = 'Programmets bifogade mappen \'{0}\' hittades inte!';
	static EditCompanyGroup = 'Redigera företagsgrupp';
	static EventTypeMustBeDefined = 'Kategori 1 måste definieras!';
	static RemoveEventType = 'Ta bort kategori 1';
	static AccountNumberTkbInDebit = 'Försäljningsfordringar konto';
	static MessageTypes = 'Meddelandetyper';
	static WhistleBlowEventTypeAttachmentsRemoved = 'Bilagor har tagits bort';
	static LoggedInUserCannotBeDeleted = 'Användaren kan inte ta bort sig själv!';
	static ExternalSystemCompanyTokenParameterIsAlreadyInUse = 'Företagstoken \'{0}\' används redan!';
	static ParameterIsNotValidAccountIBANNumber = '\'{0}\' är inte ett giltigt bankkonto IBAN-nummer!';
	static DeletingRecordFurtherEducationsParameterWasSuccessful = 'Vidareutbildningar \'{0}\' har tagits bort!';
	static DoYouReallyWantToRemoveDocumentTemplateParameter = 'Vill du verkligen ta bort dokumentmallen \'{0}\'?';
	static CompanyWithSysAdminAuthorizationLevelNotFound = 'Företaget som är relaterat till användargruppen \'Systemadministratör\' hittades inte!';
	static ToggleArchivedFurtherEducations = 'Visa/Göm arkiverade vidareutbildningar';
	static FiscalNumber = 'Skattenummer';
	static SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara kvalifikationen \'{0}\', eftersom data i kvalificeringen har ändrats samtidigt av en annan användare!';
	static DocumentProperties = 'Fil-/dokumentegenskaper';
	static LastWeek = 'Förra veckan';
	static RemovingEventHandlersWasSuccessful = 'Hanterare har tagits bort!';
	static DeletingMetadataFromFileParameterFailed = 'Det gick inte att ta bort metadata från filen \'{0}\'!';
	static IndirectCosts = 'Indirekta kostnader';
	static TrainingTime = 'Träningsdags';
	static EmailAddressMustBeValid = 'E-postadressen är inte giltig e-postadress!';
	static AddUser = 'Lägg till användarkonto';
	static SavingInstantMessageWasSuccessful = 'Snabbmeddelandet har sparats!';
	static FailureAudit = 'FailureAudit';
	static Approved = 'Godkänd';
	static FiltersStandard = 'Standardfilter';
	static User = 'Användarkonto';
	static CompanyToken = 'Company Token';
	static ShowArchived = 'Visa arkiverad';
	static InstantMessageChannelMustHaveAtLeastOneAdministrator = 'Chatten måste ha minst en administratör!';
	static AddQualificationToMultipleUserRecords = 'Lägg till kvalificering till flera användarposter';
	static PasteGroupPermissions = 'Klistra in gruppbehörigheter från minnet';
	static SavingEditableDocumentParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara dokumentet \'{0}\'! Fel: \'{1}\'';
	static TaskStateParameterNotFound = 'Uppgiftstillstånd \'{0}\' hittades inte!';
	static FileFilterCodeIsAlreadyInUse = 'Filfilterkoden används redan!';
	static RemoveEventHandler = 'Ta bort hanteraren';
	static IdentificationDetails = 'Identifieringsdetaljer';
	static ThisChannelHasBeenArchived = 'Den här chatten har arkiverats!';
	static WhistleBlowEventTypeCreated = 'Skapad';
	static NotApproved = 'Ej godkänd';
	static EditTransferredProperty = 'Redigera överförd egendom';
	static RemoveFamiliarization = 'Ta bort bekantskaper';
	static LanguageNonLocalEnglish = 'English';
	static SavingUserParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara användaren \'{0}\'! Fel: \'{1}\'';
	static NotificationSettings = 'Aviseringsinställningar';
	static SaveAndEmail = 'Spara och maila';
	static AddWhistleBlowChannelAdminParameterWasSuccessful = 'Administratören \'{0}\' har lagts till!';
	static TaxNumber = 'Skattenummer';
	static GroupByUnit = 'Gruppera efter enhet';
	static AddPayer = 'Lägg till betalare';
	static EmailAddressParameterIsInvalid = 'E-postadressen "{0}" är inte giltig!';
	static LoggedInUserAccountIsNotActive = 'Inloggad användare är inte aktiv!';
	static DeletingInstantMessageWasSuccessful = 'Snabbmeddelandet har tagits bort!';
	static EditTask = 'Redigera uppgift';
	static DirectoryItemDocumentsNotFound = 'Filer/dokument gillar inte!';
	static RemoveFileFilter = 'Ta bort filfilter';
	static RecordUserEmploymentParameterNotFound = 'Användarregister anställningspost \'{0}\' hittades inte!';
	static ExportToExcel = 'exportera till Excel';
	static AddFolder = 'Lägg till mapp';
	static UsersOfThisCompany = 'Användare av Mitt företag';
	static EventHandler = 'Hanterare';
	static SaveAndPublish = 'Spara och publicera';
	static TrainingEndDate = 'Slutdatum för träning';
	static ReferenceNumberPrefix = 'Referensnummerprefix';
	static EmailNotificationInUse = 'Jag vill få e-postmeddelanden';
	static WhistleBlowSourcePhone = 'Telefon';
	static RecordUserExcelQualification = 'Kvalifikationer';
	static BasicEducations = 'Grundläggande utbildningar';
	static SavingUserParameterParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara användaralternativet \'{0}\'! Fel: \'{1}\'';
	static RemoveQualification = 'Ta bort kvalifikation';
	static TransportCompany = 'Transportföretag';
	static DeletingCompanyParameterWasSuccessful = 'Företaget \'{0}\' har tagits bort!';
	static DefaultFamiliarizations = 'Standard bekantskaper';
	static Identifier = 'Identifierare';
	static DeletingKeywordParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort sökordet "{0}", eftersom data i sökordet har ändrats samtidigt av en annan användare!';
	static WhistleBlowPasswordConfirmed = 'Whistle blow rapport har skickats. Du kan kontrollera statusen för din rapport genom att klicka på knappen "Följ rapport" på huvudsidan.';
	static NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded = 'Ny användarpost måste sparas innan överförda egenskaper kan läggas till!';
	static EventTypeParameterNotFound = 'Kategori 1 \'{0}\' hittades inte!';
	static EventsExcel = 'Incidenter - Excel';
	static AllSelected = 'Alla valda';
	static AddPartnersParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att lägga till partner "{0}", eftersom data har ändrats samtidigt av en annan användare!';
	static AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till ytterligare utbildningar! Fel: \'{0}\'';
	static RemoveRecordUserAndKeepUser = 'Ta bort användarpost och behåll användarkonto';
	static SelectUserGroupOrUser = 'Välj Användargrupp eller Användarkonto';
	static ModifyDate = 'Senast ändrad';
	static DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort kategorin "{0}" för whistle blow eftersom data i kategorin "whistle blow" har ändrats samtidigt av en annan användare!';
	static UserToken = 'Användartoken';
	static EditableDocumentParameterNotFound = 'Dokumentet \'{0}\' hittades inte!';
	static WholeHistory = 'Hela historien';
	static RestoringTaskParameterFromTrashBinWasSuccessful = 'Uppgiften \'{0}\' har återställts från papperskorgen!';
	static WriteMessage = 'Skriv meddelande';
	static RemoveDefaultTaskType = 'Ta bort standarduppgiftstyp';
	static VersionProperties = 'Versionsegenskaper';
	static Publish = 'Publicera';
	static CopyActiveUserRightsToAllSameLevelFolders = 'Kopiera aktiva behörigheter till alla mappar på samma nivå';
	static Handling = 'Hantering';
	static WhistleBlowReceiver = 'Mottagare';
	static CopyDefaultHandlersToAllSameLevelUnits = 'Kopiera standardhanterare till alla enheter på samma nivå';
	static DeletingUserParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort användarkontot \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static SavingMessageParameterWasSuccessful = 'Meddelandet \'{0}\' har sparats!';
	static SelectReceiver = 'Välj Mottagare';
	static AllCompanyGroups = 'Alla företagsgrupper';
	static AuthorizationLevelSystemAdmin = 'Systemadministratör';
	static TaskDataRow = 'Uppgiftsrad';
	static Name = 'namn';
	static DoYouReallyWantToRemovePrintParameter = 'Vill du verkligen ta bort utskriften "{0}"?';
	static MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError = 'Det gick inte att flytta uppgiften \'{0}\' till papperskorgen eftersom data i uppgiften har ändrats samtidigt av en annan användare!';
	static CompanyGroupCodeIsAlreadyInUse = 'Koden för företagsgruppen används redan!';
	static KeywordCodeIsAlreadyInUse = 'Nyckelordskoden används redan!';
	static SelectRecordUsers = 'Välj Användarposter';
	static SSRSParameterReportsFetched = '{0} rapporter hämtade från SSRS';
	static DoYouReallyWantToRemoveMessageParameter = 'Vill du verkligen ta bort meddelandet \'{0}\'?';
	static InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel = 'Endast chattadministratören kan inte avsluta chatten!';
	static RemoveUserGroupExternalSystemsWasSuccessful = 'Användargruppslänkar till externa system har tagits bort!';
	static NameMustBeDefined = 'Namn måste definieras!';
	static Permission = 'Lov';
	static ArchivingRecordUserFurtherEducationsWasSuccessful = 'Vidareutbildningar arkiverade framgångsrikt!';
	static ProcessedFolderParameterErrorCountParameter = '* Mappen \'{0}\' slutförd, fel: {1}';
	static RelativeSpecification = 'Relativ specifikation';
	static AddUsersParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till användarkonton \'{0}\'! Fel: \'{1}\'';
	static CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att kopiera filer/dokument till mappen \'{0}\'! Fel: \'{1}\'';
	static AddUsersParameterWasSuccessful = 'Användarkonton \'{0}\' har lagts till!';
	static TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Uppgiftsprioritet \'{0}\' används i {1} uppgifter och kan inte tas bort!';
	static CurrentWorkPlace = 'Nuvarande arbetsplats';
	static JobResultParameterNotFound = 'Jobbresultat \'{0}\' hittades inte!';
	static GetPhoto = 'Skaffa foto';
	static MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att flytta filer/dokument till mappen "{0}", eftersom data i mappen har ändrats samtidigt av en annan användare!';
	static RemoveKeyword = 'Ta bort nyckelord';
	static DeletingRecordUserTransferredPropertiesParameterWasSuccessful = 'Överförda egenskaper "{0}" har tagits bort!';
	static InstantMessageChannelArchivingType = 'Hantering av arkiverad chatt';
	static WhistleBlowSourceSystem = 'Systemet';
	static WhistleBlowReportHasBeenSaved = 'Whistle blow-rapporten har sparats';
	static PrintTextPublished = 'Publicerad';
	static Internet = 'Internet';
	static UserIsActiveInWeb = 'Användarkontot är aktivt i webbapplikationen.';
	static AuthorizationLevelUser = 'Användare';
	static EventTypeCCodeMustBeDefined = 'Kategori 3 namn måste definieras!';
	static InstantMessage = 'Snabbmeddelande';
	static ChangingPasswordWasNotSuccessfulConcurrencyError = 'Det gick inte att ändra lösenordet eftersom användardata har ändrats samtidigt av en annan användare!';
	static InheritedUserRight = '(ärvt tillstånd)';
	static SaveFilterAs = 'Spara filter som';
	static All = 'Allt';
	static EditCompanyTaskType = 'Redigera företagsuppgiftstyp';
	static DoYouReallyWantToRemoveSelectedUsers = 'Vill du verkligen ta bort valda användare?';
	static LockedDocumentParameterCannotBeDeleted = 'Det låsta dokumentet \'{0}\' kan inte tas bort!';
	static BrowseFiles = 'Bläddra bland filer';
	static SavingUserParameterParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara användaralternativet \'{0}\', eftersom data i användaralternativet har ändrats samtidigt av en annan användare!';
	static SavingEventTypeCsWasNotSuccessfulErrorParameter = 'Det gick inte att spara kategori 3! Fel: \'{0}\'';
	static LogisticVoucherCustomer = 'Kund';
	static SelectInstantMessageChannelUsers = 'Välj Deltagare';
	static SavingUnitPermissionsWasNotSuccessfulErrorParameter = 'Det gick inte att spara enhetsbehörigheter! Fel: \'{0}\'';
	static LanguageEnglish = 'engelska';
	static MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful = 'Mappen \'{0}\' har flyttats till mappen \'{1}\'!';
	static UserGroup = 'Användargrupp';
	static Instructions = 'Instruktioner';
	static PanelSize2 = '2 x 1';
	static CompanyRole = 'Företagsroll';
	static ReadingFileParameterWasNotSuccessfulErrorParameter = 'Det gick inte att läsa filen \'{0}\' i filsystemet! Fel: \'{1}\'';
	static InstantMessageChannelGroupInternal = 'Grupp (företagsintern)';
	static UsedQuota = 'Använd kvot';
	static UnreadInstantMessages = 'Olästa meddelanden';
	static SelectCompany = 'Välj Företag';
	static RemoveTaskPermanently = 'Ta bort Task Permanentity';
	static Remove = 'Avlägsna';
	static WhistleBlowChannelParameterNotFound = 'Visselblåsningskanalen \'{0}\' hittades inte!';
	static LockedTaskDataRowCannotBeSaved = 'Uppgiftsraden har låsts till en annan användare och den kan inte sparas!';
	static MessageStartDateMustBeDefined = 'Meddelande synligt fram till datum måste definieras!';
	static Edit = 'Redigera';
	static DoYouReallyWantToRemoveCompanyParameter = 'Vill du verkligen ta bort företaget \'{0}\'?';
	static DeletingMessageTypeParameterWasSuccessful = 'Meddelandetypen \'{0}\' har tagits bort!';
	static SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara chatten \'{0}\'! Fel: \'{1}\'';
	static Descending = 'Nedåtgående';
	static SavingEventTypeParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara kategori 1 \'{0}\'! Fel: \'{1}\'';
	static PasswordIsTooShort = 'Lösenordet är för kort!';
	static DoYouReallyWantToRemoveWhistleBlowCategoryParameter = 'Vill du verkligen ta bort kategorin \'{0}\'?';
	static ExpiringIn120Days = 'Inkludera utbildningar som löper ut om 120 dagar';
	static AddUserGroupsParameterWasSuccessful = 'Användargrupperna \'{0}\' har lagts till!';
	static TakePicture = 'Ta bild';
	static Reports = 'Rapporter';
	static AccessRightFull = 'Alla behörigheter';
	static Update = 'Uppdatering';
	static TaskTypeParameterNotFound = 'Uppgiftstypen \'{0}\' hittades inte!';
	static FollowUp = 'Uppföljning';
	static AddEmployments = 'Lägg till anställningar';
	static WhistleBlowSeverityNormal = 'Vanligt';
	static EditUnit = 'Redigera enhet';
	static Contents = 'Innehåll';
	static ReadError = 'Läsfel!';
	static AddFamiliarizationToMultipleUserRecords = 'Lägg till bekantskap till flera användarposter';
	static RemoveInvalidDefaultEventHandlersConfirmation = 'Vissa standardincidenthanterare eller anmälda personer har inte behörighet att hantera incidenter med enheter. Vill du automatiskt ta bort dessa incidenthanterare eller meddelade personer innan du sparar nya inställningar?';
	static ResultGrouping = 'Resultatgruppering';
	static DocumentContentsSearch = 'Sök dokumentinnehåll';
	static DeletingTaskParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort uppgiften \'{0}\', eftersom data i uppgiften har ändrats samtidigt av en annan användare!';
	static SavingDefaultHandlersWasNotSuccessfulErrorParameter = 'Det gick inte att spara standardhanterare! Fel: \'{0}\'';
	static WhistleBlowState = 'Rapportera tillstånd';
	static FileFilterParameterNotFound = 'Filfiltret \'{0}\' hittades inte!';
	static DoYouReallyWantToCreateRemainderInPast = 'Vill du verkligen skapa rester i det förflutna?';
	static ExitingInstantMessageChannelWasSuccessful = 'Chatten avslutades!';
	static SharedFilters = 'Delade filter';
	static MaximumSizeOfInstantMessageAttachmentIsParameterMB = 'Den maximala storleken på bilaga till snabbmeddelanden är {0} MB!';
	static Send = 'Skicka';
	static ApproveEventHandlerConfirmation = 'Avhjälpande åtgärder har inte definierats. Vill du verkligen godkänna händelsen?';
	static SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara kategorin \'{0}\'! Fel: \'{1}\'';
	static EditTaskPriority = 'Redigera uppgiftsprioritet';
	static AutoSaveVersionHistorySize = 'Autospara versionshistorikstorlek';
	static SavingEventTypeBParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara kategori 2 \'{0}\', eftersom data i incidentkategori 2 har ändrats samtidigt av en annan användare!';
	static Folder = 'Mapp';
	static NotifiedPersonsShort = 'Aviseringar';
	static Event = 'Incident';
	static ReadReceiptRequired = 'Läskvitto krävs';
	static AddDefaultTaskState = 'Lägg till standarduppgiftsstatus';
	static AddDefaultFurtherEducation = 'Lägg till standard för vidareutbildning';
	static MessageEndDate = 'Synlig till';
	static WhistleBlowArchivingTypeChangeStateAndRemoveAllData = 'Ändra tillstånd för rapport till "Arkiverad" och behåll endast information som behövs för statistik';
	static AttachmentParameterNotFound = 'Det gick inte att hitta bilagan \'{0}\'!';
	static DownloadDocument = 'Öppna/ladda ner valda filer/dokument';
	static DirectCosts = 'Direkta kostnader';
	static SaveTask = 'Spara uppgift';
	static SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara vidareutbildning \'{0}\'! Fel: \'{1}\'';
	static SavingKeywordParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara sökordet \'{0}\'! Fel: \'{1}\'';
	static SelectableWhenCreatingAnEvent = 'Valbar när du skapar en händelse';
	static DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort anställningar \'{0}\'! Fel: \'{1}\'';
	static GroupByYear = 'Gruppera efter år';
	static UserParameterCodeIsAlreadyInUse = 'Användarparametrarnas kod används redan!';
	static WhistleBlowSeverityUnspecified = 'Ospecificerad';
	static StudyWeeks = 'Studieveckor';
	static WhistleBlowSendReport = 'Skicka rapport';
	static NoToDos = 'Inga öppna saker att göra';
	static UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter = 'Det gick inte att länka företaget till externa system! Fel: \'{0}\'';
	static AddCompanyGroupParameterWasSuccessful = 'Företagsgruppen \'{0}\' har lagts till!';
	static NoPermission = 'Inga tillstånd';
	static EventTypeCodeMustBeDefined = 'Kategori 1 namn måste definieras!';
	static AddRows = 'Lägg till rader';
	static SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara vidareutbildningen \'{0}\', eftersom data i vidareutbildningen har ändrats samtidigt av en annan användare!';
	static SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara push-meddelandeloggen \'{0}\', eftersom data i push-meddelandeloggen har ändrats samtidigt av en annan användare!';
	static TaskDataRowFieldParameterNotFoundInDataSchema = 'Uppgiftsradsfältet \'{0}\' hittades inte i dataschemat!';
	static AddInstantMessageUserParameterWasSuccessful = 'Deltagare \'{0}\' har lagts till!';
	static Employee = 'Anställd';
	static EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Kategori 1 \'{0}\' används i {1} incidenter och kan inte tas bort!';
	static Card = 'Kort';
	static AddingAttachmentParameterWasSuccessful = 'Bilaga \'{0}\' har lagts till!';
	static CreatingFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att skapa mappen \'{0}\' i filsystemet! Fel: \'{1}\'';
	static PrivacyPolicyUrl = 'Sekretesspolicy webbadress';
	static ReadReceiptNotGiven = 'Kvittot ej skickat';
	static WhistleBlowStatisticsUnreadMessages = 'Olästa meddelanden';
	static ArchiveExpiredFurtherEducations = 'Arkiv Utgått Vidareutbildningar';
	static AddCompanyTaskType = 'Lägg till uppgiftstyp till företaget';
	static Default = 'Standard';
	static MinimumPasswordLengthMustBeAtLeastParameterCharacters = 'Minsta lösenordslängd måste vara minst {0} tecken!';
	static PanelName = 'Panelnamn';
	static WhistleBlowEventTypeHandlersAdded = 'Handlare har lagts till';
	static MessagePreservationTime = 'Meddelande bevarandetid';
	static LockedEventParameterCannotBeSaved = 'Incidenten \'{0}\' har låsts till en annan användare och den kan inte sparas!';
	static NoFavoritesToShow = 'Inga favoriter att visa!';
	static AddUserToUserGroup = 'Lägg till användarkonto i användargruppen';
	static SavingDocumentTemplateParameterWasSuccessful = 'Dokumentmallen \'{0}\' har sparats!';
	static PublishEvent = 'Publicera incidenten';
	static SelectInstantMessageChannelUser = 'Välj Deltagare';
	static FileFilters = 'Filfilter';
	static AllJobTitles = 'Alla jobbtitlar';
	static DeletingUsersParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort användarkonton \'{0}\'! Fel: \'{1}\'';
	static AddWhistleBlow = 'Lägg till Whistle Blow Report';
	static SavingTaskParameterWasSuccessful = 'Uppgiften \'{0}\' har sparats!';
	static DefaultEventTypes = 'Standardkategorier 1';
	static DefaultEventHandlerParameterNotFound = 'Det gick inte att hitta standardincidenthanteraren \'{0}\'!';
	static AddTaskState = 'Lägg till uppgiftsstatus';
	static InstantMessageChannelUser = 'Deltagare';
	static SharedReports = 'Delade rapporter';
	static PaymentVoucherCounter = 'Betalningskupongräknare';
	static Admins = 'Administratörer';
	static DocumentParameterDownloaded = 'Dokumentet har laddats ner.';
	static PublishTaskType = 'Publicera uppgiftstyp';
	static NewPassword = 'nytt lösenord';
	static DoYouReallyWantToRemoveTaskHandlerParameter = 'Vill du verkligen ta bort hanteraren \'{0}\'?';
	static RemovePermission = 'Ta bort behörighet';
	static TimeSpanQuarter = 'Fjärdedel';
	static EmailHasBeenAlreadySentForThisMessage = 'E-post har redan skickats för detta meddelande!';
	static DeletingMessageParameterWasSuccessful = 'Meddelandet \'{0}\' har tagits bort!';
	static DeletingTaskStateParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort uppgiftstillståndet \'{0}\', eftersom data i uppgiftstillståndet har ändrats samtidigt av en annan användare!';
	static Qualifications = 'Kvalifikationer';
	static CostCentreName = 'Kostnadsställets namn';
	static SharedReportAllCompanies = 'Delas med alla företag';
	static UserGroupCodeIsAlreadyInUse = 'Användargruppskoden används redan!';
	static PrivateAndPublic = 'Privat och offentlig';
	static CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att kopiera filer/dokument till mappen "{0}", eftersom data i mappen har ändrats samtidigt av en annan användare!';
	static TaskDashboard = 'Uppgifter instrumentbräda';
	static DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort företagsgrupper "{0}"! Fel: \'{1}\'';
	static WhistleBlowFollowReport = 'Följ Rapport';
	static UserCodeIsAlreadyInUse = 'Användarnamnet används redan!';
	static AgencyWorker = 'Byråarbetare';
	static SavingReportWasSuccessful = 'Rapporten har sparats!';
	static RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted = 'Vidareutbildning \'{0}\' används som kvalifikation i {1} användarregister och kan inte tas bort!';
	static RemoveEventTypeB = 'Ta bort kategori 2';
	static DoYouReallyWantToRemoveDocumentsParameter = 'Vill du verkligen ta bort {0} valda filer/dokument?';
	static DeletingTaskDataRowWasSuccessful = 'Uppgiftsraden har tagits bort!';
	static TitleMustBeDefined = 'Titel måste definieras!';
	static UseInheritedContentFiltering = 'Använd ärvt innehållsfiltrering';
	static AddRow = 'Lägg till rad';
	static MessageType = 'Meddelandetyp';
	static UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter = 'Det gick inte att länka användargruppen \'{0}\' till externa system! Fel: \'{1}\'';
	static DocumentTemplateCodeIsAlreadyInUse = 'Dokumentmallskoden används redan!';
	static ShowUsers = 'Visa användarkonton';
	static Orientation = 'Orientering';
	static LoggedInUser = 'Inloggad användare';
	static VersionInformation = 'Versionsinformation';
	static RecentlyUpdated = 'Nyligen uppdaterad';
	static LanguageNonLocalSwedish = 'Svenska';
	static EditEventPartner = 'Redigera Incident Partner';
	static SavingRecordUserTransferredPropertyParameterWasSuccessful = 'Den överförda egenskapen \'{0}\' har sparats!';
	static EditSharedDocumentTemplate = 'Redigera mall för delat dokument';
	static PrivacyPolicyUrlMustBeDefined = 'Sekretesspolicy webbadress måste definieras!';
	static MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att flytta filer/dokument till mappen \'{0}! Fel: \'{1}\'';
	static DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort kvalifikationen \'{0}\'! Fel: \'{1}\'';
	static EventsToApprove = 'Händelser att godkänna';
	static DoYouReallyWantToRemoveEventHandlerParameter = 'Vill du verkligen ta bort hanteraren \'{0}\'?';
	static SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara företagsgruppen \'{0}\' eftersom data i företagsgruppen har ändrats samtidigt av en annan användare!';
	static RememberMe = 'Kom ihåg mig';
	static SavingMessageTypeParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara meddelandetypen \'{0}\', eftersom data i meddelandetypen har ändrats samtidigt av en annan användare!';
	static DeletingRecordUserEmploymentsParameterWasSuccessful = 'Anställningar "{0}" har tagits bort!';
	static RecordParameterLockingFailedRecordIsWriteLockedToUserParameter = 'Det gick inte att låsa posten \'{0}\'! Posten är skrivlåst till användaren \'{0}\'!';
	static Landscape = 'Landskap';
	static AddEditableDocument = 'Lägg till dokument';
	static RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Grundutbildning \'{0}\' används i {1} användarposter och kan inte tas bort!';
	static RecordBasicEducationCodeIsAlreadyInUse = 'Grundutbildningens namn används redan!';
	static UserRecordStatisticsArchivedCards = 'Arkiverade kort';
	static EditTaskState = 'Redigera uppgiftsstatus';
	static PermissionToCreateEvents = 'Tillstånd att skapa incidenter';
	static ActiveUserRights = 'Aktiva behörigheter';
	static DoYouReallyWantToRemoveFilterParameter = 'Vill du verkligen ta bort filtret "{0}"?';
	static DriversLicenseType = 'Typ av körkort';
	static RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort hanterare! Fel: \'{0}\'';
	static AddInstantMessageChannel = 'Lägg till chatt';
	static SavingReportWasNotSuccessfulErrorParameter = 'Det gick inte att spara rapporten! Fel: \'{0}\'';
	static AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till deltagare \'{0}\'! Fel: \'{1}\'';
	static Resolution = 'Upplösning';
	static RemoveUserAndRecordUser = 'Ta bort användarkonto och användarpost';
	static KeywordsAndCondition = 'Alla valda nyckelord';
	static EditAttachment = 'Redigera bilaga';
	static TaskTypeSchemaMustBeDefined = 'Schema för uppgiftstyp måste definieras!';
	static SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara den överförda egenskapen \'{0}\'! Fel: \'{1}\'';
	static WhistleBlowSeverityCritical = 'Kritisk';
	static AccessRightReadRecords = 'Eget användarregister';
	static PaymentSetting = 'Betalningsinställning';
	static AdvancePaymentReportPath = 'Sökväg för förskottsbetalningsrapport';
	static ReadReceiptMessage = 'Meddelande som kräver läskvitto';
	static Others = 'Andra';
	static NotAssigned = 'Inte tilldelats';
	static TaskReport = 'Uppgiftsrapport';
	static DeletingWhistleBlowCategoryParameterWasSuccessful = 'Visselslagskategori \'{0}\' har tagits bort!';
	static AddDefaultTaskPriority = 'Lägg till standarduppgiftsprioritet';
	static BasicEducationsMustBeDefinedBeforeAddingRecordUsers = 'Grundutbildningar måste definieras innan användarposter läggs till!';
	static PrintTextPrinted = 'Tryckt';
	static Public = 'offentlig';
	static ByDate = 'Efter datum';
	static EditEmployment = 'Redigera anställning';
	static RecordUserFurtherEducationParameterNotFound = 'Användarpostens utbildningspost \'{0}\' hittades inte!';
	static RowInformation = 'Radinformation';
	static WhistleBlowStateResolved = 'Löst';
	static EventType = 'Kategori 1';
	static ContactPerson = 'Kontaktperson';
	static LastNameMustBeDefined = 'Efternamn måste definieras!';
	static RemoveEditableDocument = 'Ta bort dokument';
	static Clear = 'Klar';
	static DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort kategorierna "{0}" eftersom data i kategorierna för visselslag har ändrats samtidigt av en annan användare!';
	static AddEventTypeCsToEvent = 'Lägg till kategori 3 genom att trycka på plusknappen.';
	static TheUserDoesNotHavePermissionsForThisSystem = 'Användaren har inte behörighet att använda detta system!';
	static Task = 'Uppgift';
	static Units = 'Enheter';
	static MessageTypeClass = 'Meddelandeklass';
	static AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till hanterare! Fel: \'{0}\'';
	static ContentVersion = 'Innehållsversion';
	static SavingRecordUserParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara användarposten \'{0}\'! Fel: \'{1}\'';
	static ImageOrPdfUpToParameterMb = 'Bild- eller PDF-format, upp till {0} MB';
	static DefaultEventSettings = 'Standardinställningar för incidenter';
	static RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Vidareutbildning \'{0}\' används i {1} användarposter och kan inte tas bort!';
	static ChartGenerationFailed = 'Det gick inte att skapa diagram!';
	static TaskRepetitionType = 'Upprepning av uppgift';
	static PositionErrorTimeout = 'Tidsgräns för läsning av platsinformation.';
	static MoneyIn = 'Pengar in';
	static CreateReport = 'Skapa rapport';
	static DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort standardincidenthanteraren \'{0}\'! Fel: \'{1}\'';
	static TaskDataRows = 'Rader';
	static FurtherEducations = 'Vidareutbildningar';
	static EventApprovalWasNotSuccessfulConcurrencyError = 'Det gick inte att godkänna incidenten eftersom hanteraren har ändrats samtidigt av en annan användare!';
	static TransferredProperties = 'Överlåtna fastigheter';
	static WhistleBlowInformantInstuctions = 'Informantinstruktioner';
	static Phone = 'Telefon';
	static Approval = 'Godkännande';
	static AddInstantMessageUserParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till deltagare \'{0}\'! Fel: \'{1}\'';
	static LanguageSwedish = 'svenska';
	static FileSynchronizationToDatabaseStarted = 'Filsystemsynkronisering med databas startade';
	static DoYouReallyWantToRemoveInstantMessageChannelParameter = 'Vill du verkligen ta bort chatten "{0}"?';
	static UserParameterNotFound = 'Användaren \'{0}\' hittades inte!';
	static RecordEmailNotificationShowExpired = 'Inkludera utgångna utbildningar';
	static SomeWhistleBlowHandlersHaveReadMessage = 'Vissa handläggare av whistleblow-rapporter har läst ditt meddelande';
	static ArchivingWhistleBlowsParameterWasSuccessful = 'Visselslagsrapporter \'{0}\' har arkiverats framgångsrikt!';
	static TargetDoesNotHaveFoldersWithWritePermissions = 'Target har inga mappar med skrivbehörighet!';
	static RemoveNotifiedPerson = 'Ta bort person som ska meddelas';
	static FileParameterIsEmpty = 'Filen \'{0}\' är tom!';
	static WhistleBlowEventTypeMessageAdded = 'Meddelande har lagts till';
	static AccessRightWriteRecords = 'Alla enheters användarregister';
	static WhistleBlowDoesNotContainAnyMessage = 'Inga meddelanden skrivs för att rapportera om whistle blow.';
	static NewCompanyInformation = 'Nytt företag';
	static SaveToTemplate = 'Spara som mall';
	static DeletingTaskParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort uppgiften \'{0}\'! Fel: \'{1}\'';
	static PublicationOfEditableDocumentCannotBeRemoved = 'Publicering av dokumentet kan inte tas bort!';
	static MinimumPasswordLength = 'Minsta lösenordslängd';
	static SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter = 'Det gick inte att godkänna dokumentet \'{0}\'! Fel: \'{1}\'';
	static DeletingInstantMessageWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort snabbmeddelanden! Fel: \'{0}\'';
	static LastYear = 'Förra året';
	static EventSettings = 'Incidentinställningar';
	static AnyTime = 'När som helst';
	static NoEmailsToSend = 'Inga mail att skickas!';
	static GroupByHalfYear = 'Gruppera efter halvår';
	static NextOfKin = 'Närmast anhörig';
	static UploadDocument = 'Ladda upp fil';
	static AddPhotosAndAttachementsToEvent = 'Lägg till foto eller bilaga genom att trycka på kameran eller plusknapparna.';
	static SavingDirectoryItemDocumentParameterWasSuccessful = 'Filen \'{0}\' har sparats!';
	static PageNameMustBeDefined = 'Sidnamn måste definieras!';
	static SelectRecordUserTypeToBeCreated = 'Välj användarposttyp för ny post';
	static AdditionalPermissionAInstantMessages = 'Tillståndshantering';
	static NoAddedEventTypes = 'Inga tillagda kategorier!';
	static WhistleBlow = 'Whistleblower';
	static IncludeArchivedFurtherEducations = 'Inkludera arkiverade vidareutbildningar';
	static ValidityEndDateExpirationTime = 'Giltighet Utgångstid';
	static Education = 'Utbildning';
	static Attachments = 'Bilagor';
	static SavingEventMessageWasSuccessful = 'Kommentaren har sparats!';
	static Months = 'månader';
	static WhistleBlowDescription = 'Incidentbeskrivning';
	static WhistleBlowResolutionTypeMustBeDefined = 'Upplösning måste definieras! Du kan också ange upplösningsbeskrivning.';
	static NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded = 'Ny rapport måste sparas innan rader kan läggas till!';
	static AddUserParameterWasSuccessful = 'Användarkontot \'{0}\' har lagts till!';
	static OldPassword = 'Gammalt lösenord';
	static IncludeOnlyActiveUsers = 'Inkludera endast användare med aktivt användarkonto och nuvarande arbetsplats';
	static CopyOrMoveFolder = 'Kopiera eller flytta mapp';
	static FurtherEducation = 'Vidare utbildning';
	static RemoveDefaultTaskState = 'Ta bort standarduppgiftsstatus';
	static ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter = 'Det gick inte att arkivera ytterligare bekantskaper! Fel: \'{0}\'';
	static WhistleBlowChannelPageNameCodeIsAlreadyInUse = 'Visselblåsningschanel-sidans namn används redan!';
	static SavingUnitsWasNotSuccessfulConcurrencyError = 'Det gick inte att spara enheter eftersom data i enheter har ändrats samtidigt av en annan användare!';
	static Banner = 'Baner';
	static PreventiveAction = 'Förebyggande åtgärd';
	static SetUnitPermissions = 'Ställ in enhetsbehörigheter';
	static InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter = 'Användaren \'{0}\' har inte behörighet att använda chatten \'{1}\'!';
	static Total = 'Total';
	static RecordUserLogEntryTypePrint = 'Utskrift';
	static RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort användargrupplänkar från externa system, eftersom data har ändrats samtidigt av en annan användare!';
	static SavingEventTypeBsWasNotSuccessfulErrorParameter = 'Det gick inte att spara kategori 2! Fel: \'{0}\'';
	static RecordUserParameterNotFound = 'Användarposten \'{0}\' hittades inte!';
	static SourceDescription = 'Källbeskrivning';
	static ParameterResults = '({0} resultat)';
	static Camera = 'Kamera';
	static UserNotAllowedToViewNotifier = 'Begränsad behandlingsrätt: Användaren har inte rätt att se anmälaren';
	static EmployeeTypeMustBeDefined = 'Anställningsform måste definieras!';
	static DeletingMessageParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort meddelandet \'{0}\' eftersom data i meddelandet har ändrats samtidigt av en annan användare!';
	static DeletingUserGroupsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort användargrupperna \'{0}\'! Fel: \'{1}\'';
	static ExpiredFamiliarizations = 'Utgångna bekantskaper';
	static AllUserGroups = 'Alla användargrupper';
	static SavingRecordUserParameterWasSuccessful = 'Användarposten \'{0}\' har sparats!';
	static EmailAddress = 'E-post';
	static IncludeArchivedFamiliarizations = 'Inkludera arkiverade bekantskaper';
	static TaskMustBeSavedBeforeNewDataRowsCanBeAdded = 'Uppgiften måste sparas innan nya rader kan läggas till!';
	static RecordUserTrangerredPropertyParameterNotFound = 'Den överförda egenskapen \'{0}\' hittades inte!';
	static RemoveTaskState = 'Ta bort Task State';
	static AddingAttachmentsParameterWasSuccessful = 'Bilagorna "{0}" har lagts till!';
	static PasswordConfirmationDoesNotMatchPassword = 'Lösenordet matchar inte bekräftelselösenordet!';
	static DeletingFileFilterParameterWasSuccessful = 'Filfiltret \'{0}\' har tagits bort!';
	static RemovePayer = 'Ta bort betalare';
	static NoneSelected = 'Ingen vald';
	static UpdateUserParameterExternalSystemsWasSuccessful = 'Användaren \'{0}\' har länkats till externa system!';
	static SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara uppgiftsprioritet \'{0}\', eftersom data i uppgiftsprioriteten har ändrats samtidigt av en annan användare!';
	static DefaultBasicEducations = 'Grundläggande utbildningar';
	static TiedostoturvaUseType = 'Tiedostoturva';
	static OptionCodeIsAlreadyInUse = 'Systemalternativkoden \'{0}\' används redan!';
	static ConfirmPassword = 'Bekräfta lösenord';
	static Repeated = 'Upprepad';
	static RemoveComment = 'Ta bort kommentar';
	static ActivePlural = 'Aktiva';
	static MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB = 'Den maximala totala storleken på bilagor som kan läggas till i batch är {0} MB!';
	static InstantMessageUserInvitationAcceptTypeCannotInvite = 'Jag kan inte bli inbjuden att gå med i chatten';
	static SavingUnitsWasSuccessful = 'Enheter har sparats!';
	static DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort dokumentmallen \'{0}\'! Fel: \'{1}\'';
	static Sending = 'Sändning...';
	static UserIsActiveInMobile = 'Användarkontot är aktivt i mobilapplikationen.';
	static Search = 'Sök';
	static FolderMustBeEmptyBeforeItCanBeRemoved = 'Mappen måste vara tom innan den kan tas bort!';
	static Approve = 'Godkänna';
	static EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Kategori 3 "{0}" underkategorier används i {1} incidenter och kategori 3 kan inte tas bort!';
	static FileSynchronizationToDatabaseFinishedInParameterSeconds = 'Filsystemsynkronisering med databas slutförd (varaktighet: {0:N0}s, fel: {1}, mappar: {2}, mappar utan fel: {3})';
	static DefaultEventHandlersAndNotifiedPersons = 'Standardincidenthanterare och meddelanden';
	static YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType = 'Du har inte sparat ändringar som du har gjort. Spara ändringar innan du publicerar uppgiftstypen.';
	static AdditionalInformation = 'ytterligare information';
	static AddingTaskTypeParameterWasSuccessful = 'Uppgiftstypen \'{0}\' har lagts till!';
	static DefaultFurtherEducations = 'Default Vidareutbildningar';
	static AddDefaultTaskType = 'Lägg till standarduppgiftstyp';
	static EditLogisticVoucherCustomer = 'Redigera kund';
	static DeletingUserGroupParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort användargruppen \'{0}\'! Fel: \'{1}\'';
	static RemoveDocument = 'Ta bort markerade filer/dokument';
	static InstantMessageChannelUsersNotUsable = 'Användaren tillhör inte längre chattdeltagare';
	static DoYouReallyWantToRemovePartnerParameter = 'Vill du verkligen ta bort partner \'{0}\'?';
	static SavingPrintWasSuccessful = 'Utskriften har sparats!';
	static AccountNumberTkbOutCredit = 'Handelskonto';
	static DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort vidareutbildning \'{0}\'! Fel: \'{1}\'';
	static Title = 'Titel';
	static ApprovalComment = 'Godkännandekommentar';
	static NewCompanyMustBeSavedBeforePartnersCanBeAdded = 'Nytt företag måste sparas innan partner kan läggas till!';
	static DeletingEditableDocumentParameterWasSuccessful = 'Dokumentet "{0}" har tagits bort!';
	static EditDefaultTaskPriority = 'Redigera standarduppgiftsprioritet';
	static ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers = 'Användarändringar kommer att leda till situationer där vissa standardincidenthanterare eller meddelade personer inte har behörighet att hantera incidenter med enheter. Ändringar som påverkar standardincidenthanterare eller meddelade personer kan endast göras i dialogrutan "Incidentegenskaper"!';
	static VersionHistory = 'Versionshistorik';
	static EditDefaultEventType = 'Redigera standardkategori 1';
	static EditDefaultTaskType = 'Redigera standarduppgiftstyp';
	static PageName = 'Sidnamn';
	static EditLogisticVoucherCompanyPaymentSetting = 'Redigera betalningsinställning';
	static AddingTaskTypesParameterWasSuccessful = 'Uppgiftstyperna \'{0}\' har lagts till!';
	static WhistleBlowChannelSettings = 'Inställningar för whistle Blow-kanal';
	static FileNameMustBeDefined = 'Filnamn måste definieras!';
	static EInvoiceAddress = 'E-faktura adress';
	static SavingDefaultHandlersWasNotSuccessfulConcurrencyError = 'Det gick inte att spara standardhanterare eftersom standardhanterare har ändrats samtidigt av en annan användare!';
	static AddDefaultEventHandler = 'Lägg till standardincidenthanterare';
	static LockedDocumentsParameterCannotBeMoved = 'Låsta dokument "{0}" kan inte flyttas!';
	static OptionParameterNotFound = 'Systemalternativet \'{0}\' hittades inte!';
	static MessageUnit = 'Förlagsenhet';
	static CommentMustNotBeEmpty = 'Kommentar får inte vara tom!';
	static AddFileOrTakePicture = 'Lägg till en fil eller ta en bild';
	static WhistleBlowResolutionJunk = 'Skräppost';
	static Loading = 'Läser in...';
	static PrintRow = 'Skriv ut rad';
	static Logo = 'Logotyp';
	static DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort användargruppsanslutningar relaterade till företaget \'{0}\', eftersom data i dessa användargruppanslutningar har ändrats samtidigt av en annan användare!';
	static Summary = 'Sammanfattning';
	static DeletingTaskParameterWasSuccessful = 'Uppgiften \'{0}\' har tagits bort!';
	static Communication = 'Kommunikation';
	static DataTransferError = 'Dataöverföringsfel!';
	static AddCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till företagsgruppen \'{0}\'! Fel: \'{1}\'';
	static ValidityEndDateStart = 'Giltighetsslutdatum - Start';
	static DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort anställningen \'{0}\', eftersom data i anställningen har ändrats samtidigt av en annan användare!';
	static ExpiringIn180Days = 'Inkludera utbildningar som löper ut om 180 dagar';
	static LoggedInCompanyCannotBeDeleted = 'Företaget kan inte ta bort sig själv!';
	static LogSizeInDays = 'Loggstorlek i dagar (0 = ingen gräns)';
	static RemoveUserAndRecordUserArchive = 'Ta bort användarkonto och arkivera användarpost';
	static UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError = 'Det gick inte att länka användaren \'{0}\' till externa system, eftersom data har ändrats samtidigt av en annan användare!';
	static Warning = 'Varning';
	static Employments = 'Anställningar';
	static LoginAddress = 'Inloggningsadress';
	static LoginTitle = 'Logga in på PKY-LAATU Intra';
	static OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater = 'Endast bild- och pdf-bilagor är tillåtna! Bilagorna "{0}" är ogiltiga.';
	static LockedTaskDataRowCannotBeDeleted = 'Låst uppgiftsrad kan inte tas bort!';
	static ReportGenerationAborted = 'Rapportskapandet avbröts!';
	static DeletingEventParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort incidenten \'{0}\' eftersom data i incidenten har ändrats samtidigt av en annan användare!';
	static EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Kategori 3 \'{0}\' används i {1} incidenter och kan inte tas bort!';
	static PanelSize = 'Panelstorlek';
	static ApproveEvent = 'Godkänn event';
	static RecordUserExcelUserList = 'Personal';
	static AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till överförda egenskaper! Fel: \'{0}\'';
	static InstantMessageParameterNotFound = 'Snabbmeddelandet \'{0}\' hittades inte!';
	static AddBasicEducation = 'Lägg till grundutbildning';
	static JobTypeMessageNotification = 'Meddelandemeddelande';
	static SendingEmails = 'Skickar e-postmeddelanden...';
	static CopyingFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att kopiera mappen \'{0}\' till mappen \'{1}\' i filsystemet! Fel: \'{2}\'';
	static DeletingRecordBasicEducationParameterWasSuccessful = 'Grundutbildningen \'{0}\' har tagits bort!';
	static DocumentVersionParameterNotFound = 'Dokumentversion \'{0}\' hittades inte!';
	static AddWhistleBlowCategory = 'Lägg till kategori för Whistle Blow';
	static RemoveInstantMessageChannelUser = 'Ta bort deltagare';
	static SavingImagesWasSuccessful = 'Bilderna har sparats!';
	static RecordFamiliarizationCodeIsAlreadyInUse = 'Bekantskapsnamnet används redan!';
	static SavingEventTypeCParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara kategori 3 \'{0}\'! Fel: \'{1}\'';
	static TargetsMyCompany = 'Synlighet - Mitt företag';
	static UpdatingApplication = 'Uppdaterar app...';
	static RecordFurtherEducationCodeIsAlreadyInUse = 'Vidareutbildningens namn används redan!';
	static AddRecordUserAndUserAccount = 'Lägg till användarpost och nytt användarkonto';
	static EventTypeCTitle = 'Incidentidentifieringskategori 3';
	static TaskStateCodeIsAlreadyInUse = 'Namnet på uppgiftens tillstånd används redan!';
	static Anonymity = 'Anonymitet';
	static AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till kvalifikationer! Fel: \'{0}\'';
	static InstantMessageChannelInformation = 'Chattinformation';
	static DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort deltagaren \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att lägga till företagsgrupper "{0}", eftersom data har ändrats samtidigt av en annan användare!';
	static RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter = 'Det gick inte att låsa upp posten \'{0}\'! Posten är skrivlåst till användaren \'{0}\'!';
	static UnitProperties = 'Enhetsegenskaper';
	static JobDataIsEmpty = 'Jobbdata saknas!';
	static Amount = 'Belopp';
	static WhistleBlowDateAndTimeCannotBeInFuture = 'Incident tid kan inte vara i framtiden!';
	static SavingTaskTypeParameterWasSuccessful = 'Uppgiftstypen \'{0}\' har sparats!';
	static PasswordMustBeDefined = 'Lösenord måste definieras!';
	static TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Uppgiftsstatus \'{0}\' används i {1} uppgifter och kan inte tas bort!';
	static UserAccountConnected = 'Användarkonto anslutet';
	static YouHaveUnapprovedChangesDoYouWantApproveChanges = 'Du har inte godkänt ändringar som du har gjort. Vill du godkänna ändringar?';
	static DeletingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort uppgiftstypen \'{0}\'! Fel: \'{1}\'';
	static EventReceiverIsPartnerCompany = 'Incidentmottagare är partnerföretag';
	static ProcessingTimeDays = 'Behandlingstid (d)';
	static ClosedPlural = 'Stängd';
	static AdditionalPermissionBEventsDialog = 'Ta bort incidenten';
	static PublishVoucherInOutReportToFileSystem = 'Publicera kupongsammanfattning Skriv ut till filsystem';
	static AddInstantMessageUsersParameterWasSuccessful = 'Deltagarna "{0}" har lagts till!';
	static AdminMustBeDefinedForSectionParameter = 'Administratör måste definieras för avsnittet \'{0}\'!';
	static SavingMessageParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara meddelandet \'{0}\' eftersom data i meddelandet har ändrats samtidigt av en annan användare!';
	static RecordUserActiveStateChangePermissionDeniedInUnitParameter = 'Arkiverad tillståndsändring är inte tillåten i enheten \'{0}\'!';
	static DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort bekantskapen \'{0}\'! Fel: \'{1}\'';
	static Year = 'År';
	static CourseCredits = 'Kurspoäng';
	static SelectUserGroup = 'Välj Användargrupp';
	static DeletingDirectoryItemFolderParameterWasSuccessful = 'Mappen \'{0}\' har tagits bort!';
	static SelectTaskType = 'Välj Uppgiftstyp';
	static DocumentTemplateCodeMustBeDefined = 'Dokumentmallskod måste definieras!';
	static ServiceFeePercent = 'Serviceavgift (%)';
	static SearchFurtherEducations = 'Sök vidareutbildningar';
	static PermissionDeniedForOperation = 'Tillstånd nekad för drift!';
	static DeletingRecordQualificationParameterWasSuccessful = 'Kvalifikationen \'{0}\' har tagits bort!';
	static Preview = 'Förhandsvisning';
	static UpdatingDatabase = 'Uppdaterar databas...';
	static Restore = 'Återställ';
	static SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara bekantskapen \'{0}\', eftersom data i bekantskapen har ändrats samtidigt av en annan användare!';
	static UserName = 'Användarnamn';
	static TaskTypeNameMustBeDefined = 'Uppgiftstypsnamn måste definieras!';
	static SavingEventTypeCParameterWasSuccessful = 'Kategori 3 \'{0}\' har sparats!';
	static RequestValidationErrorOnFieldParameterWithValueParameter = 'Värdet \'{1}\' i fältet \'{0}\' innehåller innehåll som anses vara farligt i webbapplikationer!';
	static WhistleBlowMessageHasNotBeenSentCloseConfirmation = 'Ett oavslutat meddelande har inte skickats ännu. Vill du stänga vyn?';
	static SelectCustomer = 'Välj Kund';
	static Payer = 'Betalare';
	static EventTypeCParameterNotFound = 'Kategori 3 \'{0}\' hittades inte!';
	static ExternalSystemUserTokenParameterIsAlreadyInUse = 'Användartoken \'{0}\' används redan!';
	static CreatingFileParameterWasNotSuccessfulErrorParameter = 'Det gick inte att skapa filen \'{0}\' i filsystemet! Fel: \'{1}\'';
	static AccessRightWriteInstantMessages = 'Företagsintern och intercompany-chatt';
	static Description = 'Beskrivning';
	static DocumentPublished = 'Publicerad av';
	static AddUnit = 'Lägg till enhet';
	static EditableDocumentVersionMustBeDefined = 'Dokumentets versionsnummer måste definieras!';
	static AttachmentIsEmpty = 'Bilaga \'{0}\' är tom!';
	static FolderNameIsTooLong = 'Mappnamnet \'{0}\' är för långt!';
	static Time = 'Tid';
	static CopyPermissionToAllSameLevelUnits = 'Kopiera tillstånd till alla enheter på samma nivå';
	static Payee = 'Betalningsmottagare';
	static SelectEventTypeB = 'Välj kategori 2';
	static SelectCompanyGroupOrCompany = 'Välj Företagsgrupp eller Företag';
	static Message = 'Meddelande';
	static TaskCreated = 'Skapad av';
	static EmploymentEndDateCannotPrecedeEmploymentStartDate = 'Anställningens slutdatum kan inte föregå anställningens startdatum!';
	static DoYouReallyWantToUnlockEditableDocument = 'Vill du verkligen låsa upp dokumentet \'{0}\'?';
	static NoImagesToShow = 'Inga bilder att visa';
	static WhistleBlowGuide = 'Guide för rapportering';
	static TaskModuleProperties = 'Uppgifter Egenskaper';
	static TrashBin = 'Soptunna';
	static TimeSpanTwoYears = 'Två år';
	static ExternalsSystemParameterNotFound = 'Det externa systemet \'{0}\' hittades inte!';
	static SavingJobParameterWasSuccessful = 'Jobbet \'{0}\' har sparats!';
	static TypeMustBeDefined = 'Typ måste definieras!';
	static TransferredPropertyType = 'Överlåten egenskapstyp';
	static SaveUserFurtherEducationConfirmation = 'Vill du spara vidareutbildningen?';
	static AddUserGroupsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till användargrupper "{0}"! Fel: \'{1}\'';
	static EmploymentStartDate = 'Anställningens startdatum';
	static RemoveSharedDocumentTemplate = 'Ta bort mall för delat dokument';
	static DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords = 'Notera! Om du minskar storleken på versionshistoriken tas tidigare sparade versioner bort från versionshistoriken.';
	static SavingEventDefaultHandlerParameterWasSuccessful = 'Standardincidenthanteraren \'{0}\' har sparats!';
	static NewInstantMessageChannel = 'Ny chatt';
	static WhistleBlowReportHasBeenSavedTopText = 'Lagra under lösenordet i säker lagring. Lösenorden ger dig tillgång till din rapport. Dela inte lösenord med någon!';
	static Days = 'dagar';
	static DeletingInstantMessageUsersParameterWasSuccessful = 'Deltagarna "{0}" har tagits bort!';
	static CopyDocumentsFromCompany = 'Kopiera dokument från detta företag';
	static RemoveUser = 'Ta bort användarkonto';
	static SavingSettingsWasSuccessful = 'Inställningarna har sparats!';
	static EventHandlerParameterNotFound = 'Hanteraren \'{0}\' hittades inte!';
	static ApplicationTempPathParameterNotFound = 'Tillämpningsmappen \'{0}\' hittades inte!';
	static DeletingEventTypeParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort kategori 1 \'{0}\' eftersom data i kategori 1 har ändrats samtidigt av en annan användare!';
	static MessageTypeParameterNotFound = 'Meddelandetypen \'{0}\' hittades inte!';
	static SSRSRefreshStarted = 'SSRS-uppdatering startade';
	static ValueAddedTaxPercent = 'Mervärdesskatt %';
	static MaximumPasswordLengthCanParameterCharacters = 'Maximal lösenordslängd kan vara {0} tecken!';
	static DeletingPartnersParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort partners{0}! Fel: \'{1}\'';
	static DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort administratören \'{0}\'! Fel: \'{1}\'';
	static FileParameterNotFound = 'Filen \'{0}\' hittades inte!';
	static InstantMessageChannelOneUser = 'Chatt';
	static DeletingWhistleBlowChannelAdminParameterWasSuccessful = 'Administratören \'{0}\' har tagits bort!';
	static TaskPrioritiesMustBeDefinedBeforeAddingTasks = 'Uppgiftsprioriteringar måste definieras innan du lägger till uppgifter!';
	static LogModuleProperties = 'Loggar egenskaper';
	static EditableDocumentPublishUnapprovedConfirmation = 'Vill du publicera ett icke godkänt dokument till fil?';
	static SelectEventHandler = 'Välj Hanterare';
	static TargetCompany = 'Målföretag';
	static ChangeSystemLanguageToSwedish = 'Byt språk till svenska';
	static Group = 'Grupp';
	static MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB = 'Den maximala totala storleken på dokument som kan läggas till i batch är {0} MB!';
	static RecordEmailNotificationType = 'Jag vill få rapport om vidareutbildningar (Utgångsutbildningar)';
	static SelectArchivingMethod = 'Välj Arkiveringsmetod';
	static InstantMessageChannel = 'Chatt';
	static DeletingAttachmentParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort bilagan \'{0}\'! Fel: \'{1}\'';
	static RemoveRecordUserAndUser = 'Ta bort användarpost och användarkonto';
	static Company = 'Företag';
	static AddEventMessage = 'Lägg till kommentar';
	static SavingTaskPriorityParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara uppgiftsprioritet \'{0}\'! Fel: \'{1}\'';
	static DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter = 'Vill du verkligen ta bort den överförda egenskapen \'{0}\'?';
	static FirstNameMustBeDefined = 'Förnamn måste definieras!';
	static EditWhistleBlowChannel = 'Redigera Whistle Blow Channel';
	static LogLine = 'Logglinje';
	static RenamingFileParameterWasNotSuccessfulErrorParameter = 'Det gick inte att byta namn på filen (\'{0}\' => \'{1}\') i filsystemet! Fel: \'{2}\'';
	static AddPartners = 'Lägg till partners';
	static SenderOrReceiver = 'Avsändare eller mottagare';
	static AddFamiliarization = 'Lägg till ytterligare bekantskap';
	static PreviewPanel = 'Förhandsvisning';
	static DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort bekantskapen \'{0}\', eftersom data i bekantskapen har ändrats samtidigt av en annan användare!';
	static ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers = 'Ändringar av användargrupp kommer att leda till situationer där vissa standardincidenthanterare eller meddelade personer inte har behörighet att hantera incidenter med enheter. Ändringar som påverkar standardincidenthanterare eller meddelade personer kan endast göras i dialogrutan "Incidentegenskaper"!';
	static EditFamiliarization = 'Redigera bekantskap';
	static RemoveTaskHandler = 'Ta bort hanteraren';
	static BirthDate = 'Födelsedatum';
	static No = 'Nej';
	static AddTaskPriority = 'Lägg till uppgiftsprioritet';
	static RecordUserExcelFurtherEducation = 'Vidareutbildningar';
	static RemoveEventPartner = 'Ta bort Incident Partner';
	static Documents = 'Dokument';
	static Comments = 'Kommentarer';
	static Go = 'Gå';
	static DeletingRecordUserParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort användarposten "{0}", eftersom data i användarposten har ändrats samtidigt av en annan användare!';
	static Sender = 'Avsändare';
	static Close = 'Stänga';
	static CurrentTopics = 'Aktuella ämnen';
	static DeletingUserParameterWasSuccessful = 'Användarkontot \'{0}\' har tagits bort!';
	static CopyingFileParameterWasNotSuccessfulErrorParameter = 'Det gick inte att kopiera filen \'{0}\' som filen \'{1}\' i filsystemet! Fel: \'{2}\'';
	static CopyOrMoveFilesDocuments = 'Kopiera eller flytta valda filer/dokument';
	static RemoveFurtherEducation = 'Ta bort vidareutbildning';
	static PrintGenerationFailed = 'Det gick inte att skapa utskrift!';
	static SavingTaskTypeParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara uppgiftstypen \'{0}\', eftersom data i uppgiftstypen har ändrats samtidigt av en annan användare!';
	static RemoveUnit = 'Ta bort enheten';
	static SortOrder = 'Sorteringsordning';
	static FamiliarizationStartDateStart = 'Bekantskap Startdatum - Start';
	static RecordUserWithSameNameAlreadyExists = 'Användarpost med samma namn finns redan!';
	static RemoveAttachment = 'Ta bort bilaga';
	static JobTypePushNotification = 'Push Notification';
	static AddComment = 'Lägg till kommentar';
	static EventModuleProperties = 'Incidenter Egenskaper';
	static AccessRightReadWhistleBlow = 'Begränsad rapporthantering';
	static DoYouReallyWantToRemoveSelectedTaskPriorities = 'Vill du verkligen ta bort valda uppgiftsprioriteringar?';
	static NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded = 'Ny bekantskap måste sparas innan bilagor kan läggas till!';
	static SelectImageFile = 'Välj bildfil!';
	static AddCompanyGroupsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till företagsgrupper \'{0}\'! Fel: \'{1}\'';
	static DeletingInstantMessageChannelWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort chatten! Fel: \'{0}\'';
	static Created = 'Skapad av';
	static WhistleBlowFrontPage = 'Framsida';
	static Published = 'Publicerad';
	static CompanyGroups = 'Företagsgrupper';
	static VersionParameterPublishedAsPdfFile = 'Version {0} publiceras som pdf-fil';
	static ModifiedDescription = 'Ändrad beskrivning';
	static TheUserNameOrPasswordIsIncorrect = 'Ogiltigt användarnamn eller lösenord!';
	static DeletingEventUrgencyClassParameterWasSuccessful = 'Brådskande klass \'{0}\' har tagits bort!';
	static DeletingEventParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort incidenten \'{0}\'! Fel: \'{1}\'';
	static DoYouReallyWantToRemoveEventParameter = 'Vill du verkligen ta bort incidenten \'{0}\'?';
	static MyReports = 'Användarens rapporter';
	static IndefinitelyNoLocalization = 'Obegränsat';
	static ExternalSystems = 'Externa system';
	static SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError = 'Det gick inte att spara mappbehörigheter eftersom mappar har ändrats samtidigt av en annan användare!';
	static Wage = 'Lön';
	static Publisher = 'Publicerad av';
	static Previous = 'Tidigare';
	static DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort meddelandetypen \'{0}\', eftersom data i meddelandetypen har ändrats samtidigt av en annan användare!';
	static EmailSent = 'Email skickat';
	static WhistleBlowInformantMustBeNotifiedLatestAtParameter = 'Visselslagsrapport måste markeras som löst {0} senast!';
	static RemoveTransferredProperty = 'Ta bort överförd egendom';
	static SavingMessageSendPushParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara meddelandets push-aviseringslogg \'{0}\'! Fel: \'{1}\'';
	static Month = 'Månad';
	static DoYouReallyWantToSaveData = 'Vill du verkligen spara?';
	static UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError = 'Det gick inte att länka användargruppen \'{0}\' till externa system, eftersom data har ändrats samtidigt av en annan användare!';
	static NoPublishedMessages = 'Inga publicerade meddelanden';
	static Subject = 'Ämne';
	static WhistleBlowUrlInfo = 'Endast systemadministratören kan ändra adressen';
	static RemovePartner = 'Ta bort partner';
	static FileParameterAlreadyExistsInFileSystem = 'Filen \'{0}\' finns redan i filsystemet!';
	static SavingUserParameterWasSuccessful = 'Användaren \'{0}\' har sparats!';
	static LogDocumentPreview = 'Logga dokumentförhandsgranskning';
	static WhistleBlowActiveReports = 'Aktiva rapporter';
	static WhistleBlowApplicationName = 'Whistle Blow Channel';
	static DeletingTaskStateParameterWasSuccessful = 'Uppgiftstillstånd \'{0}\' har tagits bort!';
	static FileFilterNameMustBeDefined = 'Filfilternamn måste definieras!';
	static EventHandlers = 'Handlare';
	static WhistleBlowResolutionUnspecified = 'Inte löst';
	static DoYouReallyWantToRemoveRecordUserParameter = 'Vill du verkligen ta bort användarposten "{0}"?';
	static RecordFamiliarizationParameterNotFound = 'Bekantskap \'{0}\' hittades inte!';
	static TaskStateCodeMustBeDefined = 'Namnet på uppgiftens tillstånd måste definieras!';
	static SaveInstantMessageChannel = 'Spara Chatt';
	static PasswordHasBeenCopiedToClipboard = 'Lösenordet har kopierats till urklipp!';
	static GeneralInfo = 'Allmän';
	static EventIsNotApprovedByUser = 'Användaren har inte godkänt händelsen';
	static EditFurtherEducation = 'Redigera Vidareutbildning';
	static SavingTaskParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara uppgiften \'{0}\'! Fel: \'{1}\'';
	static SavingDirectoryItemFolderParameterWasSuccessful = 'Mappen \'{0}\' har sparats!';
	static InstantMessagesMenuTitle = 'HoxUp';
	static FurtherEducationParameterNotFound = 'Vidareutbildning \'{0}\' hittades inte!';
	static DoYouReallyWantToRemoveUserParameter = 'Vill du verkligen ta bort användaren \'{0}\'?';
	static NoFurtherEducationsToShow = 'Inga vidareutbildningar att visa!';
	static EditLogisticVoucherTransportCompany = 'Redigera Transportföretag';
	static OperationNotAllowedForRootFolderParameter = 'Operation är inte tillåten för rotmappen \'{0}\'!';
	static SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara mappen \'{0}\' eftersom data i mappen har ändrats samtidigt av en annan användare!';
	static SavingTaskStateParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara uppgiftens status \'{0}\'! Fel: \'{1}\'';
	static DeletingRecordFamiliarizationParameterWasSuccessful = 'Bekantskapen \'{0}\' har tagits bort!';
	static AddingAttachmentsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till bilagor \'{0}\'! Fel: \'{1}\'';
	static NotSet = 'Inte inställd';
	static WhistleBlowResolutionRejected = 'avvisade';
	static DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort uppgiftsprioriteterna "{0}", eftersom data i uppgiftsprioriteterna har ändrats samtidigt av en annan användare!';
	static ArchivingRecordUserFamiliarizationsWasSuccessful = 'Bekantskaper arkiverade framgångsrikt!';
	static AdditionalSettings = 'Ytterligare inställningar';
	static PublishVersion = 'Publicera version';
	static PostalCode = 'postnummer';
	static DeletingUserParametersWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort användarparametrar! Fel: \'{0}\'';
	static ResolutionDescription = 'Beskrivning av upplösning';
	static DeletingEventUrgencyClassesParameterWasSuccessful = 'Brådskande klasser \'{0}\' har tagits bort!';
	static NoNotificationsToSend = 'Inga aviseringar ska skickas!';
	static SuccessAudit = 'SuccessAudit';
	static DeletingPartnerParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort partnern \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static SavingOptionParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara alternativet \'{0}\'! Fel: \'{1}\'';
	static CompanyNameMustBeDefined = 'Företagets namn måste anges!';
	static DoYouReallyWantToRemoveRecordFurtherEducationParameter = 'Vill du verkligen ta bort vidareutbildning "{0}"?';
	static NewPasswordMustBeDefined = 'Nytt lösenord måste definieras!';
	static InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted = 'Chatten har andra deltagare och kan inte tas bort!';
	static AddEventPartner = 'Lägg till Incident Partner';
	static AddPartnerParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till partner \'{0}\'! Fel: \'{1}\'';
	static GeneralAttachments = 'Allmänna bilagor';
	static Selected = 'Vald';
	static DescriptionMustBeDefined = 'Beskrivning måste definieras!';
	static CompanyGroup = 'Företagsgrupp';
	static MoveTaskToTrashBin = 'Flytta uppgift till papperskorgen';
	static SavingApplicationPermissionsWasNotSuccessfulConcurrencyError = 'Det gick inte att spara systembehörigheter eftersom data i systembehörigheter har ändrats samtidigt av en annan användare!';
	static List = 'Lista';
	static DeletingTaskTypeParameterWasSuccessful = 'Uppgiftstypen \'{0}\' har tagits bort!';
	static NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded = 'Ny överlåten egendom måste sparas innan bilagor kan läggas till!';
	static Header = 'Rubrik';
	static EventHandlersExcelTitle = 'Hanterare (x = godkänd)';
	static Start = 'Start';
	static AddingAddRecordUserQualificationsWasSuccessful = 'Kvalifikationer har lagts till!';
	static CompanyModuleProperties = 'Företagsfastigheter';
	static DocumentRootPathIsNotEmpty = 'Dokumentets rotsökväg är inte tom mapp!';
	static EventTypeCs = 'Kategori 3';
	static Remainder = 'Återstoden';
	static NoFilesToAdd = 'Inga filer att lägga till!';
	static SavingRecordFurtherEducationParameterWasSuccessful = 'Vidareutbildning \'{0}\' har sparats!';
	static VoucherSavePath = 'Voucher Spara sökväg';
	static AddTransferredProperty = 'Lägg till överlåten egendom';
	static PreviousCalendarMonth = 'Föregående kalendermånad';
	static RemoveEventTypeC = 'Ta bort kategori 3';
	static InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation = 'Jag accepterar automatiskt inbjudan att gå med i chatten';
	static EventTypes = 'Kategori 1';
	static ParameterIsNotValidBankBICCode = '\'{0}\' är inte giltig bank-BIC-kod!';
	static SharedFilter = 'Delat filter';
	static SavingEventTypesWasSuccessful = 'Kategori 1 har sparats framgångsrikt!';
	static DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort dokumentet \'{0}\' eftersom data i dokumentet har ändrats samtidigt av en annan användare!';
	static AddingWhistleBlowHandlersWasSuccessful = 'Hanterare har lagts till!';
	static AddNotifiedPerson = 'Lägg till person som ska meddelas';
	static SystemAdminMessage = 'Meddelande till alla systemanvändare';
	static SendingEmail = 'Skickar e-post...';
	static EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError = 'Det gick inte att tömma papperskorgen för uppgiften eftersom data i uppgifterna har ändrats samtidigt av en annan användare!';
	static PortalInUse = 'Inloggningsportalen används';
	static SavingRecordBasicEducationParameterWasSuccessful = 'Grundutbildningen \'{0}\' har sparats!';
	static EventSettingsParameterNotFound = 'Incidentinställningarna \'{0}\' hittades inte!';
	static DeletingPartnersParameterWasSuccessful = 'Partners \'{0}\' har tagits bort!';
	static EmployeeType = 'Typ av anställd';
	static CompanyTaskTypes = 'Företagsuppgiftstyper';
	static DeletingUserParametersWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort användarparametrar eftersom data i parametrarna har ändrats samtidigt av en annan användare!';
	static RemoveUserGroup = 'Ta bort användargrupp';
	static HandlersMustBeDefined = 'Hanterare måste definieras!';
	static DoYouReallyWantToRemoveRecordBasicEducationParameter = 'Vill du verkligen ta bort grundläggande utbildning \'{0}\'?';
	static AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till administratörer \'{0}\'! Fel: \'{1}\'';
	static SelectEventReceiver = 'Välj Incident Receiver';
	static Modification = 'Modifiering';
	static AddLogisticVoucherPaymentSetting = 'Lägg till betalningsinställning';
	static EditCompany = 'Redigera företag';
	static ChangingPasswordWasNotSuccessfulErrorParameter = 'Det gick inte att byta lösenord! Fel: \'{0}\'';
	static DoYouReallyWantToUnlockEvent = 'Vill du verkligen låsa upp incidenten \'{0}\'?';
	static UserModuleProperties = 'Egenskaper för användarkonton';
	static NoReports = 'Inga rapporter!';
	static SharedFiltersAndReports = 'Delade filter och rapporter';
	static AddTaskHandler = 'Lägg till hanterare';
	static WhistleBlowStateNew = 'Ny';
	static SavingAttachmentsWasNotSuccessfulErrorParameter = 'Det gick inte att spara bilagor! Fel: \'{0}\'';
	static UnkownErrorHasBeenOccured = 'Ett okänt fel har inträffat i systemet!';
	static SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara kanalen \'{0}\'! Fel: \'{1}\'';
	static SaveComment = 'Spara kommentar';
	static RemoveFilter = 'Ta bort filter';
	static Drafts = 'Utkast';
	static FolderTreePermissions = 'Mappträdsbehörigheter';
	static LockedToUser = 'Låst av användare';
	static Custom = 'Beställnings';
	static Collapse = 'Kollaps';
	static RestoringInstantMessageWasSuccessful = 'Snabbmeddelandet har återställts!';
	static PublishVersionHistorySize = 'Publicera versionshistorik Storlek';
	static AddDocumentTemplate = 'Lägg till dokumentmall';
	static WhistleBlowDateAndTime = 'Incident tid';
	static EmptyingTaskTrashBinWasSuccessful = 'Papperskorgen är nu tom!';
	static ShowOnlyUnitsThatAreInUse = 'Visa endast enheter som används';
	static ModifyDateStartDate = 'Senast ändrad från';
	static Missing = 'Saknas';
	static WorkTimeType = 'Typ av arbetstid';
	static EditUserGroup = 'Redigera användargrupp';
	static DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort administratörer "{0}", eftersom data har ändrats samtidigt av en annan användare!';
	static Channels = 'Chattar';
	static MaximumTotalSizeOfAttachmentsIsParameterMB = 'Den maximala totala storleken på alla bilagor är {0} MB!';
	static EditDefaultEventHandlersAndNotifiedPersons = 'Redigera standardincidenthanterare och anmälda personer';
	static CompanyGroupAll = '"Alla företag"';
	static SavingTaskDataRowWasNotSuccessfulErrorParameter = 'Det gick inte att spara uppgiftsraden! Fel: \'{0}\'';
	static DeletingEventTypeCParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort kategori 3 \'{0}\'! Fel: \'{1}\'';
	static EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Kategori 1 "{0}" underkategorier används i {1} incidenter och kategori 1 kan inte tas bort!';
	static ClearSubFolderPermissions = 'Rensa undermappsbehörigheter';
	static CurrentWorkPlaceNotAdded = 'Nuvarande arbetsplats har inte lagts till!';
	static NoInstantMessageChannelsToShow = 'Inga chattar att visa!';
	static EventTypeC = 'Kategori 3';
	static ChangePassword = 'Ändra lösenord';
	static FolderNameMustBeDefined = 'Mappnamn måste definieras!';
	static Severity = 'Allvarlighetsgrad';
	static TaskRemainderCheckingAndNotificationFinishedInParameterSeconds = 'Kontroll av återstående uppgift och avisering avslutad (varaktighet: {0:N0}s, fel: {1}, uppgifter: {2}, e-postmeddelanden: {3}, push-meddelanden: {4})';
	static SavingUserParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara användaren \'{0}\', eftersom användardata har ändrats samtidigt av en annan användare!';
	static WhistleBlowParameterNotFound = 'Visselslagsrapporten \'{0}\' hittades inte!';
	static Observer = 'Observatör';
	static DeletingPartnerParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort partner \'{0}\'! Fel: \'{1}\'';
	static SavingEventTypeCParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara kategori 3 \'{0}\', eftersom data i kategori 3 har ändrats samtidigt av en annan användare!';
	static DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort deltagare \'{0}\'! Fel: \'{1}\'';
	static AddingEventHandlersWasSuccessful = 'Hanterare har lagts till!';
	static Ascending = 'Stigande';
	static UnlockingTaskDataRowWasNotSuccessful = 'Det gick inte att låsa upp uppgiftsraden!';
	static Save = 'Spara';
	static RecordFurtherEducationCodeMustBeDefined = 'Vidareutbildningens namn måste anges!';
	static PaymentTermInDays = 'Betalningstid i dagar';
	static DefaultTaskStates = 'Default Task States';
	static SavingMessageParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara meddelandet \'{0}\'! Fel: \'{1}\'';
	static AddUsersParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att lägga till användarkonton "{0}", eftersom data har ändrats samtidigt av en annan användare!';
	static ExternalSystemDatabaseInterfaceError = 'Externt eSalkku-systemdatabasgränssnittsfel!';
	static RemoveUserAndKeepRecordUser = 'Ta bort användarkonto och behåll användarregister';
	static AllLogisticVoucherCustomers = 'Alla kunder';
	static Next = 'Nästa';
	static AddTransferredProperties = 'Lägg till överförda egenskaper';
	static AddingTaskTypesParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till uppgiftstyperna \'{0}\'! Fel: \'{1}\'';
	static EditableDocumentSaveBeforeApproveConfirmation = 'Vill du spara ändringar i dokumentet innan godkännande?';
	static SelectEventType = 'Välj kategori 1';
	static Actions = 'Handlingar';
	static SelectTaskPriority = 'Välj Uppgiftsprioritet';
	static EventClosed = 'Incidenten stängd';
	static Remainders = 'Rester';
	static UsedFileStorageQuota = 'Använd fillagringskvot';
	static Yes = 'Ja';
	static AddLogisticVoucherCustomerPaymentSetting = 'Lägg till betalningsinställning';
	static Interval = 'Intervall';
	static SelectUrgencyClass = 'Välj brådskande klass';
	static FileChangesCheckingAndEmailingStarted = 'Filändringskontroll och e-post har börjat';
	static Receiver = 'Mottagare';
	static SavingEventMessageWasNotSuccessfulConcurrencyError = 'Det gick inte att spara kommentaren eftersom data i kommentaren har ändrats samtidigt av en annan användare!';
	static InTesting = 'I testning';
	static Qualification = 'Kvalifikation';
	static AttachmentParameterDownloaded = 'Bilaga har laddats ner.';
	static RemoveFromFavorites = 'Ta bort från favoriter';
	static SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara meddelandets e-postlogg \'{0}\', eftersom data i e-postloggen har ändrats samtidigt av en annan användare!';
	static DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort administratören \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static EventParameterUnitChangedFromParameterToParameter = 'Incident \'{0}\' enhet ändrades från \'{1}\' till \'{2}\'.';
	static VoucherPrefix = 'Verifikationsprefix';
	static Form = 'Form';
	static SavingTaskParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara uppgiften \'{0}\', eftersom uppgiften har ändrats samtidigt av en annan användare!';
	static DeletingRecordFamiliarizationsParameterWasSuccessful = 'Bekantskapen \'{0}\' har tagits bort!';
	static UserCodeMustBeDefined = 'Användarnamn måste definieras!';
	static AddUserGroupsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att lägga till användargrupper "{0}", eftersom data har ändrats samtidigt av en annan användare!';
	static DeletingWhistleBlowParameterWasSuccessful = 'Visselblåsningsrapporten \'{0}\' har tagits bort!';
	static BusinessIdentityCode = 'Företagsidentitetskod';
	static RemoveCompanyTaskType = 'Ta bort Company Task State';
	static RemoveLogisticVoucherPaymentSetting = 'Ta bort betalningsinställning';
	static SearchDocuments = 'Sök i dokument';
	static RecordParameterLockingFailed = 'Det gick inte att låsa posten \'{0}\'!';
	static SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara meddelandets e-postlogg \'{0}\'! Fel: \'{1}\'';
	static FieldParameterContainsInvalidCodeCharacters = 'Värdet på fältet \'{0}\' innehåller tecken som är olagliga i koder ( \, ^, ?, &, <, >, /, +, :) eller så slutar det med olagliga tecken (.)!';
	static CustomerNumber = 'Kundnummer';
	static DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort uppgiftstypen "{0}", eftersom data i uppgiftstypen har ändrats samtidigt av en annan användare!';
	static RecordUserExcelFamiliarization = 'Bekantskaper';
	static PageBreakBetweenGroups = 'Lägg till sidbrytning mellan grupper';
	static AddTask = 'Lägg till uppgift';
	static Filter = 'Filtrera';
	static EventTypeBParameterNotFound = 'Kategori 2 \'{0}\' hittades inte!';
	static DoYouWantToAddThisApplicationToHomeScreen = 'Vill du lägga till den här applikationen på startskärmen?';
	static WhistleBlowAnonymousReportInfo = 'Om du inte vill avslöja ditt namn under rapporteringsprocessen, välj det här alternativet.';
	static LanguageNonLocalFinnish = 'Suomi';
	static DocumentZipFileName = 'Dokument_{0:åååå-MM-ddHH-mm}.zip';
	static JobLineParameterNotFound = 'Jobbraden \'{0}\' hittades inte!';
	static DocumentEndDate = 'Giltig till';
	static SavingInstantMessageWasNotSuccessfulErrorParameter = 'Det gick inte att spara snabbmeddelandet! Fel: \'{0}\'';
	static DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort dokumentmallen \'{0}\', eftersom data i dokumentmallen har ändrats samtidigt av en annan användare!';
	static AddTransferredPropertyToMultipleUserRecords = 'Lägg till överförd egendom till flera användarposter';
	static DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort deltagare eftersom data har ändrats samtidigt av en annan användare!';
	static AddChildEventType = 'Lägg till underkategori 1';
	static QualificationIsInvalid = 'Kvalifikationen är inte giltig!';
	static AddUserGroups = 'Lägg till användargrupper';
	static DeletingEventTypeBParameterWasSuccessful = 'Kategori 2 \'{0}\' har tagits bort!';
	static CompanyLogo = 'Företagslogotyp';
	static KeywordParameterNotFound = 'Sökordet \'{0}\' hittades inte!';
	static PrintRecordUser = 'Skriv ut användarpost';
	static SavingFilterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara filtret eftersom data i filtret har ändrats samtidigt av en annan användare!';
	static EventPartners = 'Incidentpartners';
	static DeletingAttachmentsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort bilagorna \'{0}\'! Fel: \'{1}\'';
	static FolderParameterNotFound = 'Mappen \'{0}\' hittades inte!';
	static Salary = 'Lön';
	static ClickToCancelAutomaticLogout = 'Användarsessionen är på väg att löpa ut, klicka på den här dialogrutan för att förhindra automatisk utloggning.';
	static TaskStates = 'Uppgiftsstater';
	static DefaultLanguage = 'Språk';
	static Last180Days = 'Senaste 180 dagarna';
	static WhistleBlowSourceEmail = 'E-post';
	static DatabaseCheckingStarted = 'Databaskontroll startade';
	static UnitChangedFromParameterToParameter = 'Enheten ändrades från \'{0}\' till \'{1}\'.';
	static BrowserInfo = 'Webbläsare';
	static ApplicationName = 'eSalkku';
	static SSRSRefreshFinishedInParameterSeconds = 'SSRS-uppdatering avslutad (varaktighet: {0:N0}s)';
	static EventParameterNotFound = 'Incidenten \'{0}\' hittades inte!';
	static InstantMessageChannelAdministrator = 'Administratör';
	static SavingEventSettingsWasNotSuccessfulConcurrencyError = 'Det gick inte att spara incidentinställningarna eftersom incidentinställningarna har ändrats samtidigt av en annan användare!';
	static DeletingMessageTypeParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort meddelandetypen \'{0}\'! Fel: \'{1}\'';
	static ExternalSystemCompanyCodeClaimNotFound = 'Användarens anspråk \'externalSystemCompanyCode\' hittades inte!';
	static LockedTaskParameterCannotBeSaved = 'Uppgiften \'{0}\' har låsts till en annan användare och den kan inte sparas!';
	static WhistleBlowReport = 'Whistle Blow Report';
	static IncludeArchivedRecordUsers = 'Inkludera arkiverade användaruppgifter';
	static SavingEventTypeBParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara kategori 2 \'{0}\'! Fel: \'{1}\'';
	static DoYouReallyWantToUnlockTaskDataRow = 'Vill du verkligen låsa upp uppgiftsraden?';
	static SavePrint = 'Spara utskrift';
	static SendReadReceipt = 'Skicka läskvitto';
	static ValidityDate = 'Giltighetsdatum';
	static FieldParameterContainsInvalidCompanyCodeCharacters = 'Värdet på fältet \'{0}\' innehåller tecken som är olagliga i företagskoden (., &, :, \' \', ö, ä, à, å, Ö, Ä, À tai Å)!';
	static RecordUserExcelSalary = 'Lön';
	static WhistleBlowCategory = 'Whistle Blow Kategori';
	static AccessRightWrite = 'Skrivtillstånd';
	static TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Uppgiftstypen \'{0}\' används i {1} uppgifter och kan inte tas bort!';
	static DocumentApproval = 'Dokumentgodkännande';
	static DeletingRecordQualificationsParameterWasSuccessful = 'Kvalifikationerna \'{0}\' har tagits bort!';
	static PermissionToIdentificateEvents = 'Tillstånd att skapa och identifiera incidenter';
	static SendingPusNotificationsWasNotSuccessfulErrorParameter = 'Det gick inte att skicka push-meddelanden! Fel: \'{0}\'';
	static DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort företagsgrupperna \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static Years = 'År';
	static EditMessage = 'Redigera meddelande';
	static DeletingRecordUserParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort användarposten \'{0}\'! Fel: \'{1}\'';
	static SaveTaskConfirmation = 'Vill du spara uppgiften?';
	static EventSenderIsPartnerCompany = 'Incidentavsändaren är partnerföretag';
	static UserPasswordMustBeValid = 'Lösenordet måste vara minst 8 tecken långt och det måste innehålla små bokstäver, versaler och minst en siffra eller specialtecken.';
	static PrintEvent = 'Skriv ut incident';
	static PayeeUsersCanLogInToTheSystem = 'Företagsanvändare kan logga in i systemet';
	static MessageMustBeDefined = 'Meddelandets innehåll måste definieras!';
	static ChangedUrgencyClass = 'Ändrad incident brådskande klass';
	static InviteInstantMessageChannelUser = 'Bjud in deltagare';
	static UserEventsNotApproved = 'Användarhändelser inte godkända';
	static NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded = 'Nytt företag måste sparas innan företagsgrupper kan läggas till!';
	static SendReadReceiptInfo = 'Skicka läskvitto genom att trycka på &#10003; knapp.';
	static DefaultEventUrgencyClasses = 'Standard brådskande klasser';
	static WhistleBlowInformantDateAndTime = 'När inträffade detta?';
	static SavingUserParameterParameterWasSuccessful = 'Användaralternativet \'{0}\' har sparats!';
	static PreviewIsNotSupportedForThisFileType = 'Förhandsgranskning stöds inte för den här filtypen!';
	static Last7Days = 'Senaste 7 dagarna';
	static Picture = 'Bild';
	static AddPermission = 'Lägg till rättighet';
	static EmailMessage = 'Skicka meddelande via e-post';
	static LastName = 'Efternamn';
	static DeletingTaskStateParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort uppgiftstillståndet \'{0}\'! Fel: \'{1}\'';
	static SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara filen \'{0}\'! Fel: \'{1}\'';
	static LockingEditableDocumentParameterWasNotSuccessful = 'Det gick inte att låsa dokumentet \'{0}\'!';
	static DeletingAttachmentParameterWasSuccessful = 'Bilaga \'{0}\' har tagits bort!';
	static ValidPlural = 'Giltig';
	static RootCause = 'Grundorsak';
	static Partner = 'Partner';
	static PermissionsForSections = 'Avsnitt i bruk';
	static ArchiveWhistleBlows = 'Arkivera Whistle Blow-rapporter';
	static Version = 'Version';
	static ReportsStandard = 'Standardrapporter';
	static ReadReceiptGiven = 'Kvitto skickat';
	static SavingMessageTypeParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara meddelandetypen \'{0}\'! Fel: \'{1}\'';
	static RemoveUserFromUserGroup = 'Ta bort användarkonto från användargrupp';
	static DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents = 'Standard brådskande klass måste definieras innan incidenter läggs till!';
	static ClearSubUnitPermissions = 'Rensa underenhetsbehörigheter';
	static AType = 'Typ';
	static RemoveMessageType = 'Ta bort meddelandetyp';
	static DocumentNameIsIllegal = 'Dokumentnamn är inte tillåtet (reserverat namn)!';
	static AddCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Misslyckades med företagsgruppen \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Kategori 2 "{0}" underkategorier används i {1} incidenter och kategori 2 kan inte tas bort!';
	static UnlockingTaskParameterWasNotSuccessful = 'Det gick inte att låsa upp uppgiften \'{0}\'!';
	static Row = 'rad';
	static SaveInstantMessageChannelConfirmation = 'Vill du spara chatten?';
	static NotSelected = 'Ej valt';
	static Private = 'Privat';
	static SavingUserParametersWasNotSuccessfulErrorParameter = 'Det gick inte att spara användaralternativ! Fel: \'{0}\'';
	static Identification = 'Identifiering';
	static InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter = 'Ta bort arkiverad chatt och meddelanden på {0}';
	static TaskPriorityParameterNotFound = 'Uppgiftsprioritet \'{0}\' hittades inte!';
	static Uploaded = 'Uppladdad';
	static AutomaticLogoutDone = 'Automatisk utloggning gjord på grund av utgången användarsession!';
	static RemoveTaskType = 'Ta bort uppgiftstyp';
	static EventTypeBTitle = 'Incidentidentifiering Kategori 2';
	static SelectUnit = 'Välj Enhet';
	static DeletingUsersParameterWasSuccessful = 'Användarkonton \'{0}\' har tagits bort!';
	static AddQualification = 'Lägg till kvalifikation';
	static DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort deltagaren \'{0}\'! Fel: \'{1}\'';
	static DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders = 'Det gick inte att ta bort filfiltret "{0}", eftersom filfiltret används i mappen {1}!';
	static UnitIsRelatedToEventPartner = 'Enheten är relaterad till incidentpartner';
	static NoFilters = 'Inga filter!';
	static EditDefaultFurtherEducation = 'Redigera Standard Vidareutbildning';
	static UserGroups = 'Användargrupper';
	static RecordUserLogEntryTypeCreate = 'Skapa';
	static ShortName = 'Kort namn';
	static Validity = 'Giltighet';
	static DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort bekantskaper "{0}", eftersom data i bekantskaper har ändrats samtidigt av en annan användare!';
	static DeletingPartnerParameterWasSuccessful = 'Partnern \'{0}\' har tagits bort!';
	static OtherCompanies = 'Andra företag';
	static CourseCredit = 'Kurskredit';
	static AddRemainder = 'Lägg till resten';
	static FullNameClaimNotFound = 'Användaranspråket "namn" hittades inte!';
	static UserGroupParameterNotFound = 'Användargruppen \'{0}\' hittades inte!';
	static DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort mappen \'{0}\'! Fel: \'{1}\'';
	static PanelSize1 = '1 x 1';
	static DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort filen \'{0}\' eftersom data i filen har ändrats samtidigt av en annan användare!';
	static Messages = 'Meddelanden';
	static FileParameterIsNotImage = 'Filen \'{0}\' är inte en bild!';
	static DocumentNameIsTooLong = 'Dokumentnamnet \'{0}\' är för långt!';
	static EmailNotificationOfChangedPublishStatus = 'Meddela när publicerade tillståndsändringar via e-post';
	static AllPartners = '"Alla partners"';
	static SharedReportCompany = 'Delas med företaget';
	static ScopeUnit = 'Enhet';
	static PrintParameterNotFound = 'Det gick inte att hitta utskriften \'{0}\'!';
	static DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort användargrupperna \'{0}\' eftersom data i användargrupper har ändrats samtidigt av en annan användare!';
	static DeletingDefaultEventHandlerParameterWasSuccessful = 'Standardincidenthanteraren \'{0}\' har tagits bort!';
	static TimeMustBeDefined = 'Tiden måste definieras!';
	static EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Kategori 2 \'{0}\' används i {1} incidenter och kan inte tas bort!';
	static RecordUserExcelTabName = 'Användarregister';
	static DeletingTaskStatesParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort uppgiftstillstånden \'{0}\'! Fel: \'{1}\'';
	static CompanyParameterNotFound = 'Företaget \'{0}\' hittades inte!';
	static SearchEndDate = 'Slutdatum';
	static WhistleBlowStatisticsInProgressRecords = 'Pågående rapporter';
	static RemoveEventUrgencyClass = 'Ta bort brådskande klass';
	static TaskHandlerParameterNotFound = 'Hanteraren \'{0}\' hittades inte!';
	static PositionErrorUnavailable = 'Platsinformation är inte tillgänglig.';
	static DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort företagsgruppen \'{0}\'! Fel: \'{1}\'';
	static Unit = 'Enhet';
	static ChangesMaxAmount = 'Maximalt antal ändringar';
	static NoAddedEventTypeCs = 'Inga tillagda kategorier!';
	static NoAddedEventTypeBs = 'Inga tillagda kategorier!';
	static CompanyCodeContainsIllegalCharacters = 'Företagskoden innehåller tecken som är olagliga (., &, :, \' \', ö, ä, à, å, Ö, Ä, À tai Å)!';
	static EmploymentEndDate = 'Slutdatum för anställning';
	static WhistleBlowStatisticsNonAnonymousRecords = 'Icke anonyma rapporter';
	static DoYouReallyWantToRemoveEventTypeCParameter = 'Vill du verkligen ta bort kategori 3 \'{0}\'?';
	static PrintFileName = 'Skriv ut_{0:åååå-MM-ddHH-mm}.pdf';
	static CopyContentToDocumentContent = 'Kopiera innehåll till dokumentinnehåll';
	static DoYouReallyWantToRemoveTaskPriorityParameter = 'Vill du verkligen ta bort uppgiftsprioriteten \'{0}\'?';
	static EditableDocument = 'Dokumentera';
	static GetMore = 'Visa mer';
	static FieldParameterValueMustBeDefined = 'Värdet för fältet \'{0}\' måste definieras!';
	static DeletingAttachmentParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort bilagan \'{0}\', eftersom data har ändrats samtidigt av en annan användare!';
	static LastParameterYears = 'Senaste {0} åren';
	static MyLastModifications = 'Användarens senaste ändringar';
	static DownloadAttachment = 'Ladda ner vald bilaga';
	static QualificationIsValid = 'Kvalifikationen är giltig';
	static SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara brådskande klass \'{0}\', eftersom data i brådskande klassen har ändrats samtidigt av en annan användare!';
	static Download = 'Ladda ner';
	static DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort dokumentet \'{0}\'! Fel: \'{1}\'';
	static WhistleBlowInformantMessages = 'Informantmeddelanden';
	static TaskStateLocked = 'Låst';
	static Usage = 'Användande';
	static BasicEducation = 'Grundläggande utbildning';
	static DeletingUserParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort användarkontot \'{0}\'! Fel: \'{1}\'';
	static SavingWhistleBlowCategoryParameterWasSuccessful = 'Visselslagskategori \'{0}\' har sparats!';
	static DoYouReallyWantToRemoveFileParameter = 'Vill du verkligen ta bort filen \'{0}\'?';
	static MaximumSizeOfSingleAttachment = 'Maximal storlek på en bilaga är {0} MB!';
	static MoveToTrashBin = 'Flytta till papperskorgen';
	static InstantMessageSettings = 'Inställningar för snabbmeddelanden (HoxUp)';
	static ObserverAction = 'Observatörsåtgärd';
	static AddEventTypesToEvent = 'Lägg till kategori 1 genom att trycka på plusknappen.';
	static MessageModuleProperties = 'Egenskaper för aktuella ämnen';
	static CreatingPrint = 'Skapar utskrift...';
	static EventApprovalWasNotSuccessfulErrorParameter = 'Det gick inte att godkänna incidenten! Fel: \'{0}\'';
	static ImageChangeInterval = 'Bildändringsintervall';
	static RemovePrint = 'Ta bort utskrift';
	static DocumentsSelected = 'valda dokument';
	static DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort kvalifikationerna \'{0}\'! Fel: \'{1}\'';
	static AddPartnerParameterWasSuccessful = 'Partner \'{0}\' har lagts till!';
	static MessageVisibilityPeriodNotStarted = 'Meddelandets synlighetsperiod har inte börjat!';
	static DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages = 'Det gick inte att ta bort meddelandetypen \'{0}\', eftersom meddelandetypen används i {1} meddelanden!';
	static RemovePicture = 'Ta bort bild';
	static RestoringInstantMessageWasNotSuccessfulConcurrencyError = 'Det gick inte att återställa snabbmeddelandet eftersom data i snabbmeddelandet har ändrats samtidigt av en annan användare!';
	static ExternalSystemCode = 'Kod i systemet';
	static Closed = 'Stängd';
	static SelectedDocumentTypes = 'Visa endast valda dokumenttyper';
	static InstantMessageChannelParameterNotFound = 'Chatten \'{0}\' hittades inte!';
	static AllEventHandlersHaveReadMessage = 'Alla incidenthanterare har läst din kommentar';
	static StateOpenPlural = 'Öppen';
	static ValidityStartDate = 'Giltighetsstartdatum';
	static SavingDirectoryItemPermissionsNotSuccessfulErrorParameter = 'Det gick inte att spara mappbehörigheter! Fel: \'{0}\'';
	static SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara anställning \'{0}\'! Fel: \'{1}\'';
	static SelectCategory = 'Välj kategori';
	static ModifiedBy = 'Modifierad av';
	static Expired = 'Utgånget';
	static ViewLogLine = 'Visa logglinje';
	static DoYouReallyWantToEmailMessageParameter = 'Vill du verkligen skicka meddelandet \'{0}\' via e-post? Meddelande kan skickas via e-post endast en gång.';
	static SelectEventTypeC = 'Välj kategori 3';
	static FolderNameConstainsIllegalCharacters = 'Mappnamnet innehåller olagliga tecken (<, >, :, ", /, \, |, ? tai *) eller så slutar det med olagliga tecken (., \' \')!';
	static AllCompanies = 'Alla företag';
	static Today = 'I dag';
	static PrintOrientation = 'Skriv ut orientering';
	static RecordBasicEducationCodeMustBeDefined = 'Grundutbildningens namn måste definieras!';
	static SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara filen \'{0}\' eftersom data i filen har ändrats samtidigt av en annan användare!';
	static DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort deltagare "{0}", eftersom data har ändrats samtidigt av en annan användare!';
	static ApplicationTempPathIsNotSpecified = 'Application temp-mapp är inte specificerad!';
	static DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort kategori 2 \'{0}\', eftersom data i kategori 2 har ändrats samtidigt av en annan användare!';
	static OpenWhistleBlowInstruction = 'Ange lösenordet som du fick när whistleblow-rapporten skickades in.';
	static FolderCannotBeMovedToItsSubFolder = 'Mapp kan inte flyttas i sin egen undermapp!';
	static DefaultTaskTypes = 'Standarduppgiftstyper';
	static SaveDraft = 'Spara utkast';
	static DocumentModulePropertiesSaveWasSuccessful = 'Dokumentinställningarna har sparats!';
	static WhistleBlowReportHasBeenSavedBottomText = 'Du måste lagra lösenordet innan du kan stänga den här sidan.';
	static AddedComment = 'Tillagd kommentar';
	static AddFurtherEducationToMultipleUserRecords = 'Lägg till vidareutbildning till flera användarposter';
	static WhistleBlowSourceMail = 'Post';
	static SavingUserParametersWasNotSuccessfulConcurrencyError = 'Det gick inte att spara användaralternativ, eftersom data i användaralternativen har ändrats samtidigt av en annan användare!';
	static PositionErrorPermissionDenied = 'Applikationen har inte behörighet att läsa plats.';
	static CkickToChangeTheIcon = 'Klicka för att ändra ikonen';
	static InstantMessageChannelArchivingTypeKeepMessages = 'Bevara arkiverad chatt och meddelanden';
	static CopyingFilesAndDocumentsToFolderParameterWasSuccessful = 'Filer/dokument har kopierats till mappen \'{0}\'!';
	static AddPartnersParameterWasSuccessful = 'Partners \'{0}\' har lagts till!';
	static NoModifications = 'Inga ändringar';
	static NormalMessage = 'Normalt meddelande';
	static PermissionsToCreateEventsToEventPartner = 'Tillstånd att skapa incidenter till partner';
	static AddEvent = 'Lägg till incident';
	static TrainingEndDateCannotPrecedeTrainingStartDate = 'Träningens slutdatum kan inte föregå träningens startdatum!';
	static UserAccountNotConnected = 'Användarkonto inte anslutet';
	static PrintIsEmpty = 'Utskriften är tom!';
	static RemoveLogisticVoucherCustomerPaymentSetting = 'Ta bort betalningsinställning';
	static WhistleBlowStateArchived = 'Arkiverad';
	static ShowUserGroups = 'Visa användargrupper';
	static UserLastNameMustBeDefined = 'Användarefternamn måste definieras!';
	static SearchStartDate = 'Start datum';
	static RemoveLogisticVoucherCompanyPaymentSetting = 'Ta bort betalningsinställning';
	static FilterIsActive = 'Filtret är aktivt';
	static ExternalSystemUserCodeClaimNotFound = 'Användaranspråket \'externalSystemUserCode\' hittades inte!';
	static AddPhoto = 'Lägg till foto';
	static AddSalaryRowToMultipleUserRecords = 'Lägg till lönerad till flera användarposter';
	static DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort administratörer \'{0}\'! Fel: \'{1}\'';
	static AddingRecordUserSalaryRowsWasSuccessful = 'Lönerader har lagts till!';
	static Voluntary = 'Frivillig';
	static FolderParameterAlreadyExistsInFileSystem = 'Mappen \'{0}\' finns redan i filsystemet!';
	static ValidityEndDate = 'Slutdatum för giltighet';
	static Partners = 'Partners';
	static Characters = 'Tecken';
	static AddRecordUserToUserAccount = 'Lägg till användarpost till befintligt användarkonto';
	static EmptyDocument = 'Tomt dokument';
	static TimeSpanMonth = 'Månad';
	static AddSharedDocumentTemplate = 'Lägg till delad dokumentmall';
	static AddMessage = 'Lägg till meddelande';
	static RemoveTask = 'Ta bort uppgift';
	static EditInstantMessageChannel = 'Redigera chatt';
	static SavingEventParameterWasSuccessful = 'Incidenten \'{0}\' har sparats!';
	static AddUserGroupParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att lägga till användargruppen \'{0}\' eftersom data har ändrats samtidigt av en annan användare!';
	static UserRecordStatisticsExpiredFamiliarizations = 'Utgångna bekantskaper';
	static ValidityEndDateEnd = 'Giltighet Slutdatum - Slut';
	static SavingCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Det gick inte att spara företagsgruppen \'{0}\'! Fel: \'{1}\'';
	static TasksExcelByTasks = 'Uppgifter - Excel - Filtrerat efter uppgifter';
	static FilesParameterAreEmpty = 'Filerna \'{0}\' är tomma!';
	static EventTypeBs = 'Kategori 2';
	static FolderParameterAlreadyExistsInTargetFolder = 'Mappen \'{0}\' finns redan i målmappen!';
	static LogoSquare = 'Liten logotyp';
	static DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort grundutbildningen \'{0}\', eftersom data i grundutbildningen har ändrats samtidigt av en annan användare!';
	static SearchEvents = 'Sök incidenter';
	static WhistleBlowArchivingTypeChangeStateAndRemoveInformantData = 'Ändra tillstånd för rapport till "Arkiverad" och ta bort informantdata';
	static DoYouReallyWantToRemoveReportParameter = 'Vill du verkligen ta bort rapporten \'{0}\'?';
	static Admin = 'Administratör';
	static TaskRemainderParameterNotFound = 'Återstoden \'{0}\' hittades inte!';
	static Invoicing = 'Fakturering';
	static AddCompanyGroup = 'Lägg till företagsgrupp';
	static WebPushError = 'Web Push-fel';
	static TrainingStartDateStart = 'Träningsstartdatum - Start';
	static Action = 'Handling';
	static UserCodeMustBeValid = 'Användarnamnet måste vara minst 8 tecken långt och det får inte innehålla blanksteg.';
	static UserCannotRecieveEmailNotifications = 'Användaren kommer inte att få e-postmeddelanden';
	static LogisticVoucherCustomers = 'Kunder';
	static DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort grundläggande utbildning \'{0}\'! Fel: \'{1}\'';
	static InstantMessages = 'Snabbmeddelanden';
	static DeletingRecordUserEmploymentParameterWasSuccessful = 'Anställningen \'{0}\' har tagits bort!';
	static LogIn = 'Logga in';
	static EventMessageConfirmation = 'Vill du spara en ny kommentar?';
	static SavingMessageSendEmailParameterWasSuccessful = 'E-postloggen \'{0}\' har sparats!';
	static EditKeyword = 'Redigera nyckelord';
	static DeletingUserGroupParameterWasSuccessful = 'Användargruppen \'{0}\' har tagits bort!';
	static SaveFilter = 'Spara filter';
	static EditRecordUser = 'Redigera användarpost';
	static DeletingFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att ta bort mappen \'{0}\' i filsystemet! Fel: \'{1}\'';
	static AttachmentImageSize = 'Bifogad bild Miniatyrstorlek';
	static UpdateCompanyExternalSystemsWasSuccessful = 'Företag kopplat till externa system framgångsrikt!';
	static FileUploadWasSuccessful = 'Filer har laddats upp!';
	static Hours = 'timmar';
	static EditDefaultTaskState = 'Redigera standarduppgiftsstatus';
	static DoYouReallyWantToRemoveRecordFamiliarizationParameter = 'Vill du verkligen ta bort bekantskapen "{0}"?';
	static Invited = 'Inbjudna';
	static ThisWeek = 'Denna vecka';
	static KeywordCodeMustBeDefined = 'Nyckelord måste definieras!';
	static RenewPersonalization = 'Personalisering';
	static DeletingInstantMessageChannelWasSuccessful = 'Chatten har tagits bort!';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att kopiera mappen \'{0}\' till mappen \'{1}\', eftersom data i mappen har ändrats samtidigt av en annan användare!';
	static SharedDocumentTemplates = 'Delade dokumentmallar';
	static SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter = 'Det gick inte att publicera dokumentet \'{0}\'! Fel: \'{1}\'';
	static SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att spara den överförda egenskapen \'{0}\', eftersom data i den överförda egenskapen har ändrats samtidigt av en annan användare!';
	static Reject = 'Avvisa';
	static UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted = 'Användargruppen \'{0}\' används av {1} användare och kan inte tas bort!';
	static TaskParameterNotFound = 'Uppgiften \'{0}\' hittades inte!';
	static PasswordMustContainDigitOrSpecialCharacter = 'Lösenordet måste innehålla nummer eller specialtecken!';
	static ExternalIntraSystemCompanyTokenMustBeDefined = 'Företagstoken i externt eSalkku-system måste definieras!';
	static StudyWeek = 'Studievecka';
	static Removed = 'Tog bort';
	static Unread = 'Oläst';
	static HoursPerTrainingDay = 'Timmar per träningsdag';
	static DirectoryItemDocumentParameterNotFound = 'Filen \'{0}\' gillar inte!';
	static LogisticVoucher = 'Transportfaktura';
	static PasteDefaultHandlers = 'Klistra in standardhanterare';
	static WhistleBlowResolutionCanceled = 'Inställt';
	static NewMenu = 'Ny meny';
	static Move = 'Flytta';
	static TaskPriority = 'Prioritet';
	static AddUserGroupParameterWasSuccessful = 'Användargruppen \'{0}\' har lagts till!';
	static CompanyThatIsInUseCannotBeDeleted = 'Företag som är i tillståndet "I bruk" kan inte tas bort!';
	static SaveEventHandlerConfirmation = 'Vill du rädda händelsen?';
	static WebPageStyle = 'Sidstil';
	static Last120Days = 'Senaste 120 dagarna';
	static SavingDefaultHandlersWasSuccessful = 'Standardhanterare har sparats!';
	static ExternalSystemCompanyCodeMustBeDefined = 'Företagskod i externt system måste definieras!';
	static DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort standardincidenthanteraren \'{0}\', eftersom data i standardincidenthanteraren har ändrats samtidigt av en annan användare!';
	static EditLogisticVoucherCustomerPaymentSetting = 'Redigera betalningsinställning';
	static EditRow = 'Redigera rad';
	static PanelSize3 = '1 x 2';
	static UserSessionHasBeenExpired = 'Användarsessionen har löpt ut!';
	static LogEventType = 'Typ';
	static SavingOptionParameterWasSuccessful = 'Alternativet \'{0}\' har sparats!';
	static MovingFolderParameterWasNotSuccessfulErrorParameter = 'Det gick inte att flytta mappen \'{0}\' till mappen \'{1}\' i filsystemet! Fel: \'{2}\'';
	static SavingReadReceiptWasNotSuccessfulErrorParameter = 'Det gick inte att spara läskvitto! Fel: \'{0}\'';
	static DoYouReallyWantToExitInstantMessageChannel = 'Vill du verkligen avsluta chatten?';
	static GroupByEducation = 'Gruppera efter utbildning';
	static UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError = 'Det gick inte att länka företaget till externa system, eftersom data har ändrats samtidigt av en annan användare!';
	static Source = 'Källa';
	static TaskType = 'Uppgiftstyp';
	static EventTypeB = 'Kategori 2';
	static ReadingDatabaseParameter = 'Läser databasen \'{0}\'...';
	static AddFileFilter = 'Lägg till filfilter';
	static AddEventUrgencyClass = 'Lägg till brådskande klass';
	static AddUserParameterWasNotSuccessfulErrorParameter = 'Det gick inte att lägga till användarkontot \'{0}\'! Fel: \'{1}\'';
	static Filters = 'Filter';
	static TransferToChannel = 'Flytta till chatten';
	static DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort vidareutbildning \'{0}\', eftersom data i vidareutbildningen har ändrats samtidigt av en annan användare!';
	static WhistleBlowStatisticsRecordCount = 'Aktiva rapporter';
	static Choose = 'Välja';
	static RemoveCompany = 'Ta bort företag';
	static SavingRecordFamiliarizationParameterWasSuccessful = 'Bekantskap \'{0}\' har sparats!';
	static UserFilterNoUserGroup = 'Ingen användargrupp';
	static SavingMessageSendPushParameterWasSuccessful = 'Meddelande push-aviseringslogg \'{0}\' har sparats!';
	static UserIsWhistleBlowChannelAdminAndCannotBeDeleted = 'Användaren är visselblåsningschanel administratör och kan inte raderas!';
	static TrainingStartDate = 'Utbildningens startdatum';
	static EmptyTaskTrashBin = 'Töm papperskorgen för uppgiften';
	static Familiarizations = 'Bekantskaper';
	static SavingReportWasNotSuccessfulConcurrencyError = 'Det gick inte att spara rapporten eftersom data i rapporten har ändrats samtidigt av en annan användare!';
	static OpenWhistleBlow = 'Öppna Whistle Blow Report';
	static Last30Days = 'Senaste 30 dagarna';
	static AccountNumberTkbInCredit = 'Försäljningskonto';
	static UserGroupNameIsAlreadyInUse = 'Användargruppens namn används redan!';
	static UserGroupNameMustBeDefined = 'Användargruppsnamn måste definieras!';
	static DeletingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError = 'Det gick inte att ta bort chatten "{0}" eftersom data i snabbmeddelandekanalen har ändrats samtidigt av en annan användare!';
	static AccessRightRead = 'Lästillstånd';
}
// ReSharper restore InconsistentNaming
