import { IconButton, IconButtonOwnProps, Tooltip } from "@mui/material";
import React from "react";

const variantBackgroundColor = {
    outlined: "transparent",
    primary: "primary.main",
    success: "success.main",
    error: "error.main",
};
  
const variantColor = {
    outlined: "white",
    primary: "white",
    success: "white",
    error: "white",
};

const variantBorder = {
    outlined: "1px solid white",
    primary: "none",
    sucess: "none",
    error: "none",
};

interface CustomIconButtonProps extends IconButtonOwnProps {
    variant: "outlined" | "primary" | "success" | "error";
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    tooltip?: string;
    children?: React.ReactNode;
}
  
export const CustomIconButton = ({ variant, onClick, tooltip, children, ...other }: CustomIconButtonProps) => (
    <Tooltip title={tooltip}>
        <IconButton
            sx={{
                "backgroundColor": variantBackgroundColor[variant],
                "color": variantColor[variant],
                "border": variantBorder[variant],
                "&:hover": { backgroundColor: variantBackgroundColor[variant] }
            }}
            onClick={onClick}
            {...other}
        >
            {children}
        </IconButton>
    </Tooltip>
);