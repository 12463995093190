import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Container = styled(Box)`
  height: calc(100vh - 236px);
`;

export const MessageContainer = styled(Box)`
  padding: 1rem;
  flex-grow: 2;
  overflow: hidden;

  @media screen and (min-width: 900px) {
      max-height: calc(100vh - 200px);
      overflow-y: auto;  
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;       
  }

  & div:first-of-type {
    margin-top: auto;
  }
`;
