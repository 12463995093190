import React from "react";
import * as Styled from "./ReportStatusChip.styled";
import { WhistleBlowState } from "ui/Scripts/source/models/enums";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import ErrorIcon from "@mui/icons-material/Error";

interface ReportStatusChipProps {
    status: WhistleBlowState;
    label: string;
}

const getChipIcon = (status: WhistleBlowState) => {
    switch (status) {
    case WhistleBlowState.New:
        return <ErrorIcon color="error" />;
    case WhistleBlowState.InProgress:
        return <AccessTimeFilledIcon color="info" />;
    case WhistleBlowState.Received:
        return <MoveToInboxIcon color="warning" />;
    case WhistleBlowState.Resolved:
        return <CheckCircleIcon color="success" />;
    default:
        return <ErrorIcon color="error" />;
    }
};

export const ReportStatusChip = ({ status, label }: ReportStatusChipProps) => (
    <Styled.Container
        variant="filled"
        status={status}
        size='small'
        label={label}
        icon={getChipIcon(status)}
    />
);