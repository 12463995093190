import styled from "@emotion/styled";
import { Box, Button, Paper, Typography } from "@mui/material";

export const Container = styled(Paper)`
    padding: 1rem;
    max-width: 820px;

    @media screen and (min-width: 900px) {
        padding: 2.5rem;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
    }
`;

export const InfromatioBox = styled(Box)`
    background: #F1F7FF;
    border-radius: .5rem;
    padding: 1rem;
    margin: 2rem 0 1.5rem 0
`;

export const RadioHelperText = styled(Typography)`
    margin: -0.75rem 0 .5rem 2rem;
    color: #828282;
`;

export const CopyButton = styled(Button)`
    position: absolute;
    right: 0;
    border-radius: 0 8px 8px 0;
    border: 1px solid #2856B7;
`;