import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import * as Styled from "./ErrorView.styles";

interface ErrorViewProps {
    title: string;
    subtitle: string;
    children?: ReactNode;
}

export const ErrorView = ({ title, subtitle, children }: ErrorViewProps) => (
    <Styled.Container display="flex" flexDirection="column">
        <Styled.Paper elevation={3}>
            <Styled.Title variant="h5">{title}</Styled.Title>
            <Styled.Subtitle variant="body1">{subtitle}</Styled.Subtitle>
            <Box display="flex" flexDirection="row-reverse">
                {children}
            </Box>
        </Styled.Paper>
    </Styled.Container>
);