import * as React from "react";

export const SvgReport = ({
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" fill="none">
        <g clipPath="url(#clip0_386_4212)">
            <path stroke="currentStroke" strokeWidth="1px" fillRule="evenodd" clipRule="evenodd" d="M1.5 0C1.08579 0 0.75 0.335787 0.75 0.75V17.25C0.75 17.6642 1.08579 18 1.5 18H15C15.4142 18 15.75 17.6642 15.75 17.25V3L12.75 0H1.5Z" fill="currentColor"/>
            <path stroke="currentStroke" strokeWidth="1px" fillRule="evenodd" clipRule="evenodd" d="M12.75 0V3H15.75L12.75 0Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_386_4212">
                <rect width="18" height="20" fill="white" transform="translate(0.75)"/>
            </clipPath>
        </defs>
    </svg>
);
