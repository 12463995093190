import { Base } from "../../framework/base";
import { FileAttachment, IFileAttachment } from "../common/fileAttachment";

export interface IWhistleBlowInformantMessage {
    id: string;
    date: number;
    content: string;
    myMessage: boolean;
    isRead: boolean;
    attachments: IFileAttachment[];
}

export class WhistleBlowInformantMessage implements IWhistleBlowInformantMessage {
    id: string;
    date: number;
    content: string;
    myMessage: boolean;
    isRead: boolean;
    attachments: FileAttachment[];

    constructor();
    constructor(obj: IWhistleBlowInformantMessage);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.date = obj && obj.date || 0;
        this.content = obj && obj.content || "";
        this.myMessage = obj && obj.myMessage || false;
        this.isRead = obj && obj.isRead || false;
        this.attachments = Base.getTypedArray(obj ? obj.attachments : null, FileAttachment);
    }
        
    static sortWhistleBlowInformantMessages(items: IWhistleBlowInformantMessage[]): IWhistleBlowInformantMessage[] {
        if (!items && items.length < 2) return items;
        return items.sort((a: IWhistleBlowInformantMessage, b: IWhistleBlowInformantMessage) => { return (!a || !b) ? 0 : Base.numCompare(a.date, b.date); });
    }
}