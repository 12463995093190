import { ApiSuccess, IApiSuccess } from "../common/apiSuccess";

export interface IWhistleBlowSaveSuccess extends IApiSuccess {
    informantPassword: string;
}

export class WhistleBlowSaveSuccess extends ApiSuccess implements IWhistleBlowSaveSuccess {
    informantPassword: string;

    constructor();
    constructor(obj: IWhistleBlowSaveSuccess);
    constructor(obj?: any) {
        super(obj);
        this.informantPassword = obj && obj.informantPassword || "";
    }
}