/* global DisplaceJSObject, JSX */
// Dialog
// ***********************************************************************************************************************
import { Button } from "@mui/material";
import * as displace from "displacejs";
import * as React from "react";
import { Base } from "ui/Scripts/source/framework/base";
import { Translations } from "ui/Scripts/source/models/translations";
import * as storeTypes from "../../models/store/storeTypes";

export interface IDialogButton {
    title?: any;
    classes?: string;
    disabled?: boolean;
    tooltip?: string;
    icon?: string;
    onClick: (id?: string) => void;
}

export interface IDialogProp {
    classes?: string;
    hide?: boolean;
    minHeight?: number;
    title: string | JSX.Element;
    body: string | JSX.Element;
    buttons: IDialogButton[];
    onClose?: () => void;
}

export class Dialog extends React.Component<IDialogProp, {}> {
    private dialogContainer: HTMLDivElement;
    private dialogDiv: HTMLDivElement;
    private headerDiv: HTMLDivElement;
    private displaceJs: DisplaceJSObject;
    private dialogDivSizeHash: string;
    private resizeObserver: ResizeObserver;

    handleButtonClick = (button: IDialogButton) => {
        if (!button) return;
        if (button.disabled) return;
        button.onClick();
    };

    // ReSharper disable UnusedParameter
    handleCloseClick = (e) => {
        if (!Base.isNullOrUndefined(this.props.onClose)) {
            this.props.onClose();
        }
    };
    // ReSharper restore UnusedParameter

    updateDragConstraints = Base.debounce((obj: Dialog) => {
        const dialogRect = obj.dialogDiv.getBoundingClientRect();
        const dialogDivSizeHash = dialogRect.width.toString(10) + "#" + dialogRect.height.toString(10);
        if (dialogDivSizeHash === obj.dialogDivSizeHash) return;
        obj.displaceJs = (displace as any)(obj.dialogDiv, { handle: obj.headerDiv, constrain: true, relativeTo: obj.dialogContainer });
        obj.dialogDivSizeHash = dialogDivSizeHash;
    }, 100);

    handleUpdateDragConstraints = () => {
        this.updateDragConstraints(this);
    };

    setDialogInitialLocation = () => {
        let minHeight = 200;
        if (!Base.isNullOrUndefined(this.props.minHeight)) {
            minHeight = this.props.minHeight;
        }
        const containerRect = this.dialogContainer.getBoundingClientRect();
        const dialogRect = this.dialogDiv.getBoundingClientRect();
        this.dialogDiv.style.left = Math.floor(Math.max((containerRect.width - dialogRect.width) / 2, 0)).toString(10) + "px";
        this.dialogDiv.style.top = Math.floor(Math.min(Math.max((Math.max(containerRect.height, minHeight) - dialogRect.height) / 2, 0), Math.max(containerRect.height, minHeight) / 5)).toString(10) + "px";
        this.dialogDivSizeHash = dialogRect.width.toString(10) + "#" + dialogRect.height.toString(10);
        this.displaceJs = (displace as any)(this.dialogDiv, { handle: this.headerDiv, constrain: true, relativeTo: this.dialogContainer });
    };

    componentDidMount(): void {
        if (this.props.hide) return;
        this.setDialogInitialLocation();
        this.resizeObserver = null;
        if (this.dialogDiv) {
            this.resizeObserver = new ResizeObserver(this.handleUpdateDragConstraints);
            this.resizeObserver.observe(this.dialogDiv);
        }
    }

    componentDidUpdate(prevProps: IDialogProp) {
        const props = this.props;
        if (!!prevProps.hide === !!props.hide) return;
        if (props.hide) return;
        this.setDialogInitialLocation();
    }

    componentWillUnmount(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (Base.isNullOrUndefined(this.displaceJs)) return;
        this.displaceJs.destroy();
    }

    render() {
        const props = this.props;
        // Create button
        const dialogButtons: JSX.Element[] = [];
        for (let i = 0; i < props.buttons.length; i++) {
            const button = props.buttons[i];
            if (button.onClick === null) continue;
            dialogButtons.push(<Button key={i} variant="outlined" title={button.title} disabled={button.disabled} onClick={() => this.handleButtonClick(button)} />);
        }
        const dialogClasses = "jj-dialog modal fade in show" + (!props.hide ? "" : " d-none") + (props.classes ? " " + props.classes : "");
        return (
            <div className={dialogClasses} ref={(div) => { this.dialogContainer = div; }} >
                <div className="modal-dialog" ref={(div) => { this.dialogDiv = div; }} >
                    <div className="modal-content">
                        <div className="modal-header draggable" ref={(div) => { this.headerDiv = div; }} >
                            <h5 className="modal-title">{props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseClick}>
                                <span className="icon closeWhite" aria-hidden="true"></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {props.body}
                        </div>
                        <div className="modal-footer">
                            {dialogButtons}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// ConfirmationDialog
// ***********************************************************************************************************************
export interface IConfirmationDialogCallback {
    (): void;
}

export interface IConfirmationDialogCall {
    (title: string, text: any, yesCallback: IConfirmationDialogCallback, noCallback: IConfirmationDialogCallback): void;
}

export interface IConfirmationDialogProp {
    classes?: string;
    type: storeTypes.ConfirmationDialogType;
    title: string;
    text: string | JSX.Element;
    yesConfirmationText?: string;
    onYes: IConfirmationDialogCallback;
    onNo: IConfirmationDialogCallback;
    onCancel: IConfirmationDialogCallback;
}

export interface IConfirmationDialogState {
    confirmation: string;
}

export class ConfirmationDialog extends React.Component<IConfirmationDialogProp, IConfirmationDialogState> {
    constructor(props) {
        super(props);
        this.state = { confirmation: "" };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "confimation") {
            this.setState({ confirmation: value });
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "confirmation" + (props.type === storeTypes.ConfirmationDialogType.Warning ? " warning" : " information") + (props.classes ? " " + props.classes : "");
        const buttons: IDialogButton[] = [
            { title: Translations.Yes, classes: "btn-primary", onClick: props.onYes, disabled: !!props.yesConfirmationText && !state.confirmation.equalIgnoreCase(props.yesConfirmationText) },
            { title: Translations.No, classes: "btn-default", onClick: props.onNo }];
        if (!Base.isNullOrUndefined(props.onCancel)) {
            buttons.push({ title: Translations.Cancel, classes: "btn-default", onClick: props.onCancel });
        }
        return (
            <Dialog
                classes={dialogClasses}
                title={props.title}
                body={Base.isString(props.text) && props.yesConfirmationText
                    ? <div className="row">
                        <div className="col-12">{props.text}</div>
                        <div className="col-12 confirmationText">
                            <label className="control-label">{String.format(Translations.WriteBelowFieldWordParameterToConfirmTheOperation, props.yesConfirmationText)}</label>
                            <input className="form-control" type="text" name="confimation" title={String.format(Translations.WriteBelowFieldWordParameterToConfirmTheOperation, props.yesConfirmationText)} maxLength={props.yesConfirmationText.length} readOnly={false} disabled={false} value={state.confirmation} required={true} onChange={this.handleChange} />
                        </div>
                    </div>
                    : props.text
                }
                buttons={buttons}
                onClose={props.onCancel ? props.onCancel : props.onNo}
            />
        );
    }
}