export interface ISaveSuccess {
    message: string;
    errorMessage: string;
    id: string;
    rowId: string;
}

export class SaveSuccess implements ISaveSuccess {
    message: string;
    errorMessage: string;
    id: string;
    rowId: string;

    constructor();
    constructor(obj: ISaveSuccess);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
        this.errorMessage = obj && obj.errorMessage || "";
        this.id = obj && obj.id || "";
        this.rowId = obj && obj.rowId || "";
    }
}