import styled from "@emotion/styled";
import { Card as MuiCard, CardContent as MuiCardContent, Typography } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";

export const Label = styled(Typography)`
    color: #848688;
`;

export const Card = styled(MuiCard)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: spece-between;
`;

export const CardContent = styled(MuiCardContent)`
    flex-grow: 2;
`;

export const DescriptionText = styled(Typography)`
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;       
    }

    @media screen and (min-width: 900px) {
        max-height: 180px;
        overflow-y: auto;
    }
`;

export const CalendarIcon = styled(CalendarMonthOutlinedIcon)`
    color: #667382;
    margin-right: .25rem;
`;

export const ClockIcon = styled(ScheduleOutlinedIcon)`
    color: #667382;
    margin-left: 1rem;
    margin-right: .25rem;
`;

export const ResolutionTitle = styled(Typography)`
    color: #616d7c;
    margin: 1rem 0;
`;