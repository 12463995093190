import { ApiSuccess } from "ui/Scripts/source/models/common/apiSuccess";
import { SaveSuccess } from "ui/Scripts/source/models/common/saveSuccess";
import { WhistleBlowInformantEditItem } from "ui/Scripts/source/models/whistleBlow/whistleBlowInformantEditItem";
import { WhistleBlowSaveSuccess } from "ui/Scripts/source/models/whistleBlow/whistleBlowSaveSuccess";
import { postEncApiCall, postEncFileApiCall } from "./baseService";

export const saveWhistleBlow = (obj: any): Promise<WhistleBlowSaveSuccess> => {
    return postEncApiCall("api/whistleblow/save", WhistleBlowSaveSuccess, obj);
};

export const confirmWhistleBlowPassword = (companyId: string, password: string): Promise<ApiSuccess> => {
    return postEncApiCall("api/whistleblow/passwordconfirm", ApiSuccess, {
        companyId,
        password
    });
};

export const getWhistleBlow = (companyId: string, password: string): Promise<WhistleBlowInformantEditItem> => {
    return postEncApiCall("api/whistleblow/open", WhistleBlowInformantEditItem, {
        companyId,
        password
    });
};

export const saveWhistleBlowMessage = (obj: any): Promise<SaveSuccess> => {
    return postEncApiCall("api/whistleblow/savemessage", SaveSuccess, obj);
};

export const sendWhistleBlowMessageReadReceipts = (companyId: string, ids: string[]): Promise<ApiSuccess> => {
    return postEncApiCall("api/whistleblow/sendreadreceipts", ApiSuccess, {
        companyId,
        ids
    });
};

export const getAttachment = (companyId: string, whistleBlowId: string, whistleBlowMessageId: string, attachmentId: string): Promise<string> => {
    return postEncFileApiCall("api/whistleblow/getattachment", {
        companyId,
        whistleBlowId,
        whistleBlowMessageId,
        attachmentId
    });
};
