import { Base } from "../../framework/base";
import { EData, IEData } from "./eData";

export interface IEncryptedResponse {
    d: string;
    eData: IEData;
}

export class EncryptedResponse implements IEncryptedResponse {
    d: string;
    eData: EData;

    constructor();
    constructor(obj: IEncryptedResponse);
    constructor(obj?: any) {
        this.d = obj && obj.d || "";
        this.eData = obj?.eData ? new EData(obj.eData) : null;
    }

    async getDecryptedObject<T>(eData: IEData, type: (new (...args: any[]) => T)): Promise<T> {
        const jsonStr = await Base.decryptString(this.d, eData.k, eData.i);
        /* eslint-disable new-cap */
        if (!jsonStr) {
            return type ? new type() : <T><unknown>0;
        }
        const json = JSON.parse(jsonStr);
        return type
            ? !json ? new type() : new type(json)
            : !json ? <T><unknown>0 : <T><unknown>(json);
        /* eslint-enable new-cap */
    }
}