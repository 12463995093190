// AlertBox
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "ui/Scripts/source/framework/base";

export interface IAlertBoxProp {
    success?: boolean;
    info?: boolean;
    error?: boolean;
    classes?: string;
    containerClasses?: string;
    message: string;
    onClick?: () => void;
}

export class AlertBox extends React.Component<IAlertBoxProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className={(props.containerClasses ? props.containerClasses : "")}>
                <div className={"alert " + (props.success ? "alert-success" : (props.info ? "alert-info" : (props.error ? "alert-danger" : ""))) + (props.classes ? " " + props.classes : "")} role="alert" onClick={props.onClick}>{!Base.isFormattedText(props.message) ? props.message : <div dangerouslySetInnerHTML={{ __html: Base.getFormattedText(props.message) }}></div>}</div>
            </div>
        );
    }
}