import * as React from "react";

export const FlagFi = ({
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="finland" clipPath="url(#clip0_27_6073)">
            <path id="Vector" d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#F0F0F0"/>
            <path id="Vector_2" d="M21.9069 9.56548H8.60875H8.6087V0.261719C7.59215 0.487133 6.6284 0.852496 5.73912 1.33774V9.5654V9.56544H0.0931133C0.0318828 10.0351 0 10.514 0 11.0003C0 11.4865 0.0318828 11.9654 0.0931133 12.435H5.73908H5.73912V20.6627C6.6284 21.1479 7.59215 21.5134 8.6087 21.7387V12.4352V12.4351H21.9069C21.968 11.9654 22 11.4865 22 11.0003C22 10.514 21.968 10.0351 21.9069 9.56548Z" fill="#0052B4"/>
        </g>
        <defs>
            <clipPath id="clip0_27_6073">
                <rect width="22" height="22" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);
