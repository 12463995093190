export interface IFileDataUrlResponse {
    name: string;
    dataUrl: string;
    errorMessage: string;

    isSuccess(): boolean;
}

export class FileDataUrlResponse implements IFileDataUrlResponse {
    name: string;
    dataUrl: string;
    errorMessage: string;

    constructor();
    constructor(obj: IFileDataUrlResponse);
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.dataUrl = obj && obj.dataUrl || "";
        this.errorMessage = obj && obj.errorMessage || "";
    }

    isSuccess(): boolean {
        return !this.errorMessage && !!this.name && !!this.dataUrl;
    }
}