// WhistleBlowAttachmentList - MODULE
// ***********************************************************************************************************************
import downloadjs from "downloadjs";
import * as React from "react";
import { Base } from "ui/Scripts/source/framework/base";
import { FileAttachment, IFileAttachment } from "ui/Scripts/source/models/common/fileAttachment";
import { Translations } from "ui/Scripts/source/models/translations";
import { AppUtils } from "../../../framework/appUtils";
import * as whistleBlowService from "../../../models/services/whistleBlowService";
import { AttachmentList } from "../../attachment";
import { CameraPhoto } from "../../framework/CameraPhoto";

// WhistleBlowAttachmentList
export interface IWhistleBlowAttachmentListProp {
    accept?: string;
    isReadOnly?: boolean;
    companyId: string;
    whistleBlowId: string;
    maxAttachmentsTotalSize: number;
    attachments: IFileAttachment[];
    onShowCamera: (value: boolean) => void;
    onRemoved: (ids: string[]) => void;
    onAdded: (attachments: IFileAttachment[]) => void;
}

export interface IWhistleBlowAttachmentListState {
    cameraIds: string[];
    showCamera: boolean;
    showingCamera: boolean;
}

export const WhistleBlowAttachmentList = ({ attachments, companyId, maxAttachmentsTotalSize, onAdded, onRemoved, onShowCamera, whistleBlowId, accept, isReadOnly }: IWhistleBlowAttachmentListProp) => {
    const [state, setState] = React.useState<IWhistleBlowAttachmentListState>({
        cameraIds: [],
        showCamera: false,
        showingCamera: false
    });

    React.useEffect(() => {
        Base.getCameraIds()
            .then(ids => {
                setState((old) => ({ ...old, cameraIds: ids }));
            })
            .catch(() => {
                setState((old) => ({ ...old, cameraIds: [] }));
            });
    }, []);

    const handleDownload = async (id: string) => {
        if (!id) return;
        const attachment = attachments.find(i => i.id === id);
        if (!attachment) return;
        if (attachment.file) {
            downloadjs(attachment.file, attachment.name);
            return;
        }
        if (attachment.isNew()) return;
        console.log(attachment.isNew());
        
        const fileName = await AppUtils.callService(() => whistleBlowService.getAttachment(companyId, whistleBlowId, null, id));
        if (!fileName) return;
        AppUtils.showSuccessMessage(String.format(Translations.AttachmentParameterDownloaded, fileName));
    };

    const handleRemove = async (id: string) => {
        if (!id || !onRemoved) return;
        onRemoved([id]);
    };
    
    const save = async (attachments: FileAttachment[]): Promise<boolean> => {
        if (attachments.length < 1) return false;
        onAdded(attachments);
        return true;
    };
    
    const checkAttachmentsSingleFileSize = (files: FileAttachment[], maxAttachmentsTotalSize: number): boolean => {
        let result = true;
        files.forEach(file => {
            if (!AppUtils.validateFileAttachment(file, Translations.MaximumSizeOfSingleAttachment, maxAttachmentsTotalSize)) {
                result = false;
            }
        });
        return result;
    };

    const handleAdd = async (fileList: FileList) => {
        const newAttachments = await FileAttachment.addFileListToFileAttachments([], fileList, 0);
        //Check attachment types
        if (!AppUtils.checkAttachmentNames(newAttachments)) return;
        //Check single attachment total size
        if (!checkAttachmentsSingleFileSize(newAttachments, maxAttachmentsTotalSize)) return false;
        save(newAttachments);
    };

    //#region Camera
    const hasCameras = (): boolean => {
        return !Base.isNullOrUndefined(state.cameraIds) && state.cameraIds.length > 0;
    };
    
    const handleCameraStreamAvailable = () => {
        setState((old) => ({ ...old, showingCamera: true }));
    };

    const handleAddPhoto = () => {
        if (!hasCameras()) return;
        setState((old) => ({ ...old, showCamera: true }));
        onShowCamera(true);
    };

    const handleCameraPhotoOk = async (dataUrl: string) => {
        if (!dataUrl) {
            setState((old) => ({ ...old, showCamera: false, showingCamera: false }));
            onShowCamera(false);
            return;
        }
        const attachment = await FileAttachment.photoToFileAttachment(dataUrl, 0);
        if (!attachment) return;
        if (!await save([attachment])) return;
        setState((old) => ({ ...old, showCamera: false, showingCamera: false }));
        onShowCamera(false);
    };

    const handleCameraPhotoCancel = (permissionDenied: boolean) => {
        if (!permissionDenied) {
            setState((old) => ({ ...old, showCamera: false, showingCamera: false }));
        } else {
            setState((old) => ({ ...old, showCamera: false, showingCamera: false, cameraIds: [] }));
        }
        onShowCamera(false);
    };
    //#endregion Camera
    const showCamera = state.cameraIds.length > 0 && state.showCamera;
    const showingCamera = showCamera && state.showingCamera;

    return (
        <div>
            {!showingCamera &&
                    <AttachmentList
                        accept={accept}
                        isReadOnly={isReadOnly}
                        title={Translations.Attachments}
                        canShowCamera={hasCameras()}
                        attachments={attachments}
                        onRemoveAttachment={handleRemove}
                        onDownloadAttachment={handleDownload}
                        onAddAttachments={handleAdd}
                        onAddPhoto={handleAddPhoto}
                    />
            }
            {showCamera &&
                    <CameraPhoto
                        cameraIds={state.cameraIds}
                        cameraStreamAvailable={state.showingCamera}
                        onCameraStreamAvailable={handleCameraStreamAvailable}
                        onOkClick={handleCameraPhotoOk}
                        onCancelClick={handleCameraPhotoCancel}
                    />
            }
        </div>
    );
};