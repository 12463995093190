import AttachFileIcon from "@mui/icons-material/AttachFile";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import SendIcon from "@mui/icons-material/Send";
import { Box, List, SpeedDial, SpeedDialAction, TextField, Typography } from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import * as React from "react";
import { AttachmentIcon } from "source/components/framework/AttachmentIcon";
import { Base } from "ui/Scripts/source/framework/base";
import { FileAttachment, IFileAttachment } from "ui/Scripts/source/models/common/fileAttachment";
import { SaveAttachmentBase64 } from "ui/Scripts/source/models/common/saveAttachmentBase64";
import { FileType } from "ui/Scripts/source/models/enums";
import { Translations } from "ui/Scripts/source/models/translations";
import { IWhistleBlowInformantMessage, WhistleBlowInformantMessage } from "ui/Scripts/source/models/whistleBlow/whistleBlowInformantMessage";
import { AppUtils } from "../../../framework/appUtils";
import * as whistleBlowService from "../../../models/services/whistleBlowService";
import { CameraPhoto } from "../../framework/CameraPhoto";
import * as Styled from "./WhistleBlowMessageListNew.styles";
import { useDispatch } from "react-redux";
import { AppDispatch } from "source/models/store";
import { fetchEnded, fetchStarted } from "source/models/store/features/fetchCount";

// WhistleBlowMessageListNewAttachmentImage
export interface IWhistleBlowMessageListNewAttachmentImageProp {
    attachment: IFileAttachment;
    selectedId: string;
    onClick: (id: string) => void;
    onDropAttachments?: (e: any) => Promise<void>;
    onDragOverAttachments?: (e: any) => void;
    onDragEnterAttachments?: (e: any) => void;
    onDragLeaveAttachments?: (e: any) => void;
}

export const WhistleBlowMessageListNewAttachmentImage = ({ attachment, onClick, selectedId, onDragEnterAttachments, onDragLeaveAttachments, onDragOverAttachments, onDropAttachments }: IWhistleBlowMessageListNewAttachmentImageProp) => (
    <Styled.AttachmentImageContainer onClick={() => { onClick(attachment.id); }} title={attachment.name + " (" + Base.intToFileSizeStr(attachment.size, false) + ", " + Base.timeToDateStr(attachment.modifyDate) + ")"}
        onDrop={onDropAttachments} onDragOver={onDragOverAttachments} onDragEnter={onDragEnterAttachments} onDragLeave={onDragLeaveAttachments}>
        <Styled.AttachmentImage src={attachment.thumbnail} alt={attachment.name} onDrop={onDropAttachments} onDragOver={onDragOverAttachments} onDragEnter={onDragEnterAttachments} onDragLeave={onDragLeaveAttachments}/>
    </Styled.AttachmentImageContainer>
);

// WhistleBlowMessageListNewAttachment
export interface IWhistleBlowMessageListNewAttachmentProp {
    attachment: IFileAttachment;
    selectedId: string;
    onClick: (id: string) => void;
    onDropAttachments?: (e: any) => Promise<void>;
    onDragOverAttachments?: (e: any) => void;
    onDragEnterAttachments?: (e: any) => void;
    onDragLeaveAttachments?: (e: any) => void;
}

export const WhistleBlowMessageListNewAttachment = ({ attachment, onClick, selectedId, onDragEnterAttachments, onDragLeaveAttachments, onDragOverAttachments, onDropAttachments }: IWhistleBlowMessageListNewAttachmentProp) => {
    return (
        <Styled.AttachmentListItem key={attachment.id} onClick={(e) => { e.stopPropagation(); onClick(attachment.id); }} title={attachment.name + " (" + Base.intToFileSizeStr(attachment.size, false) + ", " + Base.timeToDateStr(attachment.modifyDate) + ")"}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center">
                    <AttachmentIcon fileType={attachment.type} color="#fff" strokeColor="#848688" />
                    <Box display="flex" flexWrap="wrap" alignItems="baseline" marginLeft="4px">
                        <Typography variant="caption">{attachment.name}</Typography>
                        <Styled.FileInfo variant="caption">{Base.intToFileSizeStr(attachment.size, false)}</Styled.FileInfo>
                    </Box>
                </Box>
            </Box>
        </Styled.AttachmentListItem>
    );
};

// WhistleBlowMessageListNew
export interface IWhistleBlowMessageListNewProp {
    classes?: string;
    companyId: string;
    whistleBlowId: string;
    maxAttachmentsTotalSize: number;
    onShowCamera: (value: boolean) => void;
    onWhistleBlowMessageSaved: (item: IWhistleBlowInformantMessage) => void;
    onWhistleBlowMessageIsEmpty: (value: boolean) => void;
}

interface IWhistleBlowMessageListNewState {
    content: string;
    attachments: FileAttachment[];
    sending: boolean;
    cameraIds: string[];
    showCamera: boolean;
    showingCamera: boolean;
}

export const WhistleBlowMessageListNew = ({ companyId, maxAttachmentsTotalSize, onShowCamera, onWhistleBlowMessageIsEmpty, onWhistleBlowMessageSaved, whistleBlowId, classes }: IWhistleBlowMessageListNewProp) => {
    const fileUploadRef = React.useRef<HTMLInputElement>(null);
    const dispatch = useDispatch<AppDispatch>();

    const [state, setState] = React.useState<IWhistleBlowMessageListNewState>({
        content: "",
        attachments: [],
        sending: false,
        cameraIds: [],
        showCamera: false,
        showingCamera: false
    });

    React.useEffect(() => {
        Base.getCameraIds()
            .then(ids => {
                setState((old) => ({ ...old, cameraIds: ids }));
            })
            .catch(() => {
                setState((old) => ({ ...old, cameraIds: [] }));
            });
    }, []);

    const getSendingDisabled = (): boolean => {
        return state.sending || !state.content && state.attachments.length < 1;
    };

    const updateSavedMessageToList = (id: string) => {
        const newMessage = new WhistleBlowInformantMessage();
        newMessage.id = id;
        newMessage.date = Base.getNowTicks();
        newMessage.content = state.content;
        newMessage.myMessage = true;
        newMessage.isRead = false;
        newMessage.attachments = state.attachments.slice(0);
        onWhistleBlowMessageSaved(newMessage);
    };

    const handleInstantMessageEdited = (content: string, attachments: FileAttachment[]) => {
        onWhistleBlowMessageIsEmpty(!content && attachments.length < 1);
    };

    const saveInstantMessage = async () => {
        if (getSendingDisabled()) return;
        setState((old) => ({ ...old, sending: true }));
        dispatch(fetchStarted());
        try {
            //Call server
            const request = {
                companyId,
                whistleBlowId,
                content: state.content,
                attachments: await SaveAttachmentBase64.fromFileAttachments(state.attachments.filter(i => !!i.file))
            };
            const result = await AppUtils.callService(() => whistleBlowService.saveWhistleBlowMessage(request), false, true);
            if (!result) return null;
            updateSavedMessageToList(result.id);
            setState((old) => ({
                ...old,
                content: "",
                attachments: [],
            }));
            handleInstantMessageEdited("", []);
        } finally {
            setState((old) => ({ ...old, sending: false }));
            dispatch(fetchEnded());
        }
    };

    const checkAttachmentsTotalSize = (attachments: FileAttachment[], maxAttachmentsTotalSize: number): boolean => {
        return AppUtils.validateFileAttachments(attachments, Translations.MaximumTotalSizeOfAttachmentsIsParameterMB, maxAttachmentsTotalSize);
    };

    // #region Attachments
    const handleAddAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length < 1) return;
        const newAttachments = await FileAttachment.addFileListToFileAttachments([], e.target.files, 0);
        if (newAttachments.length < 1) return;
        const attachments = state.attachments.concat(newAttachments);
        
        //Check attachment types
        if (!AppUtils.checkAttachmentNames(newAttachments)) return;
        //Check attachment total size
        if (!checkAttachmentsTotalSize(attachments, maxAttachmentsTotalSize)) return false;
        //Check single attachment total size
        if (!AppUtils.validateFileAttachments(attachments)) return;
        
        setState((old) => ({
            ...old,
            attachments
        }));
        handleInstantMessageEdited(state.content, attachments);
    };

    const handleAddAttachments = (newAttachments: FileAttachment[]) => {
        if (!newAttachments || newAttachments.length < 1) return;
        const attachments = state.attachments.slice(0);
        for (const newAttachment of newAttachments) {
            if (!newAttachment) return;
            attachments.push(newAttachment);
        }
        if (attachments.length < 1 || !AppUtils.validateFileAttachments(attachments)) return;
        setState((old) => ({
            ...old,
            attachments
        }));
        handleInstantMessageEdited(state.content, attachments);
    };

    //handleSetAttachmentDropZone = (value: boolean) => {
    //    if (!this.inputElement) return;
    //    if (value) {
    //        this.inputElement.classList.add("draggingOver");
    //    } else {
    //        this.inputElement.classList.remove("draggingOver");
    //    }
    //};

    //handlePasteAttachments = async (e: any) => {
    //    if (!e.clipboardData.files) {
    //        return;
    //    }
    //    const clipboardItems = e.clipboardData.files;
    //    const attachments = this.state.attachments.slice(0);
    //    for (const clipboardItem of clipboardItems) {
    //        const attachment = await this.props.getFileAttachment(clipboardItem, 0);
    //        if (!attachment) return;
    //        attachments.push(attachment);
    //    }
    //    if (attachments.length < 1 || !AppUtils.validateFileAttachments(attachments)) return;
    //    this.setState({
    //        attachments
    //    });
    //};

    const handleRemoveAttachment = (id: string) => {
        if (!id) return;
        const attachments = state.attachments.filter(i => i.id !== id);
        setState((old) => ({
            ...old,
            attachments
        }));
        handleInstantMessageEdited(state.content, attachments);
    };
    // #endregion Attachments

    //#region Camera
    const hasCameras = (): boolean => {
        return !Base.isNullOrUndefined(state.cameraIds) && state.cameraIds.length > 0;
    };
    
    const handleCameraStreamAvailable = () => {
        setState((old) => ({ ...old, showingCamera: true }));
    };

    const handleAddPhoto = () => {
        if (!hasCameras()) return;
        setState((old) => ({ ...old, showCamera: true }));
        onShowCamera(true);
    };

    const handleCameraPhotoOk = async (dataUrl: string) => {
        if (!dataUrl) {
            setState((old) => ({ ...old, showCamera: false, showingCamera: false }));
            onShowCamera(false);
            return;
        }
        const attachment = await FileAttachment.photoToFileAttachment(dataUrl, 0);
        if (!attachment) return;
        handleAddAttachments([attachment]);
        setState((old) => ({ ...old, showCamera: false, showingCamera: false }));
        onShowCamera(false);
    };

    const handleCameraPhotoCancel = (permissionDenied: boolean) => {
        if (!permissionDenied) {
            setState((old) => ({ ...old, showCamera: false, showingCamera: false }));
        } else {
            setState((old) => ({ ...old, showCamera: false, showingCamera: false, cameraIds: [] }));
        }
        onShowCamera(false);
    };
    //#endregion Camera

    //# region General
    const handleChange = (event) => {
        const value = event.target.value;
        setState((old) => ({ ...old, content: event.target.value }));
        handleInstantMessageEdited(value, state.attachments);
    };
    // #endregion General

    if (state.showCamera) {
        return (
            <CameraPhoto
                cameraIds={state.cameraIds}
                cameraStreamAvailable={state.showingCamera}
                onCameraStreamAvailable={handleCameraStreamAvailable}
                onOkClick={handleCameraPhotoOk}
                onCancelClick={handleCameraPhotoCancel}
            />
        );
    }
    const imageAttachments = state.attachments.filter(i => i.type === FileType.Image);
    const fileAttachments = state.attachments.filter(i => i.type !== FileType.Image);

    return (
        <Styled.Container>
            <Styled.AttachmentContainer display="flex" flexDirection="column">
                {state.attachments.length > 0 &&
                    <>
                        {imageAttachments.length > 0 &&
                            <Box display="flex" flexWrap="wrap">
                                {imageAttachments.map((attachment) =>
                                    <WhistleBlowMessageListNewAttachmentImage
                                        key={attachment.id}
                                        attachment={attachment}
                                        selectedId={""}
                                        onClick={handleRemoveAttachment}
                                        //    onDropAttachments={props.onDropAttachments}
                                        //    onDragOverAttachments={props.onDragOverAttachments}
                                        //    onDragEnterAttachments={props.onDragEnterAttachments}
                                        //    onDragLeaveAttachments={props.onDragLeaveAttachments}
                                    />
                                )}
                            </Box>
                        }
                        {fileAttachments.length > 0 &&
                            <List>
                                {fileAttachments.map((attachment) =>
                                    <WhistleBlowMessageListNewAttachment
                                        key={attachment.id}
                                        attachment={attachment}
                                        selectedId={""}
                                        onClick={handleRemoveAttachment}
                                    //    onDropAttachments={props.onDropAttachments}
                                    //    onDragOverAttachments={props.onDragOverAttachments}
                                    //    onDragEnterAttachments={props.onDragEnterAttachments}
                                    //    onDragLeaveAttachments={props.onDragLeaveAttachments}
                                    />
                                )}
                            </List>
                        }
                    </>
                }
            </Styled.AttachmentContainer>
            <input ref={fileUploadRef} type="file" name="attachments" className="file" style={{ display: "none" }} multiple={true} onChange={handleAddAttachment} />
            <TextField size="small" name="newMessage" title={Translations.NewMessage} multiline value={state.content} onChange={handleChange} fullWidth InputProps={{
                endAdornment:
                <Box position="relative">
                    <SpeedDial icon={<SpeedDialIcon />} ariaLabel={Translations.AddAttachment} sx={{ position: "absolute", right: "40px", bottom: "0" }} FabProps={{ size: "small" }}>
                        <SpeedDialAction
                            icon={<AttachFileIcon />}
                            tooltipTitle={Translations.AddAttachment}
                            onClick={() => fileUploadRef.current.click()}
                        />
                        {hasCameras()
                            ? (
                                <SpeedDialAction
                                    icon={<PhotoCameraOutlinedIcon />}
                                    tooltipTitle={Translations.AddPhoto}
                                    onClick={handleAddPhoto}
                                />
                            )
                            : null}
                    </SpeedDial>
                    <Styled.SendButton onClick={saveInstantMessage}>
                        <SendIcon color="info" />
                    </Styled.SendButton>
                </Box>
            }}/>
        </Styled.Container>
    );
};