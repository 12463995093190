import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWhistleBlowInformantEditItem } from "ui/Scripts/source/models/whistleBlow/whistleBlowInformantEditItem";
import { IWhistleBlowState } from "../../storeTypes";

const initialState: IWhistleBlowState = {
    dataId: 0,
    whistleBlow: null
};

const whistleBlowSlice = createSlice({
    name: "whistleBlow",
    initialState,
    reducers: {
        whistleBlowLoaded(state: IWhistleBlowState, action: PayloadAction<void>) {
            state.dataId = 1;
            state.whistleBlow = null;
        },
        whistleBlowSet(state: IWhistleBlowState, action: PayloadAction<IWhistleBlowInformantEditItem>) {
            state.dataId += 1;
            state.whistleBlow = action.payload;
        },
        whistleBlowCleared(state: IWhistleBlowState, _action: PayloadAction<void>) {
            state.dataId = 0;
            state.whistleBlow = null;
        }
    },
});

export const { whistleBlowLoaded, whistleBlowSet, whistleBlowCleared } = whistleBlowSlice.actions;

export default whistleBlowSlice.reducer;
