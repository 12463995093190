import { Base } from "../../framework/base";
import { IEData } from "./eData";

export interface IEncryptedRequest {
    h: string;
    d: string;
}

export class EncryptedRequest implements IEncryptedRequest {
    h: string;
    d: string;

    constructor();
    constructor(obj: IEncryptedRequest);
    constructor(obj?: any) {
        this.h = obj && obj.h || "";
        this.d = obj && obj.d || "";
    }

    static async createEncryptedRequest(eData: IEData, obj: any): Promise<EncryptedRequest> {
        const result = new EncryptedRequest();
        result.h = eData.h;
        result.d = await Base.encryptString(JSON.stringify(obj), eData.k, eData.i);
        return result;
    }
}