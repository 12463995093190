import { Base } from "../../framework/base";
import { FileType } from "../enums";
import { EditItem, IEditItem } from "./editItem";

export interface IFileAttachment extends IEditItem {
    subOwnerId: string;
    type: FileType;
    name: string;
    size: number;
    category: number;
    modifyDate: number;
    thumbnail: string;
    file: File;
}

export class FileAttachment extends EditItem implements IFileAttachment {
    subOwnerId: string;
    type: FileType;
    name: string;
    size: number;
    category: number;
    modifyDate: number;
    thumbnail: string;
    file: File;

    constructor();
    constructor(obj: IFileAttachment);
    constructor(obj?: any) {
        super(obj);
        this.subOwnerId = obj && obj.subOwnerId || "";
        this.type = obj && obj.type || FileType.Unknown;
        this.name = obj && obj.name || "";
        this.size = obj && obj.size || 0;
        this.category = obj && obj.category || 0;
        this.modifyDate = obj && obj.modifyDate || 0;
        this.thumbnail = obj && obj.thumbnail || "";
        this.file = null;
        if (obj && obj.file) {
            this.file = new File([obj.file], obj.file.name, { type: obj.file.type });
        }
    }

    static async fileToFileAttachment(file: File, category: number, subOwnerId: string = ""): Promise<FileAttachment> {
        if (Base.isNullOrUndefined(file)) return null;
        const attachment = new FileAttachment();
        attachment.id = Base.getGuid();
        attachment.subOwnerId = subOwnerId;
        attachment.name = file.name;
        attachment.size = file.size;
        attachment.type = Base.getFileType(attachment.name);
        attachment.category = category;
        attachment.modifyDate = (new Date()).getTime();
        attachment.thumbnail = null;
        attachment.file = file;
        if (attachment.type === FileType.Image) {
            const base64Picture = await Base.blobToBase64(file);
            attachment.thumbnail = base64Picture;
            return attachment;
        } else {
            return new Promise<FileAttachment>((resolve) => { resolve(attachment); });
        }
    }

    static async blobToFileAttachment(blob: Blob, category: number, subOwnerId: string = ""): Promise<FileAttachment> {
        if (!blob) return null;
        const time = (new Date()).getTime();
        const name = "Photo " + Base.timeToDateTimeStr(time, true) + ".jpg";
        const attachment = new FileAttachment();
        attachment.id = Base.getGuid();
        attachment.subOwnerId = subOwnerId;
        attachment.name = name;
        attachment.size = blob.size;
        attachment.type = Base.getFileType(name);
        attachment.category = category;
        attachment.modifyDate = time;
        attachment.thumbnail = null;
        attachment.file = Base.blobToFile(blob, name);
        const base64Picture = await Base.blobToBase64(blob);
        attachment.thumbnail = base64Picture;
        return attachment;
    }

    static async photoToFileAttachment(dataUrl: string, category: number, subOwnerId: string = ""): Promise<FileAttachment> {
        if (Base.isNullOrEmpty(dataUrl)) return null;
        return FileAttachment.blobToFileAttachment(Base.dataUriToBlob(dataUrl), category, subOwnerId);
    }

    static getFirstFileAttachmentFromFileList(fileList: FileList, category: number, subOwnerId: string = ""): Promise<FileAttachment> {
        if (fileList.length < 1) return new Promise<FileAttachment>((resolve) => { resolve(null); });
        return FileAttachment.fileToFileAttachment(fileList[0], category, subOwnerId);
    }

    static async addFileListToFileAttachments(attachments: FileAttachment[], fileList: FileList | File[], category: number, subOwnerId: string = ""): Promise<FileAttachment[]> {
        const promises: Promise<FileAttachment>[] = [];
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            if (Base.isNullOrUndefined(file)) continue;
            promises.push(FileAttachment.fileToFileAttachment(file, category, subOwnerId));
        }
        const result = attachments.slice(0);
        const newAttachments = await Promise.all(promises);
        for (const newAttachment of newAttachments) {
            if (Base.isNullOrUndefined(newAttachment)) continue;
            result.push(newAttachment);
        }
        return result;
    }

    static sortFileAttachments(items: IFileAttachment[]): IFileAttachment[] {
        if (!items && items.length < 2) return items;
        return items.sort((a: IFileAttachment, b: IFileAttachment) => { return (!a || !b) ? 0 : (Base.strCompare(a.name, b.name)); });
    }
}