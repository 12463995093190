import { Action } from "@reduxjs/toolkit";
import { IFrontPage } from "../front/frontPage";
import { IWhistleBlowInformantEditItem } from "ui/Scripts/source/models/whistleBlow/whistleBlowInformantEditItem";

export interface IUser {
    authenticated: boolean;
}

export interface ILoadingState {
    users: boolean;
}

/* eslint-disable no-unused-vars */
export enum ConfirmationDialogType {
    Warning,
    Information
}
/* eslint-enable no-unused-vars */

export interface IConfirmationCallback {
    (): void;
}

export interface IAlertState {
    message: string;
    info: string;
    error: string;
}

export interface IConfirmationState {
    type: ConfirmationDialogType;
    title: string;
    text: string;
    show: boolean;
    yesConfirmationText: string;
    onYes: IConfirmationCallback;
    onNo: IConfirmationCallback;
    onCancel: IConfirmationCallback;
}

export interface IFrontState {
    dataId: number;
    frontPage: IFrontPage;
}

export interface IWhistleBlowState {
    dataId: number;
    whistleBlow: IWhistleBlowInformantEditItem;
}

export interface IApplicationState {
    // Fetch
    fetchCount: number;
    // Alert
    alert: IAlertState;
    // Confirmation
    confirmation: IConfirmationState;
    // Front
    front: IFrontState;
    // WhistleBlow
    whistleBlow: IWhistleBlowState
}

// Action type constants
// ***********************************************************************

// Confirmation
export const CONFIRMATION_SET = "CONFIRMATION_SET";
export const CONFIRMATION_CLEAR = "CONFIRMATION_CLEAR";

type T_CONFIRMATION_SET = typeof CONFIRMATION_SET;
type T_CONFIRMATION_CLEAR = typeof CONFIRMATION_CLEAR;

// AutoLogout
export const AUTOLOGOUT_SET = "AUTOLOGOUT_SET";
export const AUTOLOGOUT_CLEAR = "AUTOLOGOUT_CLEAR";

type T_AUTOLOGOUT_SET = typeof AUTOLOGOUT_SET;
type T_AUTOLOGOUT_CLEAR = typeof AUTOLOGOUT_CLEAR;

// Action types
// ***********************************************************************
// Confirmation
export interface IConfirmationSet extends Action {
    type: T_CONFIRMATION_SET;
    dialogType: ConfirmationDialogType;
    title: string;
    text: string;
    show: boolean;
    yesConfirmationText: string;
    onYes: IConfirmationCallback;
    onNo: IConfirmationCallback;
    onCancel: IConfirmationCallback;
}

export interface IConfirmationClear extends Action {
    type: T_CONFIRMATION_CLEAR;
}

export type ConfirmationAction =
    | IConfirmationSet
    | IConfirmationClear;

// AutoLogout
export interface IAutoLogoutSet extends Action {
    type: T_AUTOLOGOUT_SET;
}

export interface IAutoLogoutClear extends Action {
    type: T_AUTOLOGOUT_CLEAR;
}

export type AutoLogoutAction =
    | IAutoLogoutSet
    | IAutoLogoutClear;
