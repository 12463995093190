import React, { useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header";
import * as Styled from "./Layout.styles";

export const Layout = () => {
    const { pathname } = useLocation();
    const contentRef = useRef<HTMLDivElement>(null);
    
    React.useEffect(() => {
        setTimeout(() => contentRef.current.scrollTo(0, 0), 0);
    }, [pathname]);

    return (
        <Styled.Container ref={contentRef}>
            <Header />
            <Styled.MainContent>
                <Outlet />
            </Styled.MainContent>
        </Styled.Container>
    );
};
