import React from "react";
import { FileType } from "ui/Scripts/source/models/enums";
import * as Styled from "./AttachmentIcon.styles";
import { SvgIcon } from "@mui/material";
import { SvgReport } from "../../../components/icons";

interface AttachmentIconProps {
    color: string;
    strokeColor: string;
    fileType: FileType;
}

const getFileType = (fileType: FileType) => {
    switch (fileType) {
    case FileType.Pdf:
        return "PDF";
    case FileType.Image:
        return "IMG";
    }
};

export const AttachmentIcon = ({ fileType, color, strokeColor }: AttachmentIconProps) => (
    <Styled.Container>
        <SvgIcon>
            <SvgReport color={color} stroke={strokeColor} />
        </SvgIcon>
        <Styled.Label fileType={fileType}>{getFileType(fileType)}</Styled.Label>
    </Styled.Container>
);
