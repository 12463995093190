import * as React from "react";

export const FlagEn = ({
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_261_222)">
            <path d="M19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10C0.5 4.75329 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.75329 19.5 10Z" fill="#F0F0F0" stroke="#9EA5AD"/>
            <path d="M2.06645 3.91016C1.28094 4.93215 0.688633 6.11008 0.34375 7.38973H5.54602L2.06645 3.91016Z" fill="#0052B4"/>
            <path d="M19.6553 7.38969C19.3104 6.11008 18.7181 4.93215 17.9326 3.91016L14.4531 7.38969H19.6553Z" fill="#0052B4"/>
            <path d="M0.34375 12.6074C0.688672 13.887 1.28098 15.065 2.06645 16.0869L5.5459 12.6074H0.34375Z" fill="#0052B4"/>
            <path d="M16.087 2.06648C15.065 1.28098 13.8871 0.688672 12.6074 0.34375V5.54598L16.087 2.06648Z" fill="#0052B4"/>
            <path d="M3.91016 17.9326C4.93215 18.7181 6.11008 19.3104 7.38969 19.6553V14.4531L3.91016 17.9326Z" fill="#0052B4"/>
            <path d="M7.38965 0.34375C6.11004 0.688672 4.93211 1.28098 3.91016 2.06645L7.38965 5.54594V0.34375Z" fill="#0052B4"/>
            <path d="M12.6074 19.6553C13.887 19.3104 15.065 18.7181 16.0869 17.9326L12.6074 14.4531V19.6553Z" fill="#0052B4"/>
            <path d="M14.4531 12.6074L17.9326 16.087C18.7181 15.065 19.3104 13.887 19.6553 12.6074H14.4531Z" fill="#0052B4"/>
            <path d="M19.9154 8.69566H11.3044H11.3044V0.0846484C10.8774 0.0290625 10.4421 0 10 0C9.55785 0 9.12262 0.0290625 8.69566 0.0846484V8.69559V8.69563H0.0846484C0.0290625 9.12262 0 9.55793 0 10C0 10.4421 0.0290625 10.8774 0.0846484 11.3043H8.69559H8.69563V19.9154C9.12262 19.9709 9.55785 20 10 20C10.4421 20 10.8774 19.971 11.3043 19.9154V11.3044V11.3044H19.9154C19.9709 10.8774 20 10.4421 20 10C20 9.55793 19.9709 9.12262 19.9154 8.69566Z" fill="#D80027"/>
            <path d="M12.6094 12.6094L17.0717 17.0718C17.277 16.8666 17.4727 16.6521 17.6595 16.4298L13.8391 12.6094H12.6094V12.6094Z" fill="#D80027"/>
            <path d="M7.39207 12.6094H7.39199L2.92969 17.0717C3.13484 17.2769 3.34934 17.4727 3.57168 17.6595L7.39207 13.839V12.6094Z" fill="#D80027"/>
            <path d="M7.39195 7.39215V7.39207L2.92961 2.92969C2.72438 3.13484 2.52859 3.34934 2.3418 3.57168L6.16223 7.39211H7.39195V7.39215Z" fill="#D80027"/>
            <path d="M12.6094 7.39199L17.0718 2.92957C16.8666 2.72434 16.6521 2.52855 16.4298 2.3418L12.6094 6.16223V7.39199Z" fill="#D80027"/>
        </g>
        <defs>
            <clipPath id="clip0_261_222">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
