export interface IApiError {
    status: number;
    message: string;
}

export class ApiError implements IApiError {
    status: number;
    message: string;

    constructor();
    constructor(obj: IApiError);
    constructor(obj?: any) {
        this.status = obj && obj.status || 200;
        this.message = obj && obj.message || "";
    }
}