﻿(function (window) {
    $number_type = Number;
    $number_type.__typeName = "Number";
    $number_type.__class = true;
    $number_prototype = $number_type.prototype;
    
    $number_prototype.toLocaleFixed = function (decimals, languageTag) {
        if (languageTag === null || languageTag === undefined) {
            languageTag = "fi-fi";
        }
        return this.toLocaleString(languageTag,
        {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    };

    $number_prototype.roundToDecimals = function (decimals) {
        if (decimals === null || decimals === undefined) {
            decimals = 2;
        }
        var divider = Math.pow(10, decimals);
        return Math.round(this * divider) / divider;
    };

    //isInteger: Polyfill
    if (!$number_type.isInteger) {
        $number_type.isInteger = function(value) {
            return typeof value === "number" && 
                isFinite(value) && 
                Math.floor(value) === value;
        };
    }
})(typeof window !== "undefined" ? window : this);

