import styled from "@emotion/styled";

export const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: auto;
`;

export const MainContent = styled.div`
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: center;
    margin: 174px 0 1rem 0;
}`;