import styled from "@emotion/styled";
import topRectangle from "../../../../../Devel/images/top-rectangle.png";

export const Container = styled.div`
    width: 100%;
    height: 174px;
    background: url(${topRectangle}) no-repeat top center;
    background-size: 100% 58px;
    background-color: #F2F2F2;
    border-top: 8px solid #156DEE;
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center; 
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1024px;
    -webkit-touch-callout: none;
    padding: 2.25rem 1rem;
    margin-top: 44px;
`;

export const Logo = styled.img`
    height: 3rem;
    cursor: pointer;
`;
