import { Base } from "../../framework/base";
import { FileType } from "../enums";
import { IFileAttachment } from "./fileAttachment";

export interface ISaveAttachmentBase64 {
    name: string;
    content: string;
    category: number;
    fieldName: string;
}

export class SaveAttachmentBase64 implements ISaveAttachmentBase64 {
    name: string;
    content: string;
    category: number;
    fieldName: string;

    constructor();
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.content = obj && obj.content || "";
        this.category = obj && obj.category || 0;
        this.fieldName = obj && obj.fieldName || "";
    }

    static async fromFileAttachment(fieldName: string, fileAttachment: IFileAttachment): Promise<SaveAttachmentBase64> {
        if (!fileAttachment) return null;
        const attachment = new SaveAttachmentBase64();
        attachment.name = fileAttachment.name;
        attachment.content = await Base.blobToBase64WithoutHeader(fileAttachment.file);
        attachment.category = fileAttachment.category;
        attachment.fieldName = fieldName;
        return attachment;
    }
    
    static async fromFileAttachments(fileAttachments: IFileAttachment[], fieldName: string = null): Promise<SaveAttachmentBase64[]> {
        const promises: Promise<SaveAttachmentBase64>[] = [];
        for (let i = 0; i < fileAttachments.length; i++) {
            const fileAttachment = fileAttachments[i];
            if (!fileAttachment) continue;
            promises.push(SaveAttachmentBase64.fromFileAttachment(fieldName ?? (fileAttachment.type === FileType.Image ? "photo" : ""), fileAttachment));
        }
        const result: SaveAttachmentBase64[] = [];
        const newAttachments = await Promise.all(promises);
        for (const newAttachment of newAttachments) {
            if (Base.isNullOrUndefined(newAttachment)) continue;
            result.push(newAttachment);
        }
        return result;
    }
}