import { Base } from "ui/Scripts/source/framework/base";
import { IEData, EData } from "ui/Scripts/source/models/common/eData";
import { FrontPageLanguageData, IFrontPageLanguageData } from "./frontPageLanguageData";

export interface IFrontPage {
    culture: string;
    companyId: string;
    companyName: string;
    companyLogo: string;
    privacyPolicyUrl: string;
    maxAttachmentsTotalSize: number;
    eData: IEData;
    languages: IFrontPageLanguageData[];
}

export class FrontPage implements IFrontPage {
    culture: string;
    companyId: string;
    companyName: string;
    companyLogo: string;
    privacyPolicyUrl: string;
    maxAttachmentsTotalSize: number;
    eData: EData;
    languages: FrontPageLanguageData[];

    constructor();
    constructor(obj: IFrontPage);
    constructor(obj?: any) {
        this.culture = obj && obj.culture || "";
        this.companyId = obj && obj.companyId || "";
        this.companyName = obj && obj.companyName || "";
        this.companyLogo = obj && obj.companyLogo || "";
        this.privacyPolicyUrl = obj && obj.privacyPolicyUrl || "";
        this.maxAttachmentsTotalSize = obj && obj.maxAttachmentsTotalSize || 0;
        this.eData = new EData(obj?.eData);
        this.languages = Base.getTypedArray(obj ? obj.languages : null, FrontPageLanguageData);
    }
}