import { Translations } from "./translations";

/* eslint-disable no-unused-vars */
export enum DialogButtonType {
    None = "0",
    Default = "1",
    Primary = "2",
    Danger = "3"
}

export enum DialogButtonSize {
    Normal = "0",
    Large = "1",
    Small = "2",
    VerySmall = "3",
}

export enum AttachmentImageSize {
    Normal = "0",
    Large = "1"
}

export enum FileType {
    Unknown,
    Word,
    Excel,
    PowerPoint,
    Pdf,
    Image,
    Archive,
    Text,
    Html,
    Document = 20
}

export enum Permission {
    None,
    Read,
    Edit,
    Full
}

export enum TaskRepetitionType {
    NotRepeated,
    Repeated
}

export enum TaskVisibility {
    PrivateAndPublic,
    Private,
    Public
}

export enum TaskDataRowDataType {
    String,
    Integer,
    Number,
    Date,
    Time,
    Boolean,
    Label,
    TimeSpan,
    StringArray,
    TreeStringArray
}

export enum TaskStateEditabilityType {
    Editable,
    Locked
}

export enum ApplicationPermissionType {
    Message, //0
    Document, //1
    CompanyGroup, //2
    Company, //3
    UserGroup, //4
    User, //5
    Log, //6
    Record, //7
    Event, //8
    Task, //9
    InstantMessage, //10
    WhistleBlow //12
}

export enum DatabaseRecordType {
    Message, //0
    Document, //1
    File, //2
    Event, //3
    Task, //4
    RecordUser, //5
    Company, //6
    CompanyGroup, //7
    UserGroup, //8
    User, //9
    InstantMessage, //10
    WhistleBlow //11
}

export enum FrontPagePanelType {
    Message, //0
    Event, //1
    Image, //2
    UserModifiedRecords, //3
    OtherModifiedRecords, //4
    ToDo, //5
    Banner = 99
}

export enum ContentPagePanelType {
    Statistics, //0
    MyFiltersAndReports, //1
    SharedFiltersAndReports, //2
    StandardFiltersAndReports, //3
    Chart
}

export enum FrontPagePanelSize {
    None, //0
    OneXOne, //1
    TwoXOne, //2
    OneXTwo, //3
    TwoXTwo //4
}

export enum CultureCode {
    Fi = "fi",
    En = "en-US",
    Sv = "sv-FI"
}

export enum GroupType {
    None,
    UserGroup,
    CompanyGroup
}

export enum GroupPermissionLevel {
    None, //0
    Read,
    Write,
    Admin
}

export enum UserGroupAuthorizationLevel {
    None, //0
    User,
    SuperUser, //2 = Ulkoinen auditoija
    Admin,
    SystemAdmin
}

export enum CompanyGroupType {
    Normal,
    System,
    Fixed //Fixed one company group (including ALL_PARTNERS group!)
}

export enum UserGroupType {
    Normal,
    System, //Not in use
    Fixed //Fixed one user group
}

export enum RecordEmailNotificationType {
    NotInUse,
    Expiration090,
    Expiration120,
    Expiration180
}

export enum UserType {
    Normal,
    External,
    Removed = 99
}

export enum OptionDataType {
    String,
    Integer,
    Boolean,
    GuidArray
}

export enum CompanyActiveState {
    NotInUse,
    InTesting,
    InUse
}

export enum ConfirmationDialogResult {
    Yes,
    No,
    Cancel
}

export enum RecordEmployeeType {
    Employee,
    Entrepreneur,
    AgencyWorker,
    Other,
    Intern
}

export enum RecordEducationScopeUnit {
    Hour,
    Day,
    CourseCredit,
    StudyWeek
}

export enum RecordUserLogEntryType {
    Create,
    Open,
    Print,
    Report,
    Save
}

export enum RecordUserAttachmentCategory {
    RecordUser,
    Introduction,
    FurtherEducation,
    Employment,
    Salary,
    TransferredProperty,
    Familiarization
}

export enum UserRemoveType {
    RemoveUser,
    RemoveUserAndRecordUserArchive,
    RemoveUserAndRecordUser
}

export enum RecordUserRemoveType
{
    RemoveRecordUser,
    RemoveRecordUserAndUserExpired,
    RemoveRecordUserAndUser
}

export enum ReportType {
    Html,
    Pdf,
    Excel
}

export enum InstantMessageReadState {
    NotRead = 0,
    SomeRead = 1,
    AllRead = 2,
    UserRead = 3
}

export enum InstantMessageChannelArchivingType {
    None,
    KeepMessages,
    RemoveChannel
}

export enum InstantMessageChannelUserType {
    User,
    Admin
}

export enum InstantMessageChannelUserActiveState {
    Rejected,
    Active,
    Invited
}

export enum InstantMessageChannelMessageExpirationTime {
    None = 0,
    Quarter = 3,
    HalfYear = 6,
    Year = 12
}

export enum InstantMessageUserInvitationAcceptType {
    CannotInvite = 0,
    CanInvite = 1,
    AutoAcceptInvitation = 2
}

export enum SignalRConnectionState {
    Connecting = 0,
    Connected = 1,
    Reconnecting = 2,
    Disconnected = 4
}

export enum SignalRModuleType {
    Unknown = 0,
    InstantMessage = 1
}

export enum SignalRRecordType {
    Unknown = 0,
    InstantMessage = 1,
    InstantMessageChannel = 2
}

export enum EventAttachmentCategory {
    Basic,
    Action
}

export enum EventState
{
    Draft = 3,
    NotAssigned = 4,
    Open = 5,
    Closed = 6
}

export enum EventUserState
{
    NotHandler,
    NotApproved,
    Approved
}

export enum EventStateFilter {
    All = 0,
    Draft = 3,
    NotAssigned = 4,
    Open = 5,
    Closed = 6
}

export enum EventObserverActionUseType
{
    NotInUse,
    InUse,
    Required
}

export enum EventLocationUseType
{
    NotInUse,
    InUse,
}

export enum WhistleBlowState {
    New,
    Received,
    InProgress,
    Resolved,
    Archived = 99,
    Archived3 = 100,
    Archived2 = 101,
    Archived1 = 102
}

export enum WhistleBlowArchiveState {
    NotArchived,
    OnlyStateChange,
    InformantDataRemoved,
    AllDataRemoved
}

export enum WhistleBlowStateFilter {
    Active = -2,
    All = -1
}

export enum WhistleBlowSeverity {
    Unspecified,
    VeryLow,
    Low,
    Normal,
    High,
    Critical
}

export enum WhistleBlowResolution {
    Unspecified,
    Resolved,
    Rejected,
    Canceled,
    Junk,
    Other
}

export enum WhistleBlowSource {
    Unspecified,
    System,
    Phone,
    Email,
    Mail,
    Oral,
    Other
}

export enum WhistleBlowAnonymity {
    Anonymous,
    NonAnonymous
}

export enum WhistleBlowChannelResourceType
{
    Text,
    Info
}

export enum WhistleBlowArchivingType {
    ChangeState,
    ChangeStateAndRemoveInformantData,
    ChangeStateAndRemoveAllData
}

export enum DefaultHandlerType {
    Handler,
    Notfied
}

export enum EventTypeUsageType
{
    Normal,
    SelectForNew
}

export enum EventTypeCategory
{
    A,
    B,
    C
}

export enum UserFurtherEducationState {
    Valid = 0,
    Expired = 1,
    Archived = 2
}

export enum ReportSharingType {
    NotShared = 0,
    Company = 1,
    AllCompanies = 2
}

export class EnumHelper {
    static isEqual(i: number, e: FileType | Permission | TaskRepetitionType | TaskDataRowDataType | DatabaseRecordType | GroupType | GroupPermissionLevel | UserGroupAuthorizationLevel | FrontPagePanelType | ContentPagePanelType
        | FrontPagePanelSize | CompanyGroupType | UserGroupType | RecordEmailNotificationType | UserType | UserGroupAuthorizationLevel | OptionDataType | CompanyActiveState | ApplicationPermissionType
        | ConfirmationDialogResult | RecordEmployeeType | RecordEducationScopeUnit | RecordUserLogEntryType | RecordUserAttachmentCategory | UserFurtherEducationState | RecordUserRemoveType | ReportType | UserRemoveType
        | EventState | EventUserState
        | InstantMessageChannelUserActiveState | InstantMessageUserInvitationAcceptType | InstantMessageReadState | InstantMessageChannelArchivingType
        | WhistleBlowState | WhistleBlowSeverity | WhistleBlowResolution | WhistleBlowChannelResourceType | WhistleBlowArchivingType | WhistleBlowAnonymity | WhistleBlowArchiveState
        | DefaultHandlerType | EventTypeUsageType | EventTypeCategory
        | SignalRConnectionState | SignalRModuleType | SignalRRecordType): boolean {
        return i > e - 0.5 && i < e + 0.5;
    }
    
    static isLessThan(i: number, e: FileType | Permission | TaskRepetitionType | TaskDataRowDataType | WhistleBlowState): boolean {
        return i < e - 0.5;
    }
    
    static isGreaterThan(i: number, e: FileType | Permission | TaskRepetitionType | TaskDataRowDataType | GroupPermissionLevel | UserGroupAuthorizationLevel): boolean {
        return i > e + 0.5;
    }

    static isLessOrEqualThan(i: number, e: FileType | Permission | TaskRepetitionType | TaskDataRowDataType): boolean {
        return EnumHelper.isLessThan(i, e) || EnumHelper.isEqual(i, e);
    }

    static isGreaterOrEqualThan(i: number, e: FileType | Permission | TaskRepetitionType | TaskDataRowDataType | GroupPermissionLevel | UserGroupAuthorizationLevel): boolean {
        return EnumHelper.isGreaterThan(i, e) || EnumHelper.isEqual(i, e);
    }

    static UserGroupAuthorizationLevelToStr(authorizationLevel: UserGroupAuthorizationLevel): string {
        if (EnumHelper.isEqual(authorizationLevel, UserGroupAuthorizationLevel.SystemAdmin)) return Translations.AuthorizationLevelSystemAdmin;
        if (EnumHelper.isEqual(authorizationLevel, UserGroupAuthorizationLevel.Admin)) return Translations.AuthorizationLevelAdmin;
        if (EnumHelper.isEqual(authorizationLevel, UserGroupAuthorizationLevel.SuperUser)) return Translations.AuthorizationLevelSuperUser;
        if (EnumHelper.isEqual(authorizationLevel, UserGroupAuthorizationLevel.User)) return Translations.AuthorizationLevelUser;
        return Translations.AuthorizationLevelNone;
    }

    static ApplicationPermissionTypeToStr(applicationPermissionType: ApplicationPermissionType): string {
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Message)) return Translations.CurrentTopics;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Document)) return Translations.Documents;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.CompanyGroup)) return Translations.CompanyGroups;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Company)) return Translations.Companies;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.UserGroup)) return Translations.UserGroups;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.User)) return Translations.Users;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Record)) return Translations.UserRecords;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Event)) return Translations.Events;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Task)) return Translations.Tasks;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Log)) return Translations.Log;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.InstantMessage)) return Translations.InstantMessagesMenuTitle;
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.WhistleBlow)) return Translations.WhistleBlow;
        return "";
    }

    static ApplicationPermissionTypeToImageClass(applicationPermissionType: ApplicationPermissionType): string {
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Message)) return "message";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Document)) return "document";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.CompanyGroup)) return "companyGroup";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Company)) return "company";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.UserGroup)) return "userGroup";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.User)) return "user";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Record)) return "record";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Event)) return "event";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Task)) return "task";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.Log)) return "log";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.InstantMessage)) return "instantMessage";
        if (EnumHelper.isEqual(applicationPermissionType, ApplicationPermissionType.WhistleBlow)) return "whistleBlow";
        return "";
    }

    static RecordEmployeeTypeToStr(recordEmployeeType: RecordEmployeeType): string {
        if (EnumHelper.isEqual(recordEmployeeType, RecordEmployeeType.Intern)) return Translations.Intern;
        if (EnumHelper.isEqual(recordEmployeeType, RecordEmployeeType.Employee)) return Translations.Employee;
        if (EnumHelper.isEqual(recordEmployeeType, RecordEmployeeType.AgencyWorker)) return Translations.AgencyWorker;
        if (EnumHelper.isEqual(recordEmployeeType, RecordEmployeeType.Entrepreneur)) return Translations.Entrepreneur;
        if (EnumHelper.isEqual(recordEmployeeType, RecordEmployeeType.Other)) return Translations.Other;
        return "";
    }

    static RecordEducationScopeUnitToStr(recordEducationScopeUnit: RecordEducationScopeUnit): string {
        if (EnumHelper.isEqual(recordEducationScopeUnit, RecordEducationScopeUnit.Hour)) return Translations.Hour;
        if (EnumHelper.isEqual(recordEducationScopeUnit, RecordEducationScopeUnit.Day)) return Translations.Day;
        if (EnumHelper.isEqual(recordEducationScopeUnit, RecordEducationScopeUnit.CourseCredit)) return Translations.CourseCredit;
        if (EnumHelper.isEqual(recordEducationScopeUnit, RecordEducationScopeUnit.StudyWeek)) return Translations.StudyWeek;
        return "";
    }

    static RecordEducationScopeUnitToStrPlural(recordEducationScopeUnit: RecordEducationScopeUnit): string {
        if (EnumHelper.isEqual(recordEducationScopeUnit, RecordEducationScopeUnit.Hour)) return Translations.Hours;
        if (EnumHelper.isEqual(recordEducationScopeUnit, RecordEducationScopeUnit.Day)) return Translations.Days;
        if (EnumHelper.isEqual(recordEducationScopeUnit, RecordEducationScopeUnit.CourseCredit)) return Translations.CourseCredits;
        if (EnumHelper.isEqual(recordEducationScopeUnit, RecordEducationScopeUnit.StudyWeek)) return Translations.StudyWeeks;
        return "";
    }

    static RecordUserLogEntryTypeToStr(recordUserLogEntryType: RecordUserLogEntryType): string {
        if (EnumHelper.isEqual(recordUserLogEntryType, RecordUserLogEntryType.Create)) return Translations.RecordUserLogEntryTypeCreate;
        if (EnumHelper.isEqual(recordUserLogEntryType, RecordUserLogEntryType.Open)) return Translations.RecordUserLogEntryTypeOpen;
        if (EnumHelper.isEqual(recordUserLogEntryType, RecordUserLogEntryType.Print)) return Translations.RecordUserLogEntryTypePrint;
        if (EnumHelper.isEqual(recordUserLogEntryType, RecordUserLogEntryType.Report)) return Translations.RecordUserLogEntryTypeReport;
        if (EnumHelper.isEqual(recordUserLogEntryType, RecordUserLogEntryType.Save)) return Translations.RecordUserLogEntryTypeSave;
        return "";
    }

    static InstantMessageUserInvitationAcceptTypeToStr(instantMessageUserInvitationAcceptType: InstantMessageUserInvitationAcceptType): string {
        if (EnumHelper.isEqual(instantMessageUserInvitationAcceptType, InstantMessageUserInvitationAcceptType.CannotInvite)) return Translations.InstantMessageUserInvitationAcceptTypeCannotInvite;
        if (EnumHelper.isEqual(instantMessageUserInvitationAcceptType, InstantMessageUserInvitationAcceptType.CanInvite)) return Translations.InstantMessageUserInvitationAcceptTypeCanInvite;
        if (EnumHelper.isEqual(instantMessageUserInvitationAcceptType, InstantMessageUserInvitationAcceptType.AutoAcceptInvitation)) return Translations.InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation;
        return "";
    }

    static EventStateToStr(event: EventState): string {
        if (EnumHelper.isEqual(event, EventState.Draft)) return Translations.Draft;
        if (EnumHelper.isEqual(event, EventState.NotAssigned)) return Translations.NotAssigned;
        if (EnumHelper.isEqual(event, EventState.Open)) return Translations.StateOpen;
        if (EnumHelper.isEqual(event, EventState.Closed)) return Translations.Closed;
        return "";
    }

    static EventStateToPluralStr(event: EventState): string {
        if (EnumHelper.isEqual(event, EventState.Draft)) return Translations.Drafts;
        if (EnumHelper.isEqual(event, EventState.NotAssigned)) return Translations.NotAssigned;
        if (EnumHelper.isEqual(event, EventState.Open)) return Translations.StateOpenPlural;
        if (EnumHelper.isEqual(event, EventState.Closed)) return Translations.ClosedPlural;
        return "";
    }

    static WhistleBlowStateToStr(whistleBlowState: WhistleBlowState): string {
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowState.New)) return Translations.WhistleBlowStateNew;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowState.Received)) return Translations.WhistleBlowStateReceived;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowState.InProgress)) return Translations.WhistleBlowStateInProgress;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowState.Resolved)) return Translations.WhistleBlowStateResolved;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowState.Archived)) return Translations.WhistleBlowStateArchived;
        return "";
    }

    static WhistleBlowResolutionToStr(whistleBlowResolution: WhistleBlowResolution): string {
        if (EnumHelper.isEqual(whistleBlowResolution, WhistleBlowResolution.Unspecified)) return Translations.WhistleBlowResolutionUnspecified;
        if (EnumHelper.isEqual(whistleBlowResolution, WhistleBlowResolution.Resolved)) return Translations.WhistleBlowResolutionResolved;
        if (EnumHelper.isEqual(whistleBlowResolution, WhistleBlowResolution.Rejected)) return Translations.WhistleBlowResolutionRejected;
        if (EnumHelper.isEqual(whistleBlowResolution, WhistleBlowResolution.Canceled)) return Translations.WhistleBlowResolutionCanceled;
        if (EnumHelper.isEqual(whistleBlowResolution, WhistleBlowResolution.Junk)) return Translations.WhistleBlowResolutionJunk;
        if (EnumHelper.isEqual(whistleBlowResolution, WhistleBlowResolution.Other)) return Translations.WhistleBlowResolutionOther;
        return "";
    }

    static WhistleBlowSeverityToStr(whistleBlowState: WhistleBlowSeverity): string {
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowSeverity.Unspecified)) return Translations.WhistleBlowSeverityUnspecified;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowSeverity.VeryLow)) return Translations.WhistleBlowSeverityVeryLow;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowSeverity.Low)) return Translations.WhistleBlowSeverityLow;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowSeverity.Normal)) return Translations.WhistleBlowSeverityNormal;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowSeverity.High)) return Translations.WhistleBlowSeverityHigh;
        if (EnumHelper.isEqual(whistleBlowState, WhistleBlowSeverity.Critical)) return Translations.WhistleBlowSeverityCritical;
        return "";
    }
}
/* eslint-enable no-unused-vars */