/* global CanvasImageSource */
/// <reference types="react-scripts" />
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import * as React from "react";
import { Base } from "ui/Scripts/source/framework/base";
import { Translations } from "ui/Scripts/source/models/translations";
import { CustomIconButton } from "../CustomIconButton";

export interface ICameraPhotoProp {
    cameraIds: string[];
    cameraStreamAvailable: boolean;
    onCameraStreamAvailable: () => void;
    onOkClick: (dataUrl: string) => void;
    onCancelClick: (permissionDenied: boolean) => void;
}

interface ICameraPhotoState {
    showVideo: boolean;
    facingMode: string;
    cameraIds: string[];
}

export class CameraPhoto extends React.Component<ICameraPhotoProp, ICameraPhotoState> {
    private idealWidth = 1920;
    private idealHeight = 1080;
    private videoElement: HTMLVideoElement;
    private canvasElement: HTMLCanvasElement;
    private photoCaptureElement: HTMLDivElement;
    private saveCanvasElement: HTMLCanvasElement;
    private imageCapture: ImageCapture;
    private imageCaptureImageElement: HTMLImageElement;
    private imageCapturePhotoCapabilities: PhotoCapabilities;
    private isPortraitMode = true;
    private isLandscapeLeftMode = false;

    constructor(props) {
        super(props);
        this.imageCapture = null;
        this.imageCapturePhotoCapabilities = null;
        this.state = { showVideo: true, facingMode: "environment", cameraIds: props.cameraIds.slice(0) };
    }

    stopVideo = () => {
        const srcObject = this.videoElement.srcObject as MediaStream;
        if (Base.isNullOrUndefined(srcObject)) return;
        srcObject.getVideoTracks().forEach(track => {
            track.stop();
        });
    };

    disableImageCapture = () => {
        this.imageCapture = null;
        this.imageCaptureImageElement = null;
        this.imageCapturePhotoCapabilities = null;
    };

    showVideo = (facingMode: string) => {
        const obj = this;
        this.stopVideo();
        (navigator as any).mediaDevices
            .getUserMedia({
                video: {
                    facingMode,
                    width: { ideal: obj.idealWidth },
                    height: { ideal: obj.idealHeight }
                }
            })
            .then((stream) => {
                obj.videoElement.srcObject = stream;
                try {
                    const mediaStreamTrack = stream.getVideoTracks()[0];
                    obj.imageCapture = new ImageCapture(mediaStreamTrack);
                    obj.imageCapture.getPhotoCapabilities()
                        .then(function(photoCapabilities) {
                            //console.log(photoCapabilities);
                            obj.imageCapturePhotoCapabilities = photoCapabilities;
                            return obj.imageCapture.getPhotoSettings();
                        })
                        .then(function(photoSettings) {
                            //console.log(photoSettings);
                        })
                        .catch(error => console.error("imageCapture.getPhotoCapabilities Error:", error));
                } catch (e) {
                    obj.disableImageCapture();
                    console.log("mediaDevices.getUserMedia Stream processing error:", e);
                }
                this.props.onCameraStreamAvailable();
            }, (error) => { //Error
                console.log("mediaDevices.getUserMedia Error:", error);
                obj.props.onCancelClick(true);
            });
    };

    getCameraIds = () => {
        const obj = this;
        (navigator as any).mediaDevices
            .getUserMedia({
                audio: false,
                video: true,
            })
            .then((stream) => {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
                Base.getCameraIds().then(ids => {
                    obj.setState({ cameraIds: ids });
                    obj.showVideo(obj.state.facingMode);
                }).catch(() => {
                    obj.setState({ cameraIds: [] });
                });
            });
    };

    handleDeviceOrientation = (event: DeviceOrientationEvent) => {
        if (event && this.state.showVideo) {
            //console.log("event.alpha", event.alpha, "event.gamma", event.gamma, "event.beta", event.beta)
            const flatScreenUp = Math.abs(event.beta) < 15 && Math.abs(event.gamma) < 15;
            const flatScreenDown = Math.abs(event.beta) < 15 && Math.abs(event.gamma) > 165;
            if (!flatScreenUp && !flatScreenDown) {
                const beta = Math.abs(event.beta) % 180;
                if (beta >= 45) {
                    this.isPortraitMode = true;
                    this.isLandscapeLeftMode = false;
                } else {
                    this.isPortraitMode = false;
                    this.isLandscapeLeftMode = event.gamma < 0;
                }
            } else {
                this.isPortraitMode = true;
                this.isLandscapeLeftMode = false;
            }
            //console.log("this.isPortraitMode", this.isPortraitMode, "this.isLandscapeLeftMode", this.isLandscapeLeftMode)
        }
    };

    componentDidMount(): void {
        this.getCameraIds();
        window.addEventListener("deviceorientation", this.handleDeviceOrientation, true);
    }

    componentWillUnmount(): void {
        window.removeEventListener("deviceorientation", this.handleDeviceOrientation, true);
    }

    setImageToSaveCanvas = (image: CanvasImageSource, width: number, height: number) => {
        this.saveCanvasElement = document.createElement("canvas");
        this.saveCanvasElement.width = width;
        this.saveCanvasElement.height = height;
        this.saveCanvasElement.getContext("2d").drawImage(image, 0, 0);
    };

    setVideoToCanvas = () => {
        const ratio = this.videoElement.videoWidth / this.videoElement.videoHeight;
        let canvasWidth = this.videoElement.videoWidth;
        let canvasHeight = this.videoElement.videoHeight;
        const innerWidth = this.photoCaptureElement.clientWidth; //window.innerWidth
        const innerHeight = this.photoCaptureElement.clientHeight; //window.innerHeight
        if (canvasWidth > innerWidth) {
            canvasWidth = innerWidth;
            canvasHeight = Math.floor(canvasWidth / ratio);
        }
        if (canvasHeight > innerHeight) {
            canvasHeight = innerHeight;
            canvasWidth = Math.floor(canvasHeight * ratio);
        }
        this.canvasElement.width = canvasWidth;
        this.canvasElement.height = canvasHeight;
        this.canvasElement.getContext("2d").drawImage(this.videoElement, 0, 0, this.canvasElement.width, this.videoElement.videoHeight / (this.videoElement.videoWidth / this.canvasElement.width));
    };

    takePictureFromVideoStream = () => {
        this.disableImageCapture();
        this.setVideoToCanvas();
        this.setImageToSaveCanvas(this.videoElement, this.videoElement.videoWidth, this.videoElement.videoHeight);
        this.stopVideo();
        this.setState({ showVideo: false });
    };

    takePictureWithImageCapture = () => {
        const obj = this;
        const settings = {
            imageWidth: this.idealWidth,
            imageHeight: this.idealHeight
        };
        if (!Base.isNullOrUndefined(obj.imageCapturePhotoCapabilities) && !Base.isNullOrUndefined(obj.imageCapturePhotoCapabilities.imageWidth) && !Base.isNullOrUndefined(obj.imageCapturePhotoCapabilities.imageHeight)) {
            if (settings.imageWidth < obj.imageCapturePhotoCapabilities.imageWidth.min || settings.imageWidth > obj.imageCapturePhotoCapabilities.imageWidth.max ||
                settings.imageHeight < obj.imageCapturePhotoCapabilities.imageHeight.min || settings.imageWidth > obj.imageCapturePhotoCapabilities.imageHeight.max) {
                obj.takePictureFromVideoStream();
                return;
            }
        }
        this.imageCapture
            .takePhoto(settings)
            .then(blob => {
                obj.setVideoToCanvas();
                obj.stopVideo();
                obj.imageCaptureImageElement = document.createElement("img");
                obj.imageCaptureImageElement.src = URL.createObjectURL(blob);
                obj.imageCaptureImageElement.onload = () => {
                    //console.log("imageCapture", obj.imageCaptureImageElement.width, obj.imageCaptureImageElement.height);
                    obj.setImageToSaveCanvas(obj.imageCaptureImageElement, obj.imageCaptureImageElement.width, obj.imageCaptureImageElement.height);
                    URL.revokeObjectURL(obj.imageCaptureImageElement.src);
                    obj.setState({ showVideo: false });
                };
            })
            .catch((error) => {
                console.error("imageCapture.takePhoto Error:", error);
                obj.takePictureFromVideoStream();
            });
    };

    handleTakePhotoClick = () => {
        if (!Base.isNullOrUndefined(this.imageCapture)) {
            this.takePictureWithImageCapture();
        } else {
            this.takePictureFromVideoStream();
        }
    };

    handleSwitchCameraClick = () => {
        const facingMode = this.state.facingMode === "environment" ? "user" : "environment";
        this.setState({ facingMode });
        this.showVideo(facingMode);
    };

    //setCamera = (deviceId: string) => {
    //    this.setState({ deviceId: deviceId });
    //    this.showVideo(deviceId);
    //};

    getRotatedImageCanvas = async (): Promise<HTMLCanvasElement> => {
        //console.log("this.isPortraintMode", this.isPortraitMode, this.saveCanvasElement.height, this.saveCanvasElement.width)
        if (this.isPortraitMode) {
            return Base.getPromiseResult(this.saveCanvasElement);
        }
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();
        image.src = this.saveCanvasElement.toDataURL();
        return new Promise<HTMLCanvasElement>((resolve) => {
            image.onload = () => {
                // reset the canvas with new dimensions
                canvas.width = this.saveCanvasElement.height;
                canvas.height = this.saveCanvasElement.width;
                const cw = canvas.width;
                const ch = canvas.height;
                ctx.save();
                // translate and rotate
                if (!this.isLandscapeLeftMode) {
                    ctx.translate(cw, 0);
                } else {
                    ctx.translate(0, ch);
                }
                ctx.rotate((this.isLandscapeLeftMode ? -1 : 1) * Math.PI / 2);
                // draw the previows image, now rotated
                ctx.drawImage(image, 0, 0);
                ctx.restore();
                resolve(canvas);
            };
        });
    };

    handleOkClick = async () => {
        this.stopVideo();
        const canvas = await this.getRotatedImageCanvas();
        this.props.onOkClick(canvas.toDataURL("image/jpeg", 0.92));
    };

    handleCancelClick = () => {
        this.stopVideo();
        this.props.onCancelClick(false);
    };

    render() {
        const state = this.state;
        return (
            <div className="photoBackground">
                {!this.props.cameraStreamAvailable &&
                    <Backdrop open sx={{ backgroundColor: "transparent", color: "#fff", zIndex: 9999 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <video autoPlay playsInline muted className={"photoVideo" + (state.showVideo ? "" : " invisible")} style={{ height: window.innerHeight }} ref={(elem) => { this.videoElement = elem; }}></video>
                <div className={"photoCapture" + (!state.showVideo ? "" : " invisible")} ref={(elem) => { this.photoCaptureElement = elem; }}><canvas ref={(elem) => { this.canvasElement = elem; }}></canvas></div>
                {this.props.cameraStreamAvailable &&
                    <div className="toolbar camera center">
                        <div>
                            {state.showVideo && state.cameraIds.length > 1 &&
                                <CustomIconButton
                                    size="large"
                                    variant="outlined"
                                    tooltip={Translations.Change}
                                    onClick={this.handleSwitchCameraClick}
                                />
                            }
                            {!state.showVideo &&
                                <Box marginLeft="17px">
                                    <CustomIconButton
                                        size="large"
                                        variant="success"
                                        tooltip={Translations.OK}
                                        onClick={this.handleOkClick}
                                    >
                                        <DoneOutlinedIcon />
                                    </CustomIconButton>
                                </Box>
                            }
                        </div>
                        <div>
                            {state.showVideo &&
                                <CustomIconButton
                                    size="large"
                                    variant="outlined"
                                    tooltip={Translations.GetPhoto}
                                    onClick={this.handleTakePhotoClick}
                                >
                                    <CameraAltOutlinedIcon />
                                </CustomIconButton>
                            }
                        </div>
                        <div>
                            <CustomIconButton
                                size="large"
                                variant="error"
                                tooltip={Translations.Cancel}
                                onClick={this.handleCancelClick}
                            >
                                <CloseOutlinedIcon />
                            </CustomIconButton>
                        </div>
                    </div>
                }
            </div>
        );
    }
}