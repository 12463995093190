import styled from "@emotion/styled";
import { Box, Paper as MuiPaper, Typography } from "@mui/material";

export const Container = styled(Box)`
    width: 100%;
    max-width: 820px;
`;

export const Title = styled(Typography)`
    margin-bottom: .5rem;
`;

export const Subtitle = styled(Typography)`
    margin-bottom: 1rem;
`;

export const Paper = styled(MuiPaper)`
    padding: 1rem;
    max-width: 820px;
    margin-bottom: 1rem;

    @media screen and (min-width: 900px) {
        padding: 2.5rem;
    }
`;