import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 0;

const fetchCountSlice = createSlice({
    name: "fetchCount",
    initialState,
    reducers: {
        fetchStarted(state: number, _action: PayloadAction<void>) {
            return state + 1;
        },
        fetchEnded(state: number, _action: PayloadAction<void>) {
            return state - 1;
        },
    },
});

export const { fetchStarted, fetchEnded } = fetchCountSlice.actions;

export default fetchCountSlice.reducer;
