import styled from "@emotion/styled";
import { Box, IconButton, ListItem, Typography } from "@mui/material";

export const Container = styled(Box)`
    padding: 1rem;
`;

export const SendButton = styled(IconButton)`
    margin-left: 58px;
`;

export const FileInfo = styled(Typography)`
    margin-left: .5rem;
    color: #71839B;
`;

export const AttachmentContainer = styled(Box)`
    background: #F2F2F7;
    margin-bottom: -0.5rem;
    padding: .5rem;
    border-radius: .5rem .5rem 0 0;
`;

export const AttachmentListItem = styled(ListItem)`
    cursor: pointer;
    padding: 0;
`;

export const AttachmentImageContainer = styled.div`
    cursor: pointer;
    flex: 1;
    margin-bottom: 5px;
    margin-right: 5px;
    max-height: 100px;
    max-width: 100px;
`;

export const AttachmentImage = styled.img`
    height: 100%;
    max-height: 100px;
    max-width: 100px;
    object-fit: scale-down;
`;