// ReSharper disable InconsistentNaming
export class Translations_en_US {
	static DeletingRecordUserParameterWasSuccessful = 'User record \'{0}\' removed successfully!';
	static SavingEditableDocumentParameterApprovalWasSuccessful = 'Document \'{0}\' approved successfully!';
	static DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Failed to remove company group \'{0}\', because the data has been modified concurrently by another user!';
	static CompanyNumber = 'Company Number';
	static SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError = 'Failed to save document \'{0}\', because data in the document has been modified concurrently by another user!';
	static DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments = 'Do you really want to remove keyword \'{0}\'? Keyword used in {1} files/documents.';
	static UserRecords = 'User Records';
	static MobileAppInUse = 'Mobile app in use';
	static SendingPushNotificationsToParameter = 'Sending push notifications to user \'{0}\'...';
	static Competency = 'Competency';
	static DoYouReallyWantToClearLog = 'Do you really want to clear the log?';
	static And = 'and';
	static UpdatingGroupCodeWasNotSuccessfulErrorParameter = 'Failed to update group code! Error: \'{0}\'';
	static End = 'End';
	static UserReports = 'User record reports';
	static GroupByFamiliarization = 'Group by Familiarization';
	static NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded = 'New user record must be saved before attachments can be added!';
	static DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError = 'Failed to remove urgency class \'{0}\',  because data in the urgency class has been modified concurrently by another user!';
	static ShowOnMap = 'Show on Map';
	static CloseSystem = 'Close the System';
	static SaveReport = 'Save Report';
	static AddToFavorites = 'Add to Favorites';
	static ClickToChangeTheLogo = 'Click to change the logo';
	static DoYouReallyWantToRemoveEventPartnerParameter = 'Do you really want to remove incident partner \'{0}\'?';
	static RemoveTaskPriority = 'Remove Task Priority';
	static ANew = 'New';
	static FamiliarizationTrainer = 'Trainer';
	static NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded = 'New user record must be saved before qualifications can be added!';
	static StartDate = 'Visible From';
	static ResetDocumentVersion = 'Reset Document Version';
	static TogglePreviewPanel = 'Show/Hide Preview';
	static WhistleBlowSeverityLow = 'Low';
	static RemoveDefaultFurtherEducation = 'Remove Default Further Education';
	static RemoveDefaultTaskPriority = 'Remove Default Task Priority';
	static TasksFound = 'Tasks Found';
	static SavingWhistleBlowMessageWasNotSuccessfulErrorParameter = 'Failed to save message! Error: \'{0}\'';
	static FirstName = 'First Name';
	static DocumentApproved = 'Approved By';
	static RecordUsersFound = 'User Records Found';
	static UserFirstNameMustBeDefined = 'User first name must be defined!';
	static RecordUserReport = 'User Record Report';
	static AddLogisticVoucherTransportCompany = 'Add Transport Company';
	static RemoveTarget = 'Remove Target';
	static Comment = 'Comment';
	static EventReport = 'Incident Report';
	static DeletingRecordBasicEducationsParameterWasSuccessful = 'Basic educations \'{0}\' removed successfully!';
	static EventApprovalWasSuccessful = 'Incident approved!';
	static Visibility = 'Visibility';
	static LockedEventParameterCannotBeDeleted = 'Locked incident \'{0}\' cannot be removed!';
	static WhistleBlowStatisticsResolvedRecords = 'Resolved Reports';
	static AccessRightReadTasks = 'Handling tasks';
	static Copy = 'Copy';
	static UserGroupCodeMustBeDefined = 'User group code must be defined!';
	static WhistleBlowUrl = 'Whistle Blow Channel Web Address';
	static Favorites = 'Favorites';
	static SavingRecordUserQualificationParameterWasSuccessful = 'Qualification \'{0}\' saved successfully!';
	static NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded = 'New chat must be saved before participants can be added!';
	static AddingRecordUserFamiliarizationsWasSuccessful = 'Familiarizations added successfully!';
	static DoYouReallyWantToRemoveSelectedTaskTypes = 'Do you really want to remove selected task types?';
	static AccountIBANNumber = 'Bank Account IBAN Number';
	static PersonalIdentityCode = 'Personal Identity Code';
	static FileStorageQuotaGB = 'File Storage Quota (GB)';
	static Archived = 'Archived';
	static LockedTaskParameterCannotBeDeleted = 'Locked task \'{0}\' cannot be removed!';
	static SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError = 'Failed to save whistle blow category \'{0}\', because data in the whistle blow category has been modified concurrently by another user!';
	static TaskHandlers = 'Handlers';
	static AddEventTypeBsToEvent = 'Add category 2 by tapping the plus button.';
	static RemoveRecordUser = 'Remove User Record';
	static AddRecordUserToUserAccount = 'Add user record to existing user account';
	static DefaultEventUrgencyClassParameterCannotBeDeleted = 'Default urgency class cannot be removed!';
	static Saved = 'Saved By';
	static SavingUnitPermissionsWasNotSuccessfulConcurrencyError = 'Failed to save unit permissions, because permissions have been modified concurrently by another user!';
	static SaveReportAs = 'Save Report As';
	static OK = 'Ok';
	static Rows = 'rows';
	static AddUserGroupParameterWasNotSuccessfulErrorParameter = 'Failed to add user group \'{0}\'! Error: \'{1}\'';
	static MovedToTrashBin = 'Moved to Trash Bin';
	static AllUsersOfTheUnit = 'All users of the unit';
	static ChangedEventUnit = 'Changed incident unit';
	static SavingApplicationPermissionsWasNotSuccessfulErrorParameter = 'Failed to save system permissionsi! Virhe: \'{0}\'';
	static PanelVisible = 'Panel Visible';
	static Effectiveness = 'Effectiveness';
	static AddChildEventTypeB = 'Add Subcategory 2';
	static AddNew = 'Add New';
	static AdditionalFunctions = 'Additional Functions';
	static DeletingUserGroupsParameterWasSuccessful = 'User groups \'{0}\' removed successfully!';
	static MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter = 'Failed to move folder \'{0}\' into folder \'{1}\'! Error: \'{2}\'';
	static SelectUser = 'Select User Account';
	static WhistleBlowStatisticsAnonymousRecords = 'Anonymous Reports';
	static OldPasswordIsIncorrect = 'Old password is incorrect!';
	static StandardFiltersAndReports = 'Standard Filters and Reports';
	static ClassParameterHasNotBeenInitialized = 'Class \'{0}\' has not bee initialized!';
	static Permissions = 'Permissions';
	static NoEventsToShow = 'No incidents to show!';
	static RemoveRow = 'Remove Row';
	static ZipFileParameterNotFound = 'Zip file \'{0}\' not found!';
	static EventIsApprovedByUser = 'User has approved the incident';
	static DocumentNameConstainsIllegalCharacters = 'Document name contains forbidden characters (<, >, :, ", /, \, |, ? or *) or ends with forbidden character (., \' \')!';
	static WhistleBlowStatisticsReceivedRecords = 'Received Reports';
	static SavingEventTypeBsWasSuccessful = 'Category 2 saved successfully!';
	static AddImageFromClipboard = 'Add image from clipboard';
	static DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError = 'Failed to remove incident partner \'{0}\', because data in the incident partner has been modified concurrently by another user!';
	static EditEventType = 'Edit Category 1';
	static DeletingEventTypeParameterWasNotSuccessfulErrorParameter = 'Failed to remove category 1 \'{0}\'! Error: \'{1}\'';
	static AddFamiliarizations = 'Add Familiarizations';
	static SelectFurtherEducation = 'Select Further Education';
	static DeletingWhistleBlowCategoriesParameterWasSuccessful = 'Whistle blow categories \'{0}\' removed successfully!';
	static MessageVisibilityPeriodNotActive = 'Message visibility period not active!';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful = 'Folder \'{0}\' copied into folder \'{1}\' successfully!';
	static EditPermission = 'Edit Permission';
	static RemovingWhistleBlowHandlersWasSuccessful = 'Handlers removed successfully!';
	static AddPrint = 'Add Print';
	static RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError = 'Failed to restore task \'{0}\' from trash bin, because data in the task has been modified concurrently by another user!';
	static PaymentSettings = 'Payment Settings';
	static LockedTaskParameterCannotBeMovedToTrashBin = 'Locked task \'{0}\' cannot be moved to trash bin!';
	static ChooseHowYouWantToReport = 'Choose how you want to report';
	static CompanyInstantMessageChannels = 'Company Chats';
	static DeletingUsersParameterWasNotSuccessfulConcurrencyError = 'Failed to remove user accounts \'{0}\', because data has been modified concurrently by another user!';
	static AddEventType = 'Add Category 1';
	static Day = 'Day';
	static DoYouReallyWantToRemoveDocumentParameter = 'Do you really want to remove file/document \'{0}\'?';
	static EditFileFilter = 'Edit File Filter';
	static EventCreator = 'Creator';
	static UserEndDate = 'Validity Expires';
	static SavingOneTimePasswordParameterWasSuccessful = 'One time password saved successfully!';
	static NewVersionAvailable = 'New application version available! Application cannot be used before it is updated.';
	static DeletingUserGroupParameterWasNotSuccessfulConcurrencyError = 'Failed to remove user group \'{0}\', because data in the user group has been modified concurrently by another user!';
	static NotRepeated = 'Not Repeated';
	static ReplyToInstantMessage = 'Reply to message';
	static IgnoreFilePatterns = 'Ignore Files Pattern';
	static CopyPermissionToAllSameLevelFolders = 'Copy Permission to All Same Level Folders';
	static SelectState = 'Select State';
	static UserFilterActive = 'Active User Accounts';
	static ProjectNumber = 'Project Number';
	static EditableDocumentCodeMustBeDefined = 'Document name must be defined!';
	static DoYouReallyWantToRemoveRecordUserEmploymentParameter = 'Do you really want to remove employment \'{0}\'?';
	static UserRecordStatisticsExpiringInSixMonthsFamiliarizations = 'Familiarizations Expiring in Six Months';
	static PublishVoucherToFileSystem = 'Publish Voucher to File System';
	static WhistleBlowStatisticsNewRecords = 'New Reports';
	static NewInstantMessageChannelGroupInternal = 'New Group (company internal)';
	static ArchiveExpiredFamiliarizations = 'Archive Expired Familiarizations';
	static AuthorizationLevel = 'Authorization Level';
	static Locked = 'Locked';
	static MarkMessageAsRead = 'Mark message as read';
	static AuthorizationLevelAdmin = 'Administrators';
	static TaskTypesMustBeDefinedBeforeAddingTasks = 'Task types must be defined before adding tasks!';
	static SavingEventTypeBParameterWasSuccessful = 'Category 2 \'{0}\' saved successfully!';
	static InstantMessageAttachmentFolderIsNotSpecified = 'Application instant message attachment folder is not specified!';
	static DirectoryItemFolderParameterNotFound = 'Folder \'{0}\' not found!';
	static TasksExcelByTaskRows = 'Tasks - Excel - Filtered by Task Rows';
	static UserRecordStatisticsExpiringInSixMonthsQualifications = 'Qualifications Expiring in Six Months';
	static WhistleBlowStateInProgress = 'In Progress';
	static LockingTaskDataRowWasNotSuccessful = 'Failed to lock the task row!';
	static ExpiredPlural = 'Expired';
	static ACopy = 'Copy';
	static EventPartnerIsAlreadyInDefined = 'Incident partner is already defined!';
	static PublishingTaskTypeParameterWasSuccessful = 'Task type \'{0}\' published successfully!';
	static WhistleBlowInformantTitle = 'What is your concern?';
	static ChartType = 'Chart Type';
	static AddCommentsToEvent = 'Add comment by tapping the plus button.';
	static DeletingCompanyParameterWasNotSuccessfulErrorParameter = 'Failed to remove company \'{0}\'! Error: \'{1}\'';
	static WhistleBlowCategoryParameterNotFound = 'Whistle blow category \'{0}\' not found!';
	static UnlockingEventParameterWasNotSuccessful = 'Failed to unlock incident \'{0}\'!';
	static AddEventHandlersToEvent = 'Add handler by tapping the plus button.';
	static File = 'File';
	static EditEventTypeCs = 'Edit Category 3';
	static SavingEventTypeCsWasSuccessful = 'Category 3 saved successfully!';
	static WhistleBlowRecords = 'Records';
	static SavingInstantMessageChannelUserUserWasSuccessful = 'Participant saved successfully!';
	static DoCleanLog = 'Clear Log';
	static KeywordsOrCondition = 'Any of selected keywords';
	static FilterCondition = 'Filter Condition';
	static RemoveDefaultHandler = 'Remove Default Handler';
	static SavingKeywordParameterWasNotSuccessfulConcurrencyError = 'Failed to save keyword \'{0}\', because data in the keyword has been modified concurrently by another user!';
	static DeletingEventTypeCParameterWasSuccessful = 'Category 3 \'{0}\' removed successfully!';
	static FolderParameterAlreadyExists = 'Folder \'{0}\' already exists!';
	static EventUrgencyClasses = 'Urgency Classes';
	static EventTypeBCodeIsAlreadyInUse = 'Category 2 name is already in use!';
	static WhistleBlowDashboard = 'Whistleblower Dashboard';
	static WhistleBlowAnonymityNonAnonymous = 'Nonanonymous';
	static InheritedUserRights = 'Inherited Permissions';
	static Entrepreneur = 'Entrepreneur';
	static DeletingWhistleBlowChannelAdminsParameterWasSuccessful = 'Administrators \'{0}\' removed successfully!';
	static SavingFilterWasSuccessful = 'Filter saved successfully!';
	static UserIsCompanyAdminClaimNotFound = 'User claim \'userIsCompanyAdmin\' not found!';
	static ThisMessageWasDeleted = 'This message was deleted';
	static DeletingAttachmentsWasNotSuccessfulErrorParameter = 'Failed to remove attachments! Error: \'{0}\'';
	static YouHaveNotSavedChangesDoYouWantToSaveChanges = 'You have not saved changes that you have made. Do you want to save changes?';
	static SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError = 'Failed to save participant, because data in the participant has been modified concurrently by another user!';
	static DependencyResolvingFailedForInterfaceParameter = 'Implementation was not found for interface \'{0}\'!';
	static WhistleBlowInformantMessageConfirmation = 'Do you want to send new message to informant?';
	static WriteBelowFieldWordParameterToConfirmTheOperation = 'Write word \'{0}\' to the field below to confirm the operation';
	static FrontPage = 'Dashboard';
	static UserHasUsedMobile = 'User account has has been active in mobile application in last 30 days.';
	static Administrator = 'Administrator';
	static UserFilterInActive = 'Not in use';
	static MessageParameterNotFound = 'Message \'{0}\' not found!';
	static EditEventTypes = 'Edit Category 1';
	static IdCard = 'Identity Card';
	static Users = 'User Accounts';
	static RenewPersonalizationWithUser = 'Replace Personalization with Below User';
	static AccessRightWriteEvents = 'Creating and handling incidents';
	static AdditionalPermissionAMessage = 'Permission to read messages published by partners';
	static SavingEventPartnerParameterWasNotSuccessfulConcurrencyError = 'Failed to save incident partner \'{0}\', because data in the incident partner has been modified concurrently by another user!';
	static DocumentModified = 'Last Modified By';
	static DocumentNameParameterIsIllegal = 'Document name \'{0}\' is not allowed (reserved name)!';
	static DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove further educations \'{0}\',  because data in further educations has been modified concurrently by another user!';
	static RestoringInstantMessageWasNotSuccessfulErrorParameter = 'Failed to restore instant message! Error: \'{0}\'';
	static EditableDocumentUnpublishConfirmation = 'Do you want to remove the published file?';
	static SelectCompanyGroup = 'Select Company Group';
	static ActiveState = 'State';
	static TaskTypeCodeMustBeDefined = 'Task type code must be defined!';
	static DeletingInstantMessageUserParameterWasSuccessful = 'Participant \'{0}\' removed successfully!';
	static BasicInformation = 'General';
	static WorkShift = 'Work Shift';
	static InvalidValueInArgumentParameter = 'Invalid value in argument \'{0}\'!';
	static NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded = 'New user record must be saved before employments can be added!';
	static WhistleBlowSourceOral = 'Oral';
	static AddingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Failed to add task type \'{0}\'! Error: \'{1}\'';
	static WageGroup = 'Wage Group';
	static Valid = 'Valid';
	static Line = 'Line';
	static RecordUserLogEntryTypeSave = 'Modification';
	static ChangeSystemLanguageToEnglish = 'Change Language to English';
	static WhistleBlowInformantHint = 'Please provide a thorough description of the issue so that we can investigate it further.';
	static CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits = 'Copy Default Handlers and Notified Persons to All Same Level Units';
	static SavingCompanyParameterWasNotSuccessfulErrorParameter = 'Failed to save company \'{0}\'! Error: \'{1}\'';
	static MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful = 'Folder \'{0}\' moved into folder \'{1}\' successfully!';
	static UserGroupAll = '\'All Users\'';
	static SavingEventTypeParameterWasSuccessful = 'Category 1 \'{0}\' saved successfully!';
	static SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter = 'Failed to save familiarization \'{0}\'! Error: \'{1}\'';
	static DoYouReallyWantToRestoreThisInstantMessage = 'Do you really want to restore this instant message?';
	static AddPanel = 'Add Panel';
	static NotInUse = 'Not in Use';
	static CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter = 'Failed to check existance of folder \'{0}\' in filesystem! Error: \'{1}\'';
	static DoYouReallyWantToUnlockTask = 'Do you really want to unlock task \'{0}\'?';
	static SavingApplicationPermissionsWasSuccessful = 'System permissions saved successfully!';
	static Competencies = 'Competencies';
	static EventUrgencyClass = 'Urgency Class';
	static SavingEditableDocumentParameterWasSuccessful = 'Document \'{0}\' saved successfully!';
	static UserRecordStatisticsSavedCards = 'Saved Cards';
	static AllDocumentTypes = 'Show all document types';
	static SavePrintAs = 'Save Print As';
	static EmailingMessageWasNotSuccessfulErrorParameter = 'Failed to send message by email! Error: \'{0}\'';
	static DoYouReallyWantToRemoveTaskParameterPermanenty = 'Do you really want to permanently remove task \'{0}\'? Removed task cannot be restored?';
	static NewInstantMessageChannelGroup = 'New Group';
	static FileRemovalWasSuccessful = 'File removed successfully!';
	static SavingRecordUserParameterWasNotSuccessfulConcurrencyError = 'Failed to save user record \'{0}\', because user record data has been modified concurrently by another user!';
	static FamiliarizationStartDateDateEnd = 'Familiarization Start Date - End';
	static InUse = 'In Use';
	static MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter = 'Failed to move task \'{0}\' to trash bin! Error: \'{1}\'';
	static Number = 'Number';
	static DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove employments \'{0}\',  because data in employments has been modified concurrently by another user!';
	static RecordUserLogEntryTypeReport = 'Reporting';
	static Panels = 'Panels';
	static OthersLastModifications = 'Last Modifications by Company';
	static DoYouReallyWantToRemoveSharedDocumentTemplateParameter = 'Do you really want to remove shared document template \'{0}\'?';
	static EditDefaultHandlers = 'Edit Default Handlers';
	static AddQualifications = 'Add Qualifications';
	static DeletingFileFilterParameterWasNotSuccessfulErrorParameter = 'Failed to remove file filter \'{0}\'! Error: \'{1}\'';
	static Add = 'Add';
	static EventUrgencyClassParameterNotFound = 'Urgency class \'{0}\' not found!';
	static EditEventTypeC = 'Edit Category 3';
	static AddLogisticVoucherCustomer = 'Add Customer';
	static SelectInstantMessageChannelType = 'Select Chat Type';
	static DoYouReallyWantToRemoveEventTypeBParameter = 'Do you really want to remove category 2 \'{0}\'?';
	static CompanyName = 'Company Name';
	static RecordUserLogEntryTypeOpen = 'Reading';
	static RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter = 'Failed to remove user group links from external systems! Error: \'{0}\'';
	static AddPartnersByClickingPlusButton = 'Add partners by clicking the plus button.';
	static SavingChartWasNotSuccessfulConcurrencyError = 'Failed to save chart, because data in the chart has been modified concurrently by another user!';
	static DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter = 'Failed to remove task priority \'{0}\'! Error: \'{1}\'';
	static ContentFiltering = 'Content Filtering';
	static ApproveEventResolution = 'Approve Incident Resolution';
	static LockingEventParameterWasNotSuccessful = 'Failed to lock incident \'{0}\'!';
	static Report = 'Report';
	static SourceFolderAndTargetFolderAreSame = 'Source folder and destination folder are equal!';
	static SavingCompanyParameterWasNotSuccessfulConcurrencyError = 'Failed to save company \'{0}\', because data in the company has been modified concurrently by another user!';
	static AddRecordUserWithoutUserAccount = 'Add user record without user account';
	static WhistleBlowCategoryCodeIsAlreadyInUse = 'Whistle blow category name is already in use!';
	static SavingChartWasNotSuccessfulErrorParameter = 'Failed to save chart! Error: \'{0}\'';
	static SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError = 'Failed to save default incident handler \'{0}\', because data in the default incident handler has been modified concurrently by another user!';
	static LockedTaskParameterCannotBeRestored = 'Locked task \'{0}\' cannot be restored!';
	static UserFilterExpired = 'Expired';
	static DoYouReallyWantToRemoveSelectedRecordBasicEducations = 'Do you really want to remove selected basic educations?';
	static AddCompanyGroups = 'Add Company Groups';
	static DoYouReallyWantToRemoveAttachmentParameter = 'Do you really want to remove attachment \'{0}\'?';
	static SavingOneTimePasswordWasNotSuccessfulConcurrencyError = 'Failed to save one time password, because data in the one time password has been modified concurrently by another user!';
	static SaveEvent = 'Save Incident';
	static Select = 'Select';
	static WhistleBlowNonAnonymousReportInfo = 'Your name will only be known to those who handle your report, and it will remain anonymous to everyone else.';
	static AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter = 'Failed to add familiarizations! Error: \'{0}\'';
	static EditLogisticVoucherPaymentSetting = 'Edit Payment Setting';
	static ReadImp = 'Read';
	static AddKeyword = 'Add Keyword';
	static DocumentRootPath = 'Document Root Path';
	static DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove attachments \'{0}\', because data has been modified concurrently by another user!';
	static UserRecordStatisticsExpiredQualifications = 'Expired Qualifications';
	static PropertyTransferred = 'Transferred';
	static Images = 'Images';
	static CompanyCodeIsAlreadyInUse = 'Code of the company is already in use!';
	static ToDo = 'Things to Do';
	static DeletingRecordUserTransferredPropertyParameterWasSuccessful = 'Transferred property \'{0}\' removed successfully!';
	static CreatingNotifications = 'Creating notifications...';
	static RemoveRemainder = 'Remove Remainder';
	static Events = 'Incidents';
	static EditableDocumentApproveConfirmation = 'Do want to approve the document?';
	static DocumentTemplates = 'Document Templates';
	static SavingWhistleBlowWasNotSuccessfulErrorParameter = 'Failed to save whistle blow report! Error: \'{0}\'';
	static Theme = 'Theme';
	static AddUsers = 'Add User Accounts';
	static SaveUserFurtherEducation = 'Save Further Education';
	static DeletingMessageParameterWasNotSuccessfulErrorParameter = 'Failed to remove message \'{0}\'! Error: \'{1}\'';
	static Open = 'Open';
	static DeletingTaskPriorityParameterWasSuccessful = 'Task priority \'{0}\' removed successfully!';
	static DoYouReallyWantToRemoveSelectedEventUrgencyClasses = 'Do you really want to remove selected urgency classes?';
	static SavingImagesNotSuccessfulErrorParameter = 'Failed to save images! Error: \'{0}\'';
	static City = 'City';
	static SavingUserParametersWasSuccessful = 'User options saved successfully!';
	static ShowInheritedPermissions = 'Show Inherited Permissions';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter = 'Failed to copy folder \'{0}\' into folder \'{1}\'! Error: \'{2}\'';
	static WhistleBlowNewReport = 'New Report';
	static UnitHandlingIncomingEvents = 'Unit That Handles Incidents Sent by the Partner';
	static ExpiredFurtherEducations = 'Expired Further Educations';
	static SaveAndSend = 'Save and Send';
	static DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError = 'Failed to remove task types \'{0}\', because data in task types has been modified concurrently by another user!';
	static FurtherEducationsMustBeDefinedBeforeAddingRecordUsers = 'Further educations must be defined before adding user records!';
	static DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter = 'Failed to remove transferred property \'{0}\'! Error: \'{1}\'';
	static AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter = 'Failed to add administrator \'{0}\'! Error: \'{1}\'';
	static DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError = 'Failed to remove task states \'{0}\', because data in task states has been modified concurrently by another user!';
	static Familiarization = 'Familiarization';
	static Draft = 'Draft';
	static JobTypeEventNotification = 'Incident Notification';
	static DateMustBeDefined = 'Date must be defined!';
	static SetPermissions = 'Permissions Summary';
	static WhistleBlowStateReceived = 'Received';
	static GroupByPerson = 'Group by Person';
	static EventTypeCodeIsAlreadyInUse = 'Category 1 name is already in use!';
	static DoYouReallyWantToRemoveFolderParameter = 'Do you really want to remove folder \'{0}\'?';
	static AuthorizationLevelSuperUser = 'Superuser';
	static DoYouReallyWantToRemovePartnersParameter = 'Do you really want to remove partners \'{0}\'?';
	static Print = 'Print';
	static DoYouReallyWantToRemoveRemainderParameter = 'Do you really want to remove remainder \'{0}\'?';
	static FamiliarizationStartDate = 'Familiarization Start Date';
	static SearchCurrentTopics = 'Search Current Topics';
	static DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter = 'Failed to remove familiarizations \'{0}\'! Error: \'{1}\'';
	static RemoveFolder = 'Remove Folder';
	static ComparingFileSystemToDatabase = 'Comparing file system with database...';
	static SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter = 'Failed to save basic education \'{0}\'! Error: \'{1}\'';
	static MissingPlural = 'Missing';
	static DeletingOneTimePasswordWasNotSuccessfulConcurrencyError = 'Failed to remove one time password, because data in the one time password has been modified concurrently by another user!';
	static NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded = 'New employment must be saved before attachments can be added!';
	static UserRecordStatisticsExpiredFurtherEducations = 'Expired Further Educations';
	static RecordBasicEducationParameterNotFound = 'Basic education \'{0}\' not found!';
	static AdditionalPermissionAEvents = 'Acting as handler';
	static EInvoiceOperator = 'E-invoice Operator';
	static AccountNumberTkbOutDebit = 'Trade Payable Account';
	static Settings = 'Settings';
	static FileFilterCodeMustBeDefined = 'File filter code must be defined!';
	static DoYouReallyWantToRemoveChartPanelParameter = 'Do you really want to remove chart \'{0}\'?';
	static ClosedEvent = 'Closed The Incident';
	static FamiliarizationTime = 'Familiarization Time';
	static AddPartnerParameterWasNotSuccessfulConcurrencyError = 'Failed to add partner \'{0}\', because the data has been modified concurrently by another user!';
	static EmailSendDate = 'Email Sent Date';
	static Employment = 'Employment';
	static DeletingKeywordParameterWasNotSuccessfulErrorParameter = 'Failed to remove keyword \'{0}\'! Error: \'{1}\'';
	static PageFooterText = 'System Page Footer Text';
	static InstantMessageChannelUsers = 'Participants';
	static DeletingPartnersParameterWasNotSuccessfulConcurrencyError = 'Failed to remove partners \'{0}\', because the data has been modified concurrently by another user!';
	static TaskPriorities = 'Task Priorities';
	static DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError = 'Failed to remove transferred properties \'{0}\',  because data in transferred properties has been modified concurrently by another user!';
	static EditMode = 'Edit';
	static NotificationsSentSuccessfully = 'Notifications sent successfully!';
	static DoYouReallyWantToRemoveTaskDataRow = 'Do you really want to remove row?';
	static CompanyGroupCodeMustBeDefined = 'Code of the company group must be defined!';
	static DoYouReallyWantToRemoveTaskTypeParameter = 'Do you really want to remove task type \'{0}\'?';
	static FileParameterDownloaded = 'File \'{0}\' has been downloaded.';
	static AddPartnerCompanies = 'Add partners that content can be shared to';
	static RemoveRecordUserAndUserSetExpired = 'Remove User Record and Set User Account Expired';
	static SavingUnitPermissionsWasSuccessful = 'Unit permissions saved successfully!';
	static SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError = 'Failed to save basic education \'{0}\', because data in the basic education has been modified concurrently by another user!';
	static Companies = 'Companies';
	static NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded = 'New user record must be saved before familiarizations can be added!';
	static DoYouReallyWantToRemoveEventUrgencyClassParameter = 'Do you really want to remove urgency class \'{0}\'?';
	static SavingWhistleBlowWasNotSuccessfulConcurrencyError = 'Failed to save whistle blow report, because data in the report has been modified concurrently by another user!';
	static ThisPartOfApplicationIsNotInUse = 'This part of application is not in use!';
	static TimeSpanHalfYear = 'Half Year';
	static EnableExternalIntraSystemConfirmation = 'Do you really want to enable company in the system? Company data will be saved to the system when the company is saved.';
	static AccessRightReadEvents = 'Creating incidents';
	static Priority = 'Priority';
	static DocumentCreated = 'Created By';
	static TaskStatesMustBeDefinedBeforeAddingTasks = 'Task states must be defined before adding tasks!';
	static PrivacyPolicy = 'Privacy Policy';
	static Keywords = 'Keywords';
	static DoYouReallyWantToRemoveKeywordParameter = 'Do you really want to remove keyword \'{0}\'?';
	static DefaultLanguageHasBeenChanged = 'Language has been changed!';
	static DeletingEventParameterWasSuccessful = 'Incident \'{0}\' removed successfully!';
	static DefaultSettings = 'Default Settings';
	static CompanyGroupModuleProperties = 'Company Groups Properties';
	static WhistleBlowNotFound = 'Whistle blow report not found!';
	static DoYouReallyWantToRemoveMessageTypeParameter = 'Do you really want to remove message type \'{0}\'?';
	static StateOpen = 'Open';
	static CompanyGroupParameterNotFound = 'Company group \'{0}\' was not found!';
	static DeletingTaskTypesParameterWasSuccessful = 'Task types \'{0}\' removed successfully!';
	static JobTitle = 'Job Title';
	static RemoveFile = 'Remove File';
	static SaveMessage = 'Save Message';
	static AdministratorPasswordMustBeDefined = 'Password of the administrator must be defined!';
	static ExternalSystemCodeClaimNotFound = 'User claim \'externalSystemCode\' not found!';
	static DoYouReallyWantToRemoveSelectedHandlers = 'Do you really want to remove selected handlers?';
	static DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError = 'Failed to remove folder \'{0}\', because data in the folder has been modified concurrently by another user!';
	static LogCleaning = 'Clear Log';
	static EventsFound = 'Incidents Found';
	static Change = 'Change';
	static ExitingInstantMessageChannelWasNotSuccessfulErrorParameter = 'Failed to exit the chat! Error: \'{0}\'';
	static DoYouReallyWantToRemoveEditableDocumentParameter = 'Do you really want to remove document \'{0}\'? All files that are related to the document will be removed.';
	static Archive = 'Archive';
	static Empty = 'Empty';
	static Resources = 'Resources';
	static AccessRightWriteWhistleBlow = 'Full reports handling';
	static LockedDocumentParameterCannotBeMoved = 'Locked document \'{0}\' cannot be moved!';
	static SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter = 'Failed to save document template \'{0}\'! Error: \'{1}\'';
	static SavingReadReceiptWasSuccessful = 'Read receipt saved successfully!';
	static NoTasksToShow = 'No tasks to show!';
	static AllInstantMessageUsersHaveReadMessage = 'All recipients have read your message';
	static DeletingTaskStatesParameterWasSuccessful = 'Task states \'{0}\' removed successfully!';
	static Cancel = 'Cancel';
	static AdditionalPermissionBEvents = 'Removing';
	static ReportDialogInfoText = 'The report will be based on searched/filtered rows.';
	static Continue = 'Continue';
	static TaskDataRowParameterNotFound = 'Task row \'{0}\' not found!';
	static RemovingEventHandlerWasNotSuccessfulErrorParameter = 'Failed to remove handlers! Error: \'{0}\'';
	static RemoveDefaultBasicEducation = 'Remove Default Basic Education';
	static VoucherInOutReportSavePath = 'Voucher Summary Print Save Path';
	static InstantMessageChannelUserParameterNotFound = 'Chat party \'{0}\' not found!';
	static EditMessageType = 'Edit Message Type';
	static SearchProperties = 'Advanced Search';
	static AllUnits = 'All Units';
	static EventTypesGeneric = 'Types';
	static SavingTaskStateParameterWasNotSuccessfulConcurrencyError = 'Failed to save task state \'{0}\', because data in the task state has been modified concurrently by another user!';
	static ChangeSystemLanguageToFinnish = 'Change Language to Finnish';
	static SavingRecordEmploymentParameterWasSuccessful = 'Employment \'{0}\' saved successfully!';
	static Folders = 'Folders';
	static RequireReadReceipt = 'Requires Read Receipt';
	static Required = 'Required';
	static UnreadMessagesParameter = 'Unread messages: {0}';
	static WhistleBlowArchivingTypeChangeState = 'Change whistle blow report state to \'Archived\'';
	static DoYouReallyWantToRemoveCompanyGroupParameter = 'Do you really want to remove company group \'{0}\'?';
	static AddToHomeScreen = 'Add to Home Screen';
	static ForwardMessage = 'Forward';
	static Files = 'Files';
	static WhistleBlowCreateTime = 'Report Sent Time';
	static DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError = 'Failed to remove category 3 \'{0}\', because data in the category 3 has been modified concurrently by another user!';
	static ModifiedActions = 'Modified Actions';
	static ChartProperties = 'Chart Properties';
	static EditableDocumentPublishConfirmation = 'Do you want to publish document to file?';
	static Password = 'Password';
	static RemoveLogisticTransportCompany = 'Remove Transport Company';
	static DoYouReallyWantToPublishTaskTypeConfirmation = 'Do you really want to publish task type \'{0}\' to all companies that do not have it?';
	static SavingTaskDataRowWasSuccessful = 'The task row saved successfully!';
	static AddCompanyToCompanyGroup = 'Add Company into Company Group';
	static AddingAddRecordUserFurtherEducationsWasSuccessful = 'Further educations added successfully!';
	static EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Urgency class \'{0}\' is used in {1} incidents and cannot be removed!';
	static RemoveEmployment = 'Remove Employment';
	static UserCodeClaimNotFound = 'User claim \'code\' not found!';
	static DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter = 'Failed to remove further educations \'{0}\'! Error: \'{1}\'';
	static TimeSpanYear = 'Year';
	static EditableDocumentCodeIsAlreadyInUse = 'Document name is already in use!';
	static PrintTask = 'Print Task';
	static NewComment = 'New Comment';
	static Log = 'Logs';
	static WaitingToBeApproved = 'Waiting to be approved';
	static Properties = 'Properties';
	static PasswordMustContainLowercaseChar = 'Password must contain lowercase alphabet!';
	static PublishDate = 'Publish Date';
	static CurrentTopicsAbbr = 'Topics';
	static DeletingCompanyGroupParameterWasSuccessful = 'Company group \'{0}\' removed successfully!';
	static ShowDraftVersion = 'Show Draft Contents';
	static TaskPriorityCodeIsAlreadyInUse = 'Task priority name is already in use!';
	static WhistleBlowCategories = 'Whistle Blow Categories';
	static DetectingLocation = 'Detecting location...';
	static NotificationsQueuedSuccessfully = 'Notification scheduled to be sent!';
	static DeletingKeywordParameterWasSuccessful = 'Keyword \'{0}\' removed successfully!';
	static Browse = 'Browse';
	static ThisMessageWasDeletedByInstantMessageChannelAdministrator = 'This message was deleted by administrator, \'{0}\'';
	static UserClaimsNotFound = 'User claims not found!';
	static Relation = 'Relation';
	static NoName = 'Anonymous';
	static AddEmployment = 'Add Employment';
	static RemoveDefaultEventHandler = 'Remove Default Incident Handler';
	static CopyOrMoveFolderParameter = 'Copy or Move Folder \'{0}\'';
	static DefaultStyle = 'Default';
	static UnitNameMustBeDefined = 'Unit name must be defined!';
	static DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter = 'Failed to remove whistle blow categories \'{0}\'! Error: \'{1}\'';
	static TransportCompanies = 'Transport Companies';
	static SendinEmailNotificationsWasNotSuccessfulErrorParameter = 'Failed to send notifications to by email! Error: \'{0}\'';
	static Unkown = 'Unkown';
	static WhistleBlowInformantDescription = 'Please describe what is happened';
	static InternalMessages = 'Internal Messages';
	static SavingCompanyParameterWasSuccessful = 'Company \'{0}\' saved successfully!';
	static ProjectName = 'Project Name';
	static InstantMessageChannelInternal = 'Company Internal';
	static Modified = 'Last Modified By';
	static DocumentIsEmpty = 'The document is empty!';
	static WhistleBlowInformantButtonHint = 'Do not include sensitive personal information about any individual mentioned in your report if it is not necessary for describing your concern.';
	static SavingEventTypesWasNotSuccessfulErrorParameter = 'Failed to save category 1! Error: \'{0}\'';
	static DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter = 'Urgency class \'{0}\' removed successfully!';
	static AddMessageType = 'Add Message Type';
	static SavingJobParameterWasNotSuccessfulErrorParameter = 'Failed to save job \'{0}\'! Error: \'{1}\'';
	static RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Familiarization \'{0}\' is used in {1} user records and cannot be removed!';
	static DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter = 'Failed to remove transferred properties \'{0}\'! Error: \'{1}\'';
	static ReportsModified = 'Favorite Reports';
	static DoYouReallyWantToRemoveSelectedRecordFamiliarizations = 'Do you really want to remove selected further familiarizations?';
	static InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation = 'Do you really want to remove yourself from administators? Only administrators can manage chat.';
	static UserRecordStatisticsExpiringInSixMonthsFurtherEducations = 'Further Educations Expiring in Six Months';
	static DefaultTaskPriorities = 'Default Task Priorities';
	static NewMessage = 'New Message';
	static AddLogisticVoucherCompanyPaymentSetting = 'Add Payment Setting';
	static RemoveWhistleBlowCategory = 'Remove Whistle Blow Category';
	static DoYouReallyWantToRemoveUnitParameter = 'Do you really want to remove unit \'{0}\'?';
	static SelectSubject = 'Select Subject';
	static SelectRemovingMethod = 'Select Removing Method';
	static EditPayerSettings = 'Edit Payer Settings';
	static InvalidValueParameter = 'Invalid value \'{0}\'!';
	static NormalSize = 'Normal Size';
	static DeletingTaskDataRowWasNotSuccessfulConcurrencyError = 'Failed to remove the task row, because data in the task row has been modified concurrently by another user!';
	static DoYouReallyWantToRemoveFileFilterParameter = 'Do you really want to remove file filter \'{0}\'?';
	static PermissionsForSection = 'Section Permissions';
	static TargetsOtherCompanies = 'Visibility - Other Companies';
	static UserGroupModuleProperties = 'User Groups Properties';
	static WhistleBlowEventTypeModified = 'Modified';
	static SomeEventHandlersHaveReadMessage = 'Some incident handlers have read your comment';
	static Scope = 'Scope';
	static UserRecordStatisticsActiveCards = 'Active Cards';
	static ChangedObserverAction = 'Changed incident observer action';
	static SavingUserGroupParameterWasNotSuccessfulErrorParameter = 'Failed to save user group \'{0}\'! Error: \'{1}\'';
	static UnitMustBeSelected = 'Unit must be selected!';
	static CompanyCodeMustBeDefined = 'Code of the company must be defined!';
	static SavingEventParameterWasNotSuccessfulErrorParameter = 'Failed to save incident \'{0}\'! Error: \'{1}\'';
	static WhistleBlowChannel = 'Whistle Blow Channel';
	static WhistleBlowWasNotFoundExplanation = 'Check the whistle blow report password.';
	static DocumentTemplateParameterNotFound = 'Document template \'{0}\' not found!';
	static Sorting = 'Sorting';
	static MessageTypeCodeIsAlreadyInUse = 'Message type code is already in use!';
	static SelectDocumentTemplate = 'Select Document Template';
	static SavingTaskDataRowWasNotSuccessfulConcurrencyError = 'Failed to save the task row, because task row has been modified concurrently by another user!';
	static WhistleBlowInternalMessageConfirmation = 'Do you want to save new internal message?';
	static DeletingCompanyGroupsParameterWasSuccessful = 'Company groups \'{0}\' removed successfully!';
	static DeletingTaskDataRowWasNotSuccessfulErrorParameter = 'Failed to remove the task row! Error: \'{0}\'';
	static FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations = 'Company familiarizations must be defined before adding user record familiarizations!';
	static DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError = 'Failed to remove transferred property \'{0}\',  because data in the transferred property has been modified concurrently by another user!';
	static Unlock = 'Unlock';
	static Tasks = 'Tasks';
	static RestoreTaskFromTrashBin = 'Restore Task from Trash Bin';
	static SavingWhistleBlowMessageWasSuccessful = 'Message saved successfully!';
	static Test = 'Test';
	static TaskPriorityCodeMustBeDefined = 'Task priority name must be defined!';
	static DeletingOneTimePasswordWasNotSuccessfulErrorParameter = 'Failed to remove one time password! Error: \'{0}\'';
	static DoYouReallyWantToRemoveTaskStateParameter = 'Do you really want to remove task state \'{0}\'?';
	static DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter = 'Failed to remove whistle blow report \'{0}\'! Error: \'{1}\'';
	static ReadingFolderParameter = 'Reading folder \'{0}\'...';
	static SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter = 'Failed to save qualification \'{0}\'! Error: \'{1}\'';
	static PublishingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Failed to publish task type \'{0}\'! Error: \'{1}\'';
	static RemoveBasicEducation = 'Remove Basic Education';
	static WhistleBlowEventTypeAttachmentsAdded = 'Attachments added';
	static AddEventMessageConfirmation = 'Do you want to add the comment? The comment cannot be edited after it has been added.';
	static SavingEventUrgencyClassParameterWasSuccessful = 'Urgency class \'{0}\' saved successfully!';
	static DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter = 'Failed to remove file \'{0}\'! Error: \'{1}\'';
	static InstantMessageChannelGroupExternal = 'Group (intercompany)';
	static CompanyIdClaimNotFound = 'User claim \'company id\' not found!';
	static WhistleBlowReportIHaveSavedThePassword = 'I have stored the password in the safe storage so I can use it for checking the status of my report.';
	static UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels = 'User is only chat administrator in {0} chats!';
	static Unlimited = 'Unlimited';
	static RemoveDefaultEventType = 'Remove Default Category 1';
	static SavingOneTimePasswordWasNotSuccessfulErrorParameter = 'Failed to save one time password! Error: \'{0}\'';
	static NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded = 'New further education must be saved before attachments can be added!';
	static SavingFileFilterParameterWasSuccessful = 'File filter \'{0}\' saved successfully!';
	static PermissionToCreateEventsToPartners = 'Permission to Create Incidents to Partners';
	static ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError = 'Failed to exit the chat, because data in the chat has been modified concurrently by another user!';
	static FieldsToBePrinted = 'Fields to Be Printed';
	static WhistleBlowSourceOther = 'Other';
	static DoYouReallyWantToSave = 'Do you want to save?';
	static MovingTaskParameterToTrashBinWasSuccessful = 'Task \'{0}\' moved to trash bin!';
	static DisableExternalIntraSystemConfirmation = 'Do you really want to disable company from the system? Company will be removed from the system when the company is saved.';
	static WhistleBlowMustBeReceivedLatestAtParameter = 'Whistle blow report must be marked as received {0} at the latest!';
	static UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter = 'Failed to link user \'{0}\' to external systems! Error: \'{1}\'';
	static SavingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Failed to save task type \'{0}\'! Error: \'{1}\'';
	static TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion = 'This system does not support your web browser. Update your web browser to latest version.';
	static UserRecordStatisticsMissingQualifications = 'Missing Qualifications';
	static ObserverActionMustBeDefined = 'Observer action must be defined!';
	static CanShareContentToCompanies = 'Content Can Be Shared to These Partners';
	static SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError = 'Failed to save whistle blow channel \'{0}\', because data in the whistle blow channel has been modified concurrently by another user!';
	static AddDefaultHandler = 'Add Default Handler';
	static WhistleBlowInformation = 'Report Information';
	static DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter = 'Failed to remove basic educations \'{0}\'! Error: \'{1}\'';
	static EditWhistleBlow = 'Edit Whistle Blow Report';
	static NewRecordUserMustBeSavedBeforeRowsCanBeAdded = 'New user record must be saved before rows can be added!';
	static ReadingFileParameterWasNotSuccessfulFileNotFound = 'Failed to read file \'{0}\' in filesystem! File not found!';
	static ChangingPasswordWasSuccessful = 'Password changed successfully!';
	static WhistleBlowSeverityHigh = 'High';
	static WhistleBlowEventTypeHandlersRemoved = 'Handlers removed';
	static AdditionalPermissions = 'Additional Permissions';
	static Aprint = 'Print';
	static CreatingCompressedFileWasNotSuccessfulErrorParameter = 'File compression failed! Error: \'{0}\'';
	static WhistleBlowWasNotFound = 'Whistle blow report not found!';
	static ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError = 'Failed to archive further educations, because data in further educations has been modified concurrently by another user!';
	static AddCompany = 'Add Company';
	static PostalAddress = 'Street Address';
	static AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel = 'Add at least one participant to the chat!';
	static NewPrint = 'New Print';
	static ExternalIntraSystems = 'External eSalkku Systems';
	static SavingCompanyGroupParameterWasSuccessful = 'Company group \'{0}\' saved successfully!';
	static TaskHandler = 'Handler';
	static AccessRightReadInstantMessages = 'Company Internal Chat';
	static FolderProperties = 'Folder Properties';
	static WhistleBlowSourceUnspecified = 'Unspecified';
	static UserIsSystemAdminClaimNotFound = 'User claim \'userIsSystemAdmin\' not found!';
	static DocumentParameterAlreadyExists = 'Document \'{0}\' already exists in the folder!';
	static ImageProperties = 'Image Properties';
	static RefreshList = 'Refresh List';
	static EmptyTaskTrashBinConfirmation = 'Do you really want to permanently remove all tasks from trash bin? Removed tasks cannot be restored?';
	static ImmediateAction = 'Remedial Action';
	static AddUserParameterWasNotSuccessfulConcurrencyError = 'Failed to add user account \'{0}\', because the data has been modified concurrently by another user!';
	static EventTypeTitle = 'Incident Identification Category 1';
	static MovingFilesAndDocumentsToFolderParameterWasSuccessful = 'Files/Documents moved into folder \'{0}\' successfully!';
	static UserCanBeSelectedAsEventHandler = 'User can be selected as incident handler';
	static Expand = 'Expand';
	static ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter = 'Failed to archive whistle blow reports \'{0}\'! Error: \'{1}\'';
	static RecordUser = 'User Record';
	static SavingEditableDocumentParameterPublishWasSuccessful = 'Document \'{0}\' published successfully!';
	static AddingAttachmentParameterWasNotSuccessfulErrorParameter = 'Failed to add attachment \'{0}\'! Error: \'{1}\'';
	static GroupByQuarter = 'Group by Quarter';
	static SavingEventMessageWasNotSuccessfulErrorParameter = 'Failed to save comment! Error: \'{0}\'';
	static WorkShiftType = 'Work Shift Type';
	static WhistleBlowAnonymityAnonymous = 'Anonymous';
	static EventTypeBCodeMustBeDefined = 'Category 2 name must be defined!';
	static MyFiltersAndReports = 'User\'s Filters and Reports';
	static DeletingRecordFurtherEducationParameterWasSuccessful = 'Further education \'{0}\' removed successfully!';
	static ProcessingFolderParameter = '* Processing folder \'{0}\'...';
	static RemoveDocumentTemplate = 'Remove Document Template';
	static NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded = 'New user record must be saved before further educations can be added!';
	static SavingEventSettingsWasNotSuccessfulErrorParameter = 'Failed to save incident settings! Error: \'{0}\'';
	static DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError = 'Failed to remove qualification \'{0}\',  because data in the qualification has been modified concurrently by another user!';
	static AdditionalPermissionAWhistleBlow = 'Can communicate with informant';
	static UserAccountMustBeSelected = 'User account must be selected!';
	static ProcessingTime = 'Processing Time';
	static SavingPrintWasNotSuccessfulErrorParameter = 'Failed to save print! Error: \'{1}\'';
	static AddTarget = 'Add Target';
	static DeletingEventTypeBParameterWasNotSuccessfulErrorParameter = 'Failed to remove category 2 \'{0}\'! Error: \'{1}\'';
	static RecordFurtherEducationParameterNotFound = 'Further education \'{0}\' not found!';
	static RemoveParameterDocuments = 'Remove {0} Files/Documents';
	static EditEvent = 'Edit Incident';
	static RemoveRowConfirmation = 'Do you really want to remove row {0}?';
	static Addition = 'Addition';
	static MiddleName = 'Middle Name';
	static SendReadReceiptConfirmation = 'Do you want to send read receipt?';
	static EditEventTypeBs = 'Edit Category 2';
	static NotifiedPersons = 'Notifications';
	static AddFile = 'Add File';
	static ChangeLanguage = 'Change Language';
	static DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError = 'Failed to remove task priority \'{0}\', because data in the task priority has been modified concurrently by another user!';
	static ExpiringIn90Days = 'Include educations expiring in 90 days';
	static TaskRemainderCheckingAndNotificationStarted = 'Task remainder checking and notification started';
	static SavingOptionParameterWasNotSuccessfulConcurrencyError = 'Failed to save option \'{0}\', because data in the option has been modified concurrently by another user!';
	static IpAddress = 'IP Address';
	static AddAttachementsToEvent = 'Add attachment by tapping plus buttons.';
	static AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter = 'Failed to add salary rows! Error: \'{0}\'';
	static SendingEmailToParameter = 'Sending email to address \'{0}\'...';
	static ParameterPercentOfFileStorageQuotaUsed = '{0}% of file storage quota used';
	static AddPartnersParameterWasNotSuccessfulErrorParameter = 'Failed to add partners \'{0}\'! Error: \'{1}\'';
	static WhistleBlowInformant = 'Informant';
	static DefaultEventHandlers = 'Default Incident Handlers';
	static FieldParameterConstainsIllegalCharactersForFileName = 'Value of the field \'{0}\' contains characters that are illegal in file names (<, >, :, ", /, \, |, ? tai *) or it ends with illegal characters (., \' \')!';
	static AccessRightWriteTasks = 'Creating and handling tasks';
	static DeletingEventPartnerParameterWasSuccessful = 'Incident partner \'{0}\' removed successfully!';
	static State = 'State';
	static OtherInformation = 'Other Information';
	static CompanyThatIsRelatedToEventsCannotBeDeleted = 'Company that has sent or received incidents, cannot be removed!';
	static SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter = 'Failed to save urgency class \'{0}\'! Error: \'{1}\'';
	static PreviousCalendarYear = 'Previous Calendar Year';
	static SavingChartWasSuccessful = 'Chart saved successfully!';
	static DeletingTaskTypesParameterWasNotSuccessfulErrorParameter = 'Failed to remove task types \'{0}\'! Error: \'{1}\'';
	static DoYouReallyWantToRemoveSelectedRecordFurtherEducations = 'Do you really want to remove selected further educations?';
	static EditDocumentTemplate = 'Edit Document Template';
	static ByName = 'By Name';
	static AddChildEventTypeC = 'Add Subcategory 3';
	static ChartGenerationAborted = 'Chart creation canceled!';
	static OtherSettings = 'Other Properties';
	static DefaultHandlers = 'Default Handlers';
	static SavingUnitsWasNotSuccessfulErrorParameter = 'Failed to save units! Error: \'{0}\'';
	static PasswordMustContainUppercaseChar = 'Password must contain uppercase alphabet!';
	static ActionIsNotAuthorized = 'Action is not authorized!';
	static UserGroupsMustBeDefined = 'User groups must be defined!';
	static FieldParameterValueCanOnlyContainNumbers = 'Value of the field \'{0}\' can only contain numbers!';
	static WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted = 'Whistle blow category \'{0}\' is used in {1} whistle blow records and cannot be removed!';
	static SavingEventTypeParameterWasNotSuccessfulConcurrencyError = 'Failed to save category 1 \'{0}\', because data in the category 1 has been modified concurrently by another user!';
	static CustomStyleClass = 'Custom Style Class Name';
	static SavingDirectoryItemPermissionsWasSuccessful = 'Folder permissions saved successfully!';
	static SharedReport = 'Shared Report';
	static Operation = 'Operation';
	static RecordUserQualificationParameterNotFound = 'User record qualification record \'{0}\' not found!';
	static DocumentNameParameterConstainsIllegalCharacters = 'Document name \'{0}\' contains forbidden characters (<, >, :, ", /, \, |, ? or *) or ends with forbidden character (., \' \')!';
	static ExitInstantMessageChannel = 'Exit Chat';
	static AddDefaultBasicEducation = 'Add Default Basic Education';
	static SavingTaskPriorityParameterWasSuccessful = 'Task priority \'{0}\' saved successfully!';
	static EditEventTypeB = 'Edit Category 2';
	static ActiveTasks = 'Active tasks';
	static BannerPath = 'Banner Folder';
	static ClearSubUnitDefaultHandlers = 'Clear Subunit Default Handlers';
	static SavingEventSettingsWasSuccessful = 'Incident settings saved successfully!';
	static CopyGroupPermissions = 'Copy Group Permissions to Memory';
	static JobTypeEmail = 'Emailing';
	static UnpublishingEditableDocumentParameterWasSuccessful = 'Document \'{0}\' unpublished successfully!';
	static DoYouReallyWantToRemoveWhistleBlowParameter = 'Do you really want to remove whistle blow report \'{0}\'?';
	static DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter = 'Failed to remove whistle blow category \'{0}\'! Error: \'{1}\'';
	static ModifyDateEndDate = 'Last Modified Until';
	static AdminMustBeDefined = 'Administrator must be defined!';
	static SaveAsFavorite = 'Save as Favorite';
	static Handlers = 'Handlers';
	static RenamingFolderParameterWasNotSuccessfulErrorParameter = 'Failed to rename folder (\'{0}\' => \'{1}\') in filesystem! Error: \'{2}\'';
	static MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError = 'Failed to move folder \'{0}\' into folder \'{1}\',  because data in the folder has been modified concurrently by another user!';
	static SearchCondition = 'search criteria';
	static SavingWhistleBlowWasSuccessful = 'Whistle blow report saved successfully!';
	static LogEventTime = 'Time';
	static DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove qualifications \'{0}\',  because data in qualifications has been modified concurrently by another user!';
	static NewUserMustBeSavedBeforeUserGroupsCanBeAdded = 'New user must be saved before user groups can be added!';
	static InheritedContentFilter = '(inherited content filter)';
	static ActiveUserTasks = 'Active user created tasks';
	static DeletingTaskPrioritiesParameterWasSuccessful = 'Task priorities \'{0}\' removed successfully!';
	static TrainingStartDateEnd = 'Training Start Date - End';
	static DocumentsNotFound = 'Documents not found!';
	static PushEndpoint = 'Push Endpoint';
	static NewUserGroupMustBeSavedBeforeUsersCanBeAdded = 'New user group must be saved before users can be added!';
	static ReadReceiptGivenPlur = 'Read Receipt Sent';
	static AdministratorUserCodeMustBeDefined = 'Username of the administrator must be defined!';
	static AddFurtherEducation = 'Add Further Education';
	static LargeSize = 'Large Size';
	static DoYouReallyWantToMoveTaskParameterToTrashBin = 'Do you want to move task \'{0}\' to trash bin?';
	static SavingEventParameterWasNotSuccessfulConcurrencyError = 'Failed to save incident \'{0}\', because data in the incident has been modified concurrently by another user!';
	static LanguageFinnish = 'Finnish';
	static DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError = 'Failed to remove urgency classes \'{0}\',  because data in urgency classes has been modified concurrently by another user!';
	static MaxSearchResultCount = 'Max Search Result Count';
	static SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError = 'Failed to save document template \'{0}\', because data in the document template has been modified concurrently by another user!';
	static Hour = 'Hour';
	static UnitCodeIsAlreadyInUse = 'Unit name is already in use!';
	static CostCentreNumber = 'Cost Centre Number';
	static CopyDefaultHandlers = 'Copy Default Handlers';
	static ArgumentParameterIsNULL = 'Value of the argument \'{0}\' is NULL!';
	static SelectEventPartner = 'Select Incident Partner';
	static CompanyGroupNameMustBeDefined = 'Name of the company group must be defined!';
	static SavingUserGroupParameterWasSuccessful = 'User group \'{0}\' saved successfully!';
	static AddChildDefaultEventType = 'Add Default Subcategory 1';
	static EmploymentType = 'Employment Type';
	static RemoveMessage = 'Remove Message';
	static UserInformation = 'User Account';
	static MessageTitleMustBeDefined = 'Message title until date must be defined!';
	static DoYouReallyWantToRemoveDefaultEventHandlerParameter = 'Do you really want to remove default incident handler \'{0}\'?';
	static SavingMessageTypeParameterWasSuccessful = 'Message type \'{0}\' saved successfully!';
	static FileNameConstainsIllegalCharacters = 'File name contains forbidden characters (<, >, :, ", /, \, |, ? or *) or ends with forbidden character (., \' \')!';
	static AddEventTypeC = 'Add Category 3';
	static AddEventTypeB = 'Add Category 2';
	static DeletingDocumentTemplateParameterWasSuccessful = 'Document template \'{0}\' removed successfully!';
	static SavingFilterWasNotSuccessfulErrorParameter = 'Failed to save filter! Error: \'{1}\'';
	static View = 'View';
	static EmailsSentSuccessfully = 'Emails sent successfully!';
	static ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError = 'Failed to archive further familiarizations, because data in familiarizations has been modified concurrently by another user!';
	static LockingTaskParameterWasNotSuccessful = 'Failed to lock task \'{0}\'!';
	static UnitParameterNotFound = 'Unit \'{0}\' was not found!';
	static MessageTypeCodeMustBeDefined = 'Message type code must be defined!';
	static DocumentModuleProperties = 'Documents Properties';
	static AdminMessage = 'Message to managers';
	static DeletingOptionParameterWasNotSuccessfulErrorParameter = 'Failed to remove option \'{0}\'! Error: \'{1}\'';
	static DeletingDirectoryItemDocumentParameterWasSuccessful = 'File \'{0}\' removed successfully!';
	static AddAttachment = 'Add Attachment';
	static DoYouWantToPublishEvent = 'Do you want to publish the incident?';
	static EventTypeCCodeIsAlreadyInUse = 'Category 3 name is already in use!';
	static EditUser = 'Edit User Account';
	static SendMessage = 'Send Message';
	static UnlockingEditableDocumentParameterWasNotSuccessful = 'Failed to unlock document \'{0}\'!';
	static JobTitleMustBeDefined = 'Job title must be defined!';
	static AddRecordUser = 'Add User Record';
	static JobParameterNotFound = 'Job \'{0}\' not found!';
	static DefaultDeductionWithoutTax = 'Default Deduction (VAT 0%)';
	static Location = 'Location';
	static AddEventHandler = 'Add Handler';
	static RecordUserModuleProperties = 'User Records Properties';
	static Parameters = 'Parameters';
	static ApproveEventHandlerConfirmation2 = 'Do you really want to approve incident?';
	static RemoveLogisticVoucherCustomer = 'Remove Customer';
	static DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter = 'Failed to remove employment \'{0}\'! Error: \'{1}\'';
	static JobLineDataIsEmpty = 'Job line data is missing!';
	static PanelSize4 = '2 x 2';
	static RecordUserFamiliarizationParameterNotFound = 'User record familiarization record \'{0}\' not found!';
	static DraftCannotBeApprovedDoYouWantToSaveDraftAnyway = 'Draft cannot be approved! Do you want to save draft anyway (approval will not be saved)?';
	static AddDefaultEventType = 'Add Default Category 1';
	static UserProfile = 'User Account';
	static DeletingUserGroupUsersOfCompanyParameterWasSuccessful = 'User group connections related to company \'{0}\' removed successfully!';
	static IgnoreFolderPatterns = 'Ignore Folders Pattern';
	static AddPartner = 'Add Partner';
	static DoYouReallyWantToRemoveRecordQualificationParameter = 'Do you really want to remove qualification \'{0}\'?';
	static RemoveCompanyFromCompanyGroup = 'Remove Company from Company Group';
	static DeletingEventTypeParameterWasSuccessful = 'Category 1 \'{0}\' removed successfully!';
	static DeletingCompanyParameterWasNotSuccessfulConcurrencyError = 'Failed to remove company \'{0}\',  because data in the company has been modified concurrently by another user!';
	static Portrait = 'Portrait';
	static ApprovedEvent = 'Approved The Incident';
	static DoYouReallyWantToReplaceDocumentTemplateParameter = 'Do you really want to replace document template \'{0}\'?';
	static ReadImpPlur = 'Read';
	static RemoveCompanyGroup = 'Remove Company Group';
	static LogOut = 'Log Out';
	static UserCodeUpdateWasNotSuccessfulErrorParameter = 'Failed to update user code! Error: \'{0}\'';
	static Target = 'Target';
	static BankBICCode = 'Bank BIC Code';
	static RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter = 'Failed to restore task \'{0}\' from trash bin! Error: \'{1}\'';
	static UserRecordStatisticsUserAccountConnectedCards = 'User Account Connected Cards';
	static SavingEventPartnerParameterWasSuccessful = 'Incident partner \'{0}\' saved successfully!';
	static NewRow = 'New Row';
	static WhistleBlowAnonymousReport = 'Report anonymously';
	static Code = 'Code';
	static UpdateUserGroupParameterExternalSystemsWasSuccessful = 'User group \'{0}\' linked to external systems successfully!';
	static Path = 'Path';
	static EmptyingTaskTrashBinWasSuccessfulErrorParameter = 'Failed to empty task trash bin! Error: \'{1}\'';
	static WhistleBlowChannelMustHaveAtLeastOneAdministrator = 'Whistle blow channel must have at least one administrator!';
	static SavingKeywordParameterWasSuccessful = 'Keyword \'{0}\' saved successfully!';
	static AddWhistleBlowChannelAdminsParameterWasSuccessful = 'Administrators \'{0}\' added successfully!';
	static SavingInstantMessageChannelParameterWasSuccessful = 'Chat \'{0}\' saved successfully!';
	static SelectTaskState = 'Select Task State';
	static ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter = 'Active Permissions Cannot Be Copied to Folder \'{0}\'!';
	static MoneyOut = 'Money Out';
	static SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter = 'Failed to save folder \'{0}\'! Error: \'{1}\'';
	static SearchTasks = 'Search Tasks';
	static SomeInstantMessageUsersHaveReadMessage = 'Some recipients have read your message';
	static TaskState = 'Task State';
	static AmountOfComments = 'Amount of Comments';
	static MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB = 'Maximum total size of files that can be added in batch is  {0} MB!';
	static FamiliarizationEndDate = 'Familiarization End Date';
	static MessageDateMustBeDefined = 'Message date must be defined!';
	static GroupByMonth = 'Group by Month';
	static CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter = 'Failed to check existance of file \'{0}\' in filesystem! Error: \'{1}\'';
	static YouDeletedThisMessage = 'You deleted this message';
	static EditRemainder = 'Edit Remainder';
	static PrintParameterDownloaded = 'Print has been downloaded.';
	static PushNotificationInUse = 'I Want to Receive Push Notifications (eSalkku Mobile)';
	static NewPanel = 'New Panel';
	static UserRecordCards = 'User Records';
	static ReadReceipts = 'Read Receipts';
	static DoYouReallyWantToRemoveSelectedTaskStates = 'Do you really want to remove selected further task task states?';
	static WhistleBlowResolutionOther = 'Other';
	static WhistleBlowSeverityVeryLow = 'Very Low';
	static SentEmails = 'Sent Emails';
	static SavingWhistleBlowChannelParameterWasSuccessful = 'Whistle blow channel \'{0}\' saved successfully!';
	static UserTypeClaimNotFound = 'User claim \'user type\' not found!';
	static DoYouReallyWantToRemoveThisInstantMessage = 'Do you really want to remove this instant message? Instant message will be removed from all parties.';
	static DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError = 'Failed to remove whistle blow report \'{0}\',  because data in the whistle blow report has been modified concurrently by another user!';
	static Rejected = 'Rejected';
	static SavingEventPartnerParameterWasNotSuccessfulErrorParameter = 'Failed to save incident partner \'{0}\'! Error: \'{1}\'';
	static DeletingAttachmentsParameterWasSuccessful = 'Attachments \'{0}\' removed successfully!';
	static DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove basic educations \'{0}\',  because data in basic educations has been modified concurrently by another user!';
	static Reminders = 'Reminders';
	static NoMessagesToShow = 'No messages to show!';
	static Returned = 'Returned';
	static DeletingInstantMessageWasNotSuccessfulConcurrencyError = 'Failed to remove instant message, because data in the instant message has been modified concurrently by another user!';
	static SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError = 'Failed to save chat \'{0}\', because data in the chat has been modified concurrently by another user!';
	static InternalAttachments = 'Internal Attachments';
	static RemoveReport = 'Remove Report';
	static DoYouReallyWantToRemoveUserGroupParameter = 'Do you really want to remove user group \'{0}\'?';
	static SavingPrintWasNotSuccessfulConcurrencyError = 'Failed to save print \'{0}\', because data in the print has been modified concurrently by another user!';
	static LoadMore = 'Load More';
	static Category = 'Category';
	static AddCompanyGroupsParameterWasSuccessful = 'Company groups \'{0}\' added successfully!';
	static AuthorizationLevelNone = 'No Rights';
	static SavingUserGroupParameterWasNotSuccessfulConcurrencyError = 'Failed to save user group \'{0}\', because data in the user group has been modified concurrently by another user!';
	static ClearSubUnitDefaultHandlersAndNotifiedPersons = 'Clear Subunit Default Handlers and Notified Persons';
	static WhistleBlowChannelWasNotFoundExplanation = 'Check the internet address that was entered in the address bar of the browser.';
	static AddingEventHandlerWasNotSuccessfulErrorParameter = 'Failed to add handlers! Error: \'{0}\'';
	static NameIsAlreadyInUse = 'Name is already in use!';
	static Color = 'Color';
	static DeletingFileFilterParameterWasNotSuccessfulConcurrencyError = 'Failed to remove file filter \'{0}\', because data in the file filter has been modified concurrently by another user!';
	static Unpublish = 'Unpublish';
	static DeletingUserParametersWasSuccessful = 'User parameters removed successfully!';
	static Schema = 'Schema';
	static SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError = 'Failed to save employment \'{0}\', because data in the employment has been modified concurrently by another user!';
	static EditDefaultBasicEducation = 'Edit Default Basic Education';
	static ExternalSystemUserCodeMustBeDefined = 'User code in external system must be defined!';
	static Yesterday = 'Yesterday';
	static WhistleBlowNonAnonymousReport = 'Report with discretion';
	static SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter = 'Failed to save participant! Error: \'{0}\'';
	static DocumentTypes = 'Document Types';
	static ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter = 'Failed to archive further educations! Error: \'{0}\'';
	static DocumentRootPathMustBeDefined = 'Document root path must be defined!';
	static WhistleBlowResolutionResolved = 'Resolved';
	static RemoveEvent = 'Remove Incident';
	static EditBasicEducation = 'Edit Basic Education';
	static DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter = 'Failed to remove task priorities \'{0}\'! Error: \'{1}\'';
	static SavingFileFilterParameterWasNotSuccessfulConcurrencyError = 'Failed to save file filter \'{0}\', because data in the file filter has been modified concurrently by another user!';
	static PermissionsForSystems = 'System Permissions';
	static DraftCannotBeApproved = 'Draft cannot be approved!';
	static AllWhistleBlowHandlersHaveReadMessage = 'All whistle blow report handlers have read your message';
	static TaskTypeCodeIsAlreadyInUse = 'Task type name is already in use!';
	static AdditionalPermissionARecords = 'Archiving and removing';
	static UseObserverAction = 'Show Observer Action Field';
	static Order = 'Order';
	static DeletingMetadataFromFileParameterFailed = 'Failed to remove metadata from file \'{0}\'!';
	static AddUserGroup = 'Add User Group';
	static DeletingOneTimePasswordWasSuccessful = 'One time password removed successfully!';
	static SendingNotificationsWasNotSuccessfulErrorParameter = 'Failed to send notifications! Error: \'{0}\'';
	static DeletingEventPartnerParameterWasNotSuccessfulErrorParameter = 'Failed to remove incident partner \'{0}\'! Error: \'{1}\'';
	static UserReport = 'User\'s report';
	static UserPasswordMustBeDefined = 'User password must be defined!';
	static DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter = 'Failed to remove user group connections related to company \'{0}\'! Error: \'{1}\'';
	static CkickToChangeThePicture = 'Click to change the picture';
	static WhistleBlowChannelWasNotFound = 'Whistle blow channel not found!';
	static AddingAddRecordUserTransferredPropertiesWasSuccessful = 'Transferred properties added successfully!';
	static SavingTaskStateParameterWasSuccessful = 'Task state \'{0}\' saved successfully!';
	static CompanyCodeClaimNotFound = 'User claim \'company code\' not found!';
	static AddFurtherEducations = 'Add further educations';
	static Info = 'Info';
	static AddHandlers = 'Add handlers';
	static Date = 'Date';
	static EditEditableDocument = 'Edit Document';
	static AdditionalPermissionBWhistleBlow = 'Permission management';
	static SavingFileFilterParameterWasNotSuccessfulErrorParameter = 'Failed to save file filter \'{0}\'! Error: \'{1}\'';
	static Error = 'Error';
	static UserParameterHasInvitedYouToJoinInstantMessageChannelParameter = 'User \'{0}\' has invited you to join in chat';
	static FileChangesCheckingAndEmailingFinishedInParameterSeconds = 'File changes checking and emailing finished (duration: {0:N0}s, errors: {1}, files: {2}, emails: {3})';
	static InstantMessageUserInvitationAcceptTypeCanInvite = 'I can be invited to join chat';
	static ValidPasswordMustBeDefined = 'Valid password must be defined!';
	static DoYouReallyWantToRemoveEventTypeParameter = 'Do you really want to remove category 1 \'{0}\'?';
	static DownloadPdf = 'Download Pdf';
	static EventPartnerParameterNotFound = 'Incident partner \'{0}\' not found!';
	static WhistleBlowInformantHasReadMessage = 'Whistle blow report informant has read your message';
	static UserIdClaimNotFound = 'User claim \'id\' not found!';
	static Other = 'Other';
	static AddTaskType = 'Add Task Type';
	static SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter = 'Failed to save default incident handler \'{0}\'! Error: \'{1}\'';
	static ReportGenerationFailed = 'Failed to create report!';
	static AccessRightNone = 'No permissions';
	static ExternalIntraSystemUserTokenMustBeDefined = 'User token in external eSalkku system must be defined!';
	static EventTypesMustBeDefinedBeforeAddingEvents = 'Category 1 must be defined before adding incidents!';
	static DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter = 'Urgency classes \'{0}\' removed successfully!';
	static InstantMessagePartners = 'Instant Message Partners';
	static MyFilters = 'User\'s Filters';
	static DefineRecordUserFurtherEducation = 'Define Further Education';
	static InstantMessageAttachmentFolderParameterNotFound = 'Application instant message attachment folder \'{0}\' not found!';
	static EditCompanyGroup = 'Edit Company Group';
	static EventTypeMustBeDefined = 'Category 1 must be defined!';
	static RemoveEventType = 'Remove Category 1';
	static AccountNumberTkbInDebit = 'Sales Receivable Account';
	static MessageTypes = 'Message Types';
	static WhistleBlowEventTypeAttachmentsRemoved = 'Attachments removed';
	static LoggedInUserCannotBeDeleted = 'User cannot remove himself/herself!';
	static ExternalSystemCompanyTokenParameterIsAlreadyInUse = 'Company token \'{0}\' is already in use!';
	static ParameterIsNotValidAccountIBANNumber = '\'{0}\' is not valid bank account IBAN number!';
	static DeletingRecordFurtherEducationsParameterWasSuccessful = 'Further educations \'{0}\' removed successfully!';
	static DoYouReallyWantToRemoveDocumentTemplateParameter = 'Do you really want to remove document template \'{0}\'?';
	static CompanyWithSysAdminAuthorizationLevelNotFound = 'Company that is related to \'System administrator\' user group was not found!';
	static ToggleArchivedFurtherEducations = 'Show/Hide Archived Further Educations';
	static FiscalNumber = 'Fiscal Number';
	static SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError = 'Failed to save qualification \'{0}\', because data in the qualification has been modified concurrently by another user!';
	static DocumentProperties = 'File/Document Properties';
	static LastWeek = 'Last Week';
	static RemovingEventHandlersWasSuccessful = 'Handlers removed successfully!';
	static IndirectCosts = 'Indirect Costs';
	static TrainingTime = 'Training Time';
	static EmailAddressMustBeValid = 'Email address is not valid email address!';
	static AddUser = 'Add User Account';
	static SavingInstantMessageWasSuccessful = 'Instant message saved successfully!';
	static FailureAudit = 'FailureAudit';
	static Approved = 'Approved';
	static FiltersStandard = 'Standard Filters';
	static User = 'User Account';
	static CompanyToken = 'Company Token';
	static ShowArchived = 'Show Archived';
	static InstantMessageChannelMustHaveAtLeastOneAdministrator = 'Chat must have at least one administrator!';
	static AddQualificationToMultipleUserRecords = 'Add Qualification to Multiple User Records';
	static PasteGroupPermissions = 'Paste Group Permissions from Memory';
	static SavingEditableDocumentParameterWasNotSuccessfulErrorParameter = 'Failed to save document \'{0}\'! Error: \'{1}\'';
	static TaskStateParameterNotFound = 'Task state \'{0}\' not found!';
	static FileFilterCodeIsAlreadyInUse = 'File filter code is already in use!';
	static RemoveEventHandler = 'Remove Handler';
	static IdentificationDetails = 'Identification Details';
	static ThisChannelHasBeenArchived = 'This chat has been archived!';
	static WhistleBlowEventTypeCreated = 'Created';
	static NotApproved = 'Not approved';
	static EditTransferredProperty = 'Edit Transferred Property';
	static RemoveFamiliarization = 'Remove Familiarizations';
	static LanguageNonLocalEnglish = 'English';
	static SavingUserParameterWasNotSuccessfulErrorParameter = 'Failed to save user \'{0}\'! Error: \'{1}\'';
	static NotificationSettings = 'Notification Settings';
	static SaveAndEmail = 'Save and Email';
	static AddWhistleBlowChannelAdminParameterWasSuccessful = 'Administrator \'{0}\' added successfully!';
	static TaxNumber = 'Tax Number';
	static GroupByUnit = 'Group by Unit';
	static AddPayer = 'Add Payer';
	static EmailAddressParameterIsInvalid = 'Email address \'{0}\' is not valid!';
	static LoggedInUserAccountIsNotActive = 'Logged in user is not active!';
	static DeletingInstantMessageWasSuccessful = 'Instant message removed successfully!';
	static EditTask = 'Edit Task';
	static DirectoryItemDocumentsNotFound = 'Files/documents not fond!';
	static RemoveFileFilter = 'Remove File Filters';
	static RecordUserEmploymentParameterNotFound = 'User record employment record \'{0}\' not found!';
	static ExportToExcel = 'Export to Excel';
	static AddFolder = 'Add Folder';
	static UsersOfThisCompany = 'Users of My Company';
	static EventHandler = 'Handler';
	static SaveAndPublish = 'Save and Publish';
	static TrainingEndDate = 'Training End Date';
	static ReferenceNumberPrefix = 'Reference Number Prefix';
	static EmailNotificationInUse = 'I Want to Receive Email Notifications';
	static WhistleBlowSourcePhone = 'Phone';
	static RecordUserExcelQualification = 'Qualifications';
	static BasicEducations = 'Basic Educations';
	static SavingUserParameterParameterWasNotSuccessfulErrorParameter = 'Failed to save user option \'{0}\'! Error: \'{1}\'';
	static RemoveQualification = 'Remove Qualification';
	static TransportCompany = 'Transport Company';
	static DeletingCompanyParameterWasSuccessful = 'Company \'{0}\' removed successfully!';
	static DefaultFamiliarizations = 'Default Familiarizations';
	static Identifier = 'Identifier';
	static DeletingKeywordParameterWasNotSuccessfulConcurrencyError = 'Failed to remove keyword \'{0}\', because data in the keyword has been modified concurrently by another user!';
	static WhistleBlowPasswordConfirmed = 'Whistle blow report has been sent. You can check the status of your report by clicking "Follow Report" button on the main page.';
	static NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded = 'New user record must be saved before transferred properties can be added!';
	static EventTypeParameterNotFound = 'Category 1 \'{0}\' not found!';
	static EventsExcel = 'Incidents - Excel';
	static AddPartnersParameterWasNotSuccessfulConcurrencyError = 'Failed to add partners \'{0}\', because the data has been modified concurrently by another user!';
	static AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter = 'Failed to add further educations! Error: \'{0}\'';
	static RemoveRecordUserAndKeepUser = 'Remove User Record and Keep User Account';
	static SelectUserGroupOrUser = 'Select User Group or User Account';
	static ModifyDate = 'Last Modified';
	static DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError = 'Failed to remove whistle blow category \'{0}\',  because data in the whistle blow category has been modified concurrently by another user!';
	static UserToken = 'User Token';
	static EditableDocumentParameterNotFound = 'Document \'{0}\' not found!';
	static WholeHistory = 'Whole History';
	static RestoringTaskParameterFromTrashBinWasSuccessful = 'Task \'{0}\' restored from trash bin!';
	static WriteMessage = 'Write Message';
	static RemoveDefaultTaskType = 'Remove Default Task Type';
	static VersionProperties = 'Version Properties';
	static Publish = 'Publish';
	static CopyActiveUserRightsToAllSameLevelFolders = 'Copy Active Permissions to All Same Level Folders';
	static Handling = 'Handling';
	static WhistleBlowReceiver = 'Receiver';
	static CopyDefaultHandlersToAllSameLevelUnits = 'Copy Default Handlers to All Same Level Units';
	static DeletingUserParameterWasNotSuccessfulConcurrencyError = 'Failed to remove user account \'{0}\', because the data has been modified concurrently by another user!';
	static SavingMessageParameterWasSuccessful = 'Message \'{0}\' saved successfully!';
	static SelectReceiver = 'Select Receiver';
	static AllCompanyGroups = 'All Company Groups';
	static AuthorizationLevelSystemAdmin = 'System Administrator';
	static TaskDataRow = 'Task Row';
	static Name = 'Name';
	static DoYouReallyWantToRemovePrintParameter = 'Do you really want to remove print \'{0}\'?';
	static MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError = 'Failed to move task \'{0}\' to trash bin, because data in the task has been modified concurrently by another user!';
	static CompanyGroupCodeIsAlreadyInUse = 'Code of the company group is already in use!';
	static KeywordCodeIsAlreadyInUse = 'Keyword code is already in use!';
	static SelectRecordUsers = 'Select User Records';
	static SSRSParameterReportsFetched = '{0} reports fetched from SSRS';
	static DoYouReallyWantToRemoveMessageParameter = 'Do you really want to remove message \'{0}\'?';
	static InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel = 'Only chat administrator cannot exit the chat!';
	static RemoveUserGroupExternalSystemsWasSuccessful = 'User group links to external systems removed successfully!';
	static NameMustBeDefined = 'Name must be defined!';
	static Permission = 'Permission';
	static ArchivingRecordUserFurtherEducationsWasSuccessful = 'Further educations archived successfully!';
	static ProcessedFolderParameterErrorCountParameter = '* Folder \'{0}\' completed, errors: {1}';
	static RelativeSpecification = 'Relative Specification';
	static AddUsersParameterWasNotSuccessfulErrorParameter = 'Failed to add user accounts \'{0}\'! Error: \'{1}\'';
	static CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter = 'Failed to copy files/documents into folder \'{0}\'! Error: \'{1}\'';
	static AddUsersParameterWasSuccessful = 'User accounts \'{0}\' added successfully!';
	static TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Task priority \'{0}\' is used in {1} tasks and cannot be removed!';
	static CurrentWorkPlace = 'Current Workplace';
	static JobResultParameterNotFound = 'Job result \'{0}\' not found!';
	static GetPhoto = 'Get Photo';
	static MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError = 'Failed to move files/documents into folder \'{0}\',  because data in the folder has been modified concurrently by another user!';
	static RemoveKeyword = 'Remove Keyword';
	static DeletingRecordUserTransferredPropertiesParameterWasSuccessful = 'Transferred properties \'{0}\' removed successfully!';
	static InstantMessageChannelArchivingType = 'Handling of Archived Chat';
	static WhistleBlowSourceSystem = 'System';
	static WhistleBlowReportHasBeenSaved = 'Whistle blow report has be saved';
	static PrintTextPublished = 'Published';
	static Internet = 'Internet';
	static UserIsActiveInWeb = 'User account is active in web application.';
	static AuthorizationLevelUser = 'Users';
	static EventTypeCCodeMustBeDefined = 'Category 3 name must be defined!';
	static InstantMessage = 'Instant Message';
	static ChangingPasswordWasNotSuccessfulConcurrencyError = 'Failed to change password, because user data has been modified concurrently by another user!';
	static InheritedUserRight = '(inherited permission)';
	static SaveFilterAs = 'Save Filter As';
	static All = 'All';
	static EditCompanyTaskType = 'Edit Company Task Type';
	static DoYouReallyWantToRemoveSelectedUsers = 'Do you really want to remove selected users?';
	static LockedDocumentParameterCannotBeDeleted = 'Locked document \'{0}\' cannot be removed!';
	static BrowseFiles = 'Browse Files';
	static SavingUserParameterParameterWasNotSuccessfulConcurrencyError = 'Failed to save user option \'{0}\', because data in the user option has been modified concurrently by another user!';
	static SavingEventTypeCsWasNotSuccessfulErrorParameter = 'Failed to save category 3! Error: \'{0}\'';
	static LogisticVoucherCustomer = 'Customer';
	static SelectInstantMessageChannelUsers = 'Select Participants';
	static SavingUnitPermissionsWasNotSuccessfulErrorParameter = 'Failed to save unit permissions! Error: \'{0}\'';
	static LanguageEnglish = 'English';
	static AllSelected = 'All Selected';
	static UserGroup = 'User Group';
	static Instructions = 'Instructions';
	static PanelSize2 = '2 x 1';
	static CompanyRole = 'Company Role';
	static ReadingFileParameterWasNotSuccessfulErrorParameter = 'Failed to read file \'{0}\' in filesystem! Error: \'{1}\'';
	static InstantMessageChannelGroupInternal = 'Group (company internal)';
	static UsedQuota = 'Used Quota';
	static UnreadInstantMessages = 'Unread messages';
	static SelectCompany = 'Select Company';
	static RemoveTaskPermanently = 'Remove Task Permanenty';
	static Remove = 'Remove';
	static WhistleBlowChannelParameterNotFound = 'Whistle blow channel \'{0}\' not found!';
	static LockedTaskDataRowCannotBeSaved = 'The task row has been locked to another user and it cannot be saved!';
	static MessageStartDateMustBeDefined = 'Message visible until date must be defined!';
	static Edit = 'Edit';
	static DoYouReallyWantToRemoveCompanyParameter = 'Do you really want to remove company \'{0}\'?';
	static DeletingMessageTypeParameterWasSuccessful = 'Message type \'{0}\' removed successfully!';
	static SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter = 'Failed to save chat \'{0}\'! Error: \'{1}\'';
	static Descending = 'Descending';
	static SavingEventTypeParameterWasNotSuccessfulErrorParameter = 'Failed to save category 1 \'{0}\'! Error: \'{1}\'';
	static PasswordIsTooShort = 'Password is too short!';
	static DoYouReallyWantToRemoveWhistleBlowCategoryParameter = 'Do you really want to remove whistle blow category \'{0}\'?';
	static ExpiringIn120Days = 'Include educations expiring in 120 days';
	static AddUserGroupsParameterWasSuccessful = 'User groups \'{0}\' added successfully!';
	static TakePicture = 'Take Picture';
	static Reports = 'Reports';
	static AccessRightFull = 'All permissions';
	static Update = 'Update';
	static TaskTypeParameterNotFound = 'Task type \'{0}\' not found!';
	static FollowUp = 'Follow-up';
	static AddEmployments = 'Add Employments';
	static WhistleBlowSeverityNormal = 'Normal';
	static EditUnit = 'Edit Unit';
	static Contents = 'Contents';
	static ReadError = 'Read error!';
	static AddFamiliarizationToMultipleUserRecords = 'Add Familiarization to Multiple User Records';
	static RemoveInvalidDefaultEventHandlersConfirmation = 'Some default incident handlers or notified persons do not have permissions to handle incidents of units. Do you want to automatically remove these incidents handlers or notified persons before saving new settings?';
	static ResultGrouping = 'Result Grouping';
	static DocumentContentsSearch = 'Search Document Contents';
	static DeletingTaskParameterWasNotSuccessfulConcurrencyError = 'Failed to remove task \'{0}\', because data in the task has been modified concurrently by another user!';
	static SavingDefaultHandlersWasNotSuccessfulErrorParameter = 'Failed to save default handlers! Error: \'{0}\'';
	static WhistleBlowState = 'Report State';
	static FileFilterParameterNotFound = 'File filter \'{0}\' not found!';
	static DoYouReallyWantToCreateRemainderInPast = 'Do you really want to create remainder in the past?';
	static ExitingInstantMessageChannelWasSuccessful = 'Chat exited successfully!';
	static SharedFilters = 'Shared Filters';
	static MaximumSizeOfInstantMessageAttachmentIsParameterMB = 'Maximum size of instant message attachment is  {0} MB!';
	static Send = 'Send';
	static ApproveEventHandlerConfirmation = 'Remedial actions have not been defined. Do you really want to approve the incident?';
	static SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter = 'Failed to save whistle blow category \'{0}\'! Error: \'{1}\'';
	static EditTaskPriority = 'Edit Task Priority';
	static AutoSaveVersionHistorySize = 'Autosave Version History Size';
	static SavingEventTypeBParameterWasNotSuccessfulConcurrencyError = 'Failed to save category 2 \'{0}\', because data in the incident category 2 has been modified concurrently by another user!';
	static Folder = 'Folder';
	static NotifiedPersonsShort = 'Notifications';
	static Event = 'Incident';
	static ReadReceiptRequired = 'Read Receipt Required';
	static AddDefaultTaskState = 'Add Default Task State';
	static AddDefaultFurtherEducation = 'Add Default Further Education';
	static MessageEndDate = 'Visible Until';
	static WhistleBlowArchivingTypeChangeStateAndRemoveAllData = 'Change whistle blow report state to \'Archived\' and keep only information that is needed for statistics';
	static AttachmentParameterNotFound = 'Attachment \'{0}\' not found!';
	static DownloadDocument = 'Open/Download Selected Files/Documents';
	static DirectCosts = 'Direct Costs';
	static SaveTask = 'Save Task';
	static SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter = 'Failed to save further education \'{0}\'! Error: \'{1}\'';
	static SavingKeywordParameterWasNotSuccessfulErrorParameter = 'Failed to save keyword \'{0}\'! Error: \'{1}\'';
	static SelectableWhenCreatingAnEvent = 'Selectable When Creating an Event';
	static DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter = 'Failed to remove employments \'{0}\'! Error: \'{1}\'';
	static GroupByYear = 'Group by Year';
	static UserParameterCodeIsAlreadyInUse = 'User parameters code is already in use!';
	static WhistleBlowSeverityUnspecified = 'Unspecified';
	static StudyWeeks = 'Study Weeks';
	static WhistleBlowSendReport = 'Send Report';
	static NoToDos = 'No open things to do';
	static UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter = 'Failed to link company to external systems! Error: \'{0}\'';
	static AddCompanyGroupParameterWasSuccessful = 'Company group \'{0}\' added successfully!';
	static NoPermission = 'No Permissions';
	static EventTypeCodeMustBeDefined = 'Category 1 name must be defined!';
	static AddRows = 'Add Rows';
	static SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError = 'Failed to save further education \'{0}\', because data in the further education has been modified concurrently by another user!';
	static SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError = 'Failed to save message push notification log \'{0}\', because data in the push notification log has been modified concurrently by another user!';
	static TaskDataRowFieldParameterNotFoundInDataSchema = 'Task row field \'{0}\' not found in data schema!';
	static AddInstantMessageUserParameterWasSuccessful = 'Participant \'{0}\' added successfully!';
	static Employee = 'Employee';
	static EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Category 1 \'{0}\' is used in {1} incidents and cannot be removed!';
	static Card = 'Card';
	static AddingAttachmentParameterWasSuccessful = 'Attachment \'{0}\' added successfully!';
	static CreatingFolderParameterWasNotSuccessfulErrorParameter = 'Failed to create folder \'{0}\' in filesystem! Error: \'{1}\'';
	static PrivacyPolicyUrl = 'Privacy Policy Web Address';
	static ReadReceiptNotGiven = 'Receipt unsent';
	static WhistleBlowStatisticsUnreadMessages = 'Unread Messages';
	static ArchiveExpiredFurtherEducations = 'Archive Expired Further Educations';
	static AddCompanyTaskType = 'Add Task Type to Company';
	static Default = 'Default';
	static MinimumPasswordLengthMustBeAtLeastParameterCharacters = 'Minimum password length must be at least {0} characters!';
	static PanelName = 'Panel Name';
	static WhistleBlowEventTypeHandlersAdded = 'Handlers added';
	static MessagePreservationTime = 'Message Preservation Time';
	static LockedEventParameterCannotBeSaved = 'The incident \'{0}\' has been locked to another user and it cannot be saved!';
	static NoFavoritesToShow = 'No favorites to show!';
	static AddUserToUserGroup = 'Add User Account into User Group';
	static SavingDocumentTemplateParameterWasSuccessful = 'Document template \'{0}\' saved successfully!';
	static PublishEvent = 'Publish Incident';
	static SelectInstantMessageChannelUser = 'Select Participant';
	static FileFilters = 'File Filters';
	static AllJobTitles = 'All Job Titles';
	static DeletingUsersParameterWasNotSuccessfulErrorParameter = 'Failed to remove user accounts \'{0}\'! Error: \'{1}\'';
	static AddWhistleBlow = 'Add Whistle Blow Report';
	static SavingTaskParameterWasSuccessful = 'Task \'{0}\' saved successfully!';
	static DefaultEventTypes = 'Default Categories 1';
	static DefaultEventHandlerParameterNotFound = 'Default incident handler \'{0}\' not found!';
	static AddTaskState = 'Add Task State';
	static InstantMessageChannelUser = 'Participant';
	static SharedReports = 'Shared Reports';
	static PaymentVoucherCounter = 'Payment Voucher Counter';
	static Admins = 'Administrators';
	static DocumentParameterDownloaded = 'Document has been downloaded.';
	static PublishTaskType = 'Publish Task Type';
	static NewPassword = 'New Password';
	static DoYouReallyWantToRemoveTaskHandlerParameter = 'Do you really want to remove handler \'{0}\'?';
	static RemovePermission = 'Remove Permission';
	static TimeSpanQuarter = 'Quarter';
	static EmailHasBeenAlreadySentForThisMessage = 'Email has been already sent for this message!';
	static DeletingMessageParameterWasSuccessful = 'Message \'{0}\' removed successfully!';
	static DeletingTaskStateParameterWasNotSuccessfulConcurrencyError = 'Failed to remove task state \'{0}\', because data in the task state has been modified concurrently by another user!';
	static Qualifications = 'Qualifications';
	static CostCentreName = 'Cost Centre Name';
	static SharedReportAllCompanies = 'Shared to all companies';
	static UserGroupCodeIsAlreadyInUse = 'User group code is already in use!';
	static PrivateAndPublic = 'Private and Public';
	static CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError = 'Failed to copy files/documents into folder \'{0}\', because data in the folder has been modified concurrently by another user!';
	static TaskDashboard = 'Tasks Dashboard';
	static DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter = 'Failed to remove company groups \'{0}\'! Error: \'{1}\'';
	static WhistleBlowFollowReport = 'Follow Report';
	static UserCodeIsAlreadyInUse = 'Username is already in use!';
	static AgencyWorker = 'Agency Worker';
	static SavingReportWasSuccessful = 'Report saved successfully!';
	static RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted = 'Further education \'{0}\' is used as qualification in {1} user records and cannot be removed!';
	static RemoveEventTypeB = 'Remove Category 2';
	static DoYouReallyWantToRemoveDocumentsParameter = 'Do you really want to remove {0} selected files/documents?';
	static DeletingTaskDataRowWasSuccessful = 'The task row removed successfully!';
	static TitleMustBeDefined = 'Title must be defined!';
	static UseInheritedContentFiltering = 'Use inherited content filtering';
	static AddRow = 'Add Row';
	static MessageType = 'Message Type';
	static UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter = 'Failed to link user group \'{0}\' to external systems! Error: \'{1}\'';
	static DocumentTemplateCodeIsAlreadyInUse = 'Document template code is already in use!';
	static ShowUsers = 'Show User Accounts';
	static Orientation = 'Orientation';
	static LoggedInUser = 'Logged in User';
	static VersionInformation = 'Version Information';
	static RecentlyUpdated = 'Recently updated';
	static LanguageNonLocalSwedish = 'Svenska';
	static EditEventPartner = 'Edit Incident Partner';
	static SavingRecordUserTransferredPropertyParameterWasSuccessful = 'Transferred property \'{0}\' saved successfully!';
	static EditSharedDocumentTemplate = 'Edit Shared Document Template';
	static PrivacyPolicyUrlMustBeDefined = 'Privacy policy web address must be defined!';
	static MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter = 'Failed to move files/documents into folder \'{0}! Error: \'{1}\'';
	static DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter = 'Failed to remove qualification \'{0}\'! Error: \'{1}\'';
	static EventsToApprove = 'Events to approve';
	static DoYouReallyWantToRemoveEventHandlerParameter = 'Do you really want to remove handler \'{0}\'?';
	static SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Failed to save company group \'{0}\', because data in the company group has been modified concurrently by another user!';
	static RememberMe = 'Remember me';
	static SavingMessageTypeParameterWasNotSuccessfulConcurrencyError = 'Failed to save message type \'{0}\', because data in the message type has been modified concurrently by another user!';
	static DeletingRecordUserEmploymentsParameterWasSuccessful = 'Employments \'{0}\' removed successfully!';
	static RecordParameterLockingFailedRecordIsWriteLockedToUserParameter = 'Failed to lock record \'{0}\'! The record is write locked to user \'{0}\'!';
	static Landscape = 'Landscape';
	static AddEditableDocument = 'Add Document';
	static RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Basic education \'{0}\' is used in {1} user records and cannot be removed!';
	static RecordBasicEducationCodeIsAlreadyInUse = 'Basic education name is already in use!';
	static UserRecordStatisticsArchivedCards = 'Archived Cards';
	static EditTaskState = 'Edit Task State';
	static PermissionToCreateEvents = 'Permission to Create Incidents';
	static ActiveUserRights = 'Active Permissions';
	static DoYouReallyWantToRemoveFilterParameter = 'Do you really want to remove filter \'{0}\'?';
	static DriversLicenseType = 'Drivers License Type';
	static RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter = 'Failed to remove handlers! Error: \'{0}\'';
	static AddInstantMessageChannel = 'Add Chat';
	static SavingReportWasNotSuccessfulErrorParameter = 'Failed to save report! Error: \'{0}\'';
	static AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter = 'Failed to add participants \'{0}\'! Error: \'{1}\'';
	static Resolution = 'Resolution';
	static RemoveUserAndRecordUser = 'Remove User Account and User Record';
	static KeywordsAndCondition = 'All selected keywords';
	static EditAttachment = 'Edit Attachment';
	static TaskTypeSchemaMustBeDefined = 'Task type schema must be defined!';
	static SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter = 'Failed to save transferred property \'{0}\'! Error: \'{1}\'';
	static WhistleBlowSeverityCritical = 'Critical';
	static AccessRightReadRecords = 'Own user record';
	static PaymentSetting = 'Payment Setting';
	static AdvancePaymentReportPath = 'Advance Payment Report Path';
	static ReadReceiptMessage = 'Message that requires read receipt';
	static Others = 'Others';
	static NotAssigned = 'Not Assigned';
	static TaskReport = 'Task Report';
	static DeletingWhistleBlowCategoryParameterWasSuccessful = 'Whistle blow category \'{0}\' removed successfully!';
	static AddDefaultTaskPriority = 'Add Default Task Priority';
	static BasicEducationsMustBeDefinedBeforeAddingRecordUsers = 'Basic educations must be defined before adding user records!';
	static PrintTextPrinted = 'Printed';
	static Public = 'Public';
	static ByDate = 'By Date';
	static EditEmployment = 'Edit Employment';
	static RecordUserFurtherEducationParameterNotFound = 'User record education record \'{0}\' not found!';
	static RowInformation = 'Row Information';
	static WhistleBlowStateResolved = 'Resolved';
	static EventType = 'Category 1';
	static ContactPerson = 'Contact Person';
	static LastNameMustBeDefined = 'Last name must be defined!';
	static RemoveEditableDocument = 'Remove Document';
	static Clear = 'Clear';
	static DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError = 'Failed to remove whistle blow categories \'{0}\',  because data in whistle blow categories has been modified concurrently by another user!';
	static AddEventTypeCsToEvent = 'Add category 3 by tapping the plus button.';
	static TheUserDoesNotHavePermissionsForThisSystem = 'User does not have permission to use this system!';
	static Task = 'Task';
	static Units = 'Units';
	static MessageTypeClass = 'Message Class';
	static AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter = 'Failed to add handlers! Error: \'{0}\'';
	static ContentVersion = 'Content Version';
	static SavingRecordUserParameterWasNotSuccessfulErrorParameter = 'Failed to save user record \'{0}\'! Error: \'{1}\'';
	static ImageOrPdfUpToParameterMb = 'Image or PDF formats, up to {0} MB';
	static DefaultEventSettings = 'Default Incident Settings';
	static RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Further education \'{0}\' is used in {1} user records and cannot be removed!';
	static ChartGenerationFailed = 'Failed to create chart!';
	static TaskRepetitionType = 'Task Repetition';
	static PositionErrorTimeout = 'Location information reading timeout.';
	static MoneyIn = 'Money In';
	static CreateReport = 'Create Report';
	static DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter = 'Failed to remove default incident handler \'{0}\'! Error: \'{1}\'';
	static TaskDataRows = 'Rows';
	static FurtherEducations = 'Further Educations';
	static EventApprovalWasNotSuccessfulConcurrencyError = 'Failed to approve incident, because the handler has been modified concurrently by another user!';
	static TransferredProperties = 'Transferred Properties';
	static WhistleBlowInformantInstuctions = 'Informant Instuctions';
	static Phone = 'Phone';
	static Approval = 'Approval';
	static AddInstantMessageUserParameterWasNotSuccessfulErrorParameter = 'Failed to add participant \'{0}\'! Error: \'{1}\'';
	static LanguageSwedish = 'Swedish';
	static FileSynchronizationToDatabaseStarted = 'Filesystem synchronization with database started';
	static DoYouReallyWantToRemoveInstantMessageChannelParameter = 'Do you really want to remove chat \'{0}\'?';
	static UserParameterNotFound = 'User \'{0}\' not found!';
	static RecordEmailNotificationShowExpired = 'Include Expired Educations';
	static SomeWhistleBlowHandlersHaveReadMessage = 'Some whistle blow report handlers have read your message';
	static ArchivingWhistleBlowsParameterWasSuccessful = 'Whistle blow reports \'{0}\' archived successfully!';
	static TargetDoesNotHaveFoldersWithWritePermissions = 'Target does not have folders with write permissions!';
	static RemoveNotifiedPerson = 'Remove Person to Be Notified';
	static FileParameterIsEmpty = 'File \'{0}\' is empty!';
	static WhistleBlowEventTypeMessageAdded = 'Message added';
	static AccessRightWriteRecords = 'All unit\'s user records';
	static WhistleBlowDoesNotContainAnyMessage = 'No any messages are written to whistle blow report.';
	static NewCompanyInformation = 'New Company';
	static SaveToTemplate = 'Save as Template';
	static DeletingTaskParameterWasNotSuccessfulErrorParameter = 'Failed to remove task \'{0}\'! Error: \'{1}\'';
	static PublicationOfEditableDocumentCannotBeRemoved = 'Publication of the document cannot be removed!';
	static MinimumPasswordLength = 'Minimum Password Length';
	static SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter = 'Failed to approve document \'{0}\'! Error: \'{1}\'';
	static DeletingInstantMessageWasNotSuccessfulErrorParameter = 'Failed to remove instant message! Error: \'{0}\'';
	static LastYear = 'Last Year';
	static EventSettings = 'Incident Settings';
	static AnyTime = 'Any Time';
	static NoEmailsToSend = 'No emails to be sent!';
	static GroupByHalfYear = 'Group by Half Year';
	static NextOfKin = 'Next of Kin';
	static UploadDocument = 'Upload File';
	static AddPhotosAndAttachementsToEvent = 'Add photo or attachment by tapping camera or plus buttons.';
	static SavingDirectoryItemDocumentParameterWasSuccessful = 'File \'{0}\' saved successfully!';
	static PageNameMustBeDefined = 'Page name must be defined!';
	static SelectRecordUserTypeToBeCreated = 'Select user record type for new record';
	static AdditionalPermissionAInstantMessages = 'Permission management';
	static NoAddedEventTypes = 'No added categories!';
	static WhistleBlow = 'Whistleblower';
	static IncludeArchivedFurtherEducations = 'Include archived further educations';
	static ValidityEndDateExpirationTime = 'Validity Expiration Time';
	static Education = 'Education';
	static Attachments = 'Attachments';
	static SavingEventMessageWasSuccessful = 'Comment saved successfully!';
	static Months = 'Months';
	static WhistleBlowDescription = 'Incident Description';
	static WhistleBlowResolutionTypeMustBeDefined = 'Resolution must be defined! You can also set resolution description.';
	static NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded = 'New report must be saved before rows can be added!';
	static AddUserParameterWasSuccessful = 'User account \'{0}\' added successfully!';
	static OldPassword = 'Old Password';
	static IncludeOnlyActiveUsers = 'Include only users with active user account and current work place';
	static FileChangesCheckingAndEmailingStarted = 'File changes checking and emailing started';
	static FurtherEducation = 'Further Education';
	static RemoveDefaultTaskState = 'Remove Default Task State';
	static ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter = 'Failed to archive further familiarizations! Error: \'{0}\'';
	static WhistleBlowChannelPageNameCodeIsAlreadyInUse = 'Whistle blow chanel page name is already in use!';
	static SavingUnitsWasNotSuccessfulConcurrencyError = 'Failed to save units, because data in units has been modified concurrently by another user!';
	static Banner = 'Banner';
	static PreventiveAction = 'Preventive Action';
	static SetUnitPermissions = 'Set Unit Permissions';
	static InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter = 'User \'{0}\' does not have permission to use chat \'{1}\'!';
	static Total = 'Total';
	static RecordUserLogEntryTypePrint = 'Printing';
	static RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError = 'Failed to remove user group links from external systems, because the data has been modified concurrently by another user!';
	static SavingEventTypeBsWasNotSuccessfulErrorParameter = 'Failed to save category 2! Error: \'{0}\'';
	static RecordUserParameterNotFound = 'User record \'{0}\' not found!';
	static SourceDescription = 'Source Description';
	static ParameterResults = '({0} results)';
	static Camera = 'Camera';
	static UserNotAllowedToViewNotifier = 'Limited report handling: User not allowed to view notifier.';
	static EmployeeTypeMustBeDefined = 'Employment type must be defined!';
	static DeletingMessageParameterWasNotSuccessfulConcurrencyError = 'Failed to remove message \'{0}\', because data in the message has been modified concurrently by another user!';
	static DeletingUserGroupsParameterWasNotSuccessfulErrorParameter = 'Failed to remove user groups \'{0}\'! Error: \'{1}\'';
	static ExpiredFamiliarizations = 'Expired Familiarizations';
	static AllUserGroups = 'All User Groups';
	static SavingRecordUserParameterWasSuccessful = 'User record \'{0}\' saved successfully!';
	static EmailAddress = 'Email';
	static IncludeArchivedFamiliarizations = 'Include archived familiarizations';
	static TaskMustBeSavedBeforeNewDataRowsCanBeAdded = 'Task must be saved before new rows can be added!';
	static RecordUserTrangerredPropertyParameterNotFound = 'Transferred property \'{0}\' not found!';
	static RemoveTaskState = 'Remove Task State';
	static AddingAttachmentsParameterWasSuccessful = 'Attachments \'{0}\' added successfully!';
	static PasswordConfirmationDoesNotMatchPassword = 'Password does not match the confirm password!';
	static DeletingFileFilterParameterWasSuccessful = 'File filter \'{0}\' removed successfully!';
	static RemovePayer = 'Remove Payer';
	static NoneSelected = 'None Selected';
	static UpdateUserParameterExternalSystemsWasSuccessful = 'User \'{0}\' linked to external systems successfully!';
	static SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError = 'Failed to save task priority \'{0}\', because data in the task priority has been modified concurrently by another user!';
	static DefaultBasicEducations = 'Default Basic Educations';
	static TiedostoturvaUseType = 'Tiedostoturva';
	static OptionCodeIsAlreadyInUse = 'System option code \'{0}\' is already in use!';
	static ConfirmPassword = 'Confirm Password';
	static Repeated = 'Repeated';
	static RemoveComment = 'Remove Comment';
	static ActivePlural = 'Active';
	static MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB = 'Maximum total size of attachments that can be added in batch is {0} MB!';
	static InstantMessageUserInvitationAcceptTypeCannotInvite = 'I cannot be invited to join chat';
	static SavingUnitsWasSuccessful = 'Units saved successfully!';
	static DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter = 'Failed to remove document template \'{0}\'! Error: \'{1}\'';
	static Sending = 'Sending...';
	static UserIsActiveInMobile = 'User account is active in mobile application.';
	static Search = 'Search';
	static FolderMustBeEmptyBeforeItCanBeRemoved = 'Folder must be empty before it can be removed!';
	static Approve = 'Approve';
	static EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Category 3 \'{0}\' subcategories are used in {1} incidents and category 3 cannot be removed!';
	static FileSynchronizationToDatabaseFinishedInParameterSeconds = 'Filesystem synchronization with database completed (duration: {0:N0}s, errors: {1}, folders: {2}, folders without errors: {3})';
	static DefaultEventHandlersAndNotifiedPersons = 'Default Incident Handlers and Notfications';
	static YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType = 'You have not saved changes that you have made. Save changes before publishing the task type.';
	static AdditionalInformation = 'Additional Information';
	static AddingTaskTypeParameterWasSuccessful = 'Task type \'{0}\' added successfully!';
	static DefaultFurtherEducations = 'Default Further Educations';
	static AddDefaultTaskType = 'Add Default Task Type';
	static EditLogisticVoucherCustomer = 'Edit Customer';
	static DeletingUserGroupParameterWasNotSuccessfulErrorParameter = 'Failed to remove user group \'{0}\'! Error: \'{1}\'';
	static RemoveDocument = 'Remove Selected Files/Documents';
	static InstantMessageChannelUsersNotUsable = 'User does not anymore belong to chat participants';
	static DoYouReallyWantToRemovePartnerParameter = 'Do you really want to remove partner \'{0}\'?';
	static SavingPrintWasSuccessful = 'Print saved successfully!';
	static AccountNumberTkbOutCredit = 'Trade Account';
	static DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter = 'Failed to remove further education \'{0}\'! Error: \'{1}\'';
	static Title = 'Title';
	static ApprovalComment = 'Approval Comment';
	static NewCompanyMustBeSavedBeforePartnersCanBeAdded = 'New company must be saved before partners can be added!';
	static DeletingEditableDocumentParameterWasSuccessful = 'Document \'{0}\' removed successfully!';
	static EditDefaultTaskPriority = 'Edit Default Task Priority';
	static ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers = 'User changes will lead to situation where some default incident handlers or notified persons do not have permissions to handle incidents of units. Changes that have affect for default incident handlers or notified persons can be made only in \'Incidents Properties\' dialog!';
	static VersionHistory = 'Version History';
	static EditDefaultEventType = 'Edit Default Category 1';
	static EditDefaultTaskType = 'Edit Default Task Type';
	static PageName = 'Page Name';
	static EditLogisticVoucherCompanyPaymentSetting = 'Edit Payment Setting';
	static AddingTaskTypesParameterWasSuccessful = 'Task types \'{0}\' added successfully!';
	static WhistleBlowChannelSettings = 'Whistle Blow Channel Settings';
	static FileNameMustBeDefined = 'File name must be defined!';
	static EInvoiceAddress = 'E-invoice Address';
	static SavingDefaultHandlersWasNotSuccessfulConcurrencyError = 'Failed to save default handlers, because default handlers have been modified concurrently by another user!';
	static AddDefaultEventHandler = 'Add Default Incident Handler';
	static LockedDocumentsParameterCannotBeMoved = 'Locked documents \'{0}\' cannot be moved!';
	static OptionParameterNotFound = 'System option \'{0}\' not found!';
	static MessageUnit = 'Publisher Unit';
	static CommentMustNotBeEmpty = 'Comment must not be empty!';
	static AddFileOrTakePicture = 'Add a file or take a picture';
	static WhistleBlowResolutionJunk = 'Junk Mail';
	static Loading = 'Loading...';
	static PrintRow = 'Print Row';
	static Logo = 'Logo';
	static DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError = 'Failed to remove user group connections related to company \'{0}\', because data in those user group connections has been modified concurrently by another user!';
	static Summary = 'Summary';
	static DeletingTaskParameterWasSuccessful = 'Task \'{0}\' removed successfully!';
	static Communication = 'Communication';
	static DataTransferError = 'Data transfer error!';
	static AddCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Failed to add company group \'{0}\'! Error: \'{1}\'';
	static ValidityEndDateStart = 'Validity End Date - Start';
	static DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError = 'Failed to remove employment \'{0}\',  because data in the employment has been modified concurrently by another user!';
	static ExpiringIn180Days = 'Include educations expiring in 180 days';
	static LoggedInCompanyCannotBeDeleted = 'Company cannot remove itself!';
	static LogSizeInDays = 'Log Size in Days (0 = No Limit)';
	static RemoveUserAndRecordUserArchive = 'Remove User Account and Archive User Record';
	static UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError = 'Failed to link user \'{0}\' to external systems, because the data has been modified concurrently by another user!';
	static Warning = 'Warning';
	static Employments = 'Employments';
	static LoginAddress = 'Log In Address';
	static LoginTitle = 'Log in to PKY-LAATU Intra';
	static OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater = 'Only image and pdf attachments are allowed! Attachments \'{0}\' are Invalid.';
	static LockedTaskDataRowCannotBeDeleted = 'Locked task row cannot be removed!';
	static ReportGenerationAborted = 'Report creation canceled!';
	static DeletingEventParameterWasNotSuccessfulConcurrencyError = 'Failed to remove incident \'{0}\', because data in the incident has been modified concurrently by another user!';
	static EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Category 3 \'{0}\' is used in {1} incidents and cannot be removed!';
	static PanelSize = 'Panel Size';
	static ApproveEvent = 'Approve Event';
	static RecordUserExcelUserList = 'Personnel';
	static AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter = 'Failed to add transferred properties! Error: \'{0}\'';
	static InstantMessageParameterNotFound = 'Instant message \'{0}\' not found!';
	static AddBasicEducation = 'Add Basic Education';
	static JobTypeMessageNotification = 'Message Notification';
	static SendingEmails = 'Sending emails...';
	static CopyingFolderParameterWasNotSuccessfulErrorParameter = 'Failed to copy folder \'{0}\' into folder \'{1}\' in filesystem! Error: \'{2}\'';
	static DeletingRecordBasicEducationParameterWasSuccessful = 'Basic education \'{0}\' removed successfully!';
	static DocumentVersionParameterNotFound = 'Document version \'{0}\' not found!';
	static AddWhistleBlowCategory = 'Add Whistle Blow Category';
	static RemoveInstantMessageChannelUser = 'Remove participant';
	static SavingImagesWasSuccessful = 'Images saved successfully!';
	static RecordFamiliarizationCodeIsAlreadyInUse = 'Familiarization name is already in use!';
	static SavingEventTypeCParameterWasNotSuccessfulErrorParameter = 'Failed to save category 3 \'{0}\'! Error: \'{1}\'';
	static TargetsMyCompany = 'Visibility - My Company';
	static UpdatingApplication = 'Updating application...';
	static RecordFurtherEducationCodeIsAlreadyInUse = 'Further education name is already in use!';
	static AddRecordUserAndUserAccount = 'Add user record and new user account';
	static EventTypeCTitle = 'Incident Identification Category 3';
	static TaskStateCodeIsAlreadyInUse = 'Task state name is already in use!';
	static Anonymity = 'Anonymity';
	static AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter = 'Failed to add qualifications! Error: \'{0}\'';
	static InstantMessageChannelInformation = 'Chat information';
	static DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError = 'Failed to remove participant \'{0}\', because the data has been modified concurrently by another user!';
	static AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError = 'Failed to add company groups \'{0}\', because the data has been modified concurrently by another user!';
	static RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter = 'Failed to unlock record \'{0}\'! The record is write locked to user \'{0}\'!';
	static UnitProperties = 'Unit Properties';
	static JobDataIsEmpty = 'Job data is missing!';
	static Amount = 'Amount';
	static WhistleBlowDateAndTimeCannotBeInFuture = 'Incident time cannot be in future!';
	static SavingTaskTypeParameterWasSuccessful = 'Task type \'{0}\' saved successfully!';
	static PasswordMustBeDefined = 'Password must be defined!';
	static TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Task state \'{0}\' is used in {1} tasks and cannot be removed!';
	static UserAccountConnected = 'User Account Connected';
	static YouHaveUnapprovedChangesDoYouWantApproveChanges = 'You have not approved changes that you have made. Do you want to approve changes?';
	static DeletingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Failed to remove task type \'{0}\'! Error: \'{1}\'';
	static EventReceiverIsPartnerCompany = 'Incident receiver is partner company';
	static ProcessingTimeDays = 'Processing Time (d)';
	static ClosedPlural = 'Closed';
	static AdditionalPermissionBEventsDialog = 'Removing Incident';
	static PublishVoucherInOutReportToFileSystem = 'Publish Voucher Summary Print to File System';
	static AddInstantMessageUsersParameterWasSuccessful = 'Participants \'{0}\' added successfully!';
	static AdminMustBeDefinedForSectionParameter = 'Administrator must be defined for section \'{0}\'!';
	static SavingMessageParameterWasNotSuccessfulConcurrencyError = 'Failed to save message \'{0}\', because data in the message has been modified concurrently by another user!';
	static RecordUserActiveStateChangePermissionDeniedInUnitParameter = 'Archived state change is not allowed in unit \'{0}\'!';
	static DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter = 'Failed to remove familiarization \'{0}\'! Error: \'{1}\'';
	static Year = 'Year';
	static CourseCredits = 'Course Credits';
	static SelectUserGroup = 'Select User Group';
	static DeletingDirectoryItemFolderParameterWasSuccessful = 'Folder \'{0}\' removed successfully!';
	static SelectTaskType = 'Select Task Type';
	static DocumentTemplateCodeMustBeDefined = 'Document template code must be defined!';
	static ServiceFeePercent = 'Service Fee (%)';
	static SearchFurtherEducations = 'Search Further Educations';
	static PermissionDeniedForOperation = 'Permission denied for operation!';
	static DeletingRecordQualificationParameterWasSuccessful = 'Qualification \'{0}\' removed successfully!';
	static Preview = 'Preview';
	static UpdatingDatabase = 'Updating database...';
	static Restore = 'Restore';
	static SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError = 'Failed to save familiarization \'{0}\', because data in the familiarization has been modified concurrently by another user!';
	static UserName = 'Username';
	static TaskTypeNameMustBeDefined = 'Task type name must be defined!';
	static SavingEventTypeCParameterWasSuccessful = 'Category 3 \'{0}\' saved successfully!';
	static RequestValidationErrorOnFieldParameterWithValueParameter = 'The value \'{1}\' of the field \'{0}\' contains content that is considered dangerous in web applications!';
	static WhistleBlowMessageHasNotBeenSentCloseConfirmation = 'An unfinished message has not been sent yet. Do you want to close the view?';
	static SelectCustomer = 'Select Customer';
	static Payer = 'Payer';
	static EventTypeCParameterNotFound = 'Category 3 \'{0}\' not found!';
	static ExternalSystemUserTokenParameterIsAlreadyInUse = 'User token \'{0}\' is already in use!';
	static CreatingFileParameterWasNotSuccessfulErrorParameter = 'Failed to create file \'{0}\' in filesystem! Error: \'{1}\'';
	static AccessRightWriteInstantMessages = 'Company Internal and Intercompany Chat';
	static Description = 'Description';
	static DocumentPublished = 'Published By';
	static AddUnit = 'Add Unit';
	static EditableDocumentVersionMustBeDefined = 'Document version number must be defined!';
	static AttachmentIsEmpty = 'Attachment \'{0}\' is empty!';
	static FolderNameIsTooLong = 'Folder name \'{0}\' is too long!';
	static Time = 'Time';
	static CopyPermissionToAllSameLevelUnits = 'Copy Permission to All Same Level Units';
	static Payee = 'Payee';
	static SelectEventTypeB = 'Select Category 2';
	static SelectCompanyGroupOrCompany = 'Select Company Group or Company';
	static Message = 'Message';
	static TaskCreated = 'Created By';
	static EmploymentEndDateCannotPrecedeEmploymentStartDate = 'Employment end date cannot precede employment start date!';
	static DoYouReallyWantToUnlockEditableDocument = 'Do you really want to unlock document \'{0}\'?';
	static NoImagesToShow = 'No images to show';
	static WhistleBlowGuide = 'Guide for Reporting';
	static TaskModuleProperties = 'Tasks Properties';
	static TrashBin = 'Trash Bin';
	static TimeSpanTwoYears = 'Two Years';
	static ExternalsSystemParameterNotFound = 'External system \'{0}\' not found!';
	static SavingJobParameterWasSuccessful = 'Job \'{0}\' saved successfully!';
	static TypeMustBeDefined = 'Type must be defined!';
	static TransferredPropertyType = 'Transferred Property Type';
	static SaveUserFurtherEducationConfirmation = 'Do you want to save the further education?';
	static AddUserGroupsParameterWasNotSuccessfulErrorParameter = 'Failed to add user groups \'{0}\'! Error: \'{1}\'';
	static EmploymentStartDate = 'Employment Start Date';
	static RemoveSharedDocumentTemplate = 'Remove Shared Document Template';
	static DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords = 'Note! Decreasing version history size removes previously saved versions from version history.';
	static SavingEventDefaultHandlerParameterWasSuccessful = 'Default incident handler \'{0}\' saved successfully!';
	static NewInstantMessageChannel = 'New Chat';
	static WhistleBlowReportHasBeenSavedTopText = 'Store below password in secure storage. The passwords gives you access to you report. Do not share password with anyone!';
	static Days = 'days';
	static DeletingInstantMessageUsersParameterWasSuccessful = 'Participants \'{0}\' removed successfully!';
	static CopyDocumentsFromCompany = 'Copy Documents from This Company';
	static RemoveUser = 'Remove User Account';
	static SavingSettingsWasSuccessful = 'Settings saved successfully!';
	static EventHandlerParameterNotFound = 'Handler \'{0}\' not found!';
	static ApplicationTempPathParameterNotFound = 'Application temp-folder \'{0}\' not found!';
	static DeletingEventTypeParameterWasNotSuccessfulConcurrencyError = 'Failed to remove category 1 \'{0}\', because data in the category 1 has been modified concurrently by another user!';
	static MessageTypeParameterNotFound = 'Message type \'{0}\' not found!';
	static SSRSRefreshStarted = 'SSRS refresh started';
	static ValueAddedTaxPercent = 'Value Added Tax %';
	static MaximumPasswordLengthCanParameterCharacters = 'Maximum password length can be {0} characters!';
	static DeletingPartnersParameterWasNotSuccessfulErrorParameter = 'Failed to remove partners\'{0}\'! Error: \'{1}\'';
	static DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter = 'Failed to remove administrator \'{0}\'! Error: \'{1}\'';
	static FileParameterNotFound = 'File \'{0}\' not found!';
	static InstantMessageChannelOneUser = 'Chat';
	static DeletingWhistleBlowChannelAdminParameterWasSuccessful = 'Administrator \'{0}\' removed successfully!';
	static TaskPrioritiesMustBeDefinedBeforeAddingTasks = 'Task priorities must be defined before adding tasks!';
	static LogModuleProperties = 'Logs Properties';
	static EditableDocumentPublishUnapprovedConfirmation = 'Do you want to publish unapproved document to file?';
	static SelectEventHandler = 'Select Handler';
	static TargetCompany = 'Target Company';
	static ChangeSystemLanguageToSwedish = 'Change Language to Swedish';
	static Group = 'Group';
	static MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB = 'Maximum total size of documents that can be added in batch is {0} MB!';
	static RecordEmailNotificationType = 'I Want to Receive Further Educations Report (Expiring Educations)';
	static SelectArchivingMethod = 'Select Archiving Method';
	static InstantMessageChannel = 'Chat';
	static DeletingAttachmentParameterWasNotSuccessfulErrorParameter = 'Failed to remove attachment \'{0}\'! Error: \'{1}\'';
	static RemoveRecordUserAndUser = 'Remove User Record and User Account';
	static Company = 'Company';
	static AddEventMessage = 'Add Comment';
	static SavingTaskPriorityParameterWasNotSuccessfulErrorParameter = 'Failed to save task priority \'{0}\'! Error: \'{1}\'';
	static DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter = 'Do you really want to remove transferred property \'{0}\'?';
	static FirstNameMustBeDefined = 'First name must be defined!';
	static EditWhistleBlowChannel = 'Edit Whistle Blow Channel';
	static LogLine = 'Log Line';
	static RenamingFileParameterWasNotSuccessfulErrorParameter = 'Failed to rename file (\'{0}\' => \'{1}\') in filesystem! Error: \'{2}\'';
	static AddPartners = 'Add Partners';
	static SenderOrReceiver = 'Sender or Receiver';
	static AddFamiliarization = 'Add Further Familiarization';
	static PreviewPanel = 'Preview';
	static DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError = 'Failed to remove familiarization \'{0}\',  because data in the familiarization has been modified concurrently by another user!';
	static ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers = 'User group changes will lead to situation where some default incident handlers or notified persons do not have permissions to handle incidents of units. Changes that have affect for default incident handlers or notified persons can be made only in \'Incidents Properties\' dialog!';
	static EditFamiliarization = 'Edit Familiarization';
	static RemoveTaskHandler = 'Remove Handler';
	static BirthDate = 'Date of Birth';
	static No = 'No';
	static AddTaskPriority = 'Add Task Priority';
	static RecordUserExcelFurtherEducation = 'Further Educations';
	static RemoveEventPartner = 'Remove Incident Partner';
	static Documents = 'Documents';
	static Comments = 'Comments';
	static Go = 'Go';
	static DeletingRecordUserParameterWasNotSuccessfulConcurrencyError = 'Failed to remove user record \'{0}\', because data in the user record has been modified concurrently by another user!';
	static Sender = 'Sender';
	static Close = 'Close';
	static CurrentTopics = 'Current Topics';
	static DeletingUserParameterWasSuccessful = 'User account \'{0}\' removed successfully!';
	static CopyingFileParameterWasNotSuccessfulErrorParameter = 'Failed to copy file \'{0}\' as file \'{1}\' in filesystem! Error: \'{2}\'';
	static CopyOrMoveFilesDocuments = 'Copy or Move Selected Files/Documents';
	static RemoveFurtherEducation = 'Remove Further Education';
	static PrintGenerationFailed = 'Failed to create print!';
	static SavingTaskTypeParameterWasNotSuccessfulConcurrencyError = 'Failed to save task type \'{0}\', because data in the task type has been modified concurrently by another user!';
	static RemoveUnit = 'Remove Unit';
	static SortOrder = 'Sort order';
	static FamiliarizationStartDateStart = 'Familiarization Start Date - Start';
	static RecordUserWithSameNameAlreadyExists = 'User record with the same name already exists!';
	static RemoveAttachment = 'Remove Attachment';
	static JobTypePushNotification = 'Push Notification';
	static AddComment = 'Add Comment';
	static EventModuleProperties = 'Incidents Properties';
	static AccessRightReadWhistleBlow = 'Limited reports handling';
	static DoYouReallyWantToRemoveSelectedTaskPriorities = 'Do you really want to remove selected task priorities?';
	static NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded = 'New familiarization must be saved before attachments can be added!';
	static SelectImageFile = 'Select image file!';
	static AddCompanyGroupsParameterWasNotSuccessfulErrorParameter = 'Failed to add company groups \'{0}\'! Error: \'{1}\'';
	static DeletingInstantMessageChannelWasNotSuccessfulErrorParameter = 'Failed to remove chat! Error: \'{0}\'';
	static Created = 'Created By';
	static WhistleBlowFrontPage = 'Front Page';
	static Published = 'Published';
	static CompanyGroups = 'Company Groups';
	static VersionParameterPublishedAsPdfFile = 'Version {0} is published to pdf file';
	static ModifiedDescription = 'Modified Description';
	static TheUserNameOrPasswordIsIncorrect = 'Invalid username or password!';
	static DeletingEventUrgencyClassParameterWasSuccessful = 'Urgency class \'{0}\' removed successfully!';
	static DeletingEventParameterWasNotSuccessfulErrorParameter = 'Failed to remove incident \'{0}\'! Error: \'{1}\'';
	static DoYouReallyWantToRemoveEventParameter = 'Do you really want to remove incident \'{0}\'?';
	static MyReports = 'User\'s Reports';
	static IndefinitelyNoLocalization = 'Indefinitely';
	static ExternalSystems = 'External Systems';
	static SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError = 'Failed to save folder permissions, because folders have been modified concurrently by another user!';
	static Wage = 'Wage';
	static Publisher = 'Published By';
	static Previous = 'Previous';
	static DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError = 'Failed to remove message type \'{0}\', because data in the message type has been modified concurrently by another user!';
	static EmailSent = 'Email Sent';
	static WhistleBlowInformantMustBeNotifiedLatestAtParameter = 'Whistle blow report must be marked as resolved {0} at the latest!';
	static RemoveTransferredProperty = 'Remove Transferred Property';
	static SavingMessageSendPushParameterWasNotSuccessfulErrorParameter = 'Failed to save message push notification log \'{0}\'! Error: \'{1}\'';
	static Month = 'Month';
	static DoYouReallyWantToSaveData = 'Do you really want to save?';
	static UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError = 'Failed to link user group \'{0}\' to external systems, because the data has been modified concurrently by another user!';
	static NoPublishedMessages = 'No published messages';
	static Subject = 'Subject';
	static WhistleBlowUrlInfo = 'Only system administrator can change the address';
	static RemovePartner = 'Remove Partner';
	static FileParameterAlreadyExistsInFileSystem = 'File \'{0}\' already exists in the filesystem!';
	static SavingUserParameterWasSuccessful = 'User \'{0}\' saved successfully!';
	static LogDocumentPreview = 'Log Document Preview';
	static WhistleBlowActiveReports = 'Active Reports';
	static WhistleBlowApplicationName = 'Whistle Blow Channel';
	static DeletingTaskStateParameterWasSuccessful = 'Task state \'{0}\' removed successfully!';
	static FileFilterNameMustBeDefined = 'File filter name must be defined!';
	static EventHandlers = 'Handlers';
	static WhistleBlowResolutionUnspecified = 'Not Resolved';
	static DoYouReallyWantToRemoveRecordUserParameter = 'Do you really want to remove user record \'{0}\'?';
	static RecordFamiliarizationParameterNotFound = 'Familiarization \'{0}\' not found!';
	static TaskStateCodeMustBeDefined = 'Task state name must be defined!';
	static SaveInstantMessageChannel = 'Save Chat';
	static PasswordHasBeenCopiedToClipboard = 'Password has been copied to clipboard!';
	static GeneralInfo = 'General';
	static EventIsNotApprovedByUser = 'User has not approved the incident';
	static EditFurtherEducation = 'Edit Further Education';
	static SavingTaskParameterWasNotSuccessfulErrorParameter = 'Failed to save task \'{0}\'! Error: \'{1}\'';
	static SavingDirectoryItemFolderParameterWasSuccessful = 'Folder \'{0}\' saved successfully!';
	static InstantMessagesMenuTitle = 'HoxUp';
	static FurtherEducationParameterNotFound = 'Further education \'{0}\' not found!';
	static DoYouReallyWantToRemoveUserParameter = 'Do you really want to remove user \'{0}\'?';
	static NoFurtherEducationsToShow = 'No further educations to show!';
	static EditLogisticVoucherTransportCompany = 'Edit Transport Company';
	static OperationNotAllowedForRootFolderParameter = 'Operation is not allowed for root folder \'{0}\'!';
	static SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError = 'Failed to save folder \'{0}\', because data in the folder has been modified concurrently by another user!';
	static SavingTaskStateParameterWasNotSuccessfulErrorParameter = 'Failed to save task state \'{0}\'! Error: \'{1}\'';
	static DeletingRecordFamiliarizationParameterWasSuccessful = 'Familiarization \'{0}\' removed successfully!';
	static AddingAttachmentsParameterWasNotSuccessfulErrorParameter = 'Failed to add attachments \'{0}\'! Error: \'{1}\'';
	static NotSet = 'Not Set';
	static WhistleBlowResolutionRejected = 'Rejected';
	static DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError = 'Failed to remove task priorities \'{0}\', because data in task priorities has been modified concurrently by another user!';
	static ArchivingRecordUserFamiliarizationsWasSuccessful = 'Familiarizations archived successfully!';
	static AdditionalSettings = 'Additional Settings';
	static PublishVersion = 'Publish Version';
	static PostalCode = 'Postal Code';
	static DeletingUserParametersWasNotSuccessfulErrorParameter = 'Failed to remove user parameters! Error: \'{0}\'';
	static ResolutionDescription = 'Resolution Description';
	static DeletingEventUrgencyClassesParameterWasSuccessful = 'Urgency classes \'{0}\' removed successfully!';
	static NoNotificationsToSend = 'No notifications to be sent!';
	static SuccessAudit = 'SuccessAudit';
	static DeletingPartnerParameterWasNotSuccessfulConcurrencyError = 'Failed to remove partner \'{0}\', because the data has been modified concurrently by another user!';
	static SavingOptionParameterWasNotSuccessfulErrorParameter = 'Failed to save option \'{0}\'! Error: \'{1}\'';
	static CompanyNameMustBeDefined = 'Name of the company must be defined!';
	static DoYouReallyWantToRemoveRecordFurtherEducationParameter = 'Do you really want to remove further education \'{0}\'?';
	static NewPasswordMustBeDefined = 'New password must be defined!';
	static InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted = 'Chat has other participants and cannot be removed!';
	static AddEventPartner = 'Add Incident Partner';
	static AddPartnerParameterWasNotSuccessfulErrorParameter = 'Failed to add partner \'{0}\'! Error: \'{1}\'';
	static GeneralAttachments = 'General Attachments';
	static Selected = 'Selected';
	static DescriptionMustBeDefined = 'Description must be defined!';
	static CompanyGroup = 'Company Group';
	static MoveTaskToTrashBin = 'Move Task toTrash Bin';
	static SavingApplicationPermissionsWasNotSuccessfulConcurrencyError = 'Failed to save system permissions, because data in system permissions has been modified concurrently by another user!';
	static List = 'List';
	static DeletingTaskTypeParameterWasSuccessful = 'Task type \'{0}\' removed successfully!';
	static NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded = 'New transferred property must be saved before attachments can be added!';
	static Header = 'Header';
	static EventHandlersExcelTitle = 'Handlers (x = approved)';
	static Start = 'Start';
	static AddingAddRecordUserQualificationsWasSuccessful = 'Qualifications added successfully!';
	static CompanyModuleProperties = 'Companies Properties';
	static DocumentRootPathIsNotEmpty = 'Document root path is not empty folder!';
	static EventTypeCs = 'Category 3';
	static Remainder = 'Remainder';
	static NoFilesToAdd = 'No files to add!';
	static SavingRecordFurtherEducationParameterWasSuccessful = 'Further education \'{0}\' saved successfully!';
	static VoucherSavePath = 'Voucher Save Path';
	static AddTransferredProperty = 'Add Transferred Property';
	static PreviousCalendarMonth = 'Previous Calendar Month';
	static RemoveEventTypeC = 'Remove Category 3';
	static InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation = 'I will automatically accept invitation to join chat';
	static EventTypes = 'Category 1';
	static ParameterIsNotValidBankBICCode = '\'{0}\' is not valid bank BIC code!';
	static SharedFilter = 'Shared Filter';
	static SavingEventTypesWasSuccessful = 'Category 1 saved successfully!';
	static DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError = 'Failed to remove document \'{0}\', because data in the document has been modified concurrently by another user!';
	static AddingWhistleBlowHandlersWasSuccessful = 'Handlers added successfully!';
	static AddNotifiedPerson = 'Add Person to Be Notified';
	static SystemAdminMessage = 'Message to all system users';
	static SendingEmail = 'Sending email...';
	static EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError = 'Failed to empty task trash bin, because data in the tasks has been modified concurrently by another user!';
	static PortalInUse = 'Login Portal Is in Use';
	static SavingRecordBasicEducationParameterWasSuccessful = 'Basic education \'{0}\' saved successfully!';
	static EventSettingsParameterNotFound = 'Incident settings \'{0}\' not found!';
	static DeletingPartnersParameterWasSuccessful = 'Partners \'{0}\' removed successfully!';
	static EmployeeType = 'Employee Type';
	static CompanyTaskTypes = 'Company Task Types';
	static DeletingUserParametersWasNotSuccessfulConcurrencyError = 'Failed to remove user parameters, because data in the parameters has been modified concurrently by another user!';
	static RemoveUserGroup = 'Remove User Group';
	static HandlersMustBeDefined = 'Handlers must be defined!';
	static DoYouReallyWantToRemoveRecordBasicEducationParameter = 'Do you really want to remove basic education \'{0}\'?';
	static AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter = 'Failed to add administrators \'{0}\'! Error: \'{1}\'';
	static SelectEventReceiver = 'Select Incident Receiver';
	static Modification = 'Modification';
	static AddLogisticVoucherPaymentSetting = 'Add Payment Setting';
	static EditCompany = 'Edit Company';
	static ChangingPasswordWasNotSuccessfulErrorParameter = 'Failed to change password! Error: \'{0}\'';
	static DoYouReallyWantToUnlockEvent = 'Do you really want to unlock incident \'{0}\'?';
	static UserModuleProperties = 'User Accounts Properties';
	static NoReports = 'No Reports!';
	static SharedFiltersAndReports = 'Shared Filters and Reports';
	static AddTaskHandler = 'Add Handler';
	static WhistleBlowStateNew = 'New';
	static SavingAttachmentsWasNotSuccessfulErrorParameter = 'Failed to save attachments! Error: \'{0}\'';
	static UnkownErrorHasBeenOccured = 'Uknown error has been occured in the system!';
	static SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter = 'Failed to save whistle blow channel \'{0}\'! Error: \'{1}\'';
	static SaveComment = 'Save Comment';
	static RemoveFilter = 'Remove Filter';
	static Drafts = 'Drafts';
	static FolderTreePermissions = 'Folder Tree Permissions';
	static LockedToUser = 'Locked by User';
	static Custom = 'Custom';
	static Collapse = 'Collapse';
	static RestoringInstantMessageWasSuccessful = 'Instant message restored successfully!';
	static PublishVersionHistorySize = 'Publish Version History Size';
	static AddDocumentTemplate = 'Add Document Template';
	static WhistleBlowDateAndTime = 'Incident Time';
	static EmptyingTaskTrashBinWasSuccessful = 'Task trash bin is now empty!';
	static ShowOnlyUnitsThatAreInUse = 'Show Only Units That Are in Use';
	static ModifyDateStartDate = 'Last Modified From';
	static Missing = 'Missing';
	static WorkTimeType = 'Work Time Type';
	static EditUserGroup = 'Edit User Group';
	static DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove administrators \'{0}\', because the data has been modified concurrently by another user!';
	static Channels = 'Chats';
	static MaximumTotalSizeOfAttachmentsIsParameterMB = 'Maximum total size of all attachments is  {0} MB!';
	static EditDefaultEventHandlersAndNotifiedPersons = 'Edit Default Incident Handlers and Notified Persons';
	static CompanyGroupAll = '\'All Companies\'';
	static SavingTaskDataRowWasNotSuccessfulErrorParameter = 'Failed to save the task row! Error: \'{0}\'';
	static DeletingEventTypeCParameterWasNotSuccessfulErrorParameter = 'Failed to remove category 3 \'{0}\'! Error: \'{1}\'';
	static EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Category 1 \'{0}\' subcategories are used in {1} incidents and category 1 cannot be removed!';
	static ClearSubFolderPermissions = 'Clear Subfolder Permissions';
	static CurrentWorkPlaceNotAdded = 'Current workplace not added!';
	static NoInstantMessageChannelsToShow = 'No chats to show!';
	static EventTypeC = 'Category 3';
	static ChangePassword = 'Change Password';
	static FolderNameMustBeDefined = 'Folder name must be defined!';
	static Severity = 'Severity';
	static TaskRemainderCheckingAndNotificationFinishedInParameterSeconds = 'Task remainder checking and notification finished (duration: {0:N0}s, errors: {1}, tasks: {2}, emails: {3}, push notifications: {4})';
	static SavingUserParameterWasNotSuccessfulConcurrencyError = 'Failed to save user \'{0}\', because user data has been modified concurrently by another user!';
	static WhistleBlowParameterNotFound = 'Whistle blow report \'{0}\' not found!';
	static Observer = 'Observer';
	static DeletingPartnerParameterWasNotSuccessfulErrorParameter = 'Failed to remove partner \'{0}\'! Error: \'{1}\'';
	static SavingEventTypeCParameterWasNotSuccessfulConcurrencyError = 'Failed to save category 3 \'{0}\', because data in the category 3 has been modified concurrently by another user!';
	static DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter = 'Failed to remove participants \'{0}\'! Error: \'{1}\'';
	static AddingEventHandlersWasSuccessful = 'Handlers added successfully!';
	static Ascending = 'Ascending';
	static UnlockingTaskDataRowWasNotSuccessful = 'Failed to unlock the task row!';
	static Save = 'Save';
	static RecordFurtherEducationCodeMustBeDefined = 'Further education name must be defined!';
	static PaymentTermInDays = 'Payment Term in Days';
	static DefaultTaskStates = 'Default Task States';
	static SavingMessageParameterWasNotSuccessfulErrorParameter = 'Failed to save message \'{0}\'! Error: \'{1}\'';
	static AddUsersParameterWasNotSuccessfulConcurrencyError = 'Failed to add user accounts \'{0}\', because the data has been modified concurrently by another user!';
	static ExternalSystemDatabaseInterfaceError = 'External eSalkku system database interface error!';
	static RemoveUserAndKeepRecordUser = 'Remove User Account and Keep User Record';
	static AllLogisticVoucherCustomers = 'All Customers';
	static Next = 'Next';
	static AddTransferredProperties = 'Add Transferred Properties';
	static AddingTaskTypesParameterWasNotSuccessfulErrorParameter = 'Failed to add task types \'{0}\'! Error: \'{1}\'';
	static EditableDocumentSaveBeforeApproveConfirmation = 'Do want to save changes  to the document before approval?';
	static SelectEventType = 'Select Category 1';
	static Actions = 'Actions';
	static SelectTaskPriority = 'Select Task Priority';
	static EventClosed = 'Incident Closed';
	static Remainders = 'Remainders';
	static UsedFileStorageQuota = 'Used File Storage Quota';
	static Yes = 'Yes';
	static AddLogisticVoucherCustomerPaymentSetting = 'Add Payment Setting';
	static Interval = 'Interval';
	static SelectUrgencyClass = 'Select Urgency Class';
	static Receiver = 'Receiver';
	static SavingEventMessageWasNotSuccessfulConcurrencyError = 'Failed to save comment, because data in the comment has been modified concurrently by another user!';
	static InTesting = 'In Testing';
	static Qualification = 'Qualification';
	static AttachmentParameterDownloaded = 'Attachment has been downloaded.';
	static RemoveFromFavorites = 'Remove from Favorites';
	static SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError = 'Failed to save message email log \'{0}\', because data in the email log has been modified concurrently by another user!';
	static DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError = 'Failed to remove administrator \'{0}\', because the data has been modified concurrently by another user!';
	static EventParameterUnitChangedFromParameterToParameter = 'Incident \'{0}\' unit changed from \'{1}\' to \'{2}\'.';
	static VoucherPrefix = 'Voucher Prefix';
	static Form = 'Form';
	static SavingTaskParameterWasNotSuccessfulConcurrencyError = 'Failed to save task \'{0}\', because task has been modified concurrently by another user!';
	static DeletingRecordFamiliarizationsParameterWasSuccessful = 'Familiarizations \'{0}\' removed successfully!';
	static UserCodeMustBeDefined = 'Username must be defined!';
	static AddUserGroupsParameterWasNotSuccessfulConcurrencyError = 'Failed to add user groups \'{0}\', because the data has been modified concurrently by another user!';
	static DeletingWhistleBlowParameterWasSuccessful = 'Whistle blow report \'{0}\' removed successfully!';
	static BusinessIdentityCode = 'Business Identity Code';
	static RemoveCompanyTaskType = 'Remove Company Task State';
	static RemoveLogisticVoucherPaymentSetting = 'Remove Payment Setting';
	static SearchDocuments = 'Search Documents';
	static RecordParameterLockingFailed = 'Failed to lock record \'{0}\'!';
	static SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter = 'Failed to save message email log \'{0}\'! Error: \'{1}\'';
	static FieldParameterContainsInvalidCodeCharacters = 'Value of the field \'{0}\' contains characters that are illegal in codes ( \, ^, ?, &, <, >, /, +, :) or it ends with illegal characters (.)!';
	static CustomerNumber = 'Customer Number';
	static DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError = 'Failed to remove task type \'{0}\', because data in the task type has been modified concurrently by another user!';
	static RecordUserExcelFamiliarization = 'Familiarizations';
	static PageBreakBetweenGroups = 'Add page break between groups';
	static AddTask = 'Add Task';
	static Filter = 'Filter';
	static EventTypeBParameterNotFound = 'Category 2 \'{0}\' not found!';
	static DoYouWantToAddThisApplicationToHomeScreen = 'Do you want to add this application to home screen?';
	static WhistleBlowAnonymousReportInfo = 'If you do not want to reveal your name during the reporting process, choose this option.';
	static LanguageNonLocalFinnish = 'Suomi';
	static DocumentZipFileName = 'Documents_{0:yyyy-MM-dd_HH-mm}.zip';
	static JobLineParameterNotFound = 'Job line \'{0}\' not found!';
	static DocumentEndDate = 'Valid Until';
	static SavingInstantMessageWasNotSuccessfulErrorParameter = 'Failed to save instant message! Error: \'{0}\'';
	static DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError = 'Failed to remove document template \'{0}\', because data in the document template has been modified concurrently by another user!';
	static AddTransferredPropertyToMultipleUserRecords = 'Add Transferred Property to Multiple User Records';
	static DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError = 'Failed to remove participant, because the data has been modified concurrently by another user!';
	static AddChildEventType = 'Add Subcategory 1';
	static QualificationIsInvalid = 'Qualification is not valid!';
	static AddUserGroups = 'Add User Groups';
	static DeletingEventTypeBParameterWasSuccessful = 'Category 2 \'{0}\' removed successfully!';
	static CompanyLogo = 'Company Logo';
	static KeywordParameterNotFound = 'Keyword \'{0}\' not found!';
	static PrintRecordUser = 'Print User Record';
	static SavingFilterWasNotSuccessfulConcurrencyError = 'Failed to save filter, because data in the filter has been modified concurrently by another user!';
	static EventPartners = 'Incident Partners';
	static DeletingAttachmentsParameterWasNotSuccessfulErrorParameter = 'Failed to remove attachments \'{0}\'! Error: \'{1}\'';
	static FolderParameterNotFound = 'Folder \'{0}\' not found!';
	static Salary = 'Salary';
	static ClickToCancelAutomaticLogout = 'User session is about to expire, please click this dialog to prevent automatic logout.';
	static TaskStates = 'Task States';
	static DefaultLanguage = 'Language';
	static Last180Days = 'Last 180 Days';
	static WhistleBlowSourceEmail = 'Email';
	static DatabaseCheckingStarted = 'Database checking started';
	static UnitChangedFromParameterToParameter = 'Unit changed from \'{0}\' to \'{1}\'.';
	static BrowserInfo = 'Browser';
	static ApplicationName = 'eSalkku';
	static SSRSRefreshFinishedInParameterSeconds = 'SSRS refresh finished (duration: {0:N0}s)';
	static EventParameterNotFound = 'Incident \'{0}\' not found!';
	static InstantMessageChannelAdministrator = 'Administrator';
	static SavingEventSettingsWasNotSuccessfulConcurrencyError = 'Failed to save incident settings, because  incident settings have been modified concurrently by another user!';
	static DeletingMessageTypeParameterWasNotSuccessfulErrorParameter = 'Failed to remove message type \'{0}\'! Error: \'{1}\'';
	static ExternalSystemCompanyCodeClaimNotFound = 'User claim \'externalSystemCompanyCode\' not found!';
	static LockedTaskParameterCannotBeSaved = 'The task \'{0}\' has been locked to another user and it cannot be saved!';
	static WhistleBlowReport = 'Whistle Blow Report';
	static IncludeArchivedRecordUsers = 'Include archived user records';
	static SavingEventTypeBParameterWasNotSuccessfulErrorParameter = 'Failed to save category 2 \'{0}\'! Error: \'{1}\'';
	static DoYouReallyWantToUnlockTaskDataRow = 'Do you really want to unlock task row?';
	static SavePrint = 'Save Print';
	static SendReadReceipt = 'Send Read Receipt';
	static ValidityDate = 'Validity Date';
	static FieldParameterContainsInvalidCompanyCodeCharacters = 'Value of the field \'{0}\' contains characters that are illegal in company code (., &, :, \' \', ö, ä, à, å, Ö, Ä, À tai Å)!';
	static RecordUserExcelSalary = 'Salary';
	static WhistleBlowCategory = 'Whistle Blow Category';
	static AccessRightWrite = 'Write permission';
	static TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Task type \'{0}\' is used in {1} tasks and cannot be removed!';
	static DocumentApproval = 'Document Approval';
	static DeletingRecordQualificationsParameterWasSuccessful = 'Qualifications \'{0}\' removed successfully!';
	static PermissionToIdentificateEvents = 'Permission to Create and Identify Incidents';
	static SendingPusNotificationsWasNotSuccessfulErrorParameter = 'Failed to send push notifications! Error: \'{0}\'';
	static DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove company groups \'{0}\', because the data has been modified concurrently by another user!';
	static Years = 'Years';
	static EditMessage = 'Edit Message';
	static DeletingRecordUserParameterWasNotSuccessfulErrorParameter = 'Failed to remove user record \'{0}\'! Error: \'{1}\'';
	static SaveTaskConfirmation = 'Do you want to save the task?';
	static EventSenderIsPartnerCompany = 'Incident sender is partner company';
	static UserPasswordMustBeValid = 'Password must be at least 8 characters long and it must contain lowercase letters, capital letters and at least one number or special character.';
	static PrintEvent = 'Print Incident';
	static PayeeUsersCanLogInToTheSystem = 'Company users can log in to the system';
	static MessageMustBeDefined = 'Message contents must be defined!';
	static ChangedUrgencyClass = 'Changed incident urgency class';
	static InviteInstantMessageChannelUser = 'Invite Participant';
	static UserEventsNotApproved = 'User events not approved';
	static NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded = 'New company must be saved before company groups can be added!';
	static SendReadReceiptInfo = 'Send read receipt by tapping &#10003; button.';
	static DefaultEventUrgencyClasses = 'Default Urgency Classes';
	static WhistleBlowInformantDateAndTime = 'When did this happen?';
	static SavingUserParameterParameterWasSuccessful = 'User option \'{0}\' saved successfully!';
	static PreviewIsNotSupportedForThisFileType = 'Preview is not supported for this file type!';
	static Last7Days = 'Last 7 Days';
	static Picture = 'Picture';
	static AddPermission = 'Add Permission';
	static EmailMessage = 'Send Message by Email';
	static LastName = 'Last Name';
	static DeletingTaskStateParameterWasNotSuccessfulErrorParameter = 'Failed to remove task state \'{0}\'! Error: \'{1}\'';
	static SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter = 'Failed to save file \'{0}\'! Error: \'{1}\'';
	static LockingEditableDocumentParameterWasNotSuccessful = 'Failed to lock document \'{0}\'!';
	static DeletingAttachmentParameterWasSuccessful = 'Attachment \'{0}\' removed successfully!';
	static ValidPlural = 'Valid';
	static RootCause = 'Root Cause';
	static Partner = 'Partner';
	static PermissionsForSections = 'Sections in Use';
	static ArchiveWhistleBlows = 'Archive Whistle Blow Reports';
	static Version = 'Version';
	static ReportsStandard = 'Standard Reports';
	static ReadReceiptGiven = 'Receipt sent';
	static SavingMessageTypeParameterWasNotSuccessfulErrorParameter = 'Failed to save message type \'{0}\'! Error: \'{1}\'';
	static RemoveUserFromUserGroup = 'Remove User Account from User Group';
	static DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents = 'Default urgency class must be defined before adding incidents!';
	static ClearSubUnitPermissions = 'Clear Subunit Permissions';
	static AType = 'Type';
	static RemoveMessageType = 'Remove Message Type';
	static DocumentNameIsIllegal = 'Document name is not allowed (reserved name)!';
	static AddCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Failed to company group \'{0}\', because the data has been modified concurrently by another user!';
	static EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Category 2 \'{0}\' subcategories are used in {1} incidents and category 2 cannot be removed!';
	static UnlockingTaskParameterWasNotSuccessful = 'Failed to unlock task \'{0}\'!';
	static Row = 'row';
	static SaveInstantMessageChannelConfirmation = 'Do you want to save the chat?';
	static NotSelected = 'Not Selected';
	static Private = 'Private';
	static SavingUserParametersWasNotSuccessfulErrorParameter = 'Failed to save user options! Error: \'{0}\'';
	static Identification = 'Identification';
	static InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter = 'Remove Archived Chat and Messages at {0}';
	static TaskPriorityParameterNotFound = 'Task priority \'{0}\' not found!';
	static Uploaded = 'Uploaded';
	static AutomaticLogoutDone = 'Automatic logout done due to expired user session!';
	static RemoveTaskType = 'Remove Task Type';
	static EventTypeBTitle = 'Incident Identification Category 2';
	static SelectUnit = 'Select Unit';
	static DeletingUsersParameterWasSuccessful = 'User accounts \'{0}\' removed successfully!';
	static AddQualification = 'Add Qualification';
	static DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter = 'Failed to remove participant \'{0}\'! Error: \'{1}\'';
	static DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders = 'Failed to remove file filter \'{0}\', because the file filter is used in {1} folder!';
	static UnitIsRelatedToEventPartner = 'Unit is related to incident partner';
	static NoFilters = ' No Filters!';
	static EditDefaultFurtherEducation = 'Edit Default Further Education';
	static UserGroups = 'User Groups';
	static RecordUserLogEntryTypeCreate = 'Create';
	static ShortName = 'Short Name';
	static Validity = 'Validity';
	static DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove familiarizations \'{0}\',  because data in familiarizations has been modified concurrently by another user!';
	static DeletingPartnerParameterWasSuccessful = 'Partner \'{0}\' removed successfully!';
	static OtherCompanies = 'Other Companies';
	static CourseCredit = 'Course Credit';
	static AddRemainder = 'Add Remainder';
	static FullNameClaimNotFound = 'User claim \'name\' not found!';
	static UserGroupParameterNotFound = 'User group \'{0}\' not found!';
	static DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter = 'Failed to remove folder \'{0}\'! Error: \'{1}\'';
	static PanelSize1 = '1 x 1';
	static DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError = 'Failed to remove file \'{0}\',  because data in the file has been modified concurrently by another user!';
	static Messages = 'Messages';
	static FileParameterIsNotImage = 'File \'{0}\' is not a picture!';
	static DocumentNameIsTooLong = 'Document name \'{0}\' is too long!';
	static EmailNotificationOfChangedPublishStatus = 'Notify When Published State Changes by Email';
	static AllPartners = '\'All Partners\'';
	static SharedReportCompany = 'Shared to company';
	static ScopeUnit = 'Unit';
	static PrintParameterNotFound = 'Print \'{0}\' not found!';
	static DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError = 'Failed to remove user groups \'{0}\', because data in user groups has been modified concurrently by another user!';
	static DeletingDefaultEventHandlerParameterWasSuccessful = 'Default incident handler \'{0}\' removed successfully!';
	static TimeMustBeDefined = 'Time must be defined!';
	static EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Category 2 \'{0}\' is used in {1} incidents and cannot be removed!';
	static RecordUserExcelTabName = 'User Records';
	static Intern = 'Intern';
	static DeletingTaskStatesParameterWasNotSuccessfulErrorParameter = 'Failed to remove task states \'{0}\'! Error: \'{1}\'';
	static CompanyParameterNotFound = 'Company \'{0}\' was not found!';
	static SearchEndDate = 'End Date';
	static WhistleBlowStatisticsInProgressRecords = 'In Progress Reports';
	static RemoveEventUrgencyClass = 'Remove Urgency Class';
	static TaskHandlerParameterNotFound = 'Handler \'{0}\' not found!';
	static PositionErrorUnavailable = 'Location information unavailable.';
	static DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Failed to remove company group \'{0}\'! Error: \'{1}\'';
	static Unit = 'Unit';
	static ChangesMaxAmount = 'Maximum amount of changes';
	static NoAddedEventTypeCs = 'No added categories!';
	static NoAddedEventTypeBs = 'No added categories!';
	static CompanyCodeContainsIllegalCharacters = 'Company code contains characters that are illegal (., &, :, \' \', ö, ä, à, å, Ö, Ä, À tai Å)!';
	static EmploymentEndDate = 'Employment End Date';
	static WhistleBlowStatisticsNonAnonymousRecords = 'Nonanonymous Reports';
	static DoYouReallyWantToRemoveEventTypeCParameter = 'Do you really want to remove category 3 \'{0}\'?';
	static PrintFileName = 'Print_{0:yyyy-MM-dd_HH-mm}.pdf';
	static CopyContentToDocumentContent = 'Copy Content to Document Content';
	static DoYouReallyWantToRemoveTaskPriorityParameter = 'Do you really want to remove task priority \'{0}\'?';
	static EditableDocument = 'Document';
	static GetMore = 'Show More';
	static FieldParameterValueMustBeDefined = 'Value of the field \'{0}\' must be defined!';
	static DeletingAttachmentParameterWasNotSuccessfulConcurrencyError = 'Failed to remove attachment \'{0}\', because the data has been modified concurrently by another user!';
	static LastParameterYears = 'Last {0} Years';
	static MyLastModifications = 'User\'s Latest Modifications';
	static DownloadAttachment = 'Download Selected Attachment';
	static QualificationIsValid = 'Qualification is valid';
	static SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError = 'Failed to save urgency class \'{0}\', because data in the urgency class has been modified concurrently by another user!';
	static Download = 'Download';
	static DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter = 'Failed to remove document \'{0}\'! Error: \'{1}\'';
	static WhistleBlowInformantMessages = 'Informant Messages';
	static TaskStateLocked = 'Locked';
	static Usage = 'Usage';
	static BasicEducation = 'Basic Education';
	static DeletingUserParameterWasNotSuccessfulErrorParameter = 'Failed to remove user account \'{0}\'! Error: \'{1}\'';
	static SavingWhistleBlowCategoryParameterWasSuccessful = 'Whistle blow category \'{0}\' saved successfully!';
	static DoYouReallyWantToRemoveFileParameter = 'Do you really want to remove file \'{0}\'?';
	static MaximumSizeOfSingleAttachment = 'Maximum size of a attachment is {0} MB!';
	static MoveToTrashBin = 'Move to Trash Bin';
	static InstantMessageSettings = 'Instant Message Settings (HoxUp)';
	static ObserverAction = 'Observer Action';
	static AddEventTypesToEvent = 'Add category 1 by tapping the plus button.';
	static MessageModuleProperties = 'Current Topics Properties';
	static CreatingPrint = 'Creating print...';
	static EventApprovalWasNotSuccessfulErrorParameter = 'Failed to approve incident! Error: \'{0}\'';
	static ImageChangeInterval = 'Image Change Interval';
	static RemovePrint = 'Remove Print';
	static AddPartnersParameterWasSuccessful = 'Partners \'{0}\' added successfully!';
	static DocumentsSelected = 'documents selected';
	static DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter = 'Failed to remove qualifications \'{0}\'! Error: \'{1}\'';
	static AddPartnerParameterWasSuccessful = 'Partner \'{0}\' added successfully!';
	static MessageVisibilityPeriodNotStarted = 'Message visibility period not started!';
	static DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages = 'Failed to remove message type \'{0}\', because the message type is used in {1} messages!';
	static RemovePicture = 'Remove Picture';
	static RestoringInstantMessageWasNotSuccessfulConcurrencyError = 'Failed to restore instant message, because data in the instant message has been modified concurrently by another user!';
	static ExternalSystemCode = 'Code in System';
	static Closed = 'Closed';
	static SelectedDocumentTypes = 'Show only selected document types';
	static InstantMessageChannelParameterNotFound = 'Chat \'{0}\' not found!';
	static AllEventHandlersHaveReadMessage = 'All incident handlers have read your comment';
	static StateOpenPlural = 'Open';
	static ValidityStartDate = 'Validity Start Date';
	static SavingDirectoryItemPermissionsNotSuccessfulErrorParameter = 'Failed to save folder permissions! Error: \'{0}\'';
	static SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter = 'Failed to save employment \'{0}\'! Error: \'{1}\'';
	static SelectCategory = 'Select Category';
	static ModifiedBy = 'Modified By';
	static Expired = 'Expired';
	static ViewLogLine = 'Show Log Line';
	static DoYouReallyWantToEmailMessageParameter = 'Do you really want to send message \'{0}\' by email? Message can be sent by email only once.';
	static SelectEventTypeC = 'Select Category 3';
	static FolderNameConstainsIllegalCharacters = 'Folder name contains illegal characters (<, >, :, ", /, \, |, ? tai *) or it ends with illegal characters (., \' \')!';
	static AllCompanies = 'All Companies';
	static Today = 'Today';
	static PrintOrientation = 'Print Orientation';
	static RecordBasicEducationCodeMustBeDefined = 'Basic education name must be defined!';
	static SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError = 'Failed to save file \'{0}\', because data in the file has been modified concurrently by another user!';
	static DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError = 'Failed to remove participants \'{0}\', because data has been modified concurrently by another user!';
	static ApplicationTempPathIsNotSpecified = 'Application temp-folder is not specified!';
	static DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError = 'Failed to remove category 2 \'{0}\', because data in the category 2 has been modified concurrently by another user!';
	static OpenWhistleBlowInstruction = 'Enter the password that you received when the whistle blow report was submitted.';
	static FolderCannotBeMovedToItsSubFolder = 'Folder cannot be moved in its own subfolder!';
	static DefaultTaskTypes = 'Default Task Types';
	static SaveDraft = 'Save Draft';
	static DocumentModulePropertiesSaveWasSuccessful = 'Document settings saved successfully!';
	static WhistleBlowReportHasBeenSavedBottomText = 'You must store the password before you can close this page.';
	static AddedComment = 'Added Comment';
	static AddFurtherEducationToMultipleUserRecords = 'Add Further Education to Multiple User Records';
	static WhistleBlowSourceMail = 'Mail';
	static SavingUserParametersWasNotSuccessfulConcurrencyError = 'Failed to save user options\', because data in the user options has been modified concurrently by another user!';
	static PositionErrorPermissionDenied = 'Application does not have permission to read location.';
	static CkickToChangeTheIcon = 'Click to change the icon';
	static InstantMessageChannelArchivingTypeKeepMessages = 'Preserve Archived Chat and Messages';
	static CopyingFilesAndDocumentsToFolderParameterWasSuccessful = 'Files/documents copied into folder \'{0}\' successfully!';
	static CopyOrMoveFolder = 'Copy or Move Folder';
	static NoModifications = 'No modifications';
	static NormalMessage = 'Normal message';
	static PermissionsToCreateEventsToEventPartner = 'Permission to Create Incidents to Partner';
	static AddEvent = 'Add Incident';
	static TrainingEndDateCannotPrecedeTrainingStartDate = 'Training end date cannot precede training start date!';
	static UserAccountNotConnected = 'User Account Not Connected';
	static PrintIsEmpty = 'Print is empty!';
	static RemoveLogisticVoucherCustomerPaymentSetting = 'Remove Payment Setting';
	static WhistleBlowStateArchived = 'Archived';
	static ShowUserGroups = 'Show User Groups';
	static UserLastNameMustBeDefined = 'User surname must be defined!';
	static SearchStartDate = 'Start Date';
	static RemoveLogisticVoucherCompanyPaymentSetting = 'Remove Payment Setting';
	static FilterIsActive = 'Filter is Active';
	static ExternalSystemUserCodeClaimNotFound = 'User claim \'externalSystemUserCode\' not found!';
	static AddPhoto = 'Add Photo';
	static AddSalaryRowToMultipleUserRecords = 'Add Salary Row to Multiple User Records';
	static DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter = 'Failed to remove administrators \'{0}\'! Error: \'{1}\'';
	static AddingRecordUserSalaryRowsWasSuccessful = 'Salary rows added successfully!';
	static Voluntary = 'Voluntary';
	static FolderParameterAlreadyExistsInFileSystem = 'Folder \'{0}\' already exists in the filesystem!';
	static ValidityEndDate = 'Validity End Date';
	static Partners = 'Partners';
	static Characters = 'Characters';
	static DeletingFileParameterWasNotSuccessfulErrorParameter = 'Failed to remove file \'{0}\' in filesystem! Error: \'{1}\'';
	static EmptyDocument = 'Empty document';
	static TimeSpanMonth = 'Month';
	static AddSharedDocumentTemplate = 'Add Shared Document Template';
	static AddMessage = 'Add Message';
	static RemoveTask = 'Remove Task';
	static EditInstantMessageChannel = 'Edit Chat';
	static SavingEventParameterWasSuccessful = 'Incident \'{0}\' saved successfully!';
	static AddUserGroupParameterWasNotSuccessfulConcurrencyError = 'Failed to add user group \'{0}\', because the data has been modified concurrently by another user!';
	static UserRecordStatisticsExpiredFamiliarizations = 'Expired Familiarizations';
	static ValidityEndDateEnd = 'Validity End Date - End';
	static SavingCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Failed to save company group \'{0}\'! Error: \'{1}\'';
	static TasksExcelByTasks = 'Tasks - Excel - Filtered by Tasks';
	static FilesParameterAreEmpty = 'Files \'{0}\' are empty!';
	static EventTypeBs = 'Category 2';
	static FolderParameterAlreadyExistsInTargetFolder = 'Folder \'{0}\' already exists in the destination folder!';
	static LogoSquare = 'Small Logo';
	static DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError = 'Failed to remove basic education \'{0}\',  because data in the basic education has been modified concurrently by another user!';
	static SearchEvents = 'Search Incidents';
	static WhistleBlowArchivingTypeChangeStateAndRemoveInformantData = 'Change whistle blow report state to \'Archived\' and remove informant  data';
	static DoYouReallyWantToRemoveReportParameter = 'Do you really want to remove report \'{0}\'?';
	static Admin = 'Administrator';
	static TaskRemainderParameterNotFound = 'Remainder \'{0}\' not found!';
	static Invoicing = 'Invoicing';
	static AddCompanyGroup = 'Add Company Group';
	static WebPushError = 'Web Push Error';
	static TrainingStartDateStart = 'Training Start Date - Start';
	static Action = 'Action';
	static UserCodeMustBeValid = 'Username must be at least 8 characters long and it cannot contain whitespaces.';
	static UserCannotRecieveEmailNotifications = 'User will not receive email notifications';
	static LogisticVoucherCustomers = 'Customers';
	static DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter = 'Failed to remove basic education \'{0}\'! Error: \'{1}\'';
	static InstantMessages = 'Instant Messages';
	static DeletingRecordUserEmploymentParameterWasSuccessful = 'Employment \'{0}\' removed successfully!';
	static LogIn = 'Log In';
	static EventMessageConfirmation = 'Do you want to save new comment?';
	static SavingMessageSendEmailParameterWasSuccessful = 'Message email log \'{0}\' saved successfully!';
	static EditKeyword = 'Edit Keyword';
	static DeletingUserGroupParameterWasSuccessful = 'User group \'{0}\' removed successfully!';
	static SaveFilter = 'Save Filter';
	static EditRecordUser = 'Edit User Record';
	static DeletingFolderParameterWasNotSuccessfulErrorParameter = 'Failed to remove folder \'{0}\' in filesystem! Error: \'{1}\'';
	static AttachmentImageSize = 'Attachment Image Thumbnail Size';
	static UpdateCompanyExternalSystemsWasSuccessful = 'Company linked to external systems successfully!';
	static FileUploadWasSuccessful = 'Files uploaded successfully!';
	static Hours = 'hours';
	static EditDefaultTaskState = 'Edit Default Task State';
	static DoYouReallyWantToRemoveRecordFamiliarizationParameter = 'Do you really want to remove familiarization \'{0}\'?';
	static Invited = 'Invited';
	static ThisWeek = 'This Week';
	static KeywordCodeMustBeDefined = 'Keyword code must be defined!';
	static RenewPersonalization = 'Personalization';
	static DeletingInstantMessageChannelWasSuccessful = 'Chat removed successfully!';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError = 'Failed to copy folder \'{0}\' into folder \'{1}\', because data in the folder has been modified concurrently by another user!';
	static SharedDocumentTemplates = 'Shared Document Templates';
	static SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter = 'Failed to publish document \'{0}\'! Error: \'{1}\'';
	static SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError = 'Failed to save transferred property \'{0}\', because data in the transferred property has been modified concurrently by another user!';
	static Reject = 'Reject';
	static UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted = 'User group \'{0}\' is used in {1} users and cannot be removed!';
	static TaskParameterNotFound = 'Task \'{0}\' not found!';
	static PasswordMustContainDigitOrSpecialCharacter = 'Password must contain number or special character!';
	static ExternalIntraSystemCompanyTokenMustBeDefined = 'Company token in external eSalkku system must be defined!';
	static StudyWeek = 'Study Week';
	static Removed = 'Removed';
	static Unread = 'Unread';
	static HoursPerTrainingDay = 'Hours per Training Day';
	static DirectoryItemDocumentParameterNotFound = 'File \'{0}\' not fond!';
	static LogisticVoucher = 'Transport Invoicing';
	static PasteDefaultHandlers = 'Paste Default Handlers';
	static WhistleBlowResolutionCanceled = 'Canceled';
	static NewMenu = 'New Menu';
	static Move = 'Move';
	static TaskPriority = 'Priority';
	static AddUserGroupParameterWasSuccessful = 'User group \'{0}\' added successfully!';
	static CompanyThatIsInUseCannotBeDeleted = 'Company that is in the state \'In use\', cannot be removed!';
	static SaveEventHandlerConfirmation = 'Do you want to save the incident?';
	static WebPageStyle = 'Page Style';
	static Last120Days = 'Last 120 Days';
	static SavingDefaultHandlersWasSuccessful = 'Default handlers saved successfully!';
	static ExternalSystemCompanyCodeMustBeDefined = 'Company code in external system must be defined!';
	static DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError = 'Failed to remove default incident handler \'{0}\', because data in the default incident handler has been modified concurrently by another user!';
	static EditLogisticVoucherCustomerPaymentSetting = 'Edit Payment Setting';
	static EditRow = 'Edit Row';
	static PanelSize3 = '1 x 2';
	static UserSessionHasBeenExpired = 'User session has been expired!';
	static LogEventType = 'Type';
	static SavingOptionParameterWasSuccessful = 'Option \'{0}\' saved successfully!';
	static MovingFolderParameterWasNotSuccessfulErrorParameter = 'Failed to move folder \'{0}\' into folder \'{1}\' in filesystem! Error: \'{2}\'';
	static SavingReadReceiptWasNotSuccessfulErrorParameter = 'Failed to save read receipt! Error: \'{0}\'';
	static DoYouReallyWantToExitInstantMessageChannel = 'Do you really want to exit the chat?';
	static GroupByEducation = 'Group by Education';
	static UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError = 'Failed to link company to external systems, because the data has been modified concurrently by another user!';
	static Source = 'Source';
	static TaskType = 'Task type';
	static EventTypeB = 'Category 2';
	static ReadingDatabaseParameter = 'Reading database \'{0}\'...';
	static AddFileFilter = 'Add File Filter';
	static AddEventUrgencyClass = 'Add Urgency Class';
	static AddUserParameterWasNotSuccessfulErrorParameter = 'Failed to add user account \'{0}\'! Error: \'{1}\'';
	static Filters = 'Filters';
	static TransferToChannel = 'Move to chat';
	static DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError = 'Failed to remove further education \'{0}\',  because data in the further education has been modified concurrently by another user!';
	static WhistleBlowStatisticsRecordCount = 'Active Reports';
	static Choose = 'Choose';
	static RemoveCompany = 'Remove Company';
	static SavingRecordFamiliarizationParameterWasSuccessful = 'Familiarization \'{0}\' saved successfully!';
	static UserFilterNoUserGroup = 'No User Group';
	static SavingMessageSendPushParameterWasSuccessful = 'Message push notification log \'{0}\' saved successfully!';
	static UserIsWhistleBlowChannelAdminAndCannotBeDeleted = 'User is whistle blow channel administrator and cannot be deleted!';
	static TrainingStartDate = 'Training Start Date';
	static EmptyTaskTrashBin = 'Empty Task Trash Bin';
	static Familiarizations = 'Familiarizations';
	static SavingReportWasNotSuccessfulConcurrencyError = 'Failed to save report, because data in the report has been modified concurrently by another user!';
	static OpenWhistleBlow = 'Open Whistle Blow Report';
	static Last30Days = 'Last 30 Days';
	static AccountNumberTkbInCredit = 'Sales Account';
	static UserGroupNameIsAlreadyInUse = 'User group name is already in use!';
	static UserGroupNameMustBeDefined = 'User group name must be defined!';
	static DeletingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError = 'Failed to remove chat \'{0}\', because data in the instant message channel has been modified concurrently by another user!';
	static AccessRightRead = 'Read permission';
}
// ReSharper restore InconsistentNaming
