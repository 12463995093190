import { Box, Button, CardActions, CardHeader, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Base } from "ui/Scripts/source/framework/base";
import { ConfirmationDialogResult, EnumHelper, WhistleBlowState } from "ui/Scripts/source/models/enums";
import { Translations } from "ui/Scripts/source/models/translations";
import { IWhistleBlowInformantMessage, WhistleBlowInformantMessage } from "ui/Scripts/source/models/whistleBlow/whistleBlowInformantMessage";
import { ReportStatusChip } from "../../../components/framework/ReportStatusChip";
import { AppUtils } from "../../../framework/appUtils";
import { AppDispatch, RootState } from "../../../models/store";
import { whistleBlowCleared } from "../../../models/store/features/whistleBlow";
import { WhistleBlowAttachmentList } from "../WhistleBlowAttachmentList";
import { WhistleBlowMessageList } from "../WhistleBlowMessageList";
import * as Styled from "./OpenWhistleBlow.styles";
import { ErrorView } from "source/components/framework/ErrorView";

// OpenWhistleBlow
interface IOpenWhistleBlowState {
    messages: IWhistleBlowInformantMessage[];
    cameraVisible: boolean;
    draftMessageIsEmpty: boolean;
}

export const OpenWhistleBlow = () => {
    const { pageName } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const frontPage = useSelector((state: RootState) => state.front.frontPage);
    // const dataId = useSelector((state: RootState) => state.whistleBlow.dataId);
    const whistleBlow = useSelector((state: RootState) => state.whistleBlow.whistleBlow);

    const [state, setState] = useState<IOpenWhistleBlowState>({
        messages: whistleBlow ? whistleBlow.messages.slice(0) : [],
        cameraVisible: false,
        draftMessageIsEmpty: true
    });

    // const messageHash = (whistleBlow?.messages ?? []).map(i => i.id).join("#");

    // useEffect(() => {
    //     // const props = this.props;
    //     // const prevHash = (prevProps.whistleBlow?.messages ?? []).map(i => i.id).join("#");
    //     // const hash = (whistleBlow?.messages ?? []).map(i => i.id).join("#");
    //     // if (prevHash === hash) return;
    //     setState((oldState) => ({
    //         ...oldState,
    //         messages: whistleBlow ? whistleBlow.messages.slice(0) : []
    //     }));
    // }, [messageHash]);

    const closeWiew = () => {
        dispatch(whistleBlowCleared());
        navigate("/" + pageName);
    };

    const handleWhistleBlowMessageSaved = (item: IWhistleBlowInformantMessage) => {
        // const state = this.state;
        const messages = state.messages.filter(i => i.id !== item.id);
        messages.push(item);
        setState((oldState) => ({
            ...oldState,
            messages: WhistleBlowInformantMessage.sortWhistleBlowInformantMessages(messages)
        }));
    };

    const handleWhistleBlowMessageIsEmpty = (draftMessageIsEmpty: boolean) => {
        setState((oldState) => ({
            ...oldState,
            draftMessageIsEmpty,
        }));
    };

    const handleCloseWhistleBlowReport = async () => {
        if (!state.draftMessageIsEmpty && await AppUtils.showConfirmationDialog(Translations.WhistleBlowMessageHasNotBeenSentCloseConfirmation) !== ConfirmationDialogResult.Yes) {
            return;
        }
        closeWiew();
    };

    if (!whistleBlow) {
        return (
            <ErrorView title={Translations.WhistleBlowWasNotFound} subtitle={Translations.WhistleBlowWasNotFoundExplanation}>
                <Button variant="outlined" title={Translations.Close} onClick={handleCloseWhistleBlowReport}>
                    {Translations.Close}
                </Button>
            </ErrorView>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <Styled.Card elevation={3}>
                    <CardHeader title={Translations.WhistleBlowInformation} titleTypographyProps={{ variant: "body1", color: "#616D7C" }}></CardHeader>
                    <Styled.CardContent>
                        <Grid container rowSpacing="1.5rem">
                            <Grid item xs={12}>
                                <Box display="flex" flexWrap="wrap" gap={4}>
                                    <Box display="flex" flexDirection="column">
                                        <Styled.Label variant="caption">{Translations.State}</Styled.Label>
                                        <ReportStatusChip status={whistleBlow.state} label={EnumHelper.WhistleBlowStateToStr(whistleBlow.state)} />
                                    </Box>
                                    {EnumHelper.isEqual(whistleBlow.state, WhistleBlowState.Resolved) && (
                                        <Box display="flex" flexDirection="column">
                                            <Styled.Label variant="caption">{Translations.Title}</Styled.Label>
                                            <Typography variant="body2">{whistleBlow.resolutionStr}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            {EnumHelper.isEqual(whistleBlow.state, WhistleBlowState.Resolved) && (
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column">
                                        <Styled.Label variant="caption">{Translations.ResolutionDescription}</Styled.Label>
                                        <Typography variant="body2">{whistleBlow.resolutionComment}</Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box display="flex" flexWrap="wrap" gap={4}>
                                    <Box display="flex" flexDirection="column">
                                        <Styled.Label variant="caption">{Translations.WhistleBlowCreateTime}</Styled.Label>
                                        <Box display="flex" alignItems="end">
                                            <Styled.CalendarIcon fontSize="small" />
                                            <Typography variant="body2">{Base.timeToDateTimeStr(whistleBlow.createDate).split(" ")[0]}</Typography>
                                            <Styled.ClockIcon fontSize="small" />
                                            <Typography variant="body2">{Base.timeToDateTimeStr(whistleBlow.createDate).split(" ")[1]}</Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column">
                                        <Styled.Label variant="caption">{Translations.WhistleBlowInformantDateAndTime}</Styled.Label>
                                        <Box display="flex" alignItems="end">
                                            <Styled.CalendarIcon fontSize="small" />
                                            <Typography variant="body2">{Base.timeToDateTimeStr(whistleBlow.date).split(" ")[0]}</Typography>
                                            <Styled.ClockIcon fontSize="small" />
                                            <Typography variant="body2">{Base.timeToDateTimeStr(whistleBlow.date).split(" ")[1]}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="column">
                                    <Styled.Label variant="caption">{Translations.WhistleBlowInformantTitle}</Styled.Label>
                                    <Typography variant="body2">{whistleBlow.title}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="column">
                                    <Styled.Label variant="caption">{Translations.WhistleBlowInformantDescription}</Styled.Label>
                                    <Styled.DescriptionText variant="body2">{whistleBlow.description}</Styled.DescriptionText>
                                </Box>
                            </Grid>
                            {!whistleBlow.anonymous && (
                                <Grid item xs={12}>
                                    <Box display="flex" flexWrap="wrap" gap={4}>
                                        <Box display="flex" flexDirection="column">
                                            <Styled.Label variant="caption">{Translations.Name}</Styled.Label>
                                            <Typography variant="body2">{whistleBlow.informantName}</Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="column">
                                            <Styled.Label variant="caption">{Translations.EmailAddress}</Styled.Label>
                                            <Typography variant="body2">{whistleBlow.informantEmail}</Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="column">
                                            <Styled.Label variant="caption">{Translations.Phone}</Styled.Label>
                                            <Typography variant="body2">{whistleBlow.informantPhone}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        <WhistleBlowAttachmentList
                            isReadOnly={true}
                            companyId={frontPage.companyId}
                            whistleBlowId={whistleBlow.id}
                            maxAttachmentsTotalSize={frontPage.maxAttachmentsTotalSize}
                            attachments={whistleBlow.attachments}
                            onShowCamera={null}
                            onRemoved={null}
                            onAdded={null}
                        />
                    </Styled.CardContent>
                    <CardActions>
                        <Button
                            variant="outlined"
                            title={Translations.Close}
                            onClick={() => navigate("/" + pageName)}
                        >{Translations.Close}</Button>
                    </CardActions>
                </Styled.Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Styled.Card elevation={3}>
                    <CardHeader title={Translations.Messages} titleTypographyProps={{ variant: "body1", color: "#616D7C" }} />
                    <WhistleBlowMessageList
                        classes="form-group"
                        companyId={frontPage.companyId}
                        companyName={frontPage?.companyName ?? ""}
                        whistleBlowId={whistleBlow.id}
                        maxAttachmentsTotalSize={frontPage.maxAttachmentsTotalSize}
                        messages={state.messages}
                        onShowCamera={(value) => setState((oldState) => ({ ...state, cameraVisible: value }))}
                        onWhistleBlowMessageSaved={handleWhistleBlowMessageSaved}
                        onWhistleBlowMessageIsEmpty={handleWhistleBlowMessageIsEmpty}
                    />
                </Styled.Card>
            </Grid>
        </Grid>
    );
};