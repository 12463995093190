/* global JSX */
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { Box, IconButton, List, Typography } from "@mui/material";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { Base } from "ui/Scripts/source/framework/base";
import { IFileAttachment } from "ui/Scripts/source/models/common/fileAttachment";
import { Translations } from "ui/Scripts/source/models/translations";
import { AttachmentIcon } from "../framework/AttachmentIcon";
import { FormField } from "../framework/FormField";
import * as Styled from "./AttachmentList.styles";

//AttachmentImage
//export interface IAttachmentImageProp {
//    lateThreshold: number;
//    attachment: IFileAttachment;
//    selectedId: string;
//    onClick: (id: string) => void;
//}

//export class AttachmentImage extends React.Component<IAttachmentImageProp, {}> {
//    render() {
//        const timeToleranceInJsTicks = 500;
//        const props = this.props;
//        const lateThreshold = props.lateThreshold;
//        const attachment = props.attachment;
//        const isLate = !Base.isNullOrUndefined(lateThreshold) && attachment.modifyDate > (lateThreshold + timeToleranceInJsTicks);
//        return (
//            <div className={"attachmentImage" + (isLate ? " late" : "") + (attachment.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(attachment.id); }} title={attachment.name + " (" + Base.intToFileSizeStr(attachment.size, false) + ", " + Base.timeToDateStr(attachment.modifyDate) + ")"}>
//                <img src={attachment.thumbnail} alt={attachment.name}/>
//            </div>
//        );
//    }
//}

//AttachmentListLine
export interface IAttachmentListLineProp {
    lateThreshold: number;
    attachment: IFileAttachment;
    selectedId?: string;
    // isReadOnly?: boolean;
    onDownload: (id: string) => void;
    onRemove: (id: string) => void;
}

export const AttachmentListLine = ({ attachment, lateThreshold, onDownload, onRemove }: IAttachmentListLineProp) => {
    const timeToleranceInJsTicks = 500;
    const isLate = !Base.isNullOrUndefined(lateThreshold) && attachment.modifyDate > (lateThreshold + timeToleranceInJsTicks);
    return (
        <Styled.AttachmentListItem key={attachment.id} title={attachment.name + " (" + Base.intToFileSizeStr(attachment.size, false) + ", " + Base.timeToDateStr(attachment.modifyDate) + ")"}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center">
                    <AttachmentIcon fileType={attachment.type} color="#E9F0FA" strokeColor="#848688" />
                    <Box display="flex" flexWrap="wrap" alignItems="baseline" marginLeft="4px">
                        <Typography variant="subtitle2">{attachment.name}</Typography>
                        <Styled.FileInfo variant="body2">{Base.intToFileSizeStr(attachment.size, false)}</Styled.FileInfo>
                    </Box>
                    {isLate &&
                        <Styled.FileInfo variant="body2">{Base.timeToDateStr(attachment.modifyDate)}</Styled.FileInfo>
                    }
                </Box>
                <Box display="flex">
                    <IconButton onClick={() => onDownload(attachment.id)}><FileDownloadOutlinedIcon /></IconButton>
                    <IconButton onClick={() => onRemove(attachment.id)} color="error"><DeleteForeverOutlinedIcon /></IconButton>
                </Box>
            </Box>
        </Styled.AttachmentListItem>
    );
};

//AttachmentList
export interface IAttachmentListProp {
    title: string | JSX.Element;
    accept?: string;
    classes?: string;
    lateThreshold?: number;
    attachments: IFileAttachment[];
    isReadOnly?: boolean;
    canShowCamera?: boolean;
    onRemoveAttachment: (id: string) => void;
    onDownloadAttachment: (id: string) => void;
    onAddAttachments: (fileList: FileList) => void;
    onAddPhoto?: () => void;
}

export const AttachmentList = ({ attachments, title, accept, canShowCamera, classes, isReadOnly, lateThreshold, onAddAttachments, onDownloadAttachment, onRemoveAttachment, onAddPhoto }: IAttachmentListProp) => {
    const fileUploadRef = React.useRef<HTMLInputElement>(null);

    const onDrop = React.useCallback(acceptedFiles => {
        onAddAttachments(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ noClick: true, onDrop });

    const handleAddFilesClick = (e) => {
        fileUploadRef.current.click();
    };

    const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length < 1) return;
        onAddAttachments(e.target.files);
    };

    const handleAddPhotoClick = () => {
        onAddPhoto();
    };

    const handleDownload = (id: string) => {
        if (!id) return;
        onDownloadAttachment(id);
    };

    const handleRemove = (id: string) => {
        if (!id) return;
        onRemoveAttachment(id);
    };

    return (
        <FormField title={title as string}>
            {!isReadOnly && (
                <Styled.FileDropzone {...getRootProps()}>
                    <input {...getInputProps()} ref={fileUploadRef} type="file" name="attachments" className="file" style={{ display: "none" }} multiple={true} accept={accept} onChange={handleAddFiles} />
                    <Styled.Title variant="h6">{canShowCamera ? Translations.AddFileOrTakePicture : Translations.AddFile}</Styled.Title>
                    <Styled.Subtitle variant="body2">{String.format(Translations.ImageOrPdfUpToParameterMb, "64")}</Styled.Subtitle>
                    <Box display="flex">
                        <Styled.Button onClick={handleAddFilesClick} startIcon={<FileUploadOutlinedIcon />}>{Translations.BrowseFiles}</Styled.Button>
                        {canShowCamera
                            ? (
                                <Box marginLeft="1rem">
                                    <Styled.Button onClick={handleAddPhotoClick} startIcon={<PhotoCameraOutlinedIcon />}>{Translations.TakePicture}</Styled.Button>
                                </Box>
                            )
                            : null}
                    </Box>
                </Styled.FileDropzone>
            )}
            <List>
                {attachments.length > 0
                    ? attachments.map((attachment) =>
                        <AttachmentListLine
                            key={attachment.id}
                            lateThreshold={lateThreshold}
                            attachment={attachment}
                            // isReadOnly={isReadOnly}
                            // selectedId={state.selectedId}
                            onDownload={handleDownload}
                            onRemove={handleRemove}
                        />
                    )
                    : ""
                }
            </List>
        </FormField>
    );
};