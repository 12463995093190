import { Translations_en_US } from "./translations.en-US";
import { Translations_sv_FI } from "./translations.sv-FI";

// ReSharper disable InconsistentNaming
export class Translations_fi {
	static DeletingRecordUserParameterWasSuccessful = 'Henkilöstökortin \'{0}\' poisto onnistui!';
	static SavingEditableDocumentParameterApprovalWasSuccessful = 'Dokumentin \'{0}\' hyväksyntä onnistui!';
	static DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Yritysryhmän \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static CompanyNumber = 'Yritysnumero';
	static SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError = 'Dokumentin \'{0}\' tallennus epäonnistui, koska dokumentin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments = 'Haluatko todella poistaa avainsanan \'{0}\'? Avainsana on {1} dokumentin/tiedoston käytössä.';
	static UserRecords = 'Henkilöstö';
	static MobileAppInUse = 'Mobiilisovellus käytössä';
	static SendingPushNotificationsToParameter = 'Lähettää push-viestejä käyttäjälle \'{0}\'...';
	static Competency = 'Pätevyys';
	static DoYouReallyWantToClearLog = 'Haluatko todella tyhjentää lokin?';
	static And = 'ja';
	static UpdatingGroupCodeWasNotSuccessfulErrorParameter = 'Ryhmätunnuksen päivitys epäonnistui! Virhe: \'{0}\'';
	static End = 'Loppu';
	static UserReports = 'Henkilöstöraportit';
	static GroupByFamiliarization = 'Perehdytyksittäin';
	static NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded = 'Uusi henkilöstökortti täytyy tallentaan ennen liitteiden lisäämistä!';
	static DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError = 'Kiireellisyysluokan \'{0}\' poisto epäonnistui, koska kiireellisyysluokan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ShowOnMap = 'Näytä kartalla';
	static CloseSystem = 'Sulje järjestelmä';
	static SaveReport = 'Tallenna raportti';
	static AddToFavorites = 'Lisää suosikkeihin';
	static ClickToChangeTheLogo = 'Klikkaa vaihtaaksesi logoa';
	static DoYouReallyWantToRemoveEventPartnerParameter = 'Haluatko todella poistaa havaintokumppanin \'{0}\'?';
	static RemoveTaskPriority = 'Poista tehtävän prioriteetti';
	static ANew = 'Uusi';
	static FamiliarizationTrainer = 'Perehdyttäjä';
	static NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded = 'Uusi henkilöstökortti täytyy tallentaan ennen pätevyysvaatimusten lisäämistä!';
	static StartDate = 'Näkyvissä alkaen';
	static ResetDocumentVersion = 'Alusta dokumentin laatija- ja versiotiedot';
	static TogglePreviewPanel = 'Näytä/piilota esikatselu';
	static WhistleBlowSeverityLow = 'Vähäinen';
	static RemoveDefaultFurtherEducation = 'Poista oletusjatkokoulutus';
	static RemoveDefaultTaskPriority = 'Poista tehtävän oletusprioriteetti';
	static TasksFound = 'Tehtäviä löytyi';
	static SavingWhistleBlowMessageWasNotSuccessfulErrorParameter = 'Viestin tallennus epäonnistui! Virhe: \'{0}\'';
	static FirstName = 'Etunimi';
	static DocumentApproved = 'Hyväksyjä';
	static RecordUsersFound = 'Henkilöstökortteja löytyi';
	static UserFirstNameMustBeDefined = 'Käyttäjän etunimi täytyy määritellä!';
	static RecordUserReport = 'Henkilöstökorttiraportti';
	static AddLogisticVoucherTransportCompany = 'Lisää kuljetusyritys';
	static RemoveTarget = 'Poista kohde';
	static Comment = 'Kommentti';
	static EventReport = 'Havaintoraportti';
	static DeletingRecordBasicEducationsParameterWasSuccessful = 'Peruskoulutusten \'{0}\' poisto onnistui!';
	static EventApprovalWasSuccessful = 'Havainnon hyväksyminen onnistui!';
	static Visibility = 'Näkyvyys';
	static LockedEventParameterCannotBeDeleted = 'Lukittua havaintoa \'{0}\' ei voi poistaa!';
	static WhistleBlowStatisticsResolvedRecords = 'Ratkaistuja ilmoituksia';
	static AccessRightReadTasks = 'Tehtävien suoritus';
	static Copy = 'Kopioi';
	static UserGroupCodeMustBeDefined = 'Käyttäjäryhmän tunnus täytyy määritellä!';
	static WhistleBlowUrl = 'Ilmoituskanavan web-osoite';
	static Favorites = 'Suosikit';
	static SavingRecordUserQualificationParameterWasSuccessful = 'Pätevyysvaatimuksen \'{0}\' tallennus onnistui!';
	static NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded = 'Uusi keskustelu täytyy tallentaa ennen keskustelijoiden lisäämistä!';
	static AddingRecordUserFamiliarizationsWasSuccessful = 'Perehdytysten lisäys onnistui!';
	static DoYouReallyWantToRemoveSelectedTaskTypes = 'Haluatko todella poistaa valitut tehtätyypit?';
	static AccountIBANNumber = 'IBAN-tilinumero';
	static PersonalIdentityCode = 'Henkilötunnus';
	static FileStorageQuotaGB = 'Tiedostojen tallennustilan koko (GB)';
	static Archived = 'Arkistoitu';
	static LockedTaskParameterCannotBeDeleted = 'Lukittua tehtävää \'{0}\' ei voi poistaa!';
	static SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError = 'Ilmoitusluokan tallennus \'{0}\' epäonnistui, koska ilmoitusluokan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static TaskHandlers = 'Käsittelijät (näkyvyys)';
	static AddEventTypeBsToEvent = 'Lisää tarkenne 2 plus-painikkeesta.';
	static RemoveRecordUser = 'Poista henkilöstökortti';
	static DefaultEventUrgencyClassParameterCannotBeDeleted = 'Oletuskiireellisyysluokkaa ei voi poistaa!';
	static Saved = 'Tallennettu';
	static SavingUnitPermissionsWasNotSuccessfulConcurrencyError = 'Käyttöoikeuksien tallennus yksiköille epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SaveReportAs = 'Tallenna raportti nimellä';
	static OK = 'Ok';
	static Rows = 'riviä';
	static AddUserGroupParameterWasNotSuccessfulErrorParameter = 'Käyttäjäryhmän \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static MovedToTrashBin = 'Siirretty roskakoriin';
	static AllUsersOfTheUnit = 'Kaikki yksikön käyttäjät';
	static ChangedEventUnit = 'Muuttanut havainnon yksikköä';
	static SavingApplicationPermissionsWasNotSuccessfulErrorParameter = 'Asetusten tallennus epäonnistui! Virhe: \'{0}\'';
	static PanelVisible = 'Paneli näkyvissä';
	static Effectiveness = 'Vaikuttavuus';
	static AddChildEventTypeB = 'Lisää alitarkenne 2';
	static AddNew = 'Lisää uusi';
	static AdditionalFunctions = 'Lisätoiminnot';
	static DeletingUserGroupsParameterWasSuccessful = 'Käyttäjäryhmien \'{0}\' poisto onnistui!';
	static MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' siirtäminen kansioon \'{1}\' epäonnistui! Virhe: \'{2}\'';
	static SelectUser = 'Valitse käyttäjätili';
	static WhistleBlowStatisticsAnonymousRecords = 'Anonyymejä ilmoituksia';
	static OldPasswordIsIncorrect = 'Vanha salasana on virheellinen!';
	static StandardFiltersAndReports = 'Vakiosuodattimet ja -raportit';
	static ClassParameterHasNotBeenInitialized = 'Luokkaa \'{0}\' ei ole alustettu!';
	static Permissions = 'Käyttöoikeudet';
	static NoEventsToShow = 'Ei kirjattuja havaintoja!';
	static RemoveRow = 'Poista rivi';
	static ZipFileParameterNotFound = 'Zip-tiedostoa \'{0}\' ei löytynyt!';
	static EventIsApprovedByUser = 'Käyttäjä on hyväksynyt havainnon';
	static DocumentNameConstainsIllegalCharacters = 'Dokumentin nimi sisältää kiellettyjä merkkejä (<, >, :, ", /, \, |, ? tai *) tai päättyy kiellettyyn merkkiin (., \' \')!';
	static WhistleBlowStatisticsReceivedRecords = 'Vastaanotettuja ilmoituksia';
	static SavingEventTypeBsWasSuccessful = 'Tarkenteen 2 tallennus onnistui!';
	static AddImageFromClipboard = 'Lisää kuva leikepöydältä';
	static DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError = 'Havaintokumppanin \'{0}\' poisto epäonnistui, koska kumppanin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EditEventType = 'Muokkaa tarkennetta 1';
	static DeletingEventTypeParameterWasNotSuccessfulErrorParameter = 'Tarkenteen 1 \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static AddFamiliarizations = 'Lisää perehdytykset';
	static SelectFurtherEducation = 'Valitse jatkokoulutus';
	static DeletingWhistleBlowCategoriesParameterWasSuccessful = 'Ilmoitusluokkien \'{0}\' poisto onnistui!';
	static MessageVisibilityPeriodNotActive = 'Viestin näkyvyys ei ole voimassa!';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful = 'Kansion \'{0}\' kopioiminen kansioon \'{1}\' onnistui!';
	static EditPermission = 'Muokkaa käyttöoikeutta';
	static RemovingWhistleBlowHandlersWasSuccessful = 'Käsittelijöiden poisto onnistui!';
	static AddPrint = 'Lisää tuloste';
	static RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError = 'Tehtävän \'{0}\' palauttaminen roskakorista epäonnistui, koska tehtävän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static PaymentSettings = 'Laskutusasetukset';
	static LockedTaskParameterCannotBeMovedToTrashBin = 'Lukittua tehtävää \'{0}\' ei voi siirtää roskakoriin!';
	static ChooseHowYouWantToReport = 'Valitse raportoinin tapa';
	static CompanyInstantMessageChannels = 'Yrityksen keskustelut';
	static DeletingUsersParameterWasNotSuccessfulConcurrencyError = 'Käyttäjätilien \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddEventType = 'Lisää tarkenne 1';
	static Day = 'päivä';
	static DoYouReallyWantToRemoveDocumentParameter = 'Haluatko todella poistaa dokumentin/tiedoston \'{0}\'?';
	static EditFileFilter = 'Muokkaa tiedostosuodatinta';
	static DeletingFileParameterWasNotSuccessfulErrorParameter = 'Tiedoston \'{0}\' poisto tiedostojärjestelmästä epäonnistui! Virhe: \'{1}\'';
	static UserEndDate = 'Voimassaoloaika päättyy';
	static SavingOneTimePasswordParameterWasSuccessful = 'Kertakäyttöisen salasanan tallennus onnistui!';
	static NewVersionAvailable = 'Uusi versio sovelluksesta saatavilla! Sovellusta ei voi käyttää ennen kuin sovellus on päivitetty.';
	static DeletingUserGroupParameterWasNotSuccessfulConcurrencyError = 'Käyttäjäryhmän \'{0}\' poisto epäonnistui, koska käyttäjäryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static NotRepeated = 'Ei toistuva';
	static ReplyToInstantMessage = 'Vastaa viestiin';
	static IgnoreFilePatterns = 'Tiedostojen piilottamisessa käytettävä like-ehto';
	static CopyPermissionToAllSameLevelFolders = 'Kopioi käyttöoikeus kaikkiin saman tason kansioihin';
	static SelectState = 'Valitse tila';
	static UserFilterActive = 'Aktiiviset käyttäjätilit';
	static ProjectNumber = 'Projektin numero';
	static EditableDocumentCodeMustBeDefined = 'Dokumentin nimi täytyy määritellä!';
	static DoYouReallyWantToRemoveRecordUserEmploymentParameter = 'Haluatko todella poistaa työn \'{0}\'?';
	static UserRecordStatisticsExpiringInSixMonthsFamiliarizations = '6 kk sisällä vanhentuvia perehdytyksiä';
	static PublishVoucherToFileSystem = 'Julkaise tosite tiedostojärjestelmään';
	static WhistleBlowStatisticsNewRecords = 'Uusia ilmoituksia';
	static NewInstantMessageChannelGroupInternal = 'Uusi ryhmä (yrityksen sisäinen)';
	static ArchiveExpiredFamiliarizations = 'Arkistoi vanhentuneet perehdytykset';
	static AuthorizationLevel = 'Käyttöoikeustaso';
	static Locked = 'Lukittu';
	static MarkMessageAsRead = 'Kuittaa viesti luetuksi';
	static AuthorizationLevelAdmin = 'Järjestelmänvalvoja';
	static TaskTypesMustBeDefinedBeforeAddingTasks = 'Tehtävätyypit täytyy määritellä ennen kuin tehtäviä voi lisätä!';
	static SavingEventTypeBParameterWasSuccessful = 'Tarkenteen 2 \'{0}\' tallennus onnistui!';
	static InstantMessageAttachmentFolderIsNotSpecified = 'Sovelluksen pikaviestien liitteiden hakemistoa ei ole määritelty!';
	static DirectoryItemFolderParameterNotFound = 'Hakemistoa \'{0}\' ei löytynyt!';
	static TasksExcelByTaskRows = 'Tehtävät - Excel - Suodatettu tehtäväriveittäin';
	static UserRecordStatisticsExpiringInSixMonthsQualifications = '6 kk sisällä vanhentuvia pätevyysvaatimuksia';
	static WhistleBlowStateInProgress = 'Tutkittavana';
	static LockingTaskDataRowWasNotSuccessful = 'Tehtävärivin lukitseminen epäonnistui!';
	static ExpiredPlural = 'Vanhentuneet';
	static ACopy = 'Kopio';
	static EventPartnerIsAlreadyInDefined = 'Havaintokumppani on jo määritelty!';
	static PublishingTaskTypeParameterWasSuccessful = 'Tehtävätyypin \'{0}\' julkaisu onnistui!';
	static WhistleBlowInformantTitle = 'Mikä on huolenaiheesi?';
	static ChartType = 'Kaavion tyyppi';
	static AddCommentsToEvent = 'Lisää kommentti plus-painikkeesta.';
	static DeletingCompanyParameterWasNotSuccessfulErrorParameter = 'Yrityksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static WhistleBlowCategoryParameterNotFound = 'Ilmoitusluokkaa \'{0}\' ei löytynyt!';
	static UnlockingEventParameterWasNotSuccessful = 'Havainnon \'{0}\' lukituksen poisto epäonnistui!';
	static AddEventHandlersToEvent = 'Lisää käsittelijä plus-painikkeesta.';
	static File = 'Tiedosto';
	static EditEventTypeCs = 'Muokkaa tarkenteita 3';
	static SavingEventTypeCsWasSuccessful = 'Tarkenteen 3 tallennus onnistui!';
	static WhistleBlowRecords = 'Ilmoitukset';
	static SavingInstantMessageChannelUserUserWasSuccessful = 'Keskustelijan tallennus onnistui!';
	static DoCleanLog = 'Tyhjennä loki';
	static KeywordsOrCondition = 'Joku valituista avainsanoista';
	static FilterCondition = 'Suodatusehto';
	static RemoveDefaultHandler = 'Poista oletuskäsittelijä';
	static SavingKeywordParameterWasNotSuccessfulConcurrencyError = 'Avainsanan \'{0}\' tallennus epäonnistui, koska avainsanan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingEventTypeCParameterWasSuccessful = 'Tarkenteen 3 \'{0}\' poisto onnistui!';
	static FolderParameterAlreadyExists = 'Kansio \'{0}\' on jo olemassa!';
	static EventUrgencyClasses = 'Kiireellisyysluokat';
	static EventTypeBCodeIsAlreadyInUse = 'Tarkenteen 2 nimi on jo käytössä!';
	static WhistleBlowDashboard = 'Whistleblower etusivu';
	static WhistleBlowAnonymityNonAnonymous = 'Nimellinen';
	static InheritedUserRights = 'Perityt käyttöoikeudet';
	static Entrepreneur = 'Yrittäjä';
	static DeletingWhistleBlowChannelAdminsParameterWasSuccessful = 'Ylläpitäjien \'{0}\' poisto onnistui!';
	static SavingFilterWasSuccessful = 'Suodattimen tallennus onnistui!';
	static UserIsCompanyAdminClaimNotFound = 'Käyttäjän ominaisuutta \'userIsCompanyAdmin\' ei löytynyt!';
	static ThisMessageWasDeleted = 'Tämä viesti on poistettu';
	static DeletingAttachmentsWasNotSuccessfulErrorParameter = 'Liitteiden poisto epäonnistui! Virhe: \'{0}\'';
	static YouHaveNotSavedChangesDoYouWantToSaveChanges = 'Et ole tallentanut tekemiäsi muutoksia. Tallennetaanko muutokset?';
	static SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError = 'Keskustelijan tallennus epäonnistui, koska keskustelijan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DependencyResolvingFailedForInterfaceParameter = 'Toteutusta ei löytynyt rajapinnalle \'{0}\'!';
	static WhistleBlowInformantMessageConfirmation = 'Lähetetäänkö uusi viesti ilmoittajalle?';
	static WriteBelowFieldWordParameterToConfirmTheOperation = 'Kirjoita alla olevaan kenttään sana \'{0}\' vahvistaaksesi toiminto:';
	static FrontPage = 'Etusivu';
	static UserHasUsedMobile = 'Käyttäjätili on ollut aktiivinen mobiilisovelluksessa viimeisen 30 päivän aikana.';
	static Administrator = 'Järjestelmänvalvoja';
	static UserFilterInActive = 'Pois käytöstä';
	static MessageParameterNotFound = 'Viestiä \'{0}\' ei löytynyt!';
	static EditEventTypes = 'Muokkaa tarkenteita 1';
	static IdCard = 'Henkilökortti';
	static Users = 'Käyttäjätilit';
	static RenewPersonalizationWithUser = 'Korvaa yksilöintitiedot seuraavan käyttäjän tiedoilla';
	static AccessRightWriteEvents = 'Havaintojen luonti ja käsittely';
	static AdditionalPermissionAMessage = 'Oikeus lukea kumppaneiden julkaisemia viestejä';
	static SavingEventPartnerParameterWasNotSuccessfulConcurrencyError = 'Havaintokumppanin \'{0}\' tallennus epäonnistui, koska kumppanin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DocumentModified = 'Päivittäjä';
	static DocumentNameParameterIsIllegal = 'Dokumentin nimi \'{0}\' ei ole sallittu (varattu nimi)!';
	static DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError = 'Jatkokoulutusten \'{0}\' poisto epäonnistui, koska jatkokoulutusten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RestoringInstantMessageWasNotSuccessfulErrorParameter = 'Pikaviestin palautus epäonnistui! Virhe: \'{0}\'';
	static EditableDocumentUnpublishConfirmation = 'Haluatko poistaa julkaistun tiedoston?';
	static SelectCompanyGroup = 'Valitse yritysryhmä';
	static ActiveState = 'Tila';
	static TaskTypeCodeMustBeDefined = 'Tehtävätyypin tunnus täytyy määritellä!';
	static DeletingInstantMessageUserParameterWasSuccessful = 'Keskustelijan \'{0}\' poisto onnistui!';
	static BasicInformation = 'Perustiedot';
	static WorkShift = 'Työvuoro';
	static InvalidValueInArgumentParameter = 'Virheellinen arvo parametrissa \'{0}\'!';
	static NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded = 'Uusi henkilöstökortti täytyy tallentaan ennen työpaikkojen lisäämistä!';
	static WhistleBlowSourceOral = 'Suullinen';
	static AddingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Tehtävätyypin \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static WageGroup = 'Palkkaryhmä';
	static Valid = 'Voimassa';
	static Line = 'Rivi';
	static RecordUserLogEntryTypeSave = 'Muokkaus';
	static ChangeSystemLanguageToEnglish = 'Vaihda kieli englanniksi';
	static WhistleBlowInformantHint = 'Kerro tarkasti tapauksesta, jotta se on selvitettävissä.';
	static CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits = 'Kopioi oletuskäsittelijät ja tiedotettavat henkilöt kaikkiin saman tason yksiköihin';
	static SavingCompanyParameterWasNotSuccessfulErrorParameter = 'Yrityksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static Intern = 'Työharjoittelija';
	static UserGroupAll = '\'kaikki käyttäjät\'';
	static SavingEventTypeParameterWasSuccessful = 'Tarkenteen 1 \'{0}\' tallennus onnistui!';
	static SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter = 'Perehdytyksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static DoYouReallyWantToRestoreThisInstantMessage = 'Haluatko todella palauttaa tämän pikaviestin?';
	static AddPanel = 'Lisää paneli';
	static NotInUse = 'Ei käytössä';
	static CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' olemassolon tarkistaminen epäonnistui! Virhe: \'{1}\'';
	static DoYouReallyWantToUnlockTask = 'Haluatko todella poistaa tehtävän \'{0}\' lukituksen?';
	static SavingApplicationPermissionsWasSuccessful = 'Asetusten tallennus onnistui!';
	static Competencies = 'Pätevyydet';
	static EventUrgencyClass = 'Kiireellisyysluokka';
	static SavingEditableDocumentParameterWasSuccessful = 'Dokumentin \'{0}\' tallennus onnistui!';
	static UserRecordStatisticsSavedCards = 'Tallennettuja kortteja';
	static AllDocumentTypes = 'Näytä kaikki dokumenttityypit';
	static SavePrintAs = 'Tallenna tuloste nimellä';
	static EmailingMessageWasNotSuccessfulErrorParameter = 'Viestin lähetys sähköpostilla epäonnistui! Virhe: \'{0}\'';
	static DoYouReallyWantToRemoveTaskParameterPermanenty = 'Haluatko todella poistaa tehtävän \'{0}\' lopullisesti? Poistettua tehtävää ei ole mahdollista palauttaa.';
	static NewInstantMessageChannelGroup = 'Uusi ryhmä';
	static FileRemovalWasSuccessful = 'Tiedoston poisto onnistui!';
	static SavingRecordUserParameterWasNotSuccessfulConcurrencyError = 'Henkilöstökortin \'{0}\' tallennus epäonnistui, koska henkilöstökortin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static FamiliarizationStartDateDateEnd = 'Perehdytys alkoi - loppu';
	static InUse = 'Käytössä';
	static MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter = 'Tehtävän \'{0}\' siirto roskakoriin epäonnistui! Virhe: \'{1}\'';
	static Number = 'Numero';
	static DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError = 'Työpaikkojen \'{0}\' poisto epäonnistui, koska työpaikkojen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RecordUserLogEntryTypeReport = 'Raportointi';
	static Panels = 'Panelit';
	static OthersLastModifications = 'Yrityksen viimeisimmät muutokset';
	static DoYouReallyWantToRemoveSharedDocumentTemplateParameter = 'Haluatko todella poistaa jaetun dokumenttimallin \'{0}\'?';
	static EditDefaultHandlers = 'Muokkaa oletuskäsittelijöitä';
	static AddQualifications = 'Lisää pätevyysvaatimukset';
	static DeletingFileFilterParameterWasNotSuccessfulErrorParameter = 'Tiedostosuodattimen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static Add = 'Lisää';
	static EventUrgencyClassParameterNotFound = 'Kiireellisyysluokkaa \'{0}\' ei löytynyt!';
	static EditEventTypeC = 'Muokkaa tarkennetta 3';
	static AddLogisticVoucherCustomer = 'Lisää asiakas';
	static SelectInstantMessageChannelType = 'Valitse keskustelun tyyppi';
	static DoYouReallyWantToRemoveEventTypeBParameter = 'Haluatko todella poistaa tarkenteen 2 \'{0}\'?';
	static CompanyName = 'Yrityksen nimi';
	static RecordUserLogEntryTypeOpen = 'Luku';
	static RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter = 'Käyttäjäryhmien ja ulkoisten järjestelmien linkityksen poisto epäonnistui! Virhe: \'{0}\'';
	static AddPartnersByClickingPlusButton = 'Lisää kumppanit plus-painikkeesta.';
	static SavingChartWasNotSuccessfulConcurrencyError = 'Kuvaajan tallennus epäonnistui, koska kuvaajan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter = 'Tehtävän prioriteetin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static ContentFiltering = 'Sisällön suodatus';
	static ApproveEventResolution = 'Hyväksy havainto käsitellyksi';
	static LockingEventParameterWasNotSuccessful = 'Havainnon \'{0}\' lukitseminen epäonnistui!';
	static Report = 'Raportti';
	static SourceFolderAndTargetFolderAreSame = 'Lähdekansio ja kohdekansio ovat samat!';
	static SavingCompanyParameterWasNotSuccessfulConcurrencyError = 'Yrityksen \'{0}\' tallennus epäonnistui, koska yrityksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddRecordUserWithoutUserAccount = 'Lisää henkilöstökortti ilman käyttäjätiliä';
	static WhistleBlowCategoryCodeIsAlreadyInUse = 'Ilmoitusluokan nimi on jo käytössä!';
	static SavingChartWasNotSuccessfulErrorParameter = 'Kuvaajan tallennus epäonnistui! Virhe: \'{0}\'';
	static SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError = 'Oletuskäsittelijän \'{0}\' tallennus epäonnistui, koska oletuskäsittelijän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static LockedTaskParameterCannotBeRestored = 'Lukittua tehtävää \'{0}\' ei voi palauttaa!';
	static UserFilterExpired = 'Voimassaoloaika päättynyt';
	static DoYouReallyWantToRemoveSelectedRecordBasicEducations = 'Haluatko todella poistaa valitut peruskoulutukset?';
	static AddCompanyGroups = 'Lisää yritysryhmät';
	static DoYouReallyWantToRemoveAttachmentParameter = 'Haluatko todella poistaa liitteen \'{0}\'?';
	static SavingOneTimePasswordWasNotSuccessfulConcurrencyError = 'Kertakäyttöisen salasanan tallennus epäonnistui, koska kertakäyttöisen salasanan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SaveEvent = 'Tallenna havainto';
	static Select = 'Valitse';
	static WhistleBlowNonAnonymousReportInfo = 'Nimesi on ainoastaan ilmoistusten käsitellijöiden tiedossa eikä sitä paljasteta muille.';
	static AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter = 'Perehdytysten lisäys epäonnistui! Virhe: \'{0}\'';
	static EditLogisticVoucherPaymentSetting = 'Muokkaa laskutusasetusta';
	static ReadImp = 'Luettu';
	static AddKeyword = 'Lisää avainsana';
	static DocumentRootPath = 'Dokumenttien juurihakemisto';
	static DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError = 'Liitteiden \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static UserRecordStatisticsExpiredQualifications = 'Vanhentuneita pätevyysvaatimuksia';
	static PropertyTransferred = 'Luovutettu';
	static Images = 'Kuvat';
	static CompanyCodeIsAlreadyInUse = 'Yrityksen tunnus on jo käytössä!';
	static ToDo = 'Hoidettavia asioita';
	static DeletingRecordUserTransferredPropertyParameterWasSuccessful = 'Luovutetun omaisuuden \'{0}\' poisto onnistui!';
	static CreatingNotifications = 'Luo ilmoituksia...';
	static RemoveRemainder = 'Poista muistutus';
	static Events = 'Havainnot';
	static EditableDocumentApproveConfirmation = 'Asetetaanko hyväksyntä dokumenttiin?';
	static DocumentTemplates = 'Dokumenttimallit';
	static SavingWhistleBlowWasNotSuccessfulErrorParameter = 'Ilmoituksen tallennus epäonnistui! Virhe: \'{0}\'';
	static Theme = 'Teema';
	static AddUsers = 'Lisää käyttäjätilit';
	static SaveUserFurtherEducation = 'Tallenna jatkokoulutus';
	static DeletingMessageParameterWasNotSuccessfulErrorParameter = 'Viestin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static Open = 'Avaa';
	static DeletingTaskPriorityParameterWasSuccessful = 'Tehtävän prioriteetin \'{0}\' poisto onnistui!';
	static DoYouReallyWantToRemoveSelectedEventUrgencyClasses = 'Haluatko todella poistaa valitut kiireellisyysluokat?';
	static SavingImagesNotSuccessfulErrorParameter = 'Kuvien tallennus epäonnistui! Virhe: \'{0}\'';
	static City = 'Postitoimipaikka';
	static SavingUserParametersWasSuccessful = 'Käyttäjäasetusten tallennus onnistui!';
	static ShowInheritedPermissions = 'Näytä perityt käyttöoikeudet';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' kopioiminen kansioon \'{1}\' epäonnistui! Virhe: \'{2}\'';
	static WhistleBlowNewReport = 'Uusi ilmoitus';
	static UnitHandlingIncomingEvents = 'Yksikkö, joka käsittelee kumppanin lähettämät havainnot';
	static ExpiredFurtherEducations = 'Vanhentuneet jatkokoulutukset';
	static SaveAndSend = 'Tallenna ja lähetä';
	static DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError = 'Tehtävätyyppien \'{0}\' poisto epäonnistui, koska tehtävätyyppien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static FurtherEducationsMustBeDefinedBeforeAddingRecordUsers = 'Jatkokoulutukset täytyy määritellä ennen kuin henkilöstökortteja voi lisätä!';
	static DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter = 'Luovutetun omaisuuden \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter = 'Ylläpitäjän \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError = 'Tehtävien tilojen \'{0}\' poisto epäonnistui, koska tehtävien tilojen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Familiarization = 'Perehdytys';
	static Draft = 'Luonnos';
	static JobTypeEventNotification = 'Havainnon ilmoitus';
	static DateMustBeDefined = 'Päiväys täytyy määritellä!';
	static SetPermissions = 'Asetetut käyttöoikeudet';
	static WhistleBlowStateReceived = 'Vastaanotettu';
	static GroupByPerson = 'Henkilöittäin';
	static EventTypeCodeIsAlreadyInUse = 'Tarkenteen nimi on jo käytössä!';
	static DoYouReallyWantToRemoveFolderParameter = 'Haluatko todella poistaa kansion \'{0}\'?';
	static AuthorizationLevelSuperUser = 'Superkäyttäjä';
	static DoYouReallyWantToRemovePartnersParameter = 'Haluatko todella poistaa kumppanit \'{0}\'?';
	static Print = 'Tulosta';
	static DoYouReallyWantToRemoveRemainderParameter = 'Haluatko todella poistaa muistutuksen \'{0}\'?';
	static FamiliarizationStartDate = 'Perehdytys alkoi';
	static SearchCurrentTopics = 'Hae ajankohtaista';
	static DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter = 'Perehdytysten \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static RemoveFolder = 'Poista kansio';
	static ComparingFileSystemToDatabase = 'Vertailee tiedostojärjestelmää tietokantaan...';
	static SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter = 'Peruskoulutuksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static MissingPlural = 'Puuttuvat';
	static DeletingOneTimePasswordWasNotSuccessfulConcurrencyError = 'Kertakäyttöisen salasanan poisto epäonnistui, koska kertakäyttöisen salasanan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded = 'Uusi työpaikka täytyy tallentaan ennen liitteiden lisäämistä!';
	static UserRecordStatisticsExpiredFurtherEducations = 'Vanhentuneita jatkokoulutuksia';
	static RecordBasicEducationParameterNotFound = 'Peruskoulutusta \'{0}\' ei löytynyt!';
	static AdditionalPermissionAEvents = 'Käsittelijänä toimiminen';
	static EInvoiceOperator = 'Verkkolaskuoperaattori';
	static AccountNumberTkbOutDebit = 'Ostovelka';
	static Settings = 'Asetukset';
	static FileFilterCodeMustBeDefined = 'Tiedostosuodattimen tunnus täytyy määritellä!';
	static DoYouReallyWantToRemoveChartPanelParameter = 'Haluatko todella poistaa kaavion \'{0}\'?';
	static ClosedEvent = 'Sulkenut havainnon';
	static FamiliarizationTime = 'Perehdytysaika';
	static AddPartnerParameterWasNotSuccessfulConcurrencyError = 'Kumppanin \'{0}\' lisäys epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EmailSendDate = 'Sähköpostin päiväys';
	static Employment = 'Työpaikka';
	static DeletingKeywordParameterWasNotSuccessfulErrorParameter = 'Avainsanan \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static PageFooterText = 'Järjestelmän alatunnisteteksti';
	static InstantMessageChannelUsers = 'Keskustelijat';
	static DeletingPartnersParameterWasNotSuccessfulConcurrencyError = 'Kumppanien \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static TaskPriorities = 'Tehtävien prioriteetit';
	static DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError = 'Luovutettujen omaisuuksien \'{0}\' poisto epäonnistui, koska luovutettujen omaisuuksien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EditMode = 'Muokkaustila';
	static NotificationsSentSuccessfully = 'Ilmoitusten lähettäminen onnistui!';
	static DoYouReallyWantToRemoveTaskDataRow = 'Haluatko todella poistaa rivin?';
	static CompanyGroupCodeMustBeDefined = 'Yritysryhmän tunnus täytyy määritellä!';
	static DoYouReallyWantToRemoveTaskTypeParameter = 'Haluatko todella poistaa tehtävätyypin \'{0}\'?';
	static FileParameterDownloaded = 'Tedosto \'{0}\' on ladattu.';
	static AddPartnerCompanies = 'Lisää kumppanit, joille yritys voi jakaa sisältöä';
	static RemoveRecordUserAndUserSetExpired = 'Poista henkilöstökortti ja aseta käyttäjätilin voimassaolo päättyneeksi';
	static SavingUnitPermissionsWasSuccessful = 'Käyttöoikeuksien tallennus yksiköille onnistui!';
	static SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError = 'Peruskoulutuksen tallennus \'{0}\' epäonnistui, koska peruskoulutuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Companies = 'Yritykset';
	static NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded = 'Uusi henkilöstökortti täytyy tallentaan ennen perehdytysten lisäämistä!';
	static DoYouReallyWantToRemoveEventUrgencyClassParameter = 'Haluatko todella poistaa kiireellisyysluokan \'{0}\'?';
	static SavingWhistleBlowWasNotSuccessfulConcurrencyError = 'Ilmoituksen tallennus epäonnistui, koska ilmoituksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ThisPartOfApplicationIsNotInUse = 'Tämä osa sovellusta ei ole enää käytössä!';
	static TimeSpanHalfYear = 'Puoli vuotta';
	static EnableExternalIntraSystemConfirmation = 'Haluatko todella ottaa yrityksen käyttöön järjestelmässä? Tallennettaessa yrityksen tiedot tallennetaan myös järjestelmään.';
	static AccessRightReadEvents = 'Havaintojen luonti';
	static Priority = 'Prioriteetti';
	static DocumentCreated = 'Laatija';
	static TaskStatesMustBeDefinedBeforeAddingTasks = 'Tehtävien tilat täytyy määritellä ennen kuin tehtäviä voi lisätä!';
	static PrivacyPolicy = 'Tietosuojaseloste';
	static Keywords = 'Avainsanat';
	static DoYouReallyWantToRemoveKeywordParameter = 'Haluatko todella poistaa avainsanan \'{0}\'?';
	static DefaultLanguageHasBeenChanged = 'Kieli on muutettu!';
	static DeletingEventParameterWasSuccessful = 'Havainnon \'{0}\' poisto onnistui!';
	static DefaultSettings = 'Oletusasetukset';
	static CompanyGroupModuleProperties = 'Yritysryhmien asetukset';
	static WhistleBlowNotFound = 'Ilmoitusta ei löytynyt!';
	static DoYouReallyWantToRemoveMessageTypeParameter = 'Haluatko todella poistaa viestityypin \'{0}\'?';
	static StateOpen = 'Avoin';
	static CompanyGroupParameterNotFound = 'Yritysryhmää \'{0}\' ei löytynyt!';
	static DeletingTaskTypesParameterWasSuccessful = 'Tehtävätyyppien \'{0}\' poisto onnistui!';
	static JobTitle = 'Tehtävänimike';
	static RemoveFile = 'Poista tiedosto';
	static SaveMessage = 'Tallenna viesti';
	static AdministratorPasswordMustBeDefined = 'Järjestelmänvalvojan salasana täytyy määritellä!';
	static ExternalSystemCodeClaimNotFound = 'Käyttäjän ominaisuutta \'externalSystemCode\' ei löytynyt!';
	static DoYouReallyWantToRemoveSelectedHandlers = 'Haluatko todella poistaa valitut käsittelijät?';
	static DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError = 'Kansion \'{0}\' poisto epäonnistui, koska kansion tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static LogCleaning = 'Lokin tyhjennys';
	static EventsFound = 'Havaintoja löytyi';
	static Change = 'Vaihda';
	static ExitingInstantMessageChannelWasNotSuccessfulErrorParameter = 'Keskustelusta poistuminen epäonnistui! Virhe: \'{0}\'';
	static DoYouReallyWantToRemoveEditableDocumentParameter = 'Haluatko todella poistaa dokumentin \'{0}\'? Myös dokumenttiin mahdollisesti liittyvä tiedosto poistetaan.';
	static Archive = 'Arkistoi';
	static Empty = 'Tyhjä';
	static Resources = 'Resurssit';
	static AccessRightWriteWhistleBlow = 'Ilmoituksen käsittely';
	static LockedDocumentParameterCannotBeMoved = 'Lukittua dokumenttia \'{0}\' ei voi siirtää!';
	static SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter = 'Dokumenttimallin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static SavingReadReceiptWasSuccessful = 'Lukukuittauksen tallennus onnistui!';
	static NoTasksToShow = 'Ei kirjattuja tehtäviä!';
	static AllInstantMessageUsersHaveReadMessage = 'Kaikki keskustelijat ovat lukeneet viestin';
	static DeletingTaskStatesParameterWasSuccessful = 'Tehtävien tilojen \'{0}\' poisto onnistui!';
	static Cancel = 'Peruuta';
	static AdditionalPermissionBEvents = 'Poisto';
	static ReportDialogInfoText = 'Raportti muodostetaan suodatuksen/hakuehdon mukaisten rivien perusteella.';
	static Continue = 'Jatka';
	static TaskDataRowParameterNotFound = 'Tehtävän riviä \'{0}\' ei löytynyt!';
	static RemovingEventHandlerWasNotSuccessfulErrorParameter = 'Käsittelijöiden poisto epäonnistui! Virhe: \'{0}\'';
	static RemoveDefaultBasicEducation = 'Poista oletusperuskoulutus';
	static VoucherInOutReportSavePath = 'Koontitositteen tallennuskansio';
	static InstantMessageChannelUserParameterNotFound = 'Keskustelun osapuolta \'{0}\' ei löytynyt!';
	static EditMessageType = 'Muokkaa viestityyppiä';
	static SearchProperties = 'Mukautettu haku';
	static AllUnits = 'Kaikki yksiköt';
	static EventTypesGeneric = 'Tarkenteet';
	static SavingTaskStateParameterWasNotSuccessfulConcurrencyError = 'Tehtävän tilan \'{0}\' tallennus epäonnistui, koska tehtävän tilan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ChangeSystemLanguageToFinnish = 'Vaihda kieli suomeksi';
	static SavingRecordEmploymentParameterWasSuccessful = 'Työpaikan \'{0}\' tallennus onnistui!';
	static Folders = 'Kansiot';
	static RequireReadReceipt = 'Vaatii lukukuittauksen';
	static Required = 'Pakollinen';
	static UnreadMessagesParameter = 'Lukemattomia viestejä: {0}';
	static WhistleBlowArchivingTypeChangeState = 'Siirrä ilmoitus Arkistoitu-tilaan';
	static DoYouReallyWantToRemoveCompanyGroupParameter = 'Haluatko todella poistaa yritysryhmän \'{0}\'?';
	static AddToHomeScreen = 'Lisää  aloitusnäyttöön';
	static ForwardMessage = 'Lähetä edelleen';
	static Files = 'Tiedostot';
	static WhistleBlowCreateTime = 'Ilmoituksen lähetysaika';
	static DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError = 'Tarkenteen 3 \'{0}\' poisto epäonnistui, koska tarkenteen 3 tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ModifiedActions = 'Muokannut toimenpiteitä';
	static ChartProperties = 'Kuvaajan asetukset';
	static EditableDocumentPublishConfirmation = 'Haluatko julkaista dokumentin tiedostoksi?';
	static Password = 'Salasana';
	static RemoveLogisticTransportCompany = 'Poista kuljetusyritys';
	static DoYouReallyWantToPublishTaskTypeConfirmation = 'Haluatko todella julkaista tehtävätyypin \'{0}\' kaikille yrityksille joilta se puuttuu?';
	static SavingTaskDataRowWasSuccessful = 'Tehtävärivin tallennus onnistui!';
	static AddCompanyToCompanyGroup = 'Lisää yritys yritysryhmään';
	static AddingAddRecordUserFurtherEducationsWasSuccessful = 'Jatkokoulutusten lisäys onnistui!';
	static EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Kiireellisyysluokka \'{0}\' on käytössä {1} havainnossa eikä sitä voi poistaa!';
	static RemoveEmployment = 'Poista työ';
	static UserCodeClaimNotFound = 'Käyttäjän ominaisuutta \'code\' ei löytynyt!';
	static DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter = 'Jatkokoulutusten \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static TimeSpanYear = 'Vuosi';
	static EditableDocumentCodeIsAlreadyInUse = 'Dokumentin nimi on jo käytössä!';
	static PrintTask = 'Tulosta tehtävä';
	static NewComment = 'Uusi kommentti';
	static Log = 'Loki';
	static WaitingToBeApproved = 'Odottaa hyväksyntää';
	static Properties = 'Asetukset';
	static PasswordMustContainLowercaseChar = 'Salasanan täytyy sisältää pienaakkonen!';
	static PublishDate = 'Julkaisupäivä';
	static CurrentTopicsAbbr = 'Ajankoht.';
	static DeletingCompanyGroupParameterWasSuccessful = 'Yritysryhmän \'{0}\' poisto onnistui!';
	static ShowDraftVersion = 'Näytä muokkausversio';
	static TaskPriorityCodeIsAlreadyInUse = 'Tehtävän prioriteetin nimi on jo käytössä!';
	static WhistleBlowCategories = 'Ilmoitusluokat';
	static DetectingLocation = 'Tunnistaa sijaintia...';
	static NotificationsQueuedSuccessfully = 'Ilmoitukset asetettu lähetettäväksi!';
	static DeletingKeywordParameterWasSuccessful = 'Avainsanan \'{0}\' poisto onnistui!';
	static Browse = 'Selaa';
	static ThisMessageWasDeletedByInstantMessageChannelAdministrator = 'Tämän viestin on poistanut ylläpitäjä \'{0}\'';
	static UserClaimsNotFound = 'Käyttäjän ominaisuuksia ei löytynyt!';
	static Relation = 'Liittyy';
	static NoName = 'Nimetön';
	static AddEmployment = 'Lisää työ';
	static RemoveDefaultEventHandler = 'Poista oletuskäsittelijä';
	static CopyOrMoveFolderParameter = 'Kopioi tai siirrä kansio \'{0}\'';
	static DefaultStyle = 'Normaali';
	static UnitNameMustBeDefined = 'Yksikön nimi täytyy määritellä!';
	static DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter = 'Ilmoitusluokkien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static TransportCompanies = 'Kuljetusyritykset';
	static SendinEmailNotificationsWasNotSuccessfulErrorParameter = 'Tiedotteiden lähetys sähköpostilla epäonnistui! Virhe: \'{0}\'';
	static Unkown = 'Tuntematon';
	static WhistleBlowInformantDescription = 'Kerro, mitä on tapahtunut.';
	static InternalMessages = 'Sisäiset viestit';
	static SavingCompanyParameterWasSuccessful = 'Yrityksen \'{0}\' tallennus onnistui!';
	static ProjectName = 'Projektin nimi';
	static InstantMessageChannelInternal = 'Yrityksen sisäinen';
	static Modified = 'Muokattu';
	static DocumentIsEmpty = 'Dokumentti on tyhjä!';
	static WhistleBlowInformantButtonHint = 'Älä sisällytä ilmoitukseen arkaluonteisia henkilötietoja, elleivät ne ole välttämättömiä ilmoituksen kannalta.';
	static SavingEventTypesWasNotSuccessfulErrorParameter = 'Tarkenteen 1 tallennus epäonnistui! Virhe: \'{0}\'';
	static DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter = 'Kiireellisyysluokan \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static AddMessageType = 'Lisää viestityyppi';
	static SavingJobParameterWasNotSuccessfulErrorParameter = 'Työn \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Perehdytys \'{0}\' on käytössä {1} henkilöstökortissa eikä sitä voi poistaa!';
	static DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter = 'Luovutettujen omaisuuksien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static ReportsModified = 'Suosikkiraportit';
	static DoYouReallyWantToRemoveSelectedRecordFamiliarizations = 'Haluatko todella poistaa valitut perehdytykset?';
	static InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation = 'Haluatko todella poistaa poistaa itsesi ylläpitäjien joukosta? Vain ylläpitäjät voivat hallita keskustelua.';
	static UserRecordStatisticsExpiringInSixMonthsFurtherEducations = '6 kk sisällä vanhentuvia jatkokoulutuksia';
	static DefaultTaskPriorities = 'Tehtävien oletusprioriteetit';
	static NewMessage = 'Uusi viesti';
	static AddLogisticVoucherCompanyPaymentSetting = 'Lisää laskutusasetus';
	static RemoveWhistleBlowCategory = 'Poista ilmoitusluokka';
	static DoYouReallyWantToRemoveUnitParameter = 'Haluatko todella poistaa yksikön \'{0}\'?';
	static SelectSubject = 'Valitse aihe';
	static SelectRemovingMethod = 'Valitse poiston tapa';
	static EditPayerSettings = 'Muokkaa maksajan asetuksia';
	static InvalidValueParameter = 'Virheellinen arvo \'{0}\'!';
	static NormalSize = 'Normaali koko';
	static DeletingTaskDataRowWasNotSuccessfulConcurrencyError = 'Tehtävärivin poisto epäonnistui, koska tehtävärivin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DoYouReallyWantToRemoveFileFilterParameter = 'Haluatko todella poistaa tiedostosuodattimen \'{0}\'?';
	static PermissionsForSection = 'Osion käyttöoikeudet';
	static TargetsOtherCompanies = 'Näkyvyys - muut yritykset';
	static UserGroupModuleProperties = 'Käyttäjäryhmien asetukset';
	static WhistleBlowEventTypeModified = 'Muokattu';
	static SomeEventHandlersHaveReadMessage = 'Osa havainnon käsittelijöistä on lukenut kommenttisi';
	static Scope = 'Laajuus';
	static UserRecordStatisticsActiveCards = 'Aktiivisia kortteja';
	static ChangedObserverAction = 'Muuttanut havainnon tekijän toimenpidettä';
	static SavingUserGroupParameterWasNotSuccessfulErrorParameter = 'Käyttäjäryhmän \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static UnitMustBeSelected = 'Yksikkö täytyy valita!';
	static CompanyCodeMustBeDefined = 'Yrityksen tunnus täytyy määritellä!';
	static SavingEventParameterWasNotSuccessfulErrorParameter = 'Havainnon \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static WhistleBlowChannel = 'Ilmoituskanava';
	static WhistleBlowWasNotFoundExplanation = 'Tarkista että syötit ilmoituksen salasanan oikein.';
	static DocumentTemplateParameterNotFound = 'Dokumenttimallia \'{0}\' ei löytynyt!';
	static Sorting = 'Lajittelu';
	static MessageTypeCodeIsAlreadyInUse = 'Viestityypin tunnus on jo käytössä!';
	static SelectDocumentTemplate = 'Valitse dokumenttimalli';
	static SavingTaskDataRowWasNotSuccessfulConcurrencyError = 'Tehtävärivin tallennus epäonnistui, koska tehtävärivin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static WhistleBlowInternalMessageConfirmation = 'Tallennetaanko uusi sisäinen viesti?';
	static DeletingCompanyGroupsParameterWasSuccessful = 'Yritysryhmien \'{0}\' poisto onnistui!';
	static DeletingTaskDataRowWasNotSuccessfulErrorParameter = 'Tehtävärivin poisto epäonnistui! Virhe: \'{0}\'';
	static FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations = 'Yrityksen perehdytykset täytyy määritellä ennen kuin perehdytyksiä voi lisätä henkilöstökortille!';
	static DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError = 'Luovutetun omaisuuden \'{0}\' poisto epäonnistui, koska luovutetun omaisuuden tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Unlock = 'Poista lukitus';
	static Tasks = 'Tehtävät';
	static RestoreTaskFromTrashBin = 'Palauta tehtävä roskakorista';
	static SavingWhistleBlowMessageWasSuccessful = 'Viestin tallennus onnistui!';
	static Test = 'Testaa';
	static TaskPriorityCodeMustBeDefined = 'Tehtävän prioriteetin nimi täytyy määritellä!';
	static DeletingOneTimePasswordWasNotSuccessfulErrorParameter = 'Kertakäyttöisen salasanan poisto epäonnistui! Virhe: \'{0}\'';
	static DoYouReallyWantToRemoveTaskStateParameter = 'Haluatko todella poistaa tehtävän tilan \'{0}\'?';
	static DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter = 'Ilmoituksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static ReadingFolderParameter = 'Lukee kansiota \'{0}\'...';
	static SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter = 'Pätevyysvaatimuksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static PublishingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Tehtävätyypin \'{0}\' julkaisu epäonnistui! Virhe: \'{1}\'';
	static RemoveBasicEducation = 'Poista peruskoulutus';
	static WhistleBlowEventTypeAttachmentsAdded = 'Liitteitä lisätty';
	static AddEventMessageConfirmation = 'Haluatko lisätä kommentin? Kommenttia ei voi muokata lisäämisen jälkeen.';
	static SavingEventUrgencyClassParameterWasSuccessful = 'Kiireellisyysluokan \'{0}\' tallennus onnistui!';
	static DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter = 'Tiedoston \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static InstantMessageChannelGroupExternal = 'Ryhmä (yritysten välinen)';
	static CompanyIdClaimNotFound = 'Käyttäjän ominaisuutta \'yrityksen id\' ei löytynyt!';
	static WhistleBlowReportIHaveSavedThePassword = 'Olen tallentanut salasanani turvallisesti, jotta voin käyttää sitä ilmoitukseni seurantaan.';
	static UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels = 'Käyttäjä on keskustelun ainoa ylläpitäjä {0} keskustelussa!';
	static Unlimited = 'Rajoittamaton';
	static RemoveDefaultEventType = 'Poista oletustarkenne 1';
	static SavingOneTimePasswordWasNotSuccessfulErrorParameter = 'Kertakäyttöisen salasanan tallennus epäonnistui! Virhe: \'{0}\'';
	static NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded = 'Uusi jatkokoulutus täytyy tallentaan ennen liitteiden lisäämistä!';
	static SavingFileFilterParameterWasSuccessful = 'Tiedostosuodattimen \'{0}\' tallennus onnistui!';
	static PermissionToCreateEventsToPartners = 'Oikeus luoda havaintoja kumppaneille';
	static ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError = 'Keskustelusta poistuminen epäonnistui, koska keskustelun tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static FieldsToBePrinted = 'Tulostettavat kentät';
	static WhistleBlowSourceOther = 'Muu';
	static DoYouReallyWantToSave = 'Haluatko todella tallentaa?';
	static MovingTaskParameterToTrashBinWasSuccessful = 'Tehtävä \'{0}\' siirretty roskakoriin!';
	static DisableExternalIntraSystemConfirmation = 'Haluatko todella poistaa yrityksen järjestelmän käytöstä? Tallennettaessa yrityksen tiedot poistetaan järjestelmästä.';
	static WhistleBlowMustBeReceivedLatestAtParameter = 'Ilmoitus täytyy merkitä vastaanotetuksi viimeistään {0}!';
	static UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter = 'Käyttäjän \'{0}\' linkitys ulkoisiin järjestelmiin epäonnistui! Virhe: \'{1}\'';
	static SavingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Tehtävätyypin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion = 'Järjestelmä ei tue selainversiotasi, päivitä selaimesi uusimpaan versioon.';
	static UserRecordStatisticsMissingQualifications = 'Puuttuvia pätevyysvaatimuksia';
	static ObserverActionMustBeDefined = 'Havainnon tekijän toimenpiteet täytyy määritellä!';
	static CanShareContentToCompanies = 'Kumppanit, joille yritys voi jakaa sisältöä';
	static SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError = 'Ilmoituskanavan tallennus \'{0}\' epäonnistui, koska ilmoituskanavan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddDefaultHandler = 'Lisää oletuskäsittelijä';
	static WhistleBlowInformation = 'Ilmoituksen tiedot';
	static DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter = 'Peruskoulutusten \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static EditWhistleBlow = 'Muokkaa ilmoitusta';
	static NewRecordUserMustBeSavedBeforeRowsCanBeAdded = 'Uusi henkilöstökortti täytyy tallentaan ennen rivien lisäämistä!';
	static ReadingFileParameterWasNotSuccessfulFileNotFound = 'Tiedoston \'{0}\' lukeminen tiedostojärjestelmästä epäonnistui! Tiedosta ei löydy!';
	static ChangingPasswordWasSuccessful = 'Salasanan vaihto onnistui!';
	static WhistleBlowSeverityHigh = 'Vakava';
	static WhistleBlowEventTypeHandlersRemoved = 'Käsittelijöitä poistettu';
	static AdditionalPermissions = 'Lisäkäyttöoikeudet';
	static Aprint = 'Tuloste';
	static CreatingCompressedFileWasNotSuccessfulErrorParameter = 'Tiedostojen pakkaus epäonnistui! Virhe: \'{0}\'';
	static WhistleBlowWasNotFound = 'Ilmoitusta ei löytynyt!';
	static ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError = 'Jatkokoulutusten arkistointi epäonnistui, koska jatkokoulutusten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddCompany = 'Lisää yritys';
	static PostalAddress = 'Osoite';
	static AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel = 'Lisää vähintään yksi keskustelija keskusteluun!';
	static NewPrint = 'Uusi tuloste';
	static ExternalIntraSystems = 'eSalkku-järjestelmät';
	static SavingCompanyGroupParameterWasSuccessful = 'Yritysryhmän \'{0}\' tallennus onnistui!';
	static TaskHandler = 'Käsittelijä';
	static AccessRightReadInstantMessages = 'Yrityksen sisäiset keskustelut';
	static FolderProperties = 'Kansion ominaisuudet';
	static WhistleBlowSourceUnspecified = 'Määrittämättä';
	static UserIsSystemAdminClaimNotFound = 'Käyttäjän ominaisuutta \'userIsSystemAdmin\' ei löytynyt!';
	static DocumentParameterAlreadyExists = 'Dokumentti \'{0}\' on jo kansiossa!';
	static ImageProperties = 'Kuvien asetukset';
	static RefreshList = 'Päivitä lista';
	static EmptyTaskTrashBinConfirmation = 'Haluatko todella poistaa kaikki tehtävät roskakorista lopullisesti? Poistettuja tehtäviä ei ole mahdollista palauttaa.';
	static ImmediateAction = 'Tehdyt toimenpiteet (välittömät, korjaavat, tms.)';
	static AddUserParameterWasNotSuccessfulConcurrencyError = 'Käyttäjätilin \'{0}\' lisäys epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EventTypeTitle = 'Havainnon yksilöinnin tarkenne 1';
	static MovingFilesAndDocumentsToFolderParameterWasSuccessful = 'Tiedostojen/dokumenttien siirtäminen kansioon \'{0}\' onnistui!';
	static UserCanBeSelectedAsEventHandler = 'Käyttäjä voidaan asettaa havaintojen käsittelijäksi';
	static Expand = 'Laajenna';
	static ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter = 'Ilmoitusten \'{0}\' arkistointi epäonnistui! Virhe: \'{1}\'';
	static RecordUser = 'Henkilöstökortti';
	static SavingEditableDocumentParameterPublishWasSuccessful = 'Dokumentin \'{0}\' julkaisu onnistui!';
	static AddingAttachmentParameterWasNotSuccessfulErrorParameter = 'Liiteen \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static GroupByQuarter = 'Kvartaaleittain';
	static SavingEventMessageWasNotSuccessfulErrorParameter = 'Kommentin tallennus epäonnistui! Virhe: \'{0}\'';
	static WorkShiftType = 'Työvuoromuoto';
	static WhistleBlowAnonymityAnonymous = 'Anonyymi';
	static EventTypeBCodeMustBeDefined = 'Tarkenteen 2 nimi täytyy määritellä!';
	static MyFiltersAndReports = 'Käyttäjän suodattimet ja raportit';
	static DeletingRecordFurtherEducationParameterWasSuccessful = 'Jatkokoulutuksen \'{0}\' poisto onnistui!';
	static ProcessingFolderParameter = '* Käsittelee kansiota \'{0}\'...';
	static RemoveDocumentTemplate = 'Poista dokumenttimalli';
	static NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded = 'Uusi henkilöstökortti täytyy tallentaan ennen jatkokoulutusten lisäämistä!';
	static SavingEventSettingsWasNotSuccessfulErrorParameter = 'Havaintojen asetusten tallennus epäonnistui! Virhe: \'{0}\'';
	static DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError = 'Pätevyysvaatimuksen \'{0}\' poisto epäonnistui, koska pätevyysvaatimuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AdditionalPermissionAWhistleBlow = 'Voi kommunikoida ilmoittajan kanssa';
	static UserAccountMustBeSelected = 'Käyttäjätili täytyy valita!';
	static ProcessingTime = 'Käsittelyaika';
	static SavingPrintWasNotSuccessfulErrorParameter = 'Tulosteen tallennus epäonnistui! Virhe: \'{1}\'';
	static AddTarget = 'Lisää kohde';
	static DeletingEventTypeBParameterWasNotSuccessfulErrorParameter = 'Tarkenteen 2 \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static RecordFurtherEducationParameterNotFound = 'Jatkokoulutusta \'{0}\' ei löytynyt!';
	static RemoveParameterDocuments = 'Poista {0} dokumenttia/tiedostoa';
	static EditEvent = 'Muokkaa havaintoa';
	static RemoveRowConfirmation = 'Haluatko todella poistaa rivin {0}?';
	static Addition = 'Lisä';
	static MiddleName = 'Muut etunimet';
	static SendReadReceiptConfirmation = 'Haluatko kuitata viestin luetuksi?';
	static EditEventTypeBs = 'Muokkaa tarkenteita 2';
	static NotifiedPersons = 'Ilmoitus kirjatusta havainnosta';
	static AddFile = 'Lisää tiedosto';
	static ChangeLanguage = 'Vaihda kieli';
	static DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError = 'Tehtävän prioriteetin \'{0}\' poisto epäonnistui, koska tehtävän prioriteetin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ExpiringIn90Days = 'Sisällytä 90 päivässä vanhenevat koulutukset';
	static TaskRemainderCheckingAndNotificationStarted = 'Tehtävien muistutusten tarkastus ja ilmoitus aloitettu';
	static SavingOptionParameterWasNotSuccessfulConcurrencyError = 'Sovellusasetuksen \'{0}\' tallennus epäonnistui, koska sovellusasetuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static IpAddress = 'IP-osoite';
	static AddAttachementsToEvent = 'Lisää liite plus-painikkeesta.';
	static AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter = 'Palkkarivien lisäys epäonnistui! Virhe: \'{0}\'';
	static SendingEmailToParameter = 'Lähettää sähköpostia osoitteeseen \'{0}\'...';
	static ParameterPercentOfFileStorageQuotaUsed = '{0}% tiedostojen tallennustilasta käytetty';
	static AddPartnersParameterWasNotSuccessfulErrorParameter = 'Kumppanien \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static WhistleBlowInformant = 'Ilmoittaja';
	static DefaultEventHandlers = 'Oletuskäsittelijät';
	static FieldParameterConstainsIllegalCharactersForFileName = 'Kentän \'{0}\' arvo sisältää tiedostojen nimissä kiellettyjä merkkejä (<, >, :, ", /, \, |, ? tai *) tai päättyy kiellettyyn merkkiin (., \' \')!';
	static AccessRightWriteTasks = 'Tehtävien luonti ja suoritus';
	static DeletingEventPartnerParameterWasSuccessful = 'Havaintokumppanin \'{0}\' poisto onnistui!';
	static State = 'Tila';
	static OtherInformation = 'Muut tiedot';
	static CompanyThatIsRelatedToEventsCannotBeDeleted = 'Yritystä, joka on lähettänyt tai vastaanottanut havaintoja, ei voi poistaa!';
	static SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter = 'Kiireellisyysluokan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static PreviousCalendarYear = 'Edellinen kalenterivuosi';
	static SavingChartWasSuccessful = 'Kuvaajan tallennus onnistui!';
	static DeletingTaskTypesParameterWasNotSuccessfulErrorParameter = 'Tehtävätyyppien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static DoYouReallyWantToRemoveSelectedRecordFurtherEducations = 'Haluatko todella poistaa valitut jatkokoulutukset?';
	static EditDocumentTemplate = 'Muokkaa dokumenttimallia';
	static ByName = 'Nimen mukaan';
	static AddChildEventTypeC = 'Lisää alitarkenne 3';
	static ChartGenerationAborted = 'Kuvaajan luominen hylätty!';
	static OtherSettings = 'Muut asetukset';
	static DefaultHandlers = 'Oletuskäsittelijät';
	static SavingUnitsWasNotSuccessfulErrorParameter = 'Yksiköiden tallennus epäonnistui! Virhe: \'{0}\'';
	static PasswordMustContainUppercaseChar = 'Salasanan täytyy sisältää suuraakkonen!';
	static ActionIsNotAuthorized = 'Toiminto ei ole sallittu!';
	static UserGroupsMustBeDefined = 'Käyttäjäryhmät täytyy määritellä!';
	static FieldParameterValueCanOnlyContainNumbers = 'Kentän \'{0}\' arvo voi sisältää ainoastaan numeroita!';
	static WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted = 'Ilmoitusluokka \'{0}\' on käytössä {1} ilmoituksessa eikä sitä voi poistaa!';
	static SavingEventTypeParameterWasNotSuccessfulConcurrencyError = 'Tarkenteen 1 \'{0}\' tallennus epäonnistui, koska tarkenteen 1 tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static CustomStyleClass = 'Räätälöidyn tyylin luokka';
	static SavingDirectoryItemPermissionsWasSuccessful = 'Kansioiden oikeuksien tallennus onnistui!';
	static SharedReport = 'Jaettu raportti';
	static Operation = 'Operaatio';
	static RecordUserQualificationParameterNotFound = 'Henkilöstökortin pätevyysvaatimusta \'{0}\' ei löytynyt!';
	static DocumentNameParameterConstainsIllegalCharacters = 'Dokumentin nimi \'{0}\' sisältää kiellettyjä merkkejä (<, >, :, ", /, \, |, ? tai *) tai päättyy kiellettyyn merkkiin (., \' \')!';
	static ExitInstantMessageChannel = 'Poistu keskustelusta';
	static AddDefaultBasicEducation = 'Lisää oletusperuskoulutus';
	static SavingTaskPriorityParameterWasSuccessful = 'Tehtävän prioriteetin \'{0}\' tallennus onnistui!';
	static EditEventTypeB = 'Muokkaa tarkennetta 2';
	static ActiveTasks = 'Aktiivisia tehtäviä';
	static BannerPath = 'Bannerihakemisto';
	static ClearSubUnitDefaultHandlers = 'Tyhjennä aliyksiköiden oletuskäsittelijät';
	static SavingEventSettingsWasSuccessful = 'Havaintojen asetusten tallennus onnistui!';
	static CopyGroupPermissions = 'Kopioi ryhmän käyttöoikeudet muistiin';
	static JobTypeEmail = 'Sähköpostitus';
	static UnpublishingEditableDocumentParameterWasSuccessful = 'Dokumentin \'{0}\' julkaisun poisto onnistui!';
	static DoYouReallyWantToRemoveWhistleBlowParameter = 'Haluatko todella poistaa ilmoituksen \'{0}\'?';
	static DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter = 'Ilmoitusluokan \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static ModifyDateEndDate = 'Muokkauspäivä päättyen';
	static AdminMustBeDefined = 'Ylläpitäjä täytyy täytyy määritellä!';
	static SaveAsFavorite = 'Tallenna suosikiksi';
	static Handlers = 'Käsittelijät';
	static RenamingFolderParameterWasNotSuccessfulErrorParameter = 'Kansion uudelleen nimeäminen (\'{0}\' => \'{1}\') tiedostojärjestelmässä epäonnistui! Virhe: \'{2}\'';
	static MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError = 'Kansion \'{0}\' siirtäminen kansioon \'{1}\' epäonnistui, koska kansion tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SearchCondition = 'hakuehto';
	static SavingWhistleBlowWasSuccessful = 'Ilmoituksen tallennus onnistui!';
	static LogEventTime = 'Ajankohta';
	static DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError = 'Pätevyysvaatimusten \'{0}\' poisto epäonnistui, koska pätevyysvaatimusten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static NewUserMustBeSavedBeforeUserGroupsCanBeAdded = 'Uusi käyttäjä täytyy tallentaan ennen käyttäjäryhmien lisäämistä!';
	static InheritedContentFilter = '(peritty sisällön suodatus)';
	static ActiveUserTasks = 'Aktiivisia käyttäjän luomia tehtävä';
	static DeletingTaskPrioritiesParameterWasSuccessful = 'Tehtävien prioriteettien \'{0}\' poisto onnistui!';
	static TrainingStartDateEnd = 'Koulutus alkoi - loppu';
	static DocumentsNotFound = 'Dokumentteja ei löytynyt!';
	static PushEndpoint = 'Push-osoite';
	static NewUserGroupMustBeSavedBeforeUsersCanBeAdded = 'Uusi käyttäjäryhmä täytyy tallentaa ennen käyttäjien lisäämistä!';
	static ReadReceiptGivenPlur = 'Kuitatut';
	static AdministratorUserCodeMustBeDefined = 'Järjestelmänvalvojan käyttäjätunnus täytyy määritellä!';
	static AddFurtherEducation = 'Lisää jatkokoulutus';
	static LargeSize = 'Suuri koko';
	static DoYouReallyWantToMoveTaskParameterToTrashBin = 'Haluatko siirtää tehtävän \'{0}\' roskakoriin?';
	static SavingEventParameterWasNotSuccessfulConcurrencyError = 'Havainnon \'{0}\' tallennus epäonnistui, koska havainnon tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static LanguageFinnish = 'suomi';
	static DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError = 'Kiireellisyysluokkien \'{0}\' poisto epäonnistui, koska kiireellisyysluokkien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static MaxSearchResultCount = 'Haun tulosten maksimimäärä';
	static SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError = 'Dokumenttimallin \'{0}\' tallennus epäonnistui, koska dokumenttimallin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Hour = 'tunti';
	static UnitCodeIsAlreadyInUse = 'Yksikön nimi on jo käytössä!';
	static CostCentreNumber = 'Kustannuspaikan numero';
	static CopyDefaultHandlers = 'Kopioi oletuskäsittelijät';
	static ArgumentParameterIsNULL = 'Parametrin \'{0}\' arvo on NULL!';
	static SelectEventPartner = 'Valitse havaintokumppani';
	static CompanyGroupNameMustBeDefined = 'Yritysryhmän nimi täytyy määritellä!';
	static SavingUserGroupParameterWasSuccessful = 'Käyttäjäryhmän \'{0}\' tallennus onnistui!';
	static AddChildDefaultEventType = 'Lisää oletusalitarkenne 1';
	static EmploymentType = 'Työsuhdetyyppi';
	static RemoveMessage = 'Poista viesti';
	static UserInformation = 'Käyttäjätilin tiedot';
	static MessageTitleMustBeDefined = 'Viestin otsikko täytyy määritellä!';
	static DoYouReallyWantToRemoveDefaultEventHandlerParameter = 'Haluatko todella poistaa oletuskäsittelijän \'{0}\'?';
	static SavingMessageTypeParameterWasSuccessful = 'Viestityypin \'{0}\' tallennus onnistui!';
	static FileNameConstainsIllegalCharacters = 'Tiedoston nimi sisältää kiellettyjä merkkejä (<, >, :, ", /, \, |, ? tai *) tai päättyy kiellettyyn merkkiin (., \' \')!';
	static AddEventTypeC = 'Lisää tarkenne 3';
	static AddEventTypeB = 'Lisää tarkenne 2';
	static DeletingDocumentTemplateParameterWasSuccessful = 'Dokumenttimallin \'{0}\' poisto onnistui!';
	static SavingFilterWasNotSuccessfulErrorParameter = 'Suodattimen tallennus epäonnistui! Virhe: \'{1}\'';
	static View = 'Näkymä';
	static EmailsSentSuccessfully = 'Sähköpostien lähettäminen onnistui!';
	static ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError = 'Perehdytysten arkistointi epäonnistui, koska perehdytysten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static LockingTaskParameterWasNotSuccessful = 'Tehtävän \'{0}\' lukitseminen epäonnistui!';
	static UnitParameterNotFound = 'Yksikköä \'{0}\' ei löytynyt!';
	static MessageTypeCodeMustBeDefined = 'Viestityypin tunnus täytyy määritellä!';
	static DocumentModuleProperties = 'Dokumenttien asetukset';
	static AdminMessage = 'Viesti johdolle';
	static DeletingOptionParameterWasNotSuccessfulErrorParameter = 'Sovellusasetuksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static DeletingDirectoryItemDocumentParameterWasSuccessful = 'Tiedoston \'{0}\' poisto onnistui!';
	static AddAttachment = 'Lisää liite';
	static DoYouWantToPublishEvent = 'Haluatko julkaista havainnon?';
	static EventTypeCCodeIsAlreadyInUse = 'Tarkenteen 3 nimi on jo käytössä!';
	static EditUser = 'Muokkaa käyttäjätiliä';
	static SendMessage = 'Lähetä viesti';
	static UnlockingEditableDocumentParameterWasNotSuccessful = 'Dokumentin \'{0}\' lukituksen poisto epäonnistui!';
	static JobTitleMustBeDefined = 'Tehtävänimike täytyy määritellä!';
	static AddRecordUser = 'Lisää henkilöstokortti';
	static JobParameterNotFound = 'Työtä \'{0}\' ei löytynyt!';
	static DefaultDeductionWithoutTax = 'Vähennyksen oletusmäärä (ALV 0%)';
	static Location = 'Sijainti';
	static AddEventHandler = 'Lisää käsittelijä';
	static RecordUserModuleProperties = 'Henkilöstön asetukset';
	static Parameters = 'Parametrit';
	static ApproveEventHandlerConfirmation2 = 'Haluatko todella hyväksyä havainnon?';
	static RemoveLogisticVoucherCustomer = 'Poista asiakas';
	static DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter = 'Työpaikan \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static JobLineDataIsEmpty = 'Työrivin tiedot puuttuvat!';
	static PanelSize4 = '2 x 2';
	static RecordUserFamiliarizationParameterNotFound = 'Henkilöstökortin perehdytystä \'{0}\' ei löytynyt!';
	static DraftCannotBeApprovedDoYouWantToSaveDraftAnyway = 'Luonnosta ei voi hyväksyä! Haluatko tallentaa luonnoksen siitä huolimatta (hyväksyntää ei tallenneta)?';
	static AddDefaultEventType = 'Lisää oletustarkenne 1';
	static UserProfile = 'Käyttäjätilin  tiedot';
	static DeletingUserGroupUsersOfCompanyParameterWasSuccessful = 'Yritykseen \'{0}\' liittyvien käyttäjäryhmäliittymien poisto onnistui!';
	static IgnoreFolderPatterns = 'Kansioiden piilottamisessa käytettävä like-ehto';
	static AddPartner = 'Lisää kumppani';
	static DoYouReallyWantToRemoveRecordQualificationParameter = 'Haluatko todella poistaa pätevyysvaatimuksen \'{0}\'?';
	static RemoveCompanyFromCompanyGroup = 'Poista yritys yritysryhmästä';
	static DeletingEventTypeParameterWasSuccessful = 'Tarkenteen 1 \'{0}\' poisto onnistui!';
	static DeletingCompanyParameterWasNotSuccessfulConcurrencyError = 'Yrityksen \'{0}\' poisto epäonnistui, koska yrityksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Portrait = 'Pysty';
	static ApprovedEvent = 'Hyväksynyt havainnon';
	static DoYouReallyWantToReplaceDocumentTemplateParameter = 'Haluatko todella korvata dokumenttimallin \'{0}\'?';
	static ReadImpPlur = 'Luetut';
	static RemoveCompanyGroup = 'Poista yritysryhmä';
	static LogOut = 'Kirjaudu ulos';
	static UserCodeUpdateWasNotSuccessfulErrorParameter = 'Käyttäjätunnuksen päivitys epäonnistui! Virhe: \'{0}\'';
	static Target = 'Kohde';
	static BankBICCode = 'Pankin BIC-koodi';
	static RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter = 'Tehtävän \'{0}\' palauttaminen roskakorista epäonnistui! Virhe: \'{1}\'';
	static UserRecordStatisticsUserAccountConnectedCards = 'Käyttäjätiliin kytkettyjä kortteja';
	static SavingEventPartnerParameterWasSuccessful = 'Havaintokumppanin \'{0}\' tallennus onnistui!';
	static NewRow = 'Uusi rivi';
	static WhistleBlowAnonymousReport = 'Ilmoita anonyymisti';
	static Code = 'Tunnus';
	static UpdateUserGroupParameterExternalSystemsWasSuccessful = 'Käyttäjäryhmän \'{0}\' linkitys ulkoisiin järjestelmiin onnistui!';
	static Path = 'Polku';
	static EmptyingTaskTrashBinWasSuccessfulErrorParameter = 'Tehtävien roskakorin tyhjennys epäonnistui! Virhe: \'{0}\'';
	static WhistleBlowChannelMustHaveAtLeastOneAdministrator = 'Ilmoituskanavalla täytyy olla ainakin yksi ylläpitäjä!';
	static SavingKeywordParameterWasSuccessful = 'Avainsanan \'{0}\' tallennus onnistui!';
	static AddWhistleBlowChannelAdminsParameterWasSuccessful = 'Ylläpitäjien \'{0}\' lisäys onnistui!';
	static SavingInstantMessageChannelParameterWasSuccessful = 'Keskustelun \'{0}\' tallennus onnistui!';
	static SelectTaskState = 'Valitse tehtävän tila';
	static ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter = 'Asetettuja käyttöoikeuksia ei voi kopioida kansioon \'{0}\'!';
	static MoneyOut = 'Lähtevä raha';
	static SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static SearchTasks = 'Hae tehtäviä';
	static SomeInstantMessageUsersHaveReadMessage = 'Osa keskustelijoista on lukenut viestin';
	static TaskState = 'Tehtävän tila';
	static AmountOfComments = 'Kommenttien määrä';
	static MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB = 'Kerralla lisättävien tiedostojen maksimiyhteiskoko on {0} MB!';
	static FamiliarizationEndDate = 'Perehdytys päättyi';
	static MessageDateMustBeDefined = 'Viestin päiväys täytyy määritellä!';
	static GroupByMonth = 'Kuukausittain';
	static CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter = 'Tiedoston \'{0}\' olemassolon tarkistaminen epäonnistui! Virhe: \'{1}\'';
	static YouDeletedThisMessage = 'Sinä poistit tämän viestin';
	static EditRemainder = 'Muokkaa muistutusta';
	static PrintParameterDownloaded = 'Tuloste on ladattu.';
	static PushNotificationInUse = 'Haluan vastaanottaa järjestelmän lähettämiä push-ilmoituksia (eSalkku Mobile)';
	static NewPanel = 'Uusi paneli';
	static UserRecordCards = 'Henkilöstökortit';
	static ReadReceipts = 'Lukukuittaukset';
	static DoYouReallyWantToRemoveSelectedTaskStates = 'Haluatko todella poistaa valitut tehtävien tilat?';
	static WhistleBlowResolutionOther = 'Muu';
	static WhistleBlowSeverityVeryLow = 'Hyvin vähäinen';
	static SentEmails = 'Lähetetyt sähköpostit';
	static SavingWhistleBlowChannelParameterWasSuccessful = 'Ilmoituskanavan \'{0}\' tallennus onnistui!';
	static UserTypeClaimNotFound = 'Käyttäjän ominaisuutta \'käyttäjän tyyppi\' ei löytynyt!';
	static DoYouReallyWantToRemoveThisInstantMessage = 'Haluatko todella poistaa tämän pikaviestin? Huomioithan, että viesti poistetaan kaikilta keskustelijoilta.';
	static DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError = 'Ilmoituksen \'{0}\' poisto epäonnistui, koska ilmoituksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Rejected = 'Kieltäynyt';
	static SavingEventPartnerParameterWasNotSuccessfulErrorParameter = 'Havaintokumppanin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static DeletingAttachmentsParameterWasSuccessful = 'Liitteiden \'{0}\' poisto onnistui!';
	static DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError = 'Peruskoulutusten \'{0}\' poisto epäonnistui, koska peruskoulutusten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Reminders = 'Muistutukset';
	static NoMessagesToShow = 'Ei näytettäviä viestejä!';
	static Returned = 'Palautettu';
	static DeletingInstantMessageWasNotSuccessfulConcurrencyError = 'Pikaviestin poisto epäonnistui, koska pikaviestin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError = 'Keskustelun \'{0}\' tallennus epäonnistui, koska keskustelun tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static InternalAttachments = 'Sisäiset liitteet';
	static RemoveReport = 'Poista raportti';
	static DoYouReallyWantToRemoveUserGroupParameter = 'Haluatko todella poistaa käyttäjäryhmän \'{0}\'?';
	static SavingPrintWasNotSuccessfulConcurrencyError = 'Tulosteen \'{0}\' tallennus epäonnistui, koska tulosteen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static LoadMore = 'Lataa lisää';
	static Category = 'Luokka';
	static AddCompanyGroupsParameterWasSuccessful = 'Yritysryhmien \'{0}\' lisäys onnistui!';
	static AuthorizationLevelNone = 'Ei oikeuksia';
	static SavingUserGroupParameterWasNotSuccessfulConcurrencyError = 'Käyttäjäryhmän \'{0}\' tallennus epäonnistui, koska käyttäjäryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ClearSubUnitDefaultHandlersAndNotifiedPersons = 'Tyhjennä aliyksiköiden oletuskäsittelijät ja tiedotettavat henkilöt';
	static WhistleBlowChannelWasNotFoundExplanation = 'Tarkista että syötit ilmoituskanavan internet-osoitteen oikein selaimen osoiteriville.';
	static AddingEventHandlerWasNotSuccessfulErrorParameter = 'Käsittelijöiden lisäys epäonnistui! Virhe: \'{0}\'';
	static NameIsAlreadyInUse = 'Nimi on jo käytössä!';
	static Color = 'Väri';
	static DeletingFileFilterParameterWasNotSuccessfulConcurrencyError = 'Tiedostosuodattimen \'{0}\' poisto epäonnistui, koska tiedostosuodattimen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Unpublish = 'Poista julkaisu';
	static DeletingUserParametersWasSuccessful = 'Käyttäjäasetusten poisto onnistui!';
	static Schema = 'Skeema';
	static SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError = 'Työpaikan tallennus \'{0}\' epäonnistui, koska työpaikan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EditDefaultBasicEducation = 'Muokkaa oletusperuskoulutusta';
	static ExternalSystemUserCodeMustBeDefined = 'Käyttäjän tunnus muussa järjestelmässä täytyy määritellä!';
	static Yesterday = 'Eilen';
	static WhistleBlowNonAnonymousReport = 'Lähettäjän tiedot sisältävä ilmoitus';
	static SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter = 'Keskustelijan tallennus epäonnistui! Virhe: \'{0}\'';
	static DocumentTypes = 'Dokumenttityypit';
	static ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter = 'Jatkokoulutusten arkistointi epäonnistui! Virhe: \'{0}\'';
	static DocumentRootPathMustBeDefined = 'Dokumenttien juurihakemisto täytyy määritellä!';
	static WhistleBlowResolutionResolved = 'Ratkaistu';
	static RemoveEvent = 'Poista havainto';
	static EditBasicEducation = 'Muokkaa peruskoulutusta';
	static DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter = 'Tehtävien prioriteettien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static SavingFileFilterParameterWasNotSuccessfulConcurrencyError = 'Tiedostosuodattimen \'{0}\' tallennus epäonnistui, koska tiedostosuodattimen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static PermissionsForSystems = 'Järjestelmien käyttöoikeudet';
	static DraftCannotBeApproved = 'Luonnosta ei voi hyväksyä!';
	static AllWhistleBlowHandlersHaveReadMessage = 'Kaikki ilmoituksen käsittelijät ovat lukeneet viestisi';
	static TaskTypeCodeIsAlreadyInUse = 'Tehtävätyypin nimi on jo käytössä!';
	static AdditionalPermissionARecords = 'Arkistointi ja poisto';
	static UseObserverAction = 'Näytä havainnon tekijän toimenpiteet -kenttä';
	static Order = 'Järjestys';
	static EventCreator = 'Kirjaaja';
	static AddUserGroup = 'Lisää käyttäjäryhmä';
	static DeletingOneTimePasswordWasSuccessful = 'Kertakäyttöisen salasanan poisto onnistui!';
	static SendingNotificationsWasNotSuccessfulErrorParameter = 'Viestien lähetys epäonnistui! Virhe: \'{0}\'';
	static DeletingEventPartnerParameterWasNotSuccessfulErrorParameter = 'Havaintokumppanin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static UserReport = 'Käyttäjän raportti';
	static UserPasswordMustBeDefined = 'Käyttäjän salasana täytyy määritellä!';
	static DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter = 'Yritykseen \'{0}\' liittyvien käyttäjäryhmäliittymien poisto epäonnistui! Virhe: \'{1}\'';
	static CkickToChangeThePicture = 'Klikkaa vaihtaaksesi kuvaa';
	static WhistleBlowChannelWasNotFound = 'Ilmoituskanavaa ei löytynyt!';
	static AddingAddRecordUserTransferredPropertiesWasSuccessful = 'Luovutetun omaisuuden lisäys onnistui!';
	static SavingTaskStateParameterWasSuccessful = 'Tehtävän tilan \'{0}\' tallennus onnistui!';
	static CompanyCodeClaimNotFound = 'Käyttäjän ominaisuutta \'yrityksen tunnus\' ei löytynyt!';
	static AddFurtherEducations = 'Lisää jatkokoulutukset';
	static Info = 'Info';
	static AddHandlers = 'Lisää käsittelijät';
	static Date = 'Päiväys';
	static EditEditableDocument = 'Muokkaa dokumenttia';
	static AdditionalPermissionBWhistleBlow = 'Käyttöoikeuksien hallinta';
	static SavingFileFilterParameterWasNotSuccessfulErrorParameter = 'Tiedostosuodattimen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static Error = 'Virhe';
	static UserParameterHasInvitedYouToJoinInstantMessageChannelParameter = 'Käyttäjä \'{0}\' on kutsunut sinut liittymään keskusteluun';
	static FileChangesCheckingAndEmailingFinishedInParameterSeconds = 'Tiedostojen muutosten tarkastus ja lähetys sähköpostitse suoritettu (kesto: {0:N0}s, virheitä: {1}, tiedostoja: {2}, sähköposteja: {3})';
	static InstantMessageUserInvitationAcceptTypeCanInvite = 'Minut voi kutsua keskusteluun';
	static ValidPasswordMustBeDefined = 'Oikeellinen salasana täytyy määritellä!';
	static DoYouReallyWantToRemoveEventTypeParameter = 'Haluatko todella poistaa tarkenteen 1 \'{0}\'?';
	static DownloadPdf = 'Lataa Pdf';
	static EventPartnerParameterNotFound = 'Havaintokumppania \'{0}\' ei löytynyt!';
	static WhistleBlowInformantHasReadMessage = 'Ilmoituksen lähettäjä on lukenut viestisi';
	static UserIdClaimNotFound = 'Käyttäjän ominaisuutta \'id\' ei löytynyt!';
	static Other = 'Muu';
	static AddTaskType = 'Lisää tehtävätyyppi';
	static SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter = 'Oletuskäsittelijän \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static ReportGenerationFailed = 'Raportin luominen epäonnistui!';
	static AccessRightNone = 'Ei käyttöoikeutta';
	static ExternalIntraSystemUserTokenMustBeDefined = 'Käyttäjä-token eSalkku-järjestelmässä täytyy määritellä!';
	static EventTypesMustBeDefinedBeforeAddingEvents = 'Tarkenne 1 täytyy määritellä ennen kuin havaintoja voi lisätä!';
	static DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter = 'Kiireellisyysluokkien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static InstantMessagePartners = 'Pikaviestikumppanit';
	static MyFilters = 'Käyttäjän suodattimet';
	static DefineRecordUserFurtherEducation = 'Määrittele jatkokoulutus';
	static InstantMessageAttachmentFolderParameterNotFound = 'Sovelluksen pikaviestien liitteiden hakemistoa \'{0}\' ei löydy!';
	static EditCompanyGroup = 'Muokkaa yritysryhmää';
	static EventTypeMustBeDefined = 'Tarkenne 1 täytyy määritellä!';
	static RemoveEventType = 'Poista tarkenne 1';
	static AccountNumberTkbInDebit = 'Myyntisaamistili';
	static MessageTypes = 'Viestityypit';
	static WhistleBlowEventTypeAttachmentsRemoved = 'Liitteitä poistettu';
	static LoggedInUserCannotBeDeleted = 'Käyttäjä ei voi poistaa itseään!';
	static ExternalSystemCompanyTokenParameterIsAlreadyInUse = 'Yritys-token \'{0}\' on jo käytössä!';
	static ParameterIsNotValidAccountIBANNumber = '\'{0}\' ei ole oikeellinen IBAN-tilinumero!';
	static DeletingRecordFurtherEducationsParameterWasSuccessful = 'Jatkokoulutusten \'{0}\' poisto onnistui!';
	static DoYouReallyWantToRemoveDocumentTemplateParameter = 'Haluatko todella poistaa dokumenttimallin \'{0}\'?';
	static CompanyWithSysAdminAuthorizationLevelNotFound = 'Yritystä, johon on liitetty \'Järjestelmän ylläpitäjä\'-tason käyttäjäryhmä ei löytynyt!';
	static ToggleArchivedFurtherEducations = 'Näytä/piilota arkistoidut jatkokoulutukset';
	static FiscalNumber = 'Tilikausinumero';
	static SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError = 'Pätevyysvaatimuksen tallennus \'{0}\' epäonnistui, koska pätevyysvaatimuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DocumentProperties = 'Dokumentin/tiedoston ominaisuudet';
	static LastWeek = 'Viime viikolla';
	static RemovingEventHandlersWasSuccessful = 'Käsittelijöiden poisto onnistui!';
	static DeletingMetadataFromFileParameterFailed = 'Metatietojen poistaminen tiedostosta \'{0}\' epäonnistui!';
	static IndirectCosts = 'Välillinen kustannus';
	static TrainingTime = 'Koulutusaika';
	static EmailAddressMustBeValid = 'Sähköpostiosoitteen täytyy olla oikeellinen sähköpostiosoite!';
	static AddUser = 'Lisää käyttäjätili';
	static SavingInstantMessageWasSuccessful = 'Pikaviestin tallennus onnistui!';
	static FailureAudit = 'FailureAudit';
	static Approved = 'Hyväksytty';
	static FiltersStandard = 'Vakiosuodattimet';
	static User = 'Käyttäjätili';
	static CompanyToken = 'Yritys-token';
	static ShowArchived = 'Näytä arkistoidut';
	static InstantMessageChannelMustHaveAtLeastOneAdministrator = 'Keskustelululla täytyy olla ainakin yksi ylläpitäjä!';
	static AddQualificationToMultipleUserRecords = 'Lisää pätevyysvaatimus useaan henkilöstökorttiin';
	static PasteGroupPermissions = 'Liitä ryhmän käyttöikeudet muistista';
	static SavingEditableDocumentParameterWasNotSuccessfulErrorParameter = 'Dokumentin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static TaskStateParameterNotFound = 'Tehtävän tilaa \'{0}\' ei löytynyt!';
	static FileFilterCodeIsAlreadyInUse = 'Tiedostosuodattimen tunnus on jo käytössä!';
	static RemoveEventHandler = 'Poista käsittelijä';
	static IdentificationDetails = 'Yksilöinnin lisätieto';
	static ThisChannelHasBeenArchived = 'Tämä keskustelu on arkistoitu!';
	static WhistleBlowEventTypeCreated = 'Luotu';
	static NotApproved = 'Ei hyväksytty';
	static EditTransferredProperty = 'Muokkaa luovutettua omaisuutta';
	static RemoveFamiliarization = 'Poista perehdytys';
	static LanguageNonLocalEnglish = 'English';
	static SavingUserParameterWasNotSuccessfulErrorParameter = 'Käyttäjän \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static NotificationSettings = 'Ilmoitusasetukset';
	static SaveAndEmail = 'Tallenna ja sähköpostita';
	static AddWhistleBlowChannelAdminParameterWasSuccessful = 'Ylläpitäjän \'{0}\' lisäys onnistui!';
	static TaxNumber = 'Veronumero';
	static GroupByUnit = 'Yksiköittäin';
	static AddPayer = 'Lisää maksaja';
	static EmailAddressParameterIsInvalid = 'Sähköpostiosoite \'{0}\' on virheellinen!';
	static LoggedInUserAccountIsNotActive = 'Kirjautunut käyttäjätunnus ei ole aktiivinen!';
	static DeletingInstantMessageWasSuccessful = 'Pikaviestin poisto onnistui!';
	static EditTask = 'Muokkaa tehtävää';
	static DirectoryItemDocumentsNotFound = 'Tiedostoja/dokumentteja ei löytynyt!';
	static RemoveFileFilter = 'Poista tiedostosuodatin';
	static RecordUserEmploymentParameterNotFound = 'Henkilöstökortin työtehtävää \'{0}\' ei löytynyt!';
	static ExportToExcel = 'Vie Exceliin';
	static AddFolder = 'Lisää kansio';
	static UsersOfThisCompany = 'Oman yrityksen käyttäjät';
	static EventHandler = 'Käsittelijä';
	static SaveAndPublish = 'Tallenna ja julkaise';
	static TrainingEndDate = 'Koulutus päättyi';
	static ReferenceNumberPrefix = 'Viitenumeron etuliite';
	static EmailNotificationInUse = 'Haluan vastaanottaa järjestelmän lähettämiä sähköposti-ilmoituksia';
	static WhistleBlowSourcePhone = 'Puhelin';
	static RecordUserExcelQualification = 'Pätevyysvaatimukset';
	static BasicEducations = 'Peruskoulutukset';
	static SavingUserParameterParameterWasNotSuccessfulErrorParameter = 'Käyttäjäasetuksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static RemoveQualification = 'Poista pätevyysvaatimus';
	static TransportCompany = 'Kuljetusyritys';
	static DeletingCompanyParameterWasSuccessful = 'Yrityksen \'{0}\' poisto onnistui!';
	static DefaultFamiliarizations = 'Oletusperehdytykset';
	static Identifier = 'Tunniste';
	static DeletingKeywordParameterWasNotSuccessfulConcurrencyError = 'Avainsanan \'{0}\' poisto epäonnistui, koska avainsanan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static WhistleBlowPasswordConfirmed = 'Ilmoituksesi on nyt lähetetty. Voit seurata ilmoituksesi käsittelyn edistymistä klikkaamalla pääsivulla olevaa "Seuraa ilmoitusta" -painiketta.';
	static NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded = 'Uusi henkilöstökortti täytyy tallentaan ennen luovutetun omaisuuden lisäämistä!';
	static EventTypeParameterNotFound = 'Tarkennetta 1 \'{0}\' ei löytynyt!';
	static EventsExcel = 'Havainnot - Excel';
	static AllSelected = 'Kaikki';
	static AddPartnersParameterWasNotSuccessfulConcurrencyError = 'Kumppanien \'{0}\' lisäys epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter = 'Jatkokoulutusten lisäys epäonnistui! Virhe: \'{0}\'';
	static RemoveRecordUserAndKeepUser = 'Poista henkilöstökortti ja säilytä käyttäjätili';
	static SelectUserGroupOrUser = 'Valitse käyttäjäryhmä tai käyttäjätili';
	static ModifyDate = 'Muokkauspäivä';
	static DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError = 'Ilmoitusluokan \'{0}\' poisto epäonnistui, koska ilmoitusluokan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static UserToken = 'Käyttäjä-token';
	static EditableDocumentParameterNotFound = 'Dokumenttia \'{0}\' ei löytynyt!';
	static WholeHistory = 'Koko historia';
	static RestoringTaskParameterFromTrashBinWasSuccessful = 'Tehtävä \'{0}\' palautettu roskakorista!';
	static WriteMessage = 'Kirjoita viesti';
	static RemoveDefaultTaskType = 'Poista oletustehtävätyyppi';
	static VersionProperties = 'Version tiedot';
	static Publish = 'Julkaise';
	static CopyActiveUserRightsToAllSameLevelFolders = 'Kopioi asetetut käyttöoikeudet kaikkiin saman tason kansioihin';
	static Handling = 'Käsittely';
	static WhistleBlowReceiver = 'Vastaanottaja';
	static CopyDefaultHandlersToAllSameLevelUnits = 'Kopioi oletuskäsittelijät kaikkiin saman tason yksiköihin';
	static DeletingUserParameterWasNotSuccessfulConcurrencyError = 'Käyttäjätilin \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SavingMessageParameterWasSuccessful = 'Viestin \'{0}\' tallennus onnistui!';
	static SelectReceiver = 'Valitse vastaanottaja';
	static AllCompanyGroups = 'Kaikki yritysryhmät';
	static AuthorizationLevelSystemAdmin = 'Järjestelmän ylläpitäjä';
	static TaskDataRow = 'Tehtävärivi';
	static Name = 'Nimi';
	static DoYouReallyWantToRemovePrintParameter = 'Haluatko todella poistaa tulosteen \'{0}\'?';
	static MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError = 'Tehtävän \'{0}\' siirto roskakoriin epäonnistui, koska tehtävän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static CompanyGroupCodeIsAlreadyInUse = 'Yritysryhmän tunnus on jo käytössä!';
	static KeywordCodeIsAlreadyInUse = 'Avainsanan tunnus on jo käytössä!';
	static SelectRecordUsers = 'Valitse henkilöstökortit';
	static SSRSParameterReportsFetched = '{0} raporttia haettu SSRS:stä';
	static DoYouReallyWantToRemoveMessageParameter = 'Haluatko todella poistaa viestin \'{0}\'?';
	static InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel = 'Keskustelun ainoa ylläpitäjä ei voi poistua keskustelusta!';
	static RemoveUserGroupExternalSystemsWasSuccessful = 'Käyttäjäryhmien ja ulkoisten järjestelmien linkityksen poisto onnistui!';
	static NameMustBeDefined = 'Nimi täytyy määritellä!';
	static Permission = 'Käyttöoikeus';
	static ArchivingRecordUserFurtherEducationsWasSuccessful = 'Jatkokoulutusten arkistointi onnistui!';
	static ProcessedFolderParameterErrorCountParameter = '* Kansio \'{0}\' käsitelty, virheitä: {1}';
	static RelativeSpecification = 'Suhteellinen määrittely';
	static AddUsersParameterWasNotSuccessfulErrorParameter = 'Käyttäjätilien \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter = 'Tiedostojen/dokumenttien kopioiminen kansioon \'{0}\' epäonnistui! Virhe: \'{1}\'';
	static AddUsersParameterWasSuccessful = 'Käyttäjätilien \'{0}\' lisäys onnistui!';
	static TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Tehtävän prioriteetti \'{0}\' on käytössä {1} tehtävässä eikä sitä voi poistaa!';
	static CurrentWorkPlace = 'Nykyinen työpaikka';
	static JobResultParameterNotFound = 'Työtulosta \'{0}\' ei löytynyt!';
	static GetPhoto = 'Ota valokuva';
	static MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError = 'Tiedostojen/dokumenttien siirtäminen kansioon \'{0}\' epäonnistui, koska kansion tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RemoveKeyword = 'Poista avainsana';
	static DeletingRecordUserTransferredPropertiesParameterWasSuccessful = 'Luovutettujen omaisuuksien \'{0}\' poisto onnistui!';
	static InstantMessageChannelArchivingType = 'Arkistoidun keskustelun käsittely';
	static WhistleBlowSourceSystem = 'Järjestelmä';
	static WhistleBlowReportHasBeenSaved = 'Ilmoituksesi on tallennettu';
	static PrintTextPublished = 'Julkaistu';
	static Internet = 'Internet';
	static UserIsActiveInWeb = 'Käyttäjätili on aktiivinen web-sovelluksessa.';
	static AuthorizationLevelUser = 'Käyttäjä';
	static EventTypeCCodeMustBeDefined = 'Tarkenteen 3 nimi täytyy määritellä!';
	static InstantMessage = 'Pikaviesti';
	static ChangingPasswordWasNotSuccessfulConcurrencyError = 'Salasanan vaihto epäonnistui, koska käyttäjän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static InheritedUserRight = '(peritty käyttöoikeus)';
	static SaveFilterAs = 'Tallenna suodatin nimellä';
	static All = 'Kaikki';
	static EditCompanyTaskType = 'Muokkaa yrityksen tehtävätyyppiä';
	static DoYouReallyWantToRemoveSelectedUsers = 'Haluatko todella poistaa valitut käyttäjät?';
	static LockedDocumentParameterCannotBeDeleted = 'Lukittua dokumenttia \'{0}\' ei voi poistaa!';
	static BrowseFiles = 'Selaa tiedostoja';
	static SavingUserParameterParameterWasNotSuccessfulConcurrencyError = 'Käyttäjäasetuksen \'{0}\' tallennus epäonnistui, koska käyttäjäasetuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SavingEventTypeCsWasNotSuccessfulErrorParameter = 'Tarkenteen 3 tallennus epäonnistui! Virhe: \'{0}\'';
	static LogisticVoucherCustomer = 'Asiakas';
	static SelectInstantMessageChannelUsers = 'Valitse keskustelijat';
	static SavingUnitPermissionsWasNotSuccessfulErrorParameter = 'Käyttöoikeuksien tallennus yksiköille epäonnistui! Virhe: \'{0}\'';
	static LanguageEnglish = 'englanti';
	static MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful = 'Kansion \'{0}\' siirtäminen kansioon \'{1}\' onnistui!';
	static UserGroup = 'Käyttäjäryhmä';
	static Instructions = 'Ohjeita';
	static PanelSize2 = '2 x 1';
	static CompanyRole = 'Yrityksen rooli';
	static ReadingFileParameterWasNotSuccessfulErrorParameter = 'Tiedoston \'{0}\' lukeminen tiedostojärjestelmästä epäonnistui! Virhe: \'{1}\'';
	static InstantMessageChannelGroupInternal = 'Ryhmä (yrityksen sisäinen)';
	static UsedQuota = 'Käytetty tallennustila';
	static UnreadInstantMessages = 'Lukemattomia viestejä';
	static SelectCompany = 'Valitse yritys';
	static RemoveTaskPermanently = 'Poista tehtävä lopullisesti';
	static Remove = 'Poista';
	static WhistleBlowChannelParameterNotFound = 'Ilmoituskanavaa \'{0}\' ei löytynyt!';
	static LockedTaskDataRowCannotBeSaved = 'Tehtävärivi on lukittu toiselle käyttäjälle eikä sitä voi tallentaa!';
	static MessageStartDateMustBeDefined = 'Viestin näkyvissä alkaen päivä täytyy määritellä!';
	static Edit = 'Muokkaa';
	static DoYouReallyWantToRemoveCompanyParameter = 'Haluatko todella poistaa yrityksen \'{0}\'?';
	static DeletingMessageTypeParameterWasSuccessful = 'Viestityypin \'{0}\' poisto onnistui!';
	static SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter = 'Keskustelun \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static Descending = 'Laskeva';
	static SavingEventTypeParameterWasNotSuccessfulErrorParameter = 'Tarkenteen 1 \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static PasswordIsTooShort = 'Salasana on liian lyhyt!';
	static DoYouReallyWantToRemoveWhistleBlowCategoryParameter = 'Haluatko todella poistaa ilmoitusluokan \'{0}\'?';
	static ExpiringIn120Days = 'Sisällytä 120 päivässä vanhenevat koulutukset';
	static AddUserGroupsParameterWasSuccessful = 'Käyttäjäryhmien \'{0}\' lisäys onnistui!';
	static TakePicture = 'Ota valokuva';
	static Reports = 'Raportit';
	static AccessRightFull = 'Kaikki oikeudet';
	static Update = 'Päivitä';
	static TaskTypeParameterNotFound = 'Tehtävätyyppiä \'{0}\' ei löytynyt!';
	static FollowUp = 'Seuranta';
	static AddEmployments = 'Lisää työpaikat';
	static WhistleBlowSeverityNormal = 'Normaali';
	static EditUnit = 'Muokkaa yksikköä';
	static Contents = 'Sisältö';
	static ReadError = 'Lukuvirhe!';
	static AddFamiliarizationToMultipleUserRecords = 'Lisää perehdytys useaan henkilöstökorttiin';
	static RemoveInvalidDefaultEventHandlersConfirmation = 'Joillakin oletuskäsittelijöillä tai tiedotettavilla henkilöillä ei ole oikeuksia käsitellä yksikön havaintoja. Haluatko poistaa nämä oletuskäsittelijät tai tiedotettavat henkilöt automaattisesti ennen tallennusta?';
	static ResultGrouping = 'Tulosten ryhmittely';
	static DocumentContentsSearch = 'Haku dokumentin sisällöstä';
	static DeletingTaskParameterWasNotSuccessfulConcurrencyError = 'Tehtävän \'{0}\' poisto epäonnistui, koska tehtävän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SavingDefaultHandlersWasNotSuccessfulErrorParameter = 'Oletuskäsittelijöiden tallennus epäonnistui! Virhe: \'{0}\'';
	static WhistleBlowState = 'Ilmoituksen tila';
	static FileFilterParameterNotFound = 'Tiedostosuodatinta \'{0}\' ei löytynyt!';
	static DoYouReallyWantToCreateRemainderInPast = 'Haluatko todella luoda muistutuksen menneisyyteen?';
	static ExitingInstantMessageChannelWasSuccessful = 'Keskustelusta poistuminen onnistui!';
	static SharedFilters = 'Jaetut suodattimet';
	static MaximumSizeOfInstantMessageAttachmentIsParameterMB = 'Pikaviestin liitteen maksimikoko on {0} MB!';
	static Send = 'Lähetä';
	static ApproveEventHandlerConfirmation = 'Korjaavia toimenpiteitä ei ole kirjattu, haluatko silti hyväksyä havainnon?';
	static SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter = 'Ilmoitusluokan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static EditTaskPriority = 'Muokkaa tehtävän prioriteettia';
	static AutoSaveVersionHistorySize = 'Versiohistorian koko tallennuksissa';
	static SavingEventTypeBParameterWasNotSuccessfulConcurrencyError = 'Tarkenteen 2 \'{0}\' tallennus epäonnistui, koska tarkenteen 2 tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Folder = 'Kansio';
	static NotifiedPersonsShort = 'Ilmoitukset';
	static Event = 'Havainto';
	static ReadReceiptRequired = 'Kuitattavat';
	static AddDefaultTaskState = 'Lisää tehtävän oletustila';
	static AddDefaultFurtherEducation = 'Lisää oletusjatkokoulutus';
	static MessageEndDate = 'Näkyvissä päättyen';
	static WhistleBlowArchivingTypeChangeStateAndRemoveAllData = 'Siirrä ilmoitus Arkistoitu-tilaan ja säilytä vain tilastoinnissa käytettävät tiedot';
	static AttachmentParameterNotFound = 'Liitetiedostoa \'{0}\' ei löytynyt!';
	static DownloadDocument = 'Avaa/lataa valitut dokumentit/tiedostot';
	static DirectCosts = 'Välitön kustannus';
	static SaveTask = 'Tallenna tehtävä';
	static SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter = 'Jatkokoulutuksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static SavingKeywordParameterWasNotSuccessfulErrorParameter = 'Avainsanan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static SelectableWhenCreatingAnEvent = 'Valittavissa havaintoa luotaessa';
	static DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter = 'Työpaikkojen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static GroupByYear = 'Vuosittain';
	static UserParameterCodeIsAlreadyInUse = 'Käyttäjäasetuksen tunnus on jo käytössä!';
	static WhistleBlowSeverityUnspecified = 'Määrittämättä';
	static StudyWeeks = 'opintoviikkoa';
	static WhistleBlowSendReport = 'Lähetä ilmoitus';
	static NoToDos = 'Ei hoidettavia asioita';
	static UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter = 'Yrityksen linkitys ulkoisiin järjestelmiin epäonnistui! Virhe: \'{0}\'';
	static AddCompanyGroupParameterWasSuccessful = 'Yritysryhmän \'{0}\' lisäys onnistui!';
	static NoPermission = 'Ei oikeutta';
	static EventTypeCodeMustBeDefined = 'Tarkenteen nimi täytyy määritellä!';
	static AddRows = 'Lisää rivit';
	static SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError = 'Jatkokoulutuksen tallennus \'{0}\' epäonnistui, koska jatkokoulutuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError = 'Push-viestilokin tallennus viestille \'{0}\' epäonnistui, koska push-viestilokin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static TaskDataRowFieldParameterNotFoundInDataSchema = 'Tehtävärivin kenttää \'{0}\' ei löytynyt data schemasta!';
	static AddInstantMessageUserParameterWasSuccessful = 'Keskustelijan \'{0}\' lisäys onnistui!';
	static Employee = 'Työntekijä';
	static EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Tarkenne 1 \'{0}\' on käytössä {1} havainnossa eikä sitä voi poistaa!';
	static Card = 'Kortti';
	static AddingAttachmentParameterWasSuccessful = 'Liiteen \'{0}\' lisäys onnistui!';
	static CreatingFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' luominen tiedostojärjestelmään epäonnistui! Virhe: \'{1}\'';
	static PrivacyPolicyUrl = 'Tietosuojaselosteen web-osoite';
	static ReadReceiptNotGiven = 'Kuittaamatta';
	static WhistleBlowStatisticsUnreadMessages = 'Lukemattomia viestejä';
	static ArchiveExpiredFurtherEducations = 'Arkistoi vanhentuneet jatkokoulutukset';
	static AddCompanyTaskType = 'Lisää tehtävätyyppi yritykselle';
	static Default = 'Oletus';
	static MinimumPasswordLengthMustBeAtLeastParameterCharacters = 'Salasanan vähimmäispituuden täytyy olla vähintään {0} merkkiä!';
	static PanelName = 'Panelin nimi';
	static WhistleBlowEventTypeHandlersAdded = 'Käsittelijöitä lisätty';
	static MessagePreservationTime = 'Viestien säilytysaika';
	static LockedEventParameterCannotBeSaved = 'Havainto \'{0}\' on lukittu toiselle käyttäjälle eikä sitä voi tallentaa!';
	static NoFavoritesToShow = 'Ei määriteltyjä suosikkeja!';
	static AddUserToUserGroup = 'Lisää käyttäjätili käyttäjäryhmään';
	static SavingDocumentTemplateParameterWasSuccessful = 'Dokumenttimallin \'{0}\' tallennus onnistui!';
	static PublishEvent = 'Julkaise havainto';
	static SelectInstantMessageChannelUser = 'Valitse keskustelija';
	static FileFilters = 'Tiedostosuodattimet';
	static AllJobTitles = 'Kaikki tehtävänimikkeet';
	static DeletingUsersParameterWasNotSuccessfulErrorParameter = 'Käyttäjätilien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static AddWhistleBlow = 'Lisää ilmoitus';
	static SavingTaskParameterWasSuccessful = 'Tehtävän \'{0}\' tallennus onnistui!';
	static DefaultEventTypes = 'Oletustarkenteet 1';
	static DefaultEventHandlerParameterNotFound = 'Oletuskäsittelijää \'{0}\' ei löytynyt!';
	static AddTaskState = 'Lisää tehtävän tila';
	static InstantMessageChannelUser = 'Keskustelija';
	static SharedReports = 'Jaetut raportit';
	static PaymentVoucherCounter = 'Tositenumerolaskuri';
	static Admins = 'Ylläpitäjät';
	static DocumentParameterDownloaded = 'Tiedosto on ladattu.';
	static PublishTaskType = 'Julkaise tehtävätyyppi';
	static NewPassword = 'Uusi salasana';
	static DoYouReallyWantToRemoveTaskHandlerParameter = 'Haluatko todella poistaa käsittelijän \'{0}\'?';
	static RemovePermission = 'Poista käyttöoikeus';
	static TimeSpanQuarter = 'Kvartaali';
	static EmailHasBeenAlreadySentForThisMessage = 'Sähköposti on jo lähetetty tälle viestille!';
	static DeletingMessageParameterWasSuccessful = 'Viestin \'{0}\' poisto onnistui!';
	static DeletingTaskStateParameterWasNotSuccessfulConcurrencyError = 'Tehtävän tilan \'{0}\' poisto epäonnistui, koska tehtävän tilan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Qualifications = 'Pätevyysvaatimukset';
	static CostCentreName = 'Kustannuspaikan nimi';
	static SharedReportAllCompanies = 'Kaikille yrityksille jaettu raportti';
	static UserGroupCodeIsAlreadyInUse = 'Käyttäjäryhmän tunnus on jo käytössä!';
	static PrivateAndPublic = 'Yksityinen ja julkinen';
	static CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError = 'Tiedostojen/dokumenttien kopioiminen kansioon \'{0}\' epäonnistui, koska kansion tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static TaskDashboard = 'Tehtävät etusivu';
	static DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter = 'Yritysryhmien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static WhistleBlowFollowReport = 'Seuraa ilmoitusta';
	static UserCodeIsAlreadyInUse = 'Käyttäjätunnus on jo käytössä!';
	static AgencyWorker = 'Vuokratyöntekijä';
	static SavingReportWasSuccessful = 'Raportin tallennus onnistui!';
	static RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted = 'Jatkokoulutus \'{0}\' on käytössä pätevyysvaatimuksena {1} henkilöstökortissa eikä sitä voi poistaa!';
	static RemoveEventTypeB = 'Poista tarkenne 2';
	static DoYouReallyWantToRemoveDocumentsParameter = 'Haluatko todella poistaa {0} valittua dokumenttia/tiedostoa?';
	static DeletingTaskDataRowWasSuccessful = 'Tehtävärivin poisto onnistui!';
	static TitleMustBeDefined = 'Otsikko täytyy määritellä!';
	static UseInheritedContentFiltering = 'Käytä perittyä sisällön suodatusta';
	static AddRow = 'Lisää rivi';
	static MessageType = 'Viestin tyyppi';
	static UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter = 'Käyttäjäryhmän \'{0}\' linkitys ulkoisiin järjestelmiin epäonnistui! Virhe: \'{1}\'';
	static DocumentTemplateCodeIsAlreadyInUse = 'Dokumenttimallin tunnus on jo käytössä!';
	static ShowUsers = 'Näytä käyttäjätilit';
	static Orientation = 'Suunta';
	static LoggedInUser = 'Kirjautuja';
	static VersionInformation = 'Versiotiedot';
	static RecentlyUpdated = 'Päivitetty hetki sitten';
	static LanguageNonLocalSwedish = 'Svenska';
	static EditEventPartner = 'Muokkaa havaintokumppania';
	static SavingRecordUserTransferredPropertyParameterWasSuccessful = 'Luovutetun omaisuuden \'{0}\' tallennus onnistui!';
	static EditSharedDocumentTemplate = 'Muokkaa jaettua dokumenttimallia';
	static PrivacyPolicyUrlMustBeDefined = 'Tietosuojaselosteen web-osoite täytyy täytyy määritellä!';
	static MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter = 'Tiedostojen/dokumenttien siirtäminen kansioon \'{0}\' epäonnistui! Virhe: \'{1}\'';
	static DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter = 'Pätevyysvaatimuksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static EventsToApprove = 'Havaintoja hyväksyttävänä';
	static DoYouReallyWantToRemoveEventHandlerParameter = 'Haluatko todella poistaa käsittelijän \'{0}\'?';
	static SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Yritysryhmän \'{0}\' tallennus epäonnistui, koska yritysryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RememberMe = 'Muista minut';
	static SavingMessageTypeParameterWasNotSuccessfulConcurrencyError = 'Viestityypin \'{0}\' tallennus epäonnistui, koska viestityypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingRecordUserEmploymentsParameterWasSuccessful = 'Työpaikkojen \'{0}\' poisto onnistui!';
	static RecordParameterLockingFailedRecordIsWriteLockedToUserParameter = 'Tietueen \'{0}\' lukitseminen epäonnistui! Tietue on kirjoituslukittu käyttäjälle \'{0}\'!';
	static Landscape = 'Vaaka';
	static AddEditableDocument = 'Lisää dokumentti';
	static RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Peruskoulutus \'{0}\' on käytössä {1} henkilöstökortissa eikä sitä voi poistaa!';
	static RecordBasicEducationCodeIsAlreadyInUse = 'Peruskoulutuksen nimi on jo käytössä!';
	static UserRecordStatisticsArchivedCards = 'Arkistoituja kortteja';
	static EditTaskState = 'Muokkaa tehtävän tilaa';
	static PermissionToCreateEvents = 'Oikeus luoda havaintoja';
	static ActiveUserRights = 'Asetetut käyttöoikeudet';
	static DoYouReallyWantToRemoveFilterParameter = 'Haluatko todella poistaa suodattimen \'{0}\'?';
	static DriversLicenseType = 'Ajo-oikeusluokat';
	static RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter = 'Käsittelijöiden poisto epäonnistui! Virhe: \'{0}\'';
	static AddInstantMessageChannel = 'Lisää keskustelu';
	static SavingReportWasNotSuccessfulErrorParameter = 'Raportin tallennus epäonnistui! Virhe: \'{0}\'';
	static AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter = 'Keskustelijoiden \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static Resolution = 'Ratkaisu';
	static RemoveUserAndRecordUser = 'Poista käyttäjätili sekä henkilöstökortti';
	static KeywordsAndCondition = 'Kaikki valitut avainsanat';
	static EditAttachment = 'Muokkaa liitettä';
	static TaskTypeSchemaMustBeDefined = 'Tehtävätyypin skeema täytyy määritellä!';
	static SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter = 'Luovutetun omaisuuden \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static WhistleBlowSeverityCritical = 'Hyvin vakava';
	static AccessRightReadRecords = 'Oma henkilöstökortti';
	static PaymentSetting = 'Laskutusasetus';
	static AdvancePaymentReportPath = 'Ennakkomaksuraportin sijainti';
	static ReadReceiptMessage = 'Lukukuitattava viesti';
	static Others = 'Muut';
	static NotAssigned = 'Ei käsittelyssä';
	static TaskReport = 'Tehtäväraportti';
	static DeletingWhistleBlowCategoryParameterWasSuccessful = 'Ilmoitusluokan \'{0}\' poisto onnistui!';
	static AddDefaultTaskPriority = 'Lisää tehtävän oletusprioriteetti';
	static BasicEducationsMustBeDefinedBeforeAddingRecordUsers = 'Peruskoulutukset täytyy määritellä ennen kuin henkilöstökortteja voi lisätä!';
	static PrintTextPrinted = 'Tulostettu';
	static Public = 'Julkinen';
	static ByDate = 'Päiväyksen mukaan';
	static EditEmployment = 'Muokkaa työtä';
	static RecordUserFurtherEducationParameterNotFound = 'Henkilöstökortin jatkokoulutusta \'{0}\' ei löytynyt!';
	static RowInformation = 'Rivin tiedot';
	static WhistleBlowStateResolved = 'Ratkaistu';
	static EventType = 'Tarkenne 1';
	static ContactPerson = 'Yhteyshenkilö';
	static LastNameMustBeDefined = 'Sukunimi täytyy määritellä!';
	static RemoveEditableDocument = 'Poista dokumentti';
	static Clear = 'Tyhjennä';
	static DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError = 'Ilmoitusluokkien \'{0}\' poisto epäonnistui, koska ilmoitusluokkien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddEventTypeCsToEvent = 'Lisää tarkenne 3 plus-painikkeesta.';
	static TheUserDoesNotHavePermissionsForThisSystem = 'Kirjautuneella käyttäjällä ei ole oikeuksia tähän järjestelmään!';
	static Task = 'Tehtävä';
	static Units = 'Yksiköt';
	static MessageTypeClass = 'Viestin luokka';
	static AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter = 'Käsittelijöiden lisäys epäonnistui! Virhe: \'{0}\'';
	static ContentVersion = 'Sisältöversio';
	static SavingRecordUserParameterWasNotSuccessfulErrorParameter = 'Henkilöstökortin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static ImageOrPdfUpToParameterMb = 'Kuva tai PDF-tiedostot (maksimissaan {0} MB)';
	static DefaultEventSettings = 'Havaintojen oletusasetukset';
	static RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = 'Jatkokoulutus \'{0}\' on käytössä {1} henkilöstökortissa eikä sitä voi poistaa!';
	static ChartGenerationFailed = 'Kuvaajan luominen epäonnistui!';
	static TaskRepetitionType = 'Tehtävän toistuvuus';
	static PositionErrorTimeout = 'Sijaintitietoa ei löytynyt tarpeeksi nopeasti.';
	static MoneyIn = 'Saapuva raha';
	static CreateReport = 'Luo raportti';
	static DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter = 'Oletuskäsittelijän \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static TaskDataRows = 'Rivit';
	static FurtherEducations = 'Jatkokoulutukset';
	static EventApprovalWasNotSuccessfulConcurrencyError = 'Havainnon hyväksyminen epäonnistui, koska käsittelijän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static TransferredProperties = 'Luovutettu omaisuus';
	static WhistleBlowInformantInstuctions = 'Ilmoittajan ohjeet';
	static Phone = 'Puhelin';
	static Approval = 'Hyväksyntä';
	static AddInstantMessageUserParameterWasNotSuccessfulErrorParameter = 'Keskustelijan \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static LanguageSwedish = 'ruotsi';
	static FileSynchronizationToDatabaseStarted = 'Tiedostojärjestelmän synkronointi tietokantaan aloitettu';
	static DoYouReallyWantToRemoveInstantMessageChannelParameter = 'Haluatko todella poistaa keskustelun \'{0}\'?';
	static UserParameterNotFound = 'Käyttäjää \'{0}\' ei löytynyt!';
	static RecordEmailNotificationShowExpired = 'Sisällytä raporttiin jo vanhentuneet koulutukset';
	static SomeWhistleBlowHandlersHaveReadMessage = 'Osa ilmoituksen käsittelijöistä on lukenut viestisi';
	static ArchivingWhistleBlowsParameterWasSuccessful = 'Ilmoitusten \'{0}\' arkistointi onnistui!';
	static TargetDoesNotHaveFoldersWithWritePermissions = 'Kohteessa ei ole kansioita, joihin on muokkausoikeudet!';
	static RemoveNotifiedPerson = 'Poista tiedotettava henkilö';
	static FileParameterIsEmpty = 'Tiedosto \'{0}\' on tyhjä!';
	static WhistleBlowEventTypeMessageAdded = 'Viesti lisätty';
	static AccessRightWriteRecords = 'Kaikki yksikön henkilöstökortit';
	static WhistleBlowDoesNotContainAnyMessage = 'Ilmoitukseen ei ole vielä kirjoitettu viestejä';
	static NewCompanyInformation = 'Uusi yritys';
	static SaveToTemplate = 'Tallenna malliksi';
	static DeletingTaskParameterWasNotSuccessfulErrorParameter = 'Tehtävän \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static PublicationOfEditableDocumentCannotBeRemoved = 'Dokumentin julkaisua ei voi poistaa!';
	static MinimumPasswordLength = 'Salasanan vähimmäispituus';
	static SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter = 'Dokumentin \'{0}\' hyväksyntä epäonnistui! Virhe: \'{1}\'';
	static DeletingInstantMessageWasNotSuccessfulErrorParameter = 'Pikaviestin poisto epäonnistui! Virhe: \'{0}\'';
	static LastYear = 'Viime vuosi';
	static EventSettings = 'Havaintojen asetukset';
	static AnyTime = 'Milloin tahansa';
	static NoEmailsToSend = 'Ei lähetettäviä sähköposteja!';
	static GroupByHalfYear = 'Puolivuosittain';
	static NextOfKin = 'Lähiomainen';
	static UploadDocument = 'Lisää tiedosto';
	static AddPhotosAndAttachementsToEvent = 'Lisää valokuva tai liite kamera tai plus-painikkeesta.';
	static SavingDirectoryItemDocumentParameterWasSuccessful = 'Tiedoston \'{0}\' tallennus onnistui!';
	static PageNameMustBeDefined = 'Sivun nimi täytyy täytyy määritellä!';
	static SelectRecordUserTypeToBeCreated = 'Valitse luotavan henkilöstökorin tyyppi';
	static AdditionalPermissionAInstantMessages = 'Käyttöoikeuksien hallinta';
	static NoAddedEventTypes = 'Ei lisättyjä tarkenteita!';
	static WhistleBlow = 'Whistleblower';
	static IncludeArchivedFurtherEducations = 'Sisällytä arkistoidut koulutukset';
	static ValidityEndDateExpirationTime = 'Voimassaolon vanhenemisaika';
	static Education = 'Koulutus';
	static Attachments = 'Liitteet';
	static SavingEventMessageWasSuccessful = 'Kommentin tallennus onnistui!';
	static Months = 'Kuukautta';
	static WhistleBlowDescription = 'Tapahtuman kuvaus';
	static WhistleBlowResolutionTypeMustBeDefined = 'Aseta ratkaisun tyyppi ja kirjaa tarvittaessa kuvaus!';
	static NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded = 'Uusi ilmoitus täytyy tallentaan ennen rivien lisäämistä!';
	static AddUserParameterWasSuccessful = 'Käyttäjätilin \'{0}\' lisäys onnistui!';
	static OldPassword = 'Vanha salasana';
	static IncludeOnlyActiveUsers = 'Sisällytä vain henkilöt, joiden käyttäjätunnus ja työsuhde on voimassa';
	static CopyOrMoveFolder = 'Kopioi tai siirrä kansio';
	static FurtherEducation = 'Jatkokoulutus';
	static RemoveDefaultTaskState = 'Poista tehtävän oletustila';
	static ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter = 'Perehdytysten arkistointi epäonnistui! Virhe: \'{0}\'';
	static WhistleBlowChannelPageNameCodeIsAlreadyInUse = 'Ilmoituskanavan sivun nimi on jo käytössä!';
	static SavingUnitsWasNotSuccessfulConcurrencyError = 'Yksiköiden tallennus epäonnistui, koska yksiköiden tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Banner = 'Banneri';
	static PreventiveAction = 'Ennaltaehkäisevä toimenpide';
	static SetUnitPermissions = 'Aseta yksiköiden käyttöoikeudet';
	static InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter = 'Keskustelun \'{1}\' käyttö ei ole sallittu käyttäjälle {0}!';
	static Total = 'Yhteensä';
	static RecordUserLogEntryTypePrint = 'Tulostus';
	static RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError = 'Käyttäjäryhmien ja ulkoisten järjestelmien linkityksen poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SavingEventTypeBsWasNotSuccessfulErrorParameter = 'Tarkenteen 2 tallennus epäonnistui! Virhe: \'{0}\'';
	static RecordUserParameterNotFound = 'Henkilöstökorttia \'{0}\' ei löytynyt!';
	static SourceDescription = 'Lähteen kuvaus';
	static ParameterResults = '({0} tulosta)';
	static Camera = 'Kamera';
	static UserNotAllowedToViewNotifier = 'Rajoitettu käsittelyoikeus: Käyttäjällä ei oikeutta ilmoittajan näkymään.';
	static EmployeeTypeMustBeDefined = 'Työntekijätyyppi täytyy määritellä!';
	static DeletingMessageParameterWasNotSuccessfulConcurrencyError = 'Viestin \'{0}\' poisto epäonnistui, koska viestin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingUserGroupsParameterWasNotSuccessfulErrorParameter = 'Käyttäjäryhmien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static ExpiredFamiliarizations = 'Vanhentuneet perehdytykset';
	static AllUserGroups = 'Kaikki käyttäjäryhmät';
	static SavingRecordUserParameterWasSuccessful = 'Henkilöstökortin \'{0}\' tallennus onnistui!';
	static EmailAddress = 'Sähköposti';
	static IncludeArchivedFamiliarizations = 'Sisällytä arkistoidut perehdytykset';
	static TaskMustBeSavedBeforeNewDataRowsCanBeAdded = 'Tehtävä täytyy tallentaa ennen rivien lisäämistä!';
	static RecordUserTrangerredPropertyParameterNotFound = 'Luovutettua omaisuutta \'{0}\' ei löytynyt!';
	static RemoveTaskState = 'Poista tehtävän tila';
	static AddingAttachmentsParameterWasSuccessful = 'Liitteiden \'{0}\' lisäys onnistui!';
	static PasswordConfirmationDoesNotMatchPassword = 'Salasanan vahvistus ei ole sama kuin salasana!';
	static DeletingFileFilterParameterWasSuccessful = 'Tiedostosuodattimen \'{0}\' poisto onnistui!';
	static RemovePayer = 'Poista maksaja';
	static NoneSelected = 'Ei valittuja';
	static UpdateUserParameterExternalSystemsWasSuccessful = 'Käyttäjän \'{0}\' linkitys ulkoisiin järjestelmiin onnistui!';
	static SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError = 'Tehtävän prioriteetin \'{0}\' tallennus epäonnistui, koska tehtävän prioriteetin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DefaultBasicEducations = 'Oletusperuskoulutukset';
	static TiedostoturvaUseType = 'Tiedostoturva';
	static OptionCodeIsAlreadyInUse = 'Sovellusasetuksen tunnus \'{0}\' on jo käytössä!';
	static ConfirmPassword = 'Vahvista salasana';
	static Repeated = 'Toistuva';
	static RemoveComment = 'Poista kommentti';
	static ActivePlural = 'Aktiiviset';
	static MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB = 'Kerralla lisättävien liitteiden maksimiyhteiskoko on {0} MB!';
	static InstantMessageUserInvitationAcceptTypeCannotInvite = 'Minua ei voi kutsua keskusteluun';
	static SavingUnitsWasSuccessful = 'Yksiköiden tallennus onnistui!';
	static DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter = 'Dokumenttimallin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static Sending = 'Lähettää...';
	static UserIsActiveInMobile = 'Käyttäjätili on aktiivinen mobiilisovelluksessa.';
	static Search = 'Hae';
	static FolderMustBeEmptyBeforeItCanBeRemoved = 'Poistettavan kansion täytyy olla tyhjä!';
	static Approve = 'Hyväksy';
	static EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Tarkenteen 3 \'{0}\' alitarkenteita on käytössä {1} havainnossa eikä tarkennetta voi poistaa!';
	static FileSynchronizationToDatabaseFinishedInParameterSeconds = 'Tiedostojärjestelmän synkronointi tietokantaan suoritettu (kesto: {0:N0}s, virheitä: {1}, hakemistoja: {2}, virheettömiä hakemistoja: {3})';
	static DefaultEventHandlersAndNotifiedPersons = 'Oletuskäsittelijät ja ilmoitukset';
	static YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType = 'Et ole tallentanut tekemiäsi muutoksia. Tallenna muutokset ennen tehtävätyypin julkaisua.';
	static AdditionalInformation = 'Lisätiedot';
	static AddingTaskTypeParameterWasSuccessful = 'Tehtävätyypin \'{0}\' lisäys onnistui!';
	static DefaultFurtherEducations = 'Oletusjatkokoulutukset';
	static AddDefaultTaskType = 'Lisää oletustehtävätyyppi';
	static EditLogisticVoucherCustomer = 'Muokkaa asiakasta';
	static DeletingUserGroupParameterWasNotSuccessfulErrorParameter = 'Käyttäjäryhmän \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static RemoveDocument = 'Poista valitut dokumentit/tiedostot';
	static InstantMessageChannelUsersNotUsable = 'Käyttäjä ei ole enää mukana keskustelussa';
	static DoYouReallyWantToRemovePartnerParameter = 'Haluatko todella poistaa kumppanin \'{0}\'?';
	static SavingPrintWasSuccessful = 'Tulosteen tallennus onnistui!';
	static AccountNumberTkbOutCredit = 'Ostotili';
	static DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter = 'Jatkokoulutuksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static Title = 'Otsikko';
	static ApprovalComment = 'Hyväksymiskommentti';
	static NewCompanyMustBeSavedBeforePartnersCanBeAdded = 'Uusi yritys täytyy tallentaan ennen kumppanien lisäämistä!';
	static DeletingEditableDocumentParameterWasSuccessful = 'Dokumentin \'{0}\' poisto onnistui!';
	static EditDefaultTaskPriority = 'Muokkaa tehtävän oletusprioriteettia';
	static ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers = 'Käyttäjän muutokset johtavat tilanteeseen, jossa joillakin oletuskäsittelijöillä tai tiedotettavilla henkilöillä ei ole enää oikeuksia käsitellä yksikön havaintoja! Muutokset, jotka vaikuttavat oletuskäsittelijöihin tai tiedotettaviin henkilöihin, voi suorittaa ainoastaan Havaintojen asetukset\'-lomakkeessa!';
	static VersionHistory = 'Versiohistoria';
	static EditDefaultEventType = 'Muokkaa oletustarkennetta 1';
	static EditDefaultTaskType = 'Muokkaa oletustehtävätyyppiä';
	static PageName = 'Sivun nimi';
	static EditLogisticVoucherCompanyPaymentSetting = 'Muokkaa laskutusasetusta';
	static AddingTaskTypesParameterWasSuccessful = 'Tehtävätyyppien \'{0}\' lisäys onnistui!';
	static WhistleBlowChannelSettings = 'Ilmoituskanavan asetukset';
	static FileNameMustBeDefined = 'Tiedoston nimi täytyy määritellä!';
	static EInvoiceAddress = 'Verkkolaskuosoite';
	static SavingDefaultHandlersWasNotSuccessfulConcurrencyError = 'Oletuskäsittelijöiden tallennus epäonnistui, koska oletuskäsittelijöitä on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddDefaultEventHandler = 'Lisää oletuskäsittelijä';
	static LockedDocumentsParameterCannotBeMoved = 'Lukittuja dokumentteja \'{0}\' ei voi siirtää!';
	static OptionParameterNotFound = 'Sovellusasetusta \'{0}\' ei löytynyt!';
	static MessageUnit = 'Julkaisija yksikkö';
	static CommentMustNotBeEmpty = 'Kommentti ei saa olla tyhjä!';
	static AddFileOrTakePicture = 'Lisää tiedosto tai ota kuva';
	static WhistleBlowResolutionJunk = 'Roskaposti';
	static Loading = 'Lataa...';
	static PrintRow = 'Tulosta rivi';
	static Logo = 'Logo';
	static DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError = 'Yritykseen \'{0}\' liittyvien käyttäjäryhmäliittymien poisto epäonnistui, koska käyttäjäryhmäliittymän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Summary = 'Yhteenveto';
	static DeletingTaskParameterWasSuccessful = 'Tehtävän \'{0}\' poisto onnistui!';
	static Communication = 'Viestintä';
	static DataTransferError = 'Tiedonsiirtovirhe!';
	static AddCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Yritysryhmän \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static ValidityEndDateStart = 'Voimassa päättyen - alku';
	static DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError = 'Työpaikan \'{0}\' poisto epäonnistui, koska työpaikan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ExpiringIn180Days = 'Sisällytä 180 päivässä vanhenevat koulutukset';
	static LoggedInCompanyCannotBeDeleted = 'Yritys ei voi poistaa itseään!';
	static LogSizeInDays = 'Lokin koko päivinä (0 = ei rajoitusta)';
	static RemoveUserAndRecordUserArchive = 'Poista käyttäjätili ja arkistoi henkilöstökortti';
	static UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError = 'Käyttäjän \'{0}\' linkitys ulkoisiin järjestelmiin epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Warning = 'Varoitus';
	static Employments = 'Työpaikat';
	static LoginAddress = 'Kirjautumisosoite';
	static LoginTitle = 'Kirjaudu PKY-LAATU -Intraan';
	static OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater = 'Ainoastaan pdf ja kuva-tyyppiset liitteet on sallittu! Virheelliset liitteet \'{0}\'.';
	static LockedTaskDataRowCannotBeDeleted = 'Lukittua tehtäväriviä ei voi poistaa!';
	static ReportGenerationAborted = 'Raportin luominen hylätty!';
	static DeletingEventParameterWasNotSuccessfulConcurrencyError = 'Havainnon \'{0}\' poisto epäonnistui, koska havainnon tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Tarkenne 3 \'{0}\' on käytössä {1} havainnossa eikä sitä voi poistaa!';
	static PanelSize = 'Panelin koko';
	static ApproveEvent = 'Hyväksy havainto';
	static RecordUserExcelUserList = 'Henkilöstölistaus';
	static AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter = 'Luovutetun omaisuuden lisäys epäonnistui! Virhe: \'{0}\'';
	static InstantMessageParameterNotFound = 'Pikaviestiä \'{0}\' ei löytynyt!';
	static AddBasicEducation = 'Lisää peruskoulutus';
	static JobTypeMessageNotification = 'Viestin ilmoitus';
	static SendingEmails = 'Lähettää sähköposteja...';
	static CopyingFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' kopioiminen tiedostojärjestelmässä kansioon \'{1}\' epäonnistui! Virhe: \'{2}\'';
	static DeletingRecordBasicEducationParameterWasSuccessful = 'Peruskoulutuksen \'{0}\' poisto onnistui!';
	static DocumentVersionParameterNotFound = 'Dokumentin versiota \'{0}\' ei löytynyt!';
	static AddWhistleBlowCategory = 'Lisää ilmoitusluokka';
	static RemoveInstantMessageChannelUser = 'Poista keskustelija';
	static SavingImagesWasSuccessful = 'Kuvien tallennus onnistui!';
	static RecordFamiliarizationCodeIsAlreadyInUse = 'Perehdytyksen nimi on jo käytössä!';
	static SavingEventTypeCParameterWasNotSuccessfulErrorParameter = 'Tarkenteen 3 \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static TargetsMyCompany = 'Näkyvyys - oma yritys';
	static UpdatingApplication = 'Päivitetään sovellusta...';
	static RecordFurtherEducationCodeIsAlreadyInUse = 'Jatkokoulutuksen nimi on jo käytössä!';
	static AddRecordUserAndUserAccount = 'Lisää henkilöstökortti ja muodosta käyttäjätili';
	static EventTypeCTitle = 'Havainnon yksilöinnin tarkenne 3';
	static TaskStateCodeIsAlreadyInUse = 'Tehtävän tilan nimi on jo käytössä!';
	static Anonymity = 'Anonymiteetti';
	static AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter = 'Pätevyysvaatimusten lisäys epäonnistui! Virhe: \'{0}\'';
	static InstantMessageChannelInformation = 'Keskustelun tiedot';
	static DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError = 'Keskustelijan \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError = 'Yritysryhmien \'{0}\' lisäys epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter = 'Tietueen \'{0}\' lukituksen poisto epäonnistui! Tietue on kirjoituslukittu käyttäjälle \'{0}\'!';
	static UnitProperties = 'Yksikön ominaisuudet';
	static JobDataIsEmpty = 'Työn tiedot puuttuvat!';
	static Amount = 'Määrä';
	static WhistleBlowDateAndTimeCannotBeInFuture = 'Tapahtuma-aika ei voi olla tulevaisuudessa!';
	static SavingTaskTypeParameterWasSuccessful = 'Tehtävätyypin \'{0}\' tallennus onnistui!';
	static PasswordMustBeDefined = 'Salasana täytyy määritellä!';
	static TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Tehtävän tila \'{0}\' on käytössä {1} tehtävässä eikä sitä voi poistaa!';
	static UserAccountConnected = 'Käyttäjätili kytketty';
	static YouHaveUnapprovedChangesDoYouWantApproveChanges = 'Et ole hyväksynyt tekemiäsi muutoksia. Hyväksytäänkö muutokset?';
	static DeletingTaskTypeParameterWasNotSuccessfulErrorParameter = 'Tehtävätyypin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static EventReceiverIsPartnerCompany = 'Havainnon vastaanottaja on kumppaniyritys';
	static ProcessingTimeDays = 'Käsittelyaika (p)';
	static ClosedPlural = 'Suljetut';
	static AdditionalPermissionBEventsDialog = 'Havainnon poisto';
	static PublishVoucherInOutReportToFileSystem = 'Julkaise koontitosite tiedostojärjestelmään';
	static AddInstantMessageUsersParameterWasSuccessful = 'Keskustelijoiden \'{0}\' lisäys onnistui!';
	static AdminMustBeDefinedForSectionParameter = 'Ylläpitäjä täytyy määritellä osiolle \'{0}\'!';
	static SavingMessageParameterWasNotSuccessfulConcurrencyError = 'Viestin \'{0}\' tallennus epäonnistui, koska viestin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RecordUserActiveStateChangePermissionDeniedInUnitParameter = 'Arkistoitu-tilan muuttaminen ei ole sallittu yksikössä \'{0}\'!';
	static DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter = 'Perehdytyksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static Year = 'Vuosi';
	static CourseCredits = 'opintopistettä';
	static SelectUserGroup = 'Valitse käyttäjäryhmä';
	static DeletingDirectoryItemFolderParameterWasSuccessful = 'Kansion \'{0}\' poisto onnistui!';
	static SelectTaskType = 'Valitse tehtävän tyyppi';
	static DocumentTemplateCodeMustBeDefined = 'Dokumenttimallin tunnus täytyy määritellä!';
	static ServiceFeePercent = 'Palveluprovisio (%)';
	static SearchFurtherEducations = 'Hae jatkokoulutuksia';
	static PermissionDeniedForOperation = 'Käyttäjällä ei ole oikeuksia kyseiseen toimintoon!';
	static DeletingRecordQualificationParameterWasSuccessful = 'Pätevyysvaatimuksen \'{0}\' poisto onnistui!';
	static Preview = 'Esikatselu';
	static UpdatingDatabase = 'Päivittää tietokantaa...';
	static Restore = 'Palauta';
	static SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError = 'Perehdytyksen tallennus \'{0}\' epäonnistui, koska perehdytyksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static UserName = 'Käyttäjätunnus';
	static TaskTypeNameMustBeDefined = 'Tehtävätyypin nimi täytyy määritellä!';
	static SavingEventTypeCParameterWasSuccessful = 'Tarkenteen 3 \'{0}\' tallennus onnistui!';
	static RequestValidationErrorOnFieldParameterWithValueParameter = 'Kentän \'{0}\' arvo \'{1}\' sisältää web-sovelluksissa vaaralliseksi tulkittua sisältöä!';
	static WhistleBlowMessageHasNotBeenSentCloseConfirmation = 'Keskeneräistä viestiä ole vielä lähetetty. Haluatko sulkea lomakkeen?';
	static SelectCustomer = 'Valitse asiakas';
	static Payer = 'Maksaja';
	static EventTypeCParameterNotFound = 'Tarkennetta 3 \'{0}\' ei löytynyt!';
	static ExternalSystemUserTokenParameterIsAlreadyInUse = 'Käyttäjä-token \'{0}\' on jo käytössä!';
	static CreatingFileParameterWasNotSuccessfulErrorParameter = 'Tiedoston \'{0}\' luominen tiedostojärjestelmään epäonnistui! Virhe: \'{1}\'';
	static AccessRightWriteInstantMessages = 'Yrityksen sisäiset ja yritysten väliset keskustelut';
	static Description = 'Kuvaus';
	static DocumentPublished = 'Julkaisija';
	static AddUnit = 'Lisää yksikkö';
	static EditableDocumentVersionMustBeDefined = 'Dokumentin versionumero täytyy määritellä!';
	static AttachmentIsEmpty = 'Liitetiedosto \'{0}\' on tyhjä!';
	static FolderNameIsTooLong = 'Kansion nimi \'{0}\' on liian pitkä!';
	static Time = 'Aika';
	static CopyPermissionToAllSameLevelUnits = 'Kopioi käyttöoikeus kaikkiin saman tason yksiköihin';
	static Payee = 'Maksun saaja';
	static SelectEventTypeB = 'Valitse tarkenne 2';
	static SelectCompanyGroupOrCompany = 'Valitse yritysryhmä tai yritys';
	static Message = 'Viesti';
	static TaskCreated = 'Laatija';
	static EmploymentEndDateCannotPrecedeEmploymentStartDate = 'Työsuhde päättyi -päivä ei voi olla aikaisemmin kuin työsuhde alkoi -päivä!';
	static DoYouReallyWantToUnlockEditableDocument = 'Haluatko todella poistaa dokumentin \'{0}\' lukituksen?';
	static NoImagesToShow = 'Ei näytettäviä kuvia';
	static WhistleBlowGuide = 'Ohje ilmoittamiseen';
	static TaskModuleProperties = 'Tehtävien asetukset';
	static TrashBin = 'Roskakori';
	static TimeSpanTwoYears = 'Kaksi vuotta';
	static ExternalsSystemParameterNotFound = 'Ulkoista järjestelmää \'{0}\' ei löytynyt!';
	static SavingJobParameterWasSuccessful = 'Työn \'{0}\' tallennus onnistui!';
	static TypeMustBeDefined = 'Tyyppi täytyy määritellä!';
	static TransferredPropertyType = 'Luovutetun omaisuuden tyyppi';
	static SaveUserFurtherEducationConfirmation = 'Haluatko tallentaa jatkokoulutuksen?';
	static AddUserGroupsParameterWasNotSuccessfulErrorParameter = 'Käyttäjäryhmien \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static EmploymentStartDate = 'Työsuhde alkoi';
	static RemoveSharedDocumentTemplate = 'Poista jaettu dokumenttimalli';
	static DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords = 'Huom! Versiohistorian pienentäminen poistaa aiemmin tallennettuja versioita versiohistoriasta.';
	static SavingEventDefaultHandlerParameterWasSuccessful = 'Oletuskäsittelijän \'{0}\' tallennus onnistui!';
	static NewInstantMessageChannel = 'Uusi keskustelu';
	static WhistleBlowReportHasBeenSavedTopText = 'Tallenna alla oleva salasana ja säilytä sitä turvallisesti. Salasana antaa pääsyn tallennettuun ilmoitukseesi. Älä jaa salasanaa kenenkään kanssa!';
	static Days = 'päivää';
	static DeletingInstantMessageUsersParameterWasSuccessful = 'Keskustelijoiden \'{0}\' poisto onnistui!';
	static CopyDocumentsFromCompany = 'Kopioi dokumentit yritykseltä';
	static RemoveUser = 'Poista käyttäjätili';
	static SavingSettingsWasSuccessful = 'Asetusten tallennus onnistui!';
	static EventHandlerParameterNotFound = 'Käsittelijää \'{0}\' ei löytynyt!';
	static ApplicationTempPathParameterNotFound = 'Sovelluksen temp-hakemistoa \'{0}\' ei löydy!';
	static DeletingEventTypeParameterWasNotSuccessfulConcurrencyError = 'Tarkenteen 1 \'{0}\' poisto epäonnistui, koska tarkenteen 1 tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static MessageTypeParameterNotFound = 'Viestityyppiä \'{0}\' ei löytynyt!';
	static SSRSRefreshStarted = 'SSRS virkistys aloitettu';
	static ValueAddedTaxPercent = 'Arvonlisävero %';
	static MaximumPasswordLengthCanParameterCharacters = 'Salasanan enimmäispituus saa olla korkeintaan {0} merkkiä!';
	static DeletingPartnersParameterWasNotSuccessfulErrorParameter = 'Kumppanien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter = 'Ylläpitäjän \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static FileParameterNotFound = 'Tiedostoa \'{0}\' ei löytynyt!';
	static InstantMessageChannelOneUser = 'Kahdenvälinen keskustelu';
	static DeletingWhistleBlowChannelAdminParameterWasSuccessful = 'Ylläpitäjän \'{0}\' poisto onnistui!';
	static TaskPrioritiesMustBeDefinedBeforeAddingTasks = 'Tehtävien prioriteetit täytyy määritellä ennen kuin tehtäviä voi lisätä!';
	static LogModuleProperties = 'Lokin asetukset';
	static EditableDocumentPublishUnapprovedConfirmation = 'Haluatko julkaista hyväksymättömän dokumentin tiedostoksi?';
	static SelectEventHandler = 'Valitse käsittelijä';
	static TargetCompany = 'Kohdeyritys';
	static ChangeSystemLanguageToSwedish = 'Vaihda kieli ruotsiksi';
	static Group = 'Ryhmä';
	static MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB = 'Kerralla lisättävien dokumenttien maksimiyhteiskoko on {0} MB!';
	static RecordEmailNotificationType = 'Haluan vastaanottaa yrityksen jatkokoulutuksien raportin (vanhenevat koulutukset)';
	static SelectArchivingMethod = 'Valitse arkistoinnin tapa';
	static InstantMessageChannel = 'Keskustelu';
	static DeletingAttachmentParameterWasNotSuccessfulErrorParameter = 'Liiteen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static RemoveRecordUserAndUser = 'Poista henkilöstökortti ja käyttäjätili';
	static Company = 'Yritys';
	static AddEventMessage = 'Lisää kommentti';
	static SavingTaskPriorityParameterWasNotSuccessfulErrorParameter = 'Tehtävän prioriteetin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter = 'Haluatko todella poistaa luovutetun omaisuuden \'{0}\'?';
	static FirstNameMustBeDefined = 'Etunimi täytyy määritellä!';
	static EditWhistleBlowChannel = 'Muokkaa ilmoituskanavaa';
	static LogLine = 'Lokirivi';
	static RenamingFileParameterWasNotSuccessfulErrorParameter = 'Tiedoston uudelleen nimeäminen (\'{0}\' => \'{1}\') tiedostojärjestelmässä epäonnistui! Virhe: \'{2}\'';
	static AddPartners = 'Lisää kumppanit';
	static SenderOrReceiver = 'Lähettäjä tai vastaanottaja';
	static AddFamiliarization = 'Lisää perehdytys';
	static PreviewPanel = 'Esikatselu';
	static DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError = 'Perehdytyksen \'{0}\' poisto epäonnistui, koska perehdytyksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers = 'Käyttäjäryhmän muutokset johtavat tilanteeseen, jossa joillakin oletuskäsittelijöillä tai tiedotettavilla henkilöillä ei ole enää oikeuksia käsitellä yksikön havaintoja! Muutokset, jotka vaikuttavat oletuskäsittelijöihin tai tiedotettaviin henkilöihin, voi suorittaa ainoastaan \'Havaintojen asetukset\'-lomakkeessa!';
	static EditFamiliarization = 'Muokkaa perehdytystä';
	static RemoveTaskHandler = 'Poista käsittelijä';
	static BirthDate = 'Syntymäaika';
	static No = 'Ei';
	static AddTaskPriority = 'Lisää tehtävän prioriteetti';
	static RecordUserExcelFurtherEducation = 'Jatkokoulutukset';
	static RemoveEventPartner = 'Poista havaintokumppani';
	static Documents = 'Dokumentit';
	static Comments = 'Kommentit';
	static Go = 'Mene';
	static DeletingRecordUserParameterWasNotSuccessfulConcurrencyError = 'Henkilöstökortin \'{0}\' poisto epäonnistui, koska henkilöstökortin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Sender = 'Lähettäjä';
	static Close = 'Sulje';
	static CurrentTopics = 'Ajankohtaista';
	static DeletingUserParameterWasSuccessful = 'Käyttäjätilin \'{0}\' poisto onnistui!';
	static CopyingFileParameterWasNotSuccessfulErrorParameter = 'Tiedoston \'{0}\' kopioiminen tiedostojärjestelmässä tiedostoksi \'{1}\' epäonnistui! Virhe: \'{2}\'';
	static CopyOrMoveFilesDocuments = 'Kopioi tai siirrä valitut tiedostot/dokumentit';
	static RemoveFurtherEducation = 'Poista jatkokoulutus';
	static PrintGenerationFailed = 'Tulosteen luominen epäonnistui!';
	static SavingTaskTypeParameterWasNotSuccessfulConcurrencyError = 'Tehtävätyypin \'{0}\' tallennus epäonnistui, koska tehtävätyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RemoveUnit = 'Poista yksikkö';
	static SortOrder = 'Järjestysnumero';
	static FamiliarizationStartDateStart = 'Perehdytys alkoi - alku';
	static RecordUserWithSameNameAlreadyExists = 'Henkilöstökortti samalla nimellä on jo olemassa!';
	static RemoveAttachment = 'Poista liite';
	static JobTypePushNotification = 'Push-ilmoitus';
	static AddComment = 'Lisää kommentti';
	static EventModuleProperties = 'Havaintojen asetukset';
	static AccessRightReadWhistleBlow = 'Rajattu käsittely';
	static DoYouReallyWantToRemoveSelectedTaskPriorities = 'Haluatko todella poistaa valitut tehtävien prioriteetit?';
	static NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded = 'Uusi perehdytys täytyy tallentaan ennen liitteiden lisäämistä!';
	static SelectImageFile = 'Valitse kuvatiedosto!';
	static AddCompanyGroupsParameterWasNotSuccessfulErrorParameter = 'Yritysryhmien \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static DeletingInstantMessageChannelWasNotSuccessfulErrorParameter = 'Keskustelun poisto epäonnistui! Virhe: \'{0}\'';
	static Created = 'Luotu';
	static WhistleBlowFrontPage = 'Etusivu';
	static Published = 'Julkaistu';
	static CompanyGroups = 'Yritysryhmät';
	static VersionParameterPublishedAsPdfFile = 'Versio {0} julkaistu pdf-tiedostoksi';
	static ModifiedDescription = 'Muokannut kuvausta';
	static TheUserNameOrPasswordIsIncorrect = 'Virheellinen käyttäjätunnus tai salasana!';
	static DeletingEventUrgencyClassParameterWasSuccessful = 'Kiireellisyysluokan \'{0}\' poisto onnistui!';
	static DeletingEventParameterWasNotSuccessfulErrorParameter = 'Havainnon \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static DoYouReallyWantToRemoveEventParameter = 'Haluatko todella poistaa havainnon \'{0}\'?';
	static MyReports = 'Käyttäjän raportit';
	static IndefinitelyNoLocalization = 'Toistaiseksi';
	static ExternalSystems = 'Muut järjestelmät';
	static SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError = 'Kansioiden oikeuksien tallennus epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Wage = 'Palkka';
	static Publisher = 'Julkaisija';
	static Previous = 'Edellinen';
	static DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError = 'Viestityypin \'{0}\' poisto epäonnistui, koska viestityypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EmailSent = 'Sähköposti lähetetty';
	static WhistleBlowInformantMustBeNotifiedLatestAtParameter = 'Ilmoitus täytyy merkitä ratkaistuksi viimeistään {0}!';
	static RemoveTransferredProperty = 'Poista luovutettu omaisuus';
	static SavingMessageSendPushParameterWasNotSuccessfulErrorParameter = 'Push-viestilokin tallennus viestille \'{0}\' epäonnistui! Virhe: \'{1}\'';
	static Month = 'Kuukausi';
	static DoYouReallyWantToSaveData = 'Haluatko todella tallentaa tiedot?';
	static UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError = 'Käyttäjäryhmän \'{0}\' linkitys ulkoisiin järjestelmiin epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static NoPublishedMessages = 'Ei julkaistuja viestejä';
	static Subject = 'Aihe';
	static WhistleBlowUrlInfo = 'Osoitetta voi muuttaa vain järjestelmän ylläpitäjä';
	static RemovePartner = 'Poista kumppani';
	static FileParameterAlreadyExistsInFileSystem = 'Tiedosto \'{0}\' on jo olemassa tiedostojärjestelmässä!';
	static SavingUserParameterWasSuccessful = 'Käyttäjän \'{0}\' tallennus onnistui!';
	static LogDocumentPreview = 'Lokita dokumentin esikatselu';
	static WhistleBlowActiveReports = 'Aktiiviset ilmoitukset';
	static WhistleBlowApplicationName = 'Whistle Blower -ilmoituskanava';
	static DeletingTaskStateParameterWasSuccessful = 'Tehtävän tilan \'{0}\' poisto onnistui!';
	static FileFilterNameMustBeDefined = 'Tiedostosuodattimen nimi täytyy määritellä!';
	static EventHandlers = 'Käsittelijät';
	static WhistleBlowResolutionUnspecified = 'Määrittämättä';
	static DoYouReallyWantToRemoveRecordUserParameter = 'Haluatko todella poistaa henkilöstökortin \'{0}\'?';
	static RecordFamiliarizationParameterNotFound = 'Perehdytystä \'{0}\' ei löytynyt!';
	static TaskStateCodeMustBeDefined = 'Tehtävän tilan nimi täytyy määritellä!';
	static SaveInstantMessageChannel = 'Tallenna keskustelu';
	static PasswordHasBeenCopiedToClipboard = 'Salasana on kopioitu leikepöydälle!';
	static GeneralInfo = 'Yleiset tiedot';
	static EventIsNotApprovedByUser = 'Käyttäjä ei ole hyväksynyt havaintoa';
	static EditFurtherEducation = 'Muokkaa jatkokoulutusta';
	static SavingTaskParameterWasNotSuccessfulErrorParameter = 'Tehtävän \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static SavingDirectoryItemFolderParameterWasSuccessful = 'Kansion \'{0}\' tallennus onnistui!';
	static InstantMessagesMenuTitle = 'HoxUp';
	static FurtherEducationParameterNotFound = 'Jatkokoulutusta \'{0}\' ei löytynyt!';
	static DoYouReallyWantToRemoveUserParameter = 'Haluatko todella poistaa käyttäjän \'{0}\'?';
	static NoFurtherEducationsToShow = 'Ei näytettäviä jatkokoulutuksia!';
	static EditLogisticVoucherTransportCompany = 'Muokkaa kuljetusyritystä';
	static OperationNotAllowedForRootFolderParameter = 'Operaatiota ei voi suorittaa juurikansiolle \'{0}\'!';
	static SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError = 'Kansion \'{0}\' tallennus epäonnistui, koska kansion tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SavingTaskStateParameterWasNotSuccessfulErrorParameter = 'Tehtävän tilan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static DeletingRecordFamiliarizationParameterWasSuccessful = 'Perehdytyksen \'{0}\' poisto onnistui!';
	static AddingAttachmentsParameterWasNotSuccessfulErrorParameter = 'Liitteiden \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static NotSet = 'Ei asetettu';
	static WhistleBlowResolutionRejected = 'Hylätty';
	static DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError = 'Tehtävien prioriteettien \'{0}\' poisto epäonnistui, koska tehtävien prioriteettien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ArchivingRecordUserFamiliarizationsWasSuccessful = 'Perehdytysten arkistointi onnistui!';
	static AdditionalSettings = 'Lisäasetukset';
	static PublishVersion = 'Julkaisuversio';
	static PostalCode = 'Postinumero';
	static DeletingUserParametersWasNotSuccessfulErrorParameter = 'Käyttäjäasetusten poisto epäonnistui! Virhe: \'{0}\'';
	static ResolutionDescription = 'Ratkaisun kuvaus';
	static DeletingEventUrgencyClassesParameterWasSuccessful = 'Kiireellisyysluokkien \'{0}\' poisto onnistui!';
	static NoNotificationsToSend = 'Ei lähetettäviä viestejä!';
	static SuccessAudit = 'SuccessAudit';
	static DeletingPartnerParameterWasNotSuccessfulConcurrencyError = 'Kumppanin \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SavingOptionParameterWasNotSuccessfulErrorParameter = 'Sovellusasetuksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static CompanyNameMustBeDefined = 'Yrityksen nimi täytyy määritellä!';
	static DoYouReallyWantToRemoveRecordFurtherEducationParameter = 'Haluatko todella poistaa jatkokoulutuksen \'{0}\'?';
	static NewPasswordMustBeDefined = 'Uusi salasana täytyy määritellä!';
	static InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted = 'Keskusteluun kuuluu muita keskustelijoita, joten sitä voi poistaa!';
	static AddEventPartner = 'Lisää havaintokumppani';
	static AddPartnerParameterWasNotSuccessfulErrorParameter = 'Kumppanin \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static GeneralAttachments = 'Yleiset liitteet';
	static Selected = 'Valittu';
	static DescriptionMustBeDefined = 'Kuvaus täytyy määritellä!';
	static CompanyGroup = 'Yritysryhmä';
	static MoveTaskToTrashBin = 'Siirrä tehtävä roskakoriin';
	static SavingApplicationPermissionsWasNotSuccessfulConcurrencyError = 'Asetusten tallennus epäonnistui, koska asetuksia on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static List = 'Lista';
	static DeletingTaskTypeParameterWasSuccessful = 'Tehtävätyypin \'{0}\' poisto onnistui!';
	static NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded = 'Uusi luovutettu omaisuus täytyy tallentaan ennen liitteiden lisäämistä!';
	static Header = 'Yläosa';
	static EventHandlersExcelTitle = 'Käsittelijät (x = hyväksytty)';
	static Start = 'Alku';
	static AddingAddRecordUserQualificationsWasSuccessful = 'Pätevyysvaatimusten lisäys onnistui!';
	static CompanyModuleProperties = 'Yrityksen asetukset';
	static DocumentRootPathIsNotEmpty = 'Dokumenttien juurihakemisto ei ole tyhjä hakemisto!';
	static EventTypeCs = 'Tarkenne 3';
	static Remainder = 'Muistutus';
	static NoFilesToAdd = 'Ei lisättäviä tiedostoja!';
	static SavingRecordFurtherEducationParameterWasSuccessful = 'Jatkokoulutuksen \'{0}\' tallennus onnistui!';
	static VoucherSavePath = 'Tositteen tallennuskansio';
	static AddTransferredProperty = 'Lisää luovutettu omaisuus';
	static PreviousCalendarMonth = 'Edellinen kalenterikuukausi';
	static RemoveEventTypeC = 'Poista tarkenne 3';
	static InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation = 'Hyväksyn automaattisesti kutsun keskusteluun';
	static EventTypes = 'Tarkenne 1';
	static ParameterIsNotValidBankBICCode = '\'{0}\' ei ole oikeellinen pankin BIC-koodi!';
	static SharedFilter = 'Jaettu suodatin';
	static SavingEventTypesWasSuccessful = 'Tarkenteen 1 tallennus onnistui!';
	static DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError = 'Dokumentin \'{0}\' poisto epäonnistui, koska dokumentin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddingWhistleBlowHandlersWasSuccessful = 'Käsittelijöiden lisäys onnistui!';
	static AddNotifiedPerson = 'Lisää tiedotettava henkilö';
	static SystemAdminMessage = 'Viesti kaikille järjestelmän käyttäjille';
	static SendingEmail = 'Lähettää sähköpostia...';
	static EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError = 'Tehtävien roskakorin tyhjennys epäonnistui, koska tehtäviän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static PortalInUse = 'Kirjautumissivusto käytössä';
	static SavingRecordBasicEducationParameterWasSuccessful = 'Peruskoulutuksen \'{0}\' tallennus onnistui!';
	static EventSettingsParameterNotFound = 'Havaintojen asetuksia \'{0}\' ei löytynyt!';
	static DeletingPartnersParameterWasSuccessful = 'Kumppanien \'{0}\' poisto onnistui!';
	static EmployeeType = 'Työntekijätyyppi';
	static CompanyTaskTypes = 'Yrityksen tehtävätyypit';
	static DeletingUserParametersWasNotSuccessfulConcurrencyError = 'Käyttäjäasetusten poisto epäonnistui, koska käyttäjäasetusten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RemoveUserGroup = 'Poista käyttäjäryhmä';
	static HandlersMustBeDefined = 'Käsittelijät täytyy määritellä!';
	static DoYouReallyWantToRemoveRecordBasicEducationParameter = 'Haluatko todella poistaa peruskoulutuksen \'{0}\'?';
	static AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter = 'Ylläpitäjien \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static SelectEventReceiver = 'Valitse havainnon vastaanottaja';
	static Modification = 'Muutos';
	static AddLogisticVoucherPaymentSetting = 'Lisää laskutusasetus';
	static EditCompany = 'Muokkaa yritystä';
	static ChangingPasswordWasNotSuccessfulErrorParameter = 'Salasanan vaihto epäonnistui! Virhe: \'{0}\'';
	static DoYouReallyWantToUnlockEvent = 'Haluatko todella poistaa havainnon \'{0}\' lukituksen?';
	static UserModuleProperties = 'Käyttätilien asetukset';
	static NoReports = 'Ei raportteja!';
	static SharedFiltersAndReports = 'Jaetut suodattimet ja raportit';
	static AddTaskHandler = 'Lisää käsittelijä';
	static WhistleBlowStateNew = 'Uusi';
	static SavingAttachmentsWasNotSuccessfulErrorParameter = 'Liitteiden tallennus epäonnistui! Virhe: \'{0}\'';
	static UnkownErrorHasBeenOccured = 'Sovelluksessa tapahtui odottamaton virhe!';
	static SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter = 'Ilmoituskanavan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static SaveComment = 'Tallenna kommentti';
	static RemoveFilter = 'Poista suodatin';
	static Drafts = 'Luonnokset';
	static FolderTreePermissions = 'Kansiopuun käyttöoikeudet';
	static LockedToUser = 'Lukittu käyttäjälle';
	static Custom = 'Räätälöity';
	static Collapse = 'Supista';
	static RestoringInstantMessageWasSuccessful = 'Pikaviestin palautus onnistui!';
	static PublishVersionHistorySize = 'Versiohistorian koko julkaisuissa';
	static AddDocumentTemplate = 'Lisää dokumenttimalli';
	static WhistleBlowDateAndTime = 'Tapahtuma-aika';
	static EmptyingTaskTrashBinWasSuccessful = 'Tehtävien roskakori on nyt tyhjä!';
	static ShowOnlyUnitsThatAreInUse = 'Näytä vain käytössä olevat yksiköt';
	static ModifyDateStartDate = 'Muokkauspäivä alkaen';
	static Missing = 'Puuttuu';
	static WorkTimeType = 'Työaikamuoto';
	static EditUserGroup = 'Muokkaa käyttäjäryhmää';
	static DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError = 'Ylläpitäjien \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Channels = 'Keskustelut';
	static MaximumTotalSizeOfAttachmentsIsParameterMB = 'Liitteiden suurin yhteiskoko on {0} MB!';
	static EditDefaultEventHandlersAndNotifiedPersons = 'Muokkaa oletuskäsittelijöitä ja tiedotettavia henkilöitä';
	static CompanyGroupAll = '\'kaikki yritykset\'';
	static SavingTaskDataRowWasNotSuccessfulErrorParameter = 'Tehtävärivin tallennus epäonnistui! Virhe: \'{0}\'';
	static DeletingEventTypeCParameterWasNotSuccessfulErrorParameter = 'Tarkenteen 3 \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Tarkenteen 1 \'{0}\' alitarkenteita on käytössä {1} havainnossa eikä tarkennetta voi poistaa!';
	static ClearSubFolderPermissions = 'Tyhjennä alikansioiden käyttöoikeudet';
	static CurrentWorkPlaceNotAdded = 'Nykyistä työpaikkaa ei lisätty!';
	static NoInstantMessageChannelsToShow = 'Ei näytettäviä keskusteluja!';
	static EventTypeC = 'Tarkenne 3';
	static ChangePassword = 'Vaihda salasana';
	static FolderNameMustBeDefined = 'Kansion nimi täytyy määritellä!';
	static Severity = 'Vakavuus';
	static TaskRemainderCheckingAndNotificationFinishedInParameterSeconds = 'Tehtävien muistutusten tarkastus ja ilmoitus  suoritettu (kesto: {0:N0}s, virheitä: {1}, tehtäviä: {2}, sähköposteja: {3}, push-viestejä: {4})';
	static SavingUserParameterWasNotSuccessfulConcurrencyError = 'Käyttäjän \'{0}\' tallennus epäonnistui, koska käyttäjän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static WhistleBlowParameterNotFound = 'Ilmoitusta \'{0}\' ei löytynyt!';
	static Observer = 'Havainnon tekijä';
	static DeletingPartnerParameterWasNotSuccessfulErrorParameter = 'Kumppanin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static SavingEventTypeCParameterWasNotSuccessfulConcurrencyError = 'Tarkenteen 3 \'{0}\' tallennus epäonnistui, koska tarkenteen 3 tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter = 'Keskustelijoiden \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static AddingEventHandlersWasSuccessful = 'Käsittelijöiden lisäys onnistui!';
	static Ascending = 'Nouseva';
	static UnlockingTaskDataRowWasNotSuccessful = 'Tehtävärivin lukituksen poisto epäonnistui!';
	static Save = 'Tallenna';
	static RecordFurtherEducationCodeMustBeDefined = 'Jatkokoulutuksen nimi täytyy määritellä!';
	static PaymentTermInDays = 'Maksuaika päivinä';
	static DefaultTaskStates = 'Tehtävien oletustilat';
	static SavingMessageParameterWasNotSuccessfulErrorParameter = 'Viestin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static AddUsersParameterWasNotSuccessfulConcurrencyError = 'Käyttäjätilien \'{0}\' lisäys epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ExternalSystemDatabaseInterfaceError = 'Virhe eSalkku-järjestelmän tietokantaliittymässä!';
	static RemoveUserAndKeepRecordUser = 'Poista käyttäjätili ja säilytä henkilöstökortti';
	static AllLogisticVoucherCustomers = 'Kaikki asiakkaat';
	static Next = 'Seuraava';
	static AddTransferredProperties = 'Lisää luovutettu omaisuus';
	static AddingTaskTypesParameterWasNotSuccessfulErrorParameter = 'Tehtävätyyppien \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static EditableDocumentSaveBeforeApproveConfirmation = 'Tallennetaanko dokumenttiin tehdyt muutokset hyväksynnän yhteydessä?';
	static SelectEventType = 'Valitse tarkenne 1';
	static Actions = 'Toimenpiteet';
	static SelectTaskPriority = 'Valitse tehtävän priorititeetti';
	static EventClosed = 'Havainto suljettu';
	static Remainders = 'Muistutukset';
	static UsedFileStorageQuota = 'Käytetty tiedostojen tallennustila';
	static Yes = 'Kyllä';
	static AddLogisticVoucherCustomerPaymentSetting = 'Lisää laskutusasetus';
	static Interval = 'Aikaväli';
	static SelectUrgencyClass = 'Valitse kiireellisyysluokka';
	static FileChangesCheckingAndEmailingStarted = 'Tiedostojen muutosten tarkastus ja lähetys sähköpostitse aloitettu';
	static Receiver = 'Vastaanottaja';
	static SavingEventMessageWasNotSuccessfulConcurrencyError = 'Kommentin tallennus epäonnistui, koska kommentin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static InTesting = 'Testauksessa';
	static Qualification = 'Pätevyysvaatimus';
	static AttachmentParameterDownloaded = 'Liitetiedosto on ladattu.';
	static RemoveFromFavorites = 'Poista suosikeista';
	static SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError = 'Sähköpostilokin tallennus viestille \'{0}\' epäonnistui, koska sähköpostilokin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError = 'Ylläpitäjän \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EventParameterUnitChangedFromParameterToParameter = 'Havainnon \'{0}\' yksikkö vaihdettu \'{1}\' => \'{2}\'.';
	static VoucherPrefix = 'Tositteen etuliite';
	static Form = 'Lomake';
	static SavingTaskParameterWasNotSuccessfulConcurrencyError = 'Tehtävän \'{0}\' tallennus epäonnistui, koska tehtävän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingRecordFamiliarizationsParameterWasSuccessful = 'Perehdytysten \'{0}\' poisto onnistui!';
	static UserCodeMustBeDefined = 'Käyttäjätunnus täytyy määritellä!';
	static AddUserGroupsParameterWasNotSuccessfulConcurrencyError = 'Käyttäjäryhmien \'{0}\' lisäys epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingWhistleBlowParameterWasSuccessful = 'Ilmoituksen \'{0}\' poisto onnistui!';
	static BusinessIdentityCode = 'Y-tunnus';
	static RemoveCompanyTaskType = 'Poista yrityksen tehtävätyyppi';
	static RemoveLogisticVoucherPaymentSetting = 'Poista laskutusasetus';
	static SearchDocuments = 'Hae dokumentteja';
	static RecordParameterLockingFailed = 'Tietueen \'{0}\' lukitseminen epäonnistui!';
	static SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter = 'Sähköpostilokin tallennus viestille \'{0}\' epäonnistui! Virhe: \'{1}\'';
	static FieldParameterContainsInvalidCodeCharacters = 'Kentän \'{0}\' arvo sisältää merkkejä ( \, ^, ?, &, <, >, /, +, :), joita ei sallita tunnuksessa tai arvo päättyy pisteeseen!';
	static CustomerNumber = 'Asiakasnumero';
	static DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError = 'Tehtävätyypin \'{0}\' poisto epäonnistui, koska tehtävätyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static RecordUserExcelFamiliarization = 'Perehdytykset';
	static PageBreakBetweenGroups = 'Sivuta ryhmittelyn perusteella';
	static AddTask = 'Lisää tehtävä';
	static Filter = 'Suodatin';
	static EventTypeBParameterNotFound = 'Tarkennetta 2 \'{0}\' ei löytynyt!';
	static DoYouWantToAddThisApplicationToHomeScreen = 'Haluatko lisätä tämän sovelluksen aloitusnäyttöön?';
	static WhistleBlowAnonymousReportInfo = 'Valitse tämä vaihtoehto, jos et halua paljastaa nimeäsi raportoinnin yhteydessä.';
	static LanguageNonLocalFinnish = 'Suomi';
	static DocumentZipFileName = 'Dokumentit_{0:yyyy-MM-dd_HH-mm}.zip';
	static JobLineParameterNotFound = 'Työriviä \'{0}\' ei löytynyt!';
	static DocumentEndDate = 'Voimassaoloaika';
	static SavingInstantMessageWasNotSuccessfulErrorParameter = 'Pikaviestin tallennus epäonnistui! Virhe: \'{0}\'';
	static DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError = 'Dokumenttimallin \'{0}\' poisto epäonnistui, koska dokumenttimallin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddTransferredPropertyToMultipleUserRecords = 'Lisää luovutettu omaisuus useaan henkilöstökorttiin';
	static DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError = 'Keskustelun poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static AddChildEventType = 'Lisää alitarkenne 1';
	static QualificationIsInvalid = 'Pätevyysvaatimus ei ole voimassa!';
	static AddUserGroups = 'Lisää käyttäjäryhmät';
	static DeletingEventTypeBParameterWasSuccessful = 'Tarkenteen 2 \'{0}\' poisto onnistui!';
	static CompanyLogo = 'Yrityksen logo';
	static KeywordParameterNotFound = 'Avainsanaa \'{0}\' ei löytynyt!';
	static PrintRecordUser = 'Tulosta henkilöstökortti';
	static SavingFilterWasNotSuccessfulConcurrencyError = 'Suodattimen tallennus epäonnistui, koska suodattimen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EventPartners = 'Havaintokumppanit';
	static DeletingAttachmentsParameterWasNotSuccessfulErrorParameter = 'Liitteiden \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static FolderParameterNotFound = 'Kansiota \'{0}\' ei löytynyt!';
	static Salary = 'Palkkaus';
	static ClickToCancelAutomaticLogout = 'Käyttäjäistunto on vanhenemassa, klikkaa tätä lomaketta estääksesi järjestelmän sulkemisen.';
	static TaskStates = 'Tehtävien tilat';
	static DefaultLanguage = 'Kieli';
	static Last180Days = '180 viime päivää';
	static WhistleBlowSourceEmail = 'Sähköposti';
	static DatabaseCheckingStarted = 'Tietokannan tarkastus aloitettu';
	static UnitChangedFromParameterToParameter = 'Yksikkö vaihdettu \'{0}\' => \'{1}\'.';
	static BrowserInfo = 'Selain';
	static ApplicationName = 'eSalkku';
	static SSRSRefreshFinishedInParameterSeconds = 'SSRS virkistys suoritettu (kesto: {0:N0}s)';
	static EventParameterNotFound = 'Havaintoa \'{0}\' ei löytynyt!';
	static InstantMessageChannelAdministrator = 'Ylläpitäjä';
	static SavingEventSettingsWasNotSuccessfulConcurrencyError = 'Havaintojen asetusten tallennus epäonnistui, koska havaintojen asetuksia on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingMessageTypeParameterWasNotSuccessfulErrorParameter = 'Viestityypin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static ExternalSystemCompanyCodeClaimNotFound = 'Käyttäjän ominaisuutta \'externalSystemCompanyCode\' ei löytynyt!';
	static LockedTaskParameterCannotBeSaved = 'Tehtävä \'{0}\' on lukittu toiselle käyttäjälle eikä sitä voi tallentaa!';
	static WhistleBlowReport = 'Ilmoitus';
	static IncludeArchivedRecordUsers = 'Sisällytä arkistoidut henkilöstökortit';
	static SavingEventTypeBParameterWasNotSuccessfulErrorParameter = 'Tarkenteen 2 \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static DoYouReallyWantToUnlockTaskDataRow = 'Haluatko todella poistaa tehtävärivin lukituksen?';
	static SavePrint = 'Tallenna tuloste';
	static SendReadReceipt = 'Kuittaa luetuksi';
	static ValidityDate = 'Voimassaolo';
	static FieldParameterContainsInvalidCompanyCodeCharacters = 'Kentän \'{0}\' arvo sisältää merkkejä (., &, :, \' \', ö, ä, à, å, Ö, Ä, À tai Å), joita ei sallita yrityksen tunnuksessa!';
	static RecordUserExcelSalary = 'Palkkaus';
	static WhistleBlowCategory = 'Ilmoitusluokka';
	static AccessRightWrite = 'Oikeus muokata sisältöä';
	static TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted = 'Tehtävätyyppi \'{0}\' on käytössä {1} tehtävässä eikä sitä voi poistaa!';
	static DocumentApproval = 'Dokumentin hyväksyntä';
	static DeletingRecordQualificationsParameterWasSuccessful = 'Pätevyysvaatimusten \'{0}\' poisto onnistui!';
	static PermissionToIdentificateEvents = 'Oikeus luoda ja yksilöidä havaintoja';
	static SendingPusNotificationsWasNotSuccessfulErrorParameter = 'Push-viestien lähetys epäonnistui! Virhe: \'{0}\'';
	static DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError = 'Yritysryhmien \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Years = 'Vuotta';
	static EditMessage = 'Muokkaa viestiä';
	static DeletingRecordUserParameterWasNotSuccessfulErrorParameter = 'Henkilöstökortin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static SaveTaskConfirmation = 'Haluatko tallentaa tehtävän?';
	static EventSenderIsPartnerCompany = 'Havainnon lähettäjä on kumppaniyritys';
	static UserPasswordMustBeValid = 'Salasanan täytyy olla vähintään 8 merkkiä pitkä ja pitää sisältää isoja ja pieniä kirjaimia sekä vähintään yksi erikoismerkki tai numero.';
	static PrintEvent = 'Tulosta havainto';
	static PayeeUsersCanLogInToTheSystem = 'Yrityksen käyttäjät voivat kirjautua järjestelmään';
	static MessageMustBeDefined = 'Viestin sisältö täytyy määritellä!';
	static ChangedUrgencyClass = 'Muuttanut havainnon kiireellisyysluokkaa';
	static InviteInstantMessageChannelUser = 'Kutsu keskustelija';
	static UserEventsNotApproved = 'Käyttäjän luomia havaintoja hyväksymättä';
	static NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded = 'Uusi yritys täytyy tallentaan ennen yritysryhmien lisäämistä!';
	static SendReadReceiptInfo = 'Kuittaa viesti luetuksi &#10003;-painikkeesta.';
	static DefaultEventUrgencyClasses = 'Oletuskiireellisyysluokat';
	static WhistleBlowInformantDateAndTime = 'Milloin tämä tapahtui?';
	static SavingUserParameterParameterWasSuccessful = 'Käyttäjäasetuksen \'{0}\' tallennus onnistui!';
	static PreviewIsNotSupportedForThisFileType = 'Esikatselu ei tue tämän tiedostotyypin näyttämistä!';
	static Last7Days = '7 viime päivää';
	static Picture = 'Kuva';
	static AddPermission = 'Lisää käyttöoikeus';
	static EmailMessage = 'Sähköpostita viesti';
	static LastName = 'Sukunimi';
	static DeletingTaskStateParameterWasNotSuccessfulErrorParameter = 'Tehtävän tilan \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter = 'Tiedoston \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static LockingEditableDocumentParameterWasNotSuccessful = 'Dokumentin \'{0}\' lukitseminen epäonnistui!';
	static DeletingAttachmentParameterWasSuccessful = 'Liiteen \'{0}\' poisto onnistui!';
	static ValidPlural = 'Voimassaolevat';
	static RootCause = 'Juurisyy';
	static Partner = 'Kumppani';
	static PermissionsForSections = 'Käytössä olevat osiot';
	static ArchiveWhistleBlows = 'Arkistoi ilmoituksia';
	static Version = 'Versio';
	static ReportsStandard = 'Vakioraportit';
	static ReadReceiptGiven = 'Kuitattu';
	static SavingMessageTypeParameterWasNotSuccessfulErrorParameter = 'Viestityypin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static RemoveUserFromUserGroup = 'Poista käyttäjätili käyttäjäryhmästä';
	static DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents = 'Oletuskiireellisyysluokka täytyy määritellä ennen kuin havaintoja voi lisätä!';
	static ClearSubUnitPermissions = 'Tyhjennä aliyksiköiden käyttöoikeudet';
	static AType = 'Tyyppi';
	static RemoveMessageType = 'Poista viestityyppi';
	static DocumentNameIsIllegal = 'Dokumentin nimi ei ole sallittu (varattu nimi)!';
	static AddCompanyGroupParameterWasNotSuccessfulConcurrencyError = 'Yritysryhmän \'{0}\' lisäys epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = 'Tarkenteen 2 \'{0}\' alitarkenteita on käytössä {1} havainnossa eikä tarkennetta voi poistaa!';
	static UnlockingTaskParameterWasNotSuccessful = 'Tehtävän \'{0}\' lukituksen poisto epäonnistui!';
	static Row = 'rivi';
	static SaveInstantMessageChannelConfirmation = 'Haluatko tallentaa keskustelun?';
	static NotSelected = 'Ei valittu';
	static Private = 'Yksityinen';
	static SavingUserParametersWasNotSuccessfulErrorParameter = 'Käyttäjäasetusten tallennus epäonnistui! Virhe: \'{0}\'';
	static Identification = 'Yksilöinti';
	static InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter = 'Poista arkistoitu keskustelu ja viestit {0}';
	static TaskPriorityParameterNotFound = 'Tehtävän prioriteettia \'{0}\' ei löytynyt!';
	static Uploaded = 'Ladattu';
	static AutomaticLogoutDone = 'Automaattinen uloskirjautuminen suoritettu vanhentuneen käyttäjäistunnon vuoksi!';
	static RemoveTaskType = 'Poista tehtävätyyppi';
	static EventTypeBTitle = 'Havainnon yksilöinnin tarkenne 2';
	static SelectUnit = 'Valitse yksikkö';
	static DeletingUsersParameterWasSuccessful = 'Käyttäjätilien \'{0}\' poisto onnistui!';
	static AddQualification = 'Lisää pätevyysvaatimus';
	static DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter = 'Keskustelijan \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders = 'Tiedostosuodattimen \'{0}\' poisto epäonnistui, koska tiedostosuodatin on {1} kansion käytössä!';
	static UnitIsRelatedToEventPartner = 'Yksikkö liitty havaintokumppaniin';
	static NoFilters = 'Ei suodattimia!';
	static EditDefaultFurtherEducation = 'Muokkaa oletusjatkokoulutusta';
	static UserGroups = 'Käyttäjäryhmät';
	static RecordUserLogEntryTypeCreate = 'Luonti';
	static ShortName = 'Nimen lyhenne';
	static Validity = 'Voimassa';
	static DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError = 'Perehdytysten \'{0}\' poisto epäonnistui, koska perehdytysten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingPartnerParameterWasSuccessful = 'Kumppanin \'{0}\' poisto onnistui!';
	static OtherCompanies = 'Muut yritykset';
	static CourseCredit = 'opintopiste';
	static AddRemainder = 'Lisää muistutus';
	static FullNameClaimNotFound = 'Käyttäjän ominaisuutta \'nimi\' ei löytynyt!';
	static UserGroupParameterNotFound = 'Käyttäjäryhmää \'{0}\' ei löytynyt!';
	static DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static PanelSize1 = '1 x 1';
	static DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError = 'Tiedoston \'{0}\' poisto epäonnistui, koska tiedoston tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Messages = 'Viestit';
	static FileParameterIsNotImage = 'Tiedosto \'{0}\' ei ole kuva!';
	static DocumentNameIsTooLong = 'Dokumentin nimi \'{0}\' on liian pitkä!';
	static EmailNotificationOfChangedPublishStatus = 'Sähköposti-ilmoitus julkaisun muutoksesta';
	static AllPartners = '\'Kaikki kumppanit\'';
	static SharedReportCompany = 'Yritykselle jaettu raportti';
	static ScopeUnit = 'Yksikkö';
	static PrintParameterNotFound = 'Tulostetta \'{0}\' ei löytynyt!';
	static DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError = 'Käyttäjäryhmien \'{0}\' poisto epäonnistui, koska käyttäjäryhmien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingDefaultEventHandlerParameterWasSuccessful = 'Oletuskäsittelijän \'{0}\' poisto onnistui!';
	static TimeMustBeDefined = 'Aika täytyy määritellä!';
	static EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted = 'Tarkenne 2 \'{0}\' on käytössä {1} havainnossa eikä sitä voi poistaa!';
	static RecordUserExcelTabName = 'Henkilöstö';
	static DeletingTaskStatesParameterWasNotSuccessfulErrorParameter = 'Tehtävien tilojen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static CompanyParameterNotFound = 'Yritystä \'{0}\' ei löytynyt!';
	static SearchEndDate = 'Päiväys loppu';
	static WhistleBlowStatisticsInProgressRecords = 'Tutkittavia ilmoituksia';
	static RemoveEventUrgencyClass = 'Poista kiireellisyysluokka';
	static TaskHandlerParameterNotFound = 'Käsittelijää \'{0}\' ei löytynyt!';
	static PositionErrorUnavailable = 'Sijaintitietoa ei saatavilla.';
	static DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Yritysryhmän \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static Unit = 'Yksikkö';
	static ChangesMaxAmount = 'Muutosten maksimimäärä';
	static NoAddedEventTypeCs = 'Ei lisättyjä tarkenteita!';
	static NoAddedEventTypeBs = 'Ei lisättyjä tarkenteita!';
	static CompanyCodeContainsIllegalCharacters = 'Yrityksen tunnus sisältää kiellettyjä merkkejä (., &, :, \' \', ö, ä, à, å, Ö, Ä, À tai Å)!';
	static EmploymentEndDate = 'Työsuhde päättyi';
	static WhistleBlowStatisticsNonAnonymousRecords = 'Nimellisiä ilmoituksia';
	static DoYouReallyWantToRemoveEventTypeCParameter = 'Haluatko todella poistaa tarkenteen 3 \'{0}\'?';
	static PrintFileName = 'Tuloste_{0:yyyy-MM-dd_HH-mm}.pdf';
	static CopyContentToDocumentContent = 'Kopioi sisältö dokumentin sisällöksi';
	static DoYouReallyWantToRemoveTaskPriorityParameter = 'Haluatko todella poistaa tehtävän prioriteetin \'{0}\'?';
	static EditableDocument = 'Dokumentti';
	static GetMore = 'Hae lisää';
	static FieldParameterValueMustBeDefined = 'Kentän \'{0}\' arvo täytyy määritellä!';
	static DeletingAttachmentParameterWasNotSuccessfulConcurrencyError = 'Liiteen \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static LastParameterYears = 'Viimeiset {0} vuotta';
	static MyLastModifications = 'Käyttäjän viimeisimmät muutokset';
	static DownloadAttachment = 'Lataa valittu liitetiedosto';
	static QualificationIsValid = 'Pätevyysvaatimus on voimassa';
	static SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError = 'Kiireellisyysluokan tallennus \'{0}\' epäonnistui, koska kiireellisyysluokan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Download = 'Lataa';
	static DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter = 'Dokumentin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static WhistleBlowInformantMessages = 'Ilmoittajan viestit';
	static TaskStateLocked = 'Lukittu';
	static Usage = 'Käyttö';
	static BasicEducation = 'Peruskoulutus';
	static DeletingUserParameterWasNotSuccessfulErrorParameter = 'Käyttäjätilin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static SavingWhistleBlowCategoryParameterWasSuccessful = 'Ilmoitusluokan \'{0}\' tallennus onnistui!';
	static DoYouReallyWantToRemoveFileParameter = 'Haluatko todella poistaa tiedoston \'{0}\'?';
	static MaximumSizeOfSingleAttachment = 'Liitteen maksimikoko on {0} MB!';
	static MoveToTrashBin = 'Siirrä roskakoriin';
	static InstantMessageSettings = 'Pikaviestiasetukset (HoxUp)';
	static ObserverAction = 'Havainnon tekijän toimenpiteet';
	static AddEventTypesToEvent = 'Lisää tarkenne 1 plus-painikkeesta.';
	static MessageModuleProperties = 'Ajankohtaista-osion asetukset';
	static CreatingPrint = 'Luodaan tulostetta...';
	static EventApprovalWasNotSuccessfulErrorParameter = 'Havainnon hyväksyminen epäonnistui! Virhe: \'{0}\'';
	static ImageChangeInterval = 'Kuvien vaihtoaika';
	static RemovePrint = 'Poista tuloste';
	static DocumentsSelected = 'tiedostoa valittu';
	static DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter = 'Pätevyysvaatimusten \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static AddPartnerParameterWasSuccessful = 'Kumppanin \'{0}\' lisäys onnistui!';
	static MessageVisibilityPeriodNotStarted = 'Viestin näkyvyys ei ole vielä alkanut!';
	static DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages = 'Viestityypin \'{0}\' poisto epäonnistui, koska viestityyppi on {1} viestin käytössä!';
	static RemovePicture = 'Poista kuva';
	static RestoringInstantMessageWasNotSuccessfulConcurrencyError = 'Pikaviestin palautus epäonnistui, koska pikaviestin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ExternalSystemCode = 'Tunnus järjestelmässä';
	static Closed = 'Suljettu';
	static SelectedDocumentTypes = 'Näytä vain valitut dokumenttityypit';
	static InstantMessageChannelParameterNotFound = 'Keskustelua \'{0}\' ei löytynyt!';
	static AllEventHandlersHaveReadMessage = 'Kaikki havainnot käsittelijät ovat lukeneet kommenttisi';
	static StateOpenPlural = 'Avoimet';
	static ValidityStartDate = 'Voimassa alkaen';
	static SavingDirectoryItemPermissionsNotSuccessfulErrorParameter = 'Kansioiden oikeuksien tallennus epäonnistui! Virhe: \'{0}\'';
	static SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter = 'Työpaikan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static SelectCategory = 'Valitse luokka';
	static ModifiedBy = 'Muokkaaja';
	static Expired = 'Vanhentunut';
	static ViewLogLine = 'Näytä lokirivi';
	static DoYouReallyWantToEmailMessageParameter = 'Haluatko todella lähettää viestin \'{0}\' sähköpostilla? Viestin voi lähettää sähköpostilla vain yhden kerran.';
	static SelectEventTypeC = 'Valitse tarkenne 3';
	static FolderNameConstainsIllegalCharacters = 'Kansion nimi sisältää kiellettyjä merkkejä (<, >, :, ", /, \, |, ? tai *) tai päättyy kiellettyyn merkkiin (., \' \')!';
	static AllCompanies = 'Kaikki yritykset';
	static Today = 'Tänään';
	static PrintOrientation = 'Tulostussuunta';
	static RecordBasicEducationCodeMustBeDefined = 'Peruskoulutuksen nimi täytyy määritellä!';
	static SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError = 'Tiedoston \'{0}\' tallennus epäonnistui, koska tiedoston tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError = 'Keskustelijoiden \'{0}\' poisto epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static ApplicationTempPathIsNotSpecified = 'Sovelluksen temp-hakemistoa ei ole määritelty!';
	static DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError = 'Tarkenteen 2 \'{0}\' poisto epäonnistui, koska tarkenteen 2 tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static OpenWhistleBlowInstruction = 'Syötä salasana, jonka sait ilmoituksen luotuasi.';
	static FolderCannotBeMovedToItsSubFolder = 'Kansiota ei voi siirtää alikansioonsa!';
	static DefaultTaskTypes = 'Oletustehtävätyypit';
	static SaveDraft = 'Tallenna luonnos';
	static DocumentModulePropertiesSaveWasSuccessful = 'Dokumenttien asetuksien tallennus onnistui!';
	static WhistleBlowReportHasBeenSavedBottomText = 'Sinun on tallennettava salasanasi tältä sivulta ennen kuin voit sulkea tämän sivun.';
	static AddedComment = 'Lisännyt kommentin';
	static AddFurtherEducationToMultipleUserRecords = 'Lisää jatkokoulutus useaan henkilöstökorttiin';
	static WhistleBlowSourceMail = 'Kirje';
	static SavingUserParametersWasNotSuccessfulConcurrencyError = 'Käyttäjäasetusten tallennus epäonnistui, koska käyttäjäasetusten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static PositionErrorPermissionDenied = 'Sovelluksella ei oikeutta lukea sijaintitietoa.';
	static CkickToChangeTheIcon = 'Klikkaa vaihtaaksesi kuvaketta';
	static InstantMessageChannelArchivingTypeKeepMessages = 'Säilytä arkistoitu keskustelu viesteineen';
	static CopyingFilesAndDocumentsToFolderParameterWasSuccessful = 'Tiedostojen/dokumenttien kopioiminen kansioon \'{0}\' onnistui!';
	static AddPartnersParameterWasSuccessful = 'Kumppanien \'{0}\' lisäys onnistui!';
	static NoModifications = 'Ei tehtyjä muutoksia';
	static NormalMessage = 'Normaali viesti';
	static PermissionsToCreateEventsToEventPartner = 'Oikeus luoda havaintoja kumppanille';
	static AddEvent = 'Lisää havainto';
	static TrainingEndDateCannotPrecedeTrainingStartDate = 'Koulutus päättyi -päivä ei voi olla aikaisemmin kuin koulutus alkoi -päivä!';
	static UserAccountNotConnected = 'Käyttäjätiliä ei kytketty';
	static PrintIsEmpty = 'Tuloste on tyhjä!';
	static RemoveLogisticVoucherCustomerPaymentSetting = 'Poista laskutusasetus';
	static WhistleBlowStateArchived = 'Arkistoitu';
	static ShowUserGroups = 'Näytä käyttäjäryhmät';
	static UserLastNameMustBeDefined = 'Käyttäjän sukunimi täytyy määritellä!';
	static SearchStartDate = 'Päiväys alku';
	static RemoveLogisticVoucherCompanyPaymentSetting = 'Poista laskutusasetus';
	static FilterIsActive = 'Suodatin on asetettu';
	static ExternalSystemUserCodeClaimNotFound = 'Käyttäjän ominaisuutta \'externalSystemUserCode\' ei löytynyt!';
	static AddPhoto = 'Lisää valokuva';
	static AddSalaryRowToMultipleUserRecords = 'Lisää palkkarivi useaan henkilöstökorttiin';
	static DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter = 'Ylläpitäjien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static AddingRecordUserSalaryRowsWasSuccessful = 'Palkkarivien lisäys onnistui!';
	static Voluntary = 'Vapaaehtoinen';
	static FolderParameterAlreadyExistsInFileSystem = 'Kansio \'{0}\' on jo olemassa tiedostojärjestelmässä!';
	static ValidityEndDate = 'Voimassa päättyen';
	static Partners = 'Kumppanit';
	static Characters = 'Merkkiä';
	static AddRecordUserToUserAccount = 'Lisää henkilöstökortti olemassaolevaan käyttäjätiliin';
	static EmptyDocument = 'Tyhjä dokumentti';
	static TimeSpanMonth = 'Kuukausi';
	static AddSharedDocumentTemplate = 'Lisää jaettu dokumenttimalli';
	static AddMessage = 'Lisää viesti';
	static RemoveTask = 'Poista tehtävä';
	static EditInstantMessageChannel = 'Muokkaa keskustelua';
	static SavingEventParameterWasSuccessful = 'Havainnon \'{0}\' tallennus onnistui!';
	static AddUserGroupParameterWasNotSuccessfulConcurrencyError = 'Käyttäjäryhmän \'{0}\' lisäys epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static UserRecordStatisticsExpiredFamiliarizations = 'Vanhentuneita perehdytyksiä';
	static ValidityEndDateEnd = 'Voimassa päättyen - loppu';
	static SavingCompanyGroupParameterWasNotSuccessfulErrorParameter = 'Yritysryhmän \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
	static TasksExcelByTasks = 'Tehtävät - Excel - Suodatettu tehtävittäin';
	static FilesParameterAreEmpty = 'Tiedostot \'{0}\' ovat tyhjiä!';
	static EventTypeBs = 'Tarkenne 2';
	static FolderParameterAlreadyExistsInTargetFolder = 'Kansio \'{0}\' löytyy jo kohdehakemistosta!';
	static LogoSquare = 'Pieni logo';
	static DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError = 'Peruskoulutuksen \'{0}\' poisto epäonnistui, koska peruskoulutuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SearchEvents = 'Hae havaintoja';
	static WhistleBlowArchivingTypeChangeStateAndRemoveInformantData = 'Siirrä ilmoitus Arkistoitu-tilaan ja poista ilmoittajan tiedot';
	static DoYouReallyWantToRemoveReportParameter = 'Haluatko todella poistaa raportin \'{0}\'?';
	static Admin = 'Ylläpitäjä';
	static TaskRemainderParameterNotFound = 'Muistutusta \'{0}\' ei löytynyt!';
	static Invoicing = 'Laskutustiedot';
	static AddCompanyGroup = 'Lisää yritysryhmä';
	static WebPushError = 'Web Push -virhe';
	static TrainingStartDateStart = 'Koulutus alkoi - alku';
	static Action = 'Toiminto';
	static UserCodeMustBeValid = 'Käyttäjätunnus täytyy olla vähintään 8 merkkiä pitkä eikä siinä saa olla välilyöntejä.';
	static UserCannotRecieveEmailNotifications = 'Käyttäjä ei vastaanota sähköposti-ilmoituksia';
	static LogisticVoucherCustomers = 'Asiakkaat';
	static DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter = 'Peruskoulutuksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
	static InstantMessages = 'Pikaviestit';
	static DeletingRecordUserEmploymentParameterWasSuccessful = 'Työpaikan \'{0}\' poisto onnistui!';
	static LogIn = 'Kirjaudu';
	static EventMessageConfirmation = 'Tallennetaanko uusi kommentti?';
	static SavingMessageSendEmailParameterWasSuccessful = 'Sähköpostilokin tallennus viestille \'{0}\' onnistui!';
	static EditKeyword = 'Muokkaa avainsanaa';
	static DeletingUserGroupParameterWasSuccessful = 'Käyttäjäryhmän \'{0}\' poisto onnistui!';
	static SaveFilter = 'Tallenna suodatin';
	static EditRecordUser = 'Muokkaa henkilöstökorttia';
	static DeletingFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' poisto tiedostojärjestelmästä epäonnistui! Virhe: \'{1}\'';
	static AttachmentImageSize = 'Liitekuvien esikatselukuvan koko';
	static UpdateCompanyExternalSystemsWasSuccessful = 'Yrityksen linkitys ulkoisiin järjestelmiin onnistui!';
	static FileUploadWasSuccessful = 'Tiedostojen lisäys onnistui!';
	static Hours = 'tuntia';
	static EditDefaultTaskState = 'Muokkaa tehtävän oletustilaa';
	static DoYouReallyWantToRemoveRecordFamiliarizationParameter = 'Haluatko todella poistaa perehdytyksen \'{0}\'?';
	static Invited = 'Kutsuttu';
	static ThisWeek = 'Tällä viikolla';
	static KeywordCodeMustBeDefined = 'Avainsanan tunnus täytyy määritellä!';
	static RenewPersonalization = 'Yksilöintitiedot';
	static DeletingInstantMessageChannelWasSuccessful = 'Keskustelun poisto onnistui!';
	static CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError = 'Kansion \'{0}\' kopioiminen kansioon \'{1}\' epäonnistui, koska kansion tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static SharedDocumentTemplates = 'Jaetut dokumenttimallit';
	static SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter = 'Dokumentin \'{0}\' julkaisu epäonnistui! Virhe: \'{1}\'';
	static SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError = 'Luovutetun omaisuuden \'{0}\' epäonnistui, koska luovutetun omaisuuden tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Reject = 'Kieltäydy';
	static UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted = 'Käyttäjäryhmä \'{0}\' on käytössä {1} käyttäjässä eikä sitä voi poistaa!';
	static TaskParameterNotFound = 'Tehtävää \'{0}\' ei löytynyt!';
	static PasswordMustContainDigitOrSpecialCharacter = 'Salasanan täytyy sisältää numero tai erikoismerkki!';
	static ExternalIntraSystemCompanyTokenMustBeDefined = 'Yritys-token eSalkku-järjestelmässä täytyy määritellä!';
	static StudyWeek = 'opintoviikko';
	static Removed = 'Poistettu';
	static Unread = 'Lukematta';
	static HoursPerTrainingDay = 'Tunteja koulutuspäivässä';
	static DirectoryItemDocumentParameterNotFound = 'Tiedostoa \'{0}\' ei löytynyt!';
	static LogisticVoucher = 'Kuljetusten tilitysjärjestelmä';
	static PasteDefaultHandlers = 'Liitä oletuskäsittelijät';
	static WhistleBlowResolutionCanceled = 'Peruutettu';
	static NewMenu = 'Uusi valikko';
	static Move = 'Siirrä';
	static TaskPriority = 'Prioriteetti';
	static AddUserGroupParameterWasSuccessful = 'Käyttäjäryhmän \'{0}\' lisäys onnistui!';
	static CompanyThatIsInUseCannotBeDeleted = 'Yritystä, jonka tila on \'Käytössä\', ei voi poistaa!';
	static SaveEventHandlerConfirmation = 'Haluatko tallentaa havainnon?';
	static WebPageStyle = 'Sivuston tyyli';
	static Last120Days = '120 viime päivää';
	static SavingDefaultHandlersWasSuccessful = 'Oletuskäsittelijöiden tallennus onnistui!';
	static ExternalSystemCompanyCodeMustBeDefined = 'Yrityksen tunnus muussa järjestelmässä täytyy määritellä!';
	static DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError = 'Oletuskäsittelijän \'{0}\' poisto epäonnistui, koska oletuskäsittelijän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static EditLogisticVoucherCustomerPaymentSetting = 'Muokkaa laskutusasetusta';
	static EditRow = 'Muokka riviä';
	static PanelSize3 = '1 x 2';
	static UserSessionHasBeenExpired = 'Käyttäjäistunto on vanhentunut!';
	static LogEventType = 'Lokilaji';
	static SavingOptionParameterWasSuccessful = 'Sovellusasetuksen \'{0}\' tallennus onnistui!';
	static MovingFolderParameterWasNotSuccessfulErrorParameter = 'Kansion \'{0}\' siirtäminen tiedostojärjestelmässä kansioon \'{1}\' epäonnistui! Virhe: \'{2}\'';
	static SavingReadReceiptWasNotSuccessfulErrorParameter = 'Lukukuittauksen tallennus epäonnistui! Virhe: \'{0}\'';
	static DoYouReallyWantToExitInstantMessageChannel = 'Haluatko todella poistua keskustelusta?';
	static GroupByEducation = 'Koulutuksittain';
	static UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError = 'Yrityksen linkitys ulkoisiin järjestelmiin epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static Source = 'Lähde';
	static TaskType = 'Tehtävätyyppi';
	static EventTypeB = 'Tarkenne 2';
	static ReadingDatabaseParameter = 'Lukee tietokantaa \'{0}\'...';
	static AddFileFilter = 'Lisää tiedostosuodatin';
	static AddEventUrgencyClass = 'Lisää kiireellisyysluokka';
	static AddUserParameterWasNotSuccessfulErrorParameter = 'Käyttäjätilin \'{0}\' lisäys epäonnistui! Virhe: \'{1}\'';
	static Filters = 'Suodattimet';
	static TransferToChannel = 'Siirry keskusteluun';
	static DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError = 'Jatkokoulutuksen \'{0}\' poisto epäonnistui, koska jatkokoulutuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static WhistleBlowStatisticsRecordCount = 'Aktiivisia ilmoituksia';
	static Choose = 'Valitse';
	static RemoveCompany = 'Poista yritys';
	static SavingRecordFamiliarizationParameterWasSuccessful = 'Perehdytyksen \'{0}\' tallennus onnistui!';
	static UserFilterNoUserGroup = 'Käyttäjäryhmää ei valittu';
	static SavingMessageSendPushParameterWasSuccessful = 'Push-viestilokin tallennus viestille \'{0}\' onnistui!';
	static UserIsWhistleBlowChannelAdminAndCannotBeDeleted = 'Käyttäjä on ilmoituskanavan ylläpitäjä eikä käyttäjää voi siksi poistaa!';
	static TrainingStartDate = 'Koulutus alkoi';
	static EmptyTaskTrashBin = 'Tyhjännä tehtävien roskakori';
	static Familiarizations = 'Perehdytykset';
	static SavingReportWasNotSuccessfulConcurrencyError = 'Raportin tallennus epäonnistui, koska raportin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
	static OpenWhistleBlow = 'Avaa ilmoitus';
	static Last30Days = '30 viime päivää';
	static AccountNumberTkbInCredit = 'Myyntitili';
	static UserGroupNameIsAlreadyInUse = 'Käyttäjäryhmän nimi on jo käytössä!';
	static UserGroupNameMustBeDefined = 'Käyttäjäryhmän nimi täytyy määritellä!';
	static AccessRightRead = 'Oikeus lukea sisältöä';
}

export class Translations {
	static DeletingRecordUserParameterWasSuccessful: string = Translations_fi.DeletingRecordUserParameterWasSuccessful;
	static SavingEditableDocumentParameterApprovalWasSuccessful: string = Translations_fi.SavingEditableDocumentParameterApprovalWasSuccessful;
	static DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError;
	static CompanyNumber: string = Translations_fi.CompanyNumber;
	static SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError;
	static DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments: string = Translations_fi.DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments;
	static UserRecords: string = Translations_fi.UserRecords;
	static MobileAppInUse: string = Translations_fi.MobileAppInUse;
	static SendingPushNotificationsToParameter: string = Translations_fi.SendingPushNotificationsToParameter;
	static Competency: string = Translations_fi.Competency;
	static DoYouReallyWantToClearLog: string = Translations_fi.DoYouReallyWantToClearLog;
	static And: string = Translations_fi.And;
	static UpdatingGroupCodeWasNotSuccessfulErrorParameter: string = Translations_fi.UpdatingGroupCodeWasNotSuccessfulErrorParameter;
	static End: string = Translations_fi.End;
	static UserReports: string = Translations_fi.UserReports;
	static GroupByFamiliarization: string = Translations_fi.GroupByFamiliarization;
	static NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded: string = Translations_fi.NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded;
	static DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError;
	static ShowOnMap: string = Translations_fi.ShowOnMap;
	static CloseSystem: string = Translations_fi.CloseSystem;
	static SaveReport: string = Translations_fi.SaveReport;
	static AddToFavorites: string = Translations_fi.AddToFavorites;
	static ClickToChangeTheLogo: string = Translations_fi.ClickToChangeTheLogo;
	static DoYouReallyWantToRemoveEventPartnerParameter: string = Translations_fi.DoYouReallyWantToRemoveEventPartnerParameter;
	static RemoveTaskPriority: string = Translations_fi.RemoveTaskPriority;
	static ANew: string = Translations_fi.ANew;
	static FamiliarizationTrainer: string = Translations_fi.FamiliarizationTrainer;
	static NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded: string = Translations_fi.NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded;
	static StartDate: string = Translations_fi.StartDate;
	static ResetDocumentVersion: string = Translations_fi.ResetDocumentVersion;
	static TogglePreviewPanel: string = Translations_fi.TogglePreviewPanel;
	static WhistleBlowSeverityLow: string = Translations_fi.WhistleBlowSeverityLow;
	static RemoveDefaultFurtherEducation: string = Translations_fi.RemoveDefaultFurtherEducation;
	static RemoveDefaultTaskPriority: string = Translations_fi.RemoveDefaultTaskPriority;
	static TasksFound: string = Translations_fi.TasksFound;
	static SavingWhistleBlowMessageWasNotSuccessfulErrorParameter: string = Translations_fi.SavingWhistleBlowMessageWasNotSuccessfulErrorParameter;
	static FirstName: string = Translations_fi.FirstName;
	static DocumentApproved: string = Translations_fi.DocumentApproved;
	static RecordUsersFound: string = Translations_fi.RecordUsersFound;
	static UserFirstNameMustBeDefined: string = Translations_fi.UserFirstNameMustBeDefined;
	static RecordUserReport: string = Translations_fi.RecordUserReport;
	static AddLogisticVoucherTransportCompany: string = Translations_fi.AddLogisticVoucherTransportCompany;
	static RemoveTarget: string = Translations_fi.RemoveTarget;
	static Comment: string = Translations_fi.Comment;
	static EventReport: string = Translations_fi.EventReport;
	static DeletingRecordBasicEducationsParameterWasSuccessful: string = Translations_fi.DeletingRecordBasicEducationsParameterWasSuccessful;
	static EventApprovalWasSuccessful: string = Translations_fi.EventApprovalWasSuccessful;
	static Visibility: string = Translations_fi.Visibility;
	static LockedEventParameterCannotBeDeleted: string = Translations_fi.LockedEventParameterCannotBeDeleted;
	static WhistleBlowStatisticsResolvedRecords: string = Translations_fi.WhistleBlowStatisticsResolvedRecords;
	static AccessRightReadTasks: string = Translations_fi.AccessRightReadTasks;
	static Copy: string = Translations_fi.Copy;
	static UserGroupCodeMustBeDefined: string = Translations_fi.UserGroupCodeMustBeDefined;
	static WhistleBlowUrl: string = Translations_fi.WhistleBlowUrl;
	static Favorites: string = Translations_fi.Favorites;
	static SavingRecordUserQualificationParameterWasSuccessful: string = Translations_fi.SavingRecordUserQualificationParameterWasSuccessful;
	static NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded: string = Translations_fi.NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded;
	static AddingRecordUserFamiliarizationsWasSuccessful: string = Translations_fi.AddingRecordUserFamiliarizationsWasSuccessful;
	static DoYouReallyWantToRemoveSelectedTaskTypes: string = Translations_fi.DoYouReallyWantToRemoveSelectedTaskTypes;
	static AccountIBANNumber: string = Translations_fi.AccountIBANNumber;
	static PersonalIdentityCode: string = Translations_fi.PersonalIdentityCode;
	static FileStorageQuotaGB: string = Translations_fi.FileStorageQuotaGB;
	static Archived: string = Translations_fi.Archived;
	static LockedTaskParameterCannotBeDeleted: string = Translations_fi.LockedTaskParameterCannotBeDeleted;
	static SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError;
	static TaskHandlers: string = Translations_fi.TaskHandlers;
	static AddEventTypeBsToEvent: string = Translations_fi.AddEventTypeBsToEvent;
	static RemoveRecordUser: string = Translations_fi.RemoveRecordUser;
	static DefaultEventUrgencyClassParameterCannotBeDeleted: string = Translations_fi.DefaultEventUrgencyClassParameterCannotBeDeleted;
	static Saved: string = Translations_fi.Saved;
	static SavingUnitPermissionsWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingUnitPermissionsWasNotSuccessfulConcurrencyError;
	static SaveReportAs: string = Translations_fi.SaveReportAs;
	static OK: string = Translations_fi.OK;
	static Rows: string = Translations_fi.Rows;
	static AddUserGroupParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddUserGroupParameterWasNotSuccessfulErrorParameter;
	static MovedToTrashBin: string = Translations_fi.MovedToTrashBin;
	static AllUsersOfTheUnit: string = Translations_fi.AllUsersOfTheUnit;
	static ChangedEventUnit: string = Translations_fi.ChangedEventUnit;
	static SavingApplicationPermissionsWasNotSuccessfulErrorParameter: string = Translations_fi.SavingApplicationPermissionsWasNotSuccessfulErrorParameter;
	static PanelVisible: string = Translations_fi.PanelVisible;
	static Effectiveness: string = Translations_fi.Effectiveness;
	static AddChildEventTypeB: string = Translations_fi.AddChildEventTypeB;
	static AddNew: string = Translations_fi.AddNew;
	static AdditionalFunctions: string = Translations_fi.AdditionalFunctions;
	static DeletingUserGroupsParameterWasSuccessful: string = Translations_fi.DeletingUserGroupsParameterWasSuccessful;
	static MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter;
	static SelectUser: string = Translations_fi.SelectUser;
	static WhistleBlowStatisticsAnonymousRecords: string = Translations_fi.WhistleBlowStatisticsAnonymousRecords;
	static OldPasswordIsIncorrect: string = Translations_fi.OldPasswordIsIncorrect;
	static StandardFiltersAndReports: string = Translations_fi.StandardFiltersAndReports;
	static ClassParameterHasNotBeenInitialized: string = Translations_fi.ClassParameterHasNotBeenInitialized;
	static Permissions: string = Translations_fi.Permissions;
	static NoEventsToShow: string = Translations_fi.NoEventsToShow;
	static RemoveRow: string = Translations_fi.RemoveRow;
	static ZipFileParameterNotFound: string = Translations_fi.ZipFileParameterNotFound;
	static EventIsApprovedByUser: string = Translations_fi.EventIsApprovedByUser;
	static DocumentNameConstainsIllegalCharacters: string = Translations_fi.DocumentNameConstainsIllegalCharacters;
	static WhistleBlowStatisticsReceivedRecords: string = Translations_fi.WhistleBlowStatisticsReceivedRecords;
	static SavingEventTypeBsWasSuccessful: string = Translations_fi.SavingEventTypeBsWasSuccessful;
	static AddImageFromClipboard: string = Translations_fi.AddImageFromClipboard;
	static DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError;
	static EditEventType: string = Translations_fi.EditEventType;
	static DeletingEventTypeParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingEventTypeParameterWasNotSuccessfulErrorParameter;
	static AddFamiliarizations: string = Translations_fi.AddFamiliarizations;
	static SelectFurtherEducation: string = Translations_fi.SelectFurtherEducation;
	static DeletingWhistleBlowCategoriesParameterWasSuccessful: string = Translations_fi.DeletingWhistleBlowCategoriesParameterWasSuccessful;
	static MessageVisibilityPeriodNotActive: string = Translations_fi.MessageVisibilityPeriodNotActive;
	static CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful: string = Translations_fi.CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful;
	static EditPermission: string = Translations_fi.EditPermission;
	static RemovingWhistleBlowHandlersWasSuccessful: string = Translations_fi.RemovingWhistleBlowHandlersWasSuccessful;
	static AddPrint: string = Translations_fi.AddPrint;
	static RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError: string = Translations_fi.RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError;
	static PaymentSettings: string = Translations_fi.PaymentSettings;
	static LockedTaskParameterCannotBeMovedToTrashBin: string = Translations_fi.LockedTaskParameterCannotBeMovedToTrashBin;
	static ChooseHowYouWantToReport: string = Translations_fi.ChooseHowYouWantToReport;
	static CompanyInstantMessageChannels: string = Translations_fi.CompanyInstantMessageChannels;
	static DeletingUsersParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingUsersParameterWasNotSuccessfulConcurrencyError;
	static AddEventType: string = Translations_fi.AddEventType;
	static Day: string = Translations_fi.Day;
	static DoYouReallyWantToRemoveDocumentParameter: string = Translations_fi.DoYouReallyWantToRemoveDocumentParameter;
	static EditFileFilter: string = Translations_fi.EditFileFilter;
	static DeletingFileParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingFileParameterWasNotSuccessfulErrorParameter;
	static UserEndDate: string = Translations_fi.UserEndDate;
	static SavingOneTimePasswordParameterWasSuccessful: string = Translations_fi.SavingOneTimePasswordParameterWasSuccessful;
	static NewVersionAvailable: string = Translations_fi.NewVersionAvailable;
	static DeletingUserGroupParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingUserGroupParameterWasNotSuccessfulConcurrencyError;
	static NotRepeated: string = Translations_fi.NotRepeated;
	static ReplyToInstantMessage: string = Translations_fi.ReplyToInstantMessage;
	static IgnoreFilePatterns: string = Translations_fi.IgnoreFilePatterns;
	static CopyPermissionToAllSameLevelFolders: string = Translations_fi.CopyPermissionToAllSameLevelFolders;
	static SelectState: string = Translations_fi.SelectState;
	static UserFilterActive: string = Translations_fi.UserFilterActive;
	static ProjectNumber: string = Translations_fi.ProjectNumber;
	static EditableDocumentCodeMustBeDefined: string = Translations_fi.EditableDocumentCodeMustBeDefined;
	static DoYouReallyWantToRemoveRecordUserEmploymentParameter: string = Translations_fi.DoYouReallyWantToRemoveRecordUserEmploymentParameter;
	static UserRecordStatisticsExpiringInSixMonthsFamiliarizations: string = Translations_fi.UserRecordStatisticsExpiringInSixMonthsFamiliarizations;
	static PublishVoucherToFileSystem: string = Translations_fi.PublishVoucherToFileSystem;
	static WhistleBlowStatisticsNewRecords: string = Translations_fi.WhistleBlowStatisticsNewRecords;
	static NewInstantMessageChannelGroupInternal: string = Translations_fi.NewInstantMessageChannelGroupInternal;
	static ArchiveExpiredFamiliarizations: string = Translations_fi.ArchiveExpiredFamiliarizations;
	static AuthorizationLevel: string = Translations_fi.AuthorizationLevel;
	static Locked: string = Translations_fi.Locked;
	static MarkMessageAsRead: string = Translations_fi.MarkMessageAsRead;
	static AuthorizationLevelAdmin: string = Translations_fi.AuthorizationLevelAdmin;
	static TaskTypesMustBeDefinedBeforeAddingTasks: string = Translations_fi.TaskTypesMustBeDefinedBeforeAddingTasks;
	static SavingEventTypeBParameterWasSuccessful: string = Translations_fi.SavingEventTypeBParameterWasSuccessful;
	static InstantMessageAttachmentFolderIsNotSpecified: string = Translations_fi.InstantMessageAttachmentFolderIsNotSpecified;
	static DirectoryItemFolderParameterNotFound: string = Translations_fi.DirectoryItemFolderParameterNotFound;
	static TasksExcelByTaskRows: string = Translations_fi.TasksExcelByTaskRows;
	static UserRecordStatisticsExpiringInSixMonthsQualifications: string = Translations_fi.UserRecordStatisticsExpiringInSixMonthsQualifications;
	static WhistleBlowStateInProgress: string = Translations_fi.WhistleBlowStateInProgress;
	static LockingTaskDataRowWasNotSuccessful: string = Translations_fi.LockingTaskDataRowWasNotSuccessful;
	static ExpiredPlural: string = Translations_fi.ExpiredPlural;
	static ACopy: string = Translations_fi.ACopy;
	static EventPartnerIsAlreadyInDefined: string = Translations_fi.EventPartnerIsAlreadyInDefined;
	static PublishingTaskTypeParameterWasSuccessful: string = Translations_fi.PublishingTaskTypeParameterWasSuccessful;
	static WhistleBlowInformantTitle: string = Translations_fi.WhistleBlowInformantTitle;
	static ChartType: string = Translations_fi.ChartType;
	static AddCommentsToEvent: string = Translations_fi.AddCommentsToEvent;
	static DeletingCompanyParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingCompanyParameterWasNotSuccessfulErrorParameter;
	static WhistleBlowCategoryParameterNotFound: string = Translations_fi.WhistleBlowCategoryParameterNotFound;
	static UnlockingEventParameterWasNotSuccessful: string = Translations_fi.UnlockingEventParameterWasNotSuccessful;
	static AddEventHandlersToEvent: string = Translations_fi.AddEventHandlersToEvent;
	static File: string = Translations_fi.File;
	static EditEventTypeCs: string = Translations_fi.EditEventTypeCs;
	static SavingEventTypeCsWasSuccessful: string = Translations_fi.SavingEventTypeCsWasSuccessful;
	static WhistleBlowRecords: string = Translations_fi.WhistleBlowRecords;
	static SavingInstantMessageChannelUserUserWasSuccessful: string = Translations_fi.SavingInstantMessageChannelUserUserWasSuccessful;
	static DoCleanLog: string = Translations_fi.DoCleanLog;
	static KeywordsOrCondition: string = Translations_fi.KeywordsOrCondition;
	static FilterCondition: string = Translations_fi.FilterCondition;
	static RemoveDefaultHandler: string = Translations_fi.RemoveDefaultHandler;
	static SavingKeywordParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingKeywordParameterWasNotSuccessfulConcurrencyError;
	static DeletingEventTypeCParameterWasSuccessful: string = Translations_fi.DeletingEventTypeCParameterWasSuccessful;
	static FolderParameterAlreadyExists: string = Translations_fi.FolderParameterAlreadyExists;
	static EventUrgencyClasses: string = Translations_fi.EventUrgencyClasses;
	static EventTypeBCodeIsAlreadyInUse: string = Translations_fi.EventTypeBCodeIsAlreadyInUse;
	static WhistleBlowDashboard: string = Translations_fi.WhistleBlowDashboard;
	static WhistleBlowAnonymityNonAnonymous: string = Translations_fi.WhistleBlowAnonymityNonAnonymous;
	static InheritedUserRights: string = Translations_fi.InheritedUserRights;
	static Entrepreneur: string = Translations_fi.Entrepreneur;
	static DeletingWhistleBlowChannelAdminsParameterWasSuccessful: string = Translations_fi.DeletingWhistleBlowChannelAdminsParameterWasSuccessful;
	static SavingFilterWasSuccessful: string = Translations_fi.SavingFilterWasSuccessful;
	static UserIsCompanyAdminClaimNotFound: string = Translations_fi.UserIsCompanyAdminClaimNotFound;
	static ThisMessageWasDeleted: string = Translations_fi.ThisMessageWasDeleted;
	static DeletingAttachmentsWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingAttachmentsWasNotSuccessfulErrorParameter;
	static YouHaveNotSavedChangesDoYouWantToSaveChanges: string = Translations_fi.YouHaveNotSavedChangesDoYouWantToSaveChanges;
	static SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError;
	static DependencyResolvingFailedForInterfaceParameter: string = Translations_fi.DependencyResolvingFailedForInterfaceParameter;
	static WhistleBlowInformantMessageConfirmation: string = Translations_fi.WhistleBlowInformantMessageConfirmation;
	static WriteBelowFieldWordParameterToConfirmTheOperation: string = Translations_fi.WriteBelowFieldWordParameterToConfirmTheOperation;
	static FrontPage: string = Translations_fi.FrontPage;
	static UserHasUsedMobile: string = Translations_fi.UserHasUsedMobile;
	static Administrator: string = Translations_fi.Administrator;
	static UserFilterInActive: string = Translations_fi.UserFilterInActive;
	static MessageParameterNotFound: string = Translations_fi.MessageParameterNotFound;
	static EditEventTypes: string = Translations_fi.EditEventTypes;
	static IdCard: string = Translations_fi.IdCard;
	static Users: string = Translations_fi.Users;
	static RenewPersonalizationWithUser: string = Translations_fi.RenewPersonalizationWithUser;
	static AccessRightWriteEvents: string = Translations_fi.AccessRightWriteEvents;
	static AdditionalPermissionAMessage: string = Translations_fi.AdditionalPermissionAMessage;
	static SavingEventPartnerParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventPartnerParameterWasNotSuccessfulConcurrencyError;
	static DocumentModified: string = Translations_fi.DocumentModified;
	static DocumentNameParameterIsIllegal: string = Translations_fi.DocumentNameParameterIsIllegal;
	static DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError;
	static RestoringInstantMessageWasNotSuccessfulErrorParameter: string = Translations_fi.RestoringInstantMessageWasNotSuccessfulErrorParameter;
	static EditableDocumentUnpublishConfirmation: string = Translations_fi.EditableDocumentUnpublishConfirmation;
	static SelectCompanyGroup: string = Translations_fi.SelectCompanyGroup;
	static ActiveState: string = Translations_fi.ActiveState;
	static TaskTypeCodeMustBeDefined: string = Translations_fi.TaskTypeCodeMustBeDefined;
	static DeletingInstantMessageUserParameterWasSuccessful: string = Translations_fi.DeletingInstantMessageUserParameterWasSuccessful;
	static BasicInformation: string = Translations_fi.BasicInformation;
	static WorkShift: string = Translations_fi.WorkShift;
	static InvalidValueInArgumentParameter: string = Translations_fi.InvalidValueInArgumentParameter;
	static NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded: string = Translations_fi.NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded;
	static WhistleBlowSourceOral: string = Translations_fi.WhistleBlowSourceOral;
	static AddingTaskTypeParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddingTaskTypeParameterWasNotSuccessfulErrorParameter;
	static WageGroup: string = Translations_fi.WageGroup;
	static Valid: string = Translations_fi.Valid;
	static Line: string = Translations_fi.Line;
	static RecordUserLogEntryTypeSave: string = Translations_fi.RecordUserLogEntryTypeSave;
	static ChangeSystemLanguageToEnglish: string = Translations_fi.ChangeSystemLanguageToEnglish;
	static WhistleBlowInformantHint: string = Translations_fi.WhistleBlowInformantHint;
	static CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits: string = Translations_fi.CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits;
	static SavingCompanyParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingCompanyParameterWasNotSuccessfulErrorParameter;
	static Intern: string = Translations_fi.Intern;
	static UserGroupAll: string = Translations_fi.UserGroupAll;
	static SavingEventTypeParameterWasSuccessful: string = Translations_fi.SavingEventTypeParameterWasSuccessful;
	static SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToRestoreThisInstantMessage: string = Translations_fi.DoYouReallyWantToRestoreThisInstantMessage;
	static AddPanel: string = Translations_fi.AddPanel;
	static NotInUse: string = Translations_fi.NotInUse;
	static CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToUnlockTask: string = Translations_fi.DoYouReallyWantToUnlockTask;
	static SavingApplicationPermissionsWasSuccessful: string = Translations_fi.SavingApplicationPermissionsWasSuccessful;
	static Competencies: string = Translations_fi.Competencies;
	static EventUrgencyClass: string = Translations_fi.EventUrgencyClass;
	static SavingEditableDocumentParameterWasSuccessful: string = Translations_fi.SavingEditableDocumentParameterWasSuccessful;
	static UserRecordStatisticsSavedCards: string = Translations_fi.UserRecordStatisticsSavedCards;
	static AllDocumentTypes: string = Translations_fi.AllDocumentTypes;
	static SavePrintAs: string = Translations_fi.SavePrintAs;
	static EmailingMessageWasNotSuccessfulErrorParameter: string = Translations_fi.EmailingMessageWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToRemoveTaskParameterPermanenty: string = Translations_fi.DoYouReallyWantToRemoveTaskParameterPermanenty;
	static NewInstantMessageChannelGroup: string = Translations_fi.NewInstantMessageChannelGroup;
	static FileRemovalWasSuccessful: string = Translations_fi.FileRemovalWasSuccessful;
	static SavingRecordUserParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingRecordUserParameterWasNotSuccessfulConcurrencyError;
	static FamiliarizationStartDateDateEnd: string = Translations_fi.FamiliarizationStartDateDateEnd;
	static InUse: string = Translations_fi.InUse;
	static MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter: string = Translations_fi.MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter;
	static Number: string = Translations_fi.Number;
	static DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError;
	static RecordUserLogEntryTypeReport: string = Translations_fi.RecordUserLogEntryTypeReport;
	static Panels: string = Translations_fi.Panels;
	static OthersLastModifications: string = Translations_fi.OthersLastModifications;
	static DoYouReallyWantToRemoveSharedDocumentTemplateParameter: string = Translations_fi.DoYouReallyWantToRemoveSharedDocumentTemplateParameter;
	static EditDefaultHandlers: string = Translations_fi.EditDefaultHandlers;
	static AddQualifications: string = Translations_fi.AddQualifications;
	static DeletingFileFilterParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingFileFilterParameterWasNotSuccessfulErrorParameter;
	static Add: string = Translations_fi.Add;
	static EventUrgencyClassParameterNotFound: string = Translations_fi.EventUrgencyClassParameterNotFound;
	static EditEventTypeC: string = Translations_fi.EditEventTypeC;
	static AddLogisticVoucherCustomer: string = Translations_fi.AddLogisticVoucherCustomer;
	static SelectInstantMessageChannelType: string = Translations_fi.SelectInstantMessageChannelType;
	static DoYouReallyWantToRemoveEventTypeBParameter: string = Translations_fi.DoYouReallyWantToRemoveEventTypeBParameter;
	static CompanyName: string = Translations_fi.CompanyName;
	static RecordUserLogEntryTypeOpen: string = Translations_fi.RecordUserLogEntryTypeOpen;
	static RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter: string = Translations_fi.RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter;
	static AddPartnersByClickingPlusButton: string = Translations_fi.AddPartnersByClickingPlusButton;
	static SavingChartWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingChartWasNotSuccessfulConcurrencyError;
	static DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter;
	static ContentFiltering: string = Translations_fi.ContentFiltering;
	static ApproveEventResolution: string = Translations_fi.ApproveEventResolution;
	static LockingEventParameterWasNotSuccessful: string = Translations_fi.LockingEventParameterWasNotSuccessful;
	static Report: string = Translations_fi.Report;
	static SourceFolderAndTargetFolderAreSame: string = Translations_fi.SourceFolderAndTargetFolderAreSame;
	static SavingCompanyParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingCompanyParameterWasNotSuccessfulConcurrencyError;
	static AddRecordUserWithoutUserAccount: string = Translations_fi.AddRecordUserWithoutUserAccount;
	static WhistleBlowCategoryCodeIsAlreadyInUse: string = Translations_fi.WhistleBlowCategoryCodeIsAlreadyInUse;
	static SavingChartWasNotSuccessfulErrorParameter: string = Translations_fi.SavingChartWasNotSuccessfulErrorParameter;
	static SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError;
	static LockedTaskParameterCannotBeRestored: string = Translations_fi.LockedTaskParameterCannotBeRestored;
	static UserFilterExpired: string = Translations_fi.UserFilterExpired;
	static DoYouReallyWantToRemoveSelectedRecordBasicEducations: string = Translations_fi.DoYouReallyWantToRemoveSelectedRecordBasicEducations;
	static AddCompanyGroups: string = Translations_fi.AddCompanyGroups;
	static DoYouReallyWantToRemoveAttachmentParameter: string = Translations_fi.DoYouReallyWantToRemoveAttachmentParameter;
	static SavingOneTimePasswordWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingOneTimePasswordWasNotSuccessfulConcurrencyError;
	static SaveEvent: string = Translations_fi.SaveEvent;
	static Select: string = Translations_fi.Select;
	static WhistleBlowNonAnonymousReportInfo: string = Translations_fi.WhistleBlowNonAnonymousReportInfo;
	static AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter: string = Translations_fi.AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter;
	static EditLogisticVoucherPaymentSetting: string = Translations_fi.EditLogisticVoucherPaymentSetting;
	static ReadImp: string = Translations_fi.ReadImp;
	static AddKeyword: string = Translations_fi.AddKeyword;
	static DocumentRootPath: string = Translations_fi.DocumentRootPath;
	static DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError;
	static UserRecordStatisticsExpiredQualifications: string = Translations_fi.UserRecordStatisticsExpiredQualifications;
	static PropertyTransferred: string = Translations_fi.PropertyTransferred;
	static Images: string = Translations_fi.Images;
	static CompanyCodeIsAlreadyInUse: string = Translations_fi.CompanyCodeIsAlreadyInUse;
	static ToDo: string = Translations_fi.ToDo;
	static DeletingRecordUserTransferredPropertyParameterWasSuccessful: string = Translations_fi.DeletingRecordUserTransferredPropertyParameterWasSuccessful;
	static CreatingNotifications: string = Translations_fi.CreatingNotifications;
	static RemoveRemainder: string = Translations_fi.RemoveRemainder;
	static Events: string = Translations_fi.Events;
	static EditableDocumentApproveConfirmation: string = Translations_fi.EditableDocumentApproveConfirmation;
	static DocumentTemplates: string = Translations_fi.DocumentTemplates;
	static SavingWhistleBlowWasNotSuccessfulErrorParameter: string = Translations_fi.SavingWhistleBlowWasNotSuccessfulErrorParameter;
	static Theme: string = Translations_fi.Theme;
	static AddUsers: string = Translations_fi.AddUsers;
	static SaveUserFurtherEducation: string = Translations_fi.SaveUserFurtherEducation;
	static DeletingMessageParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingMessageParameterWasNotSuccessfulErrorParameter;
	static Open: string = Translations_fi.Open;
	static DeletingTaskPriorityParameterWasSuccessful: string = Translations_fi.DeletingTaskPriorityParameterWasSuccessful;
	static DoYouReallyWantToRemoveSelectedEventUrgencyClasses: string = Translations_fi.DoYouReallyWantToRemoveSelectedEventUrgencyClasses;
	static SavingImagesNotSuccessfulErrorParameter: string = Translations_fi.SavingImagesNotSuccessfulErrorParameter;
	static City: string = Translations_fi.City;
	static SavingUserParametersWasSuccessful: string = Translations_fi.SavingUserParametersWasSuccessful;
	static ShowInheritedPermissions: string = Translations_fi.ShowInheritedPermissions;
	static CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter;
	static WhistleBlowNewReport: string = Translations_fi.WhistleBlowNewReport;
	static UnitHandlingIncomingEvents: string = Translations_fi.UnitHandlingIncomingEvents;
	static ExpiredFurtherEducations: string = Translations_fi.ExpiredFurtherEducations;
	static SaveAndSend: string = Translations_fi.SaveAndSend;
	static DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError;
	static FurtherEducationsMustBeDefinedBeforeAddingRecordUsers: string = Translations_fi.FurtherEducationsMustBeDefinedBeforeAddingRecordUsers;
	static DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter;
	static AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter;
	static DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError;
	static Familiarization: string = Translations_fi.Familiarization;
	static Draft: string = Translations_fi.Draft;
	static JobTypeEventNotification: string = Translations_fi.JobTypeEventNotification;
	static DateMustBeDefined: string = Translations_fi.DateMustBeDefined;
	static SetPermissions: string = Translations_fi.SetPermissions;
	static WhistleBlowStateReceived: string = Translations_fi.WhistleBlowStateReceived;
	static GroupByPerson: string = Translations_fi.GroupByPerson;
	static EventTypeCodeIsAlreadyInUse: string = Translations_fi.EventTypeCodeIsAlreadyInUse;
	static DoYouReallyWantToRemoveFolderParameter: string = Translations_fi.DoYouReallyWantToRemoveFolderParameter;
	static AuthorizationLevelSuperUser: string = Translations_fi.AuthorizationLevelSuperUser;
	static DoYouReallyWantToRemovePartnersParameter: string = Translations_fi.DoYouReallyWantToRemovePartnersParameter;
	static Print: string = Translations_fi.Print;
	static DoYouReallyWantToRemoveRemainderParameter: string = Translations_fi.DoYouReallyWantToRemoveRemainderParameter;
	static FamiliarizationStartDate: string = Translations_fi.FamiliarizationStartDate;
	static SearchCurrentTopics: string = Translations_fi.SearchCurrentTopics;
	static DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter;
	static RemoveFolder: string = Translations_fi.RemoveFolder;
	static ComparingFileSystemToDatabase: string = Translations_fi.ComparingFileSystemToDatabase;
	static SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter;
	static MissingPlural: string = Translations_fi.MissingPlural;
	static DeletingOneTimePasswordWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingOneTimePasswordWasNotSuccessfulConcurrencyError;
	static NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded: string = Translations_fi.NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded;
	static UserRecordStatisticsExpiredFurtherEducations: string = Translations_fi.UserRecordStatisticsExpiredFurtherEducations;
	static RecordBasicEducationParameterNotFound: string = Translations_fi.RecordBasicEducationParameterNotFound;
	static AdditionalPermissionAEvents: string = Translations_fi.AdditionalPermissionAEvents;
	static EInvoiceOperator: string = Translations_fi.EInvoiceOperator;
	static AccountNumberTkbOutDebit: string = Translations_fi.AccountNumberTkbOutDebit;
	static Settings: string = Translations_fi.Settings;
	static FileFilterCodeMustBeDefined: string = Translations_fi.FileFilterCodeMustBeDefined;
	static DoYouReallyWantToRemoveChartPanelParameter: string = Translations_fi.DoYouReallyWantToRemoveChartPanelParameter;
	static ClosedEvent: string = Translations_fi.ClosedEvent;
	static FamiliarizationTime: string = Translations_fi.FamiliarizationTime;
	static AddPartnerParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.AddPartnerParameterWasNotSuccessfulConcurrencyError;
	static EmailSendDate: string = Translations_fi.EmailSendDate;
	static Employment: string = Translations_fi.Employment;
	static DeletingKeywordParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingKeywordParameterWasNotSuccessfulErrorParameter;
	static PageFooterText: string = Translations_fi.PageFooterText;
	static InstantMessageChannelUsers: string = Translations_fi.InstantMessageChannelUsers;
	static DeletingPartnersParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingPartnersParameterWasNotSuccessfulConcurrencyError;
	static TaskPriorities: string = Translations_fi.TaskPriorities;
	static DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError;
	static EditMode: string = Translations_fi.EditMode;
	static NotificationsSentSuccessfully: string = Translations_fi.NotificationsSentSuccessfully;
	static DoYouReallyWantToRemoveTaskDataRow: string = Translations_fi.DoYouReallyWantToRemoveTaskDataRow;
	static CompanyGroupCodeMustBeDefined: string = Translations_fi.CompanyGroupCodeMustBeDefined;
	static DoYouReallyWantToRemoveTaskTypeParameter: string = Translations_fi.DoYouReallyWantToRemoveTaskTypeParameter;
	static FileParameterDownloaded: string = Translations_fi.FileParameterDownloaded;
	static AddPartnerCompanies: string = Translations_fi.AddPartnerCompanies;
	static RemoveRecordUserAndUserSetExpired: string = Translations_fi.RemoveRecordUserAndUserSetExpired;
	static SavingUnitPermissionsWasSuccessful: string = Translations_fi.SavingUnitPermissionsWasSuccessful;
	static SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError;
	static Companies: string = Translations_fi.Companies;
	static NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded: string = Translations_fi.NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded;
	static DoYouReallyWantToRemoveEventUrgencyClassParameter: string = Translations_fi.DoYouReallyWantToRemoveEventUrgencyClassParameter;
	static SavingWhistleBlowWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingWhistleBlowWasNotSuccessfulConcurrencyError;
	static ThisPartOfApplicationIsNotInUse: string = Translations_fi.ThisPartOfApplicationIsNotInUse;
	static TimeSpanHalfYear: string = Translations_fi.TimeSpanHalfYear;
	static EnableExternalIntraSystemConfirmation: string = Translations_fi.EnableExternalIntraSystemConfirmation;
	static AccessRightReadEvents: string = Translations_fi.AccessRightReadEvents;
	static Priority: string = Translations_fi.Priority;
	static DocumentCreated: string = Translations_fi.DocumentCreated;
	static TaskStatesMustBeDefinedBeforeAddingTasks: string = Translations_fi.TaskStatesMustBeDefinedBeforeAddingTasks;
	static PrivacyPolicy: string = Translations_fi.PrivacyPolicy;
	static Keywords: string = Translations_fi.Keywords;
	static DoYouReallyWantToRemoveKeywordParameter: string = Translations_fi.DoYouReallyWantToRemoveKeywordParameter;
	static DefaultLanguageHasBeenChanged: string = Translations_fi.DefaultLanguageHasBeenChanged;
	static DeletingEventParameterWasSuccessful: string = Translations_fi.DeletingEventParameterWasSuccessful;
	static DefaultSettings: string = Translations_fi.DefaultSettings;
	static CompanyGroupModuleProperties: string = Translations_fi.CompanyGroupModuleProperties;
	static WhistleBlowNotFound: string = Translations_fi.WhistleBlowNotFound;
	static DoYouReallyWantToRemoveMessageTypeParameter: string = Translations_fi.DoYouReallyWantToRemoveMessageTypeParameter;
	static StateOpen: string = Translations_fi.StateOpen;
	static CompanyGroupParameterNotFound: string = Translations_fi.CompanyGroupParameterNotFound;
	static DeletingTaskTypesParameterWasSuccessful: string = Translations_fi.DeletingTaskTypesParameterWasSuccessful;
	static JobTitle: string = Translations_fi.JobTitle;
	static RemoveFile: string = Translations_fi.RemoveFile;
	static SaveMessage: string = Translations_fi.SaveMessage;
	static AdministratorPasswordMustBeDefined: string = Translations_fi.AdministratorPasswordMustBeDefined;
	static ExternalSystemCodeClaimNotFound: string = Translations_fi.ExternalSystemCodeClaimNotFound;
	static DoYouReallyWantToRemoveSelectedHandlers: string = Translations_fi.DoYouReallyWantToRemoveSelectedHandlers;
	static DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError;
	static LogCleaning: string = Translations_fi.LogCleaning;
	static EventsFound: string = Translations_fi.EventsFound;
	static Change: string = Translations_fi.Change;
	static ExitingInstantMessageChannelWasNotSuccessfulErrorParameter: string = Translations_fi.ExitingInstantMessageChannelWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToRemoveEditableDocumentParameter: string = Translations_fi.DoYouReallyWantToRemoveEditableDocumentParameter;
	static Archive: string = Translations_fi.Archive;
	static Empty: string = Translations_fi.Empty;
	static Resources: string = Translations_fi.Resources;
	static AccessRightWriteWhistleBlow: string = Translations_fi.AccessRightWriteWhistleBlow;
	static LockedDocumentParameterCannotBeMoved: string = Translations_fi.LockedDocumentParameterCannotBeMoved;
	static SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter;
	static SavingReadReceiptWasSuccessful: string = Translations_fi.SavingReadReceiptWasSuccessful;
	static NoTasksToShow: string = Translations_fi.NoTasksToShow;
	static AllInstantMessageUsersHaveReadMessage: string = Translations_fi.AllInstantMessageUsersHaveReadMessage;
	static DeletingTaskStatesParameterWasSuccessful: string = Translations_fi.DeletingTaskStatesParameterWasSuccessful;
	static Cancel: string = Translations_fi.Cancel;
	static AdditionalPermissionBEvents: string = Translations_fi.AdditionalPermissionBEvents;
	static ReportDialogInfoText: string = Translations_fi.ReportDialogInfoText;
	static Continue: string = Translations_fi.Continue;
	static TaskDataRowParameterNotFound: string = Translations_fi.TaskDataRowParameterNotFound;
	static RemovingEventHandlerWasNotSuccessfulErrorParameter: string = Translations_fi.RemovingEventHandlerWasNotSuccessfulErrorParameter;
	static RemoveDefaultBasicEducation: string = Translations_fi.RemoveDefaultBasicEducation;
	static VoucherInOutReportSavePath: string = Translations_fi.VoucherInOutReportSavePath;
	static InstantMessageChannelUserParameterNotFound: string = Translations_fi.InstantMessageChannelUserParameterNotFound;
	static EditMessageType: string = Translations_fi.EditMessageType;
	static SearchProperties: string = Translations_fi.SearchProperties;
	static AllUnits: string = Translations_fi.AllUnits;
	static EventTypesGeneric: string = Translations_fi.EventTypesGeneric;
	static SavingTaskStateParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingTaskStateParameterWasNotSuccessfulConcurrencyError;
	static ChangeSystemLanguageToFinnish: string = Translations_fi.ChangeSystemLanguageToFinnish;
	static SavingRecordEmploymentParameterWasSuccessful: string = Translations_fi.SavingRecordEmploymentParameterWasSuccessful;
	static Folders: string = Translations_fi.Folders;
	static RequireReadReceipt: string = Translations_fi.RequireReadReceipt;
	static Required: string = Translations_fi.Required;
	static UnreadMessagesParameter: string = Translations_fi.UnreadMessagesParameter;
	static WhistleBlowArchivingTypeChangeState: string = Translations_fi.WhistleBlowArchivingTypeChangeState;
	static DoYouReallyWantToRemoveCompanyGroupParameter: string = Translations_fi.DoYouReallyWantToRemoveCompanyGroupParameter;
	static AddToHomeScreen: string = Translations_fi.AddToHomeScreen;
	static ForwardMessage: string = Translations_fi.ForwardMessage;
	static Files: string = Translations_fi.Files;
	static WhistleBlowCreateTime: string = Translations_fi.WhistleBlowCreateTime;
	static DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError;
	static ModifiedActions: string = Translations_fi.ModifiedActions;
	static ChartProperties: string = Translations_fi.ChartProperties;
	static EditableDocumentPublishConfirmation: string = Translations_fi.EditableDocumentPublishConfirmation;
	static Password: string = Translations_fi.Password;
	static RemoveLogisticTransportCompany: string = Translations_fi.RemoveLogisticTransportCompany;
	static DoYouReallyWantToPublishTaskTypeConfirmation: string = Translations_fi.DoYouReallyWantToPublishTaskTypeConfirmation;
	static SavingTaskDataRowWasSuccessful: string = Translations_fi.SavingTaskDataRowWasSuccessful;
	static AddCompanyToCompanyGroup: string = Translations_fi.AddCompanyToCompanyGroup;
	static AddingAddRecordUserFurtherEducationsWasSuccessful: string = Translations_fi.AddingAddRecordUserFurtherEducationsWasSuccessful;
	static EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted: string = Translations_fi.EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted;
	static RemoveEmployment: string = Translations_fi.RemoveEmployment;
	static UserCodeClaimNotFound: string = Translations_fi.UserCodeClaimNotFound;
	static DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter;
	static TimeSpanYear: string = Translations_fi.TimeSpanYear;
	static EditableDocumentCodeIsAlreadyInUse: string = Translations_fi.EditableDocumentCodeIsAlreadyInUse;
	static PrintTask: string = Translations_fi.PrintTask;
	static NewComment: string = Translations_fi.NewComment;
	static Log: string = Translations_fi.Log;
	static WaitingToBeApproved: string = Translations_fi.WaitingToBeApproved;
	static Properties: string = Translations_fi.Properties;
	static PasswordMustContainLowercaseChar: string = Translations_fi.PasswordMustContainLowercaseChar;
	static PublishDate: string = Translations_fi.PublishDate;
	static CurrentTopicsAbbr: string = Translations_fi.CurrentTopicsAbbr;
	static DeletingCompanyGroupParameterWasSuccessful: string = Translations_fi.DeletingCompanyGroupParameterWasSuccessful;
	static ShowDraftVersion: string = Translations_fi.ShowDraftVersion;
	static TaskPriorityCodeIsAlreadyInUse: string = Translations_fi.TaskPriorityCodeIsAlreadyInUse;
	static WhistleBlowCategories: string = Translations_fi.WhistleBlowCategories;
	static DetectingLocation: string = Translations_fi.DetectingLocation;
	static NotificationsQueuedSuccessfully: string = Translations_fi.NotificationsQueuedSuccessfully;
	static DeletingKeywordParameterWasSuccessful: string = Translations_fi.DeletingKeywordParameterWasSuccessful;
	static Browse: string = Translations_fi.Browse;
	static ThisMessageWasDeletedByInstantMessageChannelAdministrator: string = Translations_fi.ThisMessageWasDeletedByInstantMessageChannelAdministrator;
	static UserClaimsNotFound: string = Translations_fi.UserClaimsNotFound;
	static Relation: string = Translations_fi.Relation;
	static NoName: string = Translations_fi.NoName;
	static AddEmployment: string = Translations_fi.AddEmployment;
	static RemoveDefaultEventHandler: string = Translations_fi.RemoveDefaultEventHandler;
	static CopyOrMoveFolderParameter: string = Translations_fi.CopyOrMoveFolderParameter;
	static DefaultStyle: string = Translations_fi.DefaultStyle;
	static UnitNameMustBeDefined: string = Translations_fi.UnitNameMustBeDefined;
	static DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter;
	static TransportCompanies: string = Translations_fi.TransportCompanies;
	static SendinEmailNotificationsWasNotSuccessfulErrorParameter: string = Translations_fi.SendinEmailNotificationsWasNotSuccessfulErrorParameter;
	static Unkown: string = Translations_fi.Unkown;
	static WhistleBlowInformantDescription: string = Translations_fi.WhistleBlowInformantDescription;
	static InternalMessages: string = Translations_fi.InternalMessages;
	static SavingCompanyParameterWasSuccessful: string = Translations_fi.SavingCompanyParameterWasSuccessful;
	static ProjectName: string = Translations_fi.ProjectName;
	static InstantMessageChannelInternal: string = Translations_fi.InstantMessageChannelInternal;
	static Modified: string = Translations_fi.Modified;
	static DocumentIsEmpty: string = Translations_fi.DocumentIsEmpty;
	static WhistleBlowInformantButtonHint: string = Translations_fi.WhistleBlowInformantButtonHint;
	static SavingEventTypesWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventTypesWasNotSuccessfulErrorParameter;
	static DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter;
	static AddMessageType: string = Translations_fi.AddMessageType;
	static SavingJobParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingJobParameterWasNotSuccessfulErrorParameter;
	static RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted: string = Translations_fi.RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted;
	static DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter;
	static ReportsModified: string = Translations_fi.ReportsModified;
	static DoYouReallyWantToRemoveSelectedRecordFamiliarizations: string = Translations_fi.DoYouReallyWantToRemoveSelectedRecordFamiliarizations;
	static InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation: string = Translations_fi.InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation;
	static UserRecordStatisticsExpiringInSixMonthsFurtherEducations: string = Translations_fi.UserRecordStatisticsExpiringInSixMonthsFurtherEducations;
	static DefaultTaskPriorities: string = Translations_fi.DefaultTaskPriorities;
	static NewMessage: string = Translations_fi.NewMessage;
	static AddLogisticVoucherCompanyPaymentSetting: string = Translations_fi.AddLogisticVoucherCompanyPaymentSetting;
	static RemoveWhistleBlowCategory: string = Translations_fi.RemoveWhistleBlowCategory;
	static DoYouReallyWantToRemoveUnitParameter: string = Translations_fi.DoYouReallyWantToRemoveUnitParameter;
	static SelectSubject: string = Translations_fi.SelectSubject;
	static SelectRemovingMethod: string = Translations_fi.SelectRemovingMethod;
	static EditPayerSettings: string = Translations_fi.EditPayerSettings;
	static InvalidValueParameter: string = Translations_fi.InvalidValueParameter;
	static NormalSize: string = Translations_fi.NormalSize;
	static DeletingTaskDataRowWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingTaskDataRowWasNotSuccessfulConcurrencyError;
	static DoYouReallyWantToRemoveFileFilterParameter: string = Translations_fi.DoYouReallyWantToRemoveFileFilterParameter;
	static PermissionsForSection: string = Translations_fi.PermissionsForSection;
	static TargetsOtherCompanies: string = Translations_fi.TargetsOtherCompanies;
	static UserGroupModuleProperties: string = Translations_fi.UserGroupModuleProperties;
	static WhistleBlowEventTypeModified: string = Translations_fi.WhistleBlowEventTypeModified;
	static SomeEventHandlersHaveReadMessage: string = Translations_fi.SomeEventHandlersHaveReadMessage;
	static Scope: string = Translations_fi.Scope;
	static UserRecordStatisticsActiveCards: string = Translations_fi.UserRecordStatisticsActiveCards;
	static ChangedObserverAction: string = Translations_fi.ChangedObserverAction;
	static SavingUserGroupParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingUserGroupParameterWasNotSuccessfulErrorParameter;
	static UnitMustBeSelected: string = Translations_fi.UnitMustBeSelected;
	static CompanyCodeMustBeDefined: string = Translations_fi.CompanyCodeMustBeDefined;
	static SavingEventParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventParameterWasNotSuccessfulErrorParameter;
	static WhistleBlowChannel: string = Translations_fi.WhistleBlowChannel;
	static WhistleBlowWasNotFoundExplanation: string = Translations_fi.WhistleBlowWasNotFoundExplanation;
	static DocumentTemplateParameterNotFound: string = Translations_fi.DocumentTemplateParameterNotFound;
	static Sorting: string = Translations_fi.Sorting;
	static MessageTypeCodeIsAlreadyInUse: string = Translations_fi.MessageTypeCodeIsAlreadyInUse;
	static SelectDocumentTemplate: string = Translations_fi.SelectDocumentTemplate;
	static SavingTaskDataRowWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingTaskDataRowWasNotSuccessfulConcurrencyError;
	static WhistleBlowInternalMessageConfirmation: string = Translations_fi.WhistleBlowInternalMessageConfirmation;
	static DeletingCompanyGroupsParameterWasSuccessful: string = Translations_fi.DeletingCompanyGroupsParameterWasSuccessful;
	static DeletingTaskDataRowWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingTaskDataRowWasNotSuccessfulErrorParameter;
	static FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations: string = Translations_fi.FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations;
	static DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError;
	static Unlock: string = Translations_fi.Unlock;
	static Tasks: string = Translations_fi.Tasks;
	static RestoreTaskFromTrashBin: string = Translations_fi.RestoreTaskFromTrashBin;
	static SavingWhistleBlowMessageWasSuccessful: string = Translations_fi.SavingWhistleBlowMessageWasSuccessful;
	static Test: string = Translations_fi.Test;
	static TaskPriorityCodeMustBeDefined: string = Translations_fi.TaskPriorityCodeMustBeDefined;
	static DeletingOneTimePasswordWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingOneTimePasswordWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToRemoveTaskStateParameter: string = Translations_fi.DoYouReallyWantToRemoveTaskStateParameter;
	static DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter;
	static ReadingFolderParameter: string = Translations_fi.ReadingFolderParameter;
	static SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter;
	static PublishingTaskTypeParameterWasNotSuccessfulErrorParameter: string = Translations_fi.PublishingTaskTypeParameterWasNotSuccessfulErrorParameter;
	static RemoveBasicEducation: string = Translations_fi.RemoveBasicEducation;
	static WhistleBlowEventTypeAttachmentsAdded: string = Translations_fi.WhistleBlowEventTypeAttachmentsAdded;
	static AddEventMessageConfirmation: string = Translations_fi.AddEventMessageConfirmation;
	static SavingEventUrgencyClassParameterWasSuccessful: string = Translations_fi.SavingEventUrgencyClassParameterWasSuccessful;
	static DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter;
	static InstantMessageChannelGroupExternal: string = Translations_fi.InstantMessageChannelGroupExternal;
	static CompanyIdClaimNotFound: string = Translations_fi.CompanyIdClaimNotFound;
	static WhistleBlowReportIHaveSavedThePassword: string = Translations_fi.WhistleBlowReportIHaveSavedThePassword;
	static UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels: string = Translations_fi.UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels;
	static Unlimited: string = Translations_fi.Unlimited;
	static RemoveDefaultEventType: string = Translations_fi.RemoveDefaultEventType;
	static SavingOneTimePasswordWasNotSuccessfulErrorParameter: string = Translations_fi.SavingOneTimePasswordWasNotSuccessfulErrorParameter;
	static NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded: string = Translations_fi.NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded;
	static SavingFileFilterParameterWasSuccessful: string = Translations_fi.SavingFileFilterParameterWasSuccessful;
	static PermissionToCreateEventsToPartners: string = Translations_fi.PermissionToCreateEventsToPartners;
	static ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError: string = Translations_fi.ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError;
	static FieldsToBePrinted: string = Translations_fi.FieldsToBePrinted;
	static WhistleBlowSourceOther: string = Translations_fi.WhistleBlowSourceOther;
	static DoYouReallyWantToSave: string = Translations_fi.DoYouReallyWantToSave;
	static MovingTaskParameterToTrashBinWasSuccessful: string = Translations_fi.MovingTaskParameterToTrashBinWasSuccessful;
	static DisableExternalIntraSystemConfirmation: string = Translations_fi.DisableExternalIntraSystemConfirmation;
	static WhistleBlowMustBeReceivedLatestAtParameter: string = Translations_fi.WhistleBlowMustBeReceivedLatestAtParameter;
	static UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter: string = Translations_fi.UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter;
	static SavingTaskTypeParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingTaskTypeParameterWasNotSuccessfulErrorParameter;
	static TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion: string = Translations_fi.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion;
	static UserRecordStatisticsMissingQualifications: string = Translations_fi.UserRecordStatisticsMissingQualifications;
	static ObserverActionMustBeDefined: string = Translations_fi.ObserverActionMustBeDefined;
	static CanShareContentToCompanies: string = Translations_fi.CanShareContentToCompanies;
	static SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError;
	static AddDefaultHandler: string = Translations_fi.AddDefaultHandler;
	static WhistleBlowInformation: string = Translations_fi.WhistleBlowInformation;
	static DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter;
	static EditWhistleBlow: string = Translations_fi.EditWhistleBlow;
	static NewRecordUserMustBeSavedBeforeRowsCanBeAdded: string = Translations_fi.NewRecordUserMustBeSavedBeforeRowsCanBeAdded;
	static ReadingFileParameterWasNotSuccessfulFileNotFound: string = Translations_fi.ReadingFileParameterWasNotSuccessfulFileNotFound;
	static ChangingPasswordWasSuccessful: string = Translations_fi.ChangingPasswordWasSuccessful;
	static WhistleBlowSeverityHigh: string = Translations_fi.WhistleBlowSeverityHigh;
	static WhistleBlowEventTypeHandlersRemoved: string = Translations_fi.WhistleBlowEventTypeHandlersRemoved;
	static AdditionalPermissions: string = Translations_fi.AdditionalPermissions;
	static Aprint: string = Translations_fi.Aprint;
	static CreatingCompressedFileWasNotSuccessfulErrorParameter: string = Translations_fi.CreatingCompressedFileWasNotSuccessfulErrorParameter;
	static WhistleBlowWasNotFound: string = Translations_fi.WhistleBlowWasNotFound;
	static ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError: string = Translations_fi.ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError;
	static AddCompany: string = Translations_fi.AddCompany;
	static PostalAddress: string = Translations_fi.PostalAddress;
	static AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel: string = Translations_fi.AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel;
	static NewPrint: string = Translations_fi.NewPrint;
	static ExternalIntraSystems: string = Translations_fi.ExternalIntraSystems;
	static SavingCompanyGroupParameterWasSuccessful: string = Translations_fi.SavingCompanyGroupParameterWasSuccessful;
	static TaskHandler: string = Translations_fi.TaskHandler;
	static AccessRightReadInstantMessages: string = Translations_fi.AccessRightReadInstantMessages;
	static FolderProperties: string = Translations_fi.FolderProperties;
	static WhistleBlowSourceUnspecified: string = Translations_fi.WhistleBlowSourceUnspecified;
	static UserIsSystemAdminClaimNotFound: string = Translations_fi.UserIsSystemAdminClaimNotFound;
	static DocumentParameterAlreadyExists: string = Translations_fi.DocumentParameterAlreadyExists;
	static ImageProperties: string = Translations_fi.ImageProperties;
	static RefreshList: string = Translations_fi.RefreshList;
	static EmptyTaskTrashBinConfirmation: string = Translations_fi.EmptyTaskTrashBinConfirmation;
	static ImmediateAction: string = Translations_fi.ImmediateAction;
	static AddUserParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.AddUserParameterWasNotSuccessfulConcurrencyError;
	static EventTypeTitle: string = Translations_fi.EventTypeTitle;
	static MovingFilesAndDocumentsToFolderParameterWasSuccessful: string = Translations_fi.MovingFilesAndDocumentsToFolderParameterWasSuccessful;
	static UserCanBeSelectedAsEventHandler: string = Translations_fi.UserCanBeSelectedAsEventHandler;
	static Expand: string = Translations_fi.Expand;
	static ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter;
	static RecordUser: string = Translations_fi.RecordUser;
	static SavingEditableDocumentParameterPublishWasSuccessful: string = Translations_fi.SavingEditableDocumentParameterPublishWasSuccessful;
	static AddingAttachmentParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddingAttachmentParameterWasNotSuccessfulErrorParameter;
	static GroupByQuarter: string = Translations_fi.GroupByQuarter;
	static SavingEventMessageWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventMessageWasNotSuccessfulErrorParameter;
	static WorkShiftType: string = Translations_fi.WorkShiftType;
	static WhistleBlowAnonymityAnonymous: string = Translations_fi.WhistleBlowAnonymityAnonymous;
	static EventTypeBCodeMustBeDefined: string = Translations_fi.EventTypeBCodeMustBeDefined;
	static MyFiltersAndReports: string = Translations_fi.MyFiltersAndReports;
	static DeletingRecordFurtherEducationParameterWasSuccessful: string = Translations_fi.DeletingRecordFurtherEducationParameterWasSuccessful;
	static ProcessingFolderParameter: string = Translations_fi.ProcessingFolderParameter;
	static RemoveDocumentTemplate: string = Translations_fi.RemoveDocumentTemplate;
	static NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded: string = Translations_fi.NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded;
	static SavingEventSettingsWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventSettingsWasNotSuccessfulErrorParameter;
	static DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError;
	static AdditionalPermissionAWhistleBlow: string = Translations_fi.AdditionalPermissionAWhistleBlow;
	static UserAccountMustBeSelected: string = Translations_fi.UserAccountMustBeSelected;
	static ProcessingTime: string = Translations_fi.ProcessingTime;
	static SavingPrintWasNotSuccessfulErrorParameter: string = Translations_fi.SavingPrintWasNotSuccessfulErrorParameter;
	static AddTarget: string = Translations_fi.AddTarget;
	static DeletingEventTypeBParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingEventTypeBParameterWasNotSuccessfulErrorParameter;
	static RecordFurtherEducationParameterNotFound: string = Translations_fi.RecordFurtherEducationParameterNotFound;
	static RemoveParameterDocuments: string = Translations_fi.RemoveParameterDocuments;
	static EditEvent: string = Translations_fi.EditEvent;
	static RemoveRowConfirmation: string = Translations_fi.RemoveRowConfirmation;
	static Addition: string = Translations_fi.Addition;
	static MiddleName: string = Translations_fi.MiddleName;
	static SendReadReceiptConfirmation: string = Translations_fi.SendReadReceiptConfirmation;
	static EditEventTypeBs: string = Translations_fi.EditEventTypeBs;
	static NotifiedPersons: string = Translations_fi.NotifiedPersons;
	static AddFile: string = Translations_fi.AddFile;
	static ChangeLanguage: string = Translations_fi.ChangeLanguage;
	static DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError;
	static ExpiringIn90Days: string = Translations_fi.ExpiringIn90Days;
	static TaskRemainderCheckingAndNotificationStarted: string = Translations_fi.TaskRemainderCheckingAndNotificationStarted;
	static SavingOptionParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingOptionParameterWasNotSuccessfulConcurrencyError;
	static IpAddress: string = Translations_fi.IpAddress;
	static AddAttachementsToEvent: string = Translations_fi.AddAttachementsToEvent;
	static AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter: string = Translations_fi.AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter;
	static SendingEmailToParameter: string = Translations_fi.SendingEmailToParameter;
	static ParameterPercentOfFileStorageQuotaUsed: string = Translations_fi.ParameterPercentOfFileStorageQuotaUsed;
	static AddPartnersParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddPartnersParameterWasNotSuccessfulErrorParameter;
	static WhistleBlowInformant: string = Translations_fi.WhistleBlowInformant;
	static DefaultEventHandlers: string = Translations_fi.DefaultEventHandlers;
	static FieldParameterConstainsIllegalCharactersForFileName: string = Translations_fi.FieldParameterConstainsIllegalCharactersForFileName;
	static AccessRightWriteTasks: string = Translations_fi.AccessRightWriteTasks;
	static DeletingEventPartnerParameterWasSuccessful: string = Translations_fi.DeletingEventPartnerParameterWasSuccessful;
	static State: string = Translations_fi.State;
	static OtherInformation: string = Translations_fi.OtherInformation;
	static CompanyThatIsRelatedToEventsCannotBeDeleted: string = Translations_fi.CompanyThatIsRelatedToEventsCannotBeDeleted;
	static SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter;
	static PreviousCalendarYear: string = Translations_fi.PreviousCalendarYear;
	static SavingChartWasSuccessful: string = Translations_fi.SavingChartWasSuccessful;
	static DeletingTaskTypesParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingTaskTypesParameterWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToRemoveSelectedRecordFurtherEducations: string = Translations_fi.DoYouReallyWantToRemoveSelectedRecordFurtherEducations;
	static EditDocumentTemplate: string = Translations_fi.EditDocumentTemplate;
	static ByName: string = Translations_fi.ByName;
	static AddChildEventTypeC: string = Translations_fi.AddChildEventTypeC;
	static ChartGenerationAborted: string = Translations_fi.ChartGenerationAborted;
	static OtherSettings: string = Translations_fi.OtherSettings;
	static DefaultHandlers: string = Translations_fi.DefaultHandlers;
	static SavingUnitsWasNotSuccessfulErrorParameter: string = Translations_fi.SavingUnitsWasNotSuccessfulErrorParameter;
	static PasswordMustContainUppercaseChar: string = Translations_fi.PasswordMustContainUppercaseChar;
	static ActionIsNotAuthorized: string = Translations_fi.ActionIsNotAuthorized;
	static UserGroupsMustBeDefined: string = Translations_fi.UserGroupsMustBeDefined;
	static FieldParameterValueCanOnlyContainNumbers: string = Translations_fi.FieldParameterValueCanOnlyContainNumbers;
	static WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted: string = Translations_fi.WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted;
	static SavingEventTypeParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventTypeParameterWasNotSuccessfulConcurrencyError;
	static CustomStyleClass: string = Translations_fi.CustomStyleClass;
	static SavingDirectoryItemPermissionsWasSuccessful: string = Translations_fi.SavingDirectoryItemPermissionsWasSuccessful;
	static SharedReport: string = Translations_fi.SharedReport;
	static Operation: string = Translations_fi.Operation;
	static RecordUserQualificationParameterNotFound: string = Translations_fi.RecordUserQualificationParameterNotFound;
	static DocumentNameParameterConstainsIllegalCharacters: string = Translations_fi.DocumentNameParameterConstainsIllegalCharacters;
	static ExitInstantMessageChannel: string = Translations_fi.ExitInstantMessageChannel;
	static AddDefaultBasicEducation: string = Translations_fi.AddDefaultBasicEducation;
	static SavingTaskPriorityParameterWasSuccessful: string = Translations_fi.SavingTaskPriorityParameterWasSuccessful;
	static EditEventTypeB: string = Translations_fi.EditEventTypeB;
	static ActiveTasks: string = Translations_fi.ActiveTasks;
	static BannerPath: string = Translations_fi.BannerPath;
	static ClearSubUnitDefaultHandlers: string = Translations_fi.ClearSubUnitDefaultHandlers;
	static SavingEventSettingsWasSuccessful: string = Translations_fi.SavingEventSettingsWasSuccessful;
	static CopyGroupPermissions: string = Translations_fi.CopyGroupPermissions;
	static JobTypeEmail: string = Translations_fi.JobTypeEmail;
	static UnpublishingEditableDocumentParameterWasSuccessful: string = Translations_fi.UnpublishingEditableDocumentParameterWasSuccessful;
	static DoYouReallyWantToRemoveWhistleBlowParameter: string = Translations_fi.DoYouReallyWantToRemoveWhistleBlowParameter;
	static DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter;
	static ModifyDateEndDate: string = Translations_fi.ModifyDateEndDate;
	static AdminMustBeDefined: string = Translations_fi.AdminMustBeDefined;
	static SaveAsFavorite: string = Translations_fi.SaveAsFavorite;
	static Handlers: string = Translations_fi.Handlers;
	static RenamingFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.RenamingFolderParameterWasNotSuccessfulErrorParameter;
	static MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError;
	static SearchCondition: string = Translations_fi.SearchCondition;
	static SavingWhistleBlowWasSuccessful: string = Translations_fi.SavingWhistleBlowWasSuccessful;
	static LogEventTime: string = Translations_fi.LogEventTime;
	static DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError;
	static NewUserMustBeSavedBeforeUserGroupsCanBeAdded: string = Translations_fi.NewUserMustBeSavedBeforeUserGroupsCanBeAdded;
	static InheritedContentFilter: string = Translations_fi.InheritedContentFilter;
	static ActiveUserTasks: string = Translations_fi.ActiveUserTasks;
	static DeletingTaskPrioritiesParameterWasSuccessful: string = Translations_fi.DeletingTaskPrioritiesParameterWasSuccessful;
	static TrainingStartDateEnd: string = Translations_fi.TrainingStartDateEnd;
	static DocumentsNotFound: string = Translations_fi.DocumentsNotFound;
	static PushEndpoint: string = Translations_fi.PushEndpoint;
	static NewUserGroupMustBeSavedBeforeUsersCanBeAdded: string = Translations_fi.NewUserGroupMustBeSavedBeforeUsersCanBeAdded;
	static ReadReceiptGivenPlur: string = Translations_fi.ReadReceiptGivenPlur;
	static AdministratorUserCodeMustBeDefined: string = Translations_fi.AdministratorUserCodeMustBeDefined;
	static AddFurtherEducation: string = Translations_fi.AddFurtherEducation;
	static LargeSize: string = Translations_fi.LargeSize;
	static DoYouReallyWantToMoveTaskParameterToTrashBin: string = Translations_fi.DoYouReallyWantToMoveTaskParameterToTrashBin;
	static SavingEventParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventParameterWasNotSuccessfulConcurrencyError;
	static LanguageFinnish: string = Translations_fi.LanguageFinnish;
	static DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError;
	static MaxSearchResultCount: string = Translations_fi.MaxSearchResultCount;
	static SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError;
	static Hour: string = Translations_fi.Hour;
	static UnitCodeIsAlreadyInUse: string = Translations_fi.UnitCodeIsAlreadyInUse;
	static CostCentreNumber: string = Translations_fi.CostCentreNumber;
	static CopyDefaultHandlers: string = Translations_fi.CopyDefaultHandlers;
	static ArgumentParameterIsNULL: string = Translations_fi.ArgumentParameterIsNULL;
	static SelectEventPartner: string = Translations_fi.SelectEventPartner;
	static CompanyGroupNameMustBeDefined: string = Translations_fi.CompanyGroupNameMustBeDefined;
	static SavingUserGroupParameterWasSuccessful: string = Translations_fi.SavingUserGroupParameterWasSuccessful;
	static AddChildDefaultEventType: string = Translations_fi.AddChildDefaultEventType;
	static EmploymentType: string = Translations_fi.EmploymentType;
	static RemoveMessage: string = Translations_fi.RemoveMessage;
	static UserInformation: string = Translations_fi.UserInformation;
	static MessageTitleMustBeDefined: string = Translations_fi.MessageTitleMustBeDefined;
	static DoYouReallyWantToRemoveDefaultEventHandlerParameter: string = Translations_fi.DoYouReallyWantToRemoveDefaultEventHandlerParameter;
	static SavingMessageTypeParameterWasSuccessful: string = Translations_fi.SavingMessageTypeParameterWasSuccessful;
	static FileNameConstainsIllegalCharacters: string = Translations_fi.FileNameConstainsIllegalCharacters;
	static AddEventTypeC: string = Translations_fi.AddEventTypeC;
	static AddEventTypeB: string = Translations_fi.AddEventTypeB;
	static DeletingDocumentTemplateParameterWasSuccessful: string = Translations_fi.DeletingDocumentTemplateParameterWasSuccessful;
	static SavingFilterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingFilterWasNotSuccessfulErrorParameter;
	static View: string = Translations_fi.View;
	static EmailsSentSuccessfully: string = Translations_fi.EmailsSentSuccessfully;
	static ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError: string = Translations_fi.ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError;
	static LockingTaskParameterWasNotSuccessful: string = Translations_fi.LockingTaskParameterWasNotSuccessful;
	static UnitParameterNotFound: string = Translations_fi.UnitParameterNotFound;
	static MessageTypeCodeMustBeDefined: string = Translations_fi.MessageTypeCodeMustBeDefined;
	static DocumentModuleProperties: string = Translations_fi.DocumentModuleProperties;
	static AdminMessage: string = Translations_fi.AdminMessage;
	static DeletingOptionParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingOptionParameterWasNotSuccessfulErrorParameter;
	static DeletingDirectoryItemDocumentParameterWasSuccessful: string = Translations_fi.DeletingDirectoryItemDocumentParameterWasSuccessful;
	static AddAttachment: string = Translations_fi.AddAttachment;
	static DoYouWantToPublishEvent: string = Translations_fi.DoYouWantToPublishEvent;
	static EventTypeCCodeIsAlreadyInUse: string = Translations_fi.EventTypeCCodeIsAlreadyInUse;
	static EditUser: string = Translations_fi.EditUser;
	static SendMessage: string = Translations_fi.SendMessage;
	static UnlockingEditableDocumentParameterWasNotSuccessful: string = Translations_fi.UnlockingEditableDocumentParameterWasNotSuccessful;
	static JobTitleMustBeDefined: string = Translations_fi.JobTitleMustBeDefined;
	static AddRecordUser: string = Translations_fi.AddRecordUser;
	static JobParameterNotFound: string = Translations_fi.JobParameterNotFound;
	static DefaultDeductionWithoutTax: string = Translations_fi.DefaultDeductionWithoutTax;
	static Location: string = Translations_fi.Location;
	static AddEventHandler: string = Translations_fi.AddEventHandler;
	static RecordUserModuleProperties: string = Translations_fi.RecordUserModuleProperties;
	static Parameters: string = Translations_fi.Parameters;
	static ApproveEventHandlerConfirmation2: string = Translations_fi.ApproveEventHandlerConfirmation2;
	static RemoveLogisticVoucherCustomer: string = Translations_fi.RemoveLogisticVoucherCustomer;
	static DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter;
	static JobLineDataIsEmpty: string = Translations_fi.JobLineDataIsEmpty;
	static PanelSize4: string = Translations_fi.PanelSize4;
	static RecordUserFamiliarizationParameterNotFound: string = Translations_fi.RecordUserFamiliarizationParameterNotFound;
	static DraftCannotBeApprovedDoYouWantToSaveDraftAnyway: string = Translations_fi.DraftCannotBeApprovedDoYouWantToSaveDraftAnyway;
	static AddDefaultEventType: string = Translations_fi.AddDefaultEventType;
	static UserProfile: string = Translations_fi.UserProfile;
	static DeletingUserGroupUsersOfCompanyParameterWasSuccessful: string = Translations_fi.DeletingUserGroupUsersOfCompanyParameterWasSuccessful;
	static IgnoreFolderPatterns: string = Translations_fi.IgnoreFolderPatterns;
	static AddPartner: string = Translations_fi.AddPartner;
	static DoYouReallyWantToRemoveRecordQualificationParameter: string = Translations_fi.DoYouReallyWantToRemoveRecordQualificationParameter;
	static RemoveCompanyFromCompanyGroup: string = Translations_fi.RemoveCompanyFromCompanyGroup;
	static DeletingEventTypeParameterWasSuccessful: string = Translations_fi.DeletingEventTypeParameterWasSuccessful;
	static DeletingCompanyParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingCompanyParameterWasNotSuccessfulConcurrencyError;
	static Portrait: string = Translations_fi.Portrait;
	static ApprovedEvent: string = Translations_fi.ApprovedEvent;
	static DoYouReallyWantToReplaceDocumentTemplateParameter: string = Translations_fi.DoYouReallyWantToReplaceDocumentTemplateParameter;
	static ReadImpPlur: string = Translations_fi.ReadImpPlur;
	static RemoveCompanyGroup: string = Translations_fi.RemoveCompanyGroup;
	static LogOut: string = Translations_fi.LogOut;
	static UserCodeUpdateWasNotSuccessfulErrorParameter: string = Translations_fi.UserCodeUpdateWasNotSuccessfulErrorParameter;
	static Target: string = Translations_fi.Target;
	static BankBICCode: string = Translations_fi.BankBICCode;
	static RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter: string = Translations_fi.RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter;
	static UserRecordStatisticsUserAccountConnectedCards: string = Translations_fi.UserRecordStatisticsUserAccountConnectedCards;
	static SavingEventPartnerParameterWasSuccessful: string = Translations_fi.SavingEventPartnerParameterWasSuccessful;
	static NewRow: string = Translations_fi.NewRow;
	static WhistleBlowAnonymousReport: string = Translations_fi.WhistleBlowAnonymousReport;
	static Code: string = Translations_fi.Code;
	static UpdateUserGroupParameterExternalSystemsWasSuccessful: string = Translations_fi.UpdateUserGroupParameterExternalSystemsWasSuccessful;
	static Path: string = Translations_fi.Path;
	static EmptyingTaskTrashBinWasSuccessfulErrorParameter: string = Translations_fi.EmptyingTaskTrashBinWasSuccessfulErrorParameter;
	static WhistleBlowChannelMustHaveAtLeastOneAdministrator: string = Translations_fi.WhistleBlowChannelMustHaveAtLeastOneAdministrator;
	static SavingKeywordParameterWasSuccessful: string = Translations_fi.SavingKeywordParameterWasSuccessful;
	static AddWhistleBlowChannelAdminsParameterWasSuccessful: string = Translations_fi.AddWhistleBlowChannelAdminsParameterWasSuccessful;
	static SavingInstantMessageChannelParameterWasSuccessful: string = Translations_fi.SavingInstantMessageChannelParameterWasSuccessful;
	static SelectTaskState: string = Translations_fi.SelectTaskState;
	static ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter: string = Translations_fi.ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter;
	static MoneyOut: string = Translations_fi.MoneyOut;
	static SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter;
	static SearchTasks: string = Translations_fi.SearchTasks;
	static SomeInstantMessageUsersHaveReadMessage: string = Translations_fi.SomeInstantMessageUsersHaveReadMessage;
	static TaskState: string = Translations_fi.TaskState;
	static AmountOfComments: string = Translations_fi.AmountOfComments;
	static MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB: string = Translations_fi.MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB;
	static FamiliarizationEndDate: string = Translations_fi.FamiliarizationEndDate;
	static MessageDateMustBeDefined: string = Translations_fi.MessageDateMustBeDefined;
	static GroupByMonth: string = Translations_fi.GroupByMonth;
	static CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter: string = Translations_fi.CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter;
	static YouDeletedThisMessage: string = Translations_fi.YouDeletedThisMessage;
	static EditRemainder: string = Translations_fi.EditRemainder;
	static PrintParameterDownloaded: string = Translations_fi.PrintParameterDownloaded;
	static PushNotificationInUse: string = Translations_fi.PushNotificationInUse;
	static NewPanel: string = Translations_fi.NewPanel;
	static UserRecordCards: string = Translations_fi.UserRecordCards;
	static ReadReceipts: string = Translations_fi.ReadReceipts;
	static DoYouReallyWantToRemoveSelectedTaskStates: string = Translations_fi.DoYouReallyWantToRemoveSelectedTaskStates;
	static WhistleBlowResolutionOther: string = Translations_fi.WhistleBlowResolutionOther;
	static WhistleBlowSeverityVeryLow: string = Translations_fi.WhistleBlowSeverityVeryLow;
	static SentEmails: string = Translations_fi.SentEmails;
	static SavingWhistleBlowChannelParameterWasSuccessful: string = Translations_fi.SavingWhistleBlowChannelParameterWasSuccessful;
	static UserTypeClaimNotFound: string = Translations_fi.UserTypeClaimNotFound;
	static DoYouReallyWantToRemoveThisInstantMessage: string = Translations_fi.DoYouReallyWantToRemoveThisInstantMessage;
	static DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError;
	static Rejected: string = Translations_fi.Rejected;
	static SavingEventPartnerParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventPartnerParameterWasNotSuccessfulErrorParameter;
	static DeletingAttachmentsParameterWasSuccessful: string = Translations_fi.DeletingAttachmentsParameterWasSuccessful;
	static DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError;
	static Reminders: string = Translations_fi.Reminders;
	static NoMessagesToShow: string = Translations_fi.NoMessagesToShow;
	static Returned: string = Translations_fi.Returned;
	static DeletingInstantMessageWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingInstantMessageWasNotSuccessfulConcurrencyError;
	static SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError;
	static InternalAttachments: string = Translations_fi.InternalAttachments;
	static RemoveReport: string = Translations_fi.RemoveReport;
	static DoYouReallyWantToRemoveUserGroupParameter: string = Translations_fi.DoYouReallyWantToRemoveUserGroupParameter;
	static SavingPrintWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingPrintWasNotSuccessfulConcurrencyError;
	static LoadMore: string = Translations_fi.LoadMore;
	static Category: string = Translations_fi.Category;
	static AddCompanyGroupsParameterWasSuccessful: string = Translations_fi.AddCompanyGroupsParameterWasSuccessful;
	static AuthorizationLevelNone: string = Translations_fi.AuthorizationLevelNone;
	static SavingUserGroupParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingUserGroupParameterWasNotSuccessfulConcurrencyError;
	static ClearSubUnitDefaultHandlersAndNotifiedPersons: string = Translations_fi.ClearSubUnitDefaultHandlersAndNotifiedPersons;
	static WhistleBlowChannelWasNotFoundExplanation: string = Translations_fi.WhistleBlowChannelWasNotFoundExplanation;
	static AddingEventHandlerWasNotSuccessfulErrorParameter: string = Translations_fi.AddingEventHandlerWasNotSuccessfulErrorParameter;
	static NameIsAlreadyInUse: string = Translations_fi.NameIsAlreadyInUse;
	static Color: string = Translations_fi.Color;
	static DeletingFileFilterParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingFileFilterParameterWasNotSuccessfulConcurrencyError;
	static Unpublish: string = Translations_fi.Unpublish;
	static DeletingUserParametersWasSuccessful: string = Translations_fi.DeletingUserParametersWasSuccessful;
	static Schema: string = Translations_fi.Schema;
	static SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError;
	static EditDefaultBasicEducation: string = Translations_fi.EditDefaultBasicEducation;
	static ExternalSystemUserCodeMustBeDefined: string = Translations_fi.ExternalSystemUserCodeMustBeDefined;
	static Yesterday: string = Translations_fi.Yesterday;
	static WhistleBlowNonAnonymousReport: string = Translations_fi.WhistleBlowNonAnonymousReport;
	static SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter: string = Translations_fi.SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter;
	static DocumentTypes: string = Translations_fi.DocumentTypes;
	static ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter: string = Translations_fi.ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter;
	static DocumentRootPathMustBeDefined: string = Translations_fi.DocumentRootPathMustBeDefined;
	static WhistleBlowResolutionResolved: string = Translations_fi.WhistleBlowResolutionResolved;
	static RemoveEvent: string = Translations_fi.RemoveEvent;
	static EditBasicEducation: string = Translations_fi.EditBasicEducation;
	static DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter;
	static SavingFileFilterParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingFileFilterParameterWasNotSuccessfulConcurrencyError;
	static PermissionsForSystems: string = Translations_fi.PermissionsForSystems;
	static DraftCannotBeApproved: string = Translations_fi.DraftCannotBeApproved;
	static AllWhistleBlowHandlersHaveReadMessage: string = Translations_fi.AllWhistleBlowHandlersHaveReadMessage;
	static TaskTypeCodeIsAlreadyInUse: string = Translations_fi.TaskTypeCodeIsAlreadyInUse;
	static AdditionalPermissionARecords: string = Translations_fi.AdditionalPermissionARecords;
	static UseObserverAction: string = Translations_fi.UseObserverAction;
	static Order: string = Translations_fi.Order;
	static EventCreator: string = Translations_fi.EventCreator;
	static AddUserGroup: string = Translations_fi.AddUserGroup;
	static DeletingOneTimePasswordWasSuccessful: string = Translations_fi.DeletingOneTimePasswordWasSuccessful;
	static SendingNotificationsWasNotSuccessfulErrorParameter: string = Translations_fi.SendingNotificationsWasNotSuccessfulErrorParameter;
	static DeletingEventPartnerParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingEventPartnerParameterWasNotSuccessfulErrorParameter;
	static UserReport: string = Translations_fi.UserReport;
	static UserPasswordMustBeDefined: string = Translations_fi.UserPasswordMustBeDefined;
	static DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter;
	static CkickToChangeThePicture: string = Translations_fi.CkickToChangeThePicture;
	static WhistleBlowChannelWasNotFound: string = Translations_fi.WhistleBlowChannelWasNotFound;
	static AddingAddRecordUserTransferredPropertiesWasSuccessful: string = Translations_fi.AddingAddRecordUserTransferredPropertiesWasSuccessful;
	static SavingTaskStateParameterWasSuccessful: string = Translations_fi.SavingTaskStateParameterWasSuccessful;
	static CompanyCodeClaimNotFound: string = Translations_fi.CompanyCodeClaimNotFound;
	static AddFurtherEducations: string = Translations_fi.AddFurtherEducations;
	static Info: string = Translations_fi.Info;
	static AddHandlers: string = Translations_fi.AddHandlers;
	static Date: string = Translations_fi.Date;
	static EditEditableDocument: string = Translations_fi.EditEditableDocument;
	static AdditionalPermissionBWhistleBlow: string = Translations_fi.AdditionalPermissionBWhistleBlow;
	static SavingFileFilterParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingFileFilterParameterWasNotSuccessfulErrorParameter;
	static Error: string = Translations_fi.Error;
	static UserParameterHasInvitedYouToJoinInstantMessageChannelParameter: string = Translations_fi.UserParameterHasInvitedYouToJoinInstantMessageChannelParameter;
	static FileChangesCheckingAndEmailingFinishedInParameterSeconds: string = Translations_fi.FileChangesCheckingAndEmailingFinishedInParameterSeconds;
	static InstantMessageUserInvitationAcceptTypeCanInvite: string = Translations_fi.InstantMessageUserInvitationAcceptTypeCanInvite;
	static ValidPasswordMustBeDefined: string = Translations_fi.ValidPasswordMustBeDefined;
	static DoYouReallyWantToRemoveEventTypeParameter: string = Translations_fi.DoYouReallyWantToRemoveEventTypeParameter;
	static DownloadPdf: string = Translations_fi.DownloadPdf;
	static EventPartnerParameterNotFound: string = Translations_fi.EventPartnerParameterNotFound;
	static WhistleBlowInformantHasReadMessage: string = Translations_fi.WhistleBlowInformantHasReadMessage;
	static UserIdClaimNotFound: string = Translations_fi.UserIdClaimNotFound;
	static Other: string = Translations_fi.Other;
	static AddTaskType: string = Translations_fi.AddTaskType;
	static SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter;
	static ReportGenerationFailed: string = Translations_fi.ReportGenerationFailed;
	static AccessRightNone: string = Translations_fi.AccessRightNone;
	static ExternalIntraSystemUserTokenMustBeDefined: string = Translations_fi.ExternalIntraSystemUserTokenMustBeDefined;
	static EventTypesMustBeDefinedBeforeAddingEvents: string = Translations_fi.EventTypesMustBeDefinedBeforeAddingEvents;
	static DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter;
	static InstantMessagePartners: string = Translations_fi.InstantMessagePartners;
	static MyFilters: string = Translations_fi.MyFilters;
	static DefineRecordUserFurtherEducation: string = Translations_fi.DefineRecordUserFurtherEducation;
	static InstantMessageAttachmentFolderParameterNotFound: string = Translations_fi.InstantMessageAttachmentFolderParameterNotFound;
	static EditCompanyGroup: string = Translations_fi.EditCompanyGroup;
	static EventTypeMustBeDefined: string = Translations_fi.EventTypeMustBeDefined;
	static RemoveEventType: string = Translations_fi.RemoveEventType;
	static AccountNumberTkbInDebit: string = Translations_fi.AccountNumberTkbInDebit;
	static MessageTypes: string = Translations_fi.MessageTypes;
	static WhistleBlowEventTypeAttachmentsRemoved: string = Translations_fi.WhistleBlowEventTypeAttachmentsRemoved;
	static LoggedInUserCannotBeDeleted: string = Translations_fi.LoggedInUserCannotBeDeleted;
	static ExternalSystemCompanyTokenParameterIsAlreadyInUse: string = Translations_fi.ExternalSystemCompanyTokenParameterIsAlreadyInUse;
	static ParameterIsNotValidAccountIBANNumber: string = Translations_fi.ParameterIsNotValidAccountIBANNumber;
	static DeletingRecordFurtherEducationsParameterWasSuccessful: string = Translations_fi.DeletingRecordFurtherEducationsParameterWasSuccessful;
	static DoYouReallyWantToRemoveDocumentTemplateParameter: string = Translations_fi.DoYouReallyWantToRemoveDocumentTemplateParameter;
	static CompanyWithSysAdminAuthorizationLevelNotFound: string = Translations_fi.CompanyWithSysAdminAuthorizationLevelNotFound;
	static ToggleArchivedFurtherEducations: string = Translations_fi.ToggleArchivedFurtherEducations;
	static FiscalNumber: string = Translations_fi.FiscalNumber;
	static SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError;
	static DocumentProperties: string = Translations_fi.DocumentProperties;
	static LastWeek: string = Translations_fi.LastWeek;
	static RemovingEventHandlersWasSuccessful: string = Translations_fi.RemovingEventHandlersWasSuccessful;
	static DeletingMetadataFromFileParameterFailed: string = Translations_fi.DeletingMetadataFromFileParameterFailed;
	static IndirectCosts: string = Translations_fi.IndirectCosts;
	static TrainingTime: string = Translations_fi.TrainingTime;
	static EmailAddressMustBeValid: string = Translations_fi.EmailAddressMustBeValid;
	static AddUser: string = Translations_fi.AddUser;
	static SavingInstantMessageWasSuccessful: string = Translations_fi.SavingInstantMessageWasSuccessful;
	static FailureAudit: string = Translations_fi.FailureAudit;
	static Approved: string = Translations_fi.Approved;
	static FiltersStandard: string = Translations_fi.FiltersStandard;
	static User: string = Translations_fi.User;
	static CompanyToken: string = Translations_fi.CompanyToken;
	static ShowArchived: string = Translations_fi.ShowArchived;
	static InstantMessageChannelMustHaveAtLeastOneAdministrator: string = Translations_fi.InstantMessageChannelMustHaveAtLeastOneAdministrator;
	static AddQualificationToMultipleUserRecords: string = Translations_fi.AddQualificationToMultipleUserRecords;
	static PasteGroupPermissions: string = Translations_fi.PasteGroupPermissions;
	static SavingEditableDocumentParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEditableDocumentParameterWasNotSuccessfulErrorParameter;
	static TaskStateParameterNotFound: string = Translations_fi.TaskStateParameterNotFound;
	static FileFilterCodeIsAlreadyInUse: string = Translations_fi.FileFilterCodeIsAlreadyInUse;
	static RemoveEventHandler: string = Translations_fi.RemoveEventHandler;
	static IdentificationDetails: string = Translations_fi.IdentificationDetails;
	static ThisChannelHasBeenArchived: string = Translations_fi.ThisChannelHasBeenArchived;
	static WhistleBlowEventTypeCreated: string = Translations_fi.WhistleBlowEventTypeCreated;
	static NotApproved: string = Translations_fi.NotApproved;
	static EditTransferredProperty: string = Translations_fi.EditTransferredProperty;
	static RemoveFamiliarization: string = Translations_fi.RemoveFamiliarization;
	static LanguageNonLocalEnglish: string = Translations_fi.LanguageNonLocalEnglish;
	static SavingUserParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingUserParameterWasNotSuccessfulErrorParameter;
	static NotificationSettings: string = Translations_fi.NotificationSettings;
	static SaveAndEmail: string = Translations_fi.SaveAndEmail;
	static AddWhistleBlowChannelAdminParameterWasSuccessful: string = Translations_fi.AddWhistleBlowChannelAdminParameterWasSuccessful;
	static TaxNumber: string = Translations_fi.TaxNumber;
	static GroupByUnit: string = Translations_fi.GroupByUnit;
	static AddPayer: string = Translations_fi.AddPayer;
	static EmailAddressParameterIsInvalid: string = Translations_fi.EmailAddressParameterIsInvalid;
	static LoggedInUserAccountIsNotActive: string = Translations_fi.LoggedInUserAccountIsNotActive;
	static DeletingInstantMessageWasSuccessful: string = Translations_fi.DeletingInstantMessageWasSuccessful;
	static EditTask: string = Translations_fi.EditTask;
	static DirectoryItemDocumentsNotFound: string = Translations_fi.DirectoryItemDocumentsNotFound;
	static RemoveFileFilter: string = Translations_fi.RemoveFileFilter;
	static RecordUserEmploymentParameterNotFound: string = Translations_fi.RecordUserEmploymentParameterNotFound;
	static ExportToExcel: string = Translations_fi.ExportToExcel;
	static AddFolder: string = Translations_fi.AddFolder;
	static UsersOfThisCompany: string = Translations_fi.UsersOfThisCompany;
	static EventHandler: string = Translations_fi.EventHandler;
	static SaveAndPublish: string = Translations_fi.SaveAndPublish;
	static TrainingEndDate: string = Translations_fi.TrainingEndDate;
	static ReferenceNumberPrefix: string = Translations_fi.ReferenceNumberPrefix;
	static EmailNotificationInUse: string = Translations_fi.EmailNotificationInUse;
	static WhistleBlowSourcePhone: string = Translations_fi.WhistleBlowSourcePhone;
	static RecordUserExcelQualification: string = Translations_fi.RecordUserExcelQualification;
	static BasicEducations: string = Translations_fi.BasicEducations;
	static SavingUserParameterParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingUserParameterParameterWasNotSuccessfulErrorParameter;
	static RemoveQualification: string = Translations_fi.RemoveQualification;
	static TransportCompany: string = Translations_fi.TransportCompany;
	static DeletingCompanyParameterWasSuccessful: string = Translations_fi.DeletingCompanyParameterWasSuccessful;
	static DefaultFamiliarizations: string = Translations_fi.DefaultFamiliarizations;
	static Identifier: string = Translations_fi.Identifier;
	static DeletingKeywordParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingKeywordParameterWasNotSuccessfulConcurrencyError;
	static WhistleBlowPasswordConfirmed: string = Translations_fi.WhistleBlowPasswordConfirmed;
	static NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded: string = Translations_fi.NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded;
	static EventTypeParameterNotFound: string = Translations_fi.EventTypeParameterNotFound;
	static EventsExcel: string = Translations_fi.EventsExcel;
	static AllSelected: string = Translations_fi.AllSelected;
	static AddPartnersParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.AddPartnersParameterWasNotSuccessfulConcurrencyError;
	static AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter: string = Translations_fi.AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter;
	static RemoveRecordUserAndKeepUser: string = Translations_fi.RemoveRecordUserAndKeepUser;
	static SelectUserGroupOrUser: string = Translations_fi.SelectUserGroupOrUser;
	static ModifyDate: string = Translations_fi.ModifyDate;
	static DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError;
	static UserToken: string = Translations_fi.UserToken;
	static EditableDocumentParameterNotFound: string = Translations_fi.EditableDocumentParameterNotFound;
	static WholeHistory: string = Translations_fi.WholeHistory;
	static RestoringTaskParameterFromTrashBinWasSuccessful: string = Translations_fi.RestoringTaskParameterFromTrashBinWasSuccessful;
	static WriteMessage: string = Translations_fi.WriteMessage;
	static RemoveDefaultTaskType: string = Translations_fi.RemoveDefaultTaskType;
	static VersionProperties: string = Translations_fi.VersionProperties;
	static Publish: string = Translations_fi.Publish;
	static CopyActiveUserRightsToAllSameLevelFolders: string = Translations_fi.CopyActiveUserRightsToAllSameLevelFolders;
	static Handling: string = Translations_fi.Handling;
	static WhistleBlowReceiver: string = Translations_fi.WhistleBlowReceiver;
	static CopyDefaultHandlersToAllSameLevelUnits: string = Translations_fi.CopyDefaultHandlersToAllSameLevelUnits;
	static DeletingUserParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingUserParameterWasNotSuccessfulConcurrencyError;
	static SavingMessageParameterWasSuccessful: string = Translations_fi.SavingMessageParameterWasSuccessful;
	static SelectReceiver: string = Translations_fi.SelectReceiver;
	static AllCompanyGroups: string = Translations_fi.AllCompanyGroups;
	static AuthorizationLevelSystemAdmin: string = Translations_fi.AuthorizationLevelSystemAdmin;
	static TaskDataRow: string = Translations_fi.TaskDataRow;
	static Name: string = Translations_fi.Name;
	static DoYouReallyWantToRemovePrintParameter: string = Translations_fi.DoYouReallyWantToRemovePrintParameter;
	static MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError: string = Translations_fi.MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError;
	static CompanyGroupCodeIsAlreadyInUse: string = Translations_fi.CompanyGroupCodeIsAlreadyInUse;
	static KeywordCodeIsAlreadyInUse: string = Translations_fi.KeywordCodeIsAlreadyInUse;
	static SelectRecordUsers: string = Translations_fi.SelectRecordUsers;
	static SSRSParameterReportsFetched: string = Translations_fi.SSRSParameterReportsFetched;
	static DoYouReallyWantToRemoveMessageParameter: string = Translations_fi.DoYouReallyWantToRemoveMessageParameter;
	static InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel: string = Translations_fi.InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel;
	static RemoveUserGroupExternalSystemsWasSuccessful: string = Translations_fi.RemoveUserGroupExternalSystemsWasSuccessful;
	static NameMustBeDefined: string = Translations_fi.NameMustBeDefined;
	static Permission: string = Translations_fi.Permission;
	static ArchivingRecordUserFurtherEducationsWasSuccessful: string = Translations_fi.ArchivingRecordUserFurtherEducationsWasSuccessful;
	static ProcessedFolderParameterErrorCountParameter: string = Translations_fi.ProcessedFolderParameterErrorCountParameter;
	static RelativeSpecification: string = Translations_fi.RelativeSpecification;
	static AddUsersParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddUsersParameterWasNotSuccessfulErrorParameter;
	static CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter;
	static AddUsersParameterWasSuccessful: string = Translations_fi.AddUsersParameterWasSuccessful;
	static TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted: string = Translations_fi.TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted;
	static CurrentWorkPlace: string = Translations_fi.CurrentWorkPlace;
	static JobResultParameterNotFound: string = Translations_fi.JobResultParameterNotFound;
	static GetPhoto: string = Translations_fi.GetPhoto;
	static MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError;
	static RemoveKeyword: string = Translations_fi.RemoveKeyword;
	static DeletingRecordUserTransferredPropertiesParameterWasSuccessful: string = Translations_fi.DeletingRecordUserTransferredPropertiesParameterWasSuccessful;
	static InstantMessageChannelArchivingType: string = Translations_fi.InstantMessageChannelArchivingType;
	static WhistleBlowSourceSystem: string = Translations_fi.WhistleBlowSourceSystem;
	static WhistleBlowReportHasBeenSaved: string = Translations_fi.WhistleBlowReportHasBeenSaved;
	static PrintTextPublished: string = Translations_fi.PrintTextPublished;
	static Internet: string = Translations_fi.Internet;
	static UserIsActiveInWeb: string = Translations_fi.UserIsActiveInWeb;
	static AuthorizationLevelUser: string = Translations_fi.AuthorizationLevelUser;
	static EventTypeCCodeMustBeDefined: string = Translations_fi.EventTypeCCodeMustBeDefined;
	static InstantMessage: string = Translations_fi.InstantMessage;
	static ChangingPasswordWasNotSuccessfulConcurrencyError: string = Translations_fi.ChangingPasswordWasNotSuccessfulConcurrencyError;
	static InheritedUserRight: string = Translations_fi.InheritedUserRight;
	static SaveFilterAs: string = Translations_fi.SaveFilterAs;
	static All: string = Translations_fi.All;
	static EditCompanyTaskType: string = Translations_fi.EditCompanyTaskType;
	static DoYouReallyWantToRemoveSelectedUsers: string = Translations_fi.DoYouReallyWantToRemoveSelectedUsers;
	static LockedDocumentParameterCannotBeDeleted: string = Translations_fi.LockedDocumentParameterCannotBeDeleted;
	static BrowseFiles: string = Translations_fi.BrowseFiles;
	static SavingUserParameterParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingUserParameterParameterWasNotSuccessfulConcurrencyError;
	static SavingEventTypeCsWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventTypeCsWasNotSuccessfulErrorParameter;
	static LogisticVoucherCustomer: string = Translations_fi.LogisticVoucherCustomer;
	static SelectInstantMessageChannelUsers: string = Translations_fi.SelectInstantMessageChannelUsers;
	static SavingUnitPermissionsWasNotSuccessfulErrorParameter: string = Translations_fi.SavingUnitPermissionsWasNotSuccessfulErrorParameter;
	static LanguageEnglish: string = Translations_fi.LanguageEnglish;
	static MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful: string = Translations_fi.MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful;
	static UserGroup: string = Translations_fi.UserGroup;
	static Instructions: string = Translations_fi.Instructions;
	static PanelSize2: string = Translations_fi.PanelSize2;
	static CompanyRole: string = Translations_fi.CompanyRole;
	static ReadingFileParameterWasNotSuccessfulErrorParameter: string = Translations_fi.ReadingFileParameterWasNotSuccessfulErrorParameter;
	static InstantMessageChannelGroupInternal: string = Translations_fi.InstantMessageChannelGroupInternal;
	static UsedQuota: string = Translations_fi.UsedQuota;
	static UnreadInstantMessages: string = Translations_fi.UnreadInstantMessages;
	static SelectCompany: string = Translations_fi.SelectCompany;
	static RemoveTaskPermanently: string = Translations_fi.RemoveTaskPermanently;
	static Remove: string = Translations_fi.Remove;
	static WhistleBlowChannelParameterNotFound: string = Translations_fi.WhistleBlowChannelParameterNotFound;
	static LockedTaskDataRowCannotBeSaved: string = Translations_fi.LockedTaskDataRowCannotBeSaved;
	static MessageStartDateMustBeDefined: string = Translations_fi.MessageStartDateMustBeDefined;
	static Edit: string = Translations_fi.Edit;
	static DoYouReallyWantToRemoveCompanyParameter: string = Translations_fi.DoYouReallyWantToRemoveCompanyParameter;
	static DeletingMessageTypeParameterWasSuccessful: string = Translations_fi.DeletingMessageTypeParameterWasSuccessful;
	static SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter;
	static Descending: string = Translations_fi.Descending;
	static SavingEventTypeParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventTypeParameterWasNotSuccessfulErrorParameter;
	static PasswordIsTooShort: string = Translations_fi.PasswordIsTooShort;
	static DoYouReallyWantToRemoveWhistleBlowCategoryParameter: string = Translations_fi.DoYouReallyWantToRemoveWhistleBlowCategoryParameter;
	static ExpiringIn120Days: string = Translations_fi.ExpiringIn120Days;
	static AddUserGroupsParameterWasSuccessful: string = Translations_fi.AddUserGroupsParameterWasSuccessful;
	static TakePicture: string = Translations_fi.TakePicture;
	static Reports: string = Translations_fi.Reports;
	static AccessRightFull: string = Translations_fi.AccessRightFull;
	static Update: string = Translations_fi.Update;
	static TaskTypeParameterNotFound: string = Translations_fi.TaskTypeParameterNotFound;
	static FollowUp: string = Translations_fi.FollowUp;
	static AddEmployments: string = Translations_fi.AddEmployments;
	static WhistleBlowSeverityNormal: string = Translations_fi.WhistleBlowSeverityNormal;
	static EditUnit: string = Translations_fi.EditUnit;
	static Contents: string = Translations_fi.Contents;
	static ReadError: string = Translations_fi.ReadError;
	static AddFamiliarizationToMultipleUserRecords: string = Translations_fi.AddFamiliarizationToMultipleUserRecords;
	static RemoveInvalidDefaultEventHandlersConfirmation: string = Translations_fi.RemoveInvalidDefaultEventHandlersConfirmation;
	static ResultGrouping: string = Translations_fi.ResultGrouping;
	static DocumentContentsSearch: string = Translations_fi.DocumentContentsSearch;
	static DeletingTaskParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingTaskParameterWasNotSuccessfulConcurrencyError;
	static SavingDefaultHandlersWasNotSuccessfulErrorParameter: string = Translations_fi.SavingDefaultHandlersWasNotSuccessfulErrorParameter;
	static WhistleBlowState: string = Translations_fi.WhistleBlowState;
	static FileFilterParameterNotFound: string = Translations_fi.FileFilterParameterNotFound;
	static DoYouReallyWantToCreateRemainderInPast: string = Translations_fi.DoYouReallyWantToCreateRemainderInPast;
	static ExitingInstantMessageChannelWasSuccessful: string = Translations_fi.ExitingInstantMessageChannelWasSuccessful;
	static SharedFilters: string = Translations_fi.SharedFilters;
	static MaximumSizeOfInstantMessageAttachmentIsParameterMB: string = Translations_fi.MaximumSizeOfInstantMessageAttachmentIsParameterMB;
	static Send: string = Translations_fi.Send;
	static ApproveEventHandlerConfirmation: string = Translations_fi.ApproveEventHandlerConfirmation;
	static SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter;
	static EditTaskPriority: string = Translations_fi.EditTaskPriority;
	static AutoSaveVersionHistorySize: string = Translations_fi.AutoSaveVersionHistorySize;
	static SavingEventTypeBParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventTypeBParameterWasNotSuccessfulConcurrencyError;
	static Folder: string = Translations_fi.Folder;
	static NotifiedPersonsShort: string = Translations_fi.NotifiedPersonsShort;
	static Event: string = Translations_fi.Event;
	static ReadReceiptRequired: string = Translations_fi.ReadReceiptRequired;
	static AddDefaultTaskState: string = Translations_fi.AddDefaultTaskState;
	static AddDefaultFurtherEducation: string = Translations_fi.AddDefaultFurtherEducation;
	static MessageEndDate: string = Translations_fi.MessageEndDate;
	static WhistleBlowArchivingTypeChangeStateAndRemoveAllData: string = Translations_fi.WhistleBlowArchivingTypeChangeStateAndRemoveAllData;
	static AttachmentParameterNotFound: string = Translations_fi.AttachmentParameterNotFound;
	static DownloadDocument: string = Translations_fi.DownloadDocument;
	static DirectCosts: string = Translations_fi.DirectCosts;
	static SaveTask: string = Translations_fi.SaveTask;
	static SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter;
	static SavingKeywordParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingKeywordParameterWasNotSuccessfulErrorParameter;
	static SelectableWhenCreatingAnEvent: string = Translations_fi.SelectableWhenCreatingAnEvent;
	static DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter;
	static GroupByYear: string = Translations_fi.GroupByYear;
	static UserParameterCodeIsAlreadyInUse: string = Translations_fi.UserParameterCodeIsAlreadyInUse;
	static WhistleBlowSeverityUnspecified: string = Translations_fi.WhistleBlowSeverityUnspecified;
	static StudyWeeks: string = Translations_fi.StudyWeeks;
	static WhistleBlowSendReport: string = Translations_fi.WhistleBlowSendReport;
	static NoToDos: string = Translations_fi.NoToDos;
	static UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter: string = Translations_fi.UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter;
	static AddCompanyGroupParameterWasSuccessful: string = Translations_fi.AddCompanyGroupParameterWasSuccessful;
	static NoPermission: string = Translations_fi.NoPermission;
	static EventTypeCodeMustBeDefined: string = Translations_fi.EventTypeCodeMustBeDefined;
	static AddRows: string = Translations_fi.AddRows;
	static SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError;
	static SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError;
	static TaskDataRowFieldParameterNotFoundInDataSchema: string = Translations_fi.TaskDataRowFieldParameterNotFoundInDataSchema;
	static AddInstantMessageUserParameterWasSuccessful: string = Translations_fi.AddInstantMessageUserParameterWasSuccessful;
	static Employee: string = Translations_fi.Employee;
	static EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted: string = Translations_fi.EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted;
	static Card: string = Translations_fi.Card;
	static AddingAttachmentParameterWasSuccessful: string = Translations_fi.AddingAttachmentParameterWasSuccessful;
	static CreatingFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.CreatingFolderParameterWasNotSuccessfulErrorParameter;
	static PrivacyPolicyUrl: string = Translations_fi.PrivacyPolicyUrl;
	static ReadReceiptNotGiven: string = Translations_fi.ReadReceiptNotGiven;
	static WhistleBlowStatisticsUnreadMessages: string = Translations_fi.WhistleBlowStatisticsUnreadMessages;
	static ArchiveExpiredFurtherEducations: string = Translations_fi.ArchiveExpiredFurtherEducations;
	static AddCompanyTaskType: string = Translations_fi.AddCompanyTaskType;
	static Default: string = Translations_fi.Default;
	static MinimumPasswordLengthMustBeAtLeastParameterCharacters: string = Translations_fi.MinimumPasswordLengthMustBeAtLeastParameterCharacters;
	static PanelName: string = Translations_fi.PanelName;
	static WhistleBlowEventTypeHandlersAdded: string = Translations_fi.WhistleBlowEventTypeHandlersAdded;
	static MessagePreservationTime: string = Translations_fi.MessagePreservationTime;
	static LockedEventParameterCannotBeSaved: string = Translations_fi.LockedEventParameterCannotBeSaved;
	static NoFavoritesToShow: string = Translations_fi.NoFavoritesToShow;
	static AddUserToUserGroup: string = Translations_fi.AddUserToUserGroup;
	static SavingDocumentTemplateParameterWasSuccessful: string = Translations_fi.SavingDocumentTemplateParameterWasSuccessful;
	static PublishEvent: string = Translations_fi.PublishEvent;
	static SelectInstantMessageChannelUser: string = Translations_fi.SelectInstantMessageChannelUser;
	static FileFilters: string = Translations_fi.FileFilters;
	static AllJobTitles: string = Translations_fi.AllJobTitles;
	static DeletingUsersParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingUsersParameterWasNotSuccessfulErrorParameter;
	static AddWhistleBlow: string = Translations_fi.AddWhistleBlow;
	static SavingTaskParameterWasSuccessful: string = Translations_fi.SavingTaskParameterWasSuccessful;
	static DefaultEventTypes: string = Translations_fi.DefaultEventTypes;
	static DefaultEventHandlerParameterNotFound: string = Translations_fi.DefaultEventHandlerParameterNotFound;
	static AddTaskState: string = Translations_fi.AddTaskState;
	static InstantMessageChannelUser: string = Translations_fi.InstantMessageChannelUser;
	static SharedReports: string = Translations_fi.SharedReports;
	static PaymentVoucherCounter: string = Translations_fi.PaymentVoucherCounter;
	static Admins: string = Translations_fi.Admins;
	static DocumentParameterDownloaded: string = Translations_fi.DocumentParameterDownloaded;
	static PublishTaskType: string = Translations_fi.PublishTaskType;
	static NewPassword: string = Translations_fi.NewPassword;
	static DoYouReallyWantToRemoveTaskHandlerParameter: string = Translations_fi.DoYouReallyWantToRemoveTaskHandlerParameter;
	static RemovePermission: string = Translations_fi.RemovePermission;
	static TimeSpanQuarter: string = Translations_fi.TimeSpanQuarter;
	static EmailHasBeenAlreadySentForThisMessage: string = Translations_fi.EmailHasBeenAlreadySentForThisMessage;
	static DeletingMessageParameterWasSuccessful: string = Translations_fi.DeletingMessageParameterWasSuccessful;
	static DeletingTaskStateParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingTaskStateParameterWasNotSuccessfulConcurrencyError;
	static Qualifications: string = Translations_fi.Qualifications;
	static CostCentreName: string = Translations_fi.CostCentreName;
	static SharedReportAllCompanies: string = Translations_fi.SharedReportAllCompanies;
	static UserGroupCodeIsAlreadyInUse: string = Translations_fi.UserGroupCodeIsAlreadyInUse;
	static PrivateAndPublic: string = Translations_fi.PrivateAndPublic;
	static CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError;
	static TaskDashboard: string = Translations_fi.TaskDashboard;
	static DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter;
	static WhistleBlowFollowReport: string = Translations_fi.WhistleBlowFollowReport;
	static UserCodeIsAlreadyInUse: string = Translations_fi.UserCodeIsAlreadyInUse;
	static AgencyWorker: string = Translations_fi.AgencyWorker;
	static SavingReportWasSuccessful: string = Translations_fi.SavingReportWasSuccessful;
	static RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted: string = Translations_fi.RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted;
	static RemoveEventTypeB: string = Translations_fi.RemoveEventTypeB;
	static DoYouReallyWantToRemoveDocumentsParameter: string = Translations_fi.DoYouReallyWantToRemoveDocumentsParameter;
	static DeletingTaskDataRowWasSuccessful: string = Translations_fi.DeletingTaskDataRowWasSuccessful;
	static TitleMustBeDefined: string = Translations_fi.TitleMustBeDefined;
	static UseInheritedContentFiltering: string = Translations_fi.UseInheritedContentFiltering;
	static AddRow: string = Translations_fi.AddRow;
	static MessageType: string = Translations_fi.MessageType;
	static UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter: string = Translations_fi.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter;
	static DocumentTemplateCodeIsAlreadyInUse: string = Translations_fi.DocumentTemplateCodeIsAlreadyInUse;
	static ShowUsers: string = Translations_fi.ShowUsers;
	static Orientation: string = Translations_fi.Orientation;
	static LoggedInUser: string = Translations_fi.LoggedInUser;
	static VersionInformation: string = Translations_fi.VersionInformation;
	static RecentlyUpdated: string = Translations_fi.RecentlyUpdated;
	static LanguageNonLocalSwedish: string = Translations_fi.LanguageNonLocalSwedish;
	static EditEventPartner: string = Translations_fi.EditEventPartner;
	static SavingRecordUserTransferredPropertyParameterWasSuccessful: string = Translations_fi.SavingRecordUserTransferredPropertyParameterWasSuccessful;
	static EditSharedDocumentTemplate: string = Translations_fi.EditSharedDocumentTemplate;
	static PrivacyPolicyUrlMustBeDefined: string = Translations_fi.PrivacyPolicyUrlMustBeDefined;
	static MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter;
	static DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter;
	static EventsToApprove: string = Translations_fi.EventsToApprove;
	static DoYouReallyWantToRemoveEventHandlerParameter: string = Translations_fi.DoYouReallyWantToRemoveEventHandlerParameter;
	static SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError;
	static RememberMe: string = Translations_fi.RememberMe;
	static SavingMessageTypeParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingMessageTypeParameterWasNotSuccessfulConcurrencyError;
	static DeletingRecordUserEmploymentsParameterWasSuccessful: string = Translations_fi.DeletingRecordUserEmploymentsParameterWasSuccessful;
	static RecordParameterLockingFailedRecordIsWriteLockedToUserParameter: string = Translations_fi.RecordParameterLockingFailedRecordIsWriteLockedToUserParameter;
	static Landscape: string = Translations_fi.Landscape;
	static AddEditableDocument: string = Translations_fi.AddEditableDocument;
	static RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted: string = Translations_fi.RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted;
	static RecordBasicEducationCodeIsAlreadyInUse: string = Translations_fi.RecordBasicEducationCodeIsAlreadyInUse;
	static UserRecordStatisticsArchivedCards: string = Translations_fi.UserRecordStatisticsArchivedCards;
	static EditTaskState: string = Translations_fi.EditTaskState;
	static PermissionToCreateEvents: string = Translations_fi.PermissionToCreateEvents;
	static ActiveUserRights: string = Translations_fi.ActiveUserRights;
	static DoYouReallyWantToRemoveFilterParameter: string = Translations_fi.DoYouReallyWantToRemoveFilterParameter;
	static DriversLicenseType: string = Translations_fi.DriversLicenseType;
	static RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter: string = Translations_fi.RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter;
	static AddInstantMessageChannel: string = Translations_fi.AddInstantMessageChannel;
	static SavingReportWasNotSuccessfulErrorParameter: string = Translations_fi.SavingReportWasNotSuccessfulErrorParameter;
	static AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter;
	static Resolution: string = Translations_fi.Resolution;
	static RemoveUserAndRecordUser: string = Translations_fi.RemoveUserAndRecordUser;
	static KeywordsAndCondition: string = Translations_fi.KeywordsAndCondition;
	static EditAttachment: string = Translations_fi.EditAttachment;
	static TaskTypeSchemaMustBeDefined: string = Translations_fi.TaskTypeSchemaMustBeDefined;
	static SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter;
	static WhistleBlowSeverityCritical: string = Translations_fi.WhistleBlowSeverityCritical;
	static AccessRightReadRecords: string = Translations_fi.AccessRightReadRecords;
	static PaymentSetting: string = Translations_fi.PaymentSetting;
	static AdvancePaymentReportPath: string = Translations_fi.AdvancePaymentReportPath;
	static ReadReceiptMessage: string = Translations_fi.ReadReceiptMessage;
	static Others: string = Translations_fi.Others;
	static NotAssigned: string = Translations_fi.NotAssigned;
	static TaskReport: string = Translations_fi.TaskReport;
	static DeletingWhistleBlowCategoryParameterWasSuccessful: string = Translations_fi.DeletingWhistleBlowCategoryParameterWasSuccessful;
	static AddDefaultTaskPriority: string = Translations_fi.AddDefaultTaskPriority;
	static BasicEducationsMustBeDefinedBeforeAddingRecordUsers: string = Translations_fi.BasicEducationsMustBeDefinedBeforeAddingRecordUsers;
	static PrintTextPrinted: string = Translations_fi.PrintTextPrinted;
	static Public: string = Translations_fi.Public;
	static ByDate: string = Translations_fi.ByDate;
	static EditEmployment: string = Translations_fi.EditEmployment;
	static RecordUserFurtherEducationParameterNotFound: string = Translations_fi.RecordUserFurtherEducationParameterNotFound;
	static RowInformation: string = Translations_fi.RowInformation;
	static WhistleBlowStateResolved: string = Translations_fi.WhistleBlowStateResolved;
	static EventType: string = Translations_fi.EventType;
	static ContactPerson: string = Translations_fi.ContactPerson;
	static LastNameMustBeDefined: string = Translations_fi.LastNameMustBeDefined;
	static RemoveEditableDocument: string = Translations_fi.RemoveEditableDocument;
	static Clear: string = Translations_fi.Clear;
	static DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError;
	static AddEventTypeCsToEvent: string = Translations_fi.AddEventTypeCsToEvent;
	static TheUserDoesNotHavePermissionsForThisSystem: string = Translations_fi.TheUserDoesNotHavePermissionsForThisSystem;
	static Task: string = Translations_fi.Task;
	static Units: string = Translations_fi.Units;
	static MessageTypeClass: string = Translations_fi.MessageTypeClass;
	static AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter: string = Translations_fi.AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter;
	static ContentVersion: string = Translations_fi.ContentVersion;
	static SavingRecordUserParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingRecordUserParameterWasNotSuccessfulErrorParameter;
	static ImageOrPdfUpToParameterMb: string = Translations_fi.ImageOrPdfUpToParameterMb;
	static DefaultEventSettings: string = Translations_fi.DefaultEventSettings;
	static RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted: string = Translations_fi.RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted;
	static ChartGenerationFailed: string = Translations_fi.ChartGenerationFailed;
	static TaskRepetitionType: string = Translations_fi.TaskRepetitionType;
	static PositionErrorTimeout: string = Translations_fi.PositionErrorTimeout;
	static MoneyIn: string = Translations_fi.MoneyIn;
	static CreateReport: string = Translations_fi.CreateReport;
	static DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter;
	static TaskDataRows: string = Translations_fi.TaskDataRows;
	static FurtherEducations: string = Translations_fi.FurtherEducations;
	static EventApprovalWasNotSuccessfulConcurrencyError: string = Translations_fi.EventApprovalWasNotSuccessfulConcurrencyError;
	static TransferredProperties: string = Translations_fi.TransferredProperties;
	static WhistleBlowInformantInstuctions: string = Translations_fi.WhistleBlowInformantInstuctions;
	static Phone: string = Translations_fi.Phone;
	static Approval: string = Translations_fi.Approval;
	static AddInstantMessageUserParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddInstantMessageUserParameterWasNotSuccessfulErrorParameter;
	static LanguageSwedish: string = Translations_fi.LanguageSwedish;
	static FileSynchronizationToDatabaseStarted: string = Translations_fi.FileSynchronizationToDatabaseStarted;
	static DoYouReallyWantToRemoveInstantMessageChannelParameter: string = Translations_fi.DoYouReallyWantToRemoveInstantMessageChannelParameter;
	static UserParameterNotFound: string = Translations_fi.UserParameterNotFound;
	static RecordEmailNotificationShowExpired: string = Translations_fi.RecordEmailNotificationShowExpired;
	static SomeWhistleBlowHandlersHaveReadMessage: string = Translations_fi.SomeWhistleBlowHandlersHaveReadMessage;
	static ArchivingWhistleBlowsParameterWasSuccessful: string = Translations_fi.ArchivingWhistleBlowsParameterWasSuccessful;
	static TargetDoesNotHaveFoldersWithWritePermissions: string = Translations_fi.TargetDoesNotHaveFoldersWithWritePermissions;
	static RemoveNotifiedPerson: string = Translations_fi.RemoveNotifiedPerson;
	static FileParameterIsEmpty: string = Translations_fi.FileParameterIsEmpty;
	static WhistleBlowEventTypeMessageAdded: string = Translations_fi.WhistleBlowEventTypeMessageAdded;
	static AccessRightWriteRecords: string = Translations_fi.AccessRightWriteRecords;
	static WhistleBlowDoesNotContainAnyMessage: string = Translations_fi.WhistleBlowDoesNotContainAnyMessage;
	static NewCompanyInformation: string = Translations_fi.NewCompanyInformation;
	static SaveToTemplate: string = Translations_fi.SaveToTemplate;
	static DeletingTaskParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingTaskParameterWasNotSuccessfulErrorParameter;
	static PublicationOfEditableDocumentCannotBeRemoved: string = Translations_fi.PublicationOfEditableDocumentCannotBeRemoved;
	static MinimumPasswordLength: string = Translations_fi.MinimumPasswordLength;
	static SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter;
	static DeletingInstantMessageWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingInstantMessageWasNotSuccessfulErrorParameter;
	static LastYear: string = Translations_fi.LastYear;
	static EventSettings: string = Translations_fi.EventSettings;
	static AnyTime: string = Translations_fi.AnyTime;
	static NoEmailsToSend: string = Translations_fi.NoEmailsToSend;
	static GroupByHalfYear: string = Translations_fi.GroupByHalfYear;
	static NextOfKin: string = Translations_fi.NextOfKin;
	static UploadDocument: string = Translations_fi.UploadDocument;
	static AddPhotosAndAttachementsToEvent: string = Translations_fi.AddPhotosAndAttachementsToEvent;
	static SavingDirectoryItemDocumentParameterWasSuccessful: string = Translations_fi.SavingDirectoryItemDocumentParameterWasSuccessful;
	static PageNameMustBeDefined: string = Translations_fi.PageNameMustBeDefined;
	static SelectRecordUserTypeToBeCreated: string = Translations_fi.SelectRecordUserTypeToBeCreated;
	static AdditionalPermissionAInstantMessages: string = Translations_fi.AdditionalPermissionAInstantMessages;
	static NoAddedEventTypes: string = Translations_fi.NoAddedEventTypes;
	static WhistleBlow: string = Translations_fi.WhistleBlow;
	static IncludeArchivedFurtherEducations: string = Translations_fi.IncludeArchivedFurtherEducations;
	static ValidityEndDateExpirationTime: string = Translations_fi.ValidityEndDateExpirationTime;
	static Education: string = Translations_fi.Education;
	static Attachments: string = Translations_fi.Attachments;
	static SavingEventMessageWasSuccessful: string = Translations_fi.SavingEventMessageWasSuccessful;
	static Months: string = Translations_fi.Months;
	static WhistleBlowDescription: string = Translations_fi.WhistleBlowDescription;
	static WhistleBlowResolutionTypeMustBeDefined: string = Translations_fi.WhistleBlowResolutionTypeMustBeDefined;
	static NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded: string = Translations_fi.NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded;
	static AddUserParameterWasSuccessful: string = Translations_fi.AddUserParameterWasSuccessful;
	static OldPassword: string = Translations_fi.OldPassword;
	static IncludeOnlyActiveUsers: string = Translations_fi.IncludeOnlyActiveUsers;
	static CopyOrMoveFolder: string = Translations_fi.CopyOrMoveFolder;
	static FurtherEducation: string = Translations_fi.FurtherEducation;
	static RemoveDefaultTaskState: string = Translations_fi.RemoveDefaultTaskState;
	static ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter: string = Translations_fi.ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter;
	static WhistleBlowChannelPageNameCodeIsAlreadyInUse: string = Translations_fi.WhistleBlowChannelPageNameCodeIsAlreadyInUse;
	static SavingUnitsWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingUnitsWasNotSuccessfulConcurrencyError;
	static Banner: string = Translations_fi.Banner;
	static PreventiveAction: string = Translations_fi.PreventiveAction;
	static SetUnitPermissions: string = Translations_fi.SetUnitPermissions;
	static InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter: string = Translations_fi.InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter;
	static Total: string = Translations_fi.Total;
	static RecordUserLogEntryTypePrint: string = Translations_fi.RecordUserLogEntryTypePrint;
	static RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError: string = Translations_fi.RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError;
	static SavingEventTypeBsWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventTypeBsWasNotSuccessfulErrorParameter;
	static RecordUserParameterNotFound: string = Translations_fi.RecordUserParameterNotFound;
	static SourceDescription: string = Translations_fi.SourceDescription;
	static ParameterResults: string = Translations_fi.ParameterResults;
	static Camera: string = Translations_fi.Camera;
	static UserNotAllowedToViewNotifier: string = Translations_fi.UserNotAllowedToViewNotifier;
	static EmployeeTypeMustBeDefined: string = Translations_fi.EmployeeTypeMustBeDefined;
	static DeletingMessageParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingMessageParameterWasNotSuccessfulConcurrencyError;
	static DeletingUserGroupsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingUserGroupsParameterWasNotSuccessfulErrorParameter;
	static ExpiredFamiliarizations: string = Translations_fi.ExpiredFamiliarizations;
	static AllUserGroups: string = Translations_fi.AllUserGroups;
	static SavingRecordUserParameterWasSuccessful: string = Translations_fi.SavingRecordUserParameterWasSuccessful;
	static EmailAddress: string = Translations_fi.EmailAddress;
	static IncludeArchivedFamiliarizations: string = Translations_fi.IncludeArchivedFamiliarizations;
	static TaskMustBeSavedBeforeNewDataRowsCanBeAdded: string = Translations_fi.TaskMustBeSavedBeforeNewDataRowsCanBeAdded;
	static RecordUserTrangerredPropertyParameterNotFound: string = Translations_fi.RecordUserTrangerredPropertyParameterNotFound;
	static RemoveTaskState: string = Translations_fi.RemoveTaskState;
	static AddingAttachmentsParameterWasSuccessful: string = Translations_fi.AddingAttachmentsParameterWasSuccessful;
	static PasswordConfirmationDoesNotMatchPassword: string = Translations_fi.PasswordConfirmationDoesNotMatchPassword;
	static DeletingFileFilterParameterWasSuccessful: string = Translations_fi.DeletingFileFilterParameterWasSuccessful;
	static RemovePayer: string = Translations_fi.RemovePayer;
	static NoneSelected: string = Translations_fi.NoneSelected;
	static UpdateUserParameterExternalSystemsWasSuccessful: string = Translations_fi.UpdateUserParameterExternalSystemsWasSuccessful;
	static SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError;
	static DefaultBasicEducations: string = Translations_fi.DefaultBasicEducations;
	static TiedostoturvaUseType: string = Translations_fi.TiedostoturvaUseType;
	static OptionCodeIsAlreadyInUse: string = Translations_fi.OptionCodeIsAlreadyInUse;
	static ConfirmPassword: string = Translations_fi.ConfirmPassword;
	static Repeated: string = Translations_fi.Repeated;
	static RemoveComment: string = Translations_fi.RemoveComment;
	static ActivePlural: string = Translations_fi.ActivePlural;
	static MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB: string = Translations_fi.MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB;
	static InstantMessageUserInvitationAcceptTypeCannotInvite: string = Translations_fi.InstantMessageUserInvitationAcceptTypeCannotInvite;
	static SavingUnitsWasSuccessful: string = Translations_fi.SavingUnitsWasSuccessful;
	static DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter;
	static Sending: string = Translations_fi.Sending;
	static UserIsActiveInMobile: string = Translations_fi.UserIsActiveInMobile;
	static Search: string = Translations_fi.Search;
	static FolderMustBeEmptyBeforeItCanBeRemoved: string = Translations_fi.FolderMustBeEmptyBeforeItCanBeRemoved;
	static Approve: string = Translations_fi.Approve;
	static EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted: string = Translations_fi.EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted;
	static FileSynchronizationToDatabaseFinishedInParameterSeconds: string = Translations_fi.FileSynchronizationToDatabaseFinishedInParameterSeconds;
	static DefaultEventHandlersAndNotifiedPersons: string = Translations_fi.DefaultEventHandlersAndNotifiedPersons;
	static YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType: string = Translations_fi.YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType;
	static AdditionalInformation: string = Translations_fi.AdditionalInformation;
	static AddingTaskTypeParameterWasSuccessful: string = Translations_fi.AddingTaskTypeParameterWasSuccessful;
	static DefaultFurtherEducations: string = Translations_fi.DefaultFurtherEducations;
	static AddDefaultTaskType: string = Translations_fi.AddDefaultTaskType;
	static EditLogisticVoucherCustomer: string = Translations_fi.EditLogisticVoucherCustomer;
	static DeletingUserGroupParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingUserGroupParameterWasNotSuccessfulErrorParameter;
	static RemoveDocument: string = Translations_fi.RemoveDocument;
	static InstantMessageChannelUsersNotUsable: string = Translations_fi.InstantMessageChannelUsersNotUsable;
	static DoYouReallyWantToRemovePartnerParameter: string = Translations_fi.DoYouReallyWantToRemovePartnerParameter;
	static SavingPrintWasSuccessful: string = Translations_fi.SavingPrintWasSuccessful;
	static AccountNumberTkbOutCredit: string = Translations_fi.AccountNumberTkbOutCredit;
	static DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter;
	static Title: string = Translations_fi.Title;
	static ApprovalComment: string = Translations_fi.ApprovalComment;
	static NewCompanyMustBeSavedBeforePartnersCanBeAdded: string = Translations_fi.NewCompanyMustBeSavedBeforePartnersCanBeAdded;
	static DeletingEditableDocumentParameterWasSuccessful: string = Translations_fi.DeletingEditableDocumentParameterWasSuccessful;
	static EditDefaultTaskPriority: string = Translations_fi.EditDefaultTaskPriority;
	static ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers: string = Translations_fi.ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers;
	static VersionHistory: string = Translations_fi.VersionHistory;
	static EditDefaultEventType: string = Translations_fi.EditDefaultEventType;
	static EditDefaultTaskType: string = Translations_fi.EditDefaultTaskType;
	static PageName: string = Translations_fi.PageName;
	static EditLogisticVoucherCompanyPaymentSetting: string = Translations_fi.EditLogisticVoucherCompanyPaymentSetting;
	static AddingTaskTypesParameterWasSuccessful: string = Translations_fi.AddingTaskTypesParameterWasSuccessful;
	static WhistleBlowChannelSettings: string = Translations_fi.WhistleBlowChannelSettings;
	static FileNameMustBeDefined: string = Translations_fi.FileNameMustBeDefined;
	static EInvoiceAddress: string = Translations_fi.EInvoiceAddress;
	static SavingDefaultHandlersWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingDefaultHandlersWasNotSuccessfulConcurrencyError;
	static AddDefaultEventHandler: string = Translations_fi.AddDefaultEventHandler;
	static LockedDocumentsParameterCannotBeMoved: string = Translations_fi.LockedDocumentsParameterCannotBeMoved;
	static OptionParameterNotFound: string = Translations_fi.OptionParameterNotFound;
	static MessageUnit: string = Translations_fi.MessageUnit;
	static CommentMustNotBeEmpty: string = Translations_fi.CommentMustNotBeEmpty;
	static AddFileOrTakePicture: string = Translations_fi.AddFileOrTakePicture;
	static WhistleBlowResolutionJunk: string = Translations_fi.WhistleBlowResolutionJunk;
	static Loading: string = Translations_fi.Loading;
	static PrintRow: string = Translations_fi.PrintRow;
	static Logo: string = Translations_fi.Logo;
	static DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError;
	static Summary: string = Translations_fi.Summary;
	static DeletingTaskParameterWasSuccessful: string = Translations_fi.DeletingTaskParameterWasSuccessful;
	static Communication: string = Translations_fi.Communication;
	static DataTransferError: string = Translations_fi.DataTransferError;
	static AddCompanyGroupParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddCompanyGroupParameterWasNotSuccessfulErrorParameter;
	static ValidityEndDateStart: string = Translations_fi.ValidityEndDateStart;
	static DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError;
	static ExpiringIn180Days: string = Translations_fi.ExpiringIn180Days;
	static LoggedInCompanyCannotBeDeleted: string = Translations_fi.LoggedInCompanyCannotBeDeleted;
	static LogSizeInDays: string = Translations_fi.LogSizeInDays;
	static RemoveUserAndRecordUserArchive: string = Translations_fi.RemoveUserAndRecordUserArchive;
	static UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError: string = Translations_fi.UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError;
	static Warning: string = Translations_fi.Warning;
	static Employments: string = Translations_fi.Employments;
	static LoginAddress: string = Translations_fi.LoginAddress;
	static LoginTitle: string = Translations_fi.LoginTitle;
	static OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater: string = Translations_fi.OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater;
	static LockedTaskDataRowCannotBeDeleted: string = Translations_fi.LockedTaskDataRowCannotBeDeleted;
	static ReportGenerationAborted: string = Translations_fi.ReportGenerationAborted;
	static DeletingEventParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingEventParameterWasNotSuccessfulConcurrencyError;
	static EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted: string = Translations_fi.EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted;
	static PanelSize: string = Translations_fi.PanelSize;
	static ApproveEvent: string = Translations_fi.ApproveEvent;
	static RecordUserExcelUserList: string = Translations_fi.RecordUserExcelUserList;
	static AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter: string = Translations_fi.AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter;
	static InstantMessageParameterNotFound: string = Translations_fi.InstantMessageParameterNotFound;
	static AddBasicEducation: string = Translations_fi.AddBasicEducation;
	static JobTypeMessageNotification: string = Translations_fi.JobTypeMessageNotification;
	static SendingEmails: string = Translations_fi.SendingEmails;
	static CopyingFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.CopyingFolderParameterWasNotSuccessfulErrorParameter;
	static DeletingRecordBasicEducationParameterWasSuccessful: string = Translations_fi.DeletingRecordBasicEducationParameterWasSuccessful;
	static DocumentVersionParameterNotFound: string = Translations_fi.DocumentVersionParameterNotFound;
	static AddWhistleBlowCategory: string = Translations_fi.AddWhistleBlowCategory;
	static RemoveInstantMessageChannelUser: string = Translations_fi.RemoveInstantMessageChannelUser;
	static SavingImagesWasSuccessful: string = Translations_fi.SavingImagesWasSuccessful;
	static RecordFamiliarizationCodeIsAlreadyInUse: string = Translations_fi.RecordFamiliarizationCodeIsAlreadyInUse;
	static SavingEventTypeCParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventTypeCParameterWasNotSuccessfulErrorParameter;
	static TargetsMyCompany: string = Translations_fi.TargetsMyCompany;
	static UpdatingApplication: string = Translations_fi.UpdatingApplication;
	static RecordFurtherEducationCodeIsAlreadyInUse: string = Translations_fi.RecordFurtherEducationCodeIsAlreadyInUse;
	static AddRecordUserAndUserAccount: string = Translations_fi.AddRecordUserAndUserAccount;
	static EventTypeCTitle: string = Translations_fi.EventTypeCTitle;
	static TaskStateCodeIsAlreadyInUse: string = Translations_fi.TaskStateCodeIsAlreadyInUse;
	static Anonymity: string = Translations_fi.Anonymity;
	static AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter: string = Translations_fi.AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter;
	static InstantMessageChannelInformation: string = Translations_fi.InstantMessageChannelInformation;
	static DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError;
	static AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError;
	static RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter: string = Translations_fi.RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter;
	static UnitProperties: string = Translations_fi.UnitProperties;
	static JobDataIsEmpty: string = Translations_fi.JobDataIsEmpty;
	static Amount: string = Translations_fi.Amount;
	static WhistleBlowDateAndTimeCannotBeInFuture: string = Translations_fi.WhistleBlowDateAndTimeCannotBeInFuture;
	static SavingTaskTypeParameterWasSuccessful: string = Translations_fi.SavingTaskTypeParameterWasSuccessful;
	static PasswordMustBeDefined: string = Translations_fi.PasswordMustBeDefined;
	static TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted: string = Translations_fi.TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted;
	static UserAccountConnected: string = Translations_fi.UserAccountConnected;
	static YouHaveUnapprovedChangesDoYouWantApproveChanges: string = Translations_fi.YouHaveUnapprovedChangesDoYouWantApproveChanges;
	static DeletingTaskTypeParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingTaskTypeParameterWasNotSuccessfulErrorParameter;
	static EventReceiverIsPartnerCompany: string = Translations_fi.EventReceiverIsPartnerCompany;
	static ProcessingTimeDays: string = Translations_fi.ProcessingTimeDays;
	static ClosedPlural: string = Translations_fi.ClosedPlural;
	static AdditionalPermissionBEventsDialog: string = Translations_fi.AdditionalPermissionBEventsDialog;
	static PublishVoucherInOutReportToFileSystem: string = Translations_fi.PublishVoucherInOutReportToFileSystem;
	static AddInstantMessageUsersParameterWasSuccessful: string = Translations_fi.AddInstantMessageUsersParameterWasSuccessful;
	static AdminMustBeDefinedForSectionParameter: string = Translations_fi.AdminMustBeDefinedForSectionParameter;
	static SavingMessageParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingMessageParameterWasNotSuccessfulConcurrencyError;
	static RecordUserActiveStateChangePermissionDeniedInUnitParameter: string = Translations_fi.RecordUserActiveStateChangePermissionDeniedInUnitParameter;
	static DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter;
	static Year: string = Translations_fi.Year;
	static CourseCredits: string = Translations_fi.CourseCredits;
	static SelectUserGroup: string = Translations_fi.SelectUserGroup;
	static DeletingDirectoryItemFolderParameterWasSuccessful: string = Translations_fi.DeletingDirectoryItemFolderParameterWasSuccessful;
	static SelectTaskType: string = Translations_fi.SelectTaskType;
	static DocumentTemplateCodeMustBeDefined: string = Translations_fi.DocumentTemplateCodeMustBeDefined;
	static ServiceFeePercent: string = Translations_fi.ServiceFeePercent;
	static SearchFurtherEducations: string = Translations_fi.SearchFurtherEducations;
	static PermissionDeniedForOperation: string = Translations_fi.PermissionDeniedForOperation;
	static DeletingRecordQualificationParameterWasSuccessful: string = Translations_fi.DeletingRecordQualificationParameterWasSuccessful;
	static Preview: string = Translations_fi.Preview;
	static UpdatingDatabase: string = Translations_fi.UpdatingDatabase;
	static Restore: string = Translations_fi.Restore;
	static SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError;
	static UserName: string = Translations_fi.UserName;
	static TaskTypeNameMustBeDefined: string = Translations_fi.TaskTypeNameMustBeDefined;
	static SavingEventTypeCParameterWasSuccessful: string = Translations_fi.SavingEventTypeCParameterWasSuccessful;
	static RequestValidationErrorOnFieldParameterWithValueParameter: string = Translations_fi.RequestValidationErrorOnFieldParameterWithValueParameter;
	static WhistleBlowMessageHasNotBeenSentCloseConfirmation: string = Translations_fi.WhistleBlowMessageHasNotBeenSentCloseConfirmation;
	static SelectCustomer: string = Translations_fi.SelectCustomer;
	static Payer: string = Translations_fi.Payer;
	static EventTypeCParameterNotFound: string = Translations_fi.EventTypeCParameterNotFound;
	static ExternalSystemUserTokenParameterIsAlreadyInUse: string = Translations_fi.ExternalSystemUserTokenParameterIsAlreadyInUse;
	static CreatingFileParameterWasNotSuccessfulErrorParameter: string = Translations_fi.CreatingFileParameterWasNotSuccessfulErrorParameter;
	static AccessRightWriteInstantMessages: string = Translations_fi.AccessRightWriteInstantMessages;
	static Description: string = Translations_fi.Description;
	static DocumentPublished: string = Translations_fi.DocumentPublished;
	static AddUnit: string = Translations_fi.AddUnit;
	static EditableDocumentVersionMustBeDefined: string = Translations_fi.EditableDocumentVersionMustBeDefined;
	static AttachmentIsEmpty: string = Translations_fi.AttachmentIsEmpty;
	static FolderNameIsTooLong: string = Translations_fi.FolderNameIsTooLong;
	static Time: string = Translations_fi.Time;
	static CopyPermissionToAllSameLevelUnits: string = Translations_fi.CopyPermissionToAllSameLevelUnits;
	static Payee: string = Translations_fi.Payee;
	static SelectEventTypeB: string = Translations_fi.SelectEventTypeB;
	static SelectCompanyGroupOrCompany: string = Translations_fi.SelectCompanyGroupOrCompany;
	static Message: string = Translations_fi.Message;
	static TaskCreated: string = Translations_fi.TaskCreated;
	static EmploymentEndDateCannotPrecedeEmploymentStartDate: string = Translations_fi.EmploymentEndDateCannotPrecedeEmploymentStartDate;
	static DoYouReallyWantToUnlockEditableDocument: string = Translations_fi.DoYouReallyWantToUnlockEditableDocument;
	static NoImagesToShow: string = Translations_fi.NoImagesToShow;
	static WhistleBlowGuide: string = Translations_fi.WhistleBlowGuide;
	static TaskModuleProperties: string = Translations_fi.TaskModuleProperties;
	static TrashBin: string = Translations_fi.TrashBin;
	static TimeSpanTwoYears: string = Translations_fi.TimeSpanTwoYears;
	static ExternalsSystemParameterNotFound: string = Translations_fi.ExternalsSystemParameterNotFound;
	static SavingJobParameterWasSuccessful: string = Translations_fi.SavingJobParameterWasSuccessful;
	static TypeMustBeDefined: string = Translations_fi.TypeMustBeDefined;
	static TransferredPropertyType: string = Translations_fi.TransferredPropertyType;
	static SaveUserFurtherEducationConfirmation: string = Translations_fi.SaveUserFurtherEducationConfirmation;
	static AddUserGroupsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddUserGroupsParameterWasNotSuccessfulErrorParameter;
	static EmploymentStartDate: string = Translations_fi.EmploymentStartDate;
	static RemoveSharedDocumentTemplate: string = Translations_fi.RemoveSharedDocumentTemplate;
	static DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords: string = Translations_fi.DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords;
	static SavingEventDefaultHandlerParameterWasSuccessful: string = Translations_fi.SavingEventDefaultHandlerParameterWasSuccessful;
	static NewInstantMessageChannel: string = Translations_fi.NewInstantMessageChannel;
	static WhistleBlowReportHasBeenSavedTopText: string = Translations_fi.WhistleBlowReportHasBeenSavedTopText;
	static Days: string = Translations_fi.Days;
	static DeletingInstantMessageUsersParameterWasSuccessful: string = Translations_fi.DeletingInstantMessageUsersParameterWasSuccessful;
	static CopyDocumentsFromCompany: string = Translations_fi.CopyDocumentsFromCompany;
	static RemoveUser: string = Translations_fi.RemoveUser;
	static SavingSettingsWasSuccessful: string = Translations_fi.SavingSettingsWasSuccessful;
	static EventHandlerParameterNotFound: string = Translations_fi.EventHandlerParameterNotFound;
	static ApplicationTempPathParameterNotFound: string = Translations_fi.ApplicationTempPathParameterNotFound;
	static DeletingEventTypeParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingEventTypeParameterWasNotSuccessfulConcurrencyError;
	static MessageTypeParameterNotFound: string = Translations_fi.MessageTypeParameterNotFound;
	static SSRSRefreshStarted: string = Translations_fi.SSRSRefreshStarted;
	static ValueAddedTaxPercent: string = Translations_fi.ValueAddedTaxPercent;
	static MaximumPasswordLengthCanParameterCharacters: string = Translations_fi.MaximumPasswordLengthCanParameterCharacters;
	static DeletingPartnersParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingPartnersParameterWasNotSuccessfulErrorParameter;
	static DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter;
	static FileParameterNotFound: string = Translations_fi.FileParameterNotFound;
	static InstantMessageChannelOneUser: string = Translations_fi.InstantMessageChannelOneUser;
	static DeletingWhistleBlowChannelAdminParameterWasSuccessful: string = Translations_fi.DeletingWhistleBlowChannelAdminParameterWasSuccessful;
	static TaskPrioritiesMustBeDefinedBeforeAddingTasks: string = Translations_fi.TaskPrioritiesMustBeDefinedBeforeAddingTasks;
	static LogModuleProperties: string = Translations_fi.LogModuleProperties;
	static EditableDocumentPublishUnapprovedConfirmation: string = Translations_fi.EditableDocumentPublishUnapprovedConfirmation;
	static SelectEventHandler: string = Translations_fi.SelectEventHandler;
	static TargetCompany: string = Translations_fi.TargetCompany;
	static ChangeSystemLanguageToSwedish: string = Translations_fi.ChangeSystemLanguageToSwedish;
	static Group: string = Translations_fi.Group;
	static MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB: string = Translations_fi.MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB;
	static RecordEmailNotificationType: string = Translations_fi.RecordEmailNotificationType;
	static SelectArchivingMethod: string = Translations_fi.SelectArchivingMethod;
	static InstantMessageChannel: string = Translations_fi.InstantMessageChannel;
	static DeletingAttachmentParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingAttachmentParameterWasNotSuccessfulErrorParameter;
	static RemoveRecordUserAndUser: string = Translations_fi.RemoveRecordUserAndUser;
	static Company: string = Translations_fi.Company;
	static AddEventMessage: string = Translations_fi.AddEventMessage;
	static SavingTaskPriorityParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingTaskPriorityParameterWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter: string = Translations_fi.DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter;
	static FirstNameMustBeDefined: string = Translations_fi.FirstNameMustBeDefined;
	static EditWhistleBlowChannel: string = Translations_fi.EditWhistleBlowChannel;
	static LogLine: string = Translations_fi.LogLine;
	static RenamingFileParameterWasNotSuccessfulErrorParameter: string = Translations_fi.RenamingFileParameterWasNotSuccessfulErrorParameter;
	static AddPartners: string = Translations_fi.AddPartners;
	static SenderOrReceiver: string = Translations_fi.SenderOrReceiver;
	static AddFamiliarization: string = Translations_fi.AddFamiliarization;
	static PreviewPanel: string = Translations_fi.PreviewPanel;
	static DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError;
	static ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers: string = Translations_fi.ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers;
	static EditFamiliarization: string = Translations_fi.EditFamiliarization;
	static RemoveTaskHandler: string = Translations_fi.RemoveTaskHandler;
	static BirthDate: string = Translations_fi.BirthDate;
	static No: string = Translations_fi.No;
	static AddTaskPriority: string = Translations_fi.AddTaskPriority;
	static RecordUserExcelFurtherEducation: string = Translations_fi.RecordUserExcelFurtherEducation;
	static RemoveEventPartner: string = Translations_fi.RemoveEventPartner;
	static Documents: string = Translations_fi.Documents;
	static Comments: string = Translations_fi.Comments;
	static Go: string = Translations_fi.Go;
	static DeletingRecordUserParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordUserParameterWasNotSuccessfulConcurrencyError;
	static Sender: string = Translations_fi.Sender;
	static Close: string = Translations_fi.Close;
	static CurrentTopics: string = Translations_fi.CurrentTopics;
	static DeletingUserParameterWasSuccessful: string = Translations_fi.DeletingUserParameterWasSuccessful;
	static CopyingFileParameterWasNotSuccessfulErrorParameter: string = Translations_fi.CopyingFileParameterWasNotSuccessfulErrorParameter;
	static CopyOrMoveFilesDocuments: string = Translations_fi.CopyOrMoveFilesDocuments;
	static RemoveFurtherEducation: string = Translations_fi.RemoveFurtherEducation;
	static PrintGenerationFailed: string = Translations_fi.PrintGenerationFailed;
	static SavingTaskTypeParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingTaskTypeParameterWasNotSuccessfulConcurrencyError;
	static RemoveUnit: string = Translations_fi.RemoveUnit;
	static SortOrder: string = Translations_fi.SortOrder;
	static FamiliarizationStartDateStart: string = Translations_fi.FamiliarizationStartDateStart;
	static RecordUserWithSameNameAlreadyExists: string = Translations_fi.RecordUserWithSameNameAlreadyExists;
	static RemoveAttachment: string = Translations_fi.RemoveAttachment;
	static JobTypePushNotification: string = Translations_fi.JobTypePushNotification;
	static AddComment: string = Translations_fi.AddComment;
	static EventModuleProperties: string = Translations_fi.EventModuleProperties;
	static AccessRightReadWhistleBlow: string = Translations_fi.AccessRightReadWhistleBlow;
	static DoYouReallyWantToRemoveSelectedTaskPriorities: string = Translations_fi.DoYouReallyWantToRemoveSelectedTaskPriorities;
	static NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded: string = Translations_fi.NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded;
	static SelectImageFile: string = Translations_fi.SelectImageFile;
	static AddCompanyGroupsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddCompanyGroupsParameterWasNotSuccessfulErrorParameter;
	static DeletingInstantMessageChannelWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingInstantMessageChannelWasNotSuccessfulErrorParameter;
	static Created: string = Translations_fi.Created;
	static WhistleBlowFrontPage: string = Translations_fi.WhistleBlowFrontPage;
	static Published: string = Translations_fi.Published;
	static CompanyGroups: string = Translations_fi.CompanyGroups;
	static VersionParameterPublishedAsPdfFile: string = Translations_fi.VersionParameterPublishedAsPdfFile;
	static ModifiedDescription: string = Translations_fi.ModifiedDescription;
	static TheUserNameOrPasswordIsIncorrect: string = Translations_fi.TheUserNameOrPasswordIsIncorrect;
	static DeletingEventUrgencyClassParameterWasSuccessful: string = Translations_fi.DeletingEventUrgencyClassParameterWasSuccessful;
	static DeletingEventParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingEventParameterWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToRemoveEventParameter: string = Translations_fi.DoYouReallyWantToRemoveEventParameter;
	static MyReports: string = Translations_fi.MyReports;
	static IndefinitelyNoLocalization: string = Translations_fi.IndefinitelyNoLocalization;
	static ExternalSystems: string = Translations_fi.ExternalSystems;
	static SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError;
	static Wage: string = Translations_fi.Wage;
	static Publisher: string = Translations_fi.Publisher;
	static Previous: string = Translations_fi.Previous;
	static DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError;
	static EmailSent: string = Translations_fi.EmailSent;
	static WhistleBlowInformantMustBeNotifiedLatestAtParameter: string = Translations_fi.WhistleBlowInformantMustBeNotifiedLatestAtParameter;
	static RemoveTransferredProperty: string = Translations_fi.RemoveTransferredProperty;
	static SavingMessageSendPushParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingMessageSendPushParameterWasNotSuccessfulErrorParameter;
	static Month: string = Translations_fi.Month;
	static DoYouReallyWantToSaveData: string = Translations_fi.DoYouReallyWantToSaveData;
	static UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError: string = Translations_fi.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError;
	static NoPublishedMessages: string = Translations_fi.NoPublishedMessages;
	static Subject: string = Translations_fi.Subject;
	static WhistleBlowUrlInfo: string = Translations_fi.WhistleBlowUrlInfo;
	static RemovePartner: string = Translations_fi.RemovePartner;
	static FileParameterAlreadyExistsInFileSystem: string = Translations_fi.FileParameterAlreadyExistsInFileSystem;
	static SavingUserParameterWasSuccessful: string = Translations_fi.SavingUserParameterWasSuccessful;
	static LogDocumentPreview: string = Translations_fi.LogDocumentPreview;
	static WhistleBlowActiveReports: string = Translations_fi.WhistleBlowActiveReports;
	static WhistleBlowApplicationName: string = Translations_fi.WhistleBlowApplicationName;
	static DeletingTaskStateParameterWasSuccessful: string = Translations_fi.DeletingTaskStateParameterWasSuccessful;
	static FileFilterNameMustBeDefined: string = Translations_fi.FileFilterNameMustBeDefined;
	static EventHandlers: string = Translations_fi.EventHandlers;
	static WhistleBlowResolutionUnspecified: string = Translations_fi.WhistleBlowResolutionUnspecified;
	static DoYouReallyWantToRemoveRecordUserParameter: string = Translations_fi.DoYouReallyWantToRemoveRecordUserParameter;
	static RecordFamiliarizationParameterNotFound: string = Translations_fi.RecordFamiliarizationParameterNotFound;
	static TaskStateCodeMustBeDefined: string = Translations_fi.TaskStateCodeMustBeDefined;
	static SaveInstantMessageChannel: string = Translations_fi.SaveInstantMessageChannel;
	static PasswordHasBeenCopiedToClipboard: string = Translations_fi.PasswordHasBeenCopiedToClipboard;
	static GeneralInfo: string = Translations_fi.GeneralInfo;
	static EventIsNotApprovedByUser: string = Translations_fi.EventIsNotApprovedByUser;
	static EditFurtherEducation: string = Translations_fi.EditFurtherEducation;
	static SavingTaskParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingTaskParameterWasNotSuccessfulErrorParameter;
	static SavingDirectoryItemFolderParameterWasSuccessful: string = Translations_fi.SavingDirectoryItemFolderParameterWasSuccessful;
	static InstantMessagesMenuTitle: string = Translations_fi.InstantMessagesMenuTitle;
	static FurtherEducationParameterNotFound: string = Translations_fi.FurtherEducationParameterNotFound;
	static DoYouReallyWantToRemoveUserParameter: string = Translations_fi.DoYouReallyWantToRemoveUserParameter;
	static NoFurtherEducationsToShow: string = Translations_fi.NoFurtherEducationsToShow;
	static EditLogisticVoucherTransportCompany: string = Translations_fi.EditLogisticVoucherTransportCompany;
	static OperationNotAllowedForRootFolderParameter: string = Translations_fi.OperationNotAllowedForRootFolderParameter;
	static SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError;
	static SavingTaskStateParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingTaskStateParameterWasNotSuccessfulErrorParameter;
	static DeletingRecordFamiliarizationParameterWasSuccessful: string = Translations_fi.DeletingRecordFamiliarizationParameterWasSuccessful;
	static AddingAttachmentsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddingAttachmentsParameterWasNotSuccessfulErrorParameter;
	static NotSet: string = Translations_fi.NotSet;
	static WhistleBlowResolutionRejected: string = Translations_fi.WhistleBlowResolutionRejected;
	static DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError;
	static ArchivingRecordUserFamiliarizationsWasSuccessful: string = Translations_fi.ArchivingRecordUserFamiliarizationsWasSuccessful;
	static AdditionalSettings: string = Translations_fi.AdditionalSettings;
	static PublishVersion: string = Translations_fi.PublishVersion;
	static PostalCode: string = Translations_fi.PostalCode;
	static DeletingUserParametersWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingUserParametersWasNotSuccessfulErrorParameter;
	static ResolutionDescription: string = Translations_fi.ResolutionDescription;
	static DeletingEventUrgencyClassesParameterWasSuccessful: string = Translations_fi.DeletingEventUrgencyClassesParameterWasSuccessful;
	static NoNotificationsToSend: string = Translations_fi.NoNotificationsToSend;
	static SuccessAudit: string = Translations_fi.SuccessAudit;
	static DeletingPartnerParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingPartnerParameterWasNotSuccessfulConcurrencyError;
	static SavingOptionParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingOptionParameterWasNotSuccessfulErrorParameter;
	static CompanyNameMustBeDefined: string = Translations_fi.CompanyNameMustBeDefined;
	static DoYouReallyWantToRemoveRecordFurtherEducationParameter: string = Translations_fi.DoYouReallyWantToRemoveRecordFurtherEducationParameter;
	static NewPasswordMustBeDefined: string = Translations_fi.NewPasswordMustBeDefined;
	static InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted: string = Translations_fi.InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted;
	static AddEventPartner: string = Translations_fi.AddEventPartner;
	static AddPartnerParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddPartnerParameterWasNotSuccessfulErrorParameter;
	static GeneralAttachments: string = Translations_fi.GeneralAttachments;
	static Selected: string = Translations_fi.Selected;
	static DescriptionMustBeDefined: string = Translations_fi.DescriptionMustBeDefined;
	static CompanyGroup: string = Translations_fi.CompanyGroup;
	static MoveTaskToTrashBin: string = Translations_fi.MoveTaskToTrashBin;
	static SavingApplicationPermissionsWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingApplicationPermissionsWasNotSuccessfulConcurrencyError;
	static List: string = Translations_fi.List;
	static DeletingTaskTypeParameterWasSuccessful: string = Translations_fi.DeletingTaskTypeParameterWasSuccessful;
	static NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded: string = Translations_fi.NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded;
	static Header: string = Translations_fi.Header;
	static EventHandlersExcelTitle: string = Translations_fi.EventHandlersExcelTitle;
	static Start: string = Translations_fi.Start;
	static AddingAddRecordUserQualificationsWasSuccessful: string = Translations_fi.AddingAddRecordUserQualificationsWasSuccessful;
	static CompanyModuleProperties: string = Translations_fi.CompanyModuleProperties;
	static DocumentRootPathIsNotEmpty: string = Translations_fi.DocumentRootPathIsNotEmpty;
	static EventTypeCs: string = Translations_fi.EventTypeCs;
	static Remainder: string = Translations_fi.Remainder;
	static NoFilesToAdd: string = Translations_fi.NoFilesToAdd;
	static SavingRecordFurtherEducationParameterWasSuccessful: string = Translations_fi.SavingRecordFurtherEducationParameterWasSuccessful;
	static VoucherSavePath: string = Translations_fi.VoucherSavePath;
	static AddTransferredProperty: string = Translations_fi.AddTransferredProperty;
	static PreviousCalendarMonth: string = Translations_fi.PreviousCalendarMonth;
	static RemoveEventTypeC: string = Translations_fi.RemoveEventTypeC;
	static InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation: string = Translations_fi.InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation;
	static EventTypes: string = Translations_fi.EventTypes;
	static ParameterIsNotValidBankBICCode: string = Translations_fi.ParameterIsNotValidBankBICCode;
	static SharedFilter: string = Translations_fi.SharedFilter;
	static SavingEventTypesWasSuccessful: string = Translations_fi.SavingEventTypesWasSuccessful;
	static DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError;
	static AddingWhistleBlowHandlersWasSuccessful: string = Translations_fi.AddingWhistleBlowHandlersWasSuccessful;
	static AddNotifiedPerson: string = Translations_fi.AddNotifiedPerson;
	static SystemAdminMessage: string = Translations_fi.SystemAdminMessage;
	static SendingEmail: string = Translations_fi.SendingEmail;
	static EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError: string = Translations_fi.EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError;
	static PortalInUse: string = Translations_fi.PortalInUse;
	static SavingRecordBasicEducationParameterWasSuccessful: string = Translations_fi.SavingRecordBasicEducationParameterWasSuccessful;
	static EventSettingsParameterNotFound: string = Translations_fi.EventSettingsParameterNotFound;
	static DeletingPartnersParameterWasSuccessful: string = Translations_fi.DeletingPartnersParameterWasSuccessful;
	static EmployeeType: string = Translations_fi.EmployeeType;
	static CompanyTaskTypes: string = Translations_fi.CompanyTaskTypes;
	static DeletingUserParametersWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingUserParametersWasNotSuccessfulConcurrencyError;
	static RemoveUserGroup: string = Translations_fi.RemoveUserGroup;
	static HandlersMustBeDefined: string = Translations_fi.HandlersMustBeDefined;
	static DoYouReallyWantToRemoveRecordBasicEducationParameter: string = Translations_fi.DoYouReallyWantToRemoveRecordBasicEducationParameter;
	static AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter;
	static SelectEventReceiver: string = Translations_fi.SelectEventReceiver;
	static Modification: string = Translations_fi.Modification;
	static AddLogisticVoucherPaymentSetting: string = Translations_fi.AddLogisticVoucherPaymentSetting;
	static EditCompany: string = Translations_fi.EditCompany;
	static ChangingPasswordWasNotSuccessfulErrorParameter: string = Translations_fi.ChangingPasswordWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToUnlockEvent: string = Translations_fi.DoYouReallyWantToUnlockEvent;
	static UserModuleProperties: string = Translations_fi.UserModuleProperties;
	static NoReports: string = Translations_fi.NoReports;
	static SharedFiltersAndReports: string = Translations_fi.SharedFiltersAndReports;
	static AddTaskHandler: string = Translations_fi.AddTaskHandler;
	static WhistleBlowStateNew: string = Translations_fi.WhistleBlowStateNew;
	static SavingAttachmentsWasNotSuccessfulErrorParameter: string = Translations_fi.SavingAttachmentsWasNotSuccessfulErrorParameter;
	static UnkownErrorHasBeenOccured: string = Translations_fi.UnkownErrorHasBeenOccured;
	static SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter;
	static SaveComment: string = Translations_fi.SaveComment;
	static RemoveFilter: string = Translations_fi.RemoveFilter;
	static Drafts: string = Translations_fi.Drafts;
	static FolderTreePermissions: string = Translations_fi.FolderTreePermissions;
	static LockedToUser: string = Translations_fi.LockedToUser;
	static Custom: string = Translations_fi.Custom;
	static Collapse: string = Translations_fi.Collapse;
	static RestoringInstantMessageWasSuccessful: string = Translations_fi.RestoringInstantMessageWasSuccessful;
	static PublishVersionHistorySize: string = Translations_fi.PublishVersionHistorySize;
	static AddDocumentTemplate: string = Translations_fi.AddDocumentTemplate;
	static WhistleBlowDateAndTime: string = Translations_fi.WhistleBlowDateAndTime;
	static EmptyingTaskTrashBinWasSuccessful: string = Translations_fi.EmptyingTaskTrashBinWasSuccessful;
	static ShowOnlyUnitsThatAreInUse: string = Translations_fi.ShowOnlyUnitsThatAreInUse;
	static ModifyDateStartDate: string = Translations_fi.ModifyDateStartDate;
	static Missing: string = Translations_fi.Missing;
	static WorkTimeType: string = Translations_fi.WorkTimeType;
	static EditUserGroup: string = Translations_fi.EditUserGroup;
	static DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError;
	static Channels: string = Translations_fi.Channels;
	static MaximumTotalSizeOfAttachmentsIsParameterMB: string = Translations_fi.MaximumTotalSizeOfAttachmentsIsParameterMB;
	static EditDefaultEventHandlersAndNotifiedPersons: string = Translations_fi.EditDefaultEventHandlersAndNotifiedPersons;
	static CompanyGroupAll: string = Translations_fi.CompanyGroupAll;
	static SavingTaskDataRowWasNotSuccessfulErrorParameter: string = Translations_fi.SavingTaskDataRowWasNotSuccessfulErrorParameter;
	static DeletingEventTypeCParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingEventTypeCParameterWasNotSuccessfulErrorParameter;
	static EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted: string = Translations_fi.EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted;
	static ClearSubFolderPermissions: string = Translations_fi.ClearSubFolderPermissions;
	static CurrentWorkPlaceNotAdded: string = Translations_fi.CurrentWorkPlaceNotAdded;
	static NoInstantMessageChannelsToShow: string = Translations_fi.NoInstantMessageChannelsToShow;
	static EventTypeC: string = Translations_fi.EventTypeC;
	static ChangePassword: string = Translations_fi.ChangePassword;
	static FolderNameMustBeDefined: string = Translations_fi.FolderNameMustBeDefined;
	static Severity: string = Translations_fi.Severity;
	static TaskRemainderCheckingAndNotificationFinishedInParameterSeconds: string = Translations_fi.TaskRemainderCheckingAndNotificationFinishedInParameterSeconds;
	static SavingUserParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingUserParameterWasNotSuccessfulConcurrencyError;
	static WhistleBlowParameterNotFound: string = Translations_fi.WhistleBlowParameterNotFound;
	static Observer: string = Translations_fi.Observer;
	static DeletingPartnerParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingPartnerParameterWasNotSuccessfulErrorParameter;
	static SavingEventTypeCParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventTypeCParameterWasNotSuccessfulConcurrencyError;
	static DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter;
	static AddingEventHandlersWasSuccessful: string = Translations_fi.AddingEventHandlersWasSuccessful;
	static Ascending: string = Translations_fi.Ascending;
	static UnlockingTaskDataRowWasNotSuccessful: string = Translations_fi.UnlockingTaskDataRowWasNotSuccessful;
	static Save: string = Translations_fi.Save;
	static RecordFurtherEducationCodeMustBeDefined: string = Translations_fi.RecordFurtherEducationCodeMustBeDefined;
	static PaymentTermInDays: string = Translations_fi.PaymentTermInDays;
	static DefaultTaskStates: string = Translations_fi.DefaultTaskStates;
	static SavingMessageParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingMessageParameterWasNotSuccessfulErrorParameter;
	static AddUsersParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.AddUsersParameterWasNotSuccessfulConcurrencyError;
	static ExternalSystemDatabaseInterfaceError: string = Translations_fi.ExternalSystemDatabaseInterfaceError;
	static RemoveUserAndKeepRecordUser: string = Translations_fi.RemoveUserAndKeepRecordUser;
	static AllLogisticVoucherCustomers: string = Translations_fi.AllLogisticVoucherCustomers;
	static Next: string = Translations_fi.Next;
	static AddTransferredProperties: string = Translations_fi.AddTransferredProperties;
	static AddingTaskTypesParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddingTaskTypesParameterWasNotSuccessfulErrorParameter;
	static EditableDocumentSaveBeforeApproveConfirmation: string = Translations_fi.EditableDocumentSaveBeforeApproveConfirmation;
	static SelectEventType: string = Translations_fi.SelectEventType;
	static Actions: string = Translations_fi.Actions;
	static SelectTaskPriority: string = Translations_fi.SelectTaskPriority;
	static EventClosed: string = Translations_fi.EventClosed;
	static Remainders: string = Translations_fi.Remainders;
	static UsedFileStorageQuota: string = Translations_fi.UsedFileStorageQuota;
	static Yes: string = Translations_fi.Yes;
	static AddLogisticVoucherCustomerPaymentSetting: string = Translations_fi.AddLogisticVoucherCustomerPaymentSetting;
	static Interval: string = Translations_fi.Interval;
	static SelectUrgencyClass: string = Translations_fi.SelectUrgencyClass;
	static FileChangesCheckingAndEmailingStarted: string = Translations_fi.FileChangesCheckingAndEmailingStarted;
	static Receiver: string = Translations_fi.Receiver;
	static SavingEventMessageWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventMessageWasNotSuccessfulConcurrencyError;
	static InTesting: string = Translations_fi.InTesting;
	static Qualification: string = Translations_fi.Qualification;
	static AttachmentParameterDownloaded: string = Translations_fi.AttachmentParameterDownloaded;
	static RemoveFromFavorites: string = Translations_fi.RemoveFromFavorites;
	static SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError;
	static DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError;
	static EventParameterUnitChangedFromParameterToParameter: string = Translations_fi.EventParameterUnitChangedFromParameterToParameter;
	static VoucherPrefix: string = Translations_fi.VoucherPrefix;
	static Form: string = Translations_fi.Form;
	static SavingTaskParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingTaskParameterWasNotSuccessfulConcurrencyError;
	static DeletingRecordFamiliarizationsParameterWasSuccessful: string = Translations_fi.DeletingRecordFamiliarizationsParameterWasSuccessful;
	static UserCodeMustBeDefined: string = Translations_fi.UserCodeMustBeDefined;
	static AddUserGroupsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.AddUserGroupsParameterWasNotSuccessfulConcurrencyError;
	static DeletingWhistleBlowParameterWasSuccessful: string = Translations_fi.DeletingWhistleBlowParameterWasSuccessful;
	static BusinessIdentityCode: string = Translations_fi.BusinessIdentityCode;
	static RemoveCompanyTaskType: string = Translations_fi.RemoveCompanyTaskType;
	static RemoveLogisticVoucherPaymentSetting: string = Translations_fi.RemoveLogisticVoucherPaymentSetting;
	static SearchDocuments: string = Translations_fi.SearchDocuments;
	static RecordParameterLockingFailed: string = Translations_fi.RecordParameterLockingFailed;
	static SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter;
	static FieldParameterContainsInvalidCodeCharacters: string = Translations_fi.FieldParameterContainsInvalidCodeCharacters;
	static CustomerNumber: string = Translations_fi.CustomerNumber;
	static DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError;
	static RecordUserExcelFamiliarization: string = Translations_fi.RecordUserExcelFamiliarization;
	static PageBreakBetweenGroups: string = Translations_fi.PageBreakBetweenGroups;
	static AddTask: string = Translations_fi.AddTask;
	static Filter: string = Translations_fi.Filter;
	static EventTypeBParameterNotFound: string = Translations_fi.EventTypeBParameterNotFound;
	static DoYouWantToAddThisApplicationToHomeScreen: string = Translations_fi.DoYouWantToAddThisApplicationToHomeScreen;
	static WhistleBlowAnonymousReportInfo: string = Translations_fi.WhistleBlowAnonymousReportInfo;
	static LanguageNonLocalFinnish: string = Translations_fi.LanguageNonLocalFinnish;
	static DocumentZipFileName: string = Translations_fi.DocumentZipFileName;
	static JobLineParameterNotFound: string = Translations_fi.JobLineParameterNotFound;
	static DocumentEndDate: string = Translations_fi.DocumentEndDate;
	static SavingInstantMessageWasNotSuccessfulErrorParameter: string = Translations_fi.SavingInstantMessageWasNotSuccessfulErrorParameter;
	static DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError;
	static AddTransferredPropertyToMultipleUserRecords: string = Translations_fi.AddTransferredPropertyToMultipleUserRecords;
	static DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError;
	static AddChildEventType: string = Translations_fi.AddChildEventType;
	static QualificationIsInvalid: string = Translations_fi.QualificationIsInvalid;
	static AddUserGroups: string = Translations_fi.AddUserGroups;
	static DeletingEventTypeBParameterWasSuccessful: string = Translations_fi.DeletingEventTypeBParameterWasSuccessful;
	static CompanyLogo: string = Translations_fi.CompanyLogo;
	static KeywordParameterNotFound: string = Translations_fi.KeywordParameterNotFound;
	static PrintRecordUser: string = Translations_fi.PrintRecordUser;
	static SavingFilterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingFilterWasNotSuccessfulConcurrencyError;
	static EventPartners: string = Translations_fi.EventPartners;
	static DeletingAttachmentsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingAttachmentsParameterWasNotSuccessfulErrorParameter;
	static FolderParameterNotFound: string = Translations_fi.FolderParameterNotFound;
	static Salary: string = Translations_fi.Salary;
	static ClickToCancelAutomaticLogout: string = Translations_fi.ClickToCancelAutomaticLogout;
	static TaskStates: string = Translations_fi.TaskStates;
	static DefaultLanguage: string = Translations_fi.DefaultLanguage;
	static Last180Days: string = Translations_fi.Last180Days;
	static WhistleBlowSourceEmail: string = Translations_fi.WhistleBlowSourceEmail;
	static DatabaseCheckingStarted: string = Translations_fi.DatabaseCheckingStarted;
	static UnitChangedFromParameterToParameter: string = Translations_fi.UnitChangedFromParameterToParameter;
	static BrowserInfo: string = Translations_fi.BrowserInfo;
	static ApplicationName: string = Translations_fi.ApplicationName;
	static SSRSRefreshFinishedInParameterSeconds: string = Translations_fi.SSRSRefreshFinishedInParameterSeconds;
	static EventParameterNotFound: string = Translations_fi.EventParameterNotFound;
	static InstantMessageChannelAdministrator: string = Translations_fi.InstantMessageChannelAdministrator;
	static SavingEventSettingsWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventSettingsWasNotSuccessfulConcurrencyError;
	static DeletingMessageTypeParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingMessageTypeParameterWasNotSuccessfulErrorParameter;
	static ExternalSystemCompanyCodeClaimNotFound: string = Translations_fi.ExternalSystemCompanyCodeClaimNotFound;
	static LockedTaskParameterCannotBeSaved: string = Translations_fi.LockedTaskParameterCannotBeSaved;
	static WhistleBlowReport: string = Translations_fi.WhistleBlowReport;
	static IncludeArchivedRecordUsers: string = Translations_fi.IncludeArchivedRecordUsers;
	static SavingEventTypeBParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEventTypeBParameterWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToUnlockTaskDataRow: string = Translations_fi.DoYouReallyWantToUnlockTaskDataRow;
	static SavePrint: string = Translations_fi.SavePrint;
	static SendReadReceipt: string = Translations_fi.SendReadReceipt;
	static ValidityDate: string = Translations_fi.ValidityDate;
	static FieldParameterContainsInvalidCompanyCodeCharacters: string = Translations_fi.FieldParameterContainsInvalidCompanyCodeCharacters;
	static RecordUserExcelSalary: string = Translations_fi.RecordUserExcelSalary;
	static WhistleBlowCategory: string = Translations_fi.WhistleBlowCategory;
	static AccessRightWrite: string = Translations_fi.AccessRightWrite;
	static TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted: string = Translations_fi.TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted;
	static DocumentApproval: string = Translations_fi.DocumentApproval;
	static DeletingRecordQualificationsParameterWasSuccessful: string = Translations_fi.DeletingRecordQualificationsParameterWasSuccessful;
	static PermissionToIdentificateEvents: string = Translations_fi.PermissionToIdentificateEvents;
	static SendingPusNotificationsWasNotSuccessfulErrorParameter: string = Translations_fi.SendingPusNotificationsWasNotSuccessfulErrorParameter;
	static DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError;
	static Years: string = Translations_fi.Years;
	static EditMessage: string = Translations_fi.EditMessage;
	static DeletingRecordUserParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordUserParameterWasNotSuccessfulErrorParameter;
	static SaveTaskConfirmation: string = Translations_fi.SaveTaskConfirmation;
	static EventSenderIsPartnerCompany: string = Translations_fi.EventSenderIsPartnerCompany;
	static UserPasswordMustBeValid: string = Translations_fi.UserPasswordMustBeValid;
	static PrintEvent: string = Translations_fi.PrintEvent;
	static PayeeUsersCanLogInToTheSystem: string = Translations_fi.PayeeUsersCanLogInToTheSystem;
	static MessageMustBeDefined: string = Translations_fi.MessageMustBeDefined;
	static ChangedUrgencyClass: string = Translations_fi.ChangedUrgencyClass;
	static InviteInstantMessageChannelUser: string = Translations_fi.InviteInstantMessageChannelUser;
	static UserEventsNotApproved: string = Translations_fi.UserEventsNotApproved;
	static NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded: string = Translations_fi.NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded;
	static SendReadReceiptInfo: string = Translations_fi.SendReadReceiptInfo;
	static DefaultEventUrgencyClasses: string = Translations_fi.DefaultEventUrgencyClasses;
	static WhistleBlowInformantDateAndTime: string = Translations_fi.WhistleBlowInformantDateAndTime;
	static SavingUserParameterParameterWasSuccessful: string = Translations_fi.SavingUserParameterParameterWasSuccessful;
	static PreviewIsNotSupportedForThisFileType: string = Translations_fi.PreviewIsNotSupportedForThisFileType;
	static Last7Days: string = Translations_fi.Last7Days;
	static Picture: string = Translations_fi.Picture;
	static AddPermission: string = Translations_fi.AddPermission;
	static EmailMessage: string = Translations_fi.EmailMessage;
	static LastName: string = Translations_fi.LastName;
	static DeletingTaskStateParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingTaskStateParameterWasNotSuccessfulErrorParameter;
	static SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter;
	static LockingEditableDocumentParameterWasNotSuccessful: string = Translations_fi.LockingEditableDocumentParameterWasNotSuccessful;
	static DeletingAttachmentParameterWasSuccessful: string = Translations_fi.DeletingAttachmentParameterWasSuccessful;
	static ValidPlural: string = Translations_fi.ValidPlural;
	static RootCause: string = Translations_fi.RootCause;
	static Partner: string = Translations_fi.Partner;
	static PermissionsForSections: string = Translations_fi.PermissionsForSections;
	static ArchiveWhistleBlows: string = Translations_fi.ArchiveWhistleBlows;
	static Version: string = Translations_fi.Version;
	static ReportsStandard: string = Translations_fi.ReportsStandard;
	static ReadReceiptGiven: string = Translations_fi.ReadReceiptGiven;
	static SavingMessageTypeParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingMessageTypeParameterWasNotSuccessfulErrorParameter;
	static RemoveUserFromUserGroup: string = Translations_fi.RemoveUserFromUserGroup;
	static DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents: string = Translations_fi.DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents;
	static ClearSubUnitPermissions: string = Translations_fi.ClearSubUnitPermissions;
	static AType: string = Translations_fi.AType;
	static RemoveMessageType: string = Translations_fi.RemoveMessageType;
	static DocumentNameIsIllegal: string = Translations_fi.DocumentNameIsIllegal;
	static AddCompanyGroupParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.AddCompanyGroupParameterWasNotSuccessfulConcurrencyError;
	static EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted: string = Translations_fi.EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted;
	static UnlockingTaskParameterWasNotSuccessful: string = Translations_fi.UnlockingTaskParameterWasNotSuccessful;
	static Row: string = Translations_fi.Row;
	static SaveInstantMessageChannelConfirmation: string = Translations_fi.SaveInstantMessageChannelConfirmation;
	static NotSelected: string = Translations_fi.NotSelected;
	static Private: string = Translations_fi.Private;
	static SavingUserParametersWasNotSuccessfulErrorParameter: string = Translations_fi.SavingUserParametersWasNotSuccessfulErrorParameter;
	static Identification: string = Translations_fi.Identification;
	static InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter: string = Translations_fi.InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter;
	static TaskPriorityParameterNotFound: string = Translations_fi.TaskPriorityParameterNotFound;
	static Uploaded: string = Translations_fi.Uploaded;
	static AutomaticLogoutDone: string = Translations_fi.AutomaticLogoutDone;
	static RemoveTaskType: string = Translations_fi.RemoveTaskType;
	static EventTypeBTitle: string = Translations_fi.EventTypeBTitle;
	static SelectUnit: string = Translations_fi.SelectUnit;
	static DeletingUsersParameterWasSuccessful: string = Translations_fi.DeletingUsersParameterWasSuccessful;
	static AddQualification: string = Translations_fi.AddQualification;
	static DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter;
	static DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders: string = Translations_fi.DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders;
	static UnitIsRelatedToEventPartner: string = Translations_fi.UnitIsRelatedToEventPartner;
	static NoFilters: string = Translations_fi.NoFilters;
	static EditDefaultFurtherEducation: string = Translations_fi.EditDefaultFurtherEducation;
	static UserGroups: string = Translations_fi.UserGroups;
	static RecordUserLogEntryTypeCreate: string = Translations_fi.RecordUserLogEntryTypeCreate;
	static ShortName: string = Translations_fi.ShortName;
	static Validity: string = Translations_fi.Validity;
	static DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError;
	static DeletingPartnerParameterWasSuccessful: string = Translations_fi.DeletingPartnerParameterWasSuccessful;
	static OtherCompanies: string = Translations_fi.OtherCompanies;
	static CourseCredit: string = Translations_fi.CourseCredit;
	static AddRemainder: string = Translations_fi.AddRemainder;
	static FullNameClaimNotFound: string = Translations_fi.FullNameClaimNotFound;
	static UserGroupParameterNotFound: string = Translations_fi.UserGroupParameterNotFound;
	static DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter;
	static PanelSize1: string = Translations_fi.PanelSize1;
	static DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError;
	static Messages: string = Translations_fi.Messages;
	static FileParameterIsNotImage: string = Translations_fi.FileParameterIsNotImage;
	static DocumentNameIsTooLong: string = Translations_fi.DocumentNameIsTooLong;
	static EmailNotificationOfChangedPublishStatus: string = Translations_fi.EmailNotificationOfChangedPublishStatus;
	static AllPartners: string = Translations_fi.AllPartners;
	static SharedReportCompany: string = Translations_fi.SharedReportCompany;
	static ScopeUnit: string = Translations_fi.ScopeUnit;
	static PrintParameterNotFound: string = Translations_fi.PrintParameterNotFound;
	static DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError;
	static DeletingDefaultEventHandlerParameterWasSuccessful: string = Translations_fi.DeletingDefaultEventHandlerParameterWasSuccessful;
	static TimeMustBeDefined: string = Translations_fi.TimeMustBeDefined;
	static EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted: string = Translations_fi.EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted;
	static RecordUserExcelTabName: string = Translations_fi.RecordUserExcelTabName;
	static DeletingTaskStatesParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingTaskStatesParameterWasNotSuccessfulErrorParameter;
	static CompanyParameterNotFound: string = Translations_fi.CompanyParameterNotFound;
	static SearchEndDate: string = Translations_fi.SearchEndDate;
	static WhistleBlowStatisticsInProgressRecords: string = Translations_fi.WhistleBlowStatisticsInProgressRecords;
	static RemoveEventUrgencyClass: string = Translations_fi.RemoveEventUrgencyClass;
	static TaskHandlerParameterNotFound: string = Translations_fi.TaskHandlerParameterNotFound;
	static PositionErrorUnavailable: string = Translations_fi.PositionErrorUnavailable;
	static DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter;
	static Unit: string = Translations_fi.Unit;
	static ChangesMaxAmount: string = Translations_fi.ChangesMaxAmount;
	static NoAddedEventTypeCs: string = Translations_fi.NoAddedEventTypeCs;
	static NoAddedEventTypeBs: string = Translations_fi.NoAddedEventTypeBs;
	static CompanyCodeContainsIllegalCharacters: string = Translations_fi.CompanyCodeContainsIllegalCharacters;
	static EmploymentEndDate: string = Translations_fi.EmploymentEndDate;
	static WhistleBlowStatisticsNonAnonymousRecords: string = Translations_fi.WhistleBlowStatisticsNonAnonymousRecords;
	static DoYouReallyWantToRemoveEventTypeCParameter: string = Translations_fi.DoYouReallyWantToRemoveEventTypeCParameter;
	static PrintFileName: string = Translations_fi.PrintFileName;
	static CopyContentToDocumentContent: string = Translations_fi.CopyContentToDocumentContent;
	static DoYouReallyWantToRemoveTaskPriorityParameter: string = Translations_fi.DoYouReallyWantToRemoveTaskPriorityParameter;
	static EditableDocument: string = Translations_fi.EditableDocument;
	static GetMore: string = Translations_fi.GetMore;
	static FieldParameterValueMustBeDefined: string = Translations_fi.FieldParameterValueMustBeDefined;
	static DeletingAttachmentParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingAttachmentParameterWasNotSuccessfulConcurrencyError;
	static LastParameterYears: string = Translations_fi.LastParameterYears;
	static MyLastModifications: string = Translations_fi.MyLastModifications;
	static DownloadAttachment: string = Translations_fi.DownloadAttachment;
	static QualificationIsValid: string = Translations_fi.QualificationIsValid;
	static SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError;
	static Download: string = Translations_fi.Download;
	static DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter;
	static WhistleBlowInformantMessages: string = Translations_fi.WhistleBlowInformantMessages;
	static TaskStateLocked: string = Translations_fi.TaskStateLocked;
	static Usage: string = Translations_fi.Usage;
	static BasicEducation: string = Translations_fi.BasicEducation;
	static DeletingUserParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingUserParameterWasNotSuccessfulErrorParameter;
	static SavingWhistleBlowCategoryParameterWasSuccessful: string = Translations_fi.SavingWhistleBlowCategoryParameterWasSuccessful;
	static DoYouReallyWantToRemoveFileParameter: string = Translations_fi.DoYouReallyWantToRemoveFileParameter;
	static MaximumSizeOfSingleAttachment: string = Translations_fi.MaximumSizeOfSingleAttachment;
	static MoveToTrashBin: string = Translations_fi.MoveToTrashBin;
	static InstantMessageSettings: string = Translations_fi.InstantMessageSettings;
	static ObserverAction: string = Translations_fi.ObserverAction;
	static AddEventTypesToEvent: string = Translations_fi.AddEventTypesToEvent;
	static MessageModuleProperties: string = Translations_fi.MessageModuleProperties;
	static CreatingPrint: string = Translations_fi.CreatingPrint;
	static EventApprovalWasNotSuccessfulErrorParameter: string = Translations_fi.EventApprovalWasNotSuccessfulErrorParameter;
	static ImageChangeInterval: string = Translations_fi.ImageChangeInterval;
	static RemovePrint: string = Translations_fi.RemovePrint;
	static DocumentsSelected: string = Translations_fi.DocumentsSelected;
	static DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter;
	static AddPartnerParameterWasSuccessful: string = Translations_fi.AddPartnerParameterWasSuccessful;
	static MessageVisibilityPeriodNotStarted: string = Translations_fi.MessageVisibilityPeriodNotStarted;
	static DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages: string = Translations_fi.DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages;
	static RemovePicture: string = Translations_fi.RemovePicture;
	static RestoringInstantMessageWasNotSuccessfulConcurrencyError: string = Translations_fi.RestoringInstantMessageWasNotSuccessfulConcurrencyError;
	static ExternalSystemCode: string = Translations_fi.ExternalSystemCode;
	static Closed: string = Translations_fi.Closed;
	static SelectedDocumentTypes: string = Translations_fi.SelectedDocumentTypes;
	static InstantMessageChannelParameterNotFound: string = Translations_fi.InstantMessageChannelParameterNotFound;
	static AllEventHandlersHaveReadMessage: string = Translations_fi.AllEventHandlersHaveReadMessage;
	static StateOpenPlural: string = Translations_fi.StateOpenPlural;
	static ValidityStartDate: string = Translations_fi.ValidityStartDate;
	static SavingDirectoryItemPermissionsNotSuccessfulErrorParameter: string = Translations_fi.SavingDirectoryItemPermissionsNotSuccessfulErrorParameter;
	static SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter;
	static SelectCategory: string = Translations_fi.SelectCategory;
	static ModifiedBy: string = Translations_fi.ModifiedBy;
	static Expired: string = Translations_fi.Expired;
	static ViewLogLine: string = Translations_fi.ViewLogLine;
	static DoYouReallyWantToEmailMessageParameter: string = Translations_fi.DoYouReallyWantToEmailMessageParameter;
	static SelectEventTypeC: string = Translations_fi.SelectEventTypeC;
	static FolderNameConstainsIllegalCharacters: string = Translations_fi.FolderNameConstainsIllegalCharacters;
	static AllCompanies: string = Translations_fi.AllCompanies;
	static Today: string = Translations_fi.Today;
	static PrintOrientation: string = Translations_fi.PrintOrientation;
	static RecordBasicEducationCodeMustBeDefined: string = Translations_fi.RecordBasicEducationCodeMustBeDefined;
	static SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError;
	static DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError;
	static ApplicationTempPathIsNotSpecified: string = Translations_fi.ApplicationTempPathIsNotSpecified;
	static DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError;
	static OpenWhistleBlowInstruction: string = Translations_fi.OpenWhistleBlowInstruction;
	static FolderCannotBeMovedToItsSubFolder: string = Translations_fi.FolderCannotBeMovedToItsSubFolder;
	static DefaultTaskTypes: string = Translations_fi.DefaultTaskTypes;
	static SaveDraft: string = Translations_fi.SaveDraft;
	static DocumentModulePropertiesSaveWasSuccessful: string = Translations_fi.DocumentModulePropertiesSaveWasSuccessful;
	static WhistleBlowReportHasBeenSavedBottomText: string = Translations_fi.WhistleBlowReportHasBeenSavedBottomText;
	static AddedComment: string = Translations_fi.AddedComment;
	static AddFurtherEducationToMultipleUserRecords: string = Translations_fi.AddFurtherEducationToMultipleUserRecords;
	static WhistleBlowSourceMail: string = Translations_fi.WhistleBlowSourceMail;
	static SavingUserParametersWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingUserParametersWasNotSuccessfulConcurrencyError;
	static PositionErrorPermissionDenied: string = Translations_fi.PositionErrorPermissionDenied;
	static CkickToChangeTheIcon: string = Translations_fi.CkickToChangeTheIcon;
	static InstantMessageChannelArchivingTypeKeepMessages: string = Translations_fi.InstantMessageChannelArchivingTypeKeepMessages;
	static CopyingFilesAndDocumentsToFolderParameterWasSuccessful: string = Translations_fi.CopyingFilesAndDocumentsToFolderParameterWasSuccessful;
	static AddPartnersParameterWasSuccessful: string = Translations_fi.AddPartnersParameterWasSuccessful;
	static NoModifications: string = Translations_fi.NoModifications;
	static NormalMessage: string = Translations_fi.NormalMessage;
	static PermissionsToCreateEventsToEventPartner: string = Translations_fi.PermissionsToCreateEventsToEventPartner;
	static AddEvent: string = Translations_fi.AddEvent;
	static TrainingEndDateCannotPrecedeTrainingStartDate: string = Translations_fi.TrainingEndDateCannotPrecedeTrainingStartDate;
	static UserAccountNotConnected: string = Translations_fi.UserAccountNotConnected;
	static PrintIsEmpty: string = Translations_fi.PrintIsEmpty;
	static RemoveLogisticVoucherCustomerPaymentSetting: string = Translations_fi.RemoveLogisticVoucherCustomerPaymentSetting;
	static WhistleBlowStateArchived: string = Translations_fi.WhistleBlowStateArchived;
	static ShowUserGroups: string = Translations_fi.ShowUserGroups;
	static UserLastNameMustBeDefined: string = Translations_fi.UserLastNameMustBeDefined;
	static SearchStartDate: string = Translations_fi.SearchStartDate;
	static RemoveLogisticVoucherCompanyPaymentSetting: string = Translations_fi.RemoveLogisticVoucherCompanyPaymentSetting;
	static FilterIsActive: string = Translations_fi.FilterIsActive;
	static ExternalSystemUserCodeClaimNotFound: string = Translations_fi.ExternalSystemUserCodeClaimNotFound;
	static AddPhoto: string = Translations_fi.AddPhoto;
	static AddSalaryRowToMultipleUserRecords: string = Translations_fi.AddSalaryRowToMultipleUserRecords;
	static DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter;
	static AddingRecordUserSalaryRowsWasSuccessful: string = Translations_fi.AddingRecordUserSalaryRowsWasSuccessful;
	static Voluntary: string = Translations_fi.Voluntary;
	static FolderParameterAlreadyExistsInFileSystem: string = Translations_fi.FolderParameterAlreadyExistsInFileSystem;
	static ValidityEndDate: string = Translations_fi.ValidityEndDate;
	static Partners: string = Translations_fi.Partners;
	static Characters: string = Translations_fi.Characters;
	static AddRecordUserToUserAccount: string = Translations_fi.AddRecordUserToUserAccount;
	static EmptyDocument: string = Translations_fi.EmptyDocument;
	static TimeSpanMonth: string = Translations_fi.TimeSpanMonth;
	static AddSharedDocumentTemplate: string = Translations_fi.AddSharedDocumentTemplate;
	static AddMessage: string = Translations_fi.AddMessage;
	static RemoveTask: string = Translations_fi.RemoveTask;
	static EditInstantMessageChannel: string = Translations_fi.EditInstantMessageChannel;
	static SavingEventParameterWasSuccessful: string = Translations_fi.SavingEventParameterWasSuccessful;
	static AddUserGroupParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.AddUserGroupParameterWasNotSuccessfulConcurrencyError;
	static UserRecordStatisticsExpiredFamiliarizations: string = Translations_fi.UserRecordStatisticsExpiredFamiliarizations;
	static ValidityEndDateEnd: string = Translations_fi.ValidityEndDateEnd;
	static SavingCompanyGroupParameterWasNotSuccessfulErrorParameter: string = Translations_fi.SavingCompanyGroupParameterWasNotSuccessfulErrorParameter;
	static TasksExcelByTasks: string = Translations_fi.TasksExcelByTasks;
	static FilesParameterAreEmpty: string = Translations_fi.FilesParameterAreEmpty;
	static EventTypeBs: string = Translations_fi.EventTypeBs;
	static FolderParameterAlreadyExistsInTargetFolder: string = Translations_fi.FolderParameterAlreadyExistsInTargetFolder;
	static LogoSquare: string = Translations_fi.LogoSquare;
	static DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError;
	static SearchEvents: string = Translations_fi.SearchEvents;
	static WhistleBlowArchivingTypeChangeStateAndRemoveInformantData: string = Translations_fi.WhistleBlowArchivingTypeChangeStateAndRemoveInformantData;
	static DoYouReallyWantToRemoveReportParameter: string = Translations_fi.DoYouReallyWantToRemoveReportParameter;
	static Admin: string = Translations_fi.Admin;
	static TaskRemainderParameterNotFound: string = Translations_fi.TaskRemainderParameterNotFound;
	static Invoicing: string = Translations_fi.Invoicing;
	static AddCompanyGroup: string = Translations_fi.AddCompanyGroup;
	static WebPushError: string = Translations_fi.WebPushError;
	static TrainingStartDateStart: string = Translations_fi.TrainingStartDateStart;
	static Action: string = Translations_fi.Action;
	static UserCodeMustBeValid: string = Translations_fi.UserCodeMustBeValid;
	static UserCannotRecieveEmailNotifications: string = Translations_fi.UserCannotRecieveEmailNotifications;
	static LogisticVoucherCustomers: string = Translations_fi.LogisticVoucherCustomers;
	static DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter;
	static InstantMessages: string = Translations_fi.InstantMessages;
	static DeletingRecordUserEmploymentParameterWasSuccessful: string = Translations_fi.DeletingRecordUserEmploymentParameterWasSuccessful;
	static LogIn: string = Translations_fi.LogIn;
	static EventMessageConfirmation: string = Translations_fi.EventMessageConfirmation;
	static SavingMessageSendEmailParameterWasSuccessful: string = Translations_fi.SavingMessageSendEmailParameterWasSuccessful;
	static EditKeyword: string = Translations_fi.EditKeyword;
	static DeletingUserGroupParameterWasSuccessful: string = Translations_fi.DeletingUserGroupParameterWasSuccessful;
	static SaveFilter: string = Translations_fi.SaveFilter;
	static EditRecordUser: string = Translations_fi.EditRecordUser;
	static DeletingFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.DeletingFolderParameterWasNotSuccessfulErrorParameter;
	static AttachmentImageSize: string = Translations_fi.AttachmentImageSize;
	static UpdateCompanyExternalSystemsWasSuccessful: string = Translations_fi.UpdateCompanyExternalSystemsWasSuccessful;
	static FileUploadWasSuccessful: string = Translations_fi.FileUploadWasSuccessful;
	static Hours: string = Translations_fi.Hours;
	static EditDefaultTaskState: string = Translations_fi.EditDefaultTaskState;
	static DoYouReallyWantToRemoveRecordFamiliarizationParameter: string = Translations_fi.DoYouReallyWantToRemoveRecordFamiliarizationParameter;
	static Invited: string = Translations_fi.Invited;
	static ThisWeek: string = Translations_fi.ThisWeek;
	static KeywordCodeMustBeDefined: string = Translations_fi.KeywordCodeMustBeDefined;
	static RenewPersonalization: string = Translations_fi.RenewPersonalization;
	static DeletingInstantMessageChannelWasSuccessful: string = Translations_fi.DeletingInstantMessageChannelWasSuccessful;
	static CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError;
	static SharedDocumentTemplates: string = Translations_fi.SharedDocumentTemplates;
	static SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter: string = Translations_fi.SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter;
	static SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError;
	static Reject: string = Translations_fi.Reject;
	static UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted: string = Translations_fi.UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted;
	static TaskParameterNotFound: string = Translations_fi.TaskParameterNotFound;
	static PasswordMustContainDigitOrSpecialCharacter: string = Translations_fi.PasswordMustContainDigitOrSpecialCharacter;
	static ExternalIntraSystemCompanyTokenMustBeDefined: string = Translations_fi.ExternalIntraSystemCompanyTokenMustBeDefined;
	static StudyWeek: string = Translations_fi.StudyWeek;
	static Removed: string = Translations_fi.Removed;
	static Unread: string = Translations_fi.Unread;
	static HoursPerTrainingDay: string = Translations_fi.HoursPerTrainingDay;
	static DirectoryItemDocumentParameterNotFound: string = Translations_fi.DirectoryItemDocumentParameterNotFound;
	static LogisticVoucher: string = Translations_fi.LogisticVoucher;
	static PasteDefaultHandlers: string = Translations_fi.PasteDefaultHandlers;
	static WhistleBlowResolutionCanceled: string = Translations_fi.WhistleBlowResolutionCanceled;
	static NewMenu: string = Translations_fi.NewMenu;
	static Move: string = Translations_fi.Move;
	static TaskPriority: string = Translations_fi.TaskPriority;
	static AddUserGroupParameterWasSuccessful: string = Translations_fi.AddUserGroupParameterWasSuccessful;
	static CompanyThatIsInUseCannotBeDeleted: string = Translations_fi.CompanyThatIsInUseCannotBeDeleted;
	static SaveEventHandlerConfirmation: string = Translations_fi.SaveEventHandlerConfirmation;
	static WebPageStyle: string = Translations_fi.WebPageStyle;
	static Last120Days: string = Translations_fi.Last120Days;
	static SavingDefaultHandlersWasSuccessful: string = Translations_fi.SavingDefaultHandlersWasSuccessful;
	static ExternalSystemCompanyCodeMustBeDefined: string = Translations_fi.ExternalSystemCompanyCodeMustBeDefined;
	static DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError;
	static EditLogisticVoucherCustomerPaymentSetting: string = Translations_fi.EditLogisticVoucherCustomerPaymentSetting;
	static EditRow: string = Translations_fi.EditRow;
	static PanelSize3: string = Translations_fi.PanelSize3;
	static UserSessionHasBeenExpired: string = Translations_fi.UserSessionHasBeenExpired;
	static LogEventType: string = Translations_fi.LogEventType;
	static SavingOptionParameterWasSuccessful: string = Translations_fi.SavingOptionParameterWasSuccessful;
	static MovingFolderParameterWasNotSuccessfulErrorParameter: string = Translations_fi.MovingFolderParameterWasNotSuccessfulErrorParameter;
	static SavingReadReceiptWasNotSuccessfulErrorParameter: string = Translations_fi.SavingReadReceiptWasNotSuccessfulErrorParameter;
	static DoYouReallyWantToExitInstantMessageChannel: string = Translations_fi.DoYouReallyWantToExitInstantMessageChannel;
	static GroupByEducation: string = Translations_fi.GroupByEducation;
	static UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError: string = Translations_fi.UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError;
	static Source: string = Translations_fi.Source;
	static TaskType: string = Translations_fi.TaskType;
	static EventTypeB: string = Translations_fi.EventTypeB;
	static ReadingDatabaseParameter: string = Translations_fi.ReadingDatabaseParameter;
	static AddFileFilter: string = Translations_fi.AddFileFilter;
	static AddEventUrgencyClass: string = Translations_fi.AddEventUrgencyClass;
	static AddUserParameterWasNotSuccessfulErrorParameter: string = Translations_fi.AddUserParameterWasNotSuccessfulErrorParameter;
	static Filters: string = Translations_fi.Filters;
	static TransferToChannel: string = Translations_fi.TransferToChannel;
	static DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError: string = Translations_fi.DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError;
	static WhistleBlowStatisticsRecordCount: string = Translations_fi.WhistleBlowStatisticsRecordCount;
	static Choose: string = Translations_fi.Choose;
	static RemoveCompany: string = Translations_fi.RemoveCompany;
	static SavingRecordFamiliarizationParameterWasSuccessful: string = Translations_fi.SavingRecordFamiliarizationParameterWasSuccessful;
	static UserFilterNoUserGroup: string = Translations_fi.UserFilterNoUserGroup;
	static SavingMessageSendPushParameterWasSuccessful: string = Translations_fi.SavingMessageSendPushParameterWasSuccessful;
	static UserIsWhistleBlowChannelAdminAndCannotBeDeleted: string = Translations_fi.UserIsWhistleBlowChannelAdminAndCannotBeDeleted;
	static TrainingStartDate: string = Translations_fi.TrainingStartDate;
	static EmptyTaskTrashBin: string = Translations_fi.EmptyTaskTrashBin;
	static Familiarizations: string = Translations_fi.Familiarizations;
	static SavingReportWasNotSuccessfulConcurrencyError: string = Translations_fi.SavingReportWasNotSuccessfulConcurrencyError;
	static OpenWhistleBlow: string = Translations_fi.OpenWhistleBlow;
	static Last30Days: string = Translations_fi.Last30Days;
	static AccountNumberTkbInCredit: string = Translations_fi.AccountNumberTkbInCredit;
	static UserGroupNameIsAlreadyInUse: string = Translations_fi.UserGroupNameIsAlreadyInUse;
	static UserGroupNameMustBeDefined: string = Translations_fi.UserGroupNameMustBeDefined;
	static AccessRightRead: string = Translations_fi.AccessRightRead;

	
static setCulture1(culture: string) {
		Translations.DeletingRecordUserParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordUserParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserParameterWasSuccessful : Translations_en_US.DeletingRecordUserParameterWasSuccessful);
		Translations.SavingEditableDocumentParameterApprovalWasSuccessful = culture === "fi" ? Translations_fi.SavingEditableDocumentParameterApprovalWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEditableDocumentParameterApprovalWasSuccessful : Translations_en_US.SavingEditableDocumentParameterApprovalWasSuccessful);
		Translations.DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingCompanyGroupParameterWasNotSuccessfulConcurrencyError);
		Translations.CompanyNumber = culture === "fi" ? Translations_fi.CompanyNumber : (culture === "sv-FI" ? Translations_sv_FI.CompanyNumber : Translations_en_US.CompanyNumber);
		Translations.SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEditableDocumentParameterWasNotSuccessfulConcurrencyError);
		Translations.DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments : Translations_en_US.DoYouReallyWantToRemoveKeywordParameterItIsUsedByParameterDocuments);
		Translations.UserRecords = culture === "fi" ? Translations_fi.UserRecords : (culture === "sv-FI" ? Translations_sv_FI.UserRecords : Translations_en_US.UserRecords);
		Translations.MobileAppInUse = culture === "fi" ? Translations_fi.MobileAppInUse : (culture === "sv-FI" ? Translations_sv_FI.MobileAppInUse : Translations_en_US.MobileAppInUse);
		Translations.SendingPushNotificationsToParameter = culture === "fi" ? Translations_fi.SendingPushNotificationsToParameter : (culture === "sv-FI" ? Translations_sv_FI.SendingPushNotificationsToParameter : Translations_en_US.SendingPushNotificationsToParameter);
		Translations.Competency = culture === "fi" ? Translations_fi.Competency : (culture === "sv-FI" ? Translations_sv_FI.Competency : Translations_en_US.Competency);
		Translations.DoYouReallyWantToClearLog = culture === "fi" ? Translations_fi.DoYouReallyWantToClearLog : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToClearLog : Translations_en_US.DoYouReallyWantToClearLog);
		Translations.And = culture === "fi" ? Translations_fi.And : (culture === "sv-FI" ? Translations_sv_FI.And : Translations_en_US.And);
		Translations.UpdatingGroupCodeWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.UpdatingGroupCodeWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.UpdatingGroupCodeWasNotSuccessfulErrorParameter : Translations_en_US.UpdatingGroupCodeWasNotSuccessfulErrorParameter);
		Translations.End = culture === "fi" ? Translations_fi.End : (culture === "sv-FI" ? Translations_sv_FI.End : Translations_en_US.End);
		Translations.UserReports = culture === "fi" ? Translations_fi.UserReports : (culture === "sv-FI" ? Translations_sv_FI.UserReports : Translations_en_US.UserReports);
		Translations.GroupByFamiliarization = culture === "fi" ? Translations_fi.GroupByFamiliarization : (culture === "sv-FI" ? Translations_sv_FI.GroupByFamiliarization : Translations_en_US.GroupByFamiliarization);
		Translations.NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded = culture === "fi" ? Translations_fi.NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded : Translations_en_US.NewRecordUserMustBeSavedBeforeAttachmentsCanBeAdded);
		Translations.DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError);
		Translations.ShowOnMap = culture === "fi" ? Translations_fi.ShowOnMap : (culture === "sv-FI" ? Translations_sv_FI.ShowOnMap : Translations_en_US.ShowOnMap);
		Translations.CloseSystem = culture === "fi" ? Translations_fi.CloseSystem : (culture === "sv-FI" ? Translations_sv_FI.CloseSystem : Translations_en_US.CloseSystem);
		Translations.SaveReport = culture === "fi" ? Translations_fi.SaveReport : (culture === "sv-FI" ? Translations_sv_FI.SaveReport : Translations_en_US.SaveReport);
		Translations.AddToFavorites = culture === "fi" ? Translations_fi.AddToFavorites : (culture === "sv-FI" ? Translations_sv_FI.AddToFavorites : Translations_en_US.AddToFavorites);
		Translations.ClickToChangeTheLogo = culture === "fi" ? Translations_fi.ClickToChangeTheLogo : (culture === "sv-FI" ? Translations_sv_FI.ClickToChangeTheLogo : Translations_en_US.ClickToChangeTheLogo);
		Translations.DoYouReallyWantToRemoveEventPartnerParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveEventPartnerParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveEventPartnerParameter : Translations_en_US.DoYouReallyWantToRemoveEventPartnerParameter);
		Translations.RemoveTaskPriority = culture === "fi" ? Translations_fi.RemoveTaskPriority : (culture === "sv-FI" ? Translations_sv_FI.RemoveTaskPriority : Translations_en_US.RemoveTaskPriority);
		Translations.ANew = culture === "fi" ? Translations_fi.ANew : (culture === "sv-FI" ? Translations_sv_FI.ANew : Translations_en_US.ANew);
		Translations.FamiliarizationTrainer = culture === "fi" ? Translations_fi.FamiliarizationTrainer : (culture === "sv-FI" ? Translations_sv_FI.FamiliarizationTrainer : Translations_en_US.FamiliarizationTrainer);
		Translations.NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded = culture === "fi" ? Translations_fi.NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded : Translations_en_US.NewRecordUserMustBeSavedBeforeQualificationsCanBeAdded);
		Translations.StartDate = culture === "fi" ? Translations_fi.StartDate : (culture === "sv-FI" ? Translations_sv_FI.StartDate : Translations_en_US.StartDate);
		Translations.ResetDocumentVersion = culture === "fi" ? Translations_fi.ResetDocumentVersion : (culture === "sv-FI" ? Translations_sv_FI.ResetDocumentVersion : Translations_en_US.ResetDocumentVersion);
		Translations.TogglePreviewPanel = culture === "fi" ? Translations_fi.TogglePreviewPanel : (culture === "sv-FI" ? Translations_sv_FI.TogglePreviewPanel : Translations_en_US.TogglePreviewPanel);
		Translations.WhistleBlowSeverityLow = culture === "fi" ? Translations_fi.WhistleBlowSeverityLow : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSeverityLow : Translations_en_US.WhistleBlowSeverityLow);
		Translations.RemoveDefaultFurtherEducation = culture === "fi" ? Translations_fi.RemoveDefaultFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.RemoveDefaultFurtherEducation : Translations_en_US.RemoveDefaultFurtherEducation);
		Translations.RemoveDefaultTaskPriority = culture === "fi" ? Translations_fi.RemoveDefaultTaskPriority : (culture === "sv-FI" ? Translations_sv_FI.RemoveDefaultTaskPriority : Translations_en_US.RemoveDefaultTaskPriority);
		Translations.TasksFound = culture === "fi" ? Translations_fi.TasksFound : (culture === "sv-FI" ? Translations_sv_FI.TasksFound : Translations_en_US.TasksFound);
		Translations.SavingWhistleBlowMessageWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingWhistleBlowMessageWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowMessageWasNotSuccessfulErrorParameter : Translations_en_US.SavingWhistleBlowMessageWasNotSuccessfulErrorParameter);
		Translations.FirstName = culture === "fi" ? Translations_fi.FirstName : (culture === "sv-FI" ? Translations_sv_FI.FirstName : Translations_en_US.FirstName);
		Translations.DocumentApproved = culture === "fi" ? Translations_fi.DocumentApproved : (culture === "sv-FI" ? Translations_sv_FI.DocumentApproved : Translations_en_US.DocumentApproved);
		Translations.RecordUsersFound = culture === "fi" ? Translations_fi.RecordUsersFound : (culture === "sv-FI" ? Translations_sv_FI.RecordUsersFound : Translations_en_US.RecordUsersFound);
		Translations.UserFirstNameMustBeDefined = culture === "fi" ? Translations_fi.UserFirstNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.UserFirstNameMustBeDefined : Translations_en_US.UserFirstNameMustBeDefined);
		Translations.RecordUserReport = culture === "fi" ? Translations_fi.RecordUserReport : (culture === "sv-FI" ? Translations_sv_FI.RecordUserReport : Translations_en_US.RecordUserReport);
		Translations.AddLogisticVoucherTransportCompany = culture === "fi" ? Translations_fi.AddLogisticVoucherTransportCompany : (culture === "sv-FI" ? Translations_sv_FI.AddLogisticVoucherTransportCompany : Translations_en_US.AddLogisticVoucherTransportCompany);
		Translations.RemoveTarget = culture === "fi" ? Translations_fi.RemoveTarget : (culture === "sv-FI" ? Translations_sv_FI.RemoveTarget : Translations_en_US.RemoveTarget);
		Translations.Comment = culture === "fi" ? Translations_fi.Comment : (culture === "sv-FI" ? Translations_sv_FI.Comment : Translations_en_US.Comment);
		Translations.EventReport = culture === "fi" ? Translations_fi.EventReport : (culture === "sv-FI" ? Translations_sv_FI.EventReport : Translations_en_US.EventReport);
		Translations.DeletingRecordBasicEducationsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordBasicEducationsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordBasicEducationsParameterWasSuccessful : Translations_en_US.DeletingRecordBasicEducationsParameterWasSuccessful);
		Translations.EventApprovalWasSuccessful = culture === "fi" ? Translations_fi.EventApprovalWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.EventApprovalWasSuccessful : Translations_en_US.EventApprovalWasSuccessful);
		Translations.Visibility = culture === "fi" ? Translations_fi.Visibility : (culture === "sv-FI" ? Translations_sv_FI.Visibility : Translations_en_US.Visibility);
		Translations.LockedEventParameterCannotBeDeleted = culture === "fi" ? Translations_fi.LockedEventParameterCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.LockedEventParameterCannotBeDeleted : Translations_en_US.LockedEventParameterCannotBeDeleted);
		Translations.WhistleBlowStatisticsResolvedRecords = culture === "fi" ? Translations_fi.WhistleBlowStatisticsResolvedRecords : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStatisticsResolvedRecords : Translations_en_US.WhistleBlowStatisticsResolvedRecords);
		Translations.AccessRightReadTasks = culture === "fi" ? Translations_fi.AccessRightReadTasks : (culture === "sv-FI" ? Translations_sv_FI.AccessRightReadTasks : Translations_en_US.AccessRightReadTasks);
		Translations.Copy = culture === "fi" ? Translations_fi.Copy : (culture === "sv-FI" ? Translations_sv_FI.Copy : Translations_en_US.Copy);
		Translations.UserGroupCodeMustBeDefined = culture === "fi" ? Translations_fi.UserGroupCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.UserGroupCodeMustBeDefined : Translations_en_US.UserGroupCodeMustBeDefined);
		Translations.WhistleBlowUrl = culture === "fi" ? Translations_fi.WhistleBlowUrl : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowUrl : Translations_en_US.WhistleBlowUrl);
		Translations.Favorites = culture === "fi" ? Translations_fi.Favorites : (culture === "sv-FI" ? Translations_sv_FI.Favorites : Translations_en_US.Favorites);
		Translations.SavingRecordUserQualificationParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingRecordUserQualificationParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserQualificationParameterWasSuccessful : Translations_en_US.SavingRecordUserQualificationParameterWasSuccessful);
		Translations.NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded = culture === "fi" ? Translations_fi.NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded : Translations_en_US.NewInstantMessageChannelMustBeSavedBeforeUsersCanBeAdded);
		Translations.AddingRecordUserFamiliarizationsWasSuccessful = culture === "fi" ? Translations_fi.AddingRecordUserFamiliarizationsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingRecordUserFamiliarizationsWasSuccessful : Translations_en_US.AddingRecordUserFamiliarizationsWasSuccessful);
		Translations.DoYouReallyWantToRemoveSelectedTaskTypes = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedTaskTypes : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedTaskTypes : Translations_en_US.DoYouReallyWantToRemoveSelectedTaskTypes);
		Translations.AccountIBANNumber = culture === "fi" ? Translations_fi.AccountIBANNumber : (culture === "sv-FI" ? Translations_sv_FI.AccountIBANNumber : Translations_en_US.AccountIBANNumber);
		Translations.PersonalIdentityCode = culture === "fi" ? Translations_fi.PersonalIdentityCode : (culture === "sv-FI" ? Translations_sv_FI.PersonalIdentityCode : Translations_en_US.PersonalIdentityCode);
		Translations.FileStorageQuotaGB = culture === "fi" ? Translations_fi.FileStorageQuotaGB : (culture === "sv-FI" ? Translations_sv_FI.FileStorageQuotaGB : Translations_en_US.FileStorageQuotaGB);
		Translations.Archived = culture === "fi" ? Translations_fi.Archived : (culture === "sv-FI" ? Translations_sv_FI.Archived : Translations_en_US.Archived);
		Translations.LockedTaskParameterCannotBeDeleted = culture === "fi" ? Translations_fi.LockedTaskParameterCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.LockedTaskParameterCannotBeDeleted : Translations_en_US.LockedTaskParameterCannotBeDeleted);
		Translations.SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError);
		Translations.TaskHandlers = culture === "fi" ? Translations_fi.TaskHandlers : (culture === "sv-FI" ? Translations_sv_FI.TaskHandlers : Translations_en_US.TaskHandlers);
		Translations.AddEventTypeBsToEvent = culture === "fi" ? Translations_fi.AddEventTypeBsToEvent : (culture === "sv-FI" ? Translations_sv_FI.AddEventTypeBsToEvent : Translations_en_US.AddEventTypeBsToEvent);
		Translations.RemoveRecordUser = culture === "fi" ? Translations_fi.RemoveRecordUser : (culture === "sv-FI" ? Translations_sv_FI.RemoveRecordUser : Translations_en_US.RemoveRecordUser);
		Translations.DefaultEventUrgencyClassParameterCannotBeDeleted = culture === "fi" ? Translations_fi.DefaultEventUrgencyClassParameterCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.DefaultEventUrgencyClassParameterCannotBeDeleted : Translations_en_US.DefaultEventUrgencyClassParameterCannotBeDeleted);
		Translations.Saved = culture === "fi" ? Translations_fi.Saved : (culture === "sv-FI" ? Translations_sv_FI.Saved : Translations_en_US.Saved);
		Translations.SavingUnitPermissionsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingUnitPermissionsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingUnitPermissionsWasNotSuccessfulConcurrencyError : Translations_en_US.SavingUnitPermissionsWasNotSuccessfulConcurrencyError);
		Translations.SaveReportAs = culture === "fi" ? Translations_fi.SaveReportAs : (culture === "sv-FI" ? Translations_sv_FI.SaveReportAs : Translations_en_US.SaveReportAs);
		Translations.OK = culture === "fi" ? Translations_fi.OK : (culture === "sv-FI" ? Translations_sv_FI.OK : Translations_en_US.OK);
		Translations.Rows = culture === "fi" ? Translations_fi.Rows : (culture === "sv-FI" ? Translations_sv_FI.Rows : Translations_en_US.Rows);
		Translations.AddUserGroupParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddUserGroupParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddUserGroupParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddUserGroupParameterWasNotSuccessfulErrorParameter);
		Translations.MovedToTrashBin = culture === "fi" ? Translations_fi.MovedToTrashBin : (culture === "sv-FI" ? Translations_sv_FI.MovedToTrashBin : Translations_en_US.MovedToTrashBin);
		Translations.AllUsersOfTheUnit = culture === "fi" ? Translations_fi.AllUsersOfTheUnit : (culture === "sv-FI" ? Translations_sv_FI.AllUsersOfTheUnit : Translations_en_US.AllUsersOfTheUnit);
		Translations.ChangedEventUnit = culture === "fi" ? Translations_fi.ChangedEventUnit : (culture === "sv-FI" ? Translations_sv_FI.ChangedEventUnit : Translations_en_US.ChangedEventUnit);
		Translations.SavingApplicationPermissionsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingApplicationPermissionsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingApplicationPermissionsWasNotSuccessfulErrorParameter : Translations_en_US.SavingApplicationPermissionsWasNotSuccessfulErrorParameter);
		Translations.PanelVisible = culture === "fi" ? Translations_fi.PanelVisible : (culture === "sv-FI" ? Translations_sv_FI.PanelVisible : Translations_en_US.PanelVisible);
		Translations.Effectiveness = culture === "fi" ? Translations_fi.Effectiveness : (culture === "sv-FI" ? Translations_sv_FI.Effectiveness : Translations_en_US.Effectiveness);
		Translations.AddChildEventTypeB = culture === "fi" ? Translations_fi.AddChildEventTypeB : (culture === "sv-FI" ? Translations_sv_FI.AddChildEventTypeB : Translations_en_US.AddChildEventTypeB);
		Translations.AddNew = culture === "fi" ? Translations_fi.AddNew : (culture === "sv-FI" ? Translations_sv_FI.AddNew : Translations_en_US.AddNew);
		Translations.AdditionalFunctions = culture === "fi" ? Translations_fi.AdditionalFunctions : (culture === "sv-FI" ? Translations_sv_FI.AdditionalFunctions : Translations_en_US.AdditionalFunctions);
		Translations.DeletingUserGroupsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingUserGroupsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupsParameterWasSuccessful : Translations_en_US.DeletingUserGroupsParameterWasSuccessful);
		Translations.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter);
		Translations.SelectUser = culture === "fi" ? Translations_fi.SelectUser : (culture === "sv-FI" ? Translations_sv_FI.SelectUser : Translations_en_US.SelectUser);
		Translations.WhistleBlowStatisticsAnonymousRecords = culture === "fi" ? Translations_fi.WhistleBlowStatisticsAnonymousRecords : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStatisticsAnonymousRecords : Translations_en_US.WhistleBlowStatisticsAnonymousRecords);
		Translations.OldPasswordIsIncorrect = culture === "fi" ? Translations_fi.OldPasswordIsIncorrect : (culture === "sv-FI" ? Translations_sv_FI.OldPasswordIsIncorrect : Translations_en_US.OldPasswordIsIncorrect);
		Translations.StandardFiltersAndReports = culture === "fi" ? Translations_fi.StandardFiltersAndReports : (culture === "sv-FI" ? Translations_sv_FI.StandardFiltersAndReports : Translations_en_US.StandardFiltersAndReports);
		Translations.ClassParameterHasNotBeenInitialized = culture === "fi" ? Translations_fi.ClassParameterHasNotBeenInitialized : (culture === "sv-FI" ? Translations_sv_FI.ClassParameterHasNotBeenInitialized : Translations_en_US.ClassParameterHasNotBeenInitialized);
		Translations.Permissions = culture === "fi" ? Translations_fi.Permissions : (culture === "sv-FI" ? Translations_sv_FI.Permissions : Translations_en_US.Permissions);
		Translations.NoEventsToShow = culture === "fi" ? Translations_fi.NoEventsToShow : (culture === "sv-FI" ? Translations_sv_FI.NoEventsToShow : Translations_en_US.NoEventsToShow);
		Translations.RemoveRow = culture === "fi" ? Translations_fi.RemoveRow : (culture === "sv-FI" ? Translations_sv_FI.RemoveRow : Translations_en_US.RemoveRow);
		Translations.ZipFileParameterNotFound = culture === "fi" ? Translations_fi.ZipFileParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.ZipFileParameterNotFound : Translations_en_US.ZipFileParameterNotFound);
		Translations.EventIsApprovedByUser = culture === "fi" ? Translations_fi.EventIsApprovedByUser : (culture === "sv-FI" ? Translations_sv_FI.EventIsApprovedByUser : Translations_en_US.EventIsApprovedByUser);
		Translations.DocumentNameConstainsIllegalCharacters = culture === "fi" ? Translations_fi.DocumentNameConstainsIllegalCharacters : (culture === "sv-FI" ? Translations_sv_FI.DocumentNameConstainsIllegalCharacters : Translations_en_US.DocumentNameConstainsIllegalCharacters);
		Translations.WhistleBlowStatisticsReceivedRecords = culture === "fi" ? Translations_fi.WhistleBlowStatisticsReceivedRecords : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStatisticsReceivedRecords : Translations_en_US.WhistleBlowStatisticsReceivedRecords);
		Translations.SavingEventTypeBsWasSuccessful = culture === "fi" ? Translations_fi.SavingEventTypeBsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeBsWasSuccessful : Translations_en_US.SavingEventTypeBsWasSuccessful);
		Translations.AddImageFromClipboard = culture === "fi" ? Translations_fi.AddImageFromClipboard : (culture === "sv-FI" ? Translations_sv_FI.AddImageFromClipboard : Translations_en_US.AddImageFromClipboard);
		Translations.DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingEventPartnerParameterWasNotSuccessfulConcurrencyError);
		Translations.EditEventType = culture === "fi" ? Translations_fi.EditEventType : (culture === "sv-FI" ? Translations_sv_FI.EditEventType : Translations_en_US.EditEventType);
		Translations.DeletingEventTypeParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingEventTypeParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingEventTypeParameterWasNotSuccessfulErrorParameter);
		Translations.AddFamiliarizations = culture === "fi" ? Translations_fi.AddFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.AddFamiliarizations : Translations_en_US.AddFamiliarizations);
		Translations.SelectFurtherEducation = culture === "fi" ? Translations_fi.SelectFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.SelectFurtherEducation : Translations_en_US.SelectFurtherEducation);
		Translations.DeletingWhistleBlowCategoriesParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingWhistleBlowCategoriesParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowCategoriesParameterWasSuccessful : Translations_en_US.DeletingWhistleBlowCategoriesParameterWasSuccessful);
		Translations.MessageVisibilityPeriodNotActive = culture === "fi" ? Translations_fi.MessageVisibilityPeriodNotActive : (culture === "sv-FI" ? Translations_sv_FI.MessageVisibilityPeriodNotActive : Translations_en_US.MessageVisibilityPeriodNotActive);
		Translations.CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful = culture === "fi" ? Translations_fi.CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful : Translations_en_US.CopyingFolderDirectoryItemParameterToFolderParameterWasSuccessful);
		Translations.EditPermission = culture === "fi" ? Translations_fi.EditPermission : (culture === "sv-FI" ? Translations_sv_FI.EditPermission : Translations_en_US.EditPermission);
		Translations.RemovingWhistleBlowHandlersWasSuccessful = culture === "fi" ? Translations_fi.RemovingWhistleBlowHandlersWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.RemovingWhistleBlowHandlersWasSuccessful : Translations_en_US.RemovingWhistleBlowHandlersWasSuccessful);
		Translations.AddPrint = culture === "fi" ? Translations_fi.AddPrint : (culture === "sv-FI" ? Translations_sv_FI.AddPrint : Translations_en_US.AddPrint);
		Translations.RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError : Translations_en_US.RestoringTaskParameterFromTrashBinWasNotSuccessfulConcurrencyError);
		Translations.PaymentSettings = culture === "fi" ? Translations_fi.PaymentSettings : (culture === "sv-FI" ? Translations_sv_FI.PaymentSettings : Translations_en_US.PaymentSettings);
		Translations.LockedTaskParameterCannotBeMovedToTrashBin = culture === "fi" ? Translations_fi.LockedTaskParameterCannotBeMovedToTrashBin : (culture === "sv-FI" ? Translations_sv_FI.LockedTaskParameterCannotBeMovedToTrashBin : Translations_en_US.LockedTaskParameterCannotBeMovedToTrashBin);
		Translations.ChooseHowYouWantToReport = culture === "fi" ? Translations_fi.ChooseHowYouWantToReport : (culture === "sv-FI" ? Translations_sv_FI.ChooseHowYouWantToReport : Translations_en_US.ChooseHowYouWantToReport);
		Translations.CompanyInstantMessageChannels = culture === "fi" ? Translations_fi.CompanyInstantMessageChannels : (culture === "sv-FI" ? Translations_sv_FI.CompanyInstantMessageChannels : Translations_en_US.CompanyInstantMessageChannels);
		Translations.DeletingUsersParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingUsersParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingUsersParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingUsersParameterWasNotSuccessfulConcurrencyError);
		Translations.AddEventType = culture === "fi" ? Translations_fi.AddEventType : (culture === "sv-FI" ? Translations_sv_FI.AddEventType : Translations_en_US.AddEventType);
		Translations.Day = culture === "fi" ? Translations_fi.Day : (culture === "sv-FI" ? Translations_sv_FI.Day : Translations_en_US.Day);
		Translations.DoYouReallyWantToRemoveDocumentParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveDocumentParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveDocumentParameter : Translations_en_US.DoYouReallyWantToRemoveDocumentParameter);
		Translations.EditFileFilter = culture === "fi" ? Translations_fi.EditFileFilter : (culture === "sv-FI" ? Translations_sv_FI.EditFileFilter : Translations_en_US.EditFileFilter);
		Translations.DeletingFileParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingFileParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingFileParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingFileParameterWasNotSuccessfulErrorParameter);
		Translations.UserEndDate = culture === "fi" ? Translations_fi.UserEndDate : (culture === "sv-FI" ? Translations_sv_FI.UserEndDate : Translations_en_US.UserEndDate);
		Translations.SavingOneTimePasswordParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingOneTimePasswordParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingOneTimePasswordParameterWasSuccessful : Translations_en_US.SavingOneTimePasswordParameterWasSuccessful);
		Translations.NewVersionAvailable = culture === "fi" ? Translations_fi.NewVersionAvailable : (culture === "sv-FI" ? Translations_sv_FI.NewVersionAvailable : Translations_en_US.NewVersionAvailable);
		Translations.DeletingUserGroupParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingUserGroupParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingUserGroupParameterWasNotSuccessfulConcurrencyError);
		Translations.NotRepeated = culture === "fi" ? Translations_fi.NotRepeated : (culture === "sv-FI" ? Translations_sv_FI.NotRepeated : Translations_en_US.NotRepeated);
		Translations.ReplyToInstantMessage = culture === "fi" ? Translations_fi.ReplyToInstantMessage : (culture === "sv-FI" ? Translations_sv_FI.ReplyToInstantMessage : Translations_en_US.ReplyToInstantMessage);
		Translations.IgnoreFilePatterns = culture === "fi" ? Translations_fi.IgnoreFilePatterns : (culture === "sv-FI" ? Translations_sv_FI.IgnoreFilePatterns : Translations_en_US.IgnoreFilePatterns);
		Translations.CopyPermissionToAllSameLevelFolders = culture === "fi" ? Translations_fi.CopyPermissionToAllSameLevelFolders : (culture === "sv-FI" ? Translations_sv_FI.CopyPermissionToAllSameLevelFolders : Translations_en_US.CopyPermissionToAllSameLevelFolders);
		Translations.SelectState = culture === "fi" ? Translations_fi.SelectState : (culture === "sv-FI" ? Translations_sv_FI.SelectState : Translations_en_US.SelectState);
		Translations.UserFilterActive = culture === "fi" ? Translations_fi.UserFilterActive : (culture === "sv-FI" ? Translations_sv_FI.UserFilterActive : Translations_en_US.UserFilterActive);
		Translations.ProjectNumber = culture === "fi" ? Translations_fi.ProjectNumber : (culture === "sv-FI" ? Translations_sv_FI.ProjectNumber : Translations_en_US.ProjectNumber);
		Translations.EditableDocumentCodeMustBeDefined = culture === "fi" ? Translations_fi.EditableDocumentCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentCodeMustBeDefined : Translations_en_US.EditableDocumentCodeMustBeDefined);
		Translations.DoYouReallyWantToRemoveRecordUserEmploymentParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveRecordUserEmploymentParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveRecordUserEmploymentParameter : Translations_en_US.DoYouReallyWantToRemoveRecordUserEmploymentParameter);
		Translations.UserRecordStatisticsExpiringInSixMonthsFamiliarizations = culture === "fi" ? Translations_fi.UserRecordStatisticsExpiringInSixMonthsFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsExpiringInSixMonthsFamiliarizations : Translations_en_US.UserRecordStatisticsExpiringInSixMonthsFamiliarizations);
		Translations.PublishVoucherToFileSystem = culture === "fi" ? Translations_fi.PublishVoucherToFileSystem : (culture === "sv-FI" ? Translations_sv_FI.PublishVoucherToFileSystem : Translations_en_US.PublishVoucherToFileSystem);
		Translations.WhistleBlowStatisticsNewRecords = culture === "fi" ? Translations_fi.WhistleBlowStatisticsNewRecords : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStatisticsNewRecords : Translations_en_US.WhistleBlowStatisticsNewRecords);
		Translations.NewInstantMessageChannelGroupInternal = culture === "fi" ? Translations_fi.NewInstantMessageChannelGroupInternal : (culture === "sv-FI" ? Translations_sv_FI.NewInstantMessageChannelGroupInternal : Translations_en_US.NewInstantMessageChannelGroupInternal);
		Translations.ArchiveExpiredFamiliarizations = culture === "fi" ? Translations_fi.ArchiveExpiredFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.ArchiveExpiredFamiliarizations : Translations_en_US.ArchiveExpiredFamiliarizations);
		Translations.AuthorizationLevel = culture === "fi" ? Translations_fi.AuthorizationLevel : (culture === "sv-FI" ? Translations_sv_FI.AuthorizationLevel : Translations_en_US.AuthorizationLevel);
		Translations.Locked = culture === "fi" ? Translations_fi.Locked : (culture === "sv-FI" ? Translations_sv_FI.Locked : Translations_en_US.Locked);
		Translations.MarkMessageAsRead = culture === "fi" ? Translations_fi.MarkMessageAsRead : (culture === "sv-FI" ? Translations_sv_FI.MarkMessageAsRead : Translations_en_US.MarkMessageAsRead);
		Translations.AuthorizationLevelAdmin = culture === "fi" ? Translations_fi.AuthorizationLevelAdmin : (culture === "sv-FI" ? Translations_sv_FI.AuthorizationLevelAdmin : Translations_en_US.AuthorizationLevelAdmin);
		Translations.TaskTypesMustBeDefinedBeforeAddingTasks = culture === "fi" ? Translations_fi.TaskTypesMustBeDefinedBeforeAddingTasks : (culture === "sv-FI" ? Translations_sv_FI.TaskTypesMustBeDefinedBeforeAddingTasks : Translations_en_US.TaskTypesMustBeDefinedBeforeAddingTasks);
		Translations.SavingEventTypeBParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingEventTypeBParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeBParameterWasSuccessful : Translations_en_US.SavingEventTypeBParameterWasSuccessful);
		Translations.InstantMessageAttachmentFolderIsNotSpecified = culture === "fi" ? Translations_fi.InstantMessageAttachmentFolderIsNotSpecified : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageAttachmentFolderIsNotSpecified : Translations_en_US.InstantMessageAttachmentFolderIsNotSpecified);
		Translations.DirectoryItemFolderParameterNotFound = culture === "fi" ? Translations_fi.DirectoryItemFolderParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.DirectoryItemFolderParameterNotFound : Translations_en_US.DirectoryItemFolderParameterNotFound);
		Translations.TasksExcelByTaskRows = culture === "fi" ? Translations_fi.TasksExcelByTaskRows : (culture === "sv-FI" ? Translations_sv_FI.TasksExcelByTaskRows : Translations_en_US.TasksExcelByTaskRows);
		Translations.UserRecordStatisticsExpiringInSixMonthsQualifications = culture === "fi" ? Translations_fi.UserRecordStatisticsExpiringInSixMonthsQualifications : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsExpiringInSixMonthsQualifications : Translations_en_US.UserRecordStatisticsExpiringInSixMonthsQualifications);
		Translations.WhistleBlowStateInProgress = culture === "fi" ? Translations_fi.WhistleBlowStateInProgress : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStateInProgress : Translations_en_US.WhistleBlowStateInProgress);
		Translations.LockingTaskDataRowWasNotSuccessful = culture === "fi" ? Translations_fi.LockingTaskDataRowWasNotSuccessful : (culture === "sv-FI" ? Translations_sv_FI.LockingTaskDataRowWasNotSuccessful : Translations_en_US.LockingTaskDataRowWasNotSuccessful);
		Translations.ExpiredPlural = culture === "fi" ? Translations_fi.ExpiredPlural : (culture === "sv-FI" ? Translations_sv_FI.ExpiredPlural : Translations_en_US.ExpiredPlural);
		Translations.ACopy = culture === "fi" ? Translations_fi.ACopy : (culture === "sv-FI" ? Translations_sv_FI.ACopy : Translations_en_US.ACopy);
		Translations.EventPartnerIsAlreadyInDefined = culture === "fi" ? Translations_fi.EventPartnerIsAlreadyInDefined : (culture === "sv-FI" ? Translations_sv_FI.EventPartnerIsAlreadyInDefined : Translations_en_US.EventPartnerIsAlreadyInDefined);
		Translations.PublishingTaskTypeParameterWasSuccessful = culture === "fi" ? Translations_fi.PublishingTaskTypeParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.PublishingTaskTypeParameterWasSuccessful : Translations_en_US.PublishingTaskTypeParameterWasSuccessful);
		Translations.WhistleBlowInformantTitle = culture === "fi" ? Translations_fi.WhistleBlowInformantTitle : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantTitle : Translations_en_US.WhistleBlowInformantTitle);
		Translations.ChartType = culture === "fi" ? Translations_fi.ChartType : (culture === "sv-FI" ? Translations_sv_FI.ChartType : Translations_en_US.ChartType);
		Translations.AddCommentsToEvent = culture === "fi" ? Translations_fi.AddCommentsToEvent : (culture === "sv-FI" ? Translations_sv_FI.AddCommentsToEvent : Translations_en_US.AddCommentsToEvent);
		Translations.DeletingCompanyParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingCompanyParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingCompanyParameterWasNotSuccessfulErrorParameter);
		Translations.WhistleBlowCategoryParameterNotFound = culture === "fi" ? Translations_fi.WhistleBlowCategoryParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowCategoryParameterNotFound : Translations_en_US.WhistleBlowCategoryParameterNotFound);
		Translations.UnlockingEventParameterWasNotSuccessful = culture === "fi" ? Translations_fi.UnlockingEventParameterWasNotSuccessful : (culture === "sv-FI" ? Translations_sv_FI.UnlockingEventParameterWasNotSuccessful : Translations_en_US.UnlockingEventParameterWasNotSuccessful);
		Translations.AddEventHandlersToEvent = culture === "fi" ? Translations_fi.AddEventHandlersToEvent : (culture === "sv-FI" ? Translations_sv_FI.AddEventHandlersToEvent : Translations_en_US.AddEventHandlersToEvent);
		Translations.File = culture === "fi" ? Translations_fi.File : (culture === "sv-FI" ? Translations_sv_FI.File : Translations_en_US.File);
		Translations.EditEventTypeCs = culture === "fi" ? Translations_fi.EditEventTypeCs : (culture === "sv-FI" ? Translations_sv_FI.EditEventTypeCs : Translations_en_US.EditEventTypeCs);
		Translations.SavingEventTypeCsWasSuccessful = culture === "fi" ? Translations_fi.SavingEventTypeCsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeCsWasSuccessful : Translations_en_US.SavingEventTypeCsWasSuccessful);
		Translations.WhistleBlowRecords = culture === "fi" ? Translations_fi.WhistleBlowRecords : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowRecords : Translations_en_US.WhistleBlowRecords);
		Translations.SavingInstantMessageChannelUserUserWasSuccessful = culture === "fi" ? Translations_fi.SavingInstantMessageChannelUserUserWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingInstantMessageChannelUserUserWasSuccessful : Translations_en_US.SavingInstantMessageChannelUserUserWasSuccessful);
		Translations.DoCleanLog = culture === "fi" ? Translations_fi.DoCleanLog : (culture === "sv-FI" ? Translations_sv_FI.DoCleanLog : Translations_en_US.DoCleanLog);
		Translations.KeywordsOrCondition = culture === "fi" ? Translations_fi.KeywordsOrCondition : (culture === "sv-FI" ? Translations_sv_FI.KeywordsOrCondition : Translations_en_US.KeywordsOrCondition);
		Translations.FilterCondition = culture === "fi" ? Translations_fi.FilterCondition : (culture === "sv-FI" ? Translations_sv_FI.FilterCondition : Translations_en_US.FilterCondition);
		Translations.RemoveDefaultHandler = culture === "fi" ? Translations_fi.RemoveDefaultHandler : (culture === "sv-FI" ? Translations_sv_FI.RemoveDefaultHandler : Translations_en_US.RemoveDefaultHandler);
		Translations.SavingKeywordParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingKeywordParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingKeywordParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingKeywordParameterWasNotSuccessfulConcurrencyError);
		Translations.DeletingEventTypeCParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingEventTypeCParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeCParameterWasSuccessful : Translations_en_US.DeletingEventTypeCParameterWasSuccessful);
		Translations.FolderParameterAlreadyExists = culture === "fi" ? Translations_fi.FolderParameterAlreadyExists : (culture === "sv-FI" ? Translations_sv_FI.FolderParameterAlreadyExists : Translations_en_US.FolderParameterAlreadyExists);
		Translations.EventUrgencyClasses = culture === "fi" ? Translations_fi.EventUrgencyClasses : (culture === "sv-FI" ? Translations_sv_FI.EventUrgencyClasses : Translations_en_US.EventUrgencyClasses);
		Translations.EventTypeBCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.EventTypeBCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.EventTypeBCodeIsAlreadyInUse : Translations_en_US.EventTypeBCodeIsAlreadyInUse);
		Translations.WhistleBlowDashboard = culture === "fi" ? Translations_fi.WhistleBlowDashboard : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowDashboard : Translations_en_US.WhistleBlowDashboard);
		Translations.WhistleBlowAnonymityNonAnonymous = culture === "fi" ? Translations_fi.WhistleBlowAnonymityNonAnonymous : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowAnonymityNonAnonymous : Translations_en_US.WhistleBlowAnonymityNonAnonymous);
		Translations.InheritedUserRights = culture === "fi" ? Translations_fi.InheritedUserRights : (culture === "sv-FI" ? Translations_sv_FI.InheritedUserRights : Translations_en_US.InheritedUserRights);
		Translations.Entrepreneur = culture === "fi" ? Translations_fi.Entrepreneur : (culture === "sv-FI" ? Translations_sv_FI.Entrepreneur : Translations_en_US.Entrepreneur);
		Translations.DeletingWhistleBlowChannelAdminsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingWhistleBlowChannelAdminsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowChannelAdminsParameterWasSuccessful : Translations_en_US.DeletingWhistleBlowChannelAdminsParameterWasSuccessful);
		Translations.SavingFilterWasSuccessful = culture === "fi" ? Translations_fi.SavingFilterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingFilterWasSuccessful : Translations_en_US.SavingFilterWasSuccessful);
		Translations.UserIsCompanyAdminClaimNotFound = culture === "fi" ? Translations_fi.UserIsCompanyAdminClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.UserIsCompanyAdminClaimNotFound : Translations_en_US.UserIsCompanyAdminClaimNotFound);
		Translations.ThisMessageWasDeleted = culture === "fi" ? Translations_fi.ThisMessageWasDeleted : (culture === "sv-FI" ? Translations_sv_FI.ThisMessageWasDeleted : Translations_en_US.ThisMessageWasDeleted);
		Translations.DeletingAttachmentsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingAttachmentsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingAttachmentsWasNotSuccessfulErrorParameter : Translations_en_US.DeletingAttachmentsWasNotSuccessfulErrorParameter);
		Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges = culture === "fi" ? Translations_fi.YouHaveNotSavedChangesDoYouWantToSaveChanges : (culture === "sv-FI" ? Translations_sv_FI.YouHaveNotSavedChangesDoYouWantToSaveChanges : Translations_en_US.YouHaveNotSavedChangesDoYouWantToSaveChanges);
		Translations.SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError : Translations_en_US.SavingInstantMessageChannelUserWasNotSuccessfulConcurrencyError);
		Translations.DependencyResolvingFailedForInterfaceParameter = culture === "fi" ? Translations_fi.DependencyResolvingFailedForInterfaceParameter : (culture === "sv-FI" ? Translations_sv_FI.DependencyResolvingFailedForInterfaceParameter : Translations_en_US.DependencyResolvingFailedForInterfaceParameter);
		Translations.WhistleBlowInformantMessageConfirmation = culture === "fi" ? Translations_fi.WhistleBlowInformantMessageConfirmation : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantMessageConfirmation : Translations_en_US.WhistleBlowInformantMessageConfirmation);
		Translations.WriteBelowFieldWordParameterToConfirmTheOperation = culture === "fi" ? Translations_fi.WriteBelowFieldWordParameterToConfirmTheOperation : (culture === "sv-FI" ? Translations_sv_FI.WriteBelowFieldWordParameterToConfirmTheOperation : Translations_en_US.WriteBelowFieldWordParameterToConfirmTheOperation);
		Translations.FrontPage = culture === "fi" ? Translations_fi.FrontPage : (culture === "sv-FI" ? Translations_sv_FI.FrontPage : Translations_en_US.FrontPage);
		Translations.UserHasUsedMobile = culture === "fi" ? Translations_fi.UserHasUsedMobile : (culture === "sv-FI" ? Translations_sv_FI.UserHasUsedMobile : Translations_en_US.UserHasUsedMobile);
		Translations.Administrator = culture === "fi" ? Translations_fi.Administrator : (culture === "sv-FI" ? Translations_sv_FI.Administrator : Translations_en_US.Administrator);
		Translations.UserFilterInActive = culture === "fi" ? Translations_fi.UserFilterInActive : (culture === "sv-FI" ? Translations_sv_FI.UserFilterInActive : Translations_en_US.UserFilterInActive);
		Translations.MessageParameterNotFound = culture === "fi" ? Translations_fi.MessageParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.MessageParameterNotFound : Translations_en_US.MessageParameterNotFound);
		Translations.EditEventTypes = culture === "fi" ? Translations_fi.EditEventTypes : (culture === "sv-FI" ? Translations_sv_FI.EditEventTypes : Translations_en_US.EditEventTypes);
		Translations.IdCard = culture === "fi" ? Translations_fi.IdCard : (culture === "sv-FI" ? Translations_sv_FI.IdCard : Translations_en_US.IdCard);
		Translations.Users = culture === "fi" ? Translations_fi.Users : (culture === "sv-FI" ? Translations_sv_FI.Users : Translations_en_US.Users);
		Translations.RenewPersonalizationWithUser = culture === "fi" ? Translations_fi.RenewPersonalizationWithUser : (culture === "sv-FI" ? Translations_sv_FI.RenewPersonalizationWithUser : Translations_en_US.RenewPersonalizationWithUser);
		Translations.AccessRightWriteEvents = culture === "fi" ? Translations_fi.AccessRightWriteEvents : (culture === "sv-FI" ? Translations_sv_FI.AccessRightWriteEvents : Translations_en_US.AccessRightWriteEvents);
		Translations.AdditionalPermissionAMessage = culture === "fi" ? Translations_fi.AdditionalPermissionAMessage : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissionAMessage : Translations_en_US.AdditionalPermissionAMessage);
		Translations.SavingEventPartnerParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventPartnerParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventPartnerParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventPartnerParameterWasNotSuccessfulConcurrencyError);
		Translations.DocumentModified = culture === "fi" ? Translations_fi.DocumentModified : (culture === "sv-FI" ? Translations_sv_FI.DocumentModified : Translations_en_US.DocumentModified);
		Translations.DocumentNameParameterIsIllegal = culture === "fi" ? Translations_fi.DocumentNameParameterIsIllegal : (culture === "sv-FI" ? Translations_sv_FI.DocumentNameParameterIsIllegal : Translations_en_US.DocumentNameParameterIsIllegal);
		Translations.DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordFurtherEducationsParameterWasNotSuccessfulConcurrencyError);
		Translations.RestoringInstantMessageWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.RestoringInstantMessageWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.RestoringInstantMessageWasNotSuccessfulErrorParameter : Translations_en_US.RestoringInstantMessageWasNotSuccessfulErrorParameter);
		Translations.EditableDocumentUnpublishConfirmation = culture === "fi" ? Translations_fi.EditableDocumentUnpublishConfirmation : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentUnpublishConfirmation : Translations_en_US.EditableDocumentUnpublishConfirmation);
		Translations.SelectCompanyGroup = culture === "fi" ? Translations_fi.SelectCompanyGroup : (culture === "sv-FI" ? Translations_sv_FI.SelectCompanyGroup : Translations_en_US.SelectCompanyGroup);
		Translations.ActiveState = culture === "fi" ? Translations_fi.ActiveState : (culture === "sv-FI" ? Translations_sv_FI.ActiveState : Translations_en_US.ActiveState);
		Translations.TaskTypeCodeMustBeDefined = culture === "fi" ? Translations_fi.TaskTypeCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.TaskTypeCodeMustBeDefined : Translations_en_US.TaskTypeCodeMustBeDefined);
		Translations.DeletingInstantMessageUserParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingInstantMessageUserParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageUserParameterWasSuccessful : Translations_en_US.DeletingInstantMessageUserParameterWasSuccessful);
		Translations.BasicInformation = culture === "fi" ? Translations_fi.BasicInformation : (culture === "sv-FI" ? Translations_sv_FI.BasicInformation : Translations_en_US.BasicInformation);
		Translations.WorkShift = culture === "fi" ? Translations_fi.WorkShift : (culture === "sv-FI" ? Translations_sv_FI.WorkShift : Translations_en_US.WorkShift);
		Translations.InvalidValueInArgumentParameter = culture === "fi" ? Translations_fi.InvalidValueInArgumentParameter : (culture === "sv-FI" ? Translations_sv_FI.InvalidValueInArgumentParameter : Translations_en_US.InvalidValueInArgumentParameter);
		Translations.NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded = culture === "fi" ? Translations_fi.NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded : Translations_en_US.NewRecordUserMustBeSavedBeforeEmploymentsCanBeAdded);
		Translations.WhistleBlowSourceOral = culture === "fi" ? Translations_fi.WhistleBlowSourceOral : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSourceOral : Translations_en_US.WhistleBlowSourceOral);
		Translations.AddingTaskTypeParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingTaskTypeParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingTaskTypeParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddingTaskTypeParameterWasNotSuccessfulErrorParameter);
		Translations.WageGroup = culture === "fi" ? Translations_fi.WageGroup : (culture === "sv-FI" ? Translations_sv_FI.WageGroup : Translations_en_US.WageGroup);
		Translations.Valid = culture === "fi" ? Translations_fi.Valid : (culture === "sv-FI" ? Translations_sv_FI.Valid : Translations_en_US.Valid);
		Translations.Line = culture === "fi" ? Translations_fi.Line : (culture === "sv-FI" ? Translations_sv_FI.Line : Translations_en_US.Line);
		Translations.RecordUserLogEntryTypeSave = culture === "fi" ? Translations_fi.RecordUserLogEntryTypeSave : (culture === "sv-FI" ? Translations_sv_FI.RecordUserLogEntryTypeSave : Translations_en_US.RecordUserLogEntryTypeSave);
		Translations.ChangeSystemLanguageToEnglish = culture === "fi" ? Translations_fi.ChangeSystemLanguageToEnglish : (culture === "sv-FI" ? Translations_sv_FI.ChangeSystemLanguageToEnglish : Translations_en_US.ChangeSystemLanguageToEnglish);
		Translations.WhistleBlowInformantHint = culture === "fi" ? Translations_fi.WhistleBlowInformantHint : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantHint : Translations_en_US.WhistleBlowInformantHint);
		Translations.CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits = culture === "fi" ? Translations_fi.CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits : (culture === "sv-FI" ? Translations_sv_FI.CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits : Translations_en_US.CopyUnitDefaultHandlersAndNotifiedPersonsToAllSameLevelUnits);
		Translations.SavingCompanyParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingCompanyParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingCompanyParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingCompanyParameterWasNotSuccessfulErrorParameter);
		Translations.Intern = culture === "fi" ? Translations_fi.Intern : (culture === "sv-FI" ? Translations_sv_FI.Intern : Translations_en_US.Intern);
		Translations.UserGroupAll = culture === "fi" ? Translations_fi.UserGroupAll : (culture === "sv-FI" ? Translations_sv_FI.UserGroupAll : Translations_en_US.UserGroupAll);
		Translations.SavingEventTypeParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingEventTypeParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeParameterWasSuccessful : Translations_en_US.SavingEventTypeParameterWasSuccessful);
		Translations.SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToRestoreThisInstantMessage = culture === "fi" ? Translations_fi.DoYouReallyWantToRestoreThisInstantMessage : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRestoreThisInstantMessage : Translations_en_US.DoYouReallyWantToRestoreThisInstantMessage);
		Translations.AddPanel = culture === "fi" ? Translations_fi.AddPanel : (culture === "sv-FI" ? Translations_sv_FI.AddPanel : Translations_en_US.AddPanel);
		Translations.NotInUse = culture === "fi" ? Translations_fi.NotInUse : (culture === "sv-FI" ? Translations_sv_FI.NotInUse : Translations_en_US.NotInUse);
		Translations.CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.CheckingExistanceOfFolderParameterWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToUnlockTask = culture === "fi" ? Translations_fi.DoYouReallyWantToUnlockTask : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToUnlockTask : Translations_en_US.DoYouReallyWantToUnlockTask);
		Translations.SavingApplicationPermissionsWasSuccessful = culture === "fi" ? Translations_fi.SavingApplicationPermissionsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingApplicationPermissionsWasSuccessful : Translations_en_US.SavingApplicationPermissionsWasSuccessful);
		Translations.Competencies = culture === "fi" ? Translations_fi.Competencies : (culture === "sv-FI" ? Translations_sv_FI.Competencies : Translations_en_US.Competencies);
		Translations.EventUrgencyClass = culture === "fi" ? Translations_fi.EventUrgencyClass : (culture === "sv-FI" ? Translations_sv_FI.EventUrgencyClass : Translations_en_US.EventUrgencyClass);
		Translations.SavingEditableDocumentParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingEditableDocumentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEditableDocumentParameterWasSuccessful : Translations_en_US.SavingEditableDocumentParameterWasSuccessful);
		Translations.UserRecordStatisticsSavedCards = culture === "fi" ? Translations_fi.UserRecordStatisticsSavedCards : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsSavedCards : Translations_en_US.UserRecordStatisticsSavedCards);
		Translations.AllDocumentTypes = culture === "fi" ? Translations_fi.AllDocumentTypes : (culture === "sv-FI" ? Translations_sv_FI.AllDocumentTypes : Translations_en_US.AllDocumentTypes);
		Translations.SavePrintAs = culture === "fi" ? Translations_fi.SavePrintAs : (culture === "sv-FI" ? Translations_sv_FI.SavePrintAs : Translations_en_US.SavePrintAs);
		Translations.EmailingMessageWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.EmailingMessageWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.EmailingMessageWasNotSuccessfulErrorParameter : Translations_en_US.EmailingMessageWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToRemoveTaskParameterPermanenty = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveTaskParameterPermanenty : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveTaskParameterPermanenty : Translations_en_US.DoYouReallyWantToRemoveTaskParameterPermanenty);
		Translations.NewInstantMessageChannelGroup = culture === "fi" ? Translations_fi.NewInstantMessageChannelGroup : (culture === "sv-FI" ? Translations_sv_FI.NewInstantMessageChannelGroup : Translations_en_US.NewInstantMessageChannelGroup);
		Translations.FileRemovalWasSuccessful = culture === "fi" ? Translations_fi.FileRemovalWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.FileRemovalWasSuccessful : Translations_en_US.FileRemovalWasSuccessful);
		Translations.SavingRecordUserParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingRecordUserParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingRecordUserParameterWasNotSuccessfulConcurrencyError);
		Translations.FamiliarizationStartDateDateEnd = culture === "fi" ? Translations_fi.FamiliarizationStartDateDateEnd : (culture === "sv-FI" ? Translations_sv_FI.FamiliarizationStartDateDateEnd : Translations_en_US.FamiliarizationStartDateDateEnd);
		Translations.InUse = culture === "fi" ? Translations_fi.InUse : (culture === "sv-FI" ? Translations_sv_FI.InUse : Translations_en_US.InUse);
		Translations.MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter : Translations_en_US.MovingTaskParameterToTrashBinWasNotSuccessfulErrorParameter);
		Translations.Number = culture === "fi" ? Translations_fi.Number : (culture === "sv-FI" ? Translations_sv_FI.Number : Translations_en_US.Number);
		Translations.DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordUserEmploymentsParameterWasNotSuccessfulConcurrencyError);
		Translations.RecordUserLogEntryTypeReport = culture === "fi" ? Translations_fi.RecordUserLogEntryTypeReport : (culture === "sv-FI" ? Translations_sv_FI.RecordUserLogEntryTypeReport : Translations_en_US.RecordUserLogEntryTypeReport);
		Translations.Panels = culture === "fi" ? Translations_fi.Panels : (culture === "sv-FI" ? Translations_sv_FI.Panels : Translations_en_US.Panels);
		Translations.OthersLastModifications = culture === "fi" ? Translations_fi.OthersLastModifications : (culture === "sv-FI" ? Translations_sv_FI.OthersLastModifications : Translations_en_US.OthersLastModifications);
		Translations.DoYouReallyWantToRemoveSharedDocumentTemplateParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSharedDocumentTemplateParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSharedDocumentTemplateParameter : Translations_en_US.DoYouReallyWantToRemoveSharedDocumentTemplateParameter);
		Translations.EditDefaultHandlers = culture === "fi" ? Translations_fi.EditDefaultHandlers : (culture === "sv-FI" ? Translations_sv_FI.EditDefaultHandlers : Translations_en_US.EditDefaultHandlers);
		Translations.AddQualifications = culture === "fi" ? Translations_fi.AddQualifications : (culture === "sv-FI" ? Translations_sv_FI.AddQualifications : Translations_en_US.AddQualifications);
		Translations.DeletingFileFilterParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingFileFilterParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingFileFilterParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingFileFilterParameterWasNotSuccessfulErrorParameter);
		Translations.Add = culture === "fi" ? Translations_fi.Add : (culture === "sv-FI" ? Translations_sv_FI.Add : Translations_en_US.Add);
		Translations.EventUrgencyClassParameterNotFound = culture === "fi" ? Translations_fi.EventUrgencyClassParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EventUrgencyClassParameterNotFound : Translations_en_US.EventUrgencyClassParameterNotFound);
		Translations.EditEventTypeC = culture === "fi" ? Translations_fi.EditEventTypeC : (culture === "sv-FI" ? Translations_sv_FI.EditEventTypeC : Translations_en_US.EditEventTypeC);
		Translations.AddLogisticVoucherCustomer = culture === "fi" ? Translations_fi.AddLogisticVoucherCustomer : (culture === "sv-FI" ? Translations_sv_FI.AddLogisticVoucherCustomer : Translations_en_US.AddLogisticVoucherCustomer);
		Translations.SelectInstantMessageChannelType = culture === "fi" ? Translations_fi.SelectInstantMessageChannelType : (culture === "sv-FI" ? Translations_sv_FI.SelectInstantMessageChannelType : Translations_en_US.SelectInstantMessageChannelType);
		Translations.DoYouReallyWantToRemoveEventTypeBParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveEventTypeBParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveEventTypeBParameter : Translations_en_US.DoYouReallyWantToRemoveEventTypeBParameter);
		Translations.CompanyName = culture === "fi" ? Translations_fi.CompanyName : (culture === "sv-FI" ? Translations_sv_FI.CompanyName : Translations_en_US.CompanyName);
		Translations.RecordUserLogEntryTypeOpen = culture === "fi" ? Translations_fi.RecordUserLogEntryTypeOpen : (culture === "sv-FI" ? Translations_sv_FI.RecordUserLogEntryTypeOpen : Translations_en_US.RecordUserLogEntryTypeOpen);
		Translations.RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter : Translations_en_US.RemoveUserGroupExternalSystemsWasNotSuccessfulErrorParameter);
		Translations.AddPartnersByClickingPlusButton = culture === "fi" ? Translations_fi.AddPartnersByClickingPlusButton : (culture === "sv-FI" ? Translations_sv_FI.AddPartnersByClickingPlusButton : Translations_en_US.AddPartnersByClickingPlusButton);
		Translations.SavingChartWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingChartWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingChartWasNotSuccessfulConcurrencyError : Translations_en_US.SavingChartWasNotSuccessfulConcurrencyError);
		Translations.DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingTaskPriorityParameterWasNotSuccessfulErrorParameter);
		Translations.ContentFiltering = culture === "fi" ? Translations_fi.ContentFiltering : (culture === "sv-FI" ? Translations_sv_FI.ContentFiltering : Translations_en_US.ContentFiltering);
		Translations.ApproveEventResolution = culture === "fi" ? Translations_fi.ApproveEventResolution : (culture === "sv-FI" ? Translations_sv_FI.ApproveEventResolution : Translations_en_US.ApproveEventResolution);
		Translations.LockingEventParameterWasNotSuccessful = culture === "fi" ? Translations_fi.LockingEventParameterWasNotSuccessful : (culture === "sv-FI" ? Translations_sv_FI.LockingEventParameterWasNotSuccessful : Translations_en_US.LockingEventParameterWasNotSuccessful);
		Translations.Report = culture === "fi" ? Translations_fi.Report : (culture === "sv-FI" ? Translations_sv_FI.Report : Translations_en_US.Report);
		Translations.SourceFolderAndTargetFolderAreSame = culture === "fi" ? Translations_fi.SourceFolderAndTargetFolderAreSame : (culture === "sv-FI" ? Translations_sv_FI.SourceFolderAndTargetFolderAreSame : Translations_en_US.SourceFolderAndTargetFolderAreSame);
		Translations.SavingCompanyParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingCompanyParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingCompanyParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingCompanyParameterWasNotSuccessfulConcurrencyError);
		Translations.AddRecordUserWithoutUserAccount = culture === "fi" ? Translations_fi.AddRecordUserWithoutUserAccount : (culture === "sv-FI" ? Translations_sv_FI.AddRecordUserWithoutUserAccount : Translations_en_US.AddRecordUserWithoutUserAccount);
		Translations.WhistleBlowCategoryCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.WhistleBlowCategoryCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowCategoryCodeIsAlreadyInUse : Translations_en_US.WhistleBlowCategoryCodeIsAlreadyInUse);
		Translations.SavingChartWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingChartWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingChartWasNotSuccessfulErrorParameter : Translations_en_US.SavingChartWasNotSuccessfulErrorParameter);
		Translations.SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventDefaultHandlerParameterWasNotSuccessfulConcurrencyError);
		Translations.LockedTaskParameterCannotBeRestored = culture === "fi" ? Translations_fi.LockedTaskParameterCannotBeRestored : (culture === "sv-FI" ? Translations_sv_FI.LockedTaskParameterCannotBeRestored : Translations_en_US.LockedTaskParameterCannotBeRestored);
		Translations.UserFilterExpired = culture === "fi" ? Translations_fi.UserFilterExpired : (culture === "sv-FI" ? Translations_sv_FI.UserFilterExpired : Translations_en_US.UserFilterExpired);
		Translations.DoYouReallyWantToRemoveSelectedRecordBasicEducations = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedRecordBasicEducations : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedRecordBasicEducations : Translations_en_US.DoYouReallyWantToRemoveSelectedRecordBasicEducations);
		Translations.AddCompanyGroups = culture === "fi" ? Translations_fi.AddCompanyGroups : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyGroups : Translations_en_US.AddCompanyGroups);
		Translations.DoYouReallyWantToRemoveAttachmentParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveAttachmentParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveAttachmentParameter : Translations_en_US.DoYouReallyWantToRemoveAttachmentParameter);
		Translations.SavingOneTimePasswordWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingOneTimePasswordWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingOneTimePasswordWasNotSuccessfulConcurrencyError : Translations_en_US.SavingOneTimePasswordWasNotSuccessfulConcurrencyError);
		Translations.SaveEvent = culture === "fi" ? Translations_fi.SaveEvent : (culture === "sv-FI" ? Translations_sv_FI.SaveEvent : Translations_en_US.SaveEvent);
		Translations.Select = culture === "fi" ? Translations_fi.Select : (culture === "sv-FI" ? Translations_sv_FI.Select : Translations_en_US.Select);
		Translations.WhistleBlowNonAnonymousReportInfo = culture === "fi" ? Translations_fi.WhistleBlowNonAnonymousReportInfo : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowNonAnonymousReportInfo : Translations_en_US.WhistleBlowNonAnonymousReportInfo);
		Translations.AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter : Translations_en_US.AddingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter);
		Translations.EditLogisticVoucherPaymentSetting = culture === "fi" ? Translations_fi.EditLogisticVoucherPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.EditLogisticVoucherPaymentSetting : Translations_en_US.EditLogisticVoucherPaymentSetting);
		Translations.ReadImp = culture === "fi" ? Translations_fi.ReadImp : (culture === "sv-FI" ? Translations_sv_FI.ReadImp : Translations_en_US.ReadImp);
		Translations.AddKeyword = culture === "fi" ? Translations_fi.AddKeyword : (culture === "sv-FI" ? Translations_sv_FI.AddKeyword : Translations_en_US.AddKeyword);
		Translations.DocumentRootPath = culture === "fi" ? Translations_fi.DocumentRootPath : (culture === "sv-FI" ? Translations_sv_FI.DocumentRootPath : Translations_en_US.DocumentRootPath);
		Translations.DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingAttachmentsParameterWasNotSuccessfulConcurrencyError);
		Translations.UserRecordStatisticsExpiredQualifications = culture === "fi" ? Translations_fi.UserRecordStatisticsExpiredQualifications : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsExpiredQualifications : Translations_en_US.UserRecordStatisticsExpiredQualifications);
		Translations.PropertyTransferred = culture === "fi" ? Translations_fi.PropertyTransferred : (culture === "sv-FI" ? Translations_sv_FI.PropertyTransferred : Translations_en_US.PropertyTransferred);
		Translations.Images = culture === "fi" ? Translations_fi.Images : (culture === "sv-FI" ? Translations_sv_FI.Images : Translations_en_US.Images);
		Translations.CompanyCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.CompanyCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.CompanyCodeIsAlreadyInUse : Translations_en_US.CompanyCodeIsAlreadyInUse);
		Translations.ToDo = culture === "fi" ? Translations_fi.ToDo : (culture === "sv-FI" ? Translations_sv_FI.ToDo : Translations_en_US.ToDo);
		Translations.DeletingRecordUserTransferredPropertyParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordUserTransferredPropertyParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserTransferredPropertyParameterWasSuccessful : Translations_en_US.DeletingRecordUserTransferredPropertyParameterWasSuccessful);
		Translations.CreatingNotifications = culture === "fi" ? Translations_fi.CreatingNotifications : (culture === "sv-FI" ? Translations_sv_FI.CreatingNotifications : Translations_en_US.CreatingNotifications);
		Translations.RemoveRemainder = culture === "fi" ? Translations_fi.RemoveRemainder : (culture === "sv-FI" ? Translations_sv_FI.RemoveRemainder : Translations_en_US.RemoveRemainder);
		Translations.Events = culture === "fi" ? Translations_fi.Events : (culture === "sv-FI" ? Translations_sv_FI.Events : Translations_en_US.Events);
		Translations.EditableDocumentApproveConfirmation = culture === "fi" ? Translations_fi.EditableDocumentApproveConfirmation : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentApproveConfirmation : Translations_en_US.EditableDocumentApproveConfirmation);
		Translations.DocumentTemplates = culture === "fi" ? Translations_fi.DocumentTemplates : (culture === "sv-FI" ? Translations_sv_FI.DocumentTemplates : Translations_en_US.DocumentTemplates);
		Translations.SavingWhistleBlowWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingWhistleBlowWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowWasNotSuccessfulErrorParameter : Translations_en_US.SavingWhistleBlowWasNotSuccessfulErrorParameter);
		Translations.Theme = culture === "fi" ? Translations_fi.Theme : (culture === "sv-FI" ? Translations_sv_FI.Theme : Translations_en_US.Theme);
		Translations.AddUsers = culture === "fi" ? Translations_fi.AddUsers : (culture === "sv-FI" ? Translations_sv_FI.AddUsers : Translations_en_US.AddUsers);
		Translations.SaveUserFurtherEducation = culture === "fi" ? Translations_fi.SaveUserFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.SaveUserFurtherEducation : Translations_en_US.SaveUserFurtherEducation);
		Translations.DeletingMessageParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingMessageParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingMessageParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingMessageParameterWasNotSuccessfulErrorParameter);
		Translations.Open = culture === "fi" ? Translations_fi.Open : (culture === "sv-FI" ? Translations_sv_FI.Open : Translations_en_US.Open);
		Translations.DeletingTaskPriorityParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingTaskPriorityParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskPriorityParameterWasSuccessful : Translations_en_US.DeletingTaskPriorityParameterWasSuccessful);
		Translations.DoYouReallyWantToRemoveSelectedEventUrgencyClasses = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedEventUrgencyClasses : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedEventUrgencyClasses : Translations_en_US.DoYouReallyWantToRemoveSelectedEventUrgencyClasses);
		Translations.SavingImagesNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingImagesNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingImagesNotSuccessfulErrorParameter : Translations_en_US.SavingImagesNotSuccessfulErrorParameter);
		Translations.City = culture === "fi" ? Translations_fi.City : (culture === "sv-FI" ? Translations_sv_FI.City : Translations_en_US.City);
		Translations.SavingUserParametersWasSuccessful = culture === "fi" ? Translations_fi.SavingUserParametersWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParametersWasSuccessful : Translations_en_US.SavingUserParametersWasSuccessful);
		Translations.ShowInheritedPermissions = culture === "fi" ? Translations_fi.ShowInheritedPermissions : (culture === "sv-FI" ? Translations_sv_FI.ShowInheritedPermissions : Translations_en_US.ShowInheritedPermissions);
		Translations.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulErrorParameter);
		Translations.WhistleBlowNewReport = culture === "fi" ? Translations_fi.WhistleBlowNewReport : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowNewReport : Translations_en_US.WhistleBlowNewReport);
		Translations.UnitHandlingIncomingEvents = culture === "fi" ? Translations_fi.UnitHandlingIncomingEvents : (culture === "sv-FI" ? Translations_sv_FI.UnitHandlingIncomingEvents : Translations_en_US.UnitHandlingIncomingEvents);
		Translations.ExpiredFurtherEducations = culture === "fi" ? Translations_fi.ExpiredFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.ExpiredFurtherEducations : Translations_en_US.ExpiredFurtherEducations);
		Translations.SaveAndSend = culture === "fi" ? Translations_fi.SaveAndSend : (culture === "sv-FI" ? Translations_sv_FI.SaveAndSend : Translations_en_US.SaveAndSend);
		Translations.DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingTaskTypesParameterWasNotSuccessfulConcurrencyError);
		Translations.FurtherEducationsMustBeDefinedBeforeAddingRecordUsers = culture === "fi" ? Translations_fi.FurtherEducationsMustBeDefinedBeforeAddingRecordUsers : (culture === "sv-FI" ? Translations_sv_FI.FurtherEducationsMustBeDefinedBeforeAddingRecordUsers : Translations_en_US.FurtherEducationsMustBeDefinedBeforeAddingRecordUsers);
		Translations.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter);
		Translations.AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter);
		Translations.DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingTaskStatesParameterWasNotSuccessfulConcurrencyError);
		Translations.Familiarization = culture === "fi" ? Translations_fi.Familiarization : (culture === "sv-FI" ? Translations_sv_FI.Familiarization : Translations_en_US.Familiarization);
		Translations.Draft = culture === "fi" ? Translations_fi.Draft : (culture === "sv-FI" ? Translations_sv_FI.Draft : Translations_en_US.Draft);
		Translations.JobTypeEventNotification = culture === "fi" ? Translations_fi.JobTypeEventNotification : (culture === "sv-FI" ? Translations_sv_FI.JobTypeEventNotification : Translations_en_US.JobTypeEventNotification);
		Translations.DateMustBeDefined = culture === "fi" ? Translations_fi.DateMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.DateMustBeDefined : Translations_en_US.DateMustBeDefined);
		Translations.SetPermissions = culture === "fi" ? Translations_fi.SetPermissions : (culture === "sv-FI" ? Translations_sv_FI.SetPermissions : Translations_en_US.SetPermissions);
		Translations.WhistleBlowStateReceived = culture === "fi" ? Translations_fi.WhistleBlowStateReceived : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStateReceived : Translations_en_US.WhistleBlowStateReceived);
		Translations.GroupByPerson = culture === "fi" ? Translations_fi.GroupByPerson : (culture === "sv-FI" ? Translations_sv_FI.GroupByPerson : Translations_en_US.GroupByPerson);
		Translations.EventTypeCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.EventTypeCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCodeIsAlreadyInUse : Translations_en_US.EventTypeCodeIsAlreadyInUse);
		Translations.DoYouReallyWantToRemoveFolderParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveFolderParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveFolderParameter : Translations_en_US.DoYouReallyWantToRemoveFolderParameter);
		Translations.AuthorizationLevelSuperUser = culture === "fi" ? Translations_fi.AuthorizationLevelSuperUser : (culture === "sv-FI" ? Translations_sv_FI.AuthorizationLevelSuperUser : Translations_en_US.AuthorizationLevelSuperUser);
		Translations.DoYouReallyWantToRemovePartnersParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemovePartnersParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemovePartnersParameter : Translations_en_US.DoYouReallyWantToRemovePartnersParameter);
		Translations.Print = culture === "fi" ? Translations_fi.Print : (culture === "sv-FI" ? Translations_sv_FI.Print : Translations_en_US.Print);
		Translations.DoYouReallyWantToRemoveRemainderParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveRemainderParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveRemainderParameter : Translations_en_US.DoYouReallyWantToRemoveRemainderParameter);
		Translations.FamiliarizationStartDate = culture === "fi" ? Translations_fi.FamiliarizationStartDate : (culture === "sv-FI" ? Translations_sv_FI.FamiliarizationStartDate : Translations_en_US.FamiliarizationStartDate);
		Translations.SearchCurrentTopics = culture === "fi" ? Translations_fi.SearchCurrentTopics : (culture === "sv-FI" ? Translations_sv_FI.SearchCurrentTopics : Translations_en_US.SearchCurrentTopics);
		Translations.DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordFamiliarizationsParameterWasNotSuccessfulErrorParameter);
		Translations.RemoveFolder = culture === "fi" ? Translations_fi.RemoveFolder : (culture === "sv-FI" ? Translations_sv_FI.RemoveFolder : Translations_en_US.RemoveFolder);
		Translations.ComparingFileSystemToDatabase = culture === "fi" ? Translations_fi.ComparingFileSystemToDatabase : (culture === "sv-FI" ? Translations_sv_FI.ComparingFileSystemToDatabase : Translations_en_US.ComparingFileSystemToDatabase);
		Translations.SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingRecordBasicEducationParameterWasNotSuccessfulErrorParameter);
		Translations.MissingPlural = culture === "fi" ? Translations_fi.MissingPlural : (culture === "sv-FI" ? Translations_sv_FI.MissingPlural : Translations_en_US.MissingPlural);
		Translations.DeletingOneTimePasswordWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingOneTimePasswordWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingOneTimePasswordWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingOneTimePasswordWasNotSuccessfulConcurrencyError);
		Translations.NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded = culture === "fi" ? Translations_fi.NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded : Translations_en_US.NewEmploymentMustBeSavedBeforeAttachmentsCanBeAdded);
		Translations.UserRecordStatisticsExpiredFurtherEducations = culture === "fi" ? Translations_fi.UserRecordStatisticsExpiredFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsExpiredFurtherEducations : Translations_en_US.UserRecordStatisticsExpiredFurtherEducations);
		Translations.RecordBasicEducationParameterNotFound = culture === "fi" ? Translations_fi.RecordBasicEducationParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordBasicEducationParameterNotFound : Translations_en_US.RecordBasicEducationParameterNotFound);
		Translations.AdditionalPermissionAEvents = culture === "fi" ? Translations_fi.AdditionalPermissionAEvents : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissionAEvents : Translations_en_US.AdditionalPermissionAEvents);
		Translations.EInvoiceOperator = culture === "fi" ? Translations_fi.EInvoiceOperator : (culture === "sv-FI" ? Translations_sv_FI.EInvoiceOperator : Translations_en_US.EInvoiceOperator);
		Translations.AccountNumberTkbOutDebit = culture === "fi" ? Translations_fi.AccountNumberTkbOutDebit : (culture === "sv-FI" ? Translations_sv_FI.AccountNumberTkbOutDebit : Translations_en_US.AccountNumberTkbOutDebit);
		Translations.Settings = culture === "fi" ? Translations_fi.Settings : (culture === "sv-FI" ? Translations_sv_FI.Settings : Translations_en_US.Settings);
		Translations.FileFilterCodeMustBeDefined = culture === "fi" ? Translations_fi.FileFilterCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.FileFilterCodeMustBeDefined : Translations_en_US.FileFilterCodeMustBeDefined);
		Translations.DoYouReallyWantToRemoveChartPanelParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveChartPanelParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveChartPanelParameter : Translations_en_US.DoYouReallyWantToRemoveChartPanelParameter);
		Translations.ClosedEvent = culture === "fi" ? Translations_fi.ClosedEvent : (culture === "sv-FI" ? Translations_sv_FI.ClosedEvent : Translations_en_US.ClosedEvent);
		Translations.FamiliarizationTime = culture === "fi" ? Translations_fi.FamiliarizationTime : (culture === "sv-FI" ? Translations_sv_FI.FamiliarizationTime : Translations_en_US.FamiliarizationTime);
		Translations.AddPartnerParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.AddPartnerParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.AddPartnerParameterWasNotSuccessfulConcurrencyError : Translations_en_US.AddPartnerParameterWasNotSuccessfulConcurrencyError);
		Translations.EmailSendDate = culture === "fi" ? Translations_fi.EmailSendDate : (culture === "sv-FI" ? Translations_sv_FI.EmailSendDate : Translations_en_US.EmailSendDate);
		Translations.Employment = culture === "fi" ? Translations_fi.Employment : (culture === "sv-FI" ? Translations_sv_FI.Employment : Translations_en_US.Employment);
		Translations.DeletingKeywordParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingKeywordParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingKeywordParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingKeywordParameterWasNotSuccessfulErrorParameter);
		Translations.PageFooterText = culture === "fi" ? Translations_fi.PageFooterText : (culture === "sv-FI" ? Translations_sv_FI.PageFooterText : Translations_en_US.PageFooterText);
		Translations.InstantMessageChannelUsers = culture === "fi" ? Translations_fi.InstantMessageChannelUsers : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelUsers : Translations_en_US.InstantMessageChannelUsers);
		Translations.DeletingPartnersParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingPartnersParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingPartnersParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingPartnersParameterWasNotSuccessfulConcurrencyError);
		Translations.TaskPriorities = culture === "fi" ? Translations_fi.TaskPriorities : (culture === "sv-FI" ? Translations_sv_FI.TaskPriorities : Translations_en_US.TaskPriorities);
		Translations.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulConcurrencyError);
		Translations.EditMode = culture === "fi" ? Translations_fi.EditMode : (culture === "sv-FI" ? Translations_sv_FI.EditMode : Translations_en_US.EditMode);
		Translations.NotificationsSentSuccessfully = culture === "fi" ? Translations_fi.NotificationsSentSuccessfully : (culture === "sv-FI" ? Translations_sv_FI.NotificationsSentSuccessfully : Translations_en_US.NotificationsSentSuccessfully);
		Translations.DoYouReallyWantToRemoveTaskDataRow = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveTaskDataRow : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveTaskDataRow : Translations_en_US.DoYouReallyWantToRemoveTaskDataRow);
		Translations.CompanyGroupCodeMustBeDefined = culture === "fi" ? Translations_fi.CompanyGroupCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.CompanyGroupCodeMustBeDefined : Translations_en_US.CompanyGroupCodeMustBeDefined);
		Translations.DoYouReallyWantToRemoveTaskTypeParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveTaskTypeParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveTaskTypeParameter : Translations_en_US.DoYouReallyWantToRemoveTaskTypeParameter);
		Translations.FileParameterDownloaded = culture === "fi" ? Translations_fi.FileParameterDownloaded : (culture === "sv-FI" ? Translations_sv_FI.FileParameterDownloaded : Translations_en_US.FileParameterDownloaded);
		Translations.AddPartnerCompanies = culture === "fi" ? Translations_fi.AddPartnerCompanies : (culture === "sv-FI" ? Translations_sv_FI.AddPartnerCompanies : Translations_en_US.AddPartnerCompanies);
		Translations.RemoveRecordUserAndUserSetExpired = culture === "fi" ? Translations_fi.RemoveRecordUserAndUserSetExpired : (culture === "sv-FI" ? Translations_sv_FI.RemoveRecordUserAndUserSetExpired : Translations_en_US.RemoveRecordUserAndUserSetExpired);
		Translations.SavingUnitPermissionsWasSuccessful = culture === "fi" ? Translations_fi.SavingUnitPermissionsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingUnitPermissionsWasSuccessful : Translations_en_US.SavingUnitPermissionsWasSuccessful);
		Translations.SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError);
		Translations.Companies = culture === "fi" ? Translations_fi.Companies : (culture === "sv-FI" ? Translations_sv_FI.Companies : Translations_en_US.Companies);
		Translations.NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded = culture === "fi" ? Translations_fi.NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded : Translations_en_US.NewRecordUserMustBeSavedBeforeFamiliarizationsCanBeAdded);
		Translations.DoYouReallyWantToRemoveEventUrgencyClassParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveEventUrgencyClassParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveEventUrgencyClassParameter : Translations_en_US.DoYouReallyWantToRemoveEventUrgencyClassParameter);
		Translations.SavingWhistleBlowWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingWhistleBlowWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowWasNotSuccessfulConcurrencyError : Translations_en_US.SavingWhistleBlowWasNotSuccessfulConcurrencyError);
		Translations.ThisPartOfApplicationIsNotInUse = culture === "fi" ? Translations_fi.ThisPartOfApplicationIsNotInUse : (culture === "sv-FI" ? Translations_sv_FI.ThisPartOfApplicationIsNotInUse : Translations_en_US.ThisPartOfApplicationIsNotInUse);
		Translations.TimeSpanHalfYear = culture === "fi" ? Translations_fi.TimeSpanHalfYear : (culture === "sv-FI" ? Translations_sv_FI.TimeSpanHalfYear : Translations_en_US.TimeSpanHalfYear);
		Translations.EnableExternalIntraSystemConfirmation = culture === "fi" ? Translations_fi.EnableExternalIntraSystemConfirmation : (culture === "sv-FI" ? Translations_sv_FI.EnableExternalIntraSystemConfirmation : Translations_en_US.EnableExternalIntraSystemConfirmation);
		Translations.AccessRightReadEvents = culture === "fi" ? Translations_fi.AccessRightReadEvents : (culture === "sv-FI" ? Translations_sv_FI.AccessRightReadEvents : Translations_en_US.AccessRightReadEvents);
		Translations.Priority = culture === "fi" ? Translations_fi.Priority : (culture === "sv-FI" ? Translations_sv_FI.Priority : Translations_en_US.Priority);
		Translations.DocumentCreated = culture === "fi" ? Translations_fi.DocumentCreated : (culture === "sv-FI" ? Translations_sv_FI.DocumentCreated : Translations_en_US.DocumentCreated);
		Translations.TaskStatesMustBeDefinedBeforeAddingTasks = culture === "fi" ? Translations_fi.TaskStatesMustBeDefinedBeforeAddingTasks : (culture === "sv-FI" ? Translations_sv_FI.TaskStatesMustBeDefinedBeforeAddingTasks : Translations_en_US.TaskStatesMustBeDefinedBeforeAddingTasks);
		Translations.PrivacyPolicy = culture === "fi" ? Translations_fi.PrivacyPolicy : (culture === "sv-FI" ? Translations_sv_FI.PrivacyPolicy : Translations_en_US.PrivacyPolicy);
		Translations.Keywords = culture === "fi" ? Translations_fi.Keywords : (culture === "sv-FI" ? Translations_sv_FI.Keywords : Translations_en_US.Keywords);
		Translations.DoYouReallyWantToRemoveKeywordParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveKeywordParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveKeywordParameter : Translations_en_US.DoYouReallyWantToRemoveKeywordParameter);
		Translations.DefaultLanguageHasBeenChanged = culture === "fi" ? Translations_fi.DefaultLanguageHasBeenChanged : (culture === "sv-FI" ? Translations_sv_FI.DefaultLanguageHasBeenChanged : Translations_en_US.DefaultLanguageHasBeenChanged);
		Translations.DeletingEventParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingEventParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventParameterWasSuccessful : Translations_en_US.DeletingEventParameterWasSuccessful);
		Translations.DefaultSettings = culture === "fi" ? Translations_fi.DefaultSettings : (culture === "sv-FI" ? Translations_sv_FI.DefaultSettings : Translations_en_US.DefaultSettings);
		Translations.CompanyGroupModuleProperties = culture === "fi" ? Translations_fi.CompanyGroupModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.CompanyGroupModuleProperties : Translations_en_US.CompanyGroupModuleProperties);
		Translations.WhistleBlowNotFound = culture === "fi" ? Translations_fi.WhistleBlowNotFound : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowNotFound : Translations_en_US.WhistleBlowNotFound);
		Translations.DoYouReallyWantToRemoveMessageTypeParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveMessageTypeParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveMessageTypeParameter : Translations_en_US.DoYouReallyWantToRemoveMessageTypeParameter);
		Translations.StateOpen = culture === "fi" ? Translations_fi.StateOpen : (culture === "sv-FI" ? Translations_sv_FI.StateOpen : Translations_en_US.StateOpen);
		Translations.CompanyGroupParameterNotFound = culture === "fi" ? Translations_fi.CompanyGroupParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.CompanyGroupParameterNotFound : Translations_en_US.CompanyGroupParameterNotFound);
		Translations.DeletingTaskTypesParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingTaskTypesParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskTypesParameterWasSuccessful : Translations_en_US.DeletingTaskTypesParameterWasSuccessful);
		Translations.JobTitle = culture === "fi" ? Translations_fi.JobTitle : (culture === "sv-FI" ? Translations_sv_FI.JobTitle : Translations_en_US.JobTitle);
		Translations.RemoveFile = culture === "fi" ? Translations_fi.RemoveFile : (culture === "sv-FI" ? Translations_sv_FI.RemoveFile : Translations_en_US.RemoveFile);
		Translations.SaveMessage = culture === "fi" ? Translations_fi.SaveMessage : (culture === "sv-FI" ? Translations_sv_FI.SaveMessage : Translations_en_US.SaveMessage);
		Translations.AdministratorPasswordMustBeDefined = culture === "fi" ? Translations_fi.AdministratorPasswordMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.AdministratorPasswordMustBeDefined : Translations_en_US.AdministratorPasswordMustBeDefined);
		Translations.ExternalSystemCodeClaimNotFound = culture === "fi" ? Translations_fi.ExternalSystemCodeClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemCodeClaimNotFound : Translations_en_US.ExternalSystemCodeClaimNotFound);
		Translations.DoYouReallyWantToRemoveSelectedHandlers = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedHandlers : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedHandlers : Translations_en_US.DoYouReallyWantToRemoveSelectedHandlers);
		Translations.DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError);
		Translations.LogCleaning = culture === "fi" ? Translations_fi.LogCleaning : (culture === "sv-FI" ? Translations_sv_FI.LogCleaning : Translations_en_US.LogCleaning);
		Translations.EventsFound = culture === "fi" ? Translations_fi.EventsFound : (culture === "sv-FI" ? Translations_sv_FI.EventsFound : Translations_en_US.EventsFound);
		Translations.Change = culture === "fi" ? Translations_fi.Change : (culture === "sv-FI" ? Translations_sv_FI.Change : Translations_en_US.Change);
		Translations.ExitingInstantMessageChannelWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.ExitingInstantMessageChannelWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.ExitingInstantMessageChannelWasNotSuccessfulErrorParameter : Translations_en_US.ExitingInstantMessageChannelWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToRemoveEditableDocumentParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveEditableDocumentParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveEditableDocumentParameter : Translations_en_US.DoYouReallyWantToRemoveEditableDocumentParameter);
		Translations.Archive = culture === "fi" ? Translations_fi.Archive : (culture === "sv-FI" ? Translations_sv_FI.Archive : Translations_en_US.Archive);
		Translations.Empty = culture === "fi" ? Translations_fi.Empty : (culture === "sv-FI" ? Translations_sv_FI.Empty : Translations_en_US.Empty);
		Translations.Resources = culture === "fi" ? Translations_fi.Resources : (culture === "sv-FI" ? Translations_sv_FI.Resources : Translations_en_US.Resources);
		Translations.AccessRightWriteWhistleBlow = culture === "fi" ? Translations_fi.AccessRightWriteWhistleBlow : (culture === "sv-FI" ? Translations_sv_FI.AccessRightWriteWhistleBlow : Translations_en_US.AccessRightWriteWhistleBlow);
		Translations.LockedDocumentParameterCannotBeMoved = culture === "fi" ? Translations_fi.LockedDocumentParameterCannotBeMoved : (culture === "sv-FI" ? Translations_sv_FI.LockedDocumentParameterCannotBeMoved : Translations_en_US.LockedDocumentParameterCannotBeMoved);
		Translations.SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingDocumentTemplateParameterWasNotSuccessfulErrorParameter);
		Translations.SavingReadReceiptWasSuccessful = culture === "fi" ? Translations_fi.SavingReadReceiptWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingReadReceiptWasSuccessful : Translations_en_US.SavingReadReceiptWasSuccessful);
		Translations.NoTasksToShow = culture === "fi" ? Translations_fi.NoTasksToShow : (culture === "sv-FI" ? Translations_sv_FI.NoTasksToShow : Translations_en_US.NoTasksToShow);
		Translations.AllInstantMessageUsersHaveReadMessage = culture === "fi" ? Translations_fi.AllInstantMessageUsersHaveReadMessage : (culture === "sv-FI" ? Translations_sv_FI.AllInstantMessageUsersHaveReadMessage : Translations_en_US.AllInstantMessageUsersHaveReadMessage);
		Translations.DeletingTaskStatesParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingTaskStatesParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskStatesParameterWasSuccessful : Translations_en_US.DeletingTaskStatesParameterWasSuccessful);
		Translations.Cancel = culture === "fi" ? Translations_fi.Cancel : (culture === "sv-FI" ? Translations_sv_FI.Cancel : Translations_en_US.Cancel);
		Translations.AdditionalPermissionBEvents = culture === "fi" ? Translations_fi.AdditionalPermissionBEvents : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissionBEvents : Translations_en_US.AdditionalPermissionBEvents);
		Translations.ReportDialogInfoText = culture === "fi" ? Translations_fi.ReportDialogInfoText : (culture === "sv-FI" ? Translations_sv_FI.ReportDialogInfoText : Translations_en_US.ReportDialogInfoText);
		Translations.Continue = culture === "fi" ? Translations_fi.Continue : (culture === "sv-FI" ? Translations_sv_FI.Continue : Translations_en_US.Continue);
		Translations.TaskDataRowParameterNotFound = culture === "fi" ? Translations_fi.TaskDataRowParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.TaskDataRowParameterNotFound : Translations_en_US.TaskDataRowParameterNotFound);
		Translations.RemovingEventHandlerWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.RemovingEventHandlerWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.RemovingEventHandlerWasNotSuccessfulErrorParameter : Translations_en_US.RemovingEventHandlerWasNotSuccessfulErrorParameter);
		Translations.RemoveDefaultBasicEducation = culture === "fi" ? Translations_fi.RemoveDefaultBasicEducation : (culture === "sv-FI" ? Translations_sv_FI.RemoveDefaultBasicEducation : Translations_en_US.RemoveDefaultBasicEducation);
		Translations.VoucherInOutReportSavePath = culture === "fi" ? Translations_fi.VoucherInOutReportSavePath : (culture === "sv-FI" ? Translations_sv_FI.VoucherInOutReportSavePath : Translations_en_US.VoucherInOutReportSavePath);
		Translations.InstantMessageChannelUserParameterNotFound = culture === "fi" ? Translations_fi.InstantMessageChannelUserParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelUserParameterNotFound : Translations_en_US.InstantMessageChannelUserParameterNotFound);
		Translations.EditMessageType = culture === "fi" ? Translations_fi.EditMessageType : (culture === "sv-FI" ? Translations_sv_FI.EditMessageType : Translations_en_US.EditMessageType);
		Translations.SearchProperties = culture === "fi" ? Translations_fi.SearchProperties : (culture === "sv-FI" ? Translations_sv_FI.SearchProperties : Translations_en_US.SearchProperties);
		Translations.AllUnits = culture === "fi" ? Translations_fi.AllUnits : (culture === "sv-FI" ? Translations_sv_FI.AllUnits : Translations_en_US.AllUnits);
		Translations.EventTypesGeneric = culture === "fi" ? Translations_fi.EventTypesGeneric : (culture === "sv-FI" ? Translations_sv_FI.EventTypesGeneric : Translations_en_US.EventTypesGeneric);
		Translations.SavingTaskStateParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingTaskStateParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskStateParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingTaskStateParameterWasNotSuccessfulConcurrencyError);
		Translations.ChangeSystemLanguageToFinnish = culture === "fi" ? Translations_fi.ChangeSystemLanguageToFinnish : (culture === "sv-FI" ? Translations_sv_FI.ChangeSystemLanguageToFinnish : Translations_en_US.ChangeSystemLanguageToFinnish);
		Translations.SavingRecordEmploymentParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingRecordEmploymentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordEmploymentParameterWasSuccessful : Translations_en_US.SavingRecordEmploymentParameterWasSuccessful);
		Translations.Folders = culture === "fi" ? Translations_fi.Folders : (culture === "sv-FI" ? Translations_sv_FI.Folders : Translations_en_US.Folders);
		Translations.RequireReadReceipt = culture === "fi" ? Translations_fi.RequireReadReceipt : (culture === "sv-FI" ? Translations_sv_FI.RequireReadReceipt : Translations_en_US.RequireReadReceipt);
		Translations.Required = culture === "fi" ? Translations_fi.Required : (culture === "sv-FI" ? Translations_sv_FI.Required : Translations_en_US.Required);
		Translations.UnreadMessagesParameter = culture === "fi" ? Translations_fi.UnreadMessagesParameter : (culture === "sv-FI" ? Translations_sv_FI.UnreadMessagesParameter : Translations_en_US.UnreadMessagesParameter);
		Translations.WhistleBlowArchivingTypeChangeState = culture === "fi" ? Translations_fi.WhistleBlowArchivingTypeChangeState : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowArchivingTypeChangeState : Translations_en_US.WhistleBlowArchivingTypeChangeState);
		Translations.DoYouReallyWantToRemoveCompanyGroupParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveCompanyGroupParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveCompanyGroupParameter : Translations_en_US.DoYouReallyWantToRemoveCompanyGroupParameter);
		Translations.AddToHomeScreen = culture === "fi" ? Translations_fi.AddToHomeScreen : (culture === "sv-FI" ? Translations_sv_FI.AddToHomeScreen : Translations_en_US.AddToHomeScreen);
		Translations.ForwardMessage = culture === "fi" ? Translations_fi.ForwardMessage : (culture === "sv-FI" ? Translations_sv_FI.ForwardMessage : Translations_en_US.ForwardMessage);
		Translations.Files = culture === "fi" ? Translations_fi.Files : (culture === "sv-FI" ? Translations_sv_FI.Files : Translations_en_US.Files);
		Translations.WhistleBlowCreateTime = culture === "fi" ? Translations_fi.WhistleBlowCreateTime : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowCreateTime : Translations_en_US.WhistleBlowCreateTime);
		Translations.DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingEventTypeCParameterWasNotSuccessfulConcurrencyError);
		Translations.ModifiedActions = culture === "fi" ? Translations_fi.ModifiedActions : (culture === "sv-FI" ? Translations_sv_FI.ModifiedActions : Translations_en_US.ModifiedActions);
		Translations.ChartProperties = culture === "fi" ? Translations_fi.ChartProperties : (culture === "sv-FI" ? Translations_sv_FI.ChartProperties : Translations_en_US.ChartProperties);
		Translations.EditableDocumentPublishConfirmation = culture === "fi" ? Translations_fi.EditableDocumentPublishConfirmation : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentPublishConfirmation : Translations_en_US.EditableDocumentPublishConfirmation);
		Translations.Password = culture === "fi" ? Translations_fi.Password : (culture === "sv-FI" ? Translations_sv_FI.Password : Translations_en_US.Password);
		Translations.RemoveLogisticTransportCompany = culture === "fi" ? Translations_fi.RemoveLogisticTransportCompany : (culture === "sv-FI" ? Translations_sv_FI.RemoveLogisticTransportCompany : Translations_en_US.RemoveLogisticTransportCompany);
		Translations.DoYouReallyWantToPublishTaskTypeConfirmation = culture === "fi" ? Translations_fi.DoYouReallyWantToPublishTaskTypeConfirmation : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToPublishTaskTypeConfirmation : Translations_en_US.DoYouReallyWantToPublishTaskTypeConfirmation);
		Translations.SavingTaskDataRowWasSuccessful = culture === "fi" ? Translations_fi.SavingTaskDataRowWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskDataRowWasSuccessful : Translations_en_US.SavingTaskDataRowWasSuccessful);
		Translations.AddCompanyToCompanyGroup = culture === "fi" ? Translations_fi.AddCompanyToCompanyGroup : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyToCompanyGroup : Translations_en_US.AddCompanyToCompanyGroup);
		Translations.AddingAddRecordUserFurtherEducationsWasSuccessful = culture === "fi" ? Translations_fi.AddingAddRecordUserFurtherEducationsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingAddRecordUserFurtherEducationsWasSuccessful : Translations_en_US.AddingAddRecordUserFurtherEducationsWasSuccessful);
		Translations.EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted = culture === "fi" ? Translations_fi.EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted : Translations_en_US.EventUrgencyClassParameterIsInUseInParameterEventsAndCannotBeDeleted);
		Translations.RemoveEmployment = culture === "fi" ? Translations_fi.RemoveEmployment : (culture === "sv-FI" ? Translations_sv_FI.RemoveEmployment : Translations_en_US.RemoveEmployment);
		Translations.UserCodeClaimNotFound = culture === "fi" ? Translations_fi.UserCodeClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.UserCodeClaimNotFound : Translations_en_US.UserCodeClaimNotFound);
		Translations.DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordFurtherEducationsParameterWasNotSuccessfulErrorParameter);
		Translations.TimeSpanYear = culture === "fi" ? Translations_fi.TimeSpanYear : (culture === "sv-FI" ? Translations_sv_FI.TimeSpanYear : Translations_en_US.TimeSpanYear);
		Translations.EditableDocumentCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.EditableDocumentCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentCodeIsAlreadyInUse : Translations_en_US.EditableDocumentCodeIsAlreadyInUse);
		Translations.PrintTask = culture === "fi" ? Translations_fi.PrintTask : (culture === "sv-FI" ? Translations_sv_FI.PrintTask : Translations_en_US.PrintTask);
		Translations.NewComment = culture === "fi" ? Translations_fi.NewComment : (culture === "sv-FI" ? Translations_sv_FI.NewComment : Translations_en_US.NewComment);
		Translations.Log = culture === "fi" ? Translations_fi.Log : (culture === "sv-FI" ? Translations_sv_FI.Log : Translations_en_US.Log);
		Translations.WaitingToBeApproved = culture === "fi" ? Translations_fi.WaitingToBeApproved : (culture === "sv-FI" ? Translations_sv_FI.WaitingToBeApproved : Translations_en_US.WaitingToBeApproved);
		Translations.Properties = culture === "fi" ? Translations_fi.Properties : (culture === "sv-FI" ? Translations_sv_FI.Properties : Translations_en_US.Properties);
		Translations.PasswordMustContainLowercaseChar = culture === "fi" ? Translations_fi.PasswordMustContainLowercaseChar : (culture === "sv-FI" ? Translations_sv_FI.PasswordMustContainLowercaseChar : Translations_en_US.PasswordMustContainLowercaseChar);
		Translations.PublishDate = culture === "fi" ? Translations_fi.PublishDate : (culture === "sv-FI" ? Translations_sv_FI.PublishDate : Translations_en_US.PublishDate);
		Translations.CurrentTopicsAbbr = culture === "fi" ? Translations_fi.CurrentTopicsAbbr : (culture === "sv-FI" ? Translations_sv_FI.CurrentTopicsAbbr : Translations_en_US.CurrentTopicsAbbr);
		Translations.DeletingCompanyGroupParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingCompanyGroupParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyGroupParameterWasSuccessful : Translations_en_US.DeletingCompanyGroupParameterWasSuccessful);
		Translations.ShowDraftVersion = culture === "fi" ? Translations_fi.ShowDraftVersion : (culture === "sv-FI" ? Translations_sv_FI.ShowDraftVersion : Translations_en_US.ShowDraftVersion);
		Translations.TaskPriorityCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.TaskPriorityCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.TaskPriorityCodeIsAlreadyInUse : Translations_en_US.TaskPriorityCodeIsAlreadyInUse);
		Translations.WhistleBlowCategories = culture === "fi" ? Translations_fi.WhistleBlowCategories : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowCategories : Translations_en_US.WhistleBlowCategories);
		Translations.DetectingLocation = culture === "fi" ? Translations_fi.DetectingLocation : (culture === "sv-FI" ? Translations_sv_FI.DetectingLocation : Translations_en_US.DetectingLocation);
		Translations.NotificationsQueuedSuccessfully = culture === "fi" ? Translations_fi.NotificationsQueuedSuccessfully : (culture === "sv-FI" ? Translations_sv_FI.NotificationsQueuedSuccessfully : Translations_en_US.NotificationsQueuedSuccessfully);
		Translations.DeletingKeywordParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingKeywordParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingKeywordParameterWasSuccessful : Translations_en_US.DeletingKeywordParameterWasSuccessful);
		Translations.Browse = culture === "fi" ? Translations_fi.Browse : (culture === "sv-FI" ? Translations_sv_FI.Browse : Translations_en_US.Browse);
		Translations.ThisMessageWasDeletedByInstantMessageChannelAdministrator = culture === "fi" ? Translations_fi.ThisMessageWasDeletedByInstantMessageChannelAdministrator : (culture === "sv-FI" ? Translations_sv_FI.ThisMessageWasDeletedByInstantMessageChannelAdministrator : Translations_en_US.ThisMessageWasDeletedByInstantMessageChannelAdministrator);
		Translations.UserClaimsNotFound = culture === "fi" ? Translations_fi.UserClaimsNotFound : (culture === "sv-FI" ? Translations_sv_FI.UserClaimsNotFound : Translations_en_US.UserClaimsNotFound);
		Translations.Relation = culture === "fi" ? Translations_fi.Relation : (culture === "sv-FI" ? Translations_sv_FI.Relation : Translations_en_US.Relation);
		Translations.NoName = culture === "fi" ? Translations_fi.NoName : (culture === "sv-FI" ? Translations_sv_FI.NoName : Translations_en_US.NoName);
		Translations.AddEmployment = culture === "fi" ? Translations_fi.AddEmployment : (culture === "sv-FI" ? Translations_sv_FI.AddEmployment : Translations_en_US.AddEmployment);
		Translations.RemoveDefaultEventHandler = culture === "fi" ? Translations_fi.RemoveDefaultEventHandler : (culture === "sv-FI" ? Translations_sv_FI.RemoveDefaultEventHandler : Translations_en_US.RemoveDefaultEventHandler);
		Translations.CopyOrMoveFolderParameter = culture === "fi" ? Translations_fi.CopyOrMoveFolderParameter : (culture === "sv-FI" ? Translations_sv_FI.CopyOrMoveFolderParameter : Translations_en_US.CopyOrMoveFolderParameter);
		Translations.DefaultStyle = culture === "fi" ? Translations_fi.DefaultStyle : (culture === "sv-FI" ? Translations_sv_FI.DefaultStyle : Translations_en_US.DefaultStyle);
		Translations.UnitNameMustBeDefined = culture === "fi" ? Translations_fi.UnitNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.UnitNameMustBeDefined : Translations_en_US.UnitNameMustBeDefined);
		Translations.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulErrorParameter);
		Translations.TransportCompanies = culture === "fi" ? Translations_fi.TransportCompanies : (culture === "sv-FI" ? Translations_sv_FI.TransportCompanies : Translations_en_US.TransportCompanies);
		Translations.SendinEmailNotificationsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SendinEmailNotificationsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SendinEmailNotificationsWasNotSuccessfulErrorParameter : Translations_en_US.SendinEmailNotificationsWasNotSuccessfulErrorParameter);
		Translations.Unkown = culture === "fi" ? Translations_fi.Unkown : (culture === "sv-FI" ? Translations_sv_FI.Unkown : Translations_en_US.Unkown);
		Translations.WhistleBlowInformantDescription = culture === "fi" ? Translations_fi.WhistleBlowInformantDescription : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantDescription : Translations_en_US.WhistleBlowInformantDescription);
		Translations.InternalMessages = culture === "fi" ? Translations_fi.InternalMessages : (culture === "sv-FI" ? Translations_sv_FI.InternalMessages : Translations_en_US.InternalMessages);
		Translations.SavingCompanyParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingCompanyParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingCompanyParameterWasSuccessful : Translations_en_US.SavingCompanyParameterWasSuccessful);
		Translations.ProjectName = culture === "fi" ? Translations_fi.ProjectName : (culture === "sv-FI" ? Translations_sv_FI.ProjectName : Translations_en_US.ProjectName);
		Translations.InstantMessageChannelInternal = culture === "fi" ? Translations_fi.InstantMessageChannelInternal : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelInternal : Translations_en_US.InstantMessageChannelInternal);
		Translations.Modified = culture === "fi" ? Translations_fi.Modified : (culture === "sv-FI" ? Translations_sv_FI.Modified : Translations_en_US.Modified);
		Translations.DocumentIsEmpty = culture === "fi" ? Translations_fi.DocumentIsEmpty : (culture === "sv-FI" ? Translations_sv_FI.DocumentIsEmpty : Translations_en_US.DocumentIsEmpty);
		Translations.WhistleBlowInformantButtonHint = culture === "fi" ? Translations_fi.WhistleBlowInformantButtonHint : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantButtonHint : Translations_en_US.WhistleBlowInformantButtonHint);
		Translations.SavingEventTypesWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventTypesWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypesWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventTypesWasNotSuccessfulErrorParameter);
		Translations.DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingEventUrgencyClassParameterWasNotSuccessfulErrorParameter);
		Translations.AddMessageType = culture === "fi" ? Translations_fi.AddMessageType : (culture === "sv-FI" ? Translations_sv_FI.AddMessageType : Translations_en_US.AddMessageType);
		Translations.SavingJobParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingJobParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingJobParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingJobParameterWasNotSuccessfulErrorParameter);
		Translations.RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = culture === "fi" ? Translations_fi.RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted : Translations_en_US.RecordFamiliarizationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted);
		Translations.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordUserTransferredPropertiesParameterWasNotSuccessfulErrorParameter);
		Translations.ReportsModified = culture === "fi" ? Translations_fi.ReportsModified : (culture === "sv-FI" ? Translations_sv_FI.ReportsModified : Translations_en_US.ReportsModified);
		Translations.DoYouReallyWantToRemoveSelectedRecordFamiliarizations = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedRecordFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedRecordFamiliarizations : Translations_en_US.DoYouReallyWantToRemoveSelectedRecordFamiliarizations);
		Translations.InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation = culture === "fi" ? Translations_fi.InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation : Translations_en_US.InstantMessageChannelRemoveFromYourselfFromAdministratorsConfirmation);
		Translations.UserRecordStatisticsExpiringInSixMonthsFurtherEducations = culture === "fi" ? Translations_fi.UserRecordStatisticsExpiringInSixMonthsFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsExpiringInSixMonthsFurtherEducations : Translations_en_US.UserRecordStatisticsExpiringInSixMonthsFurtherEducations);
		Translations.DefaultTaskPriorities = culture === "fi" ? Translations_fi.DefaultTaskPriorities : (culture === "sv-FI" ? Translations_sv_FI.DefaultTaskPriorities : Translations_en_US.DefaultTaskPriorities);
		Translations.NewMessage = culture === "fi" ? Translations_fi.NewMessage : (culture === "sv-FI" ? Translations_sv_FI.NewMessage : Translations_en_US.NewMessage);
		Translations.AddLogisticVoucherCompanyPaymentSetting = culture === "fi" ? Translations_fi.AddLogisticVoucherCompanyPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.AddLogisticVoucherCompanyPaymentSetting : Translations_en_US.AddLogisticVoucherCompanyPaymentSetting);
		Translations.RemoveWhistleBlowCategory = culture === "fi" ? Translations_fi.RemoveWhistleBlowCategory : (culture === "sv-FI" ? Translations_sv_FI.RemoveWhistleBlowCategory : Translations_en_US.RemoveWhistleBlowCategory);
		Translations.DoYouReallyWantToRemoveUnitParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveUnitParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveUnitParameter : Translations_en_US.DoYouReallyWantToRemoveUnitParameter);
		Translations.SelectSubject = culture === "fi" ? Translations_fi.SelectSubject : (culture === "sv-FI" ? Translations_sv_FI.SelectSubject : Translations_en_US.SelectSubject);
		Translations.SelectRemovingMethod = culture === "fi" ? Translations_fi.SelectRemovingMethod : (culture === "sv-FI" ? Translations_sv_FI.SelectRemovingMethod : Translations_en_US.SelectRemovingMethod);
		Translations.EditPayerSettings = culture === "fi" ? Translations_fi.EditPayerSettings : (culture === "sv-FI" ? Translations_sv_FI.EditPayerSettings : Translations_en_US.EditPayerSettings);
		Translations.InvalidValueParameter = culture === "fi" ? Translations_fi.InvalidValueParameter : (culture === "sv-FI" ? Translations_sv_FI.InvalidValueParameter : Translations_en_US.InvalidValueParameter);
		Translations.NormalSize = culture === "fi" ? Translations_fi.NormalSize : (culture === "sv-FI" ? Translations_sv_FI.NormalSize : Translations_en_US.NormalSize);
		Translations.DeletingTaskDataRowWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingTaskDataRowWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskDataRowWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingTaskDataRowWasNotSuccessfulConcurrencyError);
		Translations.DoYouReallyWantToRemoveFileFilterParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveFileFilterParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveFileFilterParameter : Translations_en_US.DoYouReallyWantToRemoveFileFilterParameter);
		Translations.PermissionsForSection = culture === "fi" ? Translations_fi.PermissionsForSection : (culture === "sv-FI" ? Translations_sv_FI.PermissionsForSection : Translations_en_US.PermissionsForSection);
		Translations.TargetsOtherCompanies = culture === "fi" ? Translations_fi.TargetsOtherCompanies : (culture === "sv-FI" ? Translations_sv_FI.TargetsOtherCompanies : Translations_en_US.TargetsOtherCompanies);
		Translations.UserGroupModuleProperties = culture === "fi" ? Translations_fi.UserGroupModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.UserGroupModuleProperties : Translations_en_US.UserGroupModuleProperties);
		Translations.WhistleBlowEventTypeModified = culture === "fi" ? Translations_fi.WhistleBlowEventTypeModified : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowEventTypeModified : Translations_en_US.WhistleBlowEventTypeModified);
		Translations.SomeEventHandlersHaveReadMessage = culture === "fi" ? Translations_fi.SomeEventHandlersHaveReadMessage : (culture === "sv-FI" ? Translations_sv_FI.SomeEventHandlersHaveReadMessage : Translations_en_US.SomeEventHandlersHaveReadMessage);
		Translations.Scope = culture === "fi" ? Translations_fi.Scope : (culture === "sv-FI" ? Translations_sv_FI.Scope : Translations_en_US.Scope);
		Translations.UserRecordStatisticsActiveCards = culture === "fi" ? Translations_fi.UserRecordStatisticsActiveCards : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsActiveCards : Translations_en_US.UserRecordStatisticsActiveCards);
		Translations.ChangedObserverAction = culture === "fi" ? Translations_fi.ChangedObserverAction : (culture === "sv-FI" ? Translations_sv_FI.ChangedObserverAction : Translations_en_US.ChangedObserverAction);
		Translations.SavingUserGroupParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingUserGroupParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingUserGroupParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingUserGroupParameterWasNotSuccessfulErrorParameter);
		Translations.UnitMustBeSelected = culture === "fi" ? Translations_fi.UnitMustBeSelected : (culture === "sv-FI" ? Translations_sv_FI.UnitMustBeSelected : Translations_en_US.UnitMustBeSelected);
		Translations.CompanyCodeMustBeDefined = culture === "fi" ? Translations_fi.CompanyCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.CompanyCodeMustBeDefined : Translations_en_US.CompanyCodeMustBeDefined);
		Translations.SavingEventParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventParameterWasNotSuccessfulErrorParameter);
		Translations.WhistleBlowChannel = culture === "fi" ? Translations_fi.WhistleBlowChannel : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowChannel : Translations_en_US.WhistleBlowChannel);
		Translations.WhistleBlowWasNotFoundExplanation = culture === "fi" ? Translations_fi.WhistleBlowWasNotFoundExplanation : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowWasNotFoundExplanation : Translations_en_US.WhistleBlowWasNotFoundExplanation);
		Translations.DocumentTemplateParameterNotFound = culture === "fi" ? Translations_fi.DocumentTemplateParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.DocumentTemplateParameterNotFound : Translations_en_US.DocumentTemplateParameterNotFound);
		Translations.Sorting = culture === "fi" ? Translations_fi.Sorting : (culture === "sv-FI" ? Translations_sv_FI.Sorting : Translations_en_US.Sorting);
		Translations.MessageTypeCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.MessageTypeCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.MessageTypeCodeIsAlreadyInUse : Translations_en_US.MessageTypeCodeIsAlreadyInUse);
		Translations.SelectDocumentTemplate = culture === "fi" ? Translations_fi.SelectDocumentTemplate : (culture === "sv-FI" ? Translations_sv_FI.SelectDocumentTemplate : Translations_en_US.SelectDocumentTemplate);
		Translations.SavingTaskDataRowWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingTaskDataRowWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskDataRowWasNotSuccessfulConcurrencyError : Translations_en_US.SavingTaskDataRowWasNotSuccessfulConcurrencyError);
		Translations.WhistleBlowInternalMessageConfirmation = culture === "fi" ? Translations_fi.WhistleBlowInternalMessageConfirmation : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInternalMessageConfirmation : Translations_en_US.WhistleBlowInternalMessageConfirmation);
		Translations.DeletingCompanyGroupsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingCompanyGroupsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyGroupsParameterWasSuccessful : Translations_en_US.DeletingCompanyGroupsParameterWasSuccessful);
		Translations.DeletingTaskDataRowWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingTaskDataRowWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskDataRowWasNotSuccessfulErrorParameter : Translations_en_US.DeletingTaskDataRowWasNotSuccessfulErrorParameter);
		Translations.FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations = culture === "fi" ? Translations_fi.FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations : Translations_en_US.FamiliarizationsMustBeDefinedBeforeAddingRecordUserFamiliarizations);
		Translations.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError);
		Translations.Unlock = culture === "fi" ? Translations_fi.Unlock : (culture === "sv-FI" ? Translations_sv_FI.Unlock : Translations_en_US.Unlock);
		Translations.Tasks = culture === "fi" ? Translations_fi.Tasks : (culture === "sv-FI" ? Translations_sv_FI.Tasks : Translations_en_US.Tasks);
		Translations.RestoreTaskFromTrashBin = culture === "fi" ? Translations_fi.RestoreTaskFromTrashBin : (culture === "sv-FI" ? Translations_sv_FI.RestoreTaskFromTrashBin : Translations_en_US.RestoreTaskFromTrashBin);
		Translations.SavingWhistleBlowMessageWasSuccessful = culture === "fi" ? Translations_fi.SavingWhistleBlowMessageWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowMessageWasSuccessful : Translations_en_US.SavingWhistleBlowMessageWasSuccessful);
		Translations.Test = culture === "fi" ? Translations_fi.Test : (culture === "sv-FI" ? Translations_sv_FI.Test : Translations_en_US.Test);
		Translations.TaskPriorityCodeMustBeDefined = culture === "fi" ? Translations_fi.TaskPriorityCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.TaskPriorityCodeMustBeDefined : Translations_en_US.TaskPriorityCodeMustBeDefined);
		Translations.DeletingOneTimePasswordWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingOneTimePasswordWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingOneTimePasswordWasNotSuccessfulErrorParameter : Translations_en_US.DeletingOneTimePasswordWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToRemoveTaskStateParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveTaskStateParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveTaskStateParameter : Translations_en_US.DoYouReallyWantToRemoveTaskStateParameter);
		Translations.DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingWhistleBlowParameterWasNotSuccessfulErrorParameter);
		Translations.ReadingFolderParameter = culture === "fi" ? Translations_fi.ReadingFolderParameter : (culture === "sv-FI" ? Translations_sv_FI.ReadingFolderParameter : Translations_en_US.ReadingFolderParameter);
		Translations.SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingRecordUserQualificationParameterWasNotSuccessfulErrorParameter);
		Translations.PublishingTaskTypeParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.PublishingTaskTypeParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.PublishingTaskTypeParameterWasNotSuccessfulErrorParameter : Translations_en_US.PublishingTaskTypeParameterWasNotSuccessfulErrorParameter);
		Translations.RemoveBasicEducation = culture === "fi" ? Translations_fi.RemoveBasicEducation : (culture === "sv-FI" ? Translations_sv_FI.RemoveBasicEducation : Translations_en_US.RemoveBasicEducation);
		Translations.WhistleBlowEventTypeAttachmentsAdded = culture === "fi" ? Translations_fi.WhistleBlowEventTypeAttachmentsAdded : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowEventTypeAttachmentsAdded : Translations_en_US.WhistleBlowEventTypeAttachmentsAdded);
		Translations.AddEventMessageConfirmation = culture === "fi" ? Translations_fi.AddEventMessageConfirmation : (culture === "sv-FI" ? Translations_sv_FI.AddEventMessageConfirmation : Translations_en_US.AddEventMessageConfirmation);
		Translations.SavingEventUrgencyClassParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingEventUrgencyClassParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventUrgencyClassParameterWasSuccessful : Translations_en_US.SavingEventUrgencyClassParameterWasSuccessful);
		Translations.DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter);
		Translations.InstantMessageChannelGroupExternal = culture === "fi" ? Translations_fi.InstantMessageChannelGroupExternal : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelGroupExternal : Translations_en_US.InstantMessageChannelGroupExternal);
		Translations.CompanyIdClaimNotFound = culture === "fi" ? Translations_fi.CompanyIdClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.CompanyIdClaimNotFound : Translations_en_US.CompanyIdClaimNotFound);
		Translations.WhistleBlowReportIHaveSavedThePassword = culture === "fi" ? Translations_fi.WhistleBlowReportIHaveSavedThePassword : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowReportIHaveSavedThePassword : Translations_en_US.WhistleBlowReportIHaveSavedThePassword);
		Translations.UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels = culture === "fi" ? Translations_fi.UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels : (culture === "sv-FI" ? Translations_sv_FI.UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels : Translations_en_US.UserIsInstantMessageChannelOnlyAdministratorInParameterInstantMessageChannels);
		Translations.Unlimited = culture === "fi" ? Translations_fi.Unlimited : (culture === "sv-FI" ? Translations_sv_FI.Unlimited : Translations_en_US.Unlimited);
		Translations.RemoveDefaultEventType = culture === "fi" ? Translations_fi.RemoveDefaultEventType : (culture === "sv-FI" ? Translations_sv_FI.RemoveDefaultEventType : Translations_en_US.RemoveDefaultEventType);
		Translations.SavingOneTimePasswordWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingOneTimePasswordWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingOneTimePasswordWasNotSuccessfulErrorParameter : Translations_en_US.SavingOneTimePasswordWasNotSuccessfulErrorParameter);
		Translations.NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded = culture === "fi" ? Translations_fi.NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded : Translations_en_US.NewFurtherEducationMustBeSavedBeforeAttachmentsCanBeAdded);
		Translations.SavingFileFilterParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingFileFilterParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingFileFilterParameterWasSuccessful : Translations_en_US.SavingFileFilterParameterWasSuccessful);
		Translations.PermissionToCreateEventsToPartners = culture === "fi" ? Translations_fi.PermissionToCreateEventsToPartners : (culture === "sv-FI" ? Translations_sv_FI.PermissionToCreateEventsToPartners : Translations_en_US.PermissionToCreateEventsToPartners);
		Translations.ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError : Translations_en_US.ExitingInstantMessageChannelWasNotSuccessfulConcurrencyError);
		Translations.FieldsToBePrinted = culture === "fi" ? Translations_fi.FieldsToBePrinted : (culture === "sv-FI" ? Translations_sv_FI.FieldsToBePrinted : Translations_en_US.FieldsToBePrinted);
		Translations.WhistleBlowSourceOther = culture === "fi" ? Translations_fi.WhistleBlowSourceOther : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSourceOther : Translations_en_US.WhistleBlowSourceOther);
		Translations.DoYouReallyWantToSave = culture === "fi" ? Translations_fi.DoYouReallyWantToSave : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToSave : Translations_en_US.DoYouReallyWantToSave);
		Translations.MovingTaskParameterToTrashBinWasSuccessful = culture === "fi" ? Translations_fi.MovingTaskParameterToTrashBinWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.MovingTaskParameterToTrashBinWasSuccessful : Translations_en_US.MovingTaskParameterToTrashBinWasSuccessful);
		Translations.DisableExternalIntraSystemConfirmation = culture === "fi" ? Translations_fi.DisableExternalIntraSystemConfirmation : (culture === "sv-FI" ? Translations_sv_FI.DisableExternalIntraSystemConfirmation : Translations_en_US.DisableExternalIntraSystemConfirmation);
		Translations.WhistleBlowMustBeReceivedLatestAtParameter = culture === "fi" ? Translations_fi.WhistleBlowMustBeReceivedLatestAtParameter : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowMustBeReceivedLatestAtParameter : Translations_en_US.WhistleBlowMustBeReceivedLatestAtParameter);
		Translations.UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter : Translations_en_US.UpdateUserParameterExternalSystemsWasNotSuccessfulErrorParameter);
		Translations.SavingTaskTypeParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingTaskTypeParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskTypeParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingTaskTypeParameterWasNotSuccessfulErrorParameter);
		Translations.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion = culture === "fi" ? Translations_fi.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion : (culture === "sv-FI" ? Translations_sv_FI.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion : Translations_en_US.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion);
		Translations.UserRecordStatisticsMissingQualifications = culture === "fi" ? Translations_fi.UserRecordStatisticsMissingQualifications : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsMissingQualifications : Translations_en_US.UserRecordStatisticsMissingQualifications);
		Translations.ObserverActionMustBeDefined = culture === "fi" ? Translations_fi.ObserverActionMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.ObserverActionMustBeDefined : Translations_en_US.ObserverActionMustBeDefined);
		Translations.CanShareContentToCompanies = culture === "fi" ? Translations_fi.CanShareContentToCompanies : (culture === "sv-FI" ? Translations_sv_FI.CanShareContentToCompanies : Translations_en_US.CanShareContentToCompanies);
		Translations.SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingWhistleBlowChannelParameterWasNotSuccessfulConcurrencyError);
		Translations.AddDefaultHandler = culture === "fi" ? Translations_fi.AddDefaultHandler : (culture === "sv-FI" ? Translations_sv_FI.AddDefaultHandler : Translations_en_US.AddDefaultHandler);
		Translations.WhistleBlowInformation = culture === "fi" ? Translations_fi.WhistleBlowInformation : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformation : Translations_en_US.WhistleBlowInformation);
		Translations.DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordBasicEducationsParameterWasNotSuccessfulErrorParameter);
		Translations.EditWhistleBlow = culture === "fi" ? Translations_fi.EditWhistleBlow : (culture === "sv-FI" ? Translations_sv_FI.EditWhistleBlow : Translations_en_US.EditWhistleBlow);
		Translations.NewRecordUserMustBeSavedBeforeRowsCanBeAdded = culture === "fi" ? Translations_fi.NewRecordUserMustBeSavedBeforeRowsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewRecordUserMustBeSavedBeforeRowsCanBeAdded : Translations_en_US.NewRecordUserMustBeSavedBeforeRowsCanBeAdded);
		Translations.ReadingFileParameterWasNotSuccessfulFileNotFound = culture === "fi" ? Translations_fi.ReadingFileParameterWasNotSuccessfulFileNotFound : (culture === "sv-FI" ? Translations_sv_FI.ReadingFileParameterWasNotSuccessfulFileNotFound : Translations_en_US.ReadingFileParameterWasNotSuccessfulFileNotFound);
		Translations.ChangingPasswordWasSuccessful = culture === "fi" ? Translations_fi.ChangingPasswordWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.ChangingPasswordWasSuccessful : Translations_en_US.ChangingPasswordWasSuccessful);
		Translations.WhistleBlowSeverityHigh = culture === "fi" ? Translations_fi.WhistleBlowSeverityHigh : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSeverityHigh : Translations_en_US.WhistleBlowSeverityHigh);
		Translations.WhistleBlowEventTypeHandlersRemoved = culture === "fi" ? Translations_fi.WhistleBlowEventTypeHandlersRemoved : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowEventTypeHandlersRemoved : Translations_en_US.WhistleBlowEventTypeHandlersRemoved);
		Translations.AdditionalPermissions = culture === "fi" ? Translations_fi.AdditionalPermissions : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissions : Translations_en_US.AdditionalPermissions);
		Translations.Aprint = culture === "fi" ? Translations_fi.Aprint : (culture === "sv-FI" ? Translations_sv_FI.Aprint : Translations_en_US.Aprint);
		Translations.CreatingCompressedFileWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CreatingCompressedFileWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CreatingCompressedFileWasNotSuccessfulErrorParameter : Translations_en_US.CreatingCompressedFileWasNotSuccessfulErrorParameter);
		Translations.WhistleBlowWasNotFound = culture === "fi" ? Translations_fi.WhistleBlowWasNotFound : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowWasNotFound : Translations_en_US.WhistleBlowWasNotFound);
		Translations.ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError : Translations_en_US.ArchivingRecordUserFurtherEducationsWasNotSuccessfulConcurrencyError);
		Translations.AddCompany = culture === "fi" ? Translations_fi.AddCompany : (culture === "sv-FI" ? Translations_sv_FI.AddCompany : Translations_en_US.AddCompany);
		Translations.PostalAddress = culture === "fi" ? Translations_fi.PostalAddress : (culture === "sv-FI" ? Translations_sv_FI.PostalAddress : Translations_en_US.PostalAddress);
		Translations.AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel = culture === "fi" ? Translations_fi.AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel : Translations_en_US.AddAtleastOneInstantMessageChannelUsersToInstantMessageChannel);
		Translations.NewPrint = culture === "fi" ? Translations_fi.NewPrint : (culture === "sv-FI" ? Translations_sv_FI.NewPrint : Translations_en_US.NewPrint);
		Translations.ExternalIntraSystems = culture === "fi" ? Translations_fi.ExternalIntraSystems : (culture === "sv-FI" ? Translations_sv_FI.ExternalIntraSystems : Translations_en_US.ExternalIntraSystems);
		Translations.SavingCompanyGroupParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingCompanyGroupParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingCompanyGroupParameterWasSuccessful : Translations_en_US.SavingCompanyGroupParameterWasSuccessful);
		Translations.TaskHandler = culture === "fi" ? Translations_fi.TaskHandler : (culture === "sv-FI" ? Translations_sv_FI.TaskHandler : Translations_en_US.TaskHandler);
		Translations.AccessRightReadInstantMessages = culture === "fi" ? Translations_fi.AccessRightReadInstantMessages : (culture === "sv-FI" ? Translations_sv_FI.AccessRightReadInstantMessages : Translations_en_US.AccessRightReadInstantMessages);
		Translations.FolderProperties = culture === "fi" ? Translations_fi.FolderProperties : (culture === "sv-FI" ? Translations_sv_FI.FolderProperties : Translations_en_US.FolderProperties);
		Translations.WhistleBlowSourceUnspecified = culture === "fi" ? Translations_fi.WhistleBlowSourceUnspecified : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSourceUnspecified : Translations_en_US.WhistleBlowSourceUnspecified);
		Translations.UserIsSystemAdminClaimNotFound = culture === "fi" ? Translations_fi.UserIsSystemAdminClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.UserIsSystemAdminClaimNotFound : Translations_en_US.UserIsSystemAdminClaimNotFound);
		Translations.DocumentParameterAlreadyExists = culture === "fi" ? Translations_fi.DocumentParameterAlreadyExists : (culture === "sv-FI" ? Translations_sv_FI.DocumentParameterAlreadyExists : Translations_en_US.DocumentParameterAlreadyExists);
		Translations.ImageProperties = culture === "fi" ? Translations_fi.ImageProperties : (culture === "sv-FI" ? Translations_sv_FI.ImageProperties : Translations_en_US.ImageProperties);
		Translations.RefreshList = culture === "fi" ? Translations_fi.RefreshList : (culture === "sv-FI" ? Translations_sv_FI.RefreshList : Translations_en_US.RefreshList);
		Translations.EmptyTaskTrashBinConfirmation = culture === "fi" ? Translations_fi.EmptyTaskTrashBinConfirmation : (culture === "sv-FI" ? Translations_sv_FI.EmptyTaskTrashBinConfirmation : Translations_en_US.EmptyTaskTrashBinConfirmation);
		Translations.ImmediateAction = culture === "fi" ? Translations_fi.ImmediateAction : (culture === "sv-FI" ? Translations_sv_FI.ImmediateAction : Translations_en_US.ImmediateAction);
		Translations.AddUserParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.AddUserParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.AddUserParameterWasNotSuccessfulConcurrencyError : Translations_en_US.AddUserParameterWasNotSuccessfulConcurrencyError);
		Translations.EventTypeTitle = culture === "fi" ? Translations_fi.EventTypeTitle : (culture === "sv-FI" ? Translations_sv_FI.EventTypeTitle : Translations_en_US.EventTypeTitle);
		Translations.MovingFilesAndDocumentsToFolderParameterWasSuccessful = culture === "fi" ? Translations_fi.MovingFilesAndDocumentsToFolderParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.MovingFilesAndDocumentsToFolderParameterWasSuccessful : Translations_en_US.MovingFilesAndDocumentsToFolderParameterWasSuccessful);
		Translations.UserCanBeSelectedAsEventHandler = culture === "fi" ? Translations_fi.UserCanBeSelectedAsEventHandler : (culture === "sv-FI" ? Translations_sv_FI.UserCanBeSelectedAsEventHandler : Translations_en_US.UserCanBeSelectedAsEventHandler);
		Translations.Expand = culture === "fi" ? Translations_fi.Expand : (culture === "sv-FI" ? Translations_sv_FI.Expand : Translations_en_US.Expand);
		Translations.ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter : Translations_en_US.ArchivingWhistleBlowsParameterWasNotSuccessfulErrorParameter);
		Translations.RecordUser = culture === "fi" ? Translations_fi.RecordUser : (culture === "sv-FI" ? Translations_sv_FI.RecordUser : Translations_en_US.RecordUser);
		Translations.SavingEditableDocumentParameterPublishWasSuccessful = culture === "fi" ? Translations_fi.SavingEditableDocumentParameterPublishWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEditableDocumentParameterPublishWasSuccessful : Translations_en_US.SavingEditableDocumentParameterPublishWasSuccessful);
		Translations.AddingAttachmentParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingAttachmentParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingAttachmentParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddingAttachmentParameterWasNotSuccessfulErrorParameter);
		Translations.GroupByQuarter = culture === "fi" ? Translations_fi.GroupByQuarter : (culture === "sv-FI" ? Translations_sv_FI.GroupByQuarter : Translations_en_US.GroupByQuarter);
		Translations.SavingEventMessageWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventMessageWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventMessageWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventMessageWasNotSuccessfulErrorParameter);
		Translations.WorkShiftType = culture === "fi" ? Translations_fi.WorkShiftType : (culture === "sv-FI" ? Translations_sv_FI.WorkShiftType : Translations_en_US.WorkShiftType);
		Translations.WhistleBlowAnonymityAnonymous = culture === "fi" ? Translations_fi.WhistleBlowAnonymityAnonymous : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowAnonymityAnonymous : Translations_en_US.WhistleBlowAnonymityAnonymous);
		Translations.EventTypeBCodeMustBeDefined = culture === "fi" ? Translations_fi.EventTypeBCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.EventTypeBCodeMustBeDefined : Translations_en_US.EventTypeBCodeMustBeDefined);
		Translations.MyFiltersAndReports = culture === "fi" ? Translations_fi.MyFiltersAndReports : (culture === "sv-FI" ? Translations_sv_FI.MyFiltersAndReports : Translations_en_US.MyFiltersAndReports);
		Translations.DeletingRecordFurtherEducationParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordFurtherEducationParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFurtherEducationParameterWasSuccessful : Translations_en_US.DeletingRecordFurtherEducationParameterWasSuccessful);
		Translations.ProcessingFolderParameter = culture === "fi" ? Translations_fi.ProcessingFolderParameter : (culture === "sv-FI" ? Translations_sv_FI.ProcessingFolderParameter : Translations_en_US.ProcessingFolderParameter);
		Translations.RemoveDocumentTemplate = culture === "fi" ? Translations_fi.RemoveDocumentTemplate : (culture === "sv-FI" ? Translations_sv_FI.RemoveDocumentTemplate : Translations_en_US.RemoveDocumentTemplate);
		Translations.NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded = culture === "fi" ? Translations_fi.NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded : Translations_en_US.NewRecordUserMustBeSavedBeforeFurtherEducationsCanBeAdded);
		Translations.SavingEventSettingsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventSettingsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventSettingsWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventSettingsWasNotSuccessfulErrorParameter);
		Translations.DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordQualificationParameterWasNotSuccessfulConcurrencyError);
		Translations.AdditionalPermissionAWhistleBlow = culture === "fi" ? Translations_fi.AdditionalPermissionAWhistleBlow : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissionAWhistleBlow : Translations_en_US.AdditionalPermissionAWhistleBlow);
		Translations.UserAccountMustBeSelected = culture === "fi" ? Translations_fi.UserAccountMustBeSelected : (culture === "sv-FI" ? Translations_sv_FI.UserAccountMustBeSelected : Translations_en_US.UserAccountMustBeSelected);
		Translations.ProcessingTime = culture === "fi" ? Translations_fi.ProcessingTime : (culture === "sv-FI" ? Translations_sv_FI.ProcessingTime : Translations_en_US.ProcessingTime);
		Translations.SavingPrintWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingPrintWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingPrintWasNotSuccessfulErrorParameter : Translations_en_US.SavingPrintWasNotSuccessfulErrorParameter);
		Translations.AddTarget = culture === "fi" ? Translations_fi.AddTarget : (culture === "sv-FI" ? Translations_sv_FI.AddTarget : Translations_en_US.AddTarget);
		Translations.DeletingEventTypeBParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingEventTypeBParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeBParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingEventTypeBParameterWasNotSuccessfulErrorParameter);
		Translations.RecordFurtherEducationParameterNotFound = culture === "fi" ? Translations_fi.RecordFurtherEducationParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordFurtherEducationParameterNotFound : Translations_en_US.RecordFurtherEducationParameterNotFound);
		Translations.RemoveParameterDocuments = culture === "fi" ? Translations_fi.RemoveParameterDocuments : (culture === "sv-FI" ? Translations_sv_FI.RemoveParameterDocuments : Translations_en_US.RemoveParameterDocuments);
		Translations.EditEvent = culture === "fi" ? Translations_fi.EditEvent : (culture === "sv-FI" ? Translations_sv_FI.EditEvent : Translations_en_US.EditEvent);
		Translations.RemoveRowConfirmation = culture === "fi" ? Translations_fi.RemoveRowConfirmation : (culture === "sv-FI" ? Translations_sv_FI.RemoveRowConfirmation : Translations_en_US.RemoveRowConfirmation);
		Translations.Addition = culture === "fi" ? Translations_fi.Addition : (culture === "sv-FI" ? Translations_sv_FI.Addition : Translations_en_US.Addition);
		Translations.MiddleName = culture === "fi" ? Translations_fi.MiddleName : (culture === "sv-FI" ? Translations_sv_FI.MiddleName : Translations_en_US.MiddleName);
		Translations.SendReadReceiptConfirmation = culture === "fi" ? Translations_fi.SendReadReceiptConfirmation : (culture === "sv-FI" ? Translations_sv_FI.SendReadReceiptConfirmation : Translations_en_US.SendReadReceiptConfirmation);
		Translations.EditEventTypeBs = culture === "fi" ? Translations_fi.EditEventTypeBs : (culture === "sv-FI" ? Translations_sv_FI.EditEventTypeBs : Translations_en_US.EditEventTypeBs);
		Translations.NotifiedPersons = culture === "fi" ? Translations_fi.NotifiedPersons : (culture === "sv-FI" ? Translations_sv_FI.NotifiedPersons : Translations_en_US.NotifiedPersons);
		Translations.AddFile = culture === "fi" ? Translations_fi.AddFile : (culture === "sv-FI" ? Translations_sv_FI.AddFile : Translations_en_US.AddFile);
		Translations.ChangeLanguage = culture === "fi" ? Translations_fi.ChangeLanguage : (culture === "sv-FI" ? Translations_sv_FI.ChangeLanguage : Translations_en_US.ChangeLanguage);
		Translations.DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingTaskPriorityParameterWasNotSuccessfulConcurrencyError);
		Translations.ExpiringIn90Days = culture === "fi" ? Translations_fi.ExpiringIn90Days : (culture === "sv-FI" ? Translations_sv_FI.ExpiringIn90Days : Translations_en_US.ExpiringIn90Days);
		Translations.TaskRemainderCheckingAndNotificationStarted = culture === "fi" ? Translations_fi.TaskRemainderCheckingAndNotificationStarted : (culture === "sv-FI" ? Translations_sv_FI.TaskRemainderCheckingAndNotificationStarted : Translations_en_US.TaskRemainderCheckingAndNotificationStarted);
		Translations.SavingOptionParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingOptionParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingOptionParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingOptionParameterWasNotSuccessfulConcurrencyError);
		Translations.IpAddress = culture === "fi" ? Translations_fi.IpAddress : (culture === "sv-FI" ? Translations_sv_FI.IpAddress : Translations_en_US.IpAddress);
		Translations.AddAttachementsToEvent = culture === "fi" ? Translations_fi.AddAttachementsToEvent : (culture === "sv-FI" ? Translations_sv_FI.AddAttachementsToEvent : Translations_en_US.AddAttachementsToEvent);
		Translations.AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter : Translations_en_US.AddingRecordUserSalaryRowsWasNotSuccessfulErrorParameter);
		Translations.SendingEmailToParameter = culture === "fi" ? Translations_fi.SendingEmailToParameter : (culture === "sv-FI" ? Translations_sv_FI.SendingEmailToParameter : Translations_en_US.SendingEmailToParameter);
		Translations.ParameterPercentOfFileStorageQuotaUsed = culture === "fi" ? Translations_fi.ParameterPercentOfFileStorageQuotaUsed : (culture === "sv-FI" ? Translations_sv_FI.ParameterPercentOfFileStorageQuotaUsed : Translations_en_US.ParameterPercentOfFileStorageQuotaUsed);
		Translations.AddPartnersParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddPartnersParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddPartnersParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddPartnersParameterWasNotSuccessfulErrorParameter);
		Translations.WhistleBlowInformant = culture === "fi" ? Translations_fi.WhistleBlowInformant : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformant : Translations_en_US.WhistleBlowInformant);
		Translations.DefaultEventHandlers = culture === "fi" ? Translations_fi.DefaultEventHandlers : (culture === "sv-FI" ? Translations_sv_FI.DefaultEventHandlers : Translations_en_US.DefaultEventHandlers);
		Translations.FieldParameterConstainsIllegalCharactersForFileName = culture === "fi" ? Translations_fi.FieldParameterConstainsIllegalCharactersForFileName : (culture === "sv-FI" ? Translations_sv_FI.FieldParameterConstainsIllegalCharactersForFileName : Translations_en_US.FieldParameterConstainsIllegalCharactersForFileName);
		Translations.AccessRightWriteTasks = culture === "fi" ? Translations_fi.AccessRightWriteTasks : (culture === "sv-FI" ? Translations_sv_FI.AccessRightWriteTasks : Translations_en_US.AccessRightWriteTasks);
		Translations.DeletingEventPartnerParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingEventPartnerParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventPartnerParameterWasSuccessful : Translations_en_US.DeletingEventPartnerParameterWasSuccessful);
		Translations.State = culture === "fi" ? Translations_fi.State : (culture === "sv-FI" ? Translations_sv_FI.State : Translations_en_US.State);
		Translations.OtherInformation = culture === "fi" ? Translations_fi.OtherInformation : (culture === "sv-FI" ? Translations_sv_FI.OtherInformation : Translations_en_US.OtherInformation);
		Translations.CompanyThatIsRelatedToEventsCannotBeDeleted = culture === "fi" ? Translations_fi.CompanyThatIsRelatedToEventsCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.CompanyThatIsRelatedToEventsCannotBeDeleted : Translations_en_US.CompanyThatIsRelatedToEventsCannotBeDeleted);
		Translations.SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventUrgencyClassParameterWasNotSuccessfulErrorParameter);
		Translations.PreviousCalendarYear = culture === "fi" ? Translations_fi.PreviousCalendarYear : (culture === "sv-FI" ? Translations_sv_FI.PreviousCalendarYear : Translations_en_US.PreviousCalendarYear);
		Translations.SavingChartWasSuccessful = culture === "fi" ? Translations_fi.SavingChartWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingChartWasSuccessful : Translations_en_US.SavingChartWasSuccessful);
		Translations.DeletingTaskTypesParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingTaskTypesParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskTypesParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingTaskTypesParameterWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToRemoveSelectedRecordFurtherEducations = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedRecordFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedRecordFurtherEducations : Translations_en_US.DoYouReallyWantToRemoveSelectedRecordFurtherEducations);
		Translations.EditDocumentTemplate = culture === "fi" ? Translations_fi.EditDocumentTemplate : (culture === "sv-FI" ? Translations_sv_FI.EditDocumentTemplate : Translations_en_US.EditDocumentTemplate);
		Translations.ByName = culture === "fi" ? Translations_fi.ByName : (culture === "sv-FI" ? Translations_sv_FI.ByName : Translations_en_US.ByName);
		Translations.AddChildEventTypeC = culture === "fi" ? Translations_fi.AddChildEventTypeC : (culture === "sv-FI" ? Translations_sv_FI.AddChildEventTypeC : Translations_en_US.AddChildEventTypeC);
		Translations.ChartGenerationAborted = culture === "fi" ? Translations_fi.ChartGenerationAborted : (culture === "sv-FI" ? Translations_sv_FI.ChartGenerationAborted : Translations_en_US.ChartGenerationAborted);
		Translations.OtherSettings = culture === "fi" ? Translations_fi.OtherSettings : (culture === "sv-FI" ? Translations_sv_FI.OtherSettings : Translations_en_US.OtherSettings);
		Translations.DefaultHandlers = culture === "fi" ? Translations_fi.DefaultHandlers : (culture === "sv-FI" ? Translations_sv_FI.DefaultHandlers : Translations_en_US.DefaultHandlers);
		Translations.SavingUnitsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingUnitsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingUnitsWasNotSuccessfulErrorParameter : Translations_en_US.SavingUnitsWasNotSuccessfulErrorParameter);
		Translations.PasswordMustContainUppercaseChar = culture === "fi" ? Translations_fi.PasswordMustContainUppercaseChar : (culture === "sv-FI" ? Translations_sv_FI.PasswordMustContainUppercaseChar : Translations_en_US.PasswordMustContainUppercaseChar);
		Translations.ActionIsNotAuthorized = culture === "fi" ? Translations_fi.ActionIsNotAuthorized : (culture === "sv-FI" ? Translations_sv_FI.ActionIsNotAuthorized : Translations_en_US.ActionIsNotAuthorized);
		Translations.UserGroupsMustBeDefined = culture === "fi" ? Translations_fi.UserGroupsMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.UserGroupsMustBeDefined : Translations_en_US.UserGroupsMustBeDefined);
		Translations.FieldParameterValueCanOnlyContainNumbers = culture === "fi" ? Translations_fi.FieldParameterValueCanOnlyContainNumbers : (culture === "sv-FI" ? Translations_sv_FI.FieldParameterValueCanOnlyContainNumbers : Translations_en_US.FieldParameterValueCanOnlyContainNumbers);
		Translations.WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted = culture === "fi" ? Translations_fi.WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted : Translations_en_US.WhistleBlowCategoryParameterIsInUseInParameterWhistleBlowsAndCannotBeDeleted);
		Translations.SavingEventTypeParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventTypeParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventTypeParameterWasNotSuccessfulConcurrencyError);
		Translations.CustomStyleClass = culture === "fi" ? Translations_fi.CustomStyleClass : (culture === "sv-FI" ? Translations_sv_FI.CustomStyleClass : Translations_en_US.CustomStyleClass);
		Translations.SavingDirectoryItemPermissionsWasSuccessful = culture === "fi" ? Translations_fi.SavingDirectoryItemPermissionsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemPermissionsWasSuccessful : Translations_en_US.SavingDirectoryItemPermissionsWasSuccessful);
		Translations.SharedReport = culture === "fi" ? Translations_fi.SharedReport : (culture === "sv-FI" ? Translations_sv_FI.SharedReport : Translations_en_US.SharedReport);
		Translations.Operation = culture === "fi" ? Translations_fi.Operation : (culture === "sv-FI" ? Translations_sv_FI.Operation : Translations_en_US.Operation);
		Translations.RecordUserQualificationParameterNotFound = culture === "fi" ? Translations_fi.RecordUserQualificationParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordUserQualificationParameterNotFound : Translations_en_US.RecordUserQualificationParameterNotFound);
		Translations.DocumentNameParameterConstainsIllegalCharacters = culture === "fi" ? Translations_fi.DocumentNameParameterConstainsIllegalCharacters : (culture === "sv-FI" ? Translations_sv_FI.DocumentNameParameterConstainsIllegalCharacters : Translations_en_US.DocumentNameParameterConstainsIllegalCharacters);
		Translations.ExitInstantMessageChannel = culture === "fi" ? Translations_fi.ExitInstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.ExitInstantMessageChannel : Translations_en_US.ExitInstantMessageChannel);
		Translations.AddDefaultBasicEducation = culture === "fi" ? Translations_fi.AddDefaultBasicEducation : (culture === "sv-FI" ? Translations_sv_FI.AddDefaultBasicEducation : Translations_en_US.AddDefaultBasicEducation);
		Translations.SavingTaskPriorityParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingTaskPriorityParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskPriorityParameterWasSuccessful : Translations_en_US.SavingTaskPriorityParameterWasSuccessful);
		Translations.EditEventTypeB = culture === "fi" ? Translations_fi.EditEventTypeB : (culture === "sv-FI" ? Translations_sv_FI.EditEventTypeB : Translations_en_US.EditEventTypeB);
		Translations.ActiveTasks = culture === "fi" ? Translations_fi.ActiveTasks : (culture === "sv-FI" ? Translations_sv_FI.ActiveTasks : Translations_en_US.ActiveTasks);
		Translations.BannerPath = culture === "fi" ? Translations_fi.BannerPath : (culture === "sv-FI" ? Translations_sv_FI.BannerPath : Translations_en_US.BannerPath);
		Translations.ClearSubUnitDefaultHandlers = culture === "fi" ? Translations_fi.ClearSubUnitDefaultHandlers : (culture === "sv-FI" ? Translations_sv_FI.ClearSubUnitDefaultHandlers : Translations_en_US.ClearSubUnitDefaultHandlers);
		Translations.SavingEventSettingsWasSuccessful = culture === "fi" ? Translations_fi.SavingEventSettingsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventSettingsWasSuccessful : Translations_en_US.SavingEventSettingsWasSuccessful);
		Translations.CopyGroupPermissions = culture === "fi" ? Translations_fi.CopyGroupPermissions : (culture === "sv-FI" ? Translations_sv_FI.CopyGroupPermissions : Translations_en_US.CopyGroupPermissions);
		Translations.JobTypeEmail = culture === "fi" ? Translations_fi.JobTypeEmail : (culture === "sv-FI" ? Translations_sv_FI.JobTypeEmail : Translations_en_US.JobTypeEmail);
		Translations.UnpublishingEditableDocumentParameterWasSuccessful = culture === "fi" ? Translations_fi.UnpublishingEditableDocumentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.UnpublishingEditableDocumentParameterWasSuccessful : Translations_en_US.UnpublishingEditableDocumentParameterWasSuccessful);
		Translations.DoYouReallyWantToRemoveWhistleBlowParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveWhistleBlowParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveWhistleBlowParameter : Translations_en_US.DoYouReallyWantToRemoveWhistleBlowParameter);
		Translations.DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter);
		Translations.ModifyDateEndDate = culture === "fi" ? Translations_fi.ModifyDateEndDate : (culture === "sv-FI" ? Translations_sv_FI.ModifyDateEndDate : Translations_en_US.ModifyDateEndDate);
		Translations.AdminMustBeDefined = culture === "fi" ? Translations_fi.AdminMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.AdminMustBeDefined : Translations_en_US.AdminMustBeDefined);
		Translations.SaveAsFavorite = culture === "fi" ? Translations_fi.SaveAsFavorite : (culture === "sv-FI" ? Translations_sv_FI.SaveAsFavorite : Translations_en_US.SaveAsFavorite);
		Translations.Handlers = culture === "fi" ? Translations_fi.Handlers : (culture === "sv-FI" ? Translations_sv_FI.Handlers : Translations_en_US.Handlers);
		Translations.RenamingFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.RenamingFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.RenamingFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.RenamingFolderParameterWasNotSuccessfulErrorParameter);
		Translations.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError : Translations_en_US.MovingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError);
		Translations.SearchCondition = culture === "fi" ? Translations_fi.SearchCondition : (culture === "sv-FI" ? Translations_sv_FI.SearchCondition : Translations_en_US.SearchCondition);
		Translations.SavingWhistleBlowWasSuccessful = culture === "fi" ? Translations_fi.SavingWhistleBlowWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowWasSuccessful : Translations_en_US.SavingWhistleBlowWasSuccessful);
		Translations.LogEventTime = culture === "fi" ? Translations_fi.LogEventTime : (culture === "sv-FI" ? Translations_sv_FI.LogEventTime : Translations_en_US.LogEventTime);
		Translations.DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordQualificationsParameterWasNotSuccessfulConcurrencyError);
		Translations.NewUserMustBeSavedBeforeUserGroupsCanBeAdded = culture === "fi" ? Translations_fi.NewUserMustBeSavedBeforeUserGroupsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewUserMustBeSavedBeforeUserGroupsCanBeAdded : Translations_en_US.NewUserMustBeSavedBeforeUserGroupsCanBeAdded);
		Translations.InheritedContentFilter = culture === "fi" ? Translations_fi.InheritedContentFilter : (culture === "sv-FI" ? Translations_sv_FI.InheritedContentFilter : Translations_en_US.InheritedContentFilter);
		Translations.ActiveUserTasks = culture === "fi" ? Translations_fi.ActiveUserTasks : (culture === "sv-FI" ? Translations_sv_FI.ActiveUserTasks : Translations_en_US.ActiveUserTasks);
		Translations.DeletingTaskPrioritiesParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingTaskPrioritiesParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskPrioritiesParameterWasSuccessful : Translations_en_US.DeletingTaskPrioritiesParameterWasSuccessful);
		Translations.TrainingStartDateEnd = culture === "fi" ? Translations_fi.TrainingStartDateEnd : (culture === "sv-FI" ? Translations_sv_FI.TrainingStartDateEnd : Translations_en_US.TrainingStartDateEnd);
		Translations.DocumentsNotFound = culture === "fi" ? Translations_fi.DocumentsNotFound : (culture === "sv-FI" ? Translations_sv_FI.DocumentsNotFound : Translations_en_US.DocumentsNotFound);
		Translations.PushEndpoint = culture === "fi" ? Translations_fi.PushEndpoint : (culture === "sv-FI" ? Translations_sv_FI.PushEndpoint : Translations_en_US.PushEndpoint);
		Translations.NewUserGroupMustBeSavedBeforeUsersCanBeAdded = culture === "fi" ? Translations_fi.NewUserGroupMustBeSavedBeforeUsersCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewUserGroupMustBeSavedBeforeUsersCanBeAdded : Translations_en_US.NewUserGroupMustBeSavedBeforeUsersCanBeAdded);
		Translations.ReadReceiptGivenPlur = culture === "fi" ? Translations_fi.ReadReceiptGivenPlur : (culture === "sv-FI" ? Translations_sv_FI.ReadReceiptGivenPlur : Translations_en_US.ReadReceiptGivenPlur);
		Translations.AdministratorUserCodeMustBeDefined = culture === "fi" ? Translations_fi.AdministratorUserCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.AdministratorUserCodeMustBeDefined : Translations_en_US.AdministratorUserCodeMustBeDefined);
		Translations.AddFurtherEducation = culture === "fi" ? Translations_fi.AddFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.AddFurtherEducation : Translations_en_US.AddFurtherEducation);
		Translations.LargeSize = culture === "fi" ? Translations_fi.LargeSize : (culture === "sv-FI" ? Translations_sv_FI.LargeSize : Translations_en_US.LargeSize);
		Translations.DoYouReallyWantToMoveTaskParameterToTrashBin = culture === "fi" ? Translations_fi.DoYouReallyWantToMoveTaskParameterToTrashBin : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToMoveTaskParameterToTrashBin : Translations_en_US.DoYouReallyWantToMoveTaskParameterToTrashBin);
		Translations.SavingEventParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventParameterWasNotSuccessfulConcurrencyError);
		Translations.LanguageFinnish = culture === "fi" ? Translations_fi.LanguageFinnish : (culture === "sv-FI" ? Translations_sv_FI.LanguageFinnish : Translations_en_US.LanguageFinnish);
		Translations.DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingEventUrgencyClassesParameterWasNotSuccessfulConcurrencyError);
		Translations.MaxSearchResultCount = culture === "fi" ? Translations_fi.MaxSearchResultCount : (culture === "sv-FI" ? Translations_sv_FI.MaxSearchResultCount : Translations_en_US.MaxSearchResultCount);
		Translations.SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingDocumentTemplateParameterWasNotSuccessfulConcurrencyError);
		Translations.Hour = culture === "fi" ? Translations_fi.Hour : (culture === "sv-FI" ? Translations_sv_FI.Hour : Translations_en_US.Hour);
		Translations.UnitCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.UnitCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.UnitCodeIsAlreadyInUse : Translations_en_US.UnitCodeIsAlreadyInUse);
		Translations.CostCentreNumber = culture === "fi" ? Translations_fi.CostCentreNumber : (culture === "sv-FI" ? Translations_sv_FI.CostCentreNumber : Translations_en_US.CostCentreNumber);
		Translations.CopyDefaultHandlers = culture === "fi" ? Translations_fi.CopyDefaultHandlers : (culture === "sv-FI" ? Translations_sv_FI.CopyDefaultHandlers : Translations_en_US.CopyDefaultHandlers);
		Translations.ArgumentParameterIsNULL = culture === "fi" ? Translations_fi.ArgumentParameterIsNULL : (culture === "sv-FI" ? Translations_sv_FI.ArgumentParameterIsNULL : Translations_en_US.ArgumentParameterIsNULL);
		Translations.SelectEventPartner = culture === "fi" ? Translations_fi.SelectEventPartner : (culture === "sv-FI" ? Translations_sv_FI.SelectEventPartner : Translations_en_US.SelectEventPartner);
		Translations.CompanyGroupNameMustBeDefined = culture === "fi" ? Translations_fi.CompanyGroupNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.CompanyGroupNameMustBeDefined : Translations_en_US.CompanyGroupNameMustBeDefined);
		Translations.SavingUserGroupParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingUserGroupParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingUserGroupParameterWasSuccessful : Translations_en_US.SavingUserGroupParameterWasSuccessful);
		Translations.AddChildDefaultEventType = culture === "fi" ? Translations_fi.AddChildDefaultEventType : (culture === "sv-FI" ? Translations_sv_FI.AddChildDefaultEventType : Translations_en_US.AddChildDefaultEventType);
		Translations.EmploymentType = culture === "fi" ? Translations_fi.EmploymentType : (culture === "sv-FI" ? Translations_sv_FI.EmploymentType : Translations_en_US.EmploymentType);
		Translations.RemoveMessage = culture === "fi" ? Translations_fi.RemoveMessage : (culture === "sv-FI" ? Translations_sv_FI.RemoveMessage : Translations_en_US.RemoveMessage);
		Translations.UserInformation = culture === "fi" ? Translations_fi.UserInformation : (culture === "sv-FI" ? Translations_sv_FI.UserInformation : Translations_en_US.UserInformation);
		Translations.MessageTitleMustBeDefined = culture === "fi" ? Translations_fi.MessageTitleMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.MessageTitleMustBeDefined : Translations_en_US.MessageTitleMustBeDefined);
		Translations.DoYouReallyWantToRemoveDefaultEventHandlerParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveDefaultEventHandlerParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveDefaultEventHandlerParameter : Translations_en_US.DoYouReallyWantToRemoveDefaultEventHandlerParameter);
		Translations.SavingMessageTypeParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingMessageTypeParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageTypeParameterWasSuccessful : Translations_en_US.SavingMessageTypeParameterWasSuccessful);
		Translations.FileNameConstainsIllegalCharacters = culture === "fi" ? Translations_fi.FileNameConstainsIllegalCharacters : (culture === "sv-FI" ? Translations_sv_FI.FileNameConstainsIllegalCharacters : Translations_en_US.FileNameConstainsIllegalCharacters);
		Translations.AddEventTypeC = culture === "fi" ? Translations_fi.AddEventTypeC : (culture === "sv-FI" ? Translations_sv_FI.AddEventTypeC : Translations_en_US.AddEventTypeC);
		Translations.AddEventTypeB = culture === "fi" ? Translations_fi.AddEventTypeB : (culture === "sv-FI" ? Translations_sv_FI.AddEventTypeB : Translations_en_US.AddEventTypeB);
		Translations.DeletingDocumentTemplateParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingDocumentTemplateParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingDocumentTemplateParameterWasSuccessful : Translations_en_US.DeletingDocumentTemplateParameterWasSuccessful);
		Translations.SavingFilterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingFilterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingFilterWasNotSuccessfulErrorParameter : Translations_en_US.SavingFilterWasNotSuccessfulErrorParameter);
		Translations.View = culture === "fi" ? Translations_fi.View : (culture === "sv-FI" ? Translations_sv_FI.View : Translations_en_US.View);
		Translations.EmailsSentSuccessfully = culture === "fi" ? Translations_fi.EmailsSentSuccessfully : (culture === "sv-FI" ? Translations_sv_FI.EmailsSentSuccessfully : Translations_en_US.EmailsSentSuccessfully);
		Translations.ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError : Translations_en_US.ArchivingRecordUserFamiliarizationsWasNotSuccessfulConcurrencyError);
	}

	static setCulture2(culture: string) {
			Translations.LockingTaskParameterWasNotSuccessful = culture === "fi" ? Translations_fi.LockingTaskParameterWasNotSuccessful : (culture === "sv-FI" ? Translations_sv_FI.LockingTaskParameterWasNotSuccessful : Translations_en_US.LockingTaskParameterWasNotSuccessful);
			Translations.UnitParameterNotFound = culture === "fi" ? Translations_fi.UnitParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.UnitParameterNotFound : Translations_en_US.UnitParameterNotFound);
			Translations.MessageTypeCodeMustBeDefined = culture === "fi" ? Translations_fi.MessageTypeCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.MessageTypeCodeMustBeDefined : Translations_en_US.MessageTypeCodeMustBeDefined);
			Translations.DocumentModuleProperties = culture === "fi" ? Translations_fi.DocumentModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.DocumentModuleProperties : Translations_en_US.DocumentModuleProperties);
			Translations.AdminMessage = culture === "fi" ? Translations_fi.AdminMessage : (culture === "sv-FI" ? Translations_sv_FI.AdminMessage : Translations_en_US.AdminMessage);
			Translations.DeletingOptionParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingOptionParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingOptionParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingOptionParameterWasNotSuccessfulErrorParameter);
			Translations.DeletingDirectoryItemDocumentParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingDirectoryItemDocumentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingDirectoryItemDocumentParameterWasSuccessful : Translations_en_US.DeletingDirectoryItemDocumentParameterWasSuccessful);
			Translations.AddAttachment = culture === "fi" ? Translations_fi.AddAttachment : (culture === "sv-FI" ? Translations_sv_FI.AddAttachment : Translations_en_US.AddAttachment);
			Translations.DoYouWantToPublishEvent = culture === "fi" ? Translations_fi.DoYouWantToPublishEvent : (culture === "sv-FI" ? Translations_sv_FI.DoYouWantToPublishEvent : Translations_en_US.DoYouWantToPublishEvent);
			Translations.EventTypeCCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.EventTypeCCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCCodeIsAlreadyInUse : Translations_en_US.EventTypeCCodeIsAlreadyInUse);
			Translations.EditUser = culture === "fi" ? Translations_fi.EditUser : (culture === "sv-FI" ? Translations_sv_FI.EditUser : Translations_en_US.EditUser);
			Translations.SendMessage = culture === "fi" ? Translations_fi.SendMessage : (culture === "sv-FI" ? Translations_sv_FI.SendMessage : Translations_en_US.SendMessage);
			Translations.UnlockingEditableDocumentParameterWasNotSuccessful = culture === "fi" ? Translations_fi.UnlockingEditableDocumentParameterWasNotSuccessful : (culture === "sv-FI" ? Translations_sv_FI.UnlockingEditableDocumentParameterWasNotSuccessful : Translations_en_US.UnlockingEditableDocumentParameterWasNotSuccessful);
			Translations.JobTitleMustBeDefined = culture === "fi" ? Translations_fi.JobTitleMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.JobTitleMustBeDefined : Translations_en_US.JobTitleMustBeDefined);
			Translations.AddRecordUser = culture === "fi" ? Translations_fi.AddRecordUser : (culture === "sv-FI" ? Translations_sv_FI.AddRecordUser : Translations_en_US.AddRecordUser);
			Translations.JobParameterNotFound = culture === "fi" ? Translations_fi.JobParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.JobParameterNotFound : Translations_en_US.JobParameterNotFound);
			Translations.DefaultDeductionWithoutTax = culture === "fi" ? Translations_fi.DefaultDeductionWithoutTax : (culture === "sv-FI" ? Translations_sv_FI.DefaultDeductionWithoutTax : Translations_en_US.DefaultDeductionWithoutTax);
			Translations.Location = culture === "fi" ? Translations_fi.Location : (culture === "sv-FI" ? Translations_sv_FI.Location : Translations_en_US.Location);
			Translations.AddEventHandler = culture === "fi" ? Translations_fi.AddEventHandler : (culture === "sv-FI" ? Translations_sv_FI.AddEventHandler : Translations_en_US.AddEventHandler);
			Translations.RecordUserModuleProperties = culture === "fi" ? Translations_fi.RecordUserModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.RecordUserModuleProperties : Translations_en_US.RecordUserModuleProperties);
			Translations.Parameters = culture === "fi" ? Translations_fi.Parameters : (culture === "sv-FI" ? Translations_sv_FI.Parameters : Translations_en_US.Parameters);
			Translations.ApproveEventHandlerConfirmation2 = culture === "fi" ? Translations_fi.ApproveEventHandlerConfirmation2 : (culture === "sv-FI" ? Translations_sv_FI.ApproveEventHandlerConfirmation2 : Translations_en_US.ApproveEventHandlerConfirmation2);
			Translations.RemoveLogisticVoucherCustomer = culture === "fi" ? Translations_fi.RemoveLogisticVoucherCustomer : (culture === "sv-FI" ? Translations_sv_FI.RemoveLogisticVoucherCustomer : Translations_en_US.RemoveLogisticVoucherCustomer);
			Translations.DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordUserEmploymentParameterWasNotSuccessfulErrorParameter);
			Translations.JobLineDataIsEmpty = culture === "fi" ? Translations_fi.JobLineDataIsEmpty : (culture === "sv-FI" ? Translations_sv_FI.JobLineDataIsEmpty : Translations_en_US.JobLineDataIsEmpty);
			Translations.PanelSize4 = culture === "fi" ? Translations_fi.PanelSize4 : (culture === "sv-FI" ? Translations_sv_FI.PanelSize4 : Translations_en_US.PanelSize4);
			Translations.RecordUserFamiliarizationParameterNotFound = culture === "fi" ? Translations_fi.RecordUserFamiliarizationParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordUserFamiliarizationParameterNotFound : Translations_en_US.RecordUserFamiliarizationParameterNotFound);
			Translations.DraftCannotBeApprovedDoYouWantToSaveDraftAnyway = culture === "fi" ? Translations_fi.DraftCannotBeApprovedDoYouWantToSaveDraftAnyway : (culture === "sv-FI" ? Translations_sv_FI.DraftCannotBeApprovedDoYouWantToSaveDraftAnyway : Translations_en_US.DraftCannotBeApprovedDoYouWantToSaveDraftAnyway);
			Translations.AddDefaultEventType = culture === "fi" ? Translations_fi.AddDefaultEventType : (culture === "sv-FI" ? Translations_sv_FI.AddDefaultEventType : Translations_en_US.AddDefaultEventType);
			Translations.UserProfile = culture === "fi" ? Translations_fi.UserProfile : (culture === "sv-FI" ? Translations_sv_FI.UserProfile : Translations_en_US.UserProfile);
			Translations.DeletingUserGroupUsersOfCompanyParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingUserGroupUsersOfCompanyParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupUsersOfCompanyParameterWasSuccessful : Translations_en_US.DeletingUserGroupUsersOfCompanyParameterWasSuccessful);
			Translations.IgnoreFolderPatterns = culture === "fi" ? Translations_fi.IgnoreFolderPatterns : (culture === "sv-FI" ? Translations_sv_FI.IgnoreFolderPatterns : Translations_en_US.IgnoreFolderPatterns);
			Translations.AddPartner = culture === "fi" ? Translations_fi.AddPartner : (culture === "sv-FI" ? Translations_sv_FI.AddPartner : Translations_en_US.AddPartner);
			Translations.DoYouReallyWantToRemoveRecordQualificationParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveRecordQualificationParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveRecordQualificationParameter : Translations_en_US.DoYouReallyWantToRemoveRecordQualificationParameter);
			Translations.RemoveCompanyFromCompanyGroup = culture === "fi" ? Translations_fi.RemoveCompanyFromCompanyGroup : (culture === "sv-FI" ? Translations_sv_FI.RemoveCompanyFromCompanyGroup : Translations_en_US.RemoveCompanyFromCompanyGroup);
			Translations.DeletingEventTypeParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingEventTypeParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeParameterWasSuccessful : Translations_en_US.DeletingEventTypeParameterWasSuccessful);
			Translations.DeletingCompanyParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingCompanyParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingCompanyParameterWasNotSuccessfulConcurrencyError);
			Translations.Portrait = culture === "fi" ? Translations_fi.Portrait : (culture === "sv-FI" ? Translations_sv_FI.Portrait : Translations_en_US.Portrait);
			Translations.ApprovedEvent = culture === "fi" ? Translations_fi.ApprovedEvent : (culture === "sv-FI" ? Translations_sv_FI.ApprovedEvent : Translations_en_US.ApprovedEvent);
			Translations.DoYouReallyWantToReplaceDocumentTemplateParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToReplaceDocumentTemplateParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToReplaceDocumentTemplateParameter : Translations_en_US.DoYouReallyWantToReplaceDocumentTemplateParameter);
			Translations.ReadImpPlur = culture === "fi" ? Translations_fi.ReadImpPlur : (culture === "sv-FI" ? Translations_sv_FI.ReadImpPlur : Translations_en_US.ReadImpPlur);
			Translations.RemoveCompanyGroup = culture === "fi" ? Translations_fi.RemoveCompanyGroup : (culture === "sv-FI" ? Translations_sv_FI.RemoveCompanyGroup : Translations_en_US.RemoveCompanyGroup);
			Translations.LogOut = culture === "fi" ? Translations_fi.LogOut : (culture === "sv-FI" ? Translations_sv_FI.LogOut : Translations_en_US.LogOut);
			Translations.UserCodeUpdateWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.UserCodeUpdateWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.UserCodeUpdateWasNotSuccessfulErrorParameter : Translations_en_US.UserCodeUpdateWasNotSuccessfulErrorParameter);
			Translations.Target = culture === "fi" ? Translations_fi.Target : (culture === "sv-FI" ? Translations_sv_FI.Target : Translations_en_US.Target);
			Translations.BankBICCode = culture === "fi" ? Translations_fi.BankBICCode : (culture === "sv-FI" ? Translations_sv_FI.BankBICCode : Translations_en_US.BankBICCode);
			Translations.RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter : Translations_en_US.RestoringTaskParameterFromTrashBinWasNotSuccessfulErrorParameter);
			Translations.UserRecordStatisticsUserAccountConnectedCards = culture === "fi" ? Translations_fi.UserRecordStatisticsUserAccountConnectedCards : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsUserAccountConnectedCards : Translations_en_US.UserRecordStatisticsUserAccountConnectedCards);
			Translations.SavingEventPartnerParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingEventPartnerParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventPartnerParameterWasSuccessful : Translations_en_US.SavingEventPartnerParameterWasSuccessful);
			Translations.NewRow = culture === "fi" ? Translations_fi.NewRow : (culture === "sv-FI" ? Translations_sv_FI.NewRow : Translations_en_US.NewRow);
			Translations.WhistleBlowAnonymousReport = culture === "fi" ? Translations_fi.WhistleBlowAnonymousReport : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowAnonymousReport : Translations_en_US.WhistleBlowAnonymousReport);
			Translations.Code = culture === "fi" ? Translations_fi.Code : (culture === "sv-FI" ? Translations_sv_FI.Code : Translations_en_US.Code);
			Translations.UpdateUserGroupParameterExternalSystemsWasSuccessful = culture === "fi" ? Translations_fi.UpdateUserGroupParameterExternalSystemsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.UpdateUserGroupParameterExternalSystemsWasSuccessful : Translations_en_US.UpdateUserGroupParameterExternalSystemsWasSuccessful);
			Translations.Path = culture === "fi" ? Translations_fi.Path : (culture === "sv-FI" ? Translations_sv_FI.Path : Translations_en_US.Path);
			Translations.EmptyingTaskTrashBinWasSuccessfulErrorParameter = culture === "fi" ? Translations_fi.EmptyingTaskTrashBinWasSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.EmptyingTaskTrashBinWasSuccessfulErrorParameter : Translations_en_US.EmptyingTaskTrashBinWasSuccessfulErrorParameter);
			Translations.WhistleBlowChannelMustHaveAtLeastOneAdministrator = culture === "fi" ? Translations_fi.WhistleBlowChannelMustHaveAtLeastOneAdministrator : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowChannelMustHaveAtLeastOneAdministrator : Translations_en_US.WhistleBlowChannelMustHaveAtLeastOneAdministrator);
			Translations.SavingKeywordParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingKeywordParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingKeywordParameterWasSuccessful : Translations_en_US.SavingKeywordParameterWasSuccessful);
			Translations.AddWhistleBlowChannelAdminsParameterWasSuccessful = culture === "fi" ? Translations_fi.AddWhistleBlowChannelAdminsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddWhistleBlowChannelAdminsParameterWasSuccessful : Translations_en_US.AddWhistleBlowChannelAdminsParameterWasSuccessful);
			Translations.SavingInstantMessageChannelParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingInstantMessageChannelParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingInstantMessageChannelParameterWasSuccessful : Translations_en_US.SavingInstantMessageChannelParameterWasSuccessful);
			Translations.SelectTaskState = culture === "fi" ? Translations_fi.SelectTaskState : (culture === "sv-FI" ? Translations_sv_FI.SelectTaskState : Translations_en_US.SelectTaskState);
			Translations.ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter = culture === "fi" ? Translations_fi.ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter : (culture === "sv-FI" ? Translations_sv_FI.ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter : Translations_en_US.ActiveUserRightsCannotBeCopiedToDirectoryItemFolderParameter);
			Translations.MoneyOut = culture === "fi" ? Translations_fi.MoneyOut : (culture === "sv-FI" ? Translations_sv_FI.MoneyOut : Translations_en_US.MoneyOut);
			Translations.SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter);
			Translations.SearchTasks = culture === "fi" ? Translations_fi.SearchTasks : (culture === "sv-FI" ? Translations_sv_FI.SearchTasks : Translations_en_US.SearchTasks);
			Translations.SomeInstantMessageUsersHaveReadMessage = culture === "fi" ? Translations_fi.SomeInstantMessageUsersHaveReadMessage : (culture === "sv-FI" ? Translations_sv_FI.SomeInstantMessageUsersHaveReadMessage : Translations_en_US.SomeInstantMessageUsersHaveReadMessage);
			Translations.TaskState = culture === "fi" ? Translations_fi.TaskState : (culture === "sv-FI" ? Translations_sv_FI.TaskState : Translations_en_US.TaskState);
			Translations.AmountOfComments = culture === "fi" ? Translations_fi.AmountOfComments : (culture === "sv-FI" ? Translations_sv_FI.AmountOfComments : Translations_en_US.AmountOfComments);
			Translations.MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB = culture === "fi" ? Translations_fi.MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB : (culture === "sv-FI" ? Translations_sv_FI.MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB : Translations_en_US.MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB);
			Translations.FamiliarizationEndDate = culture === "fi" ? Translations_fi.FamiliarizationEndDate : (culture === "sv-FI" ? Translations_sv_FI.FamiliarizationEndDate : Translations_en_US.FamiliarizationEndDate);
			Translations.MessageDateMustBeDefined = culture === "fi" ? Translations_fi.MessageDateMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.MessageDateMustBeDefined : Translations_en_US.MessageDateMustBeDefined);
			Translations.GroupByMonth = culture === "fi" ? Translations_fi.GroupByMonth : (culture === "sv-FI" ? Translations_sv_FI.GroupByMonth : Translations_en_US.GroupByMonth);
			Translations.CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter : Translations_en_US.CheckingExistanceOfFileParameterWasNotSuccessfulErrorParameter);
			Translations.YouDeletedThisMessage = culture === "fi" ? Translations_fi.YouDeletedThisMessage : (culture === "sv-FI" ? Translations_sv_FI.YouDeletedThisMessage : Translations_en_US.YouDeletedThisMessage);
			Translations.EditRemainder = culture === "fi" ? Translations_fi.EditRemainder : (culture === "sv-FI" ? Translations_sv_FI.EditRemainder : Translations_en_US.EditRemainder);
			Translations.PrintParameterDownloaded = culture === "fi" ? Translations_fi.PrintParameterDownloaded : (culture === "sv-FI" ? Translations_sv_FI.PrintParameterDownloaded : Translations_en_US.PrintParameterDownloaded);
			Translations.PushNotificationInUse = culture === "fi" ? Translations_fi.PushNotificationInUse : (culture === "sv-FI" ? Translations_sv_FI.PushNotificationInUse : Translations_en_US.PushNotificationInUse);
			Translations.NewPanel = culture === "fi" ? Translations_fi.NewPanel : (culture === "sv-FI" ? Translations_sv_FI.NewPanel : Translations_en_US.NewPanel);
			Translations.UserRecordCards = culture === "fi" ? Translations_fi.UserRecordCards : (culture === "sv-FI" ? Translations_sv_FI.UserRecordCards : Translations_en_US.UserRecordCards);
			Translations.ReadReceipts = culture === "fi" ? Translations_fi.ReadReceipts : (culture === "sv-FI" ? Translations_sv_FI.ReadReceipts : Translations_en_US.ReadReceipts);
			Translations.DoYouReallyWantToRemoveSelectedTaskStates = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedTaskStates : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedTaskStates : Translations_en_US.DoYouReallyWantToRemoveSelectedTaskStates);
			Translations.WhistleBlowResolutionOther = culture === "fi" ? Translations_fi.WhistleBlowResolutionOther : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowResolutionOther : Translations_en_US.WhistleBlowResolutionOther);
			Translations.WhistleBlowSeverityVeryLow = culture === "fi" ? Translations_fi.WhistleBlowSeverityVeryLow : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSeverityVeryLow : Translations_en_US.WhistleBlowSeverityVeryLow);
			Translations.SentEmails = culture === "fi" ? Translations_fi.SentEmails : (culture === "sv-FI" ? Translations_sv_FI.SentEmails : Translations_en_US.SentEmails);
			Translations.SavingWhistleBlowChannelParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingWhistleBlowChannelParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowChannelParameterWasSuccessful : Translations_en_US.SavingWhistleBlowChannelParameterWasSuccessful);
			Translations.UserTypeClaimNotFound = culture === "fi" ? Translations_fi.UserTypeClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.UserTypeClaimNotFound : Translations_en_US.UserTypeClaimNotFound);
			Translations.DoYouReallyWantToRemoveThisInstantMessage = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveThisInstantMessage : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveThisInstantMessage : Translations_en_US.DoYouReallyWantToRemoveThisInstantMessage);
			Translations.DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingWhistleBlowParameterWasNotSuccessfulConcurrencyError);
			Translations.Rejected = culture === "fi" ? Translations_fi.Rejected : (culture === "sv-FI" ? Translations_sv_FI.Rejected : Translations_en_US.Rejected);
			Translations.SavingEventPartnerParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventPartnerParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventPartnerParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventPartnerParameterWasNotSuccessfulErrorParameter);
			Translations.DeletingAttachmentsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingAttachmentsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingAttachmentsParameterWasSuccessful : Translations_en_US.DeletingAttachmentsParameterWasSuccessful);
			Translations.DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordBasicEducationsParameterWasNotSuccessfulConcurrencyError);
			Translations.Reminders = culture === "fi" ? Translations_fi.Reminders : (culture === "sv-FI" ? Translations_sv_FI.Reminders : Translations_en_US.Reminders);
			Translations.NoMessagesToShow = culture === "fi" ? Translations_fi.NoMessagesToShow : (culture === "sv-FI" ? Translations_sv_FI.NoMessagesToShow : Translations_en_US.NoMessagesToShow);
			Translations.Returned = culture === "fi" ? Translations_fi.Returned : (culture === "sv-FI" ? Translations_sv_FI.Returned : Translations_en_US.Returned);
			Translations.DeletingInstantMessageWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingInstantMessageWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingInstantMessageWasNotSuccessfulConcurrencyError);
			Translations.SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingInstantMessageChannelParameterWasNotSuccessfulConcurrencyError);
			Translations.InternalAttachments = culture === "fi" ? Translations_fi.InternalAttachments : (culture === "sv-FI" ? Translations_sv_FI.InternalAttachments : Translations_en_US.InternalAttachments);
			Translations.RemoveReport = culture === "fi" ? Translations_fi.RemoveReport : (culture === "sv-FI" ? Translations_sv_FI.RemoveReport : Translations_en_US.RemoveReport);
			Translations.DoYouReallyWantToRemoveUserGroupParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveUserGroupParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveUserGroupParameter : Translations_en_US.DoYouReallyWantToRemoveUserGroupParameter);
			Translations.SavingPrintWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingPrintWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingPrintWasNotSuccessfulConcurrencyError : Translations_en_US.SavingPrintWasNotSuccessfulConcurrencyError);
			Translations.LoadMore = culture === "fi" ? Translations_fi.LoadMore : (culture === "sv-FI" ? Translations_sv_FI.LoadMore : Translations_en_US.LoadMore);
			Translations.Category = culture === "fi" ? Translations_fi.Category : (culture === "sv-FI" ? Translations_sv_FI.Category : Translations_en_US.Category);
			Translations.AddCompanyGroupsParameterWasSuccessful = culture === "fi" ? Translations_fi.AddCompanyGroupsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyGroupsParameterWasSuccessful : Translations_en_US.AddCompanyGroupsParameterWasSuccessful);
			Translations.AuthorizationLevelNone = culture === "fi" ? Translations_fi.AuthorizationLevelNone : (culture === "sv-FI" ? Translations_sv_FI.AuthorizationLevelNone : Translations_en_US.AuthorizationLevelNone);
			Translations.SavingUserGroupParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingUserGroupParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingUserGroupParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingUserGroupParameterWasNotSuccessfulConcurrencyError);
			Translations.ClearSubUnitDefaultHandlersAndNotifiedPersons = culture === "fi" ? Translations_fi.ClearSubUnitDefaultHandlersAndNotifiedPersons : (culture === "sv-FI" ? Translations_sv_FI.ClearSubUnitDefaultHandlersAndNotifiedPersons : Translations_en_US.ClearSubUnitDefaultHandlersAndNotifiedPersons);
			Translations.WhistleBlowChannelWasNotFoundExplanation = culture === "fi" ? Translations_fi.WhistleBlowChannelWasNotFoundExplanation : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowChannelWasNotFoundExplanation : Translations_en_US.WhistleBlowChannelWasNotFoundExplanation);
			Translations.AddingEventHandlerWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingEventHandlerWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingEventHandlerWasNotSuccessfulErrorParameter : Translations_en_US.AddingEventHandlerWasNotSuccessfulErrorParameter);
			Translations.NameIsAlreadyInUse = culture === "fi" ? Translations_fi.NameIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.NameIsAlreadyInUse : Translations_en_US.NameIsAlreadyInUse);
			Translations.Color = culture === "fi" ? Translations_fi.Color : (culture === "sv-FI" ? Translations_sv_FI.Color : Translations_en_US.Color);
			Translations.DeletingFileFilterParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingFileFilterParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingFileFilterParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingFileFilterParameterWasNotSuccessfulConcurrencyError);
			Translations.Unpublish = culture === "fi" ? Translations_fi.Unpublish : (culture === "sv-FI" ? Translations_sv_FI.Unpublish : Translations_en_US.Unpublish);
			Translations.DeletingUserParametersWasSuccessful = culture === "fi" ? Translations_fi.DeletingUserParametersWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserParametersWasSuccessful : Translations_en_US.DeletingUserParametersWasSuccessful);
			Translations.Schema = culture === "fi" ? Translations_fi.Schema : (culture === "sv-FI" ? Translations_sv_FI.Schema : Translations_en_US.Schema);
			Translations.SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingRecordEmploymentParameterWasNotSuccessfulConcurrencyError);
			Translations.EditDefaultBasicEducation = culture === "fi" ? Translations_fi.EditDefaultBasicEducation : (culture === "sv-FI" ? Translations_sv_FI.EditDefaultBasicEducation : Translations_en_US.EditDefaultBasicEducation);
			Translations.ExternalSystemUserCodeMustBeDefined = culture === "fi" ? Translations_fi.ExternalSystemUserCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemUserCodeMustBeDefined : Translations_en_US.ExternalSystemUserCodeMustBeDefined);
			Translations.Yesterday = culture === "fi" ? Translations_fi.Yesterday : (culture === "sv-FI" ? Translations_sv_FI.Yesterday : Translations_en_US.Yesterday);
			Translations.WhistleBlowNonAnonymousReport = culture === "fi" ? Translations_fi.WhistleBlowNonAnonymousReport : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowNonAnonymousReport : Translations_en_US.WhistleBlowNonAnonymousReport);
			Translations.SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter : Translations_en_US.SavingInstantMessageChannelUserWasNotSuccessfulErrorParameter);
			Translations.DocumentTypes = culture === "fi" ? Translations_fi.DocumentTypes : (culture === "sv-FI" ? Translations_sv_FI.DocumentTypes : Translations_en_US.DocumentTypes);
			Translations.ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter : Translations_en_US.ArchivingRecordUserFurtherEducationsWasNotSuccessfulErrorParameter);
			Translations.DocumentRootPathMustBeDefined = culture === "fi" ? Translations_fi.DocumentRootPathMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.DocumentRootPathMustBeDefined : Translations_en_US.DocumentRootPathMustBeDefined);
			Translations.WhistleBlowResolutionResolved = culture === "fi" ? Translations_fi.WhistleBlowResolutionResolved : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowResolutionResolved : Translations_en_US.WhistleBlowResolutionResolved);
			Translations.RemoveEvent = culture === "fi" ? Translations_fi.RemoveEvent : (culture === "sv-FI" ? Translations_sv_FI.RemoveEvent : Translations_en_US.RemoveEvent);
			Translations.EditBasicEducation = culture === "fi" ? Translations_fi.EditBasicEducation : (culture === "sv-FI" ? Translations_sv_FI.EditBasicEducation : Translations_en_US.EditBasicEducation);
			Translations.DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingTaskPrioritiesParameterWasNotSuccessfulErrorParameter);
			Translations.SavingFileFilterParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingFileFilterParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingFileFilterParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingFileFilterParameterWasNotSuccessfulConcurrencyError);
			Translations.PermissionsForSystems = culture === "fi" ? Translations_fi.PermissionsForSystems : (culture === "sv-FI" ? Translations_sv_FI.PermissionsForSystems : Translations_en_US.PermissionsForSystems);
			Translations.DraftCannotBeApproved = culture === "fi" ? Translations_fi.DraftCannotBeApproved : (culture === "sv-FI" ? Translations_sv_FI.DraftCannotBeApproved : Translations_en_US.DraftCannotBeApproved);
			Translations.AllWhistleBlowHandlersHaveReadMessage = culture === "fi" ? Translations_fi.AllWhistleBlowHandlersHaveReadMessage : (culture === "sv-FI" ? Translations_sv_FI.AllWhistleBlowHandlersHaveReadMessage : Translations_en_US.AllWhistleBlowHandlersHaveReadMessage);
			Translations.TaskTypeCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.TaskTypeCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.TaskTypeCodeIsAlreadyInUse : Translations_en_US.TaskTypeCodeIsAlreadyInUse);
			Translations.AdditionalPermissionARecords = culture === "fi" ? Translations_fi.AdditionalPermissionARecords : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissionARecords : Translations_en_US.AdditionalPermissionARecords);
			Translations.UseObserverAction = culture === "fi" ? Translations_fi.UseObserverAction : (culture === "sv-FI" ? Translations_sv_FI.UseObserverAction : Translations_en_US.UseObserverAction);
			Translations.Order = culture === "fi" ? Translations_fi.Order : (culture === "sv-FI" ? Translations_sv_FI.Order : Translations_en_US.Order);
			Translations.EventCreator = culture === "fi" ? Translations_fi.EventCreator : (culture === "sv-FI" ? Translations_sv_FI.EventCreator : Translations_en_US.EventCreator);
			Translations.AddUserGroup = culture === "fi" ? Translations_fi.AddUserGroup : (culture === "sv-FI" ? Translations_sv_FI.AddUserGroup : Translations_en_US.AddUserGroup);
			Translations.DeletingOneTimePasswordWasSuccessful = culture === "fi" ? Translations_fi.DeletingOneTimePasswordWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingOneTimePasswordWasSuccessful : Translations_en_US.DeletingOneTimePasswordWasSuccessful);
			Translations.SendingNotificationsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SendingNotificationsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SendingNotificationsWasNotSuccessfulErrorParameter : Translations_en_US.SendingNotificationsWasNotSuccessfulErrorParameter);
			Translations.DeletingEventPartnerParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingEventPartnerParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventPartnerParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingEventPartnerParameterWasNotSuccessfulErrorParameter);
			Translations.UserReport = culture === "fi" ? Translations_fi.UserReport : (culture === "sv-FI" ? Translations_sv_FI.UserReport : Translations_en_US.UserReport);
			Translations.UserPasswordMustBeDefined = culture === "fi" ? Translations_fi.UserPasswordMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.UserPasswordMustBeDefined : Translations_en_US.UserPasswordMustBeDefined);
			Translations.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulErrorParameter);
			Translations.CkickToChangeThePicture = culture === "fi" ? Translations_fi.CkickToChangeThePicture : (culture === "sv-FI" ? Translations_sv_FI.CkickToChangeThePicture : Translations_en_US.CkickToChangeThePicture);
			Translations.WhistleBlowChannelWasNotFound = culture === "fi" ? Translations_fi.WhistleBlowChannelWasNotFound : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowChannelWasNotFound : Translations_en_US.WhistleBlowChannelWasNotFound);
			Translations.AddingAddRecordUserTransferredPropertiesWasSuccessful = culture === "fi" ? Translations_fi.AddingAddRecordUserTransferredPropertiesWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingAddRecordUserTransferredPropertiesWasSuccessful : Translations_en_US.AddingAddRecordUserTransferredPropertiesWasSuccessful);
			Translations.SavingTaskStateParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingTaskStateParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskStateParameterWasSuccessful : Translations_en_US.SavingTaskStateParameterWasSuccessful);
			Translations.CompanyCodeClaimNotFound = culture === "fi" ? Translations_fi.CompanyCodeClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.CompanyCodeClaimNotFound : Translations_en_US.CompanyCodeClaimNotFound);
			Translations.AddFurtherEducations = culture === "fi" ? Translations_fi.AddFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.AddFurtherEducations : Translations_en_US.AddFurtherEducations);
			Translations.Info = culture === "fi" ? Translations_fi.Info : (culture === "sv-FI" ? Translations_sv_FI.Info : Translations_en_US.Info);
			Translations.AddHandlers = culture === "fi" ? Translations_fi.AddHandlers : (culture === "sv-FI" ? Translations_sv_FI.AddHandlers : Translations_en_US.AddHandlers);
			Translations.Date = culture === "fi" ? Translations_fi.Date : (culture === "sv-FI" ? Translations_sv_FI.Date : Translations_en_US.Date);
			Translations.EditEditableDocument = culture === "fi" ? Translations_fi.EditEditableDocument : (culture === "sv-FI" ? Translations_sv_FI.EditEditableDocument : Translations_en_US.EditEditableDocument);
			Translations.AdditionalPermissionBWhistleBlow = culture === "fi" ? Translations_fi.AdditionalPermissionBWhistleBlow : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissionBWhistleBlow : Translations_en_US.AdditionalPermissionBWhistleBlow);
			Translations.SavingFileFilterParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingFileFilterParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingFileFilterParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingFileFilterParameterWasNotSuccessfulErrorParameter);
			Translations.Error = culture === "fi" ? Translations_fi.Error : (culture === "sv-FI" ? Translations_sv_FI.Error : Translations_en_US.Error);
			Translations.UserParameterHasInvitedYouToJoinInstantMessageChannelParameter = culture === "fi" ? Translations_fi.UserParameterHasInvitedYouToJoinInstantMessageChannelParameter : (culture === "sv-FI" ? Translations_sv_FI.UserParameterHasInvitedYouToJoinInstantMessageChannelParameter : Translations_en_US.UserParameterHasInvitedYouToJoinInstantMessageChannelParameter);
			Translations.FileChangesCheckingAndEmailingFinishedInParameterSeconds = culture === "fi" ? Translations_fi.FileChangesCheckingAndEmailingFinishedInParameterSeconds : (culture === "sv-FI" ? Translations_sv_FI.FileChangesCheckingAndEmailingFinishedInParameterSeconds : Translations_en_US.FileChangesCheckingAndEmailingFinishedInParameterSeconds);
			Translations.InstantMessageUserInvitationAcceptTypeCanInvite = culture === "fi" ? Translations_fi.InstantMessageUserInvitationAcceptTypeCanInvite : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageUserInvitationAcceptTypeCanInvite : Translations_en_US.InstantMessageUserInvitationAcceptTypeCanInvite);
			Translations.ValidPasswordMustBeDefined = culture === "fi" ? Translations_fi.ValidPasswordMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.ValidPasswordMustBeDefined : Translations_en_US.ValidPasswordMustBeDefined);
			Translations.DoYouReallyWantToRemoveEventTypeParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveEventTypeParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveEventTypeParameter : Translations_en_US.DoYouReallyWantToRemoveEventTypeParameter);
			Translations.DownloadPdf = culture === "fi" ? Translations_fi.DownloadPdf : (culture === "sv-FI" ? Translations_sv_FI.DownloadPdf : Translations_en_US.DownloadPdf);
			Translations.EventPartnerParameterNotFound = culture === "fi" ? Translations_fi.EventPartnerParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EventPartnerParameterNotFound : Translations_en_US.EventPartnerParameterNotFound);
			Translations.WhistleBlowInformantHasReadMessage = culture === "fi" ? Translations_fi.WhistleBlowInformantHasReadMessage : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantHasReadMessage : Translations_en_US.WhistleBlowInformantHasReadMessage);
			Translations.UserIdClaimNotFound = culture === "fi" ? Translations_fi.UserIdClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.UserIdClaimNotFound : Translations_en_US.UserIdClaimNotFound);
			Translations.Other = culture === "fi" ? Translations_fi.Other : (culture === "sv-FI" ? Translations_sv_FI.Other : Translations_en_US.Other);
			Translations.AddTaskType = culture === "fi" ? Translations_fi.AddTaskType : (culture === "sv-FI" ? Translations_sv_FI.AddTaskType : Translations_en_US.AddTaskType);
			Translations.SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventDefaultHandlerParameterWasNotSuccessfulErrorParameter);
			Translations.ReportGenerationFailed = culture === "fi" ? Translations_fi.ReportGenerationFailed : (culture === "sv-FI" ? Translations_sv_FI.ReportGenerationFailed : Translations_en_US.ReportGenerationFailed);
			Translations.AccessRightNone = culture === "fi" ? Translations_fi.AccessRightNone : (culture === "sv-FI" ? Translations_sv_FI.AccessRightNone : Translations_en_US.AccessRightNone);
			Translations.ExternalIntraSystemUserTokenMustBeDefined = culture === "fi" ? Translations_fi.ExternalIntraSystemUserTokenMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.ExternalIntraSystemUserTokenMustBeDefined : Translations_en_US.ExternalIntraSystemUserTokenMustBeDefined);
			Translations.EventTypesMustBeDefinedBeforeAddingEvents = culture === "fi" ? Translations_fi.EventTypesMustBeDefinedBeforeAddingEvents : (culture === "sv-FI" ? Translations_sv_FI.EventTypesMustBeDefinedBeforeAddingEvents : Translations_en_US.EventTypesMustBeDefinedBeforeAddingEvents);
			Translations.DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingEventUrgencyClassesParameterWasNotSuccessfulErrorParameter);
			Translations.InstantMessagePartners = culture === "fi" ? Translations_fi.InstantMessagePartners : (culture === "sv-FI" ? Translations_sv_FI.InstantMessagePartners : Translations_en_US.InstantMessagePartners);
			Translations.MyFilters = culture === "fi" ? Translations_fi.MyFilters : (culture === "sv-FI" ? Translations_sv_FI.MyFilters : Translations_en_US.MyFilters);
			Translations.DefineRecordUserFurtherEducation = culture === "fi" ? Translations_fi.DefineRecordUserFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.DefineRecordUserFurtherEducation : Translations_en_US.DefineRecordUserFurtherEducation);
			Translations.InstantMessageAttachmentFolderParameterNotFound = culture === "fi" ? Translations_fi.InstantMessageAttachmentFolderParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageAttachmentFolderParameterNotFound : Translations_en_US.InstantMessageAttachmentFolderParameterNotFound);
			Translations.EditCompanyGroup = culture === "fi" ? Translations_fi.EditCompanyGroup : (culture === "sv-FI" ? Translations_sv_FI.EditCompanyGroup : Translations_en_US.EditCompanyGroup);
			Translations.EventTypeMustBeDefined = culture === "fi" ? Translations_fi.EventTypeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.EventTypeMustBeDefined : Translations_en_US.EventTypeMustBeDefined);
			Translations.RemoveEventType = culture === "fi" ? Translations_fi.RemoveEventType : (culture === "sv-FI" ? Translations_sv_FI.RemoveEventType : Translations_en_US.RemoveEventType);
			Translations.AccountNumberTkbInDebit = culture === "fi" ? Translations_fi.AccountNumberTkbInDebit : (culture === "sv-FI" ? Translations_sv_FI.AccountNumberTkbInDebit : Translations_en_US.AccountNumberTkbInDebit);
			Translations.MessageTypes = culture === "fi" ? Translations_fi.MessageTypes : (culture === "sv-FI" ? Translations_sv_FI.MessageTypes : Translations_en_US.MessageTypes);
			Translations.WhistleBlowEventTypeAttachmentsRemoved = culture === "fi" ? Translations_fi.WhistleBlowEventTypeAttachmentsRemoved : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowEventTypeAttachmentsRemoved : Translations_en_US.WhistleBlowEventTypeAttachmentsRemoved);
			Translations.LoggedInUserCannotBeDeleted = culture === "fi" ? Translations_fi.LoggedInUserCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.LoggedInUserCannotBeDeleted : Translations_en_US.LoggedInUserCannotBeDeleted);
			Translations.ExternalSystemCompanyTokenParameterIsAlreadyInUse = culture === "fi" ? Translations_fi.ExternalSystemCompanyTokenParameterIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemCompanyTokenParameterIsAlreadyInUse : Translations_en_US.ExternalSystemCompanyTokenParameterIsAlreadyInUse);
			Translations.ParameterIsNotValidAccountIBANNumber = culture === "fi" ? Translations_fi.ParameterIsNotValidAccountIBANNumber : (culture === "sv-FI" ? Translations_sv_FI.ParameterIsNotValidAccountIBANNumber : Translations_en_US.ParameterIsNotValidAccountIBANNumber);
			Translations.DeletingRecordFurtherEducationsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordFurtherEducationsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFurtherEducationsParameterWasSuccessful : Translations_en_US.DeletingRecordFurtherEducationsParameterWasSuccessful);
			Translations.DoYouReallyWantToRemoveDocumentTemplateParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveDocumentTemplateParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveDocumentTemplateParameter : Translations_en_US.DoYouReallyWantToRemoveDocumentTemplateParameter);
			Translations.CompanyWithSysAdminAuthorizationLevelNotFound = culture === "fi" ? Translations_fi.CompanyWithSysAdminAuthorizationLevelNotFound : (culture === "sv-FI" ? Translations_sv_FI.CompanyWithSysAdminAuthorizationLevelNotFound : Translations_en_US.CompanyWithSysAdminAuthorizationLevelNotFound);
			Translations.ToggleArchivedFurtherEducations = culture === "fi" ? Translations_fi.ToggleArchivedFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.ToggleArchivedFurtherEducations : Translations_en_US.ToggleArchivedFurtherEducations);
			Translations.FiscalNumber = culture === "fi" ? Translations_fi.FiscalNumber : (culture === "sv-FI" ? Translations_sv_FI.FiscalNumber : Translations_en_US.FiscalNumber);
			Translations.SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingRecordUserQualificationParameterWasNotSuccessfulConcurrencyError);
			Translations.DocumentProperties = culture === "fi" ? Translations_fi.DocumentProperties : (culture === "sv-FI" ? Translations_sv_FI.DocumentProperties : Translations_en_US.DocumentProperties);
			Translations.LastWeek = culture === "fi" ? Translations_fi.LastWeek : (culture === "sv-FI" ? Translations_sv_FI.LastWeek : Translations_en_US.LastWeek);
			Translations.RemovingEventHandlersWasSuccessful = culture === "fi" ? Translations_fi.RemovingEventHandlersWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.RemovingEventHandlersWasSuccessful : Translations_en_US.RemovingEventHandlersWasSuccessful);
			Translations.DeletingMetadataFromFileParameterFailed = culture === "fi" ? Translations_fi.DeletingMetadataFromFileParameterFailed : (culture === "sv-FI" ? Translations_sv_FI.DeletingMetadataFromFileParameterFailed : Translations_en_US.DeletingMetadataFromFileParameterFailed);
			Translations.IndirectCosts = culture === "fi" ? Translations_fi.IndirectCosts : (culture === "sv-FI" ? Translations_sv_FI.IndirectCosts : Translations_en_US.IndirectCosts);
			Translations.TrainingTime = culture === "fi" ? Translations_fi.TrainingTime : (culture === "sv-FI" ? Translations_sv_FI.TrainingTime : Translations_en_US.TrainingTime);
			Translations.EmailAddressMustBeValid = culture === "fi" ? Translations_fi.EmailAddressMustBeValid : (culture === "sv-FI" ? Translations_sv_FI.EmailAddressMustBeValid : Translations_en_US.EmailAddressMustBeValid);
			Translations.AddUser = culture === "fi" ? Translations_fi.AddUser : (culture === "sv-FI" ? Translations_sv_FI.AddUser : Translations_en_US.AddUser);
			Translations.SavingInstantMessageWasSuccessful = culture === "fi" ? Translations_fi.SavingInstantMessageWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingInstantMessageWasSuccessful : Translations_en_US.SavingInstantMessageWasSuccessful);
			Translations.FailureAudit = culture === "fi" ? Translations_fi.FailureAudit : (culture === "sv-FI" ? Translations_sv_FI.FailureAudit : Translations_en_US.FailureAudit);
			Translations.Approved = culture === "fi" ? Translations_fi.Approved : (culture === "sv-FI" ? Translations_sv_FI.Approved : Translations_en_US.Approved);
			Translations.FiltersStandard = culture === "fi" ? Translations_fi.FiltersStandard : (culture === "sv-FI" ? Translations_sv_FI.FiltersStandard : Translations_en_US.FiltersStandard);
			Translations.User = culture === "fi" ? Translations_fi.User : (culture === "sv-FI" ? Translations_sv_FI.User : Translations_en_US.User);
			Translations.CompanyToken = culture === "fi" ? Translations_fi.CompanyToken : (culture === "sv-FI" ? Translations_sv_FI.CompanyToken : Translations_en_US.CompanyToken);
			Translations.ShowArchived = culture === "fi" ? Translations_fi.ShowArchived : (culture === "sv-FI" ? Translations_sv_FI.ShowArchived : Translations_en_US.ShowArchived);
			Translations.InstantMessageChannelMustHaveAtLeastOneAdministrator = culture === "fi" ? Translations_fi.InstantMessageChannelMustHaveAtLeastOneAdministrator : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelMustHaveAtLeastOneAdministrator : Translations_en_US.InstantMessageChannelMustHaveAtLeastOneAdministrator);
			Translations.AddQualificationToMultipleUserRecords = culture === "fi" ? Translations_fi.AddQualificationToMultipleUserRecords : (culture === "sv-FI" ? Translations_sv_FI.AddQualificationToMultipleUserRecords : Translations_en_US.AddQualificationToMultipleUserRecords);
			Translations.PasteGroupPermissions = culture === "fi" ? Translations_fi.PasteGroupPermissions : (culture === "sv-FI" ? Translations_sv_FI.PasteGroupPermissions : Translations_en_US.PasteGroupPermissions);
			Translations.SavingEditableDocumentParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEditableDocumentParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEditableDocumentParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingEditableDocumentParameterWasNotSuccessfulErrorParameter);
			Translations.TaskStateParameterNotFound = culture === "fi" ? Translations_fi.TaskStateParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.TaskStateParameterNotFound : Translations_en_US.TaskStateParameterNotFound);
			Translations.FileFilterCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.FileFilterCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.FileFilterCodeIsAlreadyInUse : Translations_en_US.FileFilterCodeIsAlreadyInUse);
			Translations.RemoveEventHandler = culture === "fi" ? Translations_fi.RemoveEventHandler : (culture === "sv-FI" ? Translations_sv_FI.RemoveEventHandler : Translations_en_US.RemoveEventHandler);
			Translations.IdentificationDetails = culture === "fi" ? Translations_fi.IdentificationDetails : (culture === "sv-FI" ? Translations_sv_FI.IdentificationDetails : Translations_en_US.IdentificationDetails);
			Translations.ThisChannelHasBeenArchived = culture === "fi" ? Translations_fi.ThisChannelHasBeenArchived : (culture === "sv-FI" ? Translations_sv_FI.ThisChannelHasBeenArchived : Translations_en_US.ThisChannelHasBeenArchived);
			Translations.WhistleBlowEventTypeCreated = culture === "fi" ? Translations_fi.WhistleBlowEventTypeCreated : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowEventTypeCreated : Translations_en_US.WhistleBlowEventTypeCreated);
			Translations.NotApproved = culture === "fi" ? Translations_fi.NotApproved : (culture === "sv-FI" ? Translations_sv_FI.NotApproved : Translations_en_US.NotApproved);
			Translations.EditTransferredProperty = culture === "fi" ? Translations_fi.EditTransferredProperty : (culture === "sv-FI" ? Translations_sv_FI.EditTransferredProperty : Translations_en_US.EditTransferredProperty);
			Translations.RemoveFamiliarization = culture === "fi" ? Translations_fi.RemoveFamiliarization : (culture === "sv-FI" ? Translations_sv_FI.RemoveFamiliarization : Translations_en_US.RemoveFamiliarization);
			Translations.LanguageNonLocalEnglish = culture === "fi" ? Translations_fi.LanguageNonLocalEnglish : (culture === "sv-FI" ? Translations_sv_FI.LanguageNonLocalEnglish : Translations_en_US.LanguageNonLocalEnglish);
			Translations.SavingUserParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingUserParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingUserParameterWasNotSuccessfulErrorParameter);
			Translations.NotificationSettings = culture === "fi" ? Translations_fi.NotificationSettings : (culture === "sv-FI" ? Translations_sv_FI.NotificationSettings : Translations_en_US.NotificationSettings);
			Translations.SaveAndEmail = culture === "fi" ? Translations_fi.SaveAndEmail : (culture === "sv-FI" ? Translations_sv_FI.SaveAndEmail : Translations_en_US.SaveAndEmail);
			Translations.AddWhistleBlowChannelAdminParameterWasSuccessful = culture === "fi" ? Translations_fi.AddWhistleBlowChannelAdminParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddWhistleBlowChannelAdminParameterWasSuccessful : Translations_en_US.AddWhistleBlowChannelAdminParameterWasSuccessful);
			Translations.TaxNumber = culture === "fi" ? Translations_fi.TaxNumber : (culture === "sv-FI" ? Translations_sv_FI.TaxNumber : Translations_en_US.TaxNumber);
			Translations.GroupByUnit = culture === "fi" ? Translations_fi.GroupByUnit : (culture === "sv-FI" ? Translations_sv_FI.GroupByUnit : Translations_en_US.GroupByUnit);
			Translations.AddPayer = culture === "fi" ? Translations_fi.AddPayer : (culture === "sv-FI" ? Translations_sv_FI.AddPayer : Translations_en_US.AddPayer);
			Translations.EmailAddressParameterIsInvalid = culture === "fi" ? Translations_fi.EmailAddressParameterIsInvalid : (culture === "sv-FI" ? Translations_sv_FI.EmailAddressParameterIsInvalid : Translations_en_US.EmailAddressParameterIsInvalid);
			Translations.LoggedInUserAccountIsNotActive = culture === "fi" ? Translations_fi.LoggedInUserAccountIsNotActive : (culture === "sv-FI" ? Translations_sv_FI.LoggedInUserAccountIsNotActive : Translations_en_US.LoggedInUserAccountIsNotActive);
			Translations.DeletingInstantMessageWasSuccessful = culture === "fi" ? Translations_fi.DeletingInstantMessageWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageWasSuccessful : Translations_en_US.DeletingInstantMessageWasSuccessful);
			Translations.EditTask = culture === "fi" ? Translations_fi.EditTask : (culture === "sv-FI" ? Translations_sv_FI.EditTask : Translations_en_US.EditTask);
			Translations.DirectoryItemDocumentsNotFound = culture === "fi" ? Translations_fi.DirectoryItemDocumentsNotFound : (culture === "sv-FI" ? Translations_sv_FI.DirectoryItemDocumentsNotFound : Translations_en_US.DirectoryItemDocumentsNotFound);
			Translations.RemoveFileFilter = culture === "fi" ? Translations_fi.RemoveFileFilter : (culture === "sv-FI" ? Translations_sv_FI.RemoveFileFilter : Translations_en_US.RemoveFileFilter);
			Translations.RecordUserEmploymentParameterNotFound = culture === "fi" ? Translations_fi.RecordUserEmploymentParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordUserEmploymentParameterNotFound : Translations_en_US.RecordUserEmploymentParameterNotFound);
			Translations.ExportToExcel = culture === "fi" ? Translations_fi.ExportToExcel : (culture === "sv-FI" ? Translations_sv_FI.ExportToExcel : Translations_en_US.ExportToExcel);
			Translations.AddFolder = culture === "fi" ? Translations_fi.AddFolder : (culture === "sv-FI" ? Translations_sv_FI.AddFolder : Translations_en_US.AddFolder);
			Translations.UsersOfThisCompany = culture === "fi" ? Translations_fi.UsersOfThisCompany : (culture === "sv-FI" ? Translations_sv_FI.UsersOfThisCompany : Translations_en_US.UsersOfThisCompany);
			Translations.EventHandler = culture === "fi" ? Translations_fi.EventHandler : (culture === "sv-FI" ? Translations_sv_FI.EventHandler : Translations_en_US.EventHandler);
			Translations.SaveAndPublish = culture === "fi" ? Translations_fi.SaveAndPublish : (culture === "sv-FI" ? Translations_sv_FI.SaveAndPublish : Translations_en_US.SaveAndPublish);
			Translations.TrainingEndDate = culture === "fi" ? Translations_fi.TrainingEndDate : (culture === "sv-FI" ? Translations_sv_FI.TrainingEndDate : Translations_en_US.TrainingEndDate);
			Translations.ReferenceNumberPrefix = culture === "fi" ? Translations_fi.ReferenceNumberPrefix : (culture === "sv-FI" ? Translations_sv_FI.ReferenceNumberPrefix : Translations_en_US.ReferenceNumberPrefix);
			Translations.EmailNotificationInUse = culture === "fi" ? Translations_fi.EmailNotificationInUse : (culture === "sv-FI" ? Translations_sv_FI.EmailNotificationInUse : Translations_en_US.EmailNotificationInUse);
			Translations.WhistleBlowSourcePhone = culture === "fi" ? Translations_fi.WhistleBlowSourcePhone : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSourcePhone : Translations_en_US.WhistleBlowSourcePhone);
			Translations.RecordUserExcelQualification = culture === "fi" ? Translations_fi.RecordUserExcelQualification : (culture === "sv-FI" ? Translations_sv_FI.RecordUserExcelQualification : Translations_en_US.RecordUserExcelQualification);
			Translations.BasicEducations = culture === "fi" ? Translations_fi.BasicEducations : (culture === "sv-FI" ? Translations_sv_FI.BasicEducations : Translations_en_US.BasicEducations);
			Translations.SavingUserParameterParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingUserParameterParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParameterParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingUserParameterParameterWasNotSuccessfulErrorParameter);
			Translations.RemoveQualification = culture === "fi" ? Translations_fi.RemoveQualification : (culture === "sv-FI" ? Translations_sv_FI.RemoveQualification : Translations_en_US.RemoveQualification);
			Translations.TransportCompany = culture === "fi" ? Translations_fi.TransportCompany : (culture === "sv-FI" ? Translations_sv_FI.TransportCompany : Translations_en_US.TransportCompany);
			Translations.DeletingCompanyParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingCompanyParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyParameterWasSuccessful : Translations_en_US.DeletingCompanyParameterWasSuccessful);
			Translations.DefaultFamiliarizations = culture === "fi" ? Translations_fi.DefaultFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.DefaultFamiliarizations : Translations_en_US.DefaultFamiliarizations);
			Translations.Identifier = culture === "fi" ? Translations_fi.Identifier : (culture === "sv-FI" ? Translations_sv_FI.Identifier : Translations_en_US.Identifier);
			Translations.DeletingKeywordParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingKeywordParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingKeywordParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingKeywordParameterWasNotSuccessfulConcurrencyError);
			Translations.WhistleBlowPasswordConfirmed = culture === "fi" ? Translations_fi.WhistleBlowPasswordConfirmed : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowPasswordConfirmed : Translations_en_US.WhistleBlowPasswordConfirmed);
			Translations.NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded = culture === "fi" ? Translations_fi.NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded : Translations_en_US.NewRecordUserMustBeSavedBeforeTransferredPropertiesCanBeAdded);
			Translations.EventTypeParameterNotFound = culture === "fi" ? Translations_fi.EventTypeParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EventTypeParameterNotFound : Translations_en_US.EventTypeParameterNotFound);
			Translations.EventsExcel = culture === "fi" ? Translations_fi.EventsExcel : (culture === "sv-FI" ? Translations_sv_FI.EventsExcel : Translations_en_US.EventsExcel);
			Translations.AllSelected = culture === "fi" ? Translations_fi.AllSelected : (culture === "sv-FI" ? Translations_sv_FI.AllSelected : Translations_en_US.AllSelected);
			Translations.AddPartnersParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.AddPartnersParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.AddPartnersParameterWasNotSuccessfulConcurrencyError : Translations_en_US.AddPartnersParameterWasNotSuccessfulConcurrencyError);
			Translations.AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter : Translations_en_US.AddingAddRecordUserFurtherEducationsWasNotSuccessfulErrorParameter);
			Translations.RemoveRecordUserAndKeepUser = culture === "fi" ? Translations_fi.RemoveRecordUserAndKeepUser : (culture === "sv-FI" ? Translations_sv_FI.RemoveRecordUserAndKeepUser : Translations_en_US.RemoveRecordUserAndKeepUser);
			Translations.SelectUserGroupOrUser = culture === "fi" ? Translations_fi.SelectUserGroupOrUser : (culture === "sv-FI" ? Translations_sv_FI.SelectUserGroupOrUser : Translations_en_US.SelectUserGroupOrUser);
			Translations.ModifyDate = culture === "fi" ? Translations_fi.ModifyDate : (culture === "sv-FI" ? Translations_sv_FI.ModifyDate : Translations_en_US.ModifyDate);
			Translations.DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingWhistleBlowCategoryParameterWasNotSuccessfulConcurrencyError);
			Translations.UserToken = culture === "fi" ? Translations_fi.UserToken : (culture === "sv-FI" ? Translations_sv_FI.UserToken : Translations_en_US.UserToken);
			Translations.EditableDocumentParameterNotFound = culture === "fi" ? Translations_fi.EditableDocumentParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentParameterNotFound : Translations_en_US.EditableDocumentParameterNotFound);
			Translations.WholeHistory = culture === "fi" ? Translations_fi.WholeHistory : (culture === "sv-FI" ? Translations_sv_FI.WholeHistory : Translations_en_US.WholeHistory);
			Translations.RestoringTaskParameterFromTrashBinWasSuccessful = culture === "fi" ? Translations_fi.RestoringTaskParameterFromTrashBinWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.RestoringTaskParameterFromTrashBinWasSuccessful : Translations_en_US.RestoringTaskParameterFromTrashBinWasSuccessful);
			Translations.WriteMessage = culture === "fi" ? Translations_fi.WriteMessage : (culture === "sv-FI" ? Translations_sv_FI.WriteMessage : Translations_en_US.WriteMessage);
			Translations.RemoveDefaultTaskType = culture === "fi" ? Translations_fi.RemoveDefaultTaskType : (culture === "sv-FI" ? Translations_sv_FI.RemoveDefaultTaskType : Translations_en_US.RemoveDefaultTaskType);
			Translations.VersionProperties = culture === "fi" ? Translations_fi.VersionProperties : (culture === "sv-FI" ? Translations_sv_FI.VersionProperties : Translations_en_US.VersionProperties);
			Translations.Publish = culture === "fi" ? Translations_fi.Publish : (culture === "sv-FI" ? Translations_sv_FI.Publish : Translations_en_US.Publish);
			Translations.CopyActiveUserRightsToAllSameLevelFolders = culture === "fi" ? Translations_fi.CopyActiveUserRightsToAllSameLevelFolders : (culture === "sv-FI" ? Translations_sv_FI.CopyActiveUserRightsToAllSameLevelFolders : Translations_en_US.CopyActiveUserRightsToAllSameLevelFolders);
			Translations.Handling = culture === "fi" ? Translations_fi.Handling : (culture === "sv-FI" ? Translations_sv_FI.Handling : Translations_en_US.Handling);
			Translations.WhistleBlowReceiver = culture === "fi" ? Translations_fi.WhistleBlowReceiver : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowReceiver : Translations_en_US.WhistleBlowReceiver);
			Translations.CopyDefaultHandlersToAllSameLevelUnits = culture === "fi" ? Translations_fi.CopyDefaultHandlersToAllSameLevelUnits : (culture === "sv-FI" ? Translations_sv_FI.CopyDefaultHandlersToAllSameLevelUnits : Translations_en_US.CopyDefaultHandlersToAllSameLevelUnits);
			Translations.DeletingUserParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingUserParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingUserParameterWasNotSuccessfulConcurrencyError);
			Translations.SavingMessageParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingMessageParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageParameterWasSuccessful : Translations_en_US.SavingMessageParameterWasSuccessful);
			Translations.SelectReceiver = culture === "fi" ? Translations_fi.SelectReceiver : (culture === "sv-FI" ? Translations_sv_FI.SelectReceiver : Translations_en_US.SelectReceiver);
			Translations.AllCompanyGroups = culture === "fi" ? Translations_fi.AllCompanyGroups : (culture === "sv-FI" ? Translations_sv_FI.AllCompanyGroups : Translations_en_US.AllCompanyGroups);
			Translations.AuthorizationLevelSystemAdmin = culture === "fi" ? Translations_fi.AuthorizationLevelSystemAdmin : (culture === "sv-FI" ? Translations_sv_FI.AuthorizationLevelSystemAdmin : Translations_en_US.AuthorizationLevelSystemAdmin);
			Translations.TaskDataRow = culture === "fi" ? Translations_fi.TaskDataRow : (culture === "sv-FI" ? Translations_sv_FI.TaskDataRow : Translations_en_US.TaskDataRow);
			Translations.Name = culture === "fi" ? Translations_fi.Name : (culture === "sv-FI" ? Translations_sv_FI.Name : Translations_en_US.Name);
			Translations.DoYouReallyWantToRemovePrintParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemovePrintParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemovePrintParameter : Translations_en_US.DoYouReallyWantToRemovePrintParameter);
			Translations.MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError : Translations_en_US.MovingTaskParameterToTrashBinWasNotSuccessfulConcurrencyError);
			Translations.CompanyGroupCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.CompanyGroupCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.CompanyGroupCodeIsAlreadyInUse : Translations_en_US.CompanyGroupCodeIsAlreadyInUse);
			Translations.KeywordCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.KeywordCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.KeywordCodeIsAlreadyInUse : Translations_en_US.KeywordCodeIsAlreadyInUse);
			Translations.SelectRecordUsers = culture === "fi" ? Translations_fi.SelectRecordUsers : (culture === "sv-FI" ? Translations_sv_FI.SelectRecordUsers : Translations_en_US.SelectRecordUsers);
			Translations.SSRSParameterReportsFetched = culture === "fi" ? Translations_fi.SSRSParameterReportsFetched : (culture === "sv-FI" ? Translations_sv_FI.SSRSParameterReportsFetched : Translations_en_US.SSRSParameterReportsFetched);
			Translations.DoYouReallyWantToRemoveMessageParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveMessageParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveMessageParameter : Translations_en_US.DoYouReallyWantToRemoveMessageParameter);
			Translations.InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel = culture === "fi" ? Translations_fi.InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel : Translations_en_US.InstantMessageChannelOnlyAdministratorCannotExitInstantMessageChannel);
			Translations.RemoveUserGroupExternalSystemsWasSuccessful = culture === "fi" ? Translations_fi.RemoveUserGroupExternalSystemsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.RemoveUserGroupExternalSystemsWasSuccessful : Translations_en_US.RemoveUserGroupExternalSystemsWasSuccessful);
			Translations.NameMustBeDefined = culture === "fi" ? Translations_fi.NameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.NameMustBeDefined : Translations_en_US.NameMustBeDefined);
			Translations.Permission = culture === "fi" ? Translations_fi.Permission : (culture === "sv-FI" ? Translations_sv_FI.Permission : Translations_en_US.Permission);
			Translations.ArchivingRecordUserFurtherEducationsWasSuccessful = culture === "fi" ? Translations_fi.ArchivingRecordUserFurtherEducationsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.ArchivingRecordUserFurtherEducationsWasSuccessful : Translations_en_US.ArchivingRecordUserFurtherEducationsWasSuccessful);
			Translations.ProcessedFolderParameterErrorCountParameter = culture === "fi" ? Translations_fi.ProcessedFolderParameterErrorCountParameter : (culture === "sv-FI" ? Translations_sv_FI.ProcessedFolderParameterErrorCountParameter : Translations_en_US.ProcessedFolderParameterErrorCountParameter);
			Translations.RelativeSpecification = culture === "fi" ? Translations_fi.RelativeSpecification : (culture === "sv-FI" ? Translations_sv_FI.RelativeSpecification : Translations_en_US.RelativeSpecification);
			Translations.AddUsersParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddUsersParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddUsersParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddUsersParameterWasNotSuccessfulErrorParameter);
			Translations.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter);
			Translations.AddUsersParameterWasSuccessful = culture === "fi" ? Translations_fi.AddUsersParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddUsersParameterWasSuccessful : Translations_en_US.AddUsersParameterWasSuccessful);
			Translations.TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted = culture === "fi" ? Translations_fi.TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted : Translations_en_US.TaskPriorityParameterIsInUseInParameterTasksAndCannotBeDeleted);
			Translations.CurrentWorkPlace = culture === "fi" ? Translations_fi.CurrentWorkPlace : (culture === "sv-FI" ? Translations_sv_FI.CurrentWorkPlace : Translations_en_US.CurrentWorkPlace);
			Translations.JobResultParameterNotFound = culture === "fi" ? Translations_fi.JobResultParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.JobResultParameterNotFound : Translations_en_US.JobResultParameterNotFound);
			Translations.GetPhoto = culture === "fi" ? Translations_fi.GetPhoto : (culture === "sv-FI" ? Translations_sv_FI.GetPhoto : Translations_en_US.GetPhoto);
			Translations.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError : Translations_en_US.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError);
			Translations.RemoveKeyword = culture === "fi" ? Translations_fi.RemoveKeyword : (culture === "sv-FI" ? Translations_sv_FI.RemoveKeyword : Translations_en_US.RemoveKeyword);
			Translations.DeletingRecordUserTransferredPropertiesParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordUserTransferredPropertiesParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserTransferredPropertiesParameterWasSuccessful : Translations_en_US.DeletingRecordUserTransferredPropertiesParameterWasSuccessful);
			Translations.InstantMessageChannelArchivingType = culture === "fi" ? Translations_fi.InstantMessageChannelArchivingType : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelArchivingType : Translations_en_US.InstantMessageChannelArchivingType);
			Translations.WhistleBlowSourceSystem = culture === "fi" ? Translations_fi.WhistleBlowSourceSystem : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSourceSystem : Translations_en_US.WhistleBlowSourceSystem);
			Translations.WhistleBlowReportHasBeenSaved = culture === "fi" ? Translations_fi.WhistleBlowReportHasBeenSaved : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowReportHasBeenSaved : Translations_en_US.WhistleBlowReportHasBeenSaved);
			Translations.PrintTextPublished = culture === "fi" ? Translations_fi.PrintTextPublished : (culture === "sv-FI" ? Translations_sv_FI.PrintTextPublished : Translations_en_US.PrintTextPublished);
			Translations.Internet = culture === "fi" ? Translations_fi.Internet : (culture === "sv-FI" ? Translations_sv_FI.Internet : Translations_en_US.Internet);
			Translations.UserIsActiveInWeb = culture === "fi" ? Translations_fi.UserIsActiveInWeb : (culture === "sv-FI" ? Translations_sv_FI.UserIsActiveInWeb : Translations_en_US.UserIsActiveInWeb);
			Translations.AuthorizationLevelUser = culture === "fi" ? Translations_fi.AuthorizationLevelUser : (culture === "sv-FI" ? Translations_sv_FI.AuthorizationLevelUser : Translations_en_US.AuthorizationLevelUser);
			Translations.EventTypeCCodeMustBeDefined = culture === "fi" ? Translations_fi.EventTypeCCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCCodeMustBeDefined : Translations_en_US.EventTypeCCodeMustBeDefined);
			Translations.InstantMessage = culture === "fi" ? Translations_fi.InstantMessage : (culture === "sv-FI" ? Translations_sv_FI.InstantMessage : Translations_en_US.InstantMessage);
			Translations.ChangingPasswordWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.ChangingPasswordWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.ChangingPasswordWasNotSuccessfulConcurrencyError : Translations_en_US.ChangingPasswordWasNotSuccessfulConcurrencyError);
			Translations.InheritedUserRight = culture === "fi" ? Translations_fi.InheritedUserRight : (culture === "sv-FI" ? Translations_sv_FI.InheritedUserRight : Translations_en_US.InheritedUserRight);
			Translations.SaveFilterAs = culture === "fi" ? Translations_fi.SaveFilterAs : (culture === "sv-FI" ? Translations_sv_FI.SaveFilterAs : Translations_en_US.SaveFilterAs);
			Translations.All = culture === "fi" ? Translations_fi.All : (culture === "sv-FI" ? Translations_sv_FI.All : Translations_en_US.All);
			Translations.EditCompanyTaskType = culture === "fi" ? Translations_fi.EditCompanyTaskType : (culture === "sv-FI" ? Translations_sv_FI.EditCompanyTaskType : Translations_en_US.EditCompanyTaskType);
			Translations.DoYouReallyWantToRemoveSelectedUsers = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedUsers : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedUsers : Translations_en_US.DoYouReallyWantToRemoveSelectedUsers);
			Translations.LockedDocumentParameterCannotBeDeleted = culture === "fi" ? Translations_fi.LockedDocumentParameterCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.LockedDocumentParameterCannotBeDeleted : Translations_en_US.LockedDocumentParameterCannotBeDeleted);
			Translations.BrowseFiles = culture === "fi" ? Translations_fi.BrowseFiles : (culture === "sv-FI" ? Translations_sv_FI.BrowseFiles : Translations_en_US.BrowseFiles);
			Translations.SavingUserParameterParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingUserParameterParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParameterParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingUserParameterParameterWasNotSuccessfulConcurrencyError);
			Translations.SavingEventTypeCsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventTypeCsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeCsWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventTypeCsWasNotSuccessfulErrorParameter);
			Translations.LogisticVoucherCustomer = culture === "fi" ? Translations_fi.LogisticVoucherCustomer : (culture === "sv-FI" ? Translations_sv_FI.LogisticVoucherCustomer : Translations_en_US.LogisticVoucherCustomer);
			Translations.SelectInstantMessageChannelUsers = culture === "fi" ? Translations_fi.SelectInstantMessageChannelUsers : (culture === "sv-FI" ? Translations_sv_FI.SelectInstantMessageChannelUsers : Translations_en_US.SelectInstantMessageChannelUsers);
			Translations.SavingUnitPermissionsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingUnitPermissionsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingUnitPermissionsWasNotSuccessfulErrorParameter : Translations_en_US.SavingUnitPermissionsWasNotSuccessfulErrorParameter);
			Translations.LanguageEnglish = culture === "fi" ? Translations_fi.LanguageEnglish : (culture === "sv-FI" ? Translations_sv_FI.LanguageEnglish : Translations_en_US.LanguageEnglish);
			Translations.MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful = culture === "fi" ? Translations_fi.MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful : Translations_en_US.MovingFolderDirectoryItemParameterToFolderParameterWasSuccessful);
			Translations.UserGroup = culture === "fi" ? Translations_fi.UserGroup : (culture === "sv-FI" ? Translations_sv_FI.UserGroup : Translations_en_US.UserGroup);
			Translations.Instructions = culture === "fi" ? Translations_fi.Instructions : (culture === "sv-FI" ? Translations_sv_FI.Instructions : Translations_en_US.Instructions);
			Translations.PanelSize2 = culture === "fi" ? Translations_fi.PanelSize2 : (culture === "sv-FI" ? Translations_sv_FI.PanelSize2 : Translations_en_US.PanelSize2);
			Translations.CompanyRole = culture === "fi" ? Translations_fi.CompanyRole : (culture === "sv-FI" ? Translations_sv_FI.CompanyRole : Translations_en_US.CompanyRole);
			Translations.ReadingFileParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.ReadingFileParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.ReadingFileParameterWasNotSuccessfulErrorParameter : Translations_en_US.ReadingFileParameterWasNotSuccessfulErrorParameter);
			Translations.InstantMessageChannelGroupInternal = culture === "fi" ? Translations_fi.InstantMessageChannelGroupInternal : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelGroupInternal : Translations_en_US.InstantMessageChannelGroupInternal);
			Translations.UsedQuota = culture === "fi" ? Translations_fi.UsedQuota : (culture === "sv-FI" ? Translations_sv_FI.UsedQuota : Translations_en_US.UsedQuota);
			Translations.UnreadInstantMessages = culture === "fi" ? Translations_fi.UnreadInstantMessages : (culture === "sv-FI" ? Translations_sv_FI.UnreadInstantMessages : Translations_en_US.UnreadInstantMessages);
			Translations.SelectCompany = culture === "fi" ? Translations_fi.SelectCompany : (culture === "sv-FI" ? Translations_sv_FI.SelectCompany : Translations_en_US.SelectCompany);
			Translations.RemoveTaskPermanently = culture === "fi" ? Translations_fi.RemoveTaskPermanently : (culture === "sv-FI" ? Translations_sv_FI.RemoveTaskPermanently : Translations_en_US.RemoveTaskPermanently);
			Translations.Remove = culture === "fi" ? Translations_fi.Remove : (culture === "sv-FI" ? Translations_sv_FI.Remove : Translations_en_US.Remove);
			Translations.WhistleBlowChannelParameterNotFound = culture === "fi" ? Translations_fi.WhistleBlowChannelParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowChannelParameterNotFound : Translations_en_US.WhistleBlowChannelParameterNotFound);
			Translations.LockedTaskDataRowCannotBeSaved = culture === "fi" ? Translations_fi.LockedTaskDataRowCannotBeSaved : (culture === "sv-FI" ? Translations_sv_FI.LockedTaskDataRowCannotBeSaved : Translations_en_US.LockedTaskDataRowCannotBeSaved);
			Translations.MessageStartDateMustBeDefined = culture === "fi" ? Translations_fi.MessageStartDateMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.MessageStartDateMustBeDefined : Translations_en_US.MessageStartDateMustBeDefined);
			Translations.Edit = culture === "fi" ? Translations_fi.Edit : (culture === "sv-FI" ? Translations_sv_FI.Edit : Translations_en_US.Edit);
			Translations.DoYouReallyWantToRemoveCompanyParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveCompanyParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveCompanyParameter : Translations_en_US.DoYouReallyWantToRemoveCompanyParameter);
			Translations.DeletingMessageTypeParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingMessageTypeParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingMessageTypeParameterWasSuccessful : Translations_en_US.DeletingMessageTypeParameterWasSuccessful);
			Translations.SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingInstantMessageChannelParameterWasNotSuccessfulErrorParameter);
			Translations.Descending = culture === "fi" ? Translations_fi.Descending : (culture === "sv-FI" ? Translations_sv_FI.Descending : Translations_en_US.Descending);
			Translations.SavingEventTypeParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventTypeParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventTypeParameterWasNotSuccessfulErrorParameter);
			Translations.PasswordIsTooShort = culture === "fi" ? Translations_fi.PasswordIsTooShort : (culture === "sv-FI" ? Translations_sv_FI.PasswordIsTooShort : Translations_en_US.PasswordIsTooShort);
			Translations.DoYouReallyWantToRemoveWhistleBlowCategoryParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveWhistleBlowCategoryParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveWhistleBlowCategoryParameter : Translations_en_US.DoYouReallyWantToRemoveWhistleBlowCategoryParameter);
			Translations.ExpiringIn120Days = culture === "fi" ? Translations_fi.ExpiringIn120Days : (culture === "sv-FI" ? Translations_sv_FI.ExpiringIn120Days : Translations_en_US.ExpiringIn120Days);
			Translations.AddUserGroupsParameterWasSuccessful = culture === "fi" ? Translations_fi.AddUserGroupsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddUserGroupsParameterWasSuccessful : Translations_en_US.AddUserGroupsParameterWasSuccessful);
			Translations.TakePicture = culture === "fi" ? Translations_fi.TakePicture : (culture === "sv-FI" ? Translations_sv_FI.TakePicture : Translations_en_US.TakePicture);
			Translations.Reports = culture === "fi" ? Translations_fi.Reports : (culture === "sv-FI" ? Translations_sv_FI.Reports : Translations_en_US.Reports);
			Translations.AccessRightFull = culture === "fi" ? Translations_fi.AccessRightFull : (culture === "sv-FI" ? Translations_sv_FI.AccessRightFull : Translations_en_US.AccessRightFull);
			Translations.Update = culture === "fi" ? Translations_fi.Update : (culture === "sv-FI" ? Translations_sv_FI.Update : Translations_en_US.Update);
			Translations.TaskTypeParameterNotFound = culture === "fi" ? Translations_fi.TaskTypeParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.TaskTypeParameterNotFound : Translations_en_US.TaskTypeParameterNotFound);
			Translations.FollowUp = culture === "fi" ? Translations_fi.FollowUp : (culture === "sv-FI" ? Translations_sv_FI.FollowUp : Translations_en_US.FollowUp);
			Translations.AddEmployments = culture === "fi" ? Translations_fi.AddEmployments : (culture === "sv-FI" ? Translations_sv_FI.AddEmployments : Translations_en_US.AddEmployments);
			Translations.WhistleBlowSeverityNormal = culture === "fi" ? Translations_fi.WhistleBlowSeverityNormal : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSeverityNormal : Translations_en_US.WhistleBlowSeverityNormal);
			Translations.EditUnit = culture === "fi" ? Translations_fi.EditUnit : (culture === "sv-FI" ? Translations_sv_FI.EditUnit : Translations_en_US.EditUnit);
			Translations.Contents = culture === "fi" ? Translations_fi.Contents : (culture === "sv-FI" ? Translations_sv_FI.Contents : Translations_en_US.Contents);
			Translations.ReadError = culture === "fi" ? Translations_fi.ReadError : (culture === "sv-FI" ? Translations_sv_FI.ReadError : Translations_en_US.ReadError);
			Translations.AddFamiliarizationToMultipleUserRecords = culture === "fi" ? Translations_fi.AddFamiliarizationToMultipleUserRecords : (culture === "sv-FI" ? Translations_sv_FI.AddFamiliarizationToMultipleUserRecords : Translations_en_US.AddFamiliarizationToMultipleUserRecords);
			Translations.RemoveInvalidDefaultEventHandlersConfirmation = culture === "fi" ? Translations_fi.RemoveInvalidDefaultEventHandlersConfirmation : (culture === "sv-FI" ? Translations_sv_FI.RemoveInvalidDefaultEventHandlersConfirmation : Translations_en_US.RemoveInvalidDefaultEventHandlersConfirmation);
			Translations.ResultGrouping = culture === "fi" ? Translations_fi.ResultGrouping : (culture === "sv-FI" ? Translations_sv_FI.ResultGrouping : Translations_en_US.ResultGrouping);
			Translations.DocumentContentsSearch = culture === "fi" ? Translations_fi.DocumentContentsSearch : (culture === "sv-FI" ? Translations_sv_FI.DocumentContentsSearch : Translations_en_US.DocumentContentsSearch);
			Translations.DeletingTaskParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingTaskParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingTaskParameterWasNotSuccessfulConcurrencyError);
			Translations.SavingDefaultHandlersWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingDefaultHandlersWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingDefaultHandlersWasNotSuccessfulErrorParameter : Translations_en_US.SavingDefaultHandlersWasNotSuccessfulErrorParameter);
			Translations.WhistleBlowState = culture === "fi" ? Translations_fi.WhistleBlowState : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowState : Translations_en_US.WhistleBlowState);
			Translations.FileFilterParameterNotFound = culture === "fi" ? Translations_fi.FileFilterParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.FileFilterParameterNotFound : Translations_en_US.FileFilterParameterNotFound);
			Translations.DoYouReallyWantToCreateRemainderInPast = culture === "fi" ? Translations_fi.DoYouReallyWantToCreateRemainderInPast : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToCreateRemainderInPast : Translations_en_US.DoYouReallyWantToCreateRemainderInPast);
			Translations.ExitingInstantMessageChannelWasSuccessful = culture === "fi" ? Translations_fi.ExitingInstantMessageChannelWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.ExitingInstantMessageChannelWasSuccessful : Translations_en_US.ExitingInstantMessageChannelWasSuccessful);
			Translations.SharedFilters = culture === "fi" ? Translations_fi.SharedFilters : (culture === "sv-FI" ? Translations_sv_FI.SharedFilters : Translations_en_US.SharedFilters);
			Translations.MaximumSizeOfInstantMessageAttachmentIsParameterMB = culture === "fi" ? Translations_fi.MaximumSizeOfInstantMessageAttachmentIsParameterMB : (culture === "sv-FI" ? Translations_sv_FI.MaximumSizeOfInstantMessageAttachmentIsParameterMB : Translations_en_US.MaximumSizeOfInstantMessageAttachmentIsParameterMB);
			Translations.Send = culture === "fi" ? Translations_fi.Send : (culture === "sv-FI" ? Translations_sv_FI.Send : Translations_en_US.Send);
			Translations.ApproveEventHandlerConfirmation = culture === "fi" ? Translations_fi.ApproveEventHandlerConfirmation : (culture === "sv-FI" ? Translations_sv_FI.ApproveEventHandlerConfirmation : Translations_en_US.ApproveEventHandlerConfirmation);
			Translations.SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingWhistleBlowCategoryParameterWasNotSuccessfulErrorParameter);
			Translations.EditTaskPriority = culture === "fi" ? Translations_fi.EditTaskPriority : (culture === "sv-FI" ? Translations_sv_FI.EditTaskPriority : Translations_en_US.EditTaskPriority);
			Translations.AutoSaveVersionHistorySize = culture === "fi" ? Translations_fi.AutoSaveVersionHistorySize : (culture === "sv-FI" ? Translations_sv_FI.AutoSaveVersionHistorySize : Translations_en_US.AutoSaveVersionHistorySize);
			Translations.SavingEventTypeBParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventTypeBParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeBParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventTypeBParameterWasNotSuccessfulConcurrencyError);
			Translations.Folder = culture === "fi" ? Translations_fi.Folder : (culture === "sv-FI" ? Translations_sv_FI.Folder : Translations_en_US.Folder);
			Translations.NotifiedPersonsShort = culture === "fi" ? Translations_fi.NotifiedPersonsShort : (culture === "sv-FI" ? Translations_sv_FI.NotifiedPersonsShort : Translations_en_US.NotifiedPersonsShort);
			Translations.Event = culture === "fi" ? Translations_fi.Event : (culture === "sv-FI" ? Translations_sv_FI.Event : Translations_en_US.Event);
			Translations.ReadReceiptRequired = culture === "fi" ? Translations_fi.ReadReceiptRequired : (culture === "sv-FI" ? Translations_sv_FI.ReadReceiptRequired : Translations_en_US.ReadReceiptRequired);
			Translations.AddDefaultTaskState = culture === "fi" ? Translations_fi.AddDefaultTaskState : (culture === "sv-FI" ? Translations_sv_FI.AddDefaultTaskState : Translations_en_US.AddDefaultTaskState);
			Translations.AddDefaultFurtherEducation = culture === "fi" ? Translations_fi.AddDefaultFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.AddDefaultFurtherEducation : Translations_en_US.AddDefaultFurtherEducation);
			Translations.MessageEndDate = culture === "fi" ? Translations_fi.MessageEndDate : (culture === "sv-FI" ? Translations_sv_FI.MessageEndDate : Translations_en_US.MessageEndDate);
			Translations.WhistleBlowArchivingTypeChangeStateAndRemoveAllData = culture === "fi" ? Translations_fi.WhistleBlowArchivingTypeChangeStateAndRemoveAllData : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowArchivingTypeChangeStateAndRemoveAllData : Translations_en_US.WhistleBlowArchivingTypeChangeStateAndRemoveAllData);
			Translations.AttachmentParameterNotFound = culture === "fi" ? Translations_fi.AttachmentParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.AttachmentParameterNotFound : Translations_en_US.AttachmentParameterNotFound);
			Translations.DownloadDocument = culture === "fi" ? Translations_fi.DownloadDocument : (culture === "sv-FI" ? Translations_sv_FI.DownloadDocument : Translations_en_US.DownloadDocument);
			Translations.DirectCosts = culture === "fi" ? Translations_fi.DirectCosts : (culture === "sv-FI" ? Translations_sv_FI.DirectCosts : Translations_en_US.DirectCosts);
			Translations.SaveTask = culture === "fi" ? Translations_fi.SaveTask : (culture === "sv-FI" ? Translations_sv_FI.SaveTask : Translations_en_US.SaveTask);
			Translations.SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter);
			Translations.SavingKeywordParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingKeywordParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingKeywordParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingKeywordParameterWasNotSuccessfulErrorParameter);
			Translations.SelectableWhenCreatingAnEvent = culture === "fi" ? Translations_fi.SelectableWhenCreatingAnEvent : (culture === "sv-FI" ? Translations_sv_FI.SelectableWhenCreatingAnEvent : Translations_en_US.SelectableWhenCreatingAnEvent);
			Translations.DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordUserEmploymentsParameterWasNotSuccessfulErrorParameter);
			Translations.GroupByYear = culture === "fi" ? Translations_fi.GroupByYear : (culture === "sv-FI" ? Translations_sv_FI.GroupByYear : Translations_en_US.GroupByYear);
			Translations.UserParameterCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.UserParameterCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.UserParameterCodeIsAlreadyInUse : Translations_en_US.UserParameterCodeIsAlreadyInUse);
			Translations.WhistleBlowSeverityUnspecified = culture === "fi" ? Translations_fi.WhistleBlowSeverityUnspecified : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSeverityUnspecified : Translations_en_US.WhistleBlowSeverityUnspecified);
			Translations.StudyWeeks = culture === "fi" ? Translations_fi.StudyWeeks : (culture === "sv-FI" ? Translations_sv_FI.StudyWeeks : Translations_en_US.StudyWeeks);
			Translations.WhistleBlowSendReport = culture === "fi" ? Translations_fi.WhistleBlowSendReport : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSendReport : Translations_en_US.WhistleBlowSendReport);
			Translations.NoToDos = culture === "fi" ? Translations_fi.NoToDos : (culture === "sv-FI" ? Translations_sv_FI.NoToDos : Translations_en_US.NoToDos);
			Translations.UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter : Translations_en_US.UpdateCompanyExternalSystemsWasNotSuccessfulErrorParameter);
			Translations.AddCompanyGroupParameterWasSuccessful = culture === "fi" ? Translations_fi.AddCompanyGroupParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyGroupParameterWasSuccessful : Translations_en_US.AddCompanyGroupParameterWasSuccessful);
			Translations.NoPermission = culture === "fi" ? Translations_fi.NoPermission : (culture === "sv-FI" ? Translations_sv_FI.NoPermission : Translations_en_US.NoPermission);
			Translations.EventTypeCodeMustBeDefined = culture === "fi" ? Translations_fi.EventTypeCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCodeMustBeDefined : Translations_en_US.EventTypeCodeMustBeDefined);
			Translations.AddRows = culture === "fi" ? Translations_fi.AddRows : (culture === "sv-FI" ? Translations_sv_FI.AddRows : Translations_en_US.AddRows);
			Translations.SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError);
			Translations.SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingMessageSendPushParameterWasNotSuccessfulConcurrencyError);
			Translations.TaskDataRowFieldParameterNotFoundInDataSchema = culture === "fi" ? Translations_fi.TaskDataRowFieldParameterNotFoundInDataSchema : (culture === "sv-FI" ? Translations_sv_FI.TaskDataRowFieldParameterNotFoundInDataSchema : Translations_en_US.TaskDataRowFieldParameterNotFoundInDataSchema);
			Translations.AddInstantMessageUserParameterWasSuccessful = culture === "fi" ? Translations_fi.AddInstantMessageUserParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddInstantMessageUserParameterWasSuccessful : Translations_en_US.AddInstantMessageUserParameterWasSuccessful);
			Translations.Employee = culture === "fi" ? Translations_fi.Employee : (culture === "sv-FI" ? Translations_sv_FI.Employee : Translations_en_US.Employee);
			Translations.EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted = culture === "fi" ? Translations_fi.EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted : Translations_en_US.EventTypeParameterIsInUseInParameterEventsAndCannotBeDeleted);
			Translations.Card = culture === "fi" ? Translations_fi.Card : (culture === "sv-FI" ? Translations_sv_FI.Card : Translations_en_US.Card);
			Translations.AddingAttachmentParameterWasSuccessful = culture === "fi" ? Translations_fi.AddingAttachmentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingAttachmentParameterWasSuccessful : Translations_en_US.AddingAttachmentParameterWasSuccessful);
			Translations.CreatingFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CreatingFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CreatingFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.CreatingFolderParameterWasNotSuccessfulErrorParameter);
			Translations.PrivacyPolicyUrl = culture === "fi" ? Translations_fi.PrivacyPolicyUrl : (culture === "sv-FI" ? Translations_sv_FI.PrivacyPolicyUrl : Translations_en_US.PrivacyPolicyUrl);
			Translations.ReadReceiptNotGiven = culture === "fi" ? Translations_fi.ReadReceiptNotGiven : (culture === "sv-FI" ? Translations_sv_FI.ReadReceiptNotGiven : Translations_en_US.ReadReceiptNotGiven);
			Translations.WhistleBlowStatisticsUnreadMessages = culture === "fi" ? Translations_fi.WhistleBlowStatisticsUnreadMessages : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStatisticsUnreadMessages : Translations_en_US.WhistleBlowStatisticsUnreadMessages);
			Translations.ArchiveExpiredFurtherEducations = culture === "fi" ? Translations_fi.ArchiveExpiredFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.ArchiveExpiredFurtherEducations : Translations_en_US.ArchiveExpiredFurtherEducations);
			Translations.AddCompanyTaskType = culture === "fi" ? Translations_fi.AddCompanyTaskType : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyTaskType : Translations_en_US.AddCompanyTaskType);
			Translations.Default = culture === "fi" ? Translations_fi.Default : (culture === "sv-FI" ? Translations_sv_FI.Default : Translations_en_US.Default);
			Translations.MinimumPasswordLengthMustBeAtLeastParameterCharacters = culture === "fi" ? Translations_fi.MinimumPasswordLengthMustBeAtLeastParameterCharacters : (culture === "sv-FI" ? Translations_sv_FI.MinimumPasswordLengthMustBeAtLeastParameterCharacters : Translations_en_US.MinimumPasswordLengthMustBeAtLeastParameterCharacters);
			Translations.PanelName = culture === "fi" ? Translations_fi.PanelName : (culture === "sv-FI" ? Translations_sv_FI.PanelName : Translations_en_US.PanelName);
			Translations.WhistleBlowEventTypeHandlersAdded = culture === "fi" ? Translations_fi.WhistleBlowEventTypeHandlersAdded : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowEventTypeHandlersAdded : Translations_en_US.WhistleBlowEventTypeHandlersAdded);
			Translations.MessagePreservationTime = culture === "fi" ? Translations_fi.MessagePreservationTime : (culture === "sv-FI" ? Translations_sv_FI.MessagePreservationTime : Translations_en_US.MessagePreservationTime);
			Translations.LockedEventParameterCannotBeSaved = culture === "fi" ? Translations_fi.LockedEventParameterCannotBeSaved : (culture === "sv-FI" ? Translations_sv_FI.LockedEventParameterCannotBeSaved : Translations_en_US.LockedEventParameterCannotBeSaved);
			Translations.NoFavoritesToShow = culture === "fi" ? Translations_fi.NoFavoritesToShow : (culture === "sv-FI" ? Translations_sv_FI.NoFavoritesToShow : Translations_en_US.NoFavoritesToShow);
			Translations.AddUserToUserGroup = culture === "fi" ? Translations_fi.AddUserToUserGroup : (culture === "sv-FI" ? Translations_sv_FI.AddUserToUserGroup : Translations_en_US.AddUserToUserGroup);
			Translations.SavingDocumentTemplateParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingDocumentTemplateParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingDocumentTemplateParameterWasSuccessful : Translations_en_US.SavingDocumentTemplateParameterWasSuccessful);
			Translations.PublishEvent = culture === "fi" ? Translations_fi.PublishEvent : (culture === "sv-FI" ? Translations_sv_FI.PublishEvent : Translations_en_US.PublishEvent);
			Translations.SelectInstantMessageChannelUser = culture === "fi" ? Translations_fi.SelectInstantMessageChannelUser : (culture === "sv-FI" ? Translations_sv_FI.SelectInstantMessageChannelUser : Translations_en_US.SelectInstantMessageChannelUser);
			Translations.FileFilters = culture === "fi" ? Translations_fi.FileFilters : (culture === "sv-FI" ? Translations_sv_FI.FileFilters : Translations_en_US.FileFilters);
			Translations.AllJobTitles = culture === "fi" ? Translations_fi.AllJobTitles : (culture === "sv-FI" ? Translations_sv_FI.AllJobTitles : Translations_en_US.AllJobTitles);
			Translations.DeletingUsersParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingUsersParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingUsersParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingUsersParameterWasNotSuccessfulErrorParameter);
			Translations.AddWhistleBlow = culture === "fi" ? Translations_fi.AddWhistleBlow : (culture === "sv-FI" ? Translations_sv_FI.AddWhistleBlow : Translations_en_US.AddWhistleBlow);
			Translations.SavingTaskParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingTaskParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskParameterWasSuccessful : Translations_en_US.SavingTaskParameterWasSuccessful);
			Translations.DefaultEventTypes = culture === "fi" ? Translations_fi.DefaultEventTypes : (culture === "sv-FI" ? Translations_sv_FI.DefaultEventTypes : Translations_en_US.DefaultEventTypes);
			Translations.DefaultEventHandlerParameterNotFound = culture === "fi" ? Translations_fi.DefaultEventHandlerParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.DefaultEventHandlerParameterNotFound : Translations_en_US.DefaultEventHandlerParameterNotFound);
			Translations.AddTaskState = culture === "fi" ? Translations_fi.AddTaskState : (culture === "sv-FI" ? Translations_sv_FI.AddTaskState : Translations_en_US.AddTaskState);
			Translations.InstantMessageChannelUser = culture === "fi" ? Translations_fi.InstantMessageChannelUser : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelUser : Translations_en_US.InstantMessageChannelUser);
			Translations.SharedReports = culture === "fi" ? Translations_fi.SharedReports : (culture === "sv-FI" ? Translations_sv_FI.SharedReports : Translations_en_US.SharedReports);
			Translations.PaymentVoucherCounter = culture === "fi" ? Translations_fi.PaymentVoucherCounter : (culture === "sv-FI" ? Translations_sv_FI.PaymentVoucherCounter : Translations_en_US.PaymentVoucherCounter);
			Translations.Admins = culture === "fi" ? Translations_fi.Admins : (culture === "sv-FI" ? Translations_sv_FI.Admins : Translations_en_US.Admins);
			Translations.DocumentParameterDownloaded = culture === "fi" ? Translations_fi.DocumentParameterDownloaded : (culture === "sv-FI" ? Translations_sv_FI.DocumentParameterDownloaded : Translations_en_US.DocumentParameterDownloaded);
			Translations.PublishTaskType = culture === "fi" ? Translations_fi.PublishTaskType : (culture === "sv-FI" ? Translations_sv_FI.PublishTaskType : Translations_en_US.PublishTaskType);
			Translations.NewPassword = culture === "fi" ? Translations_fi.NewPassword : (culture === "sv-FI" ? Translations_sv_FI.NewPassword : Translations_en_US.NewPassword);
			Translations.DoYouReallyWantToRemoveTaskHandlerParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveTaskHandlerParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveTaskHandlerParameter : Translations_en_US.DoYouReallyWantToRemoveTaskHandlerParameter);
			Translations.RemovePermission = culture === "fi" ? Translations_fi.RemovePermission : (culture === "sv-FI" ? Translations_sv_FI.RemovePermission : Translations_en_US.RemovePermission);
			Translations.TimeSpanQuarter = culture === "fi" ? Translations_fi.TimeSpanQuarter : (culture === "sv-FI" ? Translations_sv_FI.TimeSpanQuarter : Translations_en_US.TimeSpanQuarter);
			Translations.EmailHasBeenAlreadySentForThisMessage = culture === "fi" ? Translations_fi.EmailHasBeenAlreadySentForThisMessage : (culture === "sv-FI" ? Translations_sv_FI.EmailHasBeenAlreadySentForThisMessage : Translations_en_US.EmailHasBeenAlreadySentForThisMessage);
			Translations.DeletingMessageParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingMessageParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingMessageParameterWasSuccessful : Translations_en_US.DeletingMessageParameterWasSuccessful);
			Translations.DeletingTaskStateParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingTaskStateParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskStateParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingTaskStateParameterWasNotSuccessfulConcurrencyError);
			Translations.Qualifications = culture === "fi" ? Translations_fi.Qualifications : (culture === "sv-FI" ? Translations_sv_FI.Qualifications : Translations_en_US.Qualifications);
			Translations.CostCentreName = culture === "fi" ? Translations_fi.CostCentreName : (culture === "sv-FI" ? Translations_sv_FI.CostCentreName : Translations_en_US.CostCentreName);
			Translations.SharedReportAllCompanies = culture === "fi" ? Translations_fi.SharedReportAllCompanies : (culture === "sv-FI" ? Translations_sv_FI.SharedReportAllCompanies : Translations_en_US.SharedReportAllCompanies);
			Translations.UserGroupCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.UserGroupCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.UserGroupCodeIsAlreadyInUse : Translations_en_US.UserGroupCodeIsAlreadyInUse);
			Translations.PrivateAndPublic = culture === "fi" ? Translations_fi.PrivateAndPublic : (culture === "sv-FI" ? Translations_sv_FI.PrivateAndPublic : Translations_en_US.PrivateAndPublic);
			Translations.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError : Translations_en_US.CopyingFilesAndDocumentsToFolderParameterWasNotSuccessfulConcurrencyError);
			Translations.TaskDashboard = culture === "fi" ? Translations_fi.TaskDashboard : (culture === "sv-FI" ? Translations_sv_FI.TaskDashboard : Translations_en_US.TaskDashboard);
			Translations.DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingCompanyGroupsParameterWasNotSuccessfulErrorParameter);
			Translations.WhistleBlowFollowReport = culture === "fi" ? Translations_fi.WhistleBlowFollowReport : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowFollowReport : Translations_en_US.WhistleBlowFollowReport);
			Translations.UserCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.UserCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.UserCodeIsAlreadyInUse : Translations_en_US.UserCodeIsAlreadyInUse);
			Translations.AgencyWorker = culture === "fi" ? Translations_fi.AgencyWorker : (culture === "sv-FI" ? Translations_sv_FI.AgencyWorker : Translations_en_US.AgencyWorker);
			Translations.SavingReportWasSuccessful = culture === "fi" ? Translations_fi.SavingReportWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingReportWasSuccessful : Translations_en_US.SavingReportWasSuccessful);
			Translations.RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted = culture === "fi" ? Translations_fi.RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted : Translations_en_US.RecordFurtherEducationParameterIsInUseInParameterRecordUsersQualificationsAndCannotBeDeleted);
			Translations.RemoveEventTypeB = culture === "fi" ? Translations_fi.RemoveEventTypeB : (culture === "sv-FI" ? Translations_sv_FI.RemoveEventTypeB : Translations_en_US.RemoveEventTypeB);
			Translations.DoYouReallyWantToRemoveDocumentsParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveDocumentsParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveDocumentsParameter : Translations_en_US.DoYouReallyWantToRemoveDocumentsParameter);
			Translations.DeletingTaskDataRowWasSuccessful = culture === "fi" ? Translations_fi.DeletingTaskDataRowWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskDataRowWasSuccessful : Translations_en_US.DeletingTaskDataRowWasSuccessful);
			Translations.TitleMustBeDefined = culture === "fi" ? Translations_fi.TitleMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.TitleMustBeDefined : Translations_en_US.TitleMustBeDefined);
			Translations.UseInheritedContentFiltering = culture === "fi" ? Translations_fi.UseInheritedContentFiltering : (culture === "sv-FI" ? Translations_sv_FI.UseInheritedContentFiltering : Translations_en_US.UseInheritedContentFiltering);
			Translations.AddRow = culture === "fi" ? Translations_fi.AddRow : (culture === "sv-FI" ? Translations_sv_FI.AddRow : Translations_en_US.AddRow);
			Translations.MessageType = culture === "fi" ? Translations_fi.MessageType : (culture === "sv-FI" ? Translations_sv_FI.MessageType : Translations_en_US.MessageType);
			Translations.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter : Translations_en_US.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulErrorParameter);
			Translations.DocumentTemplateCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.DocumentTemplateCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.DocumentTemplateCodeIsAlreadyInUse : Translations_en_US.DocumentTemplateCodeIsAlreadyInUse);
			Translations.ShowUsers = culture === "fi" ? Translations_fi.ShowUsers : (culture === "sv-FI" ? Translations_sv_FI.ShowUsers : Translations_en_US.ShowUsers);
			Translations.Orientation = culture === "fi" ? Translations_fi.Orientation : (culture === "sv-FI" ? Translations_sv_FI.Orientation : Translations_en_US.Orientation);
			Translations.LoggedInUser = culture === "fi" ? Translations_fi.LoggedInUser : (culture === "sv-FI" ? Translations_sv_FI.LoggedInUser : Translations_en_US.LoggedInUser);
			Translations.VersionInformation = culture === "fi" ? Translations_fi.VersionInformation : (culture === "sv-FI" ? Translations_sv_FI.VersionInformation : Translations_en_US.VersionInformation);
			Translations.RecentlyUpdated = culture === "fi" ? Translations_fi.RecentlyUpdated : (culture === "sv-FI" ? Translations_sv_FI.RecentlyUpdated : Translations_en_US.RecentlyUpdated);
			Translations.LanguageNonLocalSwedish = culture === "fi" ? Translations_fi.LanguageNonLocalSwedish : (culture === "sv-FI" ? Translations_sv_FI.LanguageNonLocalSwedish : Translations_en_US.LanguageNonLocalSwedish);
			Translations.EditEventPartner = culture === "fi" ? Translations_fi.EditEventPartner : (culture === "sv-FI" ? Translations_sv_FI.EditEventPartner : Translations_en_US.EditEventPartner);
			Translations.SavingRecordUserTransferredPropertyParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingRecordUserTransferredPropertyParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserTransferredPropertyParameterWasSuccessful : Translations_en_US.SavingRecordUserTransferredPropertyParameterWasSuccessful);
			Translations.EditSharedDocumentTemplate = culture === "fi" ? Translations_fi.EditSharedDocumentTemplate : (culture === "sv-FI" ? Translations_sv_FI.EditSharedDocumentTemplate : Translations_en_US.EditSharedDocumentTemplate);
			Translations.PrivacyPolicyUrlMustBeDefined = culture === "fi" ? Translations_fi.PrivacyPolicyUrlMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.PrivacyPolicyUrlMustBeDefined : Translations_en_US.PrivacyPolicyUrlMustBeDefined);
			Translations.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.MovingFilesAndDocumentsToFolderParameterWasNotSuccessfulErrorParameter);
			Translations.DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordQualificationParameterWasNotSuccessfulErrorParameter);
			Translations.EventsToApprove = culture === "fi" ? Translations_fi.EventsToApprove : (culture === "sv-FI" ? Translations_sv_FI.EventsToApprove : Translations_en_US.EventsToApprove);
			Translations.DoYouReallyWantToRemoveEventHandlerParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveEventHandlerParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveEventHandlerParameter : Translations_en_US.DoYouReallyWantToRemoveEventHandlerParameter);
			Translations.SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingCompanyGroupParameterWasNotSuccessfulConcurrencyError);
			Translations.RememberMe = culture === "fi" ? Translations_fi.RememberMe : (culture === "sv-FI" ? Translations_sv_FI.RememberMe : Translations_en_US.RememberMe);
			Translations.SavingMessageTypeParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingMessageTypeParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageTypeParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingMessageTypeParameterWasNotSuccessfulConcurrencyError);
			Translations.DeletingRecordUserEmploymentsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordUserEmploymentsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserEmploymentsParameterWasSuccessful : Translations_en_US.DeletingRecordUserEmploymentsParameterWasSuccessful);
			Translations.RecordParameterLockingFailedRecordIsWriteLockedToUserParameter = culture === "fi" ? Translations_fi.RecordParameterLockingFailedRecordIsWriteLockedToUserParameter : (culture === "sv-FI" ? Translations_sv_FI.RecordParameterLockingFailedRecordIsWriteLockedToUserParameter : Translations_en_US.RecordParameterLockingFailedRecordIsWriteLockedToUserParameter);
			Translations.Landscape = culture === "fi" ? Translations_fi.Landscape : (culture === "sv-FI" ? Translations_sv_FI.Landscape : Translations_en_US.Landscape);
			Translations.AddEditableDocument = culture === "fi" ? Translations_fi.AddEditableDocument : (culture === "sv-FI" ? Translations_sv_FI.AddEditableDocument : Translations_en_US.AddEditableDocument);
			Translations.RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = culture === "fi" ? Translations_fi.RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted : Translations_en_US.RecordBasicEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted);
			Translations.RecordBasicEducationCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.RecordBasicEducationCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.RecordBasicEducationCodeIsAlreadyInUse : Translations_en_US.RecordBasicEducationCodeIsAlreadyInUse);
			Translations.UserRecordStatisticsArchivedCards = culture === "fi" ? Translations_fi.UserRecordStatisticsArchivedCards : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsArchivedCards : Translations_en_US.UserRecordStatisticsArchivedCards);
			Translations.EditTaskState = culture === "fi" ? Translations_fi.EditTaskState : (culture === "sv-FI" ? Translations_sv_FI.EditTaskState : Translations_en_US.EditTaskState);
			Translations.PermissionToCreateEvents = culture === "fi" ? Translations_fi.PermissionToCreateEvents : (culture === "sv-FI" ? Translations_sv_FI.PermissionToCreateEvents : Translations_en_US.PermissionToCreateEvents);
			Translations.ActiveUserRights = culture === "fi" ? Translations_fi.ActiveUserRights : (culture === "sv-FI" ? Translations_sv_FI.ActiveUserRights : Translations_en_US.ActiveUserRights);
			Translations.DoYouReallyWantToRemoveFilterParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveFilterParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveFilterParameter : Translations_en_US.DoYouReallyWantToRemoveFilterParameter);
			Translations.DriversLicenseType = culture === "fi" ? Translations_fi.DriversLicenseType : (culture === "sv-FI" ? Translations_sv_FI.DriversLicenseType : Translations_en_US.DriversLicenseType);
			Translations.RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter : Translations_en_US.RemovingWhistleBlowHandlerWasNotSuccessfulErrorParameter);
			Translations.AddInstantMessageChannel = culture === "fi" ? Translations_fi.AddInstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.AddInstantMessageChannel : Translations_en_US.AddInstantMessageChannel);
			Translations.SavingReportWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingReportWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingReportWasNotSuccessfulErrorParameter : Translations_en_US.SavingReportWasNotSuccessfulErrorParameter);
			Translations.AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddInstantMessageUsersParameterWasNotSuccessfulErrorParameter);
			Translations.Resolution = culture === "fi" ? Translations_fi.Resolution : (culture === "sv-FI" ? Translations_sv_FI.Resolution : Translations_en_US.Resolution);
			Translations.RemoveUserAndRecordUser = culture === "fi" ? Translations_fi.RemoveUserAndRecordUser : (culture === "sv-FI" ? Translations_sv_FI.RemoveUserAndRecordUser : Translations_en_US.RemoveUserAndRecordUser);
			Translations.KeywordsAndCondition = culture === "fi" ? Translations_fi.KeywordsAndCondition : (culture === "sv-FI" ? Translations_sv_FI.KeywordsAndCondition : Translations_en_US.KeywordsAndCondition);
			Translations.EditAttachment = culture === "fi" ? Translations_fi.EditAttachment : (culture === "sv-FI" ? Translations_sv_FI.EditAttachment : Translations_en_US.EditAttachment);
			Translations.TaskTypeSchemaMustBeDefined = culture === "fi" ? Translations_fi.TaskTypeSchemaMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.TaskTypeSchemaMustBeDefined : Translations_en_US.TaskTypeSchemaMustBeDefined);
			Translations.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulErrorParameter);
			Translations.WhistleBlowSeverityCritical = culture === "fi" ? Translations_fi.WhistleBlowSeverityCritical : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSeverityCritical : Translations_en_US.WhistleBlowSeverityCritical);
			Translations.AccessRightReadRecords = culture === "fi" ? Translations_fi.AccessRightReadRecords : (culture === "sv-FI" ? Translations_sv_FI.AccessRightReadRecords : Translations_en_US.AccessRightReadRecords);
			Translations.PaymentSetting = culture === "fi" ? Translations_fi.PaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.PaymentSetting : Translations_en_US.PaymentSetting);
			Translations.AdvancePaymentReportPath = culture === "fi" ? Translations_fi.AdvancePaymentReportPath : (culture === "sv-FI" ? Translations_sv_FI.AdvancePaymentReportPath : Translations_en_US.AdvancePaymentReportPath);
			Translations.ReadReceiptMessage = culture === "fi" ? Translations_fi.ReadReceiptMessage : (culture === "sv-FI" ? Translations_sv_FI.ReadReceiptMessage : Translations_en_US.ReadReceiptMessage);
			Translations.Others = culture === "fi" ? Translations_fi.Others : (culture === "sv-FI" ? Translations_sv_FI.Others : Translations_en_US.Others);
			Translations.NotAssigned = culture === "fi" ? Translations_fi.NotAssigned : (culture === "sv-FI" ? Translations_sv_FI.NotAssigned : Translations_en_US.NotAssigned);
			Translations.TaskReport = culture === "fi" ? Translations_fi.TaskReport : (culture === "sv-FI" ? Translations_sv_FI.TaskReport : Translations_en_US.TaskReport);
			Translations.DeletingWhistleBlowCategoryParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingWhistleBlowCategoryParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowCategoryParameterWasSuccessful : Translations_en_US.DeletingWhistleBlowCategoryParameterWasSuccessful);
			Translations.AddDefaultTaskPriority = culture === "fi" ? Translations_fi.AddDefaultTaskPriority : (culture === "sv-FI" ? Translations_sv_FI.AddDefaultTaskPriority : Translations_en_US.AddDefaultTaskPriority);
			Translations.BasicEducationsMustBeDefinedBeforeAddingRecordUsers = culture === "fi" ? Translations_fi.BasicEducationsMustBeDefinedBeforeAddingRecordUsers : (culture === "sv-FI" ? Translations_sv_FI.BasicEducationsMustBeDefinedBeforeAddingRecordUsers : Translations_en_US.BasicEducationsMustBeDefinedBeforeAddingRecordUsers);
			Translations.PrintTextPrinted = culture === "fi" ? Translations_fi.PrintTextPrinted : (culture === "sv-FI" ? Translations_sv_FI.PrintTextPrinted : Translations_en_US.PrintTextPrinted);
			Translations.Public = culture === "fi" ? Translations_fi.Public : (culture === "sv-FI" ? Translations_sv_FI.Public : Translations_en_US.Public);
			Translations.ByDate = culture === "fi" ? Translations_fi.ByDate : (culture === "sv-FI" ? Translations_sv_FI.ByDate : Translations_en_US.ByDate);
			Translations.EditEmployment = culture === "fi" ? Translations_fi.EditEmployment : (culture === "sv-FI" ? Translations_sv_FI.EditEmployment : Translations_en_US.EditEmployment);
			Translations.RecordUserFurtherEducationParameterNotFound = culture === "fi" ? Translations_fi.RecordUserFurtherEducationParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordUserFurtherEducationParameterNotFound : Translations_en_US.RecordUserFurtherEducationParameterNotFound);
			Translations.RowInformation = culture === "fi" ? Translations_fi.RowInformation : (culture === "sv-FI" ? Translations_sv_FI.RowInformation : Translations_en_US.RowInformation);
			Translations.WhistleBlowStateResolved = culture === "fi" ? Translations_fi.WhistleBlowStateResolved : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStateResolved : Translations_en_US.WhistleBlowStateResolved);
			Translations.EventType = culture === "fi" ? Translations_fi.EventType : (culture === "sv-FI" ? Translations_sv_FI.EventType : Translations_en_US.EventType);
			Translations.ContactPerson = culture === "fi" ? Translations_fi.ContactPerson : (culture === "sv-FI" ? Translations_sv_FI.ContactPerson : Translations_en_US.ContactPerson);
			Translations.LastNameMustBeDefined = culture === "fi" ? Translations_fi.LastNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.LastNameMustBeDefined : Translations_en_US.LastNameMustBeDefined);
			Translations.RemoveEditableDocument = culture === "fi" ? Translations_fi.RemoveEditableDocument : (culture === "sv-FI" ? Translations_sv_FI.RemoveEditableDocument : Translations_en_US.RemoveEditableDocument);
			Translations.Clear = culture === "fi" ? Translations_fi.Clear : (culture === "sv-FI" ? Translations_sv_FI.Clear : Translations_en_US.Clear);
			Translations.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingWhistleBlowCategoriesParameterWasNotSuccessfulConcurrencyError);
			Translations.AddEventTypeCsToEvent = culture === "fi" ? Translations_fi.AddEventTypeCsToEvent : (culture === "sv-FI" ? Translations_sv_FI.AddEventTypeCsToEvent : Translations_en_US.AddEventTypeCsToEvent);
			Translations.TheUserDoesNotHavePermissionsForThisSystem = culture === "fi" ? Translations_fi.TheUserDoesNotHavePermissionsForThisSystem : (culture === "sv-FI" ? Translations_sv_FI.TheUserDoesNotHavePermissionsForThisSystem : Translations_en_US.TheUserDoesNotHavePermissionsForThisSystem);
			Translations.Task = culture === "fi" ? Translations_fi.Task : (culture === "sv-FI" ? Translations_sv_FI.Task : Translations_en_US.Task);
			Translations.Units = culture === "fi" ? Translations_fi.Units : (culture === "sv-FI" ? Translations_sv_FI.Units : Translations_en_US.Units);
			Translations.MessageTypeClass = culture === "fi" ? Translations_fi.MessageTypeClass : (culture === "sv-FI" ? Translations_sv_FI.MessageTypeClass : Translations_en_US.MessageTypeClass);
			Translations.AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter : Translations_en_US.AddingWhistleBlowHandlerWasNotSuccessfulErrorParameter);
			Translations.ContentVersion = culture === "fi" ? Translations_fi.ContentVersion : (culture === "sv-FI" ? Translations_sv_FI.ContentVersion : Translations_en_US.ContentVersion);
			Translations.SavingRecordUserParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingRecordUserParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingRecordUserParameterWasNotSuccessfulErrorParameter);
			Translations.ImageOrPdfUpToParameterMb = culture === "fi" ? Translations_fi.ImageOrPdfUpToParameterMb : (culture === "sv-FI" ? Translations_sv_FI.ImageOrPdfUpToParameterMb : Translations_en_US.ImageOrPdfUpToParameterMb);
			Translations.DefaultEventSettings = culture === "fi" ? Translations_fi.DefaultEventSettings : (culture === "sv-FI" ? Translations_sv_FI.DefaultEventSettings : Translations_en_US.DefaultEventSettings);
			Translations.RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted = culture === "fi" ? Translations_fi.RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted : Translations_en_US.RecordFurtherEducationParameterIsInUseInParameterRecordUsersAndCannotBeDeleted);
			Translations.ChartGenerationFailed = culture === "fi" ? Translations_fi.ChartGenerationFailed : (culture === "sv-FI" ? Translations_sv_FI.ChartGenerationFailed : Translations_en_US.ChartGenerationFailed);
			Translations.TaskRepetitionType = culture === "fi" ? Translations_fi.TaskRepetitionType : (culture === "sv-FI" ? Translations_sv_FI.TaskRepetitionType : Translations_en_US.TaskRepetitionType);
			Translations.PositionErrorTimeout = culture === "fi" ? Translations_fi.PositionErrorTimeout : (culture === "sv-FI" ? Translations_sv_FI.PositionErrorTimeout : Translations_en_US.PositionErrorTimeout);
			Translations.MoneyIn = culture === "fi" ? Translations_fi.MoneyIn : (culture === "sv-FI" ? Translations_sv_FI.MoneyIn : Translations_en_US.MoneyIn);
			Translations.CreateReport = culture === "fi" ? Translations_fi.CreateReport : (culture === "sv-FI" ? Translations_sv_FI.CreateReport : Translations_en_US.CreateReport);
			Translations.DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingDefaultEventHandlerParameterWasNotSuccessfulErrorParameter);
			Translations.TaskDataRows = culture === "fi" ? Translations_fi.TaskDataRows : (culture === "sv-FI" ? Translations_sv_FI.TaskDataRows : Translations_en_US.TaskDataRows);
			Translations.FurtherEducations = culture === "fi" ? Translations_fi.FurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.FurtherEducations : Translations_en_US.FurtherEducations);
			Translations.EventApprovalWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.EventApprovalWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.EventApprovalWasNotSuccessfulConcurrencyError : Translations_en_US.EventApprovalWasNotSuccessfulConcurrencyError);
			Translations.TransferredProperties = culture === "fi" ? Translations_fi.TransferredProperties : (culture === "sv-FI" ? Translations_sv_FI.TransferredProperties : Translations_en_US.TransferredProperties);
			Translations.WhistleBlowInformantInstuctions = culture === "fi" ? Translations_fi.WhistleBlowInformantInstuctions : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantInstuctions : Translations_en_US.WhistleBlowInformantInstuctions);
			Translations.Phone = culture === "fi" ? Translations_fi.Phone : (culture === "sv-FI" ? Translations_sv_FI.Phone : Translations_en_US.Phone);
			Translations.Approval = culture === "fi" ? Translations_fi.Approval : (culture === "sv-FI" ? Translations_sv_FI.Approval : Translations_en_US.Approval);
			Translations.AddInstantMessageUserParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddInstantMessageUserParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddInstantMessageUserParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddInstantMessageUserParameterWasNotSuccessfulErrorParameter);
			Translations.LanguageSwedish = culture === "fi" ? Translations_fi.LanguageSwedish : (culture === "sv-FI" ? Translations_sv_FI.LanguageSwedish : Translations_en_US.LanguageSwedish);
			Translations.FileSynchronizationToDatabaseStarted = culture === "fi" ? Translations_fi.FileSynchronizationToDatabaseStarted : (culture === "sv-FI" ? Translations_sv_FI.FileSynchronizationToDatabaseStarted : Translations_en_US.FileSynchronizationToDatabaseStarted);
			Translations.DoYouReallyWantToRemoveInstantMessageChannelParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveInstantMessageChannelParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveInstantMessageChannelParameter : Translations_en_US.DoYouReallyWantToRemoveInstantMessageChannelParameter);
			Translations.UserParameterNotFound = culture === "fi" ? Translations_fi.UserParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.UserParameterNotFound : Translations_en_US.UserParameterNotFound);
			Translations.RecordEmailNotificationShowExpired = culture === "fi" ? Translations_fi.RecordEmailNotificationShowExpired : (culture === "sv-FI" ? Translations_sv_FI.RecordEmailNotificationShowExpired : Translations_en_US.RecordEmailNotificationShowExpired);
			Translations.SomeWhistleBlowHandlersHaveReadMessage = culture === "fi" ? Translations_fi.SomeWhistleBlowHandlersHaveReadMessage : (culture === "sv-FI" ? Translations_sv_FI.SomeWhistleBlowHandlersHaveReadMessage : Translations_en_US.SomeWhistleBlowHandlersHaveReadMessage);
			Translations.ArchivingWhistleBlowsParameterWasSuccessful = culture === "fi" ? Translations_fi.ArchivingWhistleBlowsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.ArchivingWhistleBlowsParameterWasSuccessful : Translations_en_US.ArchivingWhistleBlowsParameterWasSuccessful);
			Translations.TargetDoesNotHaveFoldersWithWritePermissions = culture === "fi" ? Translations_fi.TargetDoesNotHaveFoldersWithWritePermissions : (culture === "sv-FI" ? Translations_sv_FI.TargetDoesNotHaveFoldersWithWritePermissions : Translations_en_US.TargetDoesNotHaveFoldersWithWritePermissions);
			Translations.RemoveNotifiedPerson = culture === "fi" ? Translations_fi.RemoveNotifiedPerson : (culture === "sv-FI" ? Translations_sv_FI.RemoveNotifiedPerson : Translations_en_US.RemoveNotifiedPerson);
			Translations.FileParameterIsEmpty = culture === "fi" ? Translations_fi.FileParameterIsEmpty : (culture === "sv-FI" ? Translations_sv_FI.FileParameterIsEmpty : Translations_en_US.FileParameterIsEmpty);
			Translations.WhistleBlowEventTypeMessageAdded = culture === "fi" ? Translations_fi.WhistleBlowEventTypeMessageAdded : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowEventTypeMessageAdded : Translations_en_US.WhistleBlowEventTypeMessageAdded);
			Translations.AccessRightWriteRecords = culture === "fi" ? Translations_fi.AccessRightWriteRecords : (culture === "sv-FI" ? Translations_sv_FI.AccessRightWriteRecords : Translations_en_US.AccessRightWriteRecords);
			Translations.WhistleBlowDoesNotContainAnyMessage = culture === "fi" ? Translations_fi.WhistleBlowDoesNotContainAnyMessage : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowDoesNotContainAnyMessage : Translations_en_US.WhistleBlowDoesNotContainAnyMessage);
			Translations.NewCompanyInformation = culture === "fi" ? Translations_fi.NewCompanyInformation : (culture === "sv-FI" ? Translations_sv_FI.NewCompanyInformation : Translations_en_US.NewCompanyInformation);
			Translations.SaveToTemplate = culture === "fi" ? Translations_fi.SaveToTemplate : (culture === "sv-FI" ? Translations_sv_FI.SaveToTemplate : Translations_en_US.SaveToTemplate);
			Translations.DeletingTaskParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingTaskParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingTaskParameterWasNotSuccessfulErrorParameter);
			Translations.PublicationOfEditableDocumentCannotBeRemoved = culture === "fi" ? Translations_fi.PublicationOfEditableDocumentCannotBeRemoved : (culture === "sv-FI" ? Translations_sv_FI.PublicationOfEditableDocumentCannotBeRemoved : Translations_en_US.PublicationOfEditableDocumentCannotBeRemoved);
			Translations.MinimumPasswordLength = culture === "fi" ? Translations_fi.MinimumPasswordLength : (culture === "sv-FI" ? Translations_sv_FI.MinimumPasswordLength : Translations_en_US.MinimumPasswordLength);
			Translations.SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter : Translations_en_US.SavingEditableDocumentParameterApprovalWasNotSuccessfulErrorParameter);
			Translations.DeletingInstantMessageWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingInstantMessageWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageWasNotSuccessfulErrorParameter : Translations_en_US.DeletingInstantMessageWasNotSuccessfulErrorParameter);
			Translations.LastYear = culture === "fi" ? Translations_fi.LastYear : (culture === "sv-FI" ? Translations_sv_FI.LastYear : Translations_en_US.LastYear);
			Translations.EventSettings = culture === "fi" ? Translations_fi.EventSettings : (culture === "sv-FI" ? Translations_sv_FI.EventSettings : Translations_en_US.EventSettings);
			Translations.AnyTime = culture === "fi" ? Translations_fi.AnyTime : (culture === "sv-FI" ? Translations_sv_FI.AnyTime : Translations_en_US.AnyTime);
			Translations.NoEmailsToSend = culture === "fi" ? Translations_fi.NoEmailsToSend : (culture === "sv-FI" ? Translations_sv_FI.NoEmailsToSend : Translations_en_US.NoEmailsToSend);
			Translations.GroupByHalfYear = culture === "fi" ? Translations_fi.GroupByHalfYear : (culture === "sv-FI" ? Translations_sv_FI.GroupByHalfYear : Translations_en_US.GroupByHalfYear);
			Translations.NextOfKin = culture === "fi" ? Translations_fi.NextOfKin : (culture === "sv-FI" ? Translations_sv_FI.NextOfKin : Translations_en_US.NextOfKin);
			Translations.UploadDocument = culture === "fi" ? Translations_fi.UploadDocument : (culture === "sv-FI" ? Translations_sv_FI.UploadDocument : Translations_en_US.UploadDocument);
			Translations.AddPhotosAndAttachementsToEvent = culture === "fi" ? Translations_fi.AddPhotosAndAttachementsToEvent : (culture === "sv-FI" ? Translations_sv_FI.AddPhotosAndAttachementsToEvent : Translations_en_US.AddPhotosAndAttachementsToEvent);
			Translations.SavingDirectoryItemDocumentParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingDirectoryItemDocumentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemDocumentParameterWasSuccessful : Translations_en_US.SavingDirectoryItemDocumentParameterWasSuccessful);
			Translations.PageNameMustBeDefined = culture === "fi" ? Translations_fi.PageNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.PageNameMustBeDefined : Translations_en_US.PageNameMustBeDefined);
			Translations.SelectRecordUserTypeToBeCreated = culture === "fi" ? Translations_fi.SelectRecordUserTypeToBeCreated : (culture === "sv-FI" ? Translations_sv_FI.SelectRecordUserTypeToBeCreated : Translations_en_US.SelectRecordUserTypeToBeCreated);
			Translations.AdditionalPermissionAInstantMessages = culture === "fi" ? Translations_fi.AdditionalPermissionAInstantMessages : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissionAInstantMessages : Translations_en_US.AdditionalPermissionAInstantMessages);
			Translations.NoAddedEventTypes = culture === "fi" ? Translations_fi.NoAddedEventTypes : (culture === "sv-FI" ? Translations_sv_FI.NoAddedEventTypes : Translations_en_US.NoAddedEventTypes);
			Translations.WhistleBlow = culture === "fi" ? Translations_fi.WhistleBlow : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlow : Translations_en_US.WhistleBlow);
			Translations.IncludeArchivedFurtherEducations = culture === "fi" ? Translations_fi.IncludeArchivedFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.IncludeArchivedFurtherEducations : Translations_en_US.IncludeArchivedFurtherEducations);
			Translations.ValidityEndDateExpirationTime = culture === "fi" ? Translations_fi.ValidityEndDateExpirationTime : (culture === "sv-FI" ? Translations_sv_FI.ValidityEndDateExpirationTime : Translations_en_US.ValidityEndDateExpirationTime);
			Translations.Education = culture === "fi" ? Translations_fi.Education : (culture === "sv-FI" ? Translations_sv_FI.Education : Translations_en_US.Education);
			Translations.Attachments = culture === "fi" ? Translations_fi.Attachments : (culture === "sv-FI" ? Translations_sv_FI.Attachments : Translations_en_US.Attachments);
			Translations.SavingEventMessageWasSuccessful = culture === "fi" ? Translations_fi.SavingEventMessageWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventMessageWasSuccessful : Translations_en_US.SavingEventMessageWasSuccessful);
			Translations.Months = culture === "fi" ? Translations_fi.Months : (culture === "sv-FI" ? Translations_sv_FI.Months : Translations_en_US.Months);
			Translations.WhistleBlowDescription = culture === "fi" ? Translations_fi.WhistleBlowDescription : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowDescription : Translations_en_US.WhistleBlowDescription);
			Translations.WhistleBlowResolutionTypeMustBeDefined = culture === "fi" ? Translations_fi.WhistleBlowResolutionTypeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowResolutionTypeMustBeDefined : Translations_en_US.WhistleBlowResolutionTypeMustBeDefined);
			Translations.NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded = culture === "fi" ? Translations_fi.NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded : Translations_en_US.NewWhistleBlowMustBeSavedBeforeHandlersCanBeAdded);
			Translations.AddUserParameterWasSuccessful = culture === "fi" ? Translations_fi.AddUserParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddUserParameterWasSuccessful : Translations_en_US.AddUserParameterWasSuccessful);
			Translations.OldPassword = culture === "fi" ? Translations_fi.OldPassword : (culture === "sv-FI" ? Translations_sv_FI.OldPassword : Translations_en_US.OldPassword);
			Translations.IncludeOnlyActiveUsers = culture === "fi" ? Translations_fi.IncludeOnlyActiveUsers : (culture === "sv-FI" ? Translations_sv_FI.IncludeOnlyActiveUsers : Translations_en_US.IncludeOnlyActiveUsers);
			Translations.CopyOrMoveFolder = culture === "fi" ? Translations_fi.CopyOrMoveFolder : (culture === "sv-FI" ? Translations_sv_FI.CopyOrMoveFolder : Translations_en_US.CopyOrMoveFolder);
			Translations.FurtherEducation = culture === "fi" ? Translations_fi.FurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.FurtherEducation : Translations_en_US.FurtherEducation);
			Translations.RemoveDefaultTaskState = culture === "fi" ? Translations_fi.RemoveDefaultTaskState : (culture === "sv-FI" ? Translations_sv_FI.RemoveDefaultTaskState : Translations_en_US.RemoveDefaultTaskState);
			Translations.ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter : Translations_en_US.ArchivingRecordUserFamiliarizationsWasNotSuccessfulErrorParameter);
			Translations.WhistleBlowChannelPageNameCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.WhistleBlowChannelPageNameCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowChannelPageNameCodeIsAlreadyInUse : Translations_en_US.WhistleBlowChannelPageNameCodeIsAlreadyInUse);
			Translations.SavingUnitsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingUnitsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingUnitsWasNotSuccessfulConcurrencyError : Translations_en_US.SavingUnitsWasNotSuccessfulConcurrencyError);
			Translations.Banner = culture === "fi" ? Translations_fi.Banner : (culture === "sv-FI" ? Translations_sv_FI.Banner : Translations_en_US.Banner);
			Translations.PreventiveAction = culture === "fi" ? Translations_fi.PreventiveAction : (culture === "sv-FI" ? Translations_sv_FI.PreventiveAction : Translations_en_US.PreventiveAction);
			Translations.SetUnitPermissions = culture === "fi" ? Translations_fi.SetUnitPermissions : (culture === "sv-FI" ? Translations_sv_FI.SetUnitPermissions : Translations_en_US.SetUnitPermissions);
			Translations.InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter = culture === "fi" ? Translations_fi.InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter : Translations_en_US.InstantMessageChannelUserParameterIsNotAllowedToUseChannelParameter);
			Translations.Total = culture === "fi" ? Translations_fi.Total : (culture === "sv-FI" ? Translations_sv_FI.Total : Translations_en_US.Total);
			Translations.RecordUserLogEntryTypePrint = culture === "fi" ? Translations_fi.RecordUserLogEntryTypePrint : (culture === "sv-FI" ? Translations_sv_FI.RecordUserLogEntryTypePrint : Translations_en_US.RecordUserLogEntryTypePrint);
			Translations.RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError : Translations_en_US.RemoveUserGroupExternalSystemsWasNotSuccessfulConcurrencyError);
			Translations.SavingEventTypeBsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventTypeBsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeBsWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventTypeBsWasNotSuccessfulErrorParameter);
			Translations.RecordUserParameterNotFound = culture === "fi" ? Translations_fi.RecordUserParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordUserParameterNotFound : Translations_en_US.RecordUserParameterNotFound);
			Translations.SourceDescription = culture === "fi" ? Translations_fi.SourceDescription : (culture === "sv-FI" ? Translations_sv_FI.SourceDescription : Translations_en_US.SourceDescription);
			Translations.ParameterResults = culture === "fi" ? Translations_fi.ParameterResults : (culture === "sv-FI" ? Translations_sv_FI.ParameterResults : Translations_en_US.ParameterResults);
			Translations.Camera = culture === "fi" ? Translations_fi.Camera : (culture === "sv-FI" ? Translations_sv_FI.Camera : Translations_en_US.Camera);
			Translations.UserNotAllowedToViewNotifier = culture === "fi" ? Translations_fi.UserNotAllowedToViewNotifier : (culture === "sv-FI" ? Translations_sv_FI.UserNotAllowedToViewNotifier : Translations_en_US.UserNotAllowedToViewNotifier);
			Translations.EmployeeTypeMustBeDefined = culture === "fi" ? Translations_fi.EmployeeTypeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.EmployeeTypeMustBeDefined : Translations_en_US.EmployeeTypeMustBeDefined);
			Translations.DeletingMessageParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingMessageParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingMessageParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingMessageParameterWasNotSuccessfulConcurrencyError);
			Translations.DeletingUserGroupsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingUserGroupsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingUserGroupsParameterWasNotSuccessfulErrorParameter);
			Translations.ExpiredFamiliarizations = culture === "fi" ? Translations_fi.ExpiredFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.ExpiredFamiliarizations : Translations_en_US.ExpiredFamiliarizations);
			Translations.AllUserGroups = culture === "fi" ? Translations_fi.AllUserGroups : (culture === "sv-FI" ? Translations_sv_FI.AllUserGroups : Translations_en_US.AllUserGroups);
			Translations.SavingRecordUserParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingRecordUserParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserParameterWasSuccessful : Translations_en_US.SavingRecordUserParameterWasSuccessful);
			Translations.EmailAddress = culture === "fi" ? Translations_fi.EmailAddress : (culture === "sv-FI" ? Translations_sv_FI.EmailAddress : Translations_en_US.EmailAddress);
			Translations.IncludeArchivedFamiliarizations = culture === "fi" ? Translations_fi.IncludeArchivedFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.IncludeArchivedFamiliarizations : Translations_en_US.IncludeArchivedFamiliarizations);
			Translations.TaskMustBeSavedBeforeNewDataRowsCanBeAdded = culture === "fi" ? Translations_fi.TaskMustBeSavedBeforeNewDataRowsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.TaskMustBeSavedBeforeNewDataRowsCanBeAdded : Translations_en_US.TaskMustBeSavedBeforeNewDataRowsCanBeAdded);
			Translations.RecordUserTrangerredPropertyParameterNotFound = culture === "fi" ? Translations_fi.RecordUserTrangerredPropertyParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordUserTrangerredPropertyParameterNotFound : Translations_en_US.RecordUserTrangerredPropertyParameterNotFound);
			Translations.RemoveTaskState = culture === "fi" ? Translations_fi.RemoveTaskState : (culture === "sv-FI" ? Translations_sv_FI.RemoveTaskState : Translations_en_US.RemoveTaskState);
			Translations.AddingAttachmentsParameterWasSuccessful = culture === "fi" ? Translations_fi.AddingAttachmentsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingAttachmentsParameterWasSuccessful : Translations_en_US.AddingAttachmentsParameterWasSuccessful);
			Translations.PasswordConfirmationDoesNotMatchPassword = culture === "fi" ? Translations_fi.PasswordConfirmationDoesNotMatchPassword : (culture === "sv-FI" ? Translations_sv_FI.PasswordConfirmationDoesNotMatchPassword : Translations_en_US.PasswordConfirmationDoesNotMatchPassword);
			Translations.DeletingFileFilterParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingFileFilterParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingFileFilterParameterWasSuccessful : Translations_en_US.DeletingFileFilterParameterWasSuccessful);
			Translations.RemovePayer = culture === "fi" ? Translations_fi.RemovePayer : (culture === "sv-FI" ? Translations_sv_FI.RemovePayer : Translations_en_US.RemovePayer);
			Translations.NoneSelected = culture === "fi" ? Translations_fi.NoneSelected : (culture === "sv-FI" ? Translations_sv_FI.NoneSelected : Translations_en_US.NoneSelected);
			Translations.UpdateUserParameterExternalSystemsWasSuccessful = culture === "fi" ? Translations_fi.UpdateUserParameterExternalSystemsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.UpdateUserParameterExternalSystemsWasSuccessful : Translations_en_US.UpdateUserParameterExternalSystemsWasSuccessful);
			Translations.SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingTaskPriorityParameterWasNotSuccessfulConcurrencyError);
			Translations.DefaultBasicEducations = culture === "fi" ? Translations_fi.DefaultBasicEducations : (culture === "sv-FI" ? Translations_sv_FI.DefaultBasicEducations : Translations_en_US.DefaultBasicEducations);
			Translations.TiedostoturvaUseType = culture === "fi" ? Translations_fi.TiedostoturvaUseType : (culture === "sv-FI" ? Translations_sv_FI.TiedostoturvaUseType : Translations_en_US.TiedostoturvaUseType);
			Translations.OptionCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.OptionCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.OptionCodeIsAlreadyInUse : Translations_en_US.OptionCodeIsAlreadyInUse);
			Translations.ConfirmPassword = culture === "fi" ? Translations_fi.ConfirmPassword : (culture === "sv-FI" ? Translations_sv_FI.ConfirmPassword : Translations_en_US.ConfirmPassword);
			Translations.Repeated = culture === "fi" ? Translations_fi.Repeated : (culture === "sv-FI" ? Translations_sv_FI.Repeated : Translations_en_US.Repeated);
			Translations.RemoveComment = culture === "fi" ? Translations_fi.RemoveComment : (culture === "sv-FI" ? Translations_sv_FI.RemoveComment : Translations_en_US.RemoveComment);
			Translations.ActivePlural = culture === "fi" ? Translations_fi.ActivePlural : (culture === "sv-FI" ? Translations_sv_FI.ActivePlural : Translations_en_US.ActivePlural);
			Translations.MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB = culture === "fi" ? Translations_fi.MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB : (culture === "sv-FI" ? Translations_sv_FI.MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB : Translations_en_US.MaximumSizeOfAttachmentThatCanBeAddedInBatchIsParameterMB);
			Translations.InstantMessageUserInvitationAcceptTypeCannotInvite = culture === "fi" ? Translations_fi.InstantMessageUserInvitationAcceptTypeCannotInvite : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageUserInvitationAcceptTypeCannotInvite : Translations_en_US.InstantMessageUserInvitationAcceptTypeCannotInvite);
			Translations.SavingUnitsWasSuccessful = culture === "fi" ? Translations_fi.SavingUnitsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingUnitsWasSuccessful : Translations_en_US.SavingUnitsWasSuccessful);
			Translations.DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingDocumentTemplateParameterWasNotSuccessfulErrorParameter);
			Translations.Sending = culture === "fi" ? Translations_fi.Sending : (culture === "sv-FI" ? Translations_sv_FI.Sending : Translations_en_US.Sending);
			Translations.UserIsActiveInMobile = culture === "fi" ? Translations_fi.UserIsActiveInMobile : (culture === "sv-FI" ? Translations_sv_FI.UserIsActiveInMobile : Translations_en_US.UserIsActiveInMobile);
			Translations.Search = culture === "fi" ? Translations_fi.Search : (culture === "sv-FI" ? Translations_sv_FI.Search : Translations_en_US.Search);
			Translations.FolderMustBeEmptyBeforeItCanBeRemoved = culture === "fi" ? Translations_fi.FolderMustBeEmptyBeforeItCanBeRemoved : (culture === "sv-FI" ? Translations_sv_FI.FolderMustBeEmptyBeforeItCanBeRemoved : Translations_en_US.FolderMustBeEmptyBeforeItCanBeRemoved);
			Translations.Approve = culture === "fi" ? Translations_fi.Approve : (culture === "sv-FI" ? Translations_sv_FI.Approve : Translations_en_US.Approve);
			Translations.EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = culture === "fi" ? Translations_fi.EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted : Translations_en_US.EventTypeCParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted);
			Translations.FileSynchronizationToDatabaseFinishedInParameterSeconds = culture === "fi" ? Translations_fi.FileSynchronizationToDatabaseFinishedInParameterSeconds : (culture === "sv-FI" ? Translations_sv_FI.FileSynchronizationToDatabaseFinishedInParameterSeconds : Translations_en_US.FileSynchronizationToDatabaseFinishedInParameterSeconds);
			Translations.DefaultEventHandlersAndNotifiedPersons = culture === "fi" ? Translations_fi.DefaultEventHandlersAndNotifiedPersons : (culture === "sv-FI" ? Translations_sv_FI.DefaultEventHandlersAndNotifiedPersons : Translations_en_US.DefaultEventHandlersAndNotifiedPersons);
			Translations.YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType = culture === "fi" ? Translations_fi.YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType : (culture === "sv-FI" ? Translations_sv_FI.YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType : Translations_en_US.YouHaveNotSavedChangesSaveChangesBeforePublishingTaskType);
			Translations.AdditionalInformation = culture === "fi" ? Translations_fi.AdditionalInformation : (culture === "sv-FI" ? Translations_sv_FI.AdditionalInformation : Translations_en_US.AdditionalInformation);
			Translations.AddingTaskTypeParameterWasSuccessful = culture === "fi" ? Translations_fi.AddingTaskTypeParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingTaskTypeParameterWasSuccessful : Translations_en_US.AddingTaskTypeParameterWasSuccessful);
			Translations.DefaultFurtherEducations = culture === "fi" ? Translations_fi.DefaultFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.DefaultFurtherEducations : Translations_en_US.DefaultFurtherEducations);
			Translations.AddDefaultTaskType = culture === "fi" ? Translations_fi.AddDefaultTaskType : (culture === "sv-FI" ? Translations_sv_FI.AddDefaultTaskType : Translations_en_US.AddDefaultTaskType);
			Translations.EditLogisticVoucherCustomer = culture === "fi" ? Translations_fi.EditLogisticVoucherCustomer : (culture === "sv-FI" ? Translations_sv_FI.EditLogisticVoucherCustomer : Translations_en_US.EditLogisticVoucherCustomer);
			Translations.DeletingUserGroupParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingUserGroupParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingUserGroupParameterWasNotSuccessfulErrorParameter);
			Translations.RemoveDocument = culture === "fi" ? Translations_fi.RemoveDocument : (culture === "sv-FI" ? Translations_sv_FI.RemoveDocument : Translations_en_US.RemoveDocument);
			Translations.InstantMessageChannelUsersNotUsable = culture === "fi" ? Translations_fi.InstantMessageChannelUsersNotUsable : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelUsersNotUsable : Translations_en_US.InstantMessageChannelUsersNotUsable);
			Translations.DoYouReallyWantToRemovePartnerParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemovePartnerParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemovePartnerParameter : Translations_en_US.DoYouReallyWantToRemovePartnerParameter);
			Translations.SavingPrintWasSuccessful = culture === "fi" ? Translations_fi.SavingPrintWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingPrintWasSuccessful : Translations_en_US.SavingPrintWasSuccessful);
			Translations.AccountNumberTkbOutCredit = culture === "fi" ? Translations_fi.AccountNumberTkbOutCredit : (culture === "sv-FI" ? Translations_sv_FI.AccountNumberTkbOutCredit : Translations_en_US.AccountNumberTkbOutCredit);
			Translations.DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordFurtherEducationParameterWasNotSuccessfulErrorParameter);
			Translations.Title = culture === "fi" ? Translations_fi.Title : (culture === "sv-FI" ? Translations_sv_FI.Title : Translations_en_US.Title);
			Translations.ApprovalComment = culture === "fi" ? Translations_fi.ApprovalComment : (culture === "sv-FI" ? Translations_sv_FI.ApprovalComment : Translations_en_US.ApprovalComment);
			Translations.NewCompanyMustBeSavedBeforePartnersCanBeAdded = culture === "fi" ? Translations_fi.NewCompanyMustBeSavedBeforePartnersCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewCompanyMustBeSavedBeforePartnersCanBeAdded : Translations_en_US.NewCompanyMustBeSavedBeforePartnersCanBeAdded);
			Translations.DeletingEditableDocumentParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingEditableDocumentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingEditableDocumentParameterWasSuccessful : Translations_en_US.DeletingEditableDocumentParameterWasSuccessful);
			Translations.EditDefaultTaskPriority = culture === "fi" ? Translations_fi.EditDefaultTaskPriority : (culture === "sv-FI" ? Translations_sv_FI.EditDefaultTaskPriority : Translations_en_US.EditDefaultTaskPriority);
			Translations.ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers = culture === "fi" ? Translations_fi.ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers : (culture === "sv-FI" ? Translations_sv_FI.ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers : Translations_en_US.ErrorUserChangeWouldLeadToInvalidDefaultEventHandlers);
			Translations.VersionHistory = culture === "fi" ? Translations_fi.VersionHistory : (culture === "sv-FI" ? Translations_sv_FI.VersionHistory : Translations_en_US.VersionHistory);
			Translations.EditDefaultEventType = culture === "fi" ? Translations_fi.EditDefaultEventType : (culture === "sv-FI" ? Translations_sv_FI.EditDefaultEventType : Translations_en_US.EditDefaultEventType);
			Translations.EditDefaultTaskType = culture === "fi" ? Translations_fi.EditDefaultTaskType : (culture === "sv-FI" ? Translations_sv_FI.EditDefaultTaskType : Translations_en_US.EditDefaultTaskType);
			Translations.PageName = culture === "fi" ? Translations_fi.PageName : (culture === "sv-FI" ? Translations_sv_FI.PageName : Translations_en_US.PageName);
			Translations.EditLogisticVoucherCompanyPaymentSetting = culture === "fi" ? Translations_fi.EditLogisticVoucherCompanyPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.EditLogisticVoucherCompanyPaymentSetting : Translations_en_US.EditLogisticVoucherCompanyPaymentSetting);
			Translations.AddingTaskTypesParameterWasSuccessful = culture === "fi" ? Translations_fi.AddingTaskTypesParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingTaskTypesParameterWasSuccessful : Translations_en_US.AddingTaskTypesParameterWasSuccessful);
			Translations.WhistleBlowChannelSettings = culture === "fi" ? Translations_fi.WhistleBlowChannelSettings : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowChannelSettings : Translations_en_US.WhistleBlowChannelSettings);
			Translations.FileNameMustBeDefined = culture === "fi" ? Translations_fi.FileNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.FileNameMustBeDefined : Translations_en_US.FileNameMustBeDefined);
			Translations.EInvoiceAddress = culture === "fi" ? Translations_fi.EInvoiceAddress : (culture === "sv-FI" ? Translations_sv_FI.EInvoiceAddress : Translations_en_US.EInvoiceAddress);
			Translations.SavingDefaultHandlersWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingDefaultHandlersWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingDefaultHandlersWasNotSuccessfulConcurrencyError : Translations_en_US.SavingDefaultHandlersWasNotSuccessfulConcurrencyError);
			Translations.AddDefaultEventHandler = culture === "fi" ? Translations_fi.AddDefaultEventHandler : (culture === "sv-FI" ? Translations_sv_FI.AddDefaultEventHandler : Translations_en_US.AddDefaultEventHandler);
			Translations.LockedDocumentsParameterCannotBeMoved = culture === "fi" ? Translations_fi.LockedDocumentsParameterCannotBeMoved : (culture === "sv-FI" ? Translations_sv_FI.LockedDocumentsParameterCannotBeMoved : Translations_en_US.LockedDocumentsParameterCannotBeMoved);
			Translations.OptionParameterNotFound = culture === "fi" ? Translations_fi.OptionParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.OptionParameterNotFound : Translations_en_US.OptionParameterNotFound);
			Translations.MessageUnit = culture === "fi" ? Translations_fi.MessageUnit : (culture === "sv-FI" ? Translations_sv_FI.MessageUnit : Translations_en_US.MessageUnit);
			Translations.CommentMustNotBeEmpty = culture === "fi" ? Translations_fi.CommentMustNotBeEmpty : (culture === "sv-FI" ? Translations_sv_FI.CommentMustNotBeEmpty : Translations_en_US.CommentMustNotBeEmpty);
			Translations.AddFileOrTakePicture = culture === "fi" ? Translations_fi.AddFileOrTakePicture : (culture === "sv-FI" ? Translations_sv_FI.AddFileOrTakePicture : Translations_en_US.AddFileOrTakePicture);
			Translations.WhistleBlowResolutionJunk = culture === "fi" ? Translations_fi.WhistleBlowResolutionJunk : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowResolutionJunk : Translations_en_US.WhistleBlowResolutionJunk);
			Translations.Loading = culture === "fi" ? Translations_fi.Loading : (culture === "sv-FI" ? Translations_sv_FI.Loading : Translations_en_US.Loading);
			Translations.PrintRow = culture === "fi" ? Translations_fi.PrintRow : (culture === "sv-FI" ? Translations_sv_FI.PrintRow : Translations_en_US.PrintRow);
			Translations.Logo = culture === "fi" ? Translations_fi.Logo : (culture === "sv-FI" ? Translations_sv_FI.Logo : Translations_en_US.Logo);
			Translations.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingUserGroupUsersOfCompanyParameterWasNotSuccessfulConcurrencyError);
			Translations.Summary = culture === "fi" ? Translations_fi.Summary : (culture === "sv-FI" ? Translations_sv_FI.Summary : Translations_en_US.Summary);
			Translations.DeletingTaskParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingTaskParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskParameterWasSuccessful : Translations_en_US.DeletingTaskParameterWasSuccessful);
			Translations.Communication = culture === "fi" ? Translations_fi.Communication : (culture === "sv-FI" ? Translations_sv_FI.Communication : Translations_en_US.Communication);
			Translations.DataTransferError = culture === "fi" ? Translations_fi.DataTransferError : (culture === "sv-FI" ? Translations_sv_FI.DataTransferError : Translations_en_US.DataTransferError);
			Translations.AddCompanyGroupParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddCompanyGroupParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyGroupParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddCompanyGroupParameterWasNotSuccessfulErrorParameter);
			Translations.ValidityEndDateStart = culture === "fi" ? Translations_fi.ValidityEndDateStart : (culture === "sv-FI" ? Translations_sv_FI.ValidityEndDateStart : Translations_en_US.ValidityEndDateStart);
			Translations.DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordUserEmploymentParameterWasNotSuccessfulConcurrencyError);
			Translations.ExpiringIn180Days = culture === "fi" ? Translations_fi.ExpiringIn180Days : (culture === "sv-FI" ? Translations_sv_FI.ExpiringIn180Days : Translations_en_US.ExpiringIn180Days);
			Translations.LoggedInCompanyCannotBeDeleted = culture === "fi" ? Translations_fi.LoggedInCompanyCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.LoggedInCompanyCannotBeDeleted : Translations_en_US.LoggedInCompanyCannotBeDeleted);
			Translations.LogSizeInDays = culture === "fi" ? Translations_fi.LogSizeInDays : (culture === "sv-FI" ? Translations_sv_FI.LogSizeInDays : Translations_en_US.LogSizeInDays);
			Translations.RemoveUserAndRecordUserArchive = culture === "fi" ? Translations_fi.RemoveUserAndRecordUserArchive : (culture === "sv-FI" ? Translations_sv_FI.RemoveUserAndRecordUserArchive : Translations_en_US.RemoveUserAndRecordUserArchive);
			Translations.UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError : Translations_en_US.UpdateUserParameterExternalSystemsWasNotSuccessfulConcurrencyError);
			Translations.Warning = culture === "fi" ? Translations_fi.Warning : (culture === "sv-FI" ? Translations_sv_FI.Warning : Translations_en_US.Warning);
			Translations.Employments = culture === "fi" ? Translations_fi.Employments : (culture === "sv-FI" ? Translations_sv_FI.Employments : Translations_en_US.Employments);
			Translations.LoginAddress = culture === "fi" ? Translations_fi.LoginAddress : (culture === "sv-FI" ? Translations_sv_FI.LoginAddress : Translations_en_US.LoginAddress);
			Translations.LoginTitle = culture === "fi" ? Translations_fi.LoginTitle : (culture === "sv-FI" ? Translations_sv_FI.LoginTitle : Translations_en_US.LoginTitle);
			Translations.OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater = culture === "fi" ? Translations_fi.OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater : (culture === "sv-FI" ? Translations_sv_FI.OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater : Translations_en_US.OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater);
			Translations.LockedTaskDataRowCannotBeDeleted = culture === "fi" ? Translations_fi.LockedTaskDataRowCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.LockedTaskDataRowCannotBeDeleted : Translations_en_US.LockedTaskDataRowCannotBeDeleted);
			Translations.ReportGenerationAborted = culture === "fi" ? Translations_fi.ReportGenerationAborted : (culture === "sv-FI" ? Translations_sv_FI.ReportGenerationAborted : Translations_en_US.ReportGenerationAborted);
			Translations.DeletingEventParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingEventParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingEventParameterWasNotSuccessfulConcurrencyError);
			Translations.EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted = culture === "fi" ? Translations_fi.EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted : Translations_en_US.EventTypeCParameterIsInUseInParameterEventsAndCannotBeDeleted);
			Translations.PanelSize = culture === "fi" ? Translations_fi.PanelSize : (culture === "sv-FI" ? Translations_sv_FI.PanelSize : Translations_en_US.PanelSize);
			Translations.ApproveEvent = culture === "fi" ? Translations_fi.ApproveEvent : (culture === "sv-FI" ? Translations_sv_FI.ApproveEvent : Translations_en_US.ApproveEvent);
			Translations.RecordUserExcelUserList = culture === "fi" ? Translations_fi.RecordUserExcelUserList : (culture === "sv-FI" ? Translations_sv_FI.RecordUserExcelUserList : Translations_en_US.RecordUserExcelUserList);
			Translations.AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter : Translations_en_US.AddingAddRecordUserTransferredPropertiesWasNotSuccessfulErrorParameter);
			Translations.InstantMessageParameterNotFound = culture === "fi" ? Translations_fi.InstantMessageParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageParameterNotFound : Translations_en_US.InstantMessageParameterNotFound);
			Translations.AddBasicEducation = culture === "fi" ? Translations_fi.AddBasicEducation : (culture === "sv-FI" ? Translations_sv_FI.AddBasicEducation : Translations_en_US.AddBasicEducation);
			Translations.JobTypeMessageNotification = culture === "fi" ? Translations_fi.JobTypeMessageNotification : (culture === "sv-FI" ? Translations_sv_FI.JobTypeMessageNotification : Translations_en_US.JobTypeMessageNotification);
			Translations.SendingEmails = culture === "fi" ? Translations_fi.SendingEmails : (culture === "sv-FI" ? Translations_sv_FI.SendingEmails : Translations_en_US.SendingEmails);
			Translations.CopyingFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CopyingFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CopyingFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.CopyingFolderParameterWasNotSuccessfulErrorParameter);
			Translations.DeletingRecordBasicEducationParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordBasicEducationParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordBasicEducationParameterWasSuccessful : Translations_en_US.DeletingRecordBasicEducationParameterWasSuccessful);
			Translations.DocumentVersionParameterNotFound = culture === "fi" ? Translations_fi.DocumentVersionParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.DocumentVersionParameterNotFound : Translations_en_US.DocumentVersionParameterNotFound);
			Translations.AddWhistleBlowCategory = culture === "fi" ? Translations_fi.AddWhistleBlowCategory : (culture === "sv-FI" ? Translations_sv_FI.AddWhistleBlowCategory : Translations_en_US.AddWhistleBlowCategory);
			Translations.RemoveInstantMessageChannelUser = culture === "fi" ? Translations_fi.RemoveInstantMessageChannelUser : (culture === "sv-FI" ? Translations_sv_FI.RemoveInstantMessageChannelUser : Translations_en_US.RemoveInstantMessageChannelUser);
			Translations.SavingImagesWasSuccessful = culture === "fi" ? Translations_fi.SavingImagesWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingImagesWasSuccessful : Translations_en_US.SavingImagesWasSuccessful);
			Translations.RecordFamiliarizationCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.RecordFamiliarizationCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.RecordFamiliarizationCodeIsAlreadyInUse : Translations_en_US.RecordFamiliarizationCodeIsAlreadyInUse);
			Translations.SavingEventTypeCParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventTypeCParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeCParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventTypeCParameterWasNotSuccessfulErrorParameter);
			Translations.TargetsMyCompany = culture === "fi" ? Translations_fi.TargetsMyCompany : (culture === "sv-FI" ? Translations_sv_FI.TargetsMyCompany : Translations_en_US.TargetsMyCompany);
			Translations.UpdatingApplication = culture === "fi" ? Translations_fi.UpdatingApplication : (culture === "sv-FI" ? Translations_sv_FI.UpdatingApplication : Translations_en_US.UpdatingApplication);
			Translations.RecordFurtherEducationCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.RecordFurtherEducationCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.RecordFurtherEducationCodeIsAlreadyInUse : Translations_en_US.RecordFurtherEducationCodeIsAlreadyInUse);
			Translations.AddRecordUserAndUserAccount = culture === "fi" ? Translations_fi.AddRecordUserAndUserAccount : (culture === "sv-FI" ? Translations_sv_FI.AddRecordUserAndUserAccount : Translations_en_US.AddRecordUserAndUserAccount);
			Translations.EventTypeCTitle = culture === "fi" ? Translations_fi.EventTypeCTitle : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCTitle : Translations_en_US.EventTypeCTitle);
			Translations.TaskStateCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.TaskStateCodeIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.TaskStateCodeIsAlreadyInUse : Translations_en_US.TaskStateCodeIsAlreadyInUse);
			Translations.Anonymity = culture === "fi" ? Translations_fi.Anonymity : (culture === "sv-FI" ? Translations_sv_FI.Anonymity : Translations_en_US.Anonymity);
			Translations.AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter : Translations_en_US.AddingAddRecordUserQualificationsWasNotSuccessfulErrorParameter);
			Translations.InstantMessageChannelInformation = culture === "fi" ? Translations_fi.InstantMessageChannelInformation : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelInformation : Translations_en_US.InstantMessageChannelInformation);
			Translations.DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingInstantMessageUserParameterWasNotSuccessfulConcurrencyError);
			Translations.AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.AddCompanyGroupsParameterWasNotSuccessfulConcurrencyError);
			Translations.RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter = culture === "fi" ? Translations_fi.RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter : (culture === "sv-FI" ? Translations_sv_FI.RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter : Translations_en_US.RecordParameterUnlockingFailedRecordIsWriteLockedToUserParameter);
			Translations.UnitProperties = culture === "fi" ? Translations_fi.UnitProperties : (culture === "sv-FI" ? Translations_sv_FI.UnitProperties : Translations_en_US.UnitProperties);
			Translations.JobDataIsEmpty = culture === "fi" ? Translations_fi.JobDataIsEmpty : (culture === "sv-FI" ? Translations_sv_FI.JobDataIsEmpty : Translations_en_US.JobDataIsEmpty);
			Translations.Amount = culture === "fi" ? Translations_fi.Amount : (culture === "sv-FI" ? Translations_sv_FI.Amount : Translations_en_US.Amount);
			Translations.WhistleBlowDateAndTimeCannotBeInFuture = culture === "fi" ? Translations_fi.WhistleBlowDateAndTimeCannotBeInFuture : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowDateAndTimeCannotBeInFuture : Translations_en_US.WhistleBlowDateAndTimeCannotBeInFuture);
			Translations.SavingTaskTypeParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingTaskTypeParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskTypeParameterWasSuccessful : Translations_en_US.SavingTaskTypeParameterWasSuccessful);
			Translations.PasswordMustBeDefined = culture === "fi" ? Translations_fi.PasswordMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.PasswordMustBeDefined : Translations_en_US.PasswordMustBeDefined);
		}

	static setCulture3(culture: string) {
		Translations.TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted = culture === "fi" ? Translations_fi.TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted : Translations_en_US.TaskStateParameterIsInUseInParameterTasksAndCannotBeDeleted);
		Translations.UserAccountConnected = culture === "fi" ? Translations_fi.UserAccountConnected : (culture === "sv-FI" ? Translations_sv_FI.UserAccountConnected : Translations_en_US.UserAccountConnected);
		Translations.YouHaveUnapprovedChangesDoYouWantApproveChanges = culture === "fi" ? Translations_fi.YouHaveUnapprovedChangesDoYouWantApproveChanges : (culture === "sv-FI" ? Translations_sv_FI.YouHaveUnapprovedChangesDoYouWantApproveChanges : Translations_en_US.YouHaveUnapprovedChangesDoYouWantApproveChanges);
		Translations.DeletingTaskTypeParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingTaskTypeParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskTypeParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingTaskTypeParameterWasNotSuccessfulErrorParameter);
		Translations.EventReceiverIsPartnerCompany = culture === "fi" ? Translations_fi.EventReceiverIsPartnerCompany : (culture === "sv-FI" ? Translations_sv_FI.EventReceiverIsPartnerCompany : Translations_en_US.EventReceiverIsPartnerCompany);
		Translations.ProcessingTimeDays = culture === "fi" ? Translations_fi.ProcessingTimeDays : (culture === "sv-FI" ? Translations_sv_FI.ProcessingTimeDays : Translations_en_US.ProcessingTimeDays);
		Translations.ClosedPlural = culture === "fi" ? Translations_fi.ClosedPlural : (culture === "sv-FI" ? Translations_sv_FI.ClosedPlural : Translations_en_US.ClosedPlural);
		Translations.AdditionalPermissionBEventsDialog = culture === "fi" ? Translations_fi.AdditionalPermissionBEventsDialog : (culture === "sv-FI" ? Translations_sv_FI.AdditionalPermissionBEventsDialog : Translations_en_US.AdditionalPermissionBEventsDialog);
		Translations.PublishVoucherInOutReportToFileSystem = culture === "fi" ? Translations_fi.PublishVoucherInOutReportToFileSystem : (culture === "sv-FI" ? Translations_sv_FI.PublishVoucherInOutReportToFileSystem : Translations_en_US.PublishVoucherInOutReportToFileSystem);
		Translations.AddInstantMessageUsersParameterWasSuccessful = culture === "fi" ? Translations_fi.AddInstantMessageUsersParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddInstantMessageUsersParameterWasSuccessful : Translations_en_US.AddInstantMessageUsersParameterWasSuccessful);
		Translations.AdminMustBeDefinedForSectionParameter = culture === "fi" ? Translations_fi.AdminMustBeDefinedForSectionParameter : (culture === "sv-FI" ? Translations_sv_FI.AdminMustBeDefinedForSectionParameter : Translations_en_US.AdminMustBeDefinedForSectionParameter);
		Translations.SavingMessageParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingMessageParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingMessageParameterWasNotSuccessfulConcurrencyError);
		Translations.RecordUserActiveStateChangePermissionDeniedInUnitParameter = culture === "fi" ? Translations_fi.RecordUserActiveStateChangePermissionDeniedInUnitParameter : (culture === "sv-FI" ? Translations_sv_FI.RecordUserActiveStateChangePermissionDeniedInUnitParameter : Translations_en_US.RecordUserActiveStateChangePermissionDeniedInUnitParameter);
		Translations.DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordFamiliarizationParameterWasNotSuccessfulErrorParameter);
		Translations.Year = culture === "fi" ? Translations_fi.Year : (culture === "sv-FI" ? Translations_sv_FI.Year : Translations_en_US.Year);
		Translations.CourseCredits = culture === "fi" ? Translations_fi.CourseCredits : (culture === "sv-FI" ? Translations_sv_FI.CourseCredits : Translations_en_US.CourseCredits);
		Translations.SelectUserGroup = culture === "fi" ? Translations_fi.SelectUserGroup : (culture === "sv-FI" ? Translations_sv_FI.SelectUserGroup : Translations_en_US.SelectUserGroup);
		Translations.DeletingDirectoryItemFolderParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingDirectoryItemFolderParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingDirectoryItemFolderParameterWasSuccessful : Translations_en_US.DeletingDirectoryItemFolderParameterWasSuccessful);
		Translations.SelectTaskType = culture === "fi" ? Translations_fi.SelectTaskType : (culture === "sv-FI" ? Translations_sv_FI.SelectTaskType : Translations_en_US.SelectTaskType);
		Translations.DocumentTemplateCodeMustBeDefined = culture === "fi" ? Translations_fi.DocumentTemplateCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.DocumentTemplateCodeMustBeDefined : Translations_en_US.DocumentTemplateCodeMustBeDefined);
		Translations.ServiceFeePercent = culture === "fi" ? Translations_fi.ServiceFeePercent : (culture === "sv-FI" ? Translations_sv_FI.ServiceFeePercent : Translations_en_US.ServiceFeePercent);
		Translations.SearchFurtherEducations = culture === "fi" ? Translations_fi.SearchFurtherEducations : (culture === "sv-FI" ? Translations_sv_FI.SearchFurtherEducations : Translations_en_US.SearchFurtherEducations);
		Translations.PermissionDeniedForOperation = culture === "fi" ? Translations_fi.PermissionDeniedForOperation : (culture === "sv-FI" ? Translations_sv_FI.PermissionDeniedForOperation : Translations_en_US.PermissionDeniedForOperation);
		Translations.DeletingRecordQualificationParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordQualificationParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordQualificationParameterWasSuccessful : Translations_en_US.DeletingRecordQualificationParameterWasSuccessful);
		Translations.Preview = culture === "fi" ? Translations_fi.Preview : (culture === "sv-FI" ? Translations_sv_FI.Preview : Translations_en_US.Preview);
		Translations.UpdatingDatabase = culture === "fi" ? Translations_fi.UpdatingDatabase : (culture === "sv-FI" ? Translations_sv_FI.UpdatingDatabase : Translations_en_US.UpdatingDatabase);
		Translations.Restore = culture === "fi" ? Translations_fi.Restore : (culture === "sv-FI" ? Translations_sv_FI.Restore : Translations_en_US.Restore);
		Translations.SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError);
		Translations.UserName = culture === "fi" ? Translations_fi.UserName : (culture === "sv-FI" ? Translations_sv_FI.UserName : Translations_en_US.UserName);
		Translations.TaskTypeNameMustBeDefined = culture === "fi" ? Translations_fi.TaskTypeNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.TaskTypeNameMustBeDefined : Translations_en_US.TaskTypeNameMustBeDefined);
		Translations.SavingEventTypeCParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingEventTypeCParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeCParameterWasSuccessful : Translations_en_US.SavingEventTypeCParameterWasSuccessful);
		Translations.RequestValidationErrorOnFieldParameterWithValueParameter = culture === "fi" ? Translations_fi.RequestValidationErrorOnFieldParameterWithValueParameter : (culture === "sv-FI" ? Translations_sv_FI.RequestValidationErrorOnFieldParameterWithValueParameter : Translations_en_US.RequestValidationErrorOnFieldParameterWithValueParameter);
		Translations.WhistleBlowMessageHasNotBeenSentCloseConfirmation = culture === "fi" ? Translations_fi.WhistleBlowMessageHasNotBeenSentCloseConfirmation : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowMessageHasNotBeenSentCloseConfirmation : Translations_en_US.WhistleBlowMessageHasNotBeenSentCloseConfirmation);
		Translations.SelectCustomer = culture === "fi" ? Translations_fi.SelectCustomer : (culture === "sv-FI" ? Translations_sv_FI.SelectCustomer : Translations_en_US.SelectCustomer);
		Translations.Payer = culture === "fi" ? Translations_fi.Payer : (culture === "sv-FI" ? Translations_sv_FI.Payer : Translations_en_US.Payer);
		Translations.EventTypeCParameterNotFound = culture === "fi" ? Translations_fi.EventTypeCParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCParameterNotFound : Translations_en_US.EventTypeCParameterNotFound);
		Translations.ExternalSystemUserTokenParameterIsAlreadyInUse = culture === "fi" ? Translations_fi.ExternalSystemUserTokenParameterIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemUserTokenParameterIsAlreadyInUse : Translations_en_US.ExternalSystemUserTokenParameterIsAlreadyInUse);
		Translations.CreatingFileParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CreatingFileParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CreatingFileParameterWasNotSuccessfulErrorParameter : Translations_en_US.CreatingFileParameterWasNotSuccessfulErrorParameter);
		Translations.AccessRightWriteInstantMessages = culture === "fi" ? Translations_fi.AccessRightWriteInstantMessages : (culture === "sv-FI" ? Translations_sv_FI.AccessRightWriteInstantMessages : Translations_en_US.AccessRightWriteInstantMessages);
		Translations.Description = culture === "fi" ? Translations_fi.Description : (culture === "sv-FI" ? Translations_sv_FI.Description : Translations_en_US.Description);
		Translations.DocumentPublished = culture === "fi" ? Translations_fi.DocumentPublished : (culture === "sv-FI" ? Translations_sv_FI.DocumentPublished : Translations_en_US.DocumentPublished);
		Translations.AddUnit = culture === "fi" ? Translations_fi.AddUnit : (culture === "sv-FI" ? Translations_sv_FI.AddUnit : Translations_en_US.AddUnit);
		Translations.EditableDocumentVersionMustBeDefined = culture === "fi" ? Translations_fi.EditableDocumentVersionMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentVersionMustBeDefined : Translations_en_US.EditableDocumentVersionMustBeDefined);
		Translations.AttachmentIsEmpty = culture === "fi" ? Translations_fi.AttachmentIsEmpty : (culture === "sv-FI" ? Translations_sv_FI.AttachmentIsEmpty : Translations_en_US.AttachmentIsEmpty);
		Translations.FolderNameIsTooLong = culture === "fi" ? Translations_fi.FolderNameIsTooLong : (culture === "sv-FI" ? Translations_sv_FI.FolderNameIsTooLong : Translations_en_US.FolderNameIsTooLong);
		Translations.Time = culture === "fi" ? Translations_fi.Time : (culture === "sv-FI" ? Translations_sv_FI.Time : Translations_en_US.Time);
		Translations.CopyPermissionToAllSameLevelUnits = culture === "fi" ? Translations_fi.CopyPermissionToAllSameLevelUnits : (culture === "sv-FI" ? Translations_sv_FI.CopyPermissionToAllSameLevelUnits : Translations_en_US.CopyPermissionToAllSameLevelUnits);
		Translations.Payee = culture === "fi" ? Translations_fi.Payee : (culture === "sv-FI" ? Translations_sv_FI.Payee : Translations_en_US.Payee);
		Translations.SelectEventTypeB = culture === "fi" ? Translations_fi.SelectEventTypeB : (culture === "sv-FI" ? Translations_sv_FI.SelectEventTypeB : Translations_en_US.SelectEventTypeB);
		Translations.SelectCompanyGroupOrCompany = culture === "fi" ? Translations_fi.SelectCompanyGroupOrCompany : (culture === "sv-FI" ? Translations_sv_FI.SelectCompanyGroupOrCompany : Translations_en_US.SelectCompanyGroupOrCompany);
		Translations.Message = culture === "fi" ? Translations_fi.Message : (culture === "sv-FI" ? Translations_sv_FI.Message : Translations_en_US.Message);
		Translations.TaskCreated = culture === "fi" ? Translations_fi.TaskCreated : (culture === "sv-FI" ? Translations_sv_FI.TaskCreated : Translations_en_US.TaskCreated);
		Translations.EmploymentEndDateCannotPrecedeEmploymentStartDate = culture === "fi" ? Translations_fi.EmploymentEndDateCannotPrecedeEmploymentStartDate : (culture === "sv-FI" ? Translations_sv_FI.EmploymentEndDateCannotPrecedeEmploymentStartDate : Translations_en_US.EmploymentEndDateCannotPrecedeEmploymentStartDate);
		Translations.DoYouReallyWantToUnlockEditableDocument = culture === "fi" ? Translations_fi.DoYouReallyWantToUnlockEditableDocument : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToUnlockEditableDocument : Translations_en_US.DoYouReallyWantToUnlockEditableDocument);
		Translations.NoImagesToShow = culture === "fi" ? Translations_fi.NoImagesToShow : (culture === "sv-FI" ? Translations_sv_FI.NoImagesToShow : Translations_en_US.NoImagesToShow);
		Translations.WhistleBlowGuide = culture === "fi" ? Translations_fi.WhistleBlowGuide : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowGuide : Translations_en_US.WhistleBlowGuide);
		Translations.TaskModuleProperties = culture === "fi" ? Translations_fi.TaskModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.TaskModuleProperties : Translations_en_US.TaskModuleProperties);
		Translations.TrashBin = culture === "fi" ? Translations_fi.TrashBin : (culture === "sv-FI" ? Translations_sv_FI.TrashBin : Translations_en_US.TrashBin);
		Translations.TimeSpanTwoYears = culture === "fi" ? Translations_fi.TimeSpanTwoYears : (culture === "sv-FI" ? Translations_sv_FI.TimeSpanTwoYears : Translations_en_US.TimeSpanTwoYears);
		Translations.ExternalsSystemParameterNotFound = culture === "fi" ? Translations_fi.ExternalsSystemParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.ExternalsSystemParameterNotFound : Translations_en_US.ExternalsSystemParameterNotFound);
		Translations.SavingJobParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingJobParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingJobParameterWasSuccessful : Translations_en_US.SavingJobParameterWasSuccessful);
		Translations.TypeMustBeDefined = culture === "fi" ? Translations_fi.TypeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.TypeMustBeDefined : Translations_en_US.TypeMustBeDefined);
		Translations.TransferredPropertyType = culture === "fi" ? Translations_fi.TransferredPropertyType : (culture === "sv-FI" ? Translations_sv_FI.TransferredPropertyType : Translations_en_US.TransferredPropertyType);
		Translations.SaveUserFurtherEducationConfirmation = culture === "fi" ? Translations_fi.SaveUserFurtherEducationConfirmation : (culture === "sv-FI" ? Translations_sv_FI.SaveUserFurtherEducationConfirmation : Translations_en_US.SaveUserFurtherEducationConfirmation);
		Translations.AddUserGroupsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddUserGroupsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddUserGroupsParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddUserGroupsParameterWasNotSuccessfulErrorParameter);
		Translations.EmploymentStartDate = culture === "fi" ? Translations_fi.EmploymentStartDate : (culture === "sv-FI" ? Translations_sv_FI.EmploymentStartDate : Translations_en_US.EmploymentStartDate);
		Translations.RemoveSharedDocumentTemplate = culture === "fi" ? Translations_fi.RemoveSharedDocumentTemplate : (culture === "sv-FI" ? Translations_sv_FI.RemoveSharedDocumentTemplate : Translations_en_US.RemoveSharedDocumentTemplate);
		Translations.DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords = culture === "fi" ? Translations_fi.DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords : (culture === "sv-FI" ? Translations_sv_FI.DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords : Translations_en_US.DecreasingVersionHistorySizeRemovesCurrentlySavedVersionHistoryRecords);
		Translations.SavingEventDefaultHandlerParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingEventDefaultHandlerParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventDefaultHandlerParameterWasSuccessful : Translations_en_US.SavingEventDefaultHandlerParameterWasSuccessful);
		Translations.NewInstantMessageChannel = culture === "fi" ? Translations_fi.NewInstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.NewInstantMessageChannel : Translations_en_US.NewInstantMessageChannel);
		Translations.WhistleBlowReportHasBeenSavedTopText = culture === "fi" ? Translations_fi.WhistleBlowReportHasBeenSavedTopText : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowReportHasBeenSavedTopText : Translations_en_US.WhistleBlowReportHasBeenSavedTopText);
		Translations.Days = culture === "fi" ? Translations_fi.Days : (culture === "sv-FI" ? Translations_sv_FI.Days : Translations_en_US.Days);
		Translations.DeletingInstantMessageUsersParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingInstantMessageUsersParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageUsersParameterWasSuccessful : Translations_en_US.DeletingInstantMessageUsersParameterWasSuccessful);
		Translations.CopyDocumentsFromCompany = culture === "fi" ? Translations_fi.CopyDocumentsFromCompany : (culture === "sv-FI" ? Translations_sv_FI.CopyDocumentsFromCompany : Translations_en_US.CopyDocumentsFromCompany);
		Translations.RemoveUser = culture === "fi" ? Translations_fi.RemoveUser : (culture === "sv-FI" ? Translations_sv_FI.RemoveUser : Translations_en_US.RemoveUser);
		Translations.SavingSettingsWasSuccessful = culture === "fi" ? Translations_fi.SavingSettingsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingSettingsWasSuccessful : Translations_en_US.SavingSettingsWasSuccessful);
		Translations.EventHandlerParameterNotFound = culture === "fi" ? Translations_fi.EventHandlerParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EventHandlerParameterNotFound : Translations_en_US.EventHandlerParameterNotFound);
		Translations.ApplicationTempPathParameterNotFound = culture === "fi" ? Translations_fi.ApplicationTempPathParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.ApplicationTempPathParameterNotFound : Translations_en_US.ApplicationTempPathParameterNotFound);
		Translations.DeletingEventTypeParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingEventTypeParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingEventTypeParameterWasNotSuccessfulConcurrencyError);
		Translations.MessageTypeParameterNotFound = culture === "fi" ? Translations_fi.MessageTypeParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.MessageTypeParameterNotFound : Translations_en_US.MessageTypeParameterNotFound);
		Translations.SSRSRefreshStarted = culture === "fi" ? Translations_fi.SSRSRefreshStarted : (culture === "sv-FI" ? Translations_sv_FI.SSRSRefreshStarted : Translations_en_US.SSRSRefreshStarted);
		Translations.ValueAddedTaxPercent = culture === "fi" ? Translations_fi.ValueAddedTaxPercent : (culture === "sv-FI" ? Translations_sv_FI.ValueAddedTaxPercent : Translations_en_US.ValueAddedTaxPercent);
		Translations.MaximumPasswordLengthCanParameterCharacters = culture === "fi" ? Translations_fi.MaximumPasswordLengthCanParameterCharacters : (culture === "sv-FI" ? Translations_sv_FI.MaximumPasswordLengthCanParameterCharacters : Translations_en_US.MaximumPasswordLengthCanParameterCharacters);
		Translations.DeletingPartnersParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingPartnersParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingPartnersParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingPartnersParameterWasNotSuccessfulErrorParameter);
		Translations.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulErrorParameter);
		Translations.FileParameterNotFound = culture === "fi" ? Translations_fi.FileParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.FileParameterNotFound : Translations_en_US.FileParameterNotFound);
		Translations.InstantMessageChannelOneUser = culture === "fi" ? Translations_fi.InstantMessageChannelOneUser : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelOneUser : Translations_en_US.InstantMessageChannelOneUser);
		Translations.DeletingWhistleBlowChannelAdminParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingWhistleBlowChannelAdminParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowChannelAdminParameterWasSuccessful : Translations_en_US.DeletingWhistleBlowChannelAdminParameterWasSuccessful);
		Translations.TaskPrioritiesMustBeDefinedBeforeAddingTasks = culture === "fi" ? Translations_fi.TaskPrioritiesMustBeDefinedBeforeAddingTasks : (culture === "sv-FI" ? Translations_sv_FI.TaskPrioritiesMustBeDefinedBeforeAddingTasks : Translations_en_US.TaskPrioritiesMustBeDefinedBeforeAddingTasks);
		Translations.LogModuleProperties = culture === "fi" ? Translations_fi.LogModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.LogModuleProperties : Translations_en_US.LogModuleProperties);
		Translations.EditableDocumentPublishUnapprovedConfirmation = culture === "fi" ? Translations_fi.EditableDocumentPublishUnapprovedConfirmation : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentPublishUnapprovedConfirmation : Translations_en_US.EditableDocumentPublishUnapprovedConfirmation);
		Translations.SelectEventHandler = culture === "fi" ? Translations_fi.SelectEventHandler : (culture === "sv-FI" ? Translations_sv_FI.SelectEventHandler : Translations_en_US.SelectEventHandler);
		Translations.TargetCompany = culture === "fi" ? Translations_fi.TargetCompany : (culture === "sv-FI" ? Translations_sv_FI.TargetCompany : Translations_en_US.TargetCompany);
		Translations.ChangeSystemLanguageToSwedish = culture === "fi" ? Translations_fi.ChangeSystemLanguageToSwedish : (culture === "sv-FI" ? Translations_sv_FI.ChangeSystemLanguageToSwedish : Translations_en_US.ChangeSystemLanguageToSwedish);
		Translations.Group = culture === "fi" ? Translations_fi.Group : (culture === "sv-FI" ? Translations_sv_FI.Group : Translations_en_US.Group);
		Translations.MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB = culture === "fi" ? Translations_fi.MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB : (culture === "sv-FI" ? Translations_sv_FI.MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB : Translations_en_US.MaximumSizeOfDocumentThatCanBeAddedInBatchIsParameterMB);
		Translations.RecordEmailNotificationType = culture === "fi" ? Translations_fi.RecordEmailNotificationType : (culture === "sv-FI" ? Translations_sv_FI.RecordEmailNotificationType : Translations_en_US.RecordEmailNotificationType);
		Translations.SelectArchivingMethod = culture === "fi" ? Translations_fi.SelectArchivingMethod : (culture === "sv-FI" ? Translations_sv_FI.SelectArchivingMethod : Translations_en_US.SelectArchivingMethod);
		Translations.InstantMessageChannel = culture === "fi" ? Translations_fi.InstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannel : Translations_en_US.InstantMessageChannel);
		Translations.DeletingAttachmentParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingAttachmentParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingAttachmentParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingAttachmentParameterWasNotSuccessfulErrorParameter);
		Translations.RemoveRecordUserAndUser = culture === "fi" ? Translations_fi.RemoveRecordUserAndUser : (culture === "sv-FI" ? Translations_sv_FI.RemoveRecordUserAndUser : Translations_en_US.RemoveRecordUserAndUser);
		Translations.Company = culture === "fi" ? Translations_fi.Company : (culture === "sv-FI" ? Translations_sv_FI.Company : Translations_en_US.Company);
		Translations.AddEventMessage = culture === "fi" ? Translations_fi.AddEventMessage : (culture === "sv-FI" ? Translations_sv_FI.AddEventMessage : Translations_en_US.AddEventMessage);
		Translations.SavingTaskPriorityParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingTaskPriorityParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskPriorityParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingTaskPriorityParameterWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter : Translations_en_US.DoYouReallyWantToRemoveRecordUserTransferredPropertyParameter);
		Translations.FirstNameMustBeDefined = culture === "fi" ? Translations_fi.FirstNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.FirstNameMustBeDefined : Translations_en_US.FirstNameMustBeDefined);
		Translations.EditWhistleBlowChannel = culture === "fi" ? Translations_fi.EditWhistleBlowChannel : (culture === "sv-FI" ? Translations_sv_FI.EditWhistleBlowChannel : Translations_en_US.EditWhistleBlowChannel);
		Translations.LogLine = culture === "fi" ? Translations_fi.LogLine : (culture === "sv-FI" ? Translations_sv_FI.LogLine : Translations_en_US.LogLine);
		Translations.RenamingFileParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.RenamingFileParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.RenamingFileParameterWasNotSuccessfulErrorParameter : Translations_en_US.RenamingFileParameterWasNotSuccessfulErrorParameter);
		Translations.AddPartners = culture === "fi" ? Translations_fi.AddPartners : (culture === "sv-FI" ? Translations_sv_FI.AddPartners : Translations_en_US.AddPartners);
		Translations.SenderOrReceiver = culture === "fi" ? Translations_fi.SenderOrReceiver : (culture === "sv-FI" ? Translations_sv_FI.SenderOrReceiver : Translations_en_US.SenderOrReceiver);
		Translations.AddFamiliarization = culture === "fi" ? Translations_fi.AddFamiliarization : (culture === "sv-FI" ? Translations_sv_FI.AddFamiliarization : Translations_en_US.AddFamiliarization);
		Translations.PreviewPanel = culture === "fi" ? Translations_fi.PreviewPanel : (culture === "sv-FI" ? Translations_sv_FI.PreviewPanel : Translations_en_US.PreviewPanel);
		Translations.DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordFamiliarizationParameterWasNotSuccessfulConcurrencyError);
		Translations.ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers = culture === "fi" ? Translations_fi.ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers : (culture === "sv-FI" ? Translations_sv_FI.ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers : Translations_en_US.ErrorUserGroupChangeWouldLeadToInvalidDefaultEventHandlers);
		Translations.EditFamiliarization = culture === "fi" ? Translations_fi.EditFamiliarization : (culture === "sv-FI" ? Translations_sv_FI.EditFamiliarization : Translations_en_US.EditFamiliarization);
		Translations.RemoveTaskHandler = culture === "fi" ? Translations_fi.RemoveTaskHandler : (culture === "sv-FI" ? Translations_sv_FI.RemoveTaskHandler : Translations_en_US.RemoveTaskHandler);
		Translations.BirthDate = culture === "fi" ? Translations_fi.BirthDate : (culture === "sv-FI" ? Translations_sv_FI.BirthDate : Translations_en_US.BirthDate);
		Translations.No = culture === "fi" ? Translations_fi.No : (culture === "sv-FI" ? Translations_sv_FI.No : Translations_en_US.No);
		Translations.AddTaskPriority = culture === "fi" ? Translations_fi.AddTaskPriority : (culture === "sv-FI" ? Translations_sv_FI.AddTaskPriority : Translations_en_US.AddTaskPriority);
		Translations.RecordUserExcelFurtherEducation = culture === "fi" ? Translations_fi.RecordUserExcelFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.RecordUserExcelFurtherEducation : Translations_en_US.RecordUserExcelFurtherEducation);
		Translations.RemoveEventPartner = culture === "fi" ? Translations_fi.RemoveEventPartner : (culture === "sv-FI" ? Translations_sv_FI.RemoveEventPartner : Translations_en_US.RemoveEventPartner);
		Translations.Documents = culture === "fi" ? Translations_fi.Documents : (culture === "sv-FI" ? Translations_sv_FI.Documents : Translations_en_US.Documents);
		Translations.Comments = culture === "fi" ? Translations_fi.Comments : (culture === "sv-FI" ? Translations_sv_FI.Comments : Translations_en_US.Comments);
		Translations.Go = culture === "fi" ? Translations_fi.Go : (culture === "sv-FI" ? Translations_sv_FI.Go : Translations_en_US.Go);
		Translations.DeletingRecordUserParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordUserParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordUserParameterWasNotSuccessfulConcurrencyError);
		Translations.Sender = culture === "fi" ? Translations_fi.Sender : (culture === "sv-FI" ? Translations_sv_FI.Sender : Translations_en_US.Sender);
		Translations.Close = culture === "fi" ? Translations_fi.Close : (culture === "sv-FI" ? Translations_sv_FI.Close : Translations_en_US.Close);
		Translations.CurrentTopics = culture === "fi" ? Translations_fi.CurrentTopics : (culture === "sv-FI" ? Translations_sv_FI.CurrentTopics : Translations_en_US.CurrentTopics);
		Translations.DeletingUserParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingUserParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserParameterWasSuccessful : Translations_en_US.DeletingUserParameterWasSuccessful);
		Translations.CopyingFileParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.CopyingFileParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.CopyingFileParameterWasNotSuccessfulErrorParameter : Translations_en_US.CopyingFileParameterWasNotSuccessfulErrorParameter);
		Translations.CopyOrMoveFilesDocuments = culture === "fi" ? Translations_fi.CopyOrMoveFilesDocuments : (culture === "sv-FI" ? Translations_sv_FI.CopyOrMoveFilesDocuments : Translations_en_US.CopyOrMoveFilesDocuments);
		Translations.RemoveFurtherEducation = culture === "fi" ? Translations_fi.RemoveFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.RemoveFurtherEducation : Translations_en_US.RemoveFurtherEducation);
		Translations.PrintGenerationFailed = culture === "fi" ? Translations_fi.PrintGenerationFailed : (culture === "sv-FI" ? Translations_sv_FI.PrintGenerationFailed : Translations_en_US.PrintGenerationFailed);
		Translations.SavingTaskTypeParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingTaskTypeParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskTypeParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingTaskTypeParameterWasNotSuccessfulConcurrencyError);
		Translations.RemoveUnit = culture === "fi" ? Translations_fi.RemoveUnit : (culture === "sv-FI" ? Translations_sv_FI.RemoveUnit : Translations_en_US.RemoveUnit);
		Translations.SortOrder = culture === "fi" ? Translations_fi.SortOrder : (culture === "sv-FI" ? Translations_sv_FI.SortOrder : Translations_en_US.SortOrder);
		Translations.FamiliarizationStartDateStart = culture === "fi" ? Translations_fi.FamiliarizationStartDateStart : (culture === "sv-FI" ? Translations_sv_FI.FamiliarizationStartDateStart : Translations_en_US.FamiliarizationStartDateStart);
		Translations.RecordUserWithSameNameAlreadyExists = culture === "fi" ? Translations_fi.RecordUserWithSameNameAlreadyExists : (culture === "sv-FI" ? Translations_sv_FI.RecordUserWithSameNameAlreadyExists : Translations_en_US.RecordUserWithSameNameAlreadyExists);
		Translations.RemoveAttachment = culture === "fi" ? Translations_fi.RemoveAttachment : (culture === "sv-FI" ? Translations_sv_FI.RemoveAttachment : Translations_en_US.RemoveAttachment);
		Translations.JobTypePushNotification = culture === "fi" ? Translations_fi.JobTypePushNotification : (culture === "sv-FI" ? Translations_sv_FI.JobTypePushNotification : Translations_en_US.JobTypePushNotification);
		Translations.AddComment = culture === "fi" ? Translations_fi.AddComment : (culture === "sv-FI" ? Translations_sv_FI.AddComment : Translations_en_US.AddComment);
		Translations.EventModuleProperties = culture === "fi" ? Translations_fi.EventModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.EventModuleProperties : Translations_en_US.EventModuleProperties);
		Translations.AccessRightReadWhistleBlow = culture === "fi" ? Translations_fi.AccessRightReadWhistleBlow : (culture === "sv-FI" ? Translations_sv_FI.AccessRightReadWhistleBlow : Translations_en_US.AccessRightReadWhistleBlow);
		Translations.DoYouReallyWantToRemoveSelectedTaskPriorities = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveSelectedTaskPriorities : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveSelectedTaskPriorities : Translations_en_US.DoYouReallyWantToRemoveSelectedTaskPriorities);
		Translations.NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded = culture === "fi" ? Translations_fi.NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded : Translations_en_US.NewFamiliarizationMustBeSavedBeforeAttachmentsCanBeAdded);
		Translations.SelectImageFile = culture === "fi" ? Translations_fi.SelectImageFile : (culture === "sv-FI" ? Translations_sv_FI.SelectImageFile : Translations_en_US.SelectImageFile);
		Translations.AddCompanyGroupsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddCompanyGroupsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyGroupsParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddCompanyGroupsParameterWasNotSuccessfulErrorParameter);
		Translations.DeletingInstantMessageChannelWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingInstantMessageChannelWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageChannelWasNotSuccessfulErrorParameter : Translations_en_US.DeletingInstantMessageChannelWasNotSuccessfulErrorParameter);
		Translations.Created = culture === "fi" ? Translations_fi.Created : (culture === "sv-FI" ? Translations_sv_FI.Created : Translations_en_US.Created);
		Translations.WhistleBlowFrontPage = culture === "fi" ? Translations_fi.WhistleBlowFrontPage : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowFrontPage : Translations_en_US.WhistleBlowFrontPage);
		Translations.Published = culture === "fi" ? Translations_fi.Published : (culture === "sv-FI" ? Translations_sv_FI.Published : Translations_en_US.Published);
		Translations.CompanyGroups = culture === "fi" ? Translations_fi.CompanyGroups : (culture === "sv-FI" ? Translations_sv_FI.CompanyGroups : Translations_en_US.CompanyGroups);
		Translations.VersionParameterPublishedAsPdfFile = culture === "fi" ? Translations_fi.VersionParameterPublishedAsPdfFile : (culture === "sv-FI" ? Translations_sv_FI.VersionParameterPublishedAsPdfFile : Translations_en_US.VersionParameterPublishedAsPdfFile);
		Translations.ModifiedDescription = culture === "fi" ? Translations_fi.ModifiedDescription : (culture === "sv-FI" ? Translations_sv_FI.ModifiedDescription : Translations_en_US.ModifiedDescription);
		Translations.TheUserNameOrPasswordIsIncorrect = culture === "fi" ? Translations_fi.TheUserNameOrPasswordIsIncorrect : (culture === "sv-FI" ? Translations_sv_FI.TheUserNameOrPasswordIsIncorrect : Translations_en_US.TheUserNameOrPasswordIsIncorrect);
		Translations.DeletingEventUrgencyClassParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingEventUrgencyClassParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventUrgencyClassParameterWasSuccessful : Translations_en_US.DeletingEventUrgencyClassParameterWasSuccessful);
		Translations.DeletingEventParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingEventParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingEventParameterWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToRemoveEventParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveEventParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveEventParameter : Translations_en_US.DoYouReallyWantToRemoveEventParameter);
		Translations.MyReports = culture === "fi" ? Translations_fi.MyReports : (culture === "sv-FI" ? Translations_sv_FI.MyReports : Translations_en_US.MyReports);
		Translations.IndefinitelyNoLocalization = culture === "fi" ? Translations_fi.IndefinitelyNoLocalization : (culture === "sv-FI" ? Translations_sv_FI.IndefinitelyNoLocalization : Translations_en_US.IndefinitelyNoLocalization);
		Translations.ExternalSystems = culture === "fi" ? Translations_fi.ExternalSystems : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystems : Translations_en_US.ExternalSystems);
		Translations.SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError : Translations_en_US.SavingDirectoryItemPermissionsWasNotSuccessfulConcurrencyError);
		Translations.Wage = culture === "fi" ? Translations_fi.Wage : (culture === "sv-FI" ? Translations_sv_FI.Wage : Translations_en_US.Wage);
		Translations.Publisher = culture === "fi" ? Translations_fi.Publisher : (culture === "sv-FI" ? Translations_sv_FI.Publisher : Translations_en_US.Publisher);
		Translations.Previous = culture === "fi" ? Translations_fi.Previous : (culture === "sv-FI" ? Translations_sv_FI.Previous : Translations_en_US.Previous);
		Translations.DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingMessageTypeParameterWasNotSuccessfulConcurrencyError);
		Translations.EmailSent = culture === "fi" ? Translations_fi.EmailSent : (culture === "sv-FI" ? Translations_sv_FI.EmailSent : Translations_en_US.EmailSent);
		Translations.WhistleBlowInformantMustBeNotifiedLatestAtParameter = culture === "fi" ? Translations_fi.WhistleBlowInformantMustBeNotifiedLatestAtParameter : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantMustBeNotifiedLatestAtParameter : Translations_en_US.WhistleBlowInformantMustBeNotifiedLatestAtParameter);
		Translations.RemoveTransferredProperty = culture === "fi" ? Translations_fi.RemoveTransferredProperty : (culture === "sv-FI" ? Translations_sv_FI.RemoveTransferredProperty : Translations_en_US.RemoveTransferredProperty);
		Translations.SavingMessageSendPushParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingMessageSendPushParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageSendPushParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingMessageSendPushParameterWasNotSuccessfulErrorParameter);
		Translations.Month = culture === "fi" ? Translations_fi.Month : (culture === "sv-FI" ? Translations_sv_FI.Month : Translations_en_US.Month);
		Translations.DoYouReallyWantToSaveData = culture === "fi" ? Translations_fi.DoYouReallyWantToSaveData : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToSaveData : Translations_en_US.DoYouReallyWantToSaveData);
		Translations.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError : Translations_en_US.UpdateUserGroupParameterExternalSystemsWasNotSuccessfulConcurrencyError);
		Translations.NoPublishedMessages = culture === "fi" ? Translations_fi.NoPublishedMessages : (culture === "sv-FI" ? Translations_sv_FI.NoPublishedMessages : Translations_en_US.NoPublishedMessages);
		Translations.Subject = culture === "fi" ? Translations_fi.Subject : (culture === "sv-FI" ? Translations_sv_FI.Subject : Translations_en_US.Subject);
		Translations.WhistleBlowUrlInfo = culture === "fi" ? Translations_fi.WhistleBlowUrlInfo : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowUrlInfo : Translations_en_US.WhistleBlowUrlInfo);
		Translations.RemovePartner = culture === "fi" ? Translations_fi.RemovePartner : (culture === "sv-FI" ? Translations_sv_FI.RemovePartner : Translations_en_US.RemovePartner);
		Translations.FileParameterAlreadyExistsInFileSystem = culture === "fi" ? Translations_fi.FileParameterAlreadyExistsInFileSystem : (culture === "sv-FI" ? Translations_sv_FI.FileParameterAlreadyExistsInFileSystem : Translations_en_US.FileParameterAlreadyExistsInFileSystem);
		Translations.SavingUserParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingUserParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParameterWasSuccessful : Translations_en_US.SavingUserParameterWasSuccessful);
		Translations.LogDocumentPreview = culture === "fi" ? Translations_fi.LogDocumentPreview : (culture === "sv-FI" ? Translations_sv_FI.LogDocumentPreview : Translations_en_US.LogDocumentPreview);
		Translations.WhistleBlowActiveReports = culture === "fi" ? Translations_fi.WhistleBlowActiveReports : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowActiveReports : Translations_en_US.WhistleBlowActiveReports);
		Translations.WhistleBlowApplicationName = culture === "fi" ? Translations_fi.WhistleBlowApplicationName : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowApplicationName : Translations_en_US.WhistleBlowApplicationName);
		Translations.DeletingTaskStateParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingTaskStateParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskStateParameterWasSuccessful : Translations_en_US.DeletingTaskStateParameterWasSuccessful);
		Translations.FileFilterNameMustBeDefined = culture === "fi" ? Translations_fi.FileFilterNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.FileFilterNameMustBeDefined : Translations_en_US.FileFilterNameMustBeDefined);
		Translations.EventHandlers = culture === "fi" ? Translations_fi.EventHandlers : (culture === "sv-FI" ? Translations_sv_FI.EventHandlers : Translations_en_US.EventHandlers);
		Translations.WhistleBlowResolutionUnspecified = culture === "fi" ? Translations_fi.WhistleBlowResolutionUnspecified : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowResolutionUnspecified : Translations_en_US.WhistleBlowResolutionUnspecified);
		Translations.DoYouReallyWantToRemoveRecordUserParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveRecordUserParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveRecordUserParameter : Translations_en_US.DoYouReallyWantToRemoveRecordUserParameter);
		Translations.RecordFamiliarizationParameterNotFound = culture === "fi" ? Translations_fi.RecordFamiliarizationParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.RecordFamiliarizationParameterNotFound : Translations_en_US.RecordFamiliarizationParameterNotFound);
		Translations.TaskStateCodeMustBeDefined = culture === "fi" ? Translations_fi.TaskStateCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.TaskStateCodeMustBeDefined : Translations_en_US.TaskStateCodeMustBeDefined);
		Translations.SaveInstantMessageChannel = culture === "fi" ? Translations_fi.SaveInstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.SaveInstantMessageChannel : Translations_en_US.SaveInstantMessageChannel);
		Translations.PasswordHasBeenCopiedToClipboard = culture === "fi" ? Translations_fi.PasswordHasBeenCopiedToClipboard : (culture === "sv-FI" ? Translations_sv_FI.PasswordHasBeenCopiedToClipboard : Translations_en_US.PasswordHasBeenCopiedToClipboard);
		Translations.GeneralInfo = culture === "fi" ? Translations_fi.GeneralInfo : (culture === "sv-FI" ? Translations_sv_FI.GeneralInfo : Translations_en_US.GeneralInfo);
		Translations.EventIsNotApprovedByUser = culture === "fi" ? Translations_fi.EventIsNotApprovedByUser : (culture === "sv-FI" ? Translations_sv_FI.EventIsNotApprovedByUser : Translations_en_US.EventIsNotApprovedByUser);
		Translations.EditFurtherEducation = culture === "fi" ? Translations_fi.EditFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.EditFurtherEducation : Translations_en_US.EditFurtherEducation);
		Translations.SavingTaskParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingTaskParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingTaskParameterWasNotSuccessfulErrorParameter);
		Translations.SavingDirectoryItemFolderParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingDirectoryItemFolderParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemFolderParameterWasSuccessful : Translations_en_US.SavingDirectoryItemFolderParameterWasSuccessful);
		Translations.InstantMessagesMenuTitle = culture === "fi" ? Translations_fi.InstantMessagesMenuTitle : (culture === "sv-FI" ? Translations_sv_FI.InstantMessagesMenuTitle : Translations_en_US.InstantMessagesMenuTitle);
		Translations.FurtherEducationParameterNotFound = culture === "fi" ? Translations_fi.FurtherEducationParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.FurtherEducationParameterNotFound : Translations_en_US.FurtherEducationParameterNotFound);
		Translations.DoYouReallyWantToRemoveUserParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveUserParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveUserParameter : Translations_en_US.DoYouReallyWantToRemoveUserParameter);
		Translations.NoFurtherEducationsToShow = culture === "fi" ? Translations_fi.NoFurtherEducationsToShow : (culture === "sv-FI" ? Translations_sv_FI.NoFurtherEducationsToShow : Translations_en_US.NoFurtherEducationsToShow);
		Translations.EditLogisticVoucherTransportCompany = culture === "fi" ? Translations_fi.EditLogisticVoucherTransportCompany : (culture === "sv-FI" ? Translations_sv_FI.EditLogisticVoucherTransportCompany : Translations_en_US.EditLogisticVoucherTransportCompany);
		Translations.OperationNotAllowedForRootFolderParameter = culture === "fi" ? Translations_fi.OperationNotAllowedForRootFolderParameter : (culture === "sv-FI" ? Translations_sv_FI.OperationNotAllowedForRootFolderParameter : Translations_en_US.OperationNotAllowedForRootFolderParameter);
		Translations.SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingDirectoryItemFolderParameterWasNotSuccessfulConcurrencyError);
		Translations.SavingTaskStateParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingTaskStateParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskStateParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingTaskStateParameterWasNotSuccessfulErrorParameter);
		Translations.DeletingRecordFamiliarizationParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordFamiliarizationParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFamiliarizationParameterWasSuccessful : Translations_en_US.DeletingRecordFamiliarizationParameterWasSuccessful);
		Translations.AddingAttachmentsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingAttachmentsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingAttachmentsParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddingAttachmentsParameterWasNotSuccessfulErrorParameter);
		Translations.NotSet = culture === "fi" ? Translations_fi.NotSet : (culture === "sv-FI" ? Translations_sv_FI.NotSet : Translations_en_US.NotSet);
		Translations.WhistleBlowResolutionRejected = culture === "fi" ? Translations_fi.WhistleBlowResolutionRejected : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowResolutionRejected : Translations_en_US.WhistleBlowResolutionRejected);
		Translations.DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingTaskPrioritiesParameterWasNotSuccessfulConcurrencyError);
		Translations.ArchivingRecordUserFamiliarizationsWasSuccessful = culture === "fi" ? Translations_fi.ArchivingRecordUserFamiliarizationsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.ArchivingRecordUserFamiliarizationsWasSuccessful : Translations_en_US.ArchivingRecordUserFamiliarizationsWasSuccessful);
		Translations.AdditionalSettings = culture === "fi" ? Translations_fi.AdditionalSettings : (culture === "sv-FI" ? Translations_sv_FI.AdditionalSettings : Translations_en_US.AdditionalSettings);
		Translations.PublishVersion = culture === "fi" ? Translations_fi.PublishVersion : (culture === "sv-FI" ? Translations_sv_FI.PublishVersion : Translations_en_US.PublishVersion);
		Translations.PostalCode = culture === "fi" ? Translations_fi.PostalCode : (culture === "sv-FI" ? Translations_sv_FI.PostalCode : Translations_en_US.PostalCode);
		Translations.DeletingUserParametersWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingUserParametersWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserParametersWasNotSuccessfulErrorParameter : Translations_en_US.DeletingUserParametersWasNotSuccessfulErrorParameter);
		Translations.ResolutionDescription = culture === "fi" ? Translations_fi.ResolutionDescription : (culture === "sv-FI" ? Translations_sv_FI.ResolutionDescription : Translations_en_US.ResolutionDescription);
		Translations.DeletingEventUrgencyClassesParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingEventUrgencyClassesParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventUrgencyClassesParameterWasSuccessful : Translations_en_US.DeletingEventUrgencyClassesParameterWasSuccessful);
		Translations.NoNotificationsToSend = culture === "fi" ? Translations_fi.NoNotificationsToSend : (culture === "sv-FI" ? Translations_sv_FI.NoNotificationsToSend : Translations_en_US.NoNotificationsToSend);
		Translations.SuccessAudit = culture === "fi" ? Translations_fi.SuccessAudit : (culture === "sv-FI" ? Translations_sv_FI.SuccessAudit : Translations_en_US.SuccessAudit);
		Translations.DeletingPartnerParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingPartnerParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingPartnerParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingPartnerParameterWasNotSuccessfulConcurrencyError);
		Translations.SavingOptionParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingOptionParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingOptionParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingOptionParameterWasNotSuccessfulErrorParameter);
		Translations.CompanyNameMustBeDefined = culture === "fi" ? Translations_fi.CompanyNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.CompanyNameMustBeDefined : Translations_en_US.CompanyNameMustBeDefined);
		Translations.DoYouReallyWantToRemoveRecordFurtherEducationParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveRecordFurtherEducationParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveRecordFurtherEducationParameter : Translations_en_US.DoYouReallyWantToRemoveRecordFurtherEducationParameter);
		Translations.NewPasswordMustBeDefined = culture === "fi" ? Translations_fi.NewPasswordMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.NewPasswordMustBeDefined : Translations_en_US.NewPasswordMustBeDefined);
		Translations.InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted = culture === "fi" ? Translations_fi.InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted : Translations_en_US.InstantMessageChannelContainsParameterInstantMessageUsersAndCannotBeDeleted);
		Translations.AddEventPartner = culture === "fi" ? Translations_fi.AddEventPartner : (culture === "sv-FI" ? Translations_sv_FI.AddEventPartner : Translations_en_US.AddEventPartner);
		Translations.AddPartnerParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddPartnerParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddPartnerParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddPartnerParameterWasNotSuccessfulErrorParameter);
		Translations.GeneralAttachments = culture === "fi" ? Translations_fi.GeneralAttachments : (culture === "sv-FI" ? Translations_sv_FI.GeneralAttachments : Translations_en_US.GeneralAttachments);
		Translations.Selected = culture === "fi" ? Translations_fi.Selected : (culture === "sv-FI" ? Translations_sv_FI.Selected : Translations_en_US.Selected);
		Translations.DescriptionMustBeDefined = culture === "fi" ? Translations_fi.DescriptionMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.DescriptionMustBeDefined : Translations_en_US.DescriptionMustBeDefined);
		Translations.CompanyGroup = culture === "fi" ? Translations_fi.CompanyGroup : (culture === "sv-FI" ? Translations_sv_FI.CompanyGroup : Translations_en_US.CompanyGroup);
		Translations.MoveTaskToTrashBin = culture === "fi" ? Translations_fi.MoveTaskToTrashBin : (culture === "sv-FI" ? Translations_sv_FI.MoveTaskToTrashBin : Translations_en_US.MoveTaskToTrashBin);
		Translations.SavingApplicationPermissionsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingApplicationPermissionsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingApplicationPermissionsWasNotSuccessfulConcurrencyError : Translations_en_US.SavingApplicationPermissionsWasNotSuccessfulConcurrencyError);
		Translations.List = culture === "fi" ? Translations_fi.List : (culture === "sv-FI" ? Translations_sv_FI.List : Translations_en_US.List);
		Translations.DeletingTaskTypeParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingTaskTypeParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskTypeParameterWasSuccessful : Translations_en_US.DeletingTaskTypeParameterWasSuccessful);
		Translations.NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded = culture === "fi" ? Translations_fi.NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded : Translations_en_US.NewTransferredPropertyMustBeSavedBeforeAttachmentsCanBeAdded);
		Translations.Header = culture === "fi" ? Translations_fi.Header : (culture === "sv-FI" ? Translations_sv_FI.Header : Translations_en_US.Header);
		Translations.EventHandlersExcelTitle = culture === "fi" ? Translations_fi.EventHandlersExcelTitle : (culture === "sv-FI" ? Translations_sv_FI.EventHandlersExcelTitle : Translations_en_US.EventHandlersExcelTitle);
		Translations.Start = culture === "fi" ? Translations_fi.Start : (culture === "sv-FI" ? Translations_sv_FI.Start : Translations_en_US.Start);
		Translations.AddingAddRecordUserQualificationsWasSuccessful = culture === "fi" ? Translations_fi.AddingAddRecordUserQualificationsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingAddRecordUserQualificationsWasSuccessful : Translations_en_US.AddingAddRecordUserQualificationsWasSuccessful);
		Translations.CompanyModuleProperties = culture === "fi" ? Translations_fi.CompanyModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.CompanyModuleProperties : Translations_en_US.CompanyModuleProperties);
		Translations.DocumentRootPathIsNotEmpty = culture === "fi" ? Translations_fi.DocumentRootPathIsNotEmpty : (culture === "sv-FI" ? Translations_sv_FI.DocumentRootPathIsNotEmpty : Translations_en_US.DocumentRootPathIsNotEmpty);
		Translations.EventTypeCs = culture === "fi" ? Translations_fi.EventTypeCs : (culture === "sv-FI" ? Translations_sv_FI.EventTypeCs : Translations_en_US.EventTypeCs);
		Translations.Remainder = culture === "fi" ? Translations_fi.Remainder : (culture === "sv-FI" ? Translations_sv_FI.Remainder : Translations_en_US.Remainder);
		Translations.NoFilesToAdd = culture === "fi" ? Translations_fi.NoFilesToAdd : (culture === "sv-FI" ? Translations_sv_FI.NoFilesToAdd : Translations_en_US.NoFilesToAdd);
		Translations.SavingRecordFurtherEducationParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingRecordFurtherEducationParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordFurtherEducationParameterWasSuccessful : Translations_en_US.SavingRecordFurtherEducationParameterWasSuccessful);
		Translations.VoucherSavePath = culture === "fi" ? Translations_fi.VoucherSavePath : (culture === "sv-FI" ? Translations_sv_FI.VoucherSavePath : Translations_en_US.VoucherSavePath);
		Translations.AddTransferredProperty = culture === "fi" ? Translations_fi.AddTransferredProperty : (culture === "sv-FI" ? Translations_sv_FI.AddTransferredProperty : Translations_en_US.AddTransferredProperty);
		Translations.PreviousCalendarMonth = culture === "fi" ? Translations_fi.PreviousCalendarMonth : (culture === "sv-FI" ? Translations_sv_FI.PreviousCalendarMonth : Translations_en_US.PreviousCalendarMonth);
		Translations.RemoveEventTypeC = culture === "fi" ? Translations_fi.RemoveEventTypeC : (culture === "sv-FI" ? Translations_sv_FI.RemoveEventTypeC : Translations_en_US.RemoveEventTypeC);
		Translations.InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation = culture === "fi" ? Translations_fi.InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation : Translations_en_US.InstantMessageUserInvitationAcceptTypeAutoAcceptInvitation);
		Translations.EventTypes = culture === "fi" ? Translations_fi.EventTypes : (culture === "sv-FI" ? Translations_sv_FI.EventTypes : Translations_en_US.EventTypes);
		Translations.ParameterIsNotValidBankBICCode = culture === "fi" ? Translations_fi.ParameterIsNotValidBankBICCode : (culture === "sv-FI" ? Translations_sv_FI.ParameterIsNotValidBankBICCode : Translations_en_US.ParameterIsNotValidBankBICCode);
		Translations.SharedFilter = culture === "fi" ? Translations_fi.SharedFilter : (culture === "sv-FI" ? Translations_sv_FI.SharedFilter : Translations_en_US.SharedFilter);
		Translations.SavingEventTypesWasSuccessful = culture === "fi" ? Translations_fi.SavingEventTypesWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypesWasSuccessful : Translations_en_US.SavingEventTypesWasSuccessful);
		Translations.DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingEditableDocumentParameterWasNotSuccessfulConcurrencyError);
		Translations.AddingWhistleBlowHandlersWasSuccessful = culture === "fi" ? Translations_fi.AddingWhistleBlowHandlersWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingWhistleBlowHandlersWasSuccessful : Translations_en_US.AddingWhistleBlowHandlersWasSuccessful);
		Translations.AddNotifiedPerson = culture === "fi" ? Translations_fi.AddNotifiedPerson : (culture === "sv-FI" ? Translations_sv_FI.AddNotifiedPerson : Translations_en_US.AddNotifiedPerson);
		Translations.SystemAdminMessage = culture === "fi" ? Translations_fi.SystemAdminMessage : (culture === "sv-FI" ? Translations_sv_FI.SystemAdminMessage : Translations_en_US.SystemAdminMessage);
		Translations.SendingEmail = culture === "fi" ? Translations_fi.SendingEmail : (culture === "sv-FI" ? Translations_sv_FI.SendingEmail : Translations_en_US.SendingEmail);
		Translations.EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError : Translations_en_US.EmptyingTaskTrashBinWasNotSuccessfulConcurrencyError);
		Translations.PortalInUse = culture === "fi" ? Translations_fi.PortalInUse : (culture === "sv-FI" ? Translations_sv_FI.PortalInUse : Translations_en_US.PortalInUse);
		Translations.SavingRecordBasicEducationParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingRecordBasicEducationParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordBasicEducationParameterWasSuccessful : Translations_en_US.SavingRecordBasicEducationParameterWasSuccessful);
		Translations.EventSettingsParameterNotFound = culture === "fi" ? Translations_fi.EventSettingsParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EventSettingsParameterNotFound : Translations_en_US.EventSettingsParameterNotFound);
		Translations.DeletingPartnersParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingPartnersParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingPartnersParameterWasSuccessful : Translations_en_US.DeletingPartnersParameterWasSuccessful);
		Translations.EmployeeType = culture === "fi" ? Translations_fi.EmployeeType : (culture === "sv-FI" ? Translations_sv_FI.EmployeeType : Translations_en_US.EmployeeType);
		Translations.CompanyTaskTypes = culture === "fi" ? Translations_fi.CompanyTaskTypes : (culture === "sv-FI" ? Translations_sv_FI.CompanyTaskTypes : Translations_en_US.CompanyTaskTypes);
		Translations.DeletingUserParametersWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingUserParametersWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserParametersWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingUserParametersWasNotSuccessfulConcurrencyError);
		Translations.RemoveUserGroup = culture === "fi" ? Translations_fi.RemoveUserGroup : (culture === "sv-FI" ? Translations_sv_FI.RemoveUserGroup : Translations_en_US.RemoveUserGroup);
		Translations.HandlersMustBeDefined = culture === "fi" ? Translations_fi.HandlersMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.HandlersMustBeDefined : Translations_en_US.HandlersMustBeDefined);
		Translations.DoYouReallyWantToRemoveRecordBasicEducationParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveRecordBasicEducationParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveRecordBasicEducationParameter : Translations_en_US.DoYouReallyWantToRemoveRecordBasicEducationParameter);
		Translations.AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter);
		Translations.SelectEventReceiver = culture === "fi" ? Translations_fi.SelectEventReceiver : (culture === "sv-FI" ? Translations_sv_FI.SelectEventReceiver : Translations_en_US.SelectEventReceiver);
		Translations.Modification = culture === "fi" ? Translations_fi.Modification : (culture === "sv-FI" ? Translations_sv_FI.Modification : Translations_en_US.Modification);
		Translations.AddLogisticVoucherPaymentSetting = culture === "fi" ? Translations_fi.AddLogisticVoucherPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.AddLogisticVoucherPaymentSetting : Translations_en_US.AddLogisticVoucherPaymentSetting);
		Translations.EditCompany = culture === "fi" ? Translations_fi.EditCompany : (culture === "sv-FI" ? Translations_sv_FI.EditCompany : Translations_en_US.EditCompany);
		Translations.ChangingPasswordWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.ChangingPasswordWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.ChangingPasswordWasNotSuccessfulErrorParameter : Translations_en_US.ChangingPasswordWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToUnlockEvent = culture === "fi" ? Translations_fi.DoYouReallyWantToUnlockEvent : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToUnlockEvent : Translations_en_US.DoYouReallyWantToUnlockEvent);
		Translations.UserModuleProperties = culture === "fi" ? Translations_fi.UserModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.UserModuleProperties : Translations_en_US.UserModuleProperties);
		Translations.NoReports = culture === "fi" ? Translations_fi.NoReports : (culture === "sv-FI" ? Translations_sv_FI.NoReports : Translations_en_US.NoReports);
		Translations.SharedFiltersAndReports = culture === "fi" ? Translations_fi.SharedFiltersAndReports : (culture === "sv-FI" ? Translations_sv_FI.SharedFiltersAndReports : Translations_en_US.SharedFiltersAndReports);
		Translations.AddTaskHandler = culture === "fi" ? Translations_fi.AddTaskHandler : (culture === "sv-FI" ? Translations_sv_FI.AddTaskHandler : Translations_en_US.AddTaskHandler);
		Translations.WhistleBlowStateNew = culture === "fi" ? Translations_fi.WhistleBlowStateNew : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStateNew : Translations_en_US.WhistleBlowStateNew);
		Translations.SavingAttachmentsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingAttachmentsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingAttachmentsWasNotSuccessfulErrorParameter : Translations_en_US.SavingAttachmentsWasNotSuccessfulErrorParameter);
		Translations.UnkownErrorHasBeenOccured = culture === "fi" ? Translations_fi.UnkownErrorHasBeenOccured : (culture === "sv-FI" ? Translations_sv_FI.UnkownErrorHasBeenOccured : Translations_en_US.UnkownErrorHasBeenOccured);
		Translations.SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingWhistleBlowChannelParameterWasNotSuccessfulErrorParameter);
		Translations.SaveComment = culture === "fi" ? Translations_fi.SaveComment : (culture === "sv-FI" ? Translations_sv_FI.SaveComment : Translations_en_US.SaveComment);
		Translations.RemoveFilter = culture === "fi" ? Translations_fi.RemoveFilter : (culture === "sv-FI" ? Translations_sv_FI.RemoveFilter : Translations_en_US.RemoveFilter);
		Translations.Drafts = culture === "fi" ? Translations_fi.Drafts : (culture === "sv-FI" ? Translations_sv_FI.Drafts : Translations_en_US.Drafts);
		Translations.FolderTreePermissions = culture === "fi" ? Translations_fi.FolderTreePermissions : (culture === "sv-FI" ? Translations_sv_FI.FolderTreePermissions : Translations_en_US.FolderTreePermissions);
		Translations.LockedToUser = culture === "fi" ? Translations_fi.LockedToUser : (culture === "sv-FI" ? Translations_sv_FI.LockedToUser : Translations_en_US.LockedToUser);
		Translations.Custom = culture === "fi" ? Translations_fi.Custom : (culture === "sv-FI" ? Translations_sv_FI.Custom : Translations_en_US.Custom);
		Translations.Collapse = culture === "fi" ? Translations_fi.Collapse : (culture === "sv-FI" ? Translations_sv_FI.Collapse : Translations_en_US.Collapse);
		Translations.RestoringInstantMessageWasSuccessful = culture === "fi" ? Translations_fi.RestoringInstantMessageWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.RestoringInstantMessageWasSuccessful : Translations_en_US.RestoringInstantMessageWasSuccessful);
		Translations.PublishVersionHistorySize = culture === "fi" ? Translations_fi.PublishVersionHistorySize : (culture === "sv-FI" ? Translations_sv_FI.PublishVersionHistorySize : Translations_en_US.PublishVersionHistorySize);
		Translations.AddDocumentTemplate = culture === "fi" ? Translations_fi.AddDocumentTemplate : (culture === "sv-FI" ? Translations_sv_FI.AddDocumentTemplate : Translations_en_US.AddDocumentTemplate);
		Translations.WhistleBlowDateAndTime = culture === "fi" ? Translations_fi.WhistleBlowDateAndTime : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowDateAndTime : Translations_en_US.WhistleBlowDateAndTime);
		Translations.EmptyingTaskTrashBinWasSuccessful = culture === "fi" ? Translations_fi.EmptyingTaskTrashBinWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.EmptyingTaskTrashBinWasSuccessful : Translations_en_US.EmptyingTaskTrashBinWasSuccessful);
		Translations.ShowOnlyUnitsThatAreInUse = culture === "fi" ? Translations_fi.ShowOnlyUnitsThatAreInUse : (culture === "sv-FI" ? Translations_sv_FI.ShowOnlyUnitsThatAreInUse : Translations_en_US.ShowOnlyUnitsThatAreInUse);
		Translations.ModifyDateStartDate = culture === "fi" ? Translations_fi.ModifyDateStartDate : (culture === "sv-FI" ? Translations_sv_FI.ModifyDateStartDate : Translations_en_US.ModifyDateStartDate);
		Translations.Missing = culture === "fi" ? Translations_fi.Missing : (culture === "sv-FI" ? Translations_sv_FI.Missing : Translations_en_US.Missing);
		Translations.WorkTimeType = culture === "fi" ? Translations_fi.WorkTimeType : (culture === "sv-FI" ? Translations_sv_FI.WorkTimeType : Translations_en_US.WorkTimeType);
		Translations.EditUserGroup = culture === "fi" ? Translations_fi.EditUserGroup : (culture === "sv-FI" ? Translations_sv_FI.EditUserGroup : Translations_en_US.EditUserGroup);
		Translations.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulConcurrencyError);
		Translations.Channels = culture === "fi" ? Translations_fi.Channels : (culture === "sv-FI" ? Translations_sv_FI.Channels : Translations_en_US.Channels);
		Translations.MaximumTotalSizeOfAttachmentsIsParameterMB = culture === "fi" ? Translations_fi.MaximumTotalSizeOfAttachmentsIsParameterMB : (culture === "sv-FI" ? Translations_sv_FI.MaximumTotalSizeOfAttachmentsIsParameterMB : Translations_en_US.MaximumTotalSizeOfAttachmentsIsParameterMB);
		Translations.EditDefaultEventHandlersAndNotifiedPersons = culture === "fi" ? Translations_fi.EditDefaultEventHandlersAndNotifiedPersons : (culture === "sv-FI" ? Translations_sv_FI.EditDefaultEventHandlersAndNotifiedPersons : Translations_en_US.EditDefaultEventHandlersAndNotifiedPersons);
		Translations.CompanyGroupAll = culture === "fi" ? Translations_fi.CompanyGroupAll : (culture === "sv-FI" ? Translations_sv_FI.CompanyGroupAll : Translations_en_US.CompanyGroupAll);
		Translations.SavingTaskDataRowWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingTaskDataRowWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskDataRowWasNotSuccessfulErrorParameter : Translations_en_US.SavingTaskDataRowWasNotSuccessfulErrorParameter);
		Translations.DeletingEventTypeCParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingEventTypeCParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeCParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingEventTypeCParameterWasNotSuccessfulErrorParameter);
		Translations.EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = culture === "fi" ? Translations_fi.EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted : Translations_en_US.EventTypeParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted);
		Translations.ClearSubFolderPermissions = culture === "fi" ? Translations_fi.ClearSubFolderPermissions : (culture === "sv-FI" ? Translations_sv_FI.ClearSubFolderPermissions : Translations_en_US.ClearSubFolderPermissions);
		Translations.CurrentWorkPlaceNotAdded = culture === "fi" ? Translations_fi.CurrentWorkPlaceNotAdded : (culture === "sv-FI" ? Translations_sv_FI.CurrentWorkPlaceNotAdded : Translations_en_US.CurrentWorkPlaceNotAdded);
		Translations.NoInstantMessageChannelsToShow = culture === "fi" ? Translations_fi.NoInstantMessageChannelsToShow : (culture === "sv-FI" ? Translations_sv_FI.NoInstantMessageChannelsToShow : Translations_en_US.NoInstantMessageChannelsToShow);
		Translations.EventTypeC = culture === "fi" ? Translations_fi.EventTypeC : (culture === "sv-FI" ? Translations_sv_FI.EventTypeC : Translations_en_US.EventTypeC);
		Translations.ChangePassword = culture === "fi" ? Translations_fi.ChangePassword : (culture === "sv-FI" ? Translations_sv_FI.ChangePassword : Translations_en_US.ChangePassword);
		Translations.FolderNameMustBeDefined = culture === "fi" ? Translations_fi.FolderNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.FolderNameMustBeDefined : Translations_en_US.FolderNameMustBeDefined);
		Translations.Severity = culture === "fi" ? Translations_fi.Severity : (culture === "sv-FI" ? Translations_sv_FI.Severity : Translations_en_US.Severity);
		Translations.TaskRemainderCheckingAndNotificationFinishedInParameterSeconds = culture === "fi" ? Translations_fi.TaskRemainderCheckingAndNotificationFinishedInParameterSeconds : (culture === "sv-FI" ? Translations_sv_FI.TaskRemainderCheckingAndNotificationFinishedInParameterSeconds : Translations_en_US.TaskRemainderCheckingAndNotificationFinishedInParameterSeconds);
		Translations.SavingUserParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingUserParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingUserParameterWasNotSuccessfulConcurrencyError);
		Translations.WhistleBlowParameterNotFound = culture === "fi" ? Translations_fi.WhistleBlowParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowParameterNotFound : Translations_en_US.WhistleBlowParameterNotFound);
		Translations.Observer = culture === "fi" ? Translations_fi.Observer : (culture === "sv-FI" ? Translations_sv_FI.Observer : Translations_en_US.Observer);
		Translations.DeletingPartnerParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingPartnerParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingPartnerParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingPartnerParameterWasNotSuccessfulErrorParameter);
		Translations.SavingEventTypeCParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventTypeCParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeCParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventTypeCParameterWasNotSuccessfulConcurrencyError);
		Translations.DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingInstantMessageUsersParameterWasNotSuccessfulErrorParameter);
		Translations.AddingEventHandlersWasSuccessful = culture === "fi" ? Translations_fi.AddingEventHandlersWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingEventHandlersWasSuccessful : Translations_en_US.AddingEventHandlersWasSuccessful);
		Translations.Ascending = culture === "fi" ? Translations_fi.Ascending : (culture === "sv-FI" ? Translations_sv_FI.Ascending : Translations_en_US.Ascending);
		Translations.UnlockingTaskDataRowWasNotSuccessful = culture === "fi" ? Translations_fi.UnlockingTaskDataRowWasNotSuccessful : (culture === "sv-FI" ? Translations_sv_FI.UnlockingTaskDataRowWasNotSuccessful : Translations_en_US.UnlockingTaskDataRowWasNotSuccessful);
		Translations.Save = culture === "fi" ? Translations_fi.Save : (culture === "sv-FI" ? Translations_sv_FI.Save : Translations_en_US.Save);
		Translations.RecordFurtherEducationCodeMustBeDefined = culture === "fi" ? Translations_fi.RecordFurtherEducationCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.RecordFurtherEducationCodeMustBeDefined : Translations_en_US.RecordFurtherEducationCodeMustBeDefined);
		Translations.PaymentTermInDays = culture === "fi" ? Translations_fi.PaymentTermInDays : (culture === "sv-FI" ? Translations_sv_FI.PaymentTermInDays : Translations_en_US.PaymentTermInDays);
		Translations.DefaultTaskStates = culture === "fi" ? Translations_fi.DefaultTaskStates : (culture === "sv-FI" ? Translations_sv_FI.DefaultTaskStates : Translations_en_US.DefaultTaskStates);
		Translations.SavingMessageParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingMessageParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingMessageParameterWasNotSuccessfulErrorParameter);
		Translations.AddUsersParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.AddUsersParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.AddUsersParameterWasNotSuccessfulConcurrencyError : Translations_en_US.AddUsersParameterWasNotSuccessfulConcurrencyError);
		Translations.ExternalSystemDatabaseInterfaceError = culture === "fi" ? Translations_fi.ExternalSystemDatabaseInterfaceError : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemDatabaseInterfaceError : Translations_en_US.ExternalSystemDatabaseInterfaceError);
		Translations.RemoveUserAndKeepRecordUser = culture === "fi" ? Translations_fi.RemoveUserAndKeepRecordUser : (culture === "sv-FI" ? Translations_sv_FI.RemoveUserAndKeepRecordUser : Translations_en_US.RemoveUserAndKeepRecordUser);
		Translations.AllLogisticVoucherCustomers = culture === "fi" ? Translations_fi.AllLogisticVoucherCustomers : (culture === "sv-FI" ? Translations_sv_FI.AllLogisticVoucherCustomers : Translations_en_US.AllLogisticVoucherCustomers);
		Translations.Next = culture === "fi" ? Translations_fi.Next : (culture === "sv-FI" ? Translations_sv_FI.Next : Translations_en_US.Next);
		Translations.AddTransferredProperties = culture === "fi" ? Translations_fi.AddTransferredProperties : (culture === "sv-FI" ? Translations_sv_FI.AddTransferredProperties : Translations_en_US.AddTransferredProperties);
		Translations.AddingTaskTypesParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddingTaskTypesParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddingTaskTypesParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddingTaskTypesParameterWasNotSuccessfulErrorParameter);
		Translations.EditableDocumentSaveBeforeApproveConfirmation = culture === "fi" ? Translations_fi.EditableDocumentSaveBeforeApproveConfirmation : (culture === "sv-FI" ? Translations_sv_FI.EditableDocumentSaveBeforeApproveConfirmation : Translations_en_US.EditableDocumentSaveBeforeApproveConfirmation);
		Translations.SelectEventType = culture === "fi" ? Translations_fi.SelectEventType : (culture === "sv-FI" ? Translations_sv_FI.SelectEventType : Translations_en_US.SelectEventType);
		Translations.Actions = culture === "fi" ? Translations_fi.Actions : (culture === "sv-FI" ? Translations_sv_FI.Actions : Translations_en_US.Actions);
		Translations.SelectTaskPriority = culture === "fi" ? Translations_fi.SelectTaskPriority : (culture === "sv-FI" ? Translations_sv_FI.SelectTaskPriority : Translations_en_US.SelectTaskPriority);
		Translations.EventClosed = culture === "fi" ? Translations_fi.EventClosed : (culture === "sv-FI" ? Translations_sv_FI.EventClosed : Translations_en_US.EventClosed);
		Translations.Remainders = culture === "fi" ? Translations_fi.Remainders : (culture === "sv-FI" ? Translations_sv_FI.Remainders : Translations_en_US.Remainders);
		Translations.UsedFileStorageQuota = culture === "fi" ? Translations_fi.UsedFileStorageQuota : (culture === "sv-FI" ? Translations_sv_FI.UsedFileStorageQuota : Translations_en_US.UsedFileStorageQuota);
		Translations.Yes = culture === "fi" ? Translations_fi.Yes : (culture === "sv-FI" ? Translations_sv_FI.Yes : Translations_en_US.Yes);
		Translations.AddLogisticVoucherCustomerPaymentSetting = culture === "fi" ? Translations_fi.AddLogisticVoucherCustomerPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.AddLogisticVoucherCustomerPaymentSetting : Translations_en_US.AddLogisticVoucherCustomerPaymentSetting);
		Translations.Interval = culture === "fi" ? Translations_fi.Interval : (culture === "sv-FI" ? Translations_sv_FI.Interval : Translations_en_US.Interval);
		Translations.SelectUrgencyClass = culture === "fi" ? Translations_fi.SelectUrgencyClass : (culture === "sv-FI" ? Translations_sv_FI.SelectUrgencyClass : Translations_en_US.SelectUrgencyClass);
		Translations.FileChangesCheckingAndEmailingStarted = culture === "fi" ? Translations_fi.FileChangesCheckingAndEmailingStarted : (culture === "sv-FI" ? Translations_sv_FI.FileChangesCheckingAndEmailingStarted : Translations_en_US.FileChangesCheckingAndEmailingStarted);
		Translations.Receiver = culture === "fi" ? Translations_fi.Receiver : (culture === "sv-FI" ? Translations_sv_FI.Receiver : Translations_en_US.Receiver);
		Translations.SavingEventMessageWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventMessageWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventMessageWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventMessageWasNotSuccessfulConcurrencyError);
		Translations.InTesting = culture === "fi" ? Translations_fi.InTesting : (culture === "sv-FI" ? Translations_sv_FI.InTesting : Translations_en_US.InTesting);
		Translations.Qualification = culture === "fi" ? Translations_fi.Qualification : (culture === "sv-FI" ? Translations_sv_FI.Qualification : Translations_en_US.Qualification);
		Translations.AttachmentParameterDownloaded = culture === "fi" ? Translations_fi.AttachmentParameterDownloaded : (culture === "sv-FI" ? Translations_sv_FI.AttachmentParameterDownloaded : Translations_en_US.AttachmentParameterDownloaded);
		Translations.RemoveFromFavorites = culture === "fi" ? Translations_fi.RemoveFromFavorites : (culture === "sv-FI" ? Translations_sv_FI.RemoveFromFavorites : Translations_en_US.RemoveFromFavorites);
		Translations.SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingMessageSendEmailParameterWasNotSuccessfulConcurrencyError);
		Translations.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingWhistleBlowChannelAdminParameterWasNotSuccessfulConcurrencyError);
		Translations.EventParameterUnitChangedFromParameterToParameter = culture === "fi" ? Translations_fi.EventParameterUnitChangedFromParameterToParameter : (culture === "sv-FI" ? Translations_sv_FI.EventParameterUnitChangedFromParameterToParameter : Translations_en_US.EventParameterUnitChangedFromParameterToParameter);
		Translations.VoucherPrefix = culture === "fi" ? Translations_fi.VoucherPrefix : (culture === "sv-FI" ? Translations_sv_FI.VoucherPrefix : Translations_en_US.VoucherPrefix);
		Translations.Form = culture === "fi" ? Translations_fi.Form : (culture === "sv-FI" ? Translations_sv_FI.Form : Translations_en_US.Form);
		Translations.SavingTaskParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingTaskParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingTaskParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingTaskParameterWasNotSuccessfulConcurrencyError);
		Translations.DeletingRecordFamiliarizationsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordFamiliarizationsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFamiliarizationsParameterWasSuccessful : Translations_en_US.DeletingRecordFamiliarizationsParameterWasSuccessful);
		Translations.UserCodeMustBeDefined = culture === "fi" ? Translations_fi.UserCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.UserCodeMustBeDefined : Translations_en_US.UserCodeMustBeDefined);
		Translations.AddUserGroupsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.AddUserGroupsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.AddUserGroupsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.AddUserGroupsParameterWasNotSuccessfulConcurrencyError);
		Translations.DeletingWhistleBlowParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingWhistleBlowParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowParameterWasSuccessful : Translations_en_US.DeletingWhistleBlowParameterWasSuccessful);
		Translations.BusinessIdentityCode = culture === "fi" ? Translations_fi.BusinessIdentityCode : (culture === "sv-FI" ? Translations_sv_FI.BusinessIdentityCode : Translations_en_US.BusinessIdentityCode);
		Translations.RemoveCompanyTaskType = culture === "fi" ? Translations_fi.RemoveCompanyTaskType : (culture === "sv-FI" ? Translations_sv_FI.RemoveCompanyTaskType : Translations_en_US.RemoveCompanyTaskType);
		Translations.RemoveLogisticVoucherPaymentSetting = culture === "fi" ? Translations_fi.RemoveLogisticVoucherPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.RemoveLogisticVoucherPaymentSetting : Translations_en_US.RemoveLogisticVoucherPaymentSetting);
		Translations.SearchDocuments = culture === "fi" ? Translations_fi.SearchDocuments : (culture === "sv-FI" ? Translations_sv_FI.SearchDocuments : Translations_en_US.SearchDocuments);
		Translations.RecordParameterLockingFailed = culture === "fi" ? Translations_fi.RecordParameterLockingFailed : (culture === "sv-FI" ? Translations_sv_FI.RecordParameterLockingFailed : Translations_en_US.RecordParameterLockingFailed);
		Translations.SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingMessageSendEmailParameterWasNotSuccessfulErrorParameter);
		Translations.FieldParameterContainsInvalidCodeCharacters = culture === "fi" ? Translations_fi.FieldParameterContainsInvalidCodeCharacters : (culture === "sv-FI" ? Translations_sv_FI.FieldParameterContainsInvalidCodeCharacters : Translations_en_US.FieldParameterContainsInvalidCodeCharacters);
		Translations.CustomerNumber = culture === "fi" ? Translations_fi.CustomerNumber : (culture === "sv-FI" ? Translations_sv_FI.CustomerNumber : Translations_en_US.CustomerNumber);
		Translations.DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingTaskTypeParameterWasNotSuccessfulConcurrencyError);
		Translations.RecordUserExcelFamiliarization = culture === "fi" ? Translations_fi.RecordUserExcelFamiliarization : (culture === "sv-FI" ? Translations_sv_FI.RecordUserExcelFamiliarization : Translations_en_US.RecordUserExcelFamiliarization);
		Translations.PageBreakBetweenGroups = culture === "fi" ? Translations_fi.PageBreakBetweenGroups : (culture === "sv-FI" ? Translations_sv_FI.PageBreakBetweenGroups : Translations_en_US.PageBreakBetweenGroups);
		Translations.AddTask = culture === "fi" ? Translations_fi.AddTask : (culture === "sv-FI" ? Translations_sv_FI.AddTask : Translations_en_US.AddTask);
		Translations.Filter = culture === "fi" ? Translations_fi.Filter : (culture === "sv-FI" ? Translations_sv_FI.Filter : Translations_en_US.Filter);
		Translations.EventTypeBParameterNotFound = culture === "fi" ? Translations_fi.EventTypeBParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EventTypeBParameterNotFound : Translations_en_US.EventTypeBParameterNotFound);
		Translations.DoYouWantToAddThisApplicationToHomeScreen = culture === "fi" ? Translations_fi.DoYouWantToAddThisApplicationToHomeScreen : (culture === "sv-FI" ? Translations_sv_FI.DoYouWantToAddThisApplicationToHomeScreen : Translations_en_US.DoYouWantToAddThisApplicationToHomeScreen);
		Translations.WhistleBlowAnonymousReportInfo = culture === "fi" ? Translations_fi.WhistleBlowAnonymousReportInfo : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowAnonymousReportInfo : Translations_en_US.WhistleBlowAnonymousReportInfo);
		Translations.LanguageNonLocalFinnish = culture === "fi" ? Translations_fi.LanguageNonLocalFinnish : (culture === "sv-FI" ? Translations_sv_FI.LanguageNonLocalFinnish : Translations_en_US.LanguageNonLocalFinnish);
		Translations.DocumentZipFileName = culture === "fi" ? Translations_fi.DocumentZipFileName : (culture === "sv-FI" ? Translations_sv_FI.DocumentZipFileName : Translations_en_US.DocumentZipFileName);
		Translations.JobLineParameterNotFound = culture === "fi" ? Translations_fi.JobLineParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.JobLineParameterNotFound : Translations_en_US.JobLineParameterNotFound);
		Translations.DocumentEndDate = culture === "fi" ? Translations_fi.DocumentEndDate : (culture === "sv-FI" ? Translations_sv_FI.DocumentEndDate : Translations_en_US.DocumentEndDate);
		Translations.SavingInstantMessageWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingInstantMessageWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingInstantMessageWasNotSuccessfulErrorParameter : Translations_en_US.SavingInstantMessageWasNotSuccessfulErrorParameter);
		Translations.DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingDocumentTemplateParameterWasNotSuccessfulConcurrencyError);
		Translations.AddTransferredPropertyToMultipleUserRecords = culture === "fi" ? Translations_fi.AddTransferredPropertyToMultipleUserRecords : (culture === "sv-FI" ? Translations_sv_FI.AddTransferredPropertyToMultipleUserRecords : Translations_en_US.AddTransferredPropertyToMultipleUserRecords);
		Translations.DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingInstantMessageChannelWasNotSuccessfulConcurrencyError);
		Translations.AddChildEventType = culture === "fi" ? Translations_fi.AddChildEventType : (culture === "sv-FI" ? Translations_sv_FI.AddChildEventType : Translations_en_US.AddChildEventType);
		Translations.QualificationIsInvalid = culture === "fi" ? Translations_fi.QualificationIsInvalid : (culture === "sv-FI" ? Translations_sv_FI.QualificationIsInvalid : Translations_en_US.QualificationIsInvalid);
		Translations.AddUserGroups = culture === "fi" ? Translations_fi.AddUserGroups : (culture === "sv-FI" ? Translations_sv_FI.AddUserGroups : Translations_en_US.AddUserGroups);
		Translations.DeletingEventTypeBParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingEventTypeBParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeBParameterWasSuccessful : Translations_en_US.DeletingEventTypeBParameterWasSuccessful);
		Translations.CompanyLogo = culture === "fi" ? Translations_fi.CompanyLogo : (culture === "sv-FI" ? Translations_sv_FI.CompanyLogo : Translations_en_US.CompanyLogo);
		Translations.KeywordParameterNotFound = culture === "fi" ? Translations_fi.KeywordParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.KeywordParameterNotFound : Translations_en_US.KeywordParameterNotFound);
		Translations.PrintRecordUser = culture === "fi" ? Translations_fi.PrintRecordUser : (culture === "sv-FI" ? Translations_sv_FI.PrintRecordUser : Translations_en_US.PrintRecordUser);
		Translations.SavingFilterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingFilterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingFilterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingFilterWasNotSuccessfulConcurrencyError);
		Translations.EventPartners = culture === "fi" ? Translations_fi.EventPartners : (culture === "sv-FI" ? Translations_sv_FI.EventPartners : Translations_en_US.EventPartners);
		Translations.DeletingAttachmentsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingAttachmentsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingAttachmentsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingAttachmentsParameterWasNotSuccessfulErrorParameter);
		Translations.FolderParameterNotFound = culture === "fi" ? Translations_fi.FolderParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.FolderParameterNotFound : Translations_en_US.FolderParameterNotFound);
		Translations.Salary = culture === "fi" ? Translations_fi.Salary : (culture === "sv-FI" ? Translations_sv_FI.Salary : Translations_en_US.Salary);
		Translations.ClickToCancelAutomaticLogout = culture === "fi" ? Translations_fi.ClickToCancelAutomaticLogout : (culture === "sv-FI" ? Translations_sv_FI.ClickToCancelAutomaticLogout : Translations_en_US.ClickToCancelAutomaticLogout);
		Translations.TaskStates = culture === "fi" ? Translations_fi.TaskStates : (culture === "sv-FI" ? Translations_sv_FI.TaskStates : Translations_en_US.TaskStates);
		Translations.DefaultLanguage = culture === "fi" ? Translations_fi.DefaultLanguage : (culture === "sv-FI" ? Translations_sv_FI.DefaultLanguage : Translations_en_US.DefaultLanguage);
		Translations.Last180Days = culture === "fi" ? Translations_fi.Last180Days : (culture === "sv-FI" ? Translations_sv_FI.Last180Days : Translations_en_US.Last180Days);
		Translations.WhistleBlowSourceEmail = culture === "fi" ? Translations_fi.WhistleBlowSourceEmail : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSourceEmail : Translations_en_US.WhistleBlowSourceEmail);
		Translations.DatabaseCheckingStarted = culture === "fi" ? Translations_fi.DatabaseCheckingStarted : (culture === "sv-FI" ? Translations_sv_FI.DatabaseCheckingStarted : Translations_en_US.DatabaseCheckingStarted);
		Translations.UnitChangedFromParameterToParameter = culture === "fi" ? Translations_fi.UnitChangedFromParameterToParameter : (culture === "sv-FI" ? Translations_sv_FI.UnitChangedFromParameterToParameter : Translations_en_US.UnitChangedFromParameterToParameter);
		Translations.BrowserInfo = culture === "fi" ? Translations_fi.BrowserInfo : (culture === "sv-FI" ? Translations_sv_FI.BrowserInfo : Translations_en_US.BrowserInfo);
		Translations.ApplicationName = culture === "fi" ? Translations_fi.ApplicationName : (culture === "sv-FI" ? Translations_sv_FI.ApplicationName : Translations_en_US.ApplicationName);
		Translations.SSRSRefreshFinishedInParameterSeconds = culture === "fi" ? Translations_fi.SSRSRefreshFinishedInParameterSeconds : (culture === "sv-FI" ? Translations_sv_FI.SSRSRefreshFinishedInParameterSeconds : Translations_en_US.SSRSRefreshFinishedInParameterSeconds);
		Translations.EventParameterNotFound = culture === "fi" ? Translations_fi.EventParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.EventParameterNotFound : Translations_en_US.EventParameterNotFound);
		Translations.InstantMessageChannelAdministrator = culture === "fi" ? Translations_fi.InstantMessageChannelAdministrator : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelAdministrator : Translations_en_US.InstantMessageChannelAdministrator);
		Translations.SavingEventSettingsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventSettingsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventSettingsWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventSettingsWasNotSuccessfulConcurrencyError);
		Translations.DeletingMessageTypeParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingMessageTypeParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingMessageTypeParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingMessageTypeParameterWasNotSuccessfulErrorParameter);
		Translations.ExternalSystemCompanyCodeClaimNotFound = culture === "fi" ? Translations_fi.ExternalSystemCompanyCodeClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemCompanyCodeClaimNotFound : Translations_en_US.ExternalSystemCompanyCodeClaimNotFound);
		Translations.LockedTaskParameterCannotBeSaved = culture === "fi" ? Translations_fi.LockedTaskParameterCannotBeSaved : (culture === "sv-FI" ? Translations_sv_FI.LockedTaskParameterCannotBeSaved : Translations_en_US.LockedTaskParameterCannotBeSaved);
		Translations.WhistleBlowReport = culture === "fi" ? Translations_fi.WhistleBlowReport : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowReport : Translations_en_US.WhistleBlowReport);
		Translations.IncludeArchivedRecordUsers = culture === "fi" ? Translations_fi.IncludeArchivedRecordUsers : (culture === "sv-FI" ? Translations_sv_FI.IncludeArchivedRecordUsers : Translations_en_US.IncludeArchivedRecordUsers);
		Translations.SavingEventTypeBParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEventTypeBParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEventTypeBParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingEventTypeBParameterWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToUnlockTaskDataRow = culture === "fi" ? Translations_fi.DoYouReallyWantToUnlockTaskDataRow : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToUnlockTaskDataRow : Translations_en_US.DoYouReallyWantToUnlockTaskDataRow);
		Translations.SavePrint = culture === "fi" ? Translations_fi.SavePrint : (culture === "sv-FI" ? Translations_sv_FI.SavePrint : Translations_en_US.SavePrint);
		Translations.SendReadReceipt = culture === "fi" ? Translations_fi.SendReadReceipt : (culture === "sv-FI" ? Translations_sv_FI.SendReadReceipt : Translations_en_US.SendReadReceipt);
		Translations.ValidityDate = culture === "fi" ? Translations_fi.ValidityDate : (culture === "sv-FI" ? Translations_sv_FI.ValidityDate : Translations_en_US.ValidityDate);
		Translations.FieldParameterContainsInvalidCompanyCodeCharacters = culture === "fi" ? Translations_fi.FieldParameterContainsInvalidCompanyCodeCharacters : (culture === "sv-FI" ? Translations_sv_FI.FieldParameterContainsInvalidCompanyCodeCharacters : Translations_en_US.FieldParameterContainsInvalidCompanyCodeCharacters);
		Translations.RecordUserExcelSalary = culture === "fi" ? Translations_fi.RecordUserExcelSalary : (culture === "sv-FI" ? Translations_sv_FI.RecordUserExcelSalary : Translations_en_US.RecordUserExcelSalary);
		Translations.WhistleBlowCategory = culture === "fi" ? Translations_fi.WhistleBlowCategory : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowCategory : Translations_en_US.WhistleBlowCategory);
		Translations.AccessRightWrite = culture === "fi" ? Translations_fi.AccessRightWrite : (culture === "sv-FI" ? Translations_sv_FI.AccessRightWrite : Translations_en_US.AccessRightWrite);
		Translations.TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted = culture === "fi" ? Translations_fi.TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted : Translations_en_US.TaskTypeParameterIsInUseInParameterTasksAndCannotBeDeleted);
		Translations.DocumentApproval = culture === "fi" ? Translations_fi.DocumentApproval : (culture === "sv-FI" ? Translations_sv_FI.DocumentApproval : Translations_en_US.DocumentApproval);
		Translations.DeletingRecordQualificationsParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordQualificationsParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordQualificationsParameterWasSuccessful : Translations_en_US.DeletingRecordQualificationsParameterWasSuccessful);
		Translations.PermissionToIdentificateEvents = culture === "fi" ? Translations_fi.PermissionToIdentificateEvents : (culture === "sv-FI" ? Translations_sv_FI.PermissionToIdentificateEvents : Translations_en_US.PermissionToIdentificateEvents);
		Translations.SendingPusNotificationsWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SendingPusNotificationsWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SendingPusNotificationsWasNotSuccessfulErrorParameter : Translations_en_US.SendingPusNotificationsWasNotSuccessfulErrorParameter);
		Translations.DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingCompanyGroupsParameterWasNotSuccessfulConcurrencyError);
		Translations.Years = culture === "fi" ? Translations_fi.Years : (culture === "sv-FI" ? Translations_sv_FI.Years : Translations_en_US.Years);
		Translations.EditMessage = culture === "fi" ? Translations_fi.EditMessage : (culture === "sv-FI" ? Translations_sv_FI.EditMessage : Translations_en_US.EditMessage);
		Translations.DeletingRecordUserParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordUserParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordUserParameterWasNotSuccessfulErrorParameter);
		Translations.SaveTaskConfirmation = culture === "fi" ? Translations_fi.SaveTaskConfirmation : (culture === "sv-FI" ? Translations_sv_FI.SaveTaskConfirmation : Translations_en_US.SaveTaskConfirmation);
		Translations.EventSenderIsPartnerCompany = culture === "fi" ? Translations_fi.EventSenderIsPartnerCompany : (culture === "sv-FI" ? Translations_sv_FI.EventSenderIsPartnerCompany : Translations_en_US.EventSenderIsPartnerCompany);
		Translations.UserPasswordMustBeValid = culture === "fi" ? Translations_fi.UserPasswordMustBeValid : (culture === "sv-FI" ? Translations_sv_FI.UserPasswordMustBeValid : Translations_en_US.UserPasswordMustBeValid);
		Translations.PrintEvent = culture === "fi" ? Translations_fi.PrintEvent : (culture === "sv-FI" ? Translations_sv_FI.PrintEvent : Translations_en_US.PrintEvent);
		Translations.PayeeUsersCanLogInToTheSystem = culture === "fi" ? Translations_fi.PayeeUsersCanLogInToTheSystem : (culture === "sv-FI" ? Translations_sv_FI.PayeeUsersCanLogInToTheSystem : Translations_en_US.PayeeUsersCanLogInToTheSystem);
		Translations.MessageMustBeDefined = culture === "fi" ? Translations_fi.MessageMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.MessageMustBeDefined : Translations_en_US.MessageMustBeDefined);
		Translations.ChangedUrgencyClass = culture === "fi" ? Translations_fi.ChangedUrgencyClass : (culture === "sv-FI" ? Translations_sv_FI.ChangedUrgencyClass : Translations_en_US.ChangedUrgencyClass);
		Translations.InviteInstantMessageChannelUser = culture === "fi" ? Translations_fi.InviteInstantMessageChannelUser : (culture === "sv-FI" ? Translations_sv_FI.InviteInstantMessageChannelUser : Translations_en_US.InviteInstantMessageChannelUser);
		Translations.UserEventsNotApproved = culture === "fi" ? Translations_fi.UserEventsNotApproved : (culture === "sv-FI" ? Translations_sv_FI.UserEventsNotApproved : Translations_en_US.UserEventsNotApproved);
		Translations.NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded = culture === "fi" ? Translations_fi.NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded : (culture === "sv-FI" ? Translations_sv_FI.NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded : Translations_en_US.NewCompanyMustBeSavedBeforeCompanyGroupsCanBeAdded);
		Translations.SendReadReceiptInfo = culture === "fi" ? Translations_fi.SendReadReceiptInfo : (culture === "sv-FI" ? Translations_sv_FI.SendReadReceiptInfo : Translations_en_US.SendReadReceiptInfo);
		Translations.DefaultEventUrgencyClasses = culture === "fi" ? Translations_fi.DefaultEventUrgencyClasses : (culture === "sv-FI" ? Translations_sv_FI.DefaultEventUrgencyClasses : Translations_en_US.DefaultEventUrgencyClasses);
		Translations.WhistleBlowInformantDateAndTime = culture === "fi" ? Translations_fi.WhistleBlowInformantDateAndTime : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantDateAndTime : Translations_en_US.WhistleBlowInformantDateAndTime);
		Translations.SavingUserParameterParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingUserParameterParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParameterParameterWasSuccessful : Translations_en_US.SavingUserParameterParameterWasSuccessful);
		Translations.PreviewIsNotSupportedForThisFileType = culture === "fi" ? Translations_fi.PreviewIsNotSupportedForThisFileType : (culture === "sv-FI" ? Translations_sv_FI.PreviewIsNotSupportedForThisFileType : Translations_en_US.PreviewIsNotSupportedForThisFileType);
		Translations.Last7Days = culture === "fi" ? Translations_fi.Last7Days : (culture === "sv-FI" ? Translations_sv_FI.Last7Days : Translations_en_US.Last7Days);
		Translations.Picture = culture === "fi" ? Translations_fi.Picture : (culture === "sv-FI" ? Translations_sv_FI.Picture : Translations_en_US.Picture);
		Translations.AddPermission = culture === "fi" ? Translations_fi.AddPermission : (culture === "sv-FI" ? Translations_sv_FI.AddPermission : Translations_en_US.AddPermission);
		Translations.EmailMessage = culture === "fi" ? Translations_fi.EmailMessage : (culture === "sv-FI" ? Translations_sv_FI.EmailMessage : Translations_en_US.EmailMessage);
		Translations.LastName = culture === "fi" ? Translations_fi.LastName : (culture === "sv-FI" ? Translations_sv_FI.LastName : Translations_en_US.LastName);
		Translations.DeletingTaskStateParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingTaskStateParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskStateParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingTaskStateParameterWasNotSuccessfulErrorParameter);
		Translations.SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingDirectoryItemDocumentParameterWasNotSuccessfulErrorParameter);
		Translations.LockingEditableDocumentParameterWasNotSuccessful = culture === "fi" ? Translations_fi.LockingEditableDocumentParameterWasNotSuccessful : (culture === "sv-FI" ? Translations_sv_FI.LockingEditableDocumentParameterWasNotSuccessful : Translations_en_US.LockingEditableDocumentParameterWasNotSuccessful);
		Translations.DeletingAttachmentParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingAttachmentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingAttachmentParameterWasSuccessful : Translations_en_US.DeletingAttachmentParameterWasSuccessful);
		Translations.ValidPlural = culture === "fi" ? Translations_fi.ValidPlural : (culture === "sv-FI" ? Translations_sv_FI.ValidPlural : Translations_en_US.ValidPlural);
		Translations.RootCause = culture === "fi" ? Translations_fi.RootCause : (culture === "sv-FI" ? Translations_sv_FI.RootCause : Translations_en_US.RootCause);
		Translations.Partner = culture === "fi" ? Translations_fi.Partner : (culture === "sv-FI" ? Translations_sv_FI.Partner : Translations_en_US.Partner);
		Translations.PermissionsForSections = culture === "fi" ? Translations_fi.PermissionsForSections : (culture === "sv-FI" ? Translations_sv_FI.PermissionsForSections : Translations_en_US.PermissionsForSections);
		Translations.ArchiveWhistleBlows = culture === "fi" ? Translations_fi.ArchiveWhistleBlows : (culture === "sv-FI" ? Translations_sv_FI.ArchiveWhistleBlows : Translations_en_US.ArchiveWhistleBlows);
		Translations.Version = culture === "fi" ? Translations_fi.Version : (culture === "sv-FI" ? Translations_sv_FI.Version : Translations_en_US.Version);
		Translations.ReportsStandard = culture === "fi" ? Translations_fi.ReportsStandard : (culture === "sv-FI" ? Translations_sv_FI.ReportsStandard : Translations_en_US.ReportsStandard);
		Translations.ReadReceiptGiven = culture === "fi" ? Translations_fi.ReadReceiptGiven : (culture === "sv-FI" ? Translations_sv_FI.ReadReceiptGiven : Translations_en_US.ReadReceiptGiven);
		Translations.SavingMessageTypeParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingMessageTypeParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageTypeParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingMessageTypeParameterWasNotSuccessfulErrorParameter);
		Translations.RemoveUserFromUserGroup = culture === "fi" ? Translations_fi.RemoveUserFromUserGroup : (culture === "sv-FI" ? Translations_sv_FI.RemoveUserFromUserGroup : Translations_en_US.RemoveUserFromUserGroup);
		Translations.DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents = culture === "fi" ? Translations_fi.DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents : (culture === "sv-FI" ? Translations_sv_FI.DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents : Translations_en_US.DefaultEventUrgencyClassMustBeDefinedBeforeAddingEvents);
		Translations.ClearSubUnitPermissions = culture === "fi" ? Translations_fi.ClearSubUnitPermissions : (culture === "sv-FI" ? Translations_sv_FI.ClearSubUnitPermissions : Translations_en_US.ClearSubUnitPermissions);
		Translations.AType = culture === "fi" ? Translations_fi.AType : (culture === "sv-FI" ? Translations_sv_FI.AType : Translations_en_US.AType);
		Translations.RemoveMessageType = culture === "fi" ? Translations_fi.RemoveMessageType : (culture === "sv-FI" ? Translations_sv_FI.RemoveMessageType : Translations_en_US.RemoveMessageType);
		Translations.DocumentNameIsIllegal = culture === "fi" ? Translations_fi.DocumentNameIsIllegal : (culture === "sv-FI" ? Translations_sv_FI.DocumentNameIsIllegal : Translations_en_US.DocumentNameIsIllegal);
		Translations.AddCompanyGroupParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.AddCompanyGroupParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyGroupParameterWasNotSuccessfulConcurrencyError : Translations_en_US.AddCompanyGroupParameterWasNotSuccessfulConcurrencyError);
		Translations.EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted = culture === "fi" ? Translations_fi.EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted : Translations_en_US.EventTypeBParameterChildrenAreInUseInParameterEventsAndCannotBeDeleted);
		Translations.UnlockingTaskParameterWasNotSuccessful = culture === "fi" ? Translations_fi.UnlockingTaskParameterWasNotSuccessful : (culture === "sv-FI" ? Translations_sv_FI.UnlockingTaskParameterWasNotSuccessful : Translations_en_US.UnlockingTaskParameterWasNotSuccessful);
		Translations.Row = culture === "fi" ? Translations_fi.Row : (culture === "sv-FI" ? Translations_sv_FI.Row : Translations_en_US.Row);
		Translations.SaveInstantMessageChannelConfirmation = culture === "fi" ? Translations_fi.SaveInstantMessageChannelConfirmation : (culture === "sv-FI" ? Translations_sv_FI.SaveInstantMessageChannelConfirmation : Translations_en_US.SaveInstantMessageChannelConfirmation);
		Translations.NotSelected = culture === "fi" ? Translations_fi.NotSelected : (culture === "sv-FI" ? Translations_sv_FI.NotSelected : Translations_en_US.NotSelected);
		Translations.Private = culture === "fi" ? Translations_fi.Private : (culture === "sv-FI" ? Translations_sv_FI.Private : Translations_en_US.Private);
		Translations.SavingUserParametersWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingUserParametersWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParametersWasNotSuccessfulErrorParameter : Translations_en_US.SavingUserParametersWasNotSuccessfulErrorParameter);
		Translations.Identification = culture === "fi" ? Translations_fi.Identification : (culture === "sv-FI" ? Translations_sv_FI.Identification : Translations_en_US.Identification);
		Translations.InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter = culture === "fi" ? Translations_fi.InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter : Translations_en_US.InstantMessageChannelArchivingTypeRemoveChannelAtDateParameter);
		Translations.TaskPriorityParameterNotFound = culture === "fi" ? Translations_fi.TaskPriorityParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.TaskPriorityParameterNotFound : Translations_en_US.TaskPriorityParameterNotFound);
		Translations.Uploaded = culture === "fi" ? Translations_fi.Uploaded : (culture === "sv-FI" ? Translations_sv_FI.Uploaded : Translations_en_US.Uploaded);
		Translations.AutomaticLogoutDone = culture === "fi" ? Translations_fi.AutomaticLogoutDone : (culture === "sv-FI" ? Translations_sv_FI.AutomaticLogoutDone : Translations_en_US.AutomaticLogoutDone);
		Translations.RemoveTaskType = culture === "fi" ? Translations_fi.RemoveTaskType : (culture === "sv-FI" ? Translations_sv_FI.RemoveTaskType : Translations_en_US.RemoveTaskType);
		Translations.EventTypeBTitle = culture === "fi" ? Translations_fi.EventTypeBTitle : (culture === "sv-FI" ? Translations_sv_FI.EventTypeBTitle : Translations_en_US.EventTypeBTitle);
		Translations.SelectUnit = culture === "fi" ? Translations_fi.SelectUnit : (culture === "sv-FI" ? Translations_sv_FI.SelectUnit : Translations_en_US.SelectUnit);
		Translations.DeletingUsersParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingUsersParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingUsersParameterWasSuccessful : Translations_en_US.DeletingUsersParameterWasSuccessful);
		Translations.AddQualification = culture === "fi" ? Translations_fi.AddQualification : (culture === "sv-FI" ? Translations_sv_FI.AddQualification : Translations_en_US.AddQualification);
		Translations.DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingInstantMessageUserParameterWasNotSuccessfulErrorParameter);
		Translations.DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders = culture === "fi" ? Translations_fi.DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders : (culture === "sv-FI" ? Translations_sv_FI.DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders : Translations_en_US.DeletingFileFilterParameterWasNotSuccessfulUsedInParameterFolders);
		Translations.UnitIsRelatedToEventPartner = culture === "fi" ? Translations_fi.UnitIsRelatedToEventPartner : (culture === "sv-FI" ? Translations_sv_FI.UnitIsRelatedToEventPartner : Translations_en_US.UnitIsRelatedToEventPartner);
		Translations.NoFilters = culture === "fi" ? Translations_fi.NoFilters : (culture === "sv-FI" ? Translations_sv_FI.NoFilters : Translations_en_US.NoFilters);
		Translations.EditDefaultFurtherEducation = culture === "fi" ? Translations_fi.EditDefaultFurtherEducation : (culture === "sv-FI" ? Translations_sv_FI.EditDefaultFurtherEducation : Translations_en_US.EditDefaultFurtherEducation);
		Translations.UserGroups = culture === "fi" ? Translations_fi.UserGroups : (culture === "sv-FI" ? Translations_sv_FI.UserGroups : Translations_en_US.UserGroups);
		Translations.RecordUserLogEntryTypeCreate = culture === "fi" ? Translations_fi.RecordUserLogEntryTypeCreate : (culture === "sv-FI" ? Translations_sv_FI.RecordUserLogEntryTypeCreate : Translations_en_US.RecordUserLogEntryTypeCreate);
		Translations.ShortName = culture === "fi" ? Translations_fi.ShortName : (culture === "sv-FI" ? Translations_sv_FI.ShortName : Translations_en_US.ShortName);
		Translations.Validity = culture === "fi" ? Translations_fi.Validity : (culture === "sv-FI" ? Translations_sv_FI.Validity : Translations_en_US.Validity);
		Translations.DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordFamiliarizationsParameterWasNotSuccessfulConcurrencyError);
		Translations.DeletingPartnerParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingPartnerParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingPartnerParameterWasSuccessful : Translations_en_US.DeletingPartnerParameterWasSuccessful);
		Translations.OtherCompanies = culture === "fi" ? Translations_fi.OtherCompanies : (culture === "sv-FI" ? Translations_sv_FI.OtherCompanies : Translations_en_US.OtherCompanies);
		Translations.CourseCredit = culture === "fi" ? Translations_fi.CourseCredit : (culture === "sv-FI" ? Translations_sv_FI.CourseCredit : Translations_en_US.CourseCredit);
		Translations.AddRemainder = culture === "fi" ? Translations_fi.AddRemainder : (culture === "sv-FI" ? Translations_sv_FI.AddRemainder : Translations_en_US.AddRemainder);
		Translations.FullNameClaimNotFound = culture === "fi" ? Translations_fi.FullNameClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.FullNameClaimNotFound : Translations_en_US.FullNameClaimNotFound);
		Translations.UserGroupParameterNotFound = culture === "fi" ? Translations_fi.UserGroupParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.UserGroupParameterNotFound : Translations_en_US.UserGroupParameterNotFound);
		Translations.DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingDirectoryItemFolderParameterWasNotSuccessfulErrorParameter);
		Translations.PanelSize1 = culture === "fi" ? Translations_fi.PanelSize1 : (culture === "sv-FI" ? Translations_sv_FI.PanelSize1 : Translations_en_US.PanelSize1);
		Translations.DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError);
		Translations.Messages = culture === "fi" ? Translations_fi.Messages : (culture === "sv-FI" ? Translations_sv_FI.Messages : Translations_en_US.Messages);
		Translations.FileParameterIsNotImage = culture === "fi" ? Translations_fi.FileParameterIsNotImage : (culture === "sv-FI" ? Translations_sv_FI.FileParameterIsNotImage : Translations_en_US.FileParameterIsNotImage);
		Translations.DocumentNameIsTooLong = culture === "fi" ? Translations_fi.DocumentNameIsTooLong : (culture === "sv-FI" ? Translations_sv_FI.DocumentNameIsTooLong : Translations_en_US.DocumentNameIsTooLong);
		Translations.EmailNotificationOfChangedPublishStatus = culture === "fi" ? Translations_fi.EmailNotificationOfChangedPublishStatus : (culture === "sv-FI" ? Translations_sv_FI.EmailNotificationOfChangedPublishStatus : Translations_en_US.EmailNotificationOfChangedPublishStatus);
		Translations.AllPartners = culture === "fi" ? Translations_fi.AllPartners : (culture === "sv-FI" ? Translations_sv_FI.AllPartners : Translations_en_US.AllPartners);
		Translations.SharedReportCompany = culture === "fi" ? Translations_fi.SharedReportCompany : (culture === "sv-FI" ? Translations_sv_FI.SharedReportCompany : Translations_en_US.SharedReportCompany);
		Translations.ScopeUnit = culture === "fi" ? Translations_fi.ScopeUnit : (culture === "sv-FI" ? Translations_sv_FI.ScopeUnit : Translations_en_US.ScopeUnit);
		Translations.PrintParameterNotFound = culture === "fi" ? Translations_fi.PrintParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.PrintParameterNotFound : Translations_en_US.PrintParameterNotFound);
		Translations.DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingUserGroupsParameterWasNotSuccessfulConcurrencyError);
		Translations.DeletingDefaultEventHandlerParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingDefaultEventHandlerParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingDefaultEventHandlerParameterWasSuccessful : Translations_en_US.DeletingDefaultEventHandlerParameterWasSuccessful);
		Translations.TimeMustBeDefined = culture === "fi" ? Translations_fi.TimeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.TimeMustBeDefined : Translations_en_US.TimeMustBeDefined);
		Translations.EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted = culture === "fi" ? Translations_fi.EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted : Translations_en_US.EventTypeBParameterIsInUseInParameterEventsAndCannotBeDeleted);
		Translations.RecordUserExcelTabName = culture === "fi" ? Translations_fi.RecordUserExcelTabName : (culture === "sv-FI" ? Translations_sv_FI.RecordUserExcelTabName : Translations_en_US.RecordUserExcelTabName);
		Translations.DeletingTaskStatesParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingTaskStatesParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingTaskStatesParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingTaskStatesParameterWasNotSuccessfulErrorParameter);
		Translations.CompanyParameterNotFound = culture === "fi" ? Translations_fi.CompanyParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.CompanyParameterNotFound : Translations_en_US.CompanyParameterNotFound);
		Translations.SearchEndDate = culture === "fi" ? Translations_fi.SearchEndDate : (culture === "sv-FI" ? Translations_sv_FI.SearchEndDate : Translations_en_US.SearchEndDate);
		Translations.WhistleBlowStatisticsInProgressRecords = culture === "fi" ? Translations_fi.WhistleBlowStatisticsInProgressRecords : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStatisticsInProgressRecords : Translations_en_US.WhistleBlowStatisticsInProgressRecords);
		Translations.RemoveEventUrgencyClass = culture === "fi" ? Translations_fi.RemoveEventUrgencyClass : (culture === "sv-FI" ? Translations_sv_FI.RemoveEventUrgencyClass : Translations_en_US.RemoveEventUrgencyClass);
		Translations.TaskHandlerParameterNotFound = culture === "fi" ? Translations_fi.TaskHandlerParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.TaskHandlerParameterNotFound : Translations_en_US.TaskHandlerParameterNotFound);
		Translations.PositionErrorUnavailable = culture === "fi" ? Translations_fi.PositionErrorUnavailable : (culture === "sv-FI" ? Translations_sv_FI.PositionErrorUnavailable : Translations_en_US.PositionErrorUnavailable);
		Translations.DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingCompanyGroupParameterWasNotSuccessfulErrorParameter);
		Translations.Unit = culture === "fi" ? Translations_fi.Unit : (culture === "sv-FI" ? Translations_sv_FI.Unit : Translations_en_US.Unit);
		Translations.ChangesMaxAmount = culture === "fi" ? Translations_fi.ChangesMaxAmount : (culture === "sv-FI" ? Translations_sv_FI.ChangesMaxAmount : Translations_en_US.ChangesMaxAmount);
		Translations.NoAddedEventTypeCs = culture === "fi" ? Translations_fi.NoAddedEventTypeCs : (culture === "sv-FI" ? Translations_sv_FI.NoAddedEventTypeCs : Translations_en_US.NoAddedEventTypeCs);
		Translations.NoAddedEventTypeBs = culture === "fi" ? Translations_fi.NoAddedEventTypeBs : (culture === "sv-FI" ? Translations_sv_FI.NoAddedEventTypeBs : Translations_en_US.NoAddedEventTypeBs);
		Translations.CompanyCodeContainsIllegalCharacters = culture === "fi" ? Translations_fi.CompanyCodeContainsIllegalCharacters : (culture === "sv-FI" ? Translations_sv_FI.CompanyCodeContainsIllegalCharacters : Translations_en_US.CompanyCodeContainsIllegalCharacters);
		Translations.EmploymentEndDate = culture === "fi" ? Translations_fi.EmploymentEndDate : (culture === "sv-FI" ? Translations_sv_FI.EmploymentEndDate : Translations_en_US.EmploymentEndDate);
		Translations.WhistleBlowStatisticsNonAnonymousRecords = culture === "fi" ? Translations_fi.WhistleBlowStatisticsNonAnonymousRecords : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStatisticsNonAnonymousRecords : Translations_en_US.WhistleBlowStatisticsNonAnonymousRecords);
		Translations.DoYouReallyWantToRemoveEventTypeCParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveEventTypeCParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveEventTypeCParameter : Translations_en_US.DoYouReallyWantToRemoveEventTypeCParameter);
		Translations.PrintFileName = culture === "fi" ? Translations_fi.PrintFileName : (culture === "sv-FI" ? Translations_sv_FI.PrintFileName : Translations_en_US.PrintFileName);
		Translations.CopyContentToDocumentContent = culture === "fi" ? Translations_fi.CopyContentToDocumentContent : (culture === "sv-FI" ? Translations_sv_FI.CopyContentToDocumentContent : Translations_en_US.CopyContentToDocumentContent);
		Translations.DoYouReallyWantToRemoveTaskPriorityParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveTaskPriorityParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveTaskPriorityParameter : Translations_en_US.DoYouReallyWantToRemoveTaskPriorityParameter);
		Translations.EditableDocument = culture === "fi" ? Translations_fi.EditableDocument : (culture === "sv-FI" ? Translations_sv_FI.EditableDocument : Translations_en_US.EditableDocument);
		Translations.GetMore = culture === "fi" ? Translations_fi.GetMore : (culture === "sv-FI" ? Translations_sv_FI.GetMore : Translations_en_US.GetMore);
		Translations.FieldParameterValueMustBeDefined = culture === "fi" ? Translations_fi.FieldParameterValueMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.FieldParameterValueMustBeDefined : Translations_en_US.FieldParameterValueMustBeDefined);
		Translations.DeletingAttachmentParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingAttachmentParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingAttachmentParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingAttachmentParameterWasNotSuccessfulConcurrencyError);
		Translations.LastParameterYears = culture === "fi" ? Translations_fi.LastParameterYears : (culture === "sv-FI" ? Translations_sv_FI.LastParameterYears : Translations_en_US.LastParameterYears);
		Translations.MyLastModifications = culture === "fi" ? Translations_fi.MyLastModifications : (culture === "sv-FI" ? Translations_sv_FI.MyLastModifications : Translations_en_US.MyLastModifications);
		Translations.DownloadAttachment = culture === "fi" ? Translations_fi.DownloadAttachment : (culture === "sv-FI" ? Translations_sv_FI.DownloadAttachment : Translations_en_US.DownloadAttachment);
		Translations.QualificationIsValid = culture === "fi" ? Translations_fi.QualificationIsValid : (culture === "sv-FI" ? Translations_sv_FI.QualificationIsValid : Translations_en_US.QualificationIsValid);
		Translations.SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingEventUrgencyClassParameterWasNotSuccessfulConcurrencyError);
		Translations.Download = culture === "fi" ? Translations_fi.Download : (culture === "sv-FI" ? Translations_sv_FI.Download : Translations_en_US.Download);
		Translations.DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingEditableDocumentParameterWasNotSuccessfulErrorParameter);
		Translations.WhistleBlowInformantMessages = culture === "fi" ? Translations_fi.WhistleBlowInformantMessages : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowInformantMessages : Translations_en_US.WhistleBlowInformantMessages);
		Translations.TaskStateLocked = culture === "fi" ? Translations_fi.TaskStateLocked : (culture === "sv-FI" ? Translations_sv_FI.TaskStateLocked : Translations_en_US.TaskStateLocked);
		Translations.Usage = culture === "fi" ? Translations_fi.Usage : (culture === "sv-FI" ? Translations_sv_FI.Usage : Translations_en_US.Usage);
		Translations.BasicEducation = culture === "fi" ? Translations_fi.BasicEducation : (culture === "sv-FI" ? Translations_sv_FI.BasicEducation : Translations_en_US.BasicEducation);
		Translations.DeletingUserParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingUserParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingUserParameterWasNotSuccessfulErrorParameter);
		Translations.SavingWhistleBlowCategoryParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingWhistleBlowCategoryParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingWhistleBlowCategoryParameterWasSuccessful : Translations_en_US.SavingWhistleBlowCategoryParameterWasSuccessful);
		Translations.DoYouReallyWantToRemoveFileParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveFileParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveFileParameter : Translations_en_US.DoYouReallyWantToRemoveFileParameter);
		Translations.MaximumSizeOfSingleAttachment = culture === "fi" ? Translations_fi.MaximumSizeOfSingleAttachment : (culture === "sv-FI" ? Translations_sv_FI.MaximumSizeOfSingleAttachment : Translations_en_US.MaximumSizeOfSingleAttachment);
		Translations.MoveToTrashBin = culture === "fi" ? Translations_fi.MoveToTrashBin : (culture === "sv-FI" ? Translations_sv_FI.MoveToTrashBin : Translations_en_US.MoveToTrashBin);
		Translations.InstantMessageSettings = culture === "fi" ? Translations_fi.InstantMessageSettings : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageSettings : Translations_en_US.InstantMessageSettings);
		Translations.ObserverAction = culture === "fi" ? Translations_fi.ObserverAction : (culture === "sv-FI" ? Translations_sv_FI.ObserverAction : Translations_en_US.ObserverAction);
		Translations.AddEventTypesToEvent = culture === "fi" ? Translations_fi.AddEventTypesToEvent : (culture === "sv-FI" ? Translations_sv_FI.AddEventTypesToEvent : Translations_en_US.AddEventTypesToEvent);
		Translations.MessageModuleProperties = culture === "fi" ? Translations_fi.MessageModuleProperties : (culture === "sv-FI" ? Translations_sv_FI.MessageModuleProperties : Translations_en_US.MessageModuleProperties);
		Translations.CreatingPrint = culture === "fi" ? Translations_fi.CreatingPrint : (culture === "sv-FI" ? Translations_sv_FI.CreatingPrint : Translations_en_US.CreatingPrint);
		Translations.EventApprovalWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.EventApprovalWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.EventApprovalWasNotSuccessfulErrorParameter : Translations_en_US.EventApprovalWasNotSuccessfulErrorParameter);
		Translations.ImageChangeInterval = culture === "fi" ? Translations_fi.ImageChangeInterval : (culture === "sv-FI" ? Translations_sv_FI.ImageChangeInterval : Translations_en_US.ImageChangeInterval);
		Translations.RemovePrint = culture === "fi" ? Translations_fi.RemovePrint : (culture === "sv-FI" ? Translations_sv_FI.RemovePrint : Translations_en_US.RemovePrint);
		Translations.DocumentsSelected = culture === "fi" ? Translations_fi.DocumentsSelected : (culture === "sv-FI" ? Translations_sv_FI.DocumentsSelected : Translations_en_US.DocumentsSelected);
		Translations.DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordQualificationsParameterWasNotSuccessfulErrorParameter);
		Translations.AddPartnerParameterWasSuccessful = culture === "fi" ? Translations_fi.AddPartnerParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddPartnerParameterWasSuccessful : Translations_en_US.AddPartnerParameterWasSuccessful);
		Translations.MessageVisibilityPeriodNotStarted = culture === "fi" ? Translations_fi.MessageVisibilityPeriodNotStarted : (culture === "sv-FI" ? Translations_sv_FI.MessageVisibilityPeriodNotStarted : Translations_en_US.MessageVisibilityPeriodNotStarted);
		Translations.DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages = culture === "fi" ? Translations_fi.DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages : (culture === "sv-FI" ? Translations_sv_FI.DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages : Translations_en_US.DeletingMessageTypeParameterWasNotSuccessfulUsedInParameterMessages);
		Translations.RemovePicture = culture === "fi" ? Translations_fi.RemovePicture : (culture === "sv-FI" ? Translations_sv_FI.RemovePicture : Translations_en_US.RemovePicture);
		Translations.RestoringInstantMessageWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.RestoringInstantMessageWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.RestoringInstantMessageWasNotSuccessfulConcurrencyError : Translations_en_US.RestoringInstantMessageWasNotSuccessfulConcurrencyError);
		Translations.ExternalSystemCode = culture === "fi" ? Translations_fi.ExternalSystemCode : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemCode : Translations_en_US.ExternalSystemCode);
		Translations.Closed = culture === "fi" ? Translations_fi.Closed : (culture === "sv-FI" ? Translations_sv_FI.Closed : Translations_en_US.Closed);
		Translations.SelectedDocumentTypes = culture === "fi" ? Translations_fi.SelectedDocumentTypes : (culture === "sv-FI" ? Translations_sv_FI.SelectedDocumentTypes : Translations_en_US.SelectedDocumentTypes);
		Translations.InstantMessageChannelParameterNotFound = culture === "fi" ? Translations_fi.InstantMessageChannelParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelParameterNotFound : Translations_en_US.InstantMessageChannelParameterNotFound);
		Translations.AllEventHandlersHaveReadMessage = culture === "fi" ? Translations_fi.AllEventHandlersHaveReadMessage : (culture === "sv-FI" ? Translations_sv_FI.AllEventHandlersHaveReadMessage : Translations_en_US.AllEventHandlersHaveReadMessage);
		Translations.StateOpenPlural = culture === "fi" ? Translations_fi.StateOpenPlural : (culture === "sv-FI" ? Translations_sv_FI.StateOpenPlural : Translations_en_US.StateOpenPlural);
		Translations.ValidityStartDate = culture === "fi" ? Translations_fi.ValidityStartDate : (culture === "sv-FI" ? Translations_sv_FI.ValidityStartDate : Translations_en_US.ValidityStartDate);
		Translations.SavingDirectoryItemPermissionsNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingDirectoryItemPermissionsNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemPermissionsNotSuccessfulErrorParameter : Translations_en_US.SavingDirectoryItemPermissionsNotSuccessfulErrorParameter);
		Translations.SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingRecordEmploymentParameterWasNotSuccessfulErrorParameter);
		Translations.SelectCategory = culture === "fi" ? Translations_fi.SelectCategory : (culture === "sv-FI" ? Translations_sv_FI.SelectCategory : Translations_en_US.SelectCategory);
		Translations.ModifiedBy = culture === "fi" ? Translations_fi.ModifiedBy : (culture === "sv-FI" ? Translations_sv_FI.ModifiedBy : Translations_en_US.ModifiedBy);
		Translations.Expired = culture === "fi" ? Translations_fi.Expired : (culture === "sv-FI" ? Translations_sv_FI.Expired : Translations_en_US.Expired);
		Translations.ViewLogLine = culture === "fi" ? Translations_fi.ViewLogLine : (culture === "sv-FI" ? Translations_sv_FI.ViewLogLine : Translations_en_US.ViewLogLine);
		Translations.DoYouReallyWantToEmailMessageParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToEmailMessageParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToEmailMessageParameter : Translations_en_US.DoYouReallyWantToEmailMessageParameter);
		Translations.SelectEventTypeC = culture === "fi" ? Translations_fi.SelectEventTypeC : (culture === "sv-FI" ? Translations_sv_FI.SelectEventTypeC : Translations_en_US.SelectEventTypeC);
		Translations.FolderNameConstainsIllegalCharacters = culture === "fi" ? Translations_fi.FolderNameConstainsIllegalCharacters : (culture === "sv-FI" ? Translations_sv_FI.FolderNameConstainsIllegalCharacters : Translations_en_US.FolderNameConstainsIllegalCharacters);
		Translations.AllCompanies = culture === "fi" ? Translations_fi.AllCompanies : (culture === "sv-FI" ? Translations_sv_FI.AllCompanies : Translations_en_US.AllCompanies);
		Translations.Today = culture === "fi" ? Translations_fi.Today : (culture === "sv-FI" ? Translations_sv_FI.Today : Translations_en_US.Today);
		Translations.PrintOrientation = culture === "fi" ? Translations_fi.PrintOrientation : (culture === "sv-FI" ? Translations_sv_FI.PrintOrientation : Translations_en_US.PrintOrientation);
		Translations.RecordBasicEducationCodeMustBeDefined = culture === "fi" ? Translations_fi.RecordBasicEducationCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.RecordBasicEducationCodeMustBeDefined : Translations_en_US.RecordBasicEducationCodeMustBeDefined);
		Translations.SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingDirectoryItemDocumentParameterWasNotSuccessfulConcurrencyError);
		Translations.DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingInstantMessageUsersParameterWasNotSuccessfulConcurrencyError);
		Translations.ApplicationTempPathIsNotSpecified = culture === "fi" ? Translations_fi.ApplicationTempPathIsNotSpecified : (culture === "sv-FI" ? Translations_sv_FI.ApplicationTempPathIsNotSpecified : Translations_en_US.ApplicationTempPathIsNotSpecified);
		Translations.DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingEventTypeBParameterWasNotSuccessfulConcurrencyError);
		Translations.OpenWhistleBlowInstruction = culture === "fi" ? Translations_fi.OpenWhistleBlowInstruction : (culture === "sv-FI" ? Translations_sv_FI.OpenWhistleBlowInstruction : Translations_en_US.OpenWhistleBlowInstruction);
		Translations.FolderCannotBeMovedToItsSubFolder = culture === "fi" ? Translations_fi.FolderCannotBeMovedToItsSubFolder : (culture === "sv-FI" ? Translations_sv_FI.FolderCannotBeMovedToItsSubFolder : Translations_en_US.FolderCannotBeMovedToItsSubFolder);
		Translations.DefaultTaskTypes = culture === "fi" ? Translations_fi.DefaultTaskTypes : (culture === "sv-FI" ? Translations_sv_FI.DefaultTaskTypes : Translations_en_US.DefaultTaskTypes);
		Translations.SaveDraft = culture === "fi" ? Translations_fi.SaveDraft : (culture === "sv-FI" ? Translations_sv_FI.SaveDraft : Translations_en_US.SaveDraft);
		Translations.DocumentModulePropertiesSaveWasSuccessful = culture === "fi" ? Translations_fi.DocumentModulePropertiesSaveWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DocumentModulePropertiesSaveWasSuccessful : Translations_en_US.DocumentModulePropertiesSaveWasSuccessful);
		Translations.WhistleBlowReportHasBeenSavedBottomText = culture === "fi" ? Translations_fi.WhistleBlowReportHasBeenSavedBottomText : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowReportHasBeenSavedBottomText : Translations_en_US.WhistleBlowReportHasBeenSavedBottomText);
		Translations.AddedComment = culture === "fi" ? Translations_fi.AddedComment : (culture === "sv-FI" ? Translations_sv_FI.AddedComment : Translations_en_US.AddedComment);
		Translations.AddFurtherEducationToMultipleUserRecords = culture === "fi" ? Translations_fi.AddFurtherEducationToMultipleUserRecords : (culture === "sv-FI" ? Translations_sv_FI.AddFurtherEducationToMultipleUserRecords : Translations_en_US.AddFurtherEducationToMultipleUserRecords);
		Translations.WhistleBlowSourceMail = culture === "fi" ? Translations_fi.WhistleBlowSourceMail : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowSourceMail : Translations_en_US.WhistleBlowSourceMail);
		Translations.SavingUserParametersWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingUserParametersWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingUserParametersWasNotSuccessfulConcurrencyError : Translations_en_US.SavingUserParametersWasNotSuccessfulConcurrencyError);
		Translations.PositionErrorPermissionDenied = culture === "fi" ? Translations_fi.PositionErrorPermissionDenied : (culture === "sv-FI" ? Translations_sv_FI.PositionErrorPermissionDenied : Translations_en_US.PositionErrorPermissionDenied);
		Translations.CkickToChangeTheIcon = culture === "fi" ? Translations_fi.CkickToChangeTheIcon : (culture === "sv-FI" ? Translations_sv_FI.CkickToChangeTheIcon : Translations_en_US.CkickToChangeTheIcon);
		Translations.InstantMessageChannelArchivingTypeKeepMessages = culture === "fi" ? Translations_fi.InstantMessageChannelArchivingTypeKeepMessages : (culture === "sv-FI" ? Translations_sv_FI.InstantMessageChannelArchivingTypeKeepMessages : Translations_en_US.InstantMessageChannelArchivingTypeKeepMessages);
		Translations.CopyingFilesAndDocumentsToFolderParameterWasSuccessful = culture === "fi" ? Translations_fi.CopyingFilesAndDocumentsToFolderParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.CopyingFilesAndDocumentsToFolderParameterWasSuccessful : Translations_en_US.CopyingFilesAndDocumentsToFolderParameterWasSuccessful);
		Translations.AddPartnersParameterWasSuccessful = culture === "fi" ? Translations_fi.AddPartnersParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddPartnersParameterWasSuccessful : Translations_en_US.AddPartnersParameterWasSuccessful);
		Translations.NoModifications = culture === "fi" ? Translations_fi.NoModifications : (culture === "sv-FI" ? Translations_sv_FI.NoModifications : Translations_en_US.NoModifications);
		Translations.NormalMessage = culture === "fi" ? Translations_fi.NormalMessage : (culture === "sv-FI" ? Translations_sv_FI.NormalMessage : Translations_en_US.NormalMessage);
		Translations.PermissionsToCreateEventsToEventPartner = culture === "fi" ? Translations_fi.PermissionsToCreateEventsToEventPartner : (culture === "sv-FI" ? Translations_sv_FI.PermissionsToCreateEventsToEventPartner : Translations_en_US.PermissionsToCreateEventsToEventPartner);
		Translations.AddEvent = culture === "fi" ? Translations_fi.AddEvent : (culture === "sv-FI" ? Translations_sv_FI.AddEvent : Translations_en_US.AddEvent);
		Translations.TrainingEndDateCannotPrecedeTrainingStartDate = culture === "fi" ? Translations_fi.TrainingEndDateCannotPrecedeTrainingStartDate : (culture === "sv-FI" ? Translations_sv_FI.TrainingEndDateCannotPrecedeTrainingStartDate : Translations_en_US.TrainingEndDateCannotPrecedeTrainingStartDate);
		Translations.UserAccountNotConnected = culture === "fi" ? Translations_fi.UserAccountNotConnected : (culture === "sv-FI" ? Translations_sv_FI.UserAccountNotConnected : Translations_en_US.UserAccountNotConnected);
		Translations.PrintIsEmpty = culture === "fi" ? Translations_fi.PrintIsEmpty : (culture === "sv-FI" ? Translations_sv_FI.PrintIsEmpty : Translations_en_US.PrintIsEmpty);
		Translations.RemoveLogisticVoucherCustomerPaymentSetting = culture === "fi" ? Translations_fi.RemoveLogisticVoucherCustomerPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.RemoveLogisticVoucherCustomerPaymentSetting : Translations_en_US.RemoveLogisticVoucherCustomerPaymentSetting);
		Translations.WhistleBlowStateArchived = culture === "fi" ? Translations_fi.WhistleBlowStateArchived : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStateArchived : Translations_en_US.WhistleBlowStateArchived);
		Translations.ShowUserGroups = culture === "fi" ? Translations_fi.ShowUserGroups : (culture === "sv-FI" ? Translations_sv_FI.ShowUserGroups : Translations_en_US.ShowUserGroups);
		Translations.UserLastNameMustBeDefined = culture === "fi" ? Translations_fi.UserLastNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.UserLastNameMustBeDefined : Translations_en_US.UserLastNameMustBeDefined);
		Translations.SearchStartDate = culture === "fi" ? Translations_fi.SearchStartDate : (culture === "sv-FI" ? Translations_sv_FI.SearchStartDate : Translations_en_US.SearchStartDate);
		Translations.RemoveLogisticVoucherCompanyPaymentSetting = culture === "fi" ? Translations_fi.RemoveLogisticVoucherCompanyPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.RemoveLogisticVoucherCompanyPaymentSetting : Translations_en_US.RemoveLogisticVoucherCompanyPaymentSetting);
		Translations.FilterIsActive = culture === "fi" ? Translations_fi.FilterIsActive : (culture === "sv-FI" ? Translations_sv_FI.FilterIsActive : Translations_en_US.FilterIsActive);
		Translations.ExternalSystemUserCodeClaimNotFound = culture === "fi" ? Translations_fi.ExternalSystemUserCodeClaimNotFound : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemUserCodeClaimNotFound : Translations_en_US.ExternalSystemUserCodeClaimNotFound);
		Translations.AddPhoto = culture === "fi" ? Translations_fi.AddPhoto : (culture === "sv-FI" ? Translations_sv_FI.AddPhoto : Translations_en_US.AddPhoto);
		Translations.AddSalaryRowToMultipleUserRecords = culture === "fi" ? Translations_fi.AddSalaryRowToMultipleUserRecords : (culture === "sv-FI" ? Translations_sv_FI.AddSalaryRowToMultipleUserRecords : Translations_en_US.AddSalaryRowToMultipleUserRecords);
		Translations.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingWhistleBlowChannelAdminsParameterWasNotSuccessfulErrorParameter);
		Translations.AddingRecordUserSalaryRowsWasSuccessful = culture === "fi" ? Translations_fi.AddingRecordUserSalaryRowsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddingRecordUserSalaryRowsWasSuccessful : Translations_en_US.AddingRecordUserSalaryRowsWasSuccessful);
		Translations.Voluntary = culture === "fi" ? Translations_fi.Voluntary : (culture === "sv-FI" ? Translations_sv_FI.Voluntary : Translations_en_US.Voluntary);
		Translations.FolderParameterAlreadyExistsInFileSystem = culture === "fi" ? Translations_fi.FolderParameterAlreadyExistsInFileSystem : (culture === "sv-FI" ? Translations_sv_FI.FolderParameterAlreadyExistsInFileSystem : Translations_en_US.FolderParameterAlreadyExistsInFileSystem);
		Translations.ValidityEndDate = culture === "fi" ? Translations_fi.ValidityEndDate : (culture === "sv-FI" ? Translations_sv_FI.ValidityEndDate : Translations_en_US.ValidityEndDate);
		Translations.Partners = culture === "fi" ? Translations_fi.Partners : (culture === "sv-FI" ? Translations_sv_FI.Partners : Translations_en_US.Partners);
		Translations.Characters = culture === "fi" ? Translations_fi.Characters : (culture === "sv-FI" ? Translations_sv_FI.Characters : Translations_en_US.Characters);
		Translations.AddRecordUserToUserAccount = culture === "fi" ? Translations_fi.AddRecordUserToUserAccount : (culture === "sv-FI" ? Translations_sv_FI.AddRecordUserToUserAccount : Translations_en_US.AddRecordUserToUserAccount);
		Translations.EmptyDocument = culture === "fi" ? Translations_fi.EmptyDocument : (culture === "sv-FI" ? Translations_sv_FI.EmptyDocument : Translations_en_US.EmptyDocument);
		Translations.TimeSpanMonth = culture === "fi" ? Translations_fi.TimeSpanMonth : (culture === "sv-FI" ? Translations_sv_FI.TimeSpanMonth : Translations_en_US.TimeSpanMonth);
		Translations.AddSharedDocumentTemplate = culture === "fi" ? Translations_fi.AddSharedDocumentTemplate : (culture === "sv-FI" ? Translations_sv_FI.AddSharedDocumentTemplate : Translations_en_US.AddSharedDocumentTemplate);
		Translations.AddMessage = culture === "fi" ? Translations_fi.AddMessage : (culture === "sv-FI" ? Translations_sv_FI.AddMessage : Translations_en_US.AddMessage);
		Translations.RemoveTask = culture === "fi" ? Translations_fi.RemoveTask : (culture === "sv-FI" ? Translations_sv_FI.RemoveTask : Translations_en_US.RemoveTask);
		Translations.EditInstantMessageChannel = culture === "fi" ? Translations_fi.EditInstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.EditInstantMessageChannel : Translations_en_US.EditInstantMessageChannel);
		Translations.SavingEventParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingEventParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingEventParameterWasSuccessful : Translations_en_US.SavingEventParameterWasSuccessful);
		Translations.AddUserGroupParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.AddUserGroupParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.AddUserGroupParameterWasNotSuccessfulConcurrencyError : Translations_en_US.AddUserGroupParameterWasNotSuccessfulConcurrencyError);
		Translations.UserRecordStatisticsExpiredFamiliarizations = culture === "fi" ? Translations_fi.UserRecordStatisticsExpiredFamiliarizations : (culture === "sv-FI" ? Translations_sv_FI.UserRecordStatisticsExpiredFamiliarizations : Translations_en_US.UserRecordStatisticsExpiredFamiliarizations);
		Translations.ValidityEndDateEnd = culture === "fi" ? Translations_fi.ValidityEndDateEnd : (culture === "sv-FI" ? Translations_sv_FI.ValidityEndDateEnd : Translations_en_US.ValidityEndDateEnd);
		Translations.SavingCompanyGroupParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingCompanyGroupParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingCompanyGroupParameterWasNotSuccessfulErrorParameter : Translations_en_US.SavingCompanyGroupParameterWasNotSuccessfulErrorParameter);
		Translations.TasksExcelByTasks = culture === "fi" ? Translations_fi.TasksExcelByTasks : (culture === "sv-FI" ? Translations_sv_FI.TasksExcelByTasks : Translations_en_US.TasksExcelByTasks);
		Translations.FilesParameterAreEmpty = culture === "fi" ? Translations_fi.FilesParameterAreEmpty : (culture === "sv-FI" ? Translations_sv_FI.FilesParameterAreEmpty : Translations_en_US.FilesParameterAreEmpty);
		Translations.EventTypeBs = culture === "fi" ? Translations_fi.EventTypeBs : (culture === "sv-FI" ? Translations_sv_FI.EventTypeBs : Translations_en_US.EventTypeBs);
		Translations.FolderParameterAlreadyExistsInTargetFolder = culture === "fi" ? Translations_fi.FolderParameterAlreadyExistsInTargetFolder : (culture === "sv-FI" ? Translations_sv_FI.FolderParameterAlreadyExistsInTargetFolder : Translations_en_US.FolderParameterAlreadyExistsInTargetFolder);
		Translations.LogoSquare = culture === "fi" ? Translations_fi.LogoSquare : (culture === "sv-FI" ? Translations_sv_FI.LogoSquare : Translations_en_US.LogoSquare);
		Translations.DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordBasicEducationParameterWasNotSuccessfulConcurrencyError);
		Translations.SearchEvents = culture === "fi" ? Translations_fi.SearchEvents : (culture === "sv-FI" ? Translations_sv_FI.SearchEvents : Translations_en_US.SearchEvents);
		Translations.WhistleBlowArchivingTypeChangeStateAndRemoveInformantData = culture === "fi" ? Translations_fi.WhistleBlowArchivingTypeChangeStateAndRemoveInformantData : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowArchivingTypeChangeStateAndRemoveInformantData : Translations_en_US.WhistleBlowArchivingTypeChangeStateAndRemoveInformantData);
		Translations.DoYouReallyWantToRemoveReportParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveReportParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveReportParameter : Translations_en_US.DoYouReallyWantToRemoveReportParameter);
		Translations.Admin = culture === "fi" ? Translations_fi.Admin : (culture === "sv-FI" ? Translations_sv_FI.Admin : Translations_en_US.Admin);
		Translations.TaskRemainderParameterNotFound = culture === "fi" ? Translations_fi.TaskRemainderParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.TaskRemainderParameterNotFound : Translations_en_US.TaskRemainderParameterNotFound);
		Translations.Invoicing = culture === "fi" ? Translations_fi.Invoicing : (culture === "sv-FI" ? Translations_sv_FI.Invoicing : Translations_en_US.Invoicing);
		Translations.AddCompanyGroup = culture === "fi" ? Translations_fi.AddCompanyGroup : (culture === "sv-FI" ? Translations_sv_FI.AddCompanyGroup : Translations_en_US.AddCompanyGroup);
		Translations.WebPushError = culture === "fi" ? Translations_fi.WebPushError : (culture === "sv-FI" ? Translations_sv_FI.WebPushError : Translations_en_US.WebPushError);
		Translations.TrainingStartDateStart = culture === "fi" ? Translations_fi.TrainingStartDateStart : (culture === "sv-FI" ? Translations_sv_FI.TrainingStartDateStart : Translations_en_US.TrainingStartDateStart);
		Translations.Action = culture === "fi" ? Translations_fi.Action : (culture === "sv-FI" ? Translations_sv_FI.Action : Translations_en_US.Action);
		Translations.UserCodeMustBeValid = culture === "fi" ? Translations_fi.UserCodeMustBeValid : (culture === "sv-FI" ? Translations_sv_FI.UserCodeMustBeValid : Translations_en_US.UserCodeMustBeValid);
		Translations.UserCannotRecieveEmailNotifications = culture === "fi" ? Translations_fi.UserCannotRecieveEmailNotifications : (culture === "sv-FI" ? Translations_sv_FI.UserCannotRecieveEmailNotifications : Translations_en_US.UserCannotRecieveEmailNotifications);
		Translations.LogisticVoucherCustomers = culture === "fi" ? Translations_fi.LogisticVoucherCustomers : (culture === "sv-FI" ? Translations_sv_FI.LogisticVoucherCustomers : Translations_en_US.LogisticVoucherCustomers);
		Translations.DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingRecordBasicEducationParameterWasNotSuccessfulErrorParameter);
		Translations.InstantMessages = culture === "fi" ? Translations_fi.InstantMessages : (culture === "sv-FI" ? Translations_sv_FI.InstantMessages : Translations_en_US.InstantMessages);
		Translations.DeletingRecordUserEmploymentParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingRecordUserEmploymentParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordUserEmploymentParameterWasSuccessful : Translations_en_US.DeletingRecordUserEmploymentParameterWasSuccessful);
		Translations.LogIn = culture === "fi" ? Translations_fi.LogIn : (culture === "sv-FI" ? Translations_sv_FI.LogIn : Translations_en_US.LogIn);
		Translations.EventMessageConfirmation = culture === "fi" ? Translations_fi.EventMessageConfirmation : (culture === "sv-FI" ? Translations_sv_FI.EventMessageConfirmation : Translations_en_US.EventMessageConfirmation);
		Translations.SavingMessageSendEmailParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingMessageSendEmailParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageSendEmailParameterWasSuccessful : Translations_en_US.SavingMessageSendEmailParameterWasSuccessful);
		Translations.EditKeyword = culture === "fi" ? Translations_fi.EditKeyword : (culture === "sv-FI" ? Translations_sv_FI.EditKeyword : Translations_en_US.EditKeyword);
		Translations.DeletingUserGroupParameterWasSuccessful = culture === "fi" ? Translations_fi.DeletingUserGroupParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingUserGroupParameterWasSuccessful : Translations_en_US.DeletingUserGroupParameterWasSuccessful);
		Translations.SaveFilter = culture === "fi" ? Translations_fi.SaveFilter : (culture === "sv-FI" ? Translations_sv_FI.SaveFilter : Translations_en_US.SaveFilter);
		Translations.EditRecordUser = culture === "fi" ? Translations_fi.EditRecordUser : (culture === "sv-FI" ? Translations_sv_FI.EditRecordUser : Translations_en_US.EditRecordUser);
		Translations.DeletingFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.DeletingFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.DeletingFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.DeletingFolderParameterWasNotSuccessfulErrorParameter);
		Translations.AttachmentImageSize = culture === "fi" ? Translations_fi.AttachmentImageSize : (culture === "sv-FI" ? Translations_sv_FI.AttachmentImageSize : Translations_en_US.AttachmentImageSize);
		Translations.UpdateCompanyExternalSystemsWasSuccessful = culture === "fi" ? Translations_fi.UpdateCompanyExternalSystemsWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.UpdateCompanyExternalSystemsWasSuccessful : Translations_en_US.UpdateCompanyExternalSystemsWasSuccessful);
		Translations.FileUploadWasSuccessful = culture === "fi" ? Translations_fi.FileUploadWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.FileUploadWasSuccessful : Translations_en_US.FileUploadWasSuccessful);
		Translations.Hours = culture === "fi" ? Translations_fi.Hours : (culture === "sv-FI" ? Translations_sv_FI.Hours : Translations_en_US.Hours);
		Translations.EditDefaultTaskState = culture === "fi" ? Translations_fi.EditDefaultTaskState : (culture === "sv-FI" ? Translations_sv_FI.EditDefaultTaskState : Translations_en_US.EditDefaultTaskState);
		Translations.DoYouReallyWantToRemoveRecordFamiliarizationParameter = culture === "fi" ? Translations_fi.DoYouReallyWantToRemoveRecordFamiliarizationParameter : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToRemoveRecordFamiliarizationParameter : Translations_en_US.DoYouReallyWantToRemoveRecordFamiliarizationParameter);
		Translations.Invited = culture === "fi" ? Translations_fi.Invited : (culture === "sv-FI" ? Translations_sv_FI.Invited : Translations_en_US.Invited);
		Translations.ThisWeek = culture === "fi" ? Translations_fi.ThisWeek : (culture === "sv-FI" ? Translations_sv_FI.ThisWeek : Translations_en_US.ThisWeek);
		Translations.KeywordCodeMustBeDefined = culture === "fi" ? Translations_fi.KeywordCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.KeywordCodeMustBeDefined : Translations_en_US.KeywordCodeMustBeDefined);
		Translations.RenewPersonalization = culture === "fi" ? Translations_fi.RenewPersonalization : (culture === "sv-FI" ? Translations_sv_FI.RenewPersonalization : Translations_en_US.RenewPersonalization);
		Translations.DeletingInstantMessageChannelWasSuccessful = culture === "fi" ? Translations_fi.DeletingInstantMessageChannelWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.DeletingInstantMessageChannelWasSuccessful : Translations_en_US.DeletingInstantMessageChannelWasSuccessful);
		Translations.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError : Translations_en_US.CopyingFolderDirectoryItemParameterToFolderParameterWasNotSuccessfulConcurrencyError);
		Translations.SharedDocumentTemplates = culture === "fi" ? Translations_fi.SharedDocumentTemplates : (culture === "sv-FI" ? Translations_sv_FI.SharedDocumentTemplates : Translations_en_US.SharedDocumentTemplates);
		Translations.SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter : Translations_en_US.SavingEditableDocumentParameterPublishWasNotSuccessfulErrorParameter);
		Translations.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError : Translations_en_US.SavingRecordUserTransferredPropertyParameterWasNotSuccessfulConcurrencyError);
		Translations.Reject = culture === "fi" ? Translations_fi.Reject : (culture === "sv-FI" ? Translations_sv_FI.Reject : Translations_en_US.Reject);
		Translations.UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted = culture === "fi" ? Translations_fi.UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted : Translations_en_US.UserGroupParameterIsInUseInParameterUsersAndCannotBeDeleted);
		Translations.TaskParameterNotFound = culture === "fi" ? Translations_fi.TaskParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.TaskParameterNotFound : Translations_en_US.TaskParameterNotFound);
		Translations.PasswordMustContainDigitOrSpecialCharacter = culture === "fi" ? Translations_fi.PasswordMustContainDigitOrSpecialCharacter : (culture === "sv-FI" ? Translations_sv_FI.PasswordMustContainDigitOrSpecialCharacter : Translations_en_US.PasswordMustContainDigitOrSpecialCharacter);
		Translations.ExternalIntraSystemCompanyTokenMustBeDefined = culture === "fi" ? Translations_fi.ExternalIntraSystemCompanyTokenMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.ExternalIntraSystemCompanyTokenMustBeDefined : Translations_en_US.ExternalIntraSystemCompanyTokenMustBeDefined);
		Translations.StudyWeek = culture === "fi" ? Translations_fi.StudyWeek : (culture === "sv-FI" ? Translations_sv_FI.StudyWeek : Translations_en_US.StudyWeek);
		Translations.Removed = culture === "fi" ? Translations_fi.Removed : (culture === "sv-FI" ? Translations_sv_FI.Removed : Translations_en_US.Removed);
		Translations.Unread = culture === "fi" ? Translations_fi.Unread : (culture === "sv-FI" ? Translations_sv_FI.Unread : Translations_en_US.Unread);
		Translations.HoursPerTrainingDay = culture === "fi" ? Translations_fi.HoursPerTrainingDay : (culture === "sv-FI" ? Translations_sv_FI.HoursPerTrainingDay : Translations_en_US.HoursPerTrainingDay);
		Translations.DirectoryItemDocumentParameterNotFound = culture === "fi" ? Translations_fi.DirectoryItemDocumentParameterNotFound : (culture === "sv-FI" ? Translations_sv_FI.DirectoryItemDocumentParameterNotFound : Translations_en_US.DirectoryItemDocumentParameterNotFound);
		Translations.LogisticVoucher = culture === "fi" ? Translations_fi.LogisticVoucher : (culture === "sv-FI" ? Translations_sv_FI.LogisticVoucher : Translations_en_US.LogisticVoucher);
		Translations.PasteDefaultHandlers = culture === "fi" ? Translations_fi.PasteDefaultHandlers : (culture === "sv-FI" ? Translations_sv_FI.PasteDefaultHandlers : Translations_en_US.PasteDefaultHandlers);
		Translations.WhistleBlowResolutionCanceled = culture === "fi" ? Translations_fi.WhistleBlowResolutionCanceled : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowResolutionCanceled : Translations_en_US.WhistleBlowResolutionCanceled);
		Translations.NewMenu = culture === "fi" ? Translations_fi.NewMenu : (culture === "sv-FI" ? Translations_sv_FI.NewMenu : Translations_en_US.NewMenu);
		Translations.Move = culture === "fi" ? Translations_fi.Move : (culture === "sv-FI" ? Translations_sv_FI.Move : Translations_en_US.Move);
		Translations.TaskPriority = culture === "fi" ? Translations_fi.TaskPriority : (culture === "sv-FI" ? Translations_sv_FI.TaskPriority : Translations_en_US.TaskPriority);
		Translations.AddUserGroupParameterWasSuccessful = culture === "fi" ? Translations_fi.AddUserGroupParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.AddUserGroupParameterWasSuccessful : Translations_en_US.AddUserGroupParameterWasSuccessful);
		Translations.CompanyThatIsInUseCannotBeDeleted = culture === "fi" ? Translations_fi.CompanyThatIsInUseCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.CompanyThatIsInUseCannotBeDeleted : Translations_en_US.CompanyThatIsInUseCannotBeDeleted);
		Translations.SaveEventHandlerConfirmation = culture === "fi" ? Translations_fi.SaveEventHandlerConfirmation : (culture === "sv-FI" ? Translations_sv_FI.SaveEventHandlerConfirmation : Translations_en_US.SaveEventHandlerConfirmation);
		Translations.WebPageStyle = culture === "fi" ? Translations_fi.WebPageStyle : (culture === "sv-FI" ? Translations_sv_FI.WebPageStyle : Translations_en_US.WebPageStyle);
		Translations.Last120Days = culture === "fi" ? Translations_fi.Last120Days : (culture === "sv-FI" ? Translations_sv_FI.Last120Days : Translations_en_US.Last120Days);
		Translations.SavingDefaultHandlersWasSuccessful = culture === "fi" ? Translations_fi.SavingDefaultHandlersWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingDefaultHandlersWasSuccessful : Translations_en_US.SavingDefaultHandlersWasSuccessful);
		Translations.ExternalSystemCompanyCodeMustBeDefined = culture === "fi" ? Translations_fi.ExternalSystemCompanyCodeMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.ExternalSystemCompanyCodeMustBeDefined : Translations_en_US.ExternalSystemCompanyCodeMustBeDefined);
		Translations.DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingDefaultEventHandlerParameterWasNotSuccessfulConcurrencyError);
		Translations.EditLogisticVoucherCustomerPaymentSetting = culture === "fi" ? Translations_fi.EditLogisticVoucherCustomerPaymentSetting : (culture === "sv-FI" ? Translations_sv_FI.EditLogisticVoucherCustomerPaymentSetting : Translations_en_US.EditLogisticVoucherCustomerPaymentSetting);
		Translations.EditRow = culture === "fi" ? Translations_fi.EditRow : (culture === "sv-FI" ? Translations_sv_FI.EditRow : Translations_en_US.EditRow);
		Translations.PanelSize3 = culture === "fi" ? Translations_fi.PanelSize3 : (culture === "sv-FI" ? Translations_sv_FI.PanelSize3 : Translations_en_US.PanelSize3);
		Translations.UserSessionHasBeenExpired = culture === "fi" ? Translations_fi.UserSessionHasBeenExpired : (culture === "sv-FI" ? Translations_sv_FI.UserSessionHasBeenExpired : Translations_en_US.UserSessionHasBeenExpired);
		Translations.LogEventType = culture === "fi" ? Translations_fi.LogEventType : (culture === "sv-FI" ? Translations_sv_FI.LogEventType : Translations_en_US.LogEventType);
		Translations.SavingOptionParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingOptionParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingOptionParameterWasSuccessful : Translations_en_US.SavingOptionParameterWasSuccessful);
		Translations.MovingFolderParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.MovingFolderParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.MovingFolderParameterWasNotSuccessfulErrorParameter : Translations_en_US.MovingFolderParameterWasNotSuccessfulErrorParameter);
		Translations.SavingReadReceiptWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.SavingReadReceiptWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.SavingReadReceiptWasNotSuccessfulErrorParameter : Translations_en_US.SavingReadReceiptWasNotSuccessfulErrorParameter);
		Translations.DoYouReallyWantToExitInstantMessageChannel = culture === "fi" ? Translations_fi.DoYouReallyWantToExitInstantMessageChannel : (culture === "sv-FI" ? Translations_sv_FI.DoYouReallyWantToExitInstantMessageChannel : Translations_en_US.DoYouReallyWantToExitInstantMessageChannel);
		Translations.GroupByEducation = culture === "fi" ? Translations_fi.GroupByEducation : (culture === "sv-FI" ? Translations_sv_FI.GroupByEducation : Translations_en_US.GroupByEducation);
		Translations.UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError : Translations_en_US.UpdateCompanyExternalSystemsWasNotSuccessfulConcurrencyError);
		Translations.Source = culture === "fi" ? Translations_fi.Source : (culture === "sv-FI" ? Translations_sv_FI.Source : Translations_en_US.Source);
		Translations.TaskType = culture === "fi" ? Translations_fi.TaskType : (culture === "sv-FI" ? Translations_sv_FI.TaskType : Translations_en_US.TaskType);
		Translations.EventTypeB = culture === "fi" ? Translations_fi.EventTypeB : (culture === "sv-FI" ? Translations_sv_FI.EventTypeB : Translations_en_US.EventTypeB);
		Translations.ReadingDatabaseParameter = culture === "fi" ? Translations_fi.ReadingDatabaseParameter : (culture === "sv-FI" ? Translations_sv_FI.ReadingDatabaseParameter : Translations_en_US.ReadingDatabaseParameter);
		Translations.AddFileFilter = culture === "fi" ? Translations_fi.AddFileFilter : (culture === "sv-FI" ? Translations_sv_FI.AddFileFilter : Translations_en_US.AddFileFilter);
		Translations.AddEventUrgencyClass = culture === "fi" ? Translations_fi.AddEventUrgencyClass : (culture === "sv-FI" ? Translations_sv_FI.AddEventUrgencyClass : Translations_en_US.AddEventUrgencyClass);
		Translations.AddUserParameterWasNotSuccessfulErrorParameter = culture === "fi" ? Translations_fi.AddUserParameterWasNotSuccessfulErrorParameter : (culture === "sv-FI" ? Translations_sv_FI.AddUserParameterWasNotSuccessfulErrorParameter : Translations_en_US.AddUserParameterWasNotSuccessfulErrorParameter);
		Translations.Filters = culture === "fi" ? Translations_fi.Filters : (culture === "sv-FI" ? Translations_sv_FI.Filters : Translations_en_US.Filters);
		Translations.TransferToChannel = culture === "fi" ? Translations_fi.TransferToChannel : (culture === "sv-FI" ? Translations_sv_FI.TransferToChannel : Translations_en_US.TransferToChannel);
		Translations.DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError : Translations_en_US.DeletingRecordFurtherEducationParameterWasNotSuccessfulConcurrencyError);
		Translations.WhistleBlowStatisticsRecordCount = culture === "fi" ? Translations_fi.WhistleBlowStatisticsRecordCount : (culture === "sv-FI" ? Translations_sv_FI.WhistleBlowStatisticsRecordCount : Translations_en_US.WhistleBlowStatisticsRecordCount);
		Translations.Choose = culture === "fi" ? Translations_fi.Choose : (culture === "sv-FI" ? Translations_sv_FI.Choose : Translations_en_US.Choose);
		Translations.RemoveCompany = culture === "fi" ? Translations_fi.RemoveCompany : (culture === "sv-FI" ? Translations_sv_FI.RemoveCompany : Translations_en_US.RemoveCompany);
		Translations.SavingRecordFamiliarizationParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingRecordFamiliarizationParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingRecordFamiliarizationParameterWasSuccessful : Translations_en_US.SavingRecordFamiliarizationParameterWasSuccessful);
		Translations.UserFilterNoUserGroup = culture === "fi" ? Translations_fi.UserFilterNoUserGroup : (culture === "sv-FI" ? Translations_sv_FI.UserFilterNoUserGroup : Translations_en_US.UserFilterNoUserGroup);
		Translations.SavingMessageSendPushParameterWasSuccessful = culture === "fi" ? Translations_fi.SavingMessageSendPushParameterWasSuccessful : (culture === "sv-FI" ? Translations_sv_FI.SavingMessageSendPushParameterWasSuccessful : Translations_en_US.SavingMessageSendPushParameterWasSuccessful);
		Translations.UserIsWhistleBlowChannelAdminAndCannotBeDeleted = culture === "fi" ? Translations_fi.UserIsWhistleBlowChannelAdminAndCannotBeDeleted : (culture === "sv-FI" ? Translations_sv_FI.UserIsWhistleBlowChannelAdminAndCannotBeDeleted : Translations_en_US.UserIsWhistleBlowChannelAdminAndCannotBeDeleted);
		Translations.TrainingStartDate = culture === "fi" ? Translations_fi.TrainingStartDate : (culture === "sv-FI" ? Translations_sv_FI.TrainingStartDate : Translations_en_US.TrainingStartDate);
		Translations.EmptyTaskTrashBin = culture === "fi" ? Translations_fi.EmptyTaskTrashBin : (culture === "sv-FI" ? Translations_sv_FI.EmptyTaskTrashBin : Translations_en_US.EmptyTaskTrashBin);
		Translations.Familiarizations = culture === "fi" ? Translations_fi.Familiarizations : (culture === "sv-FI" ? Translations_sv_FI.Familiarizations : Translations_en_US.Familiarizations);
		Translations.SavingReportWasNotSuccessfulConcurrencyError = culture === "fi" ? Translations_fi.SavingReportWasNotSuccessfulConcurrencyError : (culture === "sv-FI" ? Translations_sv_FI.SavingReportWasNotSuccessfulConcurrencyError : Translations_en_US.SavingReportWasNotSuccessfulConcurrencyError);
		Translations.OpenWhistleBlow = culture === "fi" ? Translations_fi.OpenWhistleBlow : (culture === "sv-FI" ? Translations_sv_FI.OpenWhistleBlow : Translations_en_US.OpenWhistleBlow);
		Translations.Last30Days = culture === "fi" ? Translations_fi.Last30Days : (culture === "sv-FI" ? Translations_sv_FI.Last30Days : Translations_en_US.Last30Days);
		Translations.AccountNumberTkbInCredit = culture === "fi" ? Translations_fi.AccountNumberTkbInCredit : (culture === "sv-FI" ? Translations_sv_FI.AccountNumberTkbInCredit : Translations_en_US.AccountNumberTkbInCredit);
		Translations.UserGroupNameIsAlreadyInUse = culture === "fi" ? Translations_fi.UserGroupNameIsAlreadyInUse : (culture === "sv-FI" ? Translations_sv_FI.UserGroupNameIsAlreadyInUse : Translations_en_US.UserGroupNameIsAlreadyInUse);
		Translations.UserGroupNameMustBeDefined = culture === "fi" ? Translations_fi.UserGroupNameMustBeDefined : (culture === "sv-FI" ? Translations_sv_FI.UserGroupNameMustBeDefined : Translations_en_US.UserGroupNameMustBeDefined);
		Translations.AccessRightRead = culture === "fi" ? Translations_fi.AccessRightRead : (culture === "sv-FI" ? Translations_sv_FI.AccessRightRead : Translations_en_US.AccessRightRead);
	}

	//Divided to multiple functions because of error - TS2563 The containing function or module body is too large for control flow analysis.
	static setCulture(culture: string) {
	  this.setCulture1(culture);
	  this.setCulture2(culture);
	  this.setCulture3(culture);
	}
}
 
// ReSharper restore InconsistentNaming
