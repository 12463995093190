import styled from "@emotion/styled";
import { FormControl, Typography } from "@mui/material";

export const Container = styled(FormControl)`
    margin-top: 1.5rem;
`;

export const Label = styled.div`
    margin-bottom: 0.5rem;
    color: #616d7c;
`;

export const Error = styled(Typography)`
    margin: 0 0 -1.21875rem 1rem;
`;