import React, { ReactNode } from "react";
import * as Styled from "./FormField.styles";
import { Box, Typography } from "@mui/material";

interface FormFieldProps {
    children: ReactNode;
    title: string;
    required?: boolean;
    fullWidth?: boolean;
    error?: string;
}

export const FormField = ({ children, title, required = false, fullWidth = true, error }: FormFieldProps) => {
    return (
        <Styled.Container fullWidth={fullWidth}>
            <Styled.Label>
                <Box display="flex"><Typography variant="body2">{title}</Typography> {required ? (<Typography variant="body2" color="error" marginLeft=".25rem">*</Typography>) : null}</Box>
            </Styled.Label>
            {children}
            {error ? (<Styled.Error variant="caption" color="error">{error}</Styled.Error>) : null}
        </Styled.Container>
    );
};
