//OpenWhistleBlowDialog
//***********************************************************************************************************************
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Base } from "ui/Scripts/source/framework/base";
import { Translations } from "ui/Scripts/source/models/translations";
import { AppUtils } from "../../../framework/appUtils";
import * as whistleBlowService from "../../../models/services/whistleBlowService";
import { AppDispatch } from "../../../models/store";
import { whistleBlowLoaded, whistleBlowSet } from "../../../models/store/features/whistleBlow";
import * as Styled from "./OpenWhistleBlowDialog.styles";

//OpenWhistleBlowDialog
export interface IOpenWhistleBlowDialogProps {
    open?: boolean;
    classes?: string;
    companyId: string;
    onOk: () => void;
    onCancel: () => void;
}

export interface IOpenWhistleBlowDialogState {
    fieldName: string;
    value: string;
}

export const OpenWhistleBlowDialog = ({ open = false, companyId, classes, onCancel, onOk }: IOpenWhistleBlowDialogProps) => {
    const [state, setState] = useState<IOpenWhistleBlowDialogState>({
        fieldName: Base.getGuid(),
        value: ""
    });
    const dispatch = useDispatch<AppDispatch>();

    //# region General
    const handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        setState((oldState) => ({ ...oldState, value }));
    };

    const passwordIsValid = (): boolean => {
        return !!state.value && state.value.length === 40;
    };
    // #endregion General

    const handleOk = async () => {
        if (!passwordIsValid()) {
            AppUtils.showErrorMessage(Translations.ValidPasswordMustBeDefined);
            return;
        }
        dispatch(whistleBlowLoaded());
        const result = await AppUtils.callService(() => whistleBlowService.getWhistleBlow(companyId, state.value));
        if (!result) return null;
        dispatch(whistleBlowSet(JSON.parse(JSON.stringify(result))));
        onOk();
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <DialogTitle id="alert-dialog-title">
                {Translations.OpenWhistleBlow}
            </DialogTitle>
            <DialogContent>
                <Styled.Label variant="body2">
                    {Translations.OpenWhistleBlowInstruction}
                </Styled.Label>
                <Styled.PasswordInput autoFocus autoComplete="off" id="outlined-basic" size="small" label={Translations.Password} value={state.value} variant="outlined" onChange={handleChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">{Translations.Cancel}</Button>
                <Button onClick={handleOk} variant="contained" disabled={!passwordIsValid()}>{Translations.OK}</Button>
            </DialogActions>
        </Dialog>
    );
};