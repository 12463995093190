import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlertState } from "../../storeTypes";

const initialState: IAlertState = {
    message: null,
    info: null,
    error: null
};

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        alertSuccessShown(state: IAlertState, action: PayloadAction<string>) {
            state.message = action.payload;
        },
        alertInfoShown(state: IAlertState, action: PayloadAction<string>) {
            state.info = action.payload;
        },
        alertErrorShown(state: IAlertState, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        alertCleared(state: IAlertState, _action: PayloadAction<void>) {
            state.message = null;
            state.info = null;
            state.error = null;
        },
        alertSuccessCleared(state: IAlertState, _action: PayloadAction<void>) {
            state.message = null;
        },
        alertInfoCleared(state: IAlertState, _action: PayloadAction<void>) {
            state.info = null;
        }
    },
});

export const {
    alertSuccessShown,
    alertInfoShown,
    alertErrorShown,
    alertCleared,
    alertSuccessCleared,
    alertInfoCleared,
} = alertSlice.actions;

export default alertSlice.reducer;
