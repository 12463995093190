import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFrontState } from "../../storeTypes";
import { AppThunk } from "../../store";
import * as frontService from "../../../services/frontService";
import { IFrontPage } from "../../../front/frontPage";
import { fetchEnded, fetchStarted } from "../fetchCount";
import { Translations } from "ui/Scripts/source/models/translations";
import { alertErrorShown, alertSuccessShown } from "../alert";
import { isInstanceOfIApiError } from "../../../services/baseService";

const initialState: IFrontState = {
    dataId: 0,
    frontPage: null
};

const frontSlice = createSlice({
    name: "front",
    initialState,
    reducers: {
        frontSucceded(state: IFrontState, action: PayloadAction<IFrontPage>) {
            return {
                ...state,
                dataId: state.dataId + 1,
                frontPage: action.payload
            };
        },
        frontFailed(state: IFrontState, _action: PayloadAction<void>) {
            return {
                ...state,
                dataId: state.dataId + 1,
                frontPage: null
            };
        },
    },
});

export const { frontSucceded, frontFailed } = frontSlice.actions;

export const handleApiError = (error: any, dispatch) => {
    if (isInstanceOfIApiError(error)) {
        if (error.status === 401) {
            // customHistory.push("/login");
        }
        dispatch(alertErrorShown(error.message));
    } else {
        dispatch(alertErrorShown(error));
    }
};

export const getFront = (): AppThunk => (dispatch, getState) => {
    dispatch(fetchStarted());
    return frontService.getFrontPage()
        .then(
            front => {
                dispatch(frontSucceded(JSON.parse(JSON.stringify(front))));
            },
            error => {
                handleApiError(error, dispatch);
                dispatch(frontFailed());
            })
        .finally(
            () => dispatch(fetchEnded())
        );
};

export const setCulture = (culture: string): AppThunk => (dispatch, getState) => {
    dispatch(fetchStarted());
    return frontService.setCulture(culture)
        .then(
            success => {
                appConfig.culture = culture;
                Translations.setCulture(culture);
                dispatch(alertSuccessShown(success.message));
            },
            error => {
                handleApiError(error, dispatch);
            })
        .finally(() => dispatch(fetchEnded()));
};

export default frontSlice.reducer;
