export interface IEData {
    k: string;
    i: string;
    h: string;
}

export class EData implements IEData {
    k: string;
    i: string;
    h: string;

    constructor();
    constructor(obj: IEData);
    constructor(obj?: any) {
        this.k = obj && obj.k || "";
        this.i = obj && obj.i || "";
        this.h = obj && obj.h || "";
    }
}

export interface IHasEData {
    eData: IEData;
}
