import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#2856B7",
        },
        secondary: {
            main: "#156DEE",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    boxSizing: "border-box",
                    backgroundColor: "#F2F2F2 !important",
                    fontFamily: "'Inter', sans-serif !important",
                    fontSize: "14px",
                    height: "100%",
                    lineHeight: "1.42857143",
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    background: "#F9F9F9",
                    borderBottom: "1px solid #E4E4E4",
                    fontSize: "1rem",
                    color: "#616D7C",
                    padding: "9px 1.5rem",
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "1.5rem",
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: "0 1.5rem 1.5rem 1.5rem",
                    justifyContent: "flex-end",
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontSize: "1rem",
                    minHeight: "44px",
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: "#2856B7",
                    color: "#fff"
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: "1rem !important",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    textTransform: "none",
                    padding: "7px 16px",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#F7F7F7",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: ".5rem !important",
                },
            }
        },
    },
});
