import * as React from "react";

export const SvgBubbleTipLeft = ({
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg {...props} width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Bubble tip">
            <path id="top-vector-tip" d="M9.00064 0C9.00064 0 3.2627 0 1.80065 0C0.338591 0 0.00067234 1.5 1.35067 3C2.70067 4.5 8.50127 9.5 9.00064 11C9.5 12.5 9.00064 0 9.00064 0Z" fill="#F1F7FF"/>
            <rect id="top-rectangle" width="6" height="12" transform="matrix(-1 0 0 1 15 0)" fill="#F1F7FF"/>
        </g>
    </svg>
);
