import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { SvgBubbleTipLeft, SvgBubbleTipRight } from "../../../components/icons";

export const Message = styled(Box)<{ message: "true" | "false" }>`
    justify-content: ${({ message }) => (message === "true" ? "flex-end" : "flex-start")};
    margin: .5rem 0;
`;

export const MessageBox = styled(Box)<{ message: "true" | "false" }>`
    background: ${({ message }) => (message === "true" ? "#F2F2F7" : "#F1F7FF")};
    padding: .5rem 1rem;
    border-radius: ${({ message }) => (message === "true" ? "4px 0 4px 4px" : "0 4px 4px 4px")};
    min-width: 200px;
    position: relative;
    margin: ${({ message }) => (message === "true" ? "0 .5rem 0 0" : "0 0 0 .5rem")};
    white-space: break-spaces;
    word-wrap: break-word;
`;

export const MessageTipLeft = styled(SvgBubbleTipLeft)`
    left: -8px;
    top: 0;
    position: absolute;
`;

export const MessageTipRight = styled(SvgBubbleTipRight)`
    right: -8px;
    top: 0;
    position: absolute;
`;

export const Sender = styled(Typography)`
    font-weight: 600;
`;

export const MessageText = styled(Typography)`

`;

export const MessageAttachment = styled(Box)`
    cursor: pointer;
`;

export const AttachmentTitle = styled(Typography)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 4px;
`;

export const Timestamp = styled(Typography)`
    color: #667382;
    text-align: right;
`;
