import * as React from "react";

export const SvgBubbleTipRight = ({
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg {...props} width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Bubble tip">
            <path id="top-vector-tip" d="M5.99936 0C5.99936 0 11.7373 0 13.1994 0C14.6614 0 14.9993 1.5 13.6493 3C12.2993 4.5 6.49873 9.5 5.99936 11C5.5 12.5 5.99936 0 5.99936 0Z" fill="#F2F2F7"/>
            <rect id="top-rectangle" width="6" height="12" fill="#F2F2F7"/>
        </g>
    </svg>
);
