import { IApiError } from "./apiError";

export interface IApiSuccess {
    message: string;
    errorMessage: string;
}

export class ApiSuccess implements IApiSuccess {
    message: string;
    errorMessage: string;

    constructor();
    constructor(obj: IApiError);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
        this.errorMessage = obj && obj.errorMessage || "";
    }
}