import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { WhistleBlowState } from "ui/Scripts/source/models/enums";

const getChipColor = (status: WhistleBlowState) => {
    switch (status) {
    case WhistleBlowState.New:
        return "#FFEEED";
    case WhistleBlowState.InProgress:
        return "#F1F7FF";
    case WhistleBlowState.Received:
        return "#FFFAE2";
    case WhistleBlowState.Resolved:
        return "#E0FAEF";
    default:
        return "#FFEEED";
    }
};

export const Container = styled(Chip)<{ status: WhistleBlowState }>`
    background: ${({ status }) => getChipColor(status)};
`;