/// <reference types="react-scripts" />
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AppDispatch, RootState } from "../../../models/store";
import { Base } from "ui/Scripts/source/framework/base";
import Logo from "./../../../../../Devel/images/logo.svg";
import * as Styled from "./Header.styles";
import { LanguageToggle } from "./LanguageToggle";
import { setCulture } from "../../../models/store/features/front";

//Header
export interface IHeaderProp {
    onNavigateOutCheck?: () => Promise<boolean>;
}

export const Header = ({ onNavigateOutCheck }: IHeaderProp) => {
    const { pageName } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const frontPage = useSelector((state: RootState) => state.front.frontPage);
    const activeCultureSimple = Base.getCultureSimple(appConfig.culture);

    const languages = frontPage?.languages ?? [];

    const canNavigateOut = async (): Promise<boolean> => {
        if (!onNavigateOutCheck) return Base.getPromiseResult(true);
        return await onNavigateOutCheck();
    };

    const handleGoToFrontPage = () => {
        canNavigateOut()
            .then(result => {
                if (!result) return;
                navigate("/" + pageName ?? "");
            });
    };

    return (
        <Styled.Container>
            <Styled.Content>
                <Box display="flex" justifyContent="space-between" alignItems={"center"}>
                    {frontPage?.companyLogo
                        ? (<Styled.Logo className="logo" alt="logo" src={frontPage?.companyLogo ? "data:image/png;base64," + frontPage?.companyLogo : Logo} title={frontPage?.companyName} onClick={handleGoToFrontPage} />)
                        : (<Typography variant="h5">{frontPage?.companyName}</Typography>)
                    }
                    {languages.length > 1 &&
                        <LanguageToggle activeLanguage={activeCultureSimple} languages={languages.map((x) => x.cultureCode)} onLanguageToggle={(culture) => dispatch(setCulture(culture))} />
                    }
                </Box>
            </Styled.Content>
        </Styled.Container>
    );
};