import { Box, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { Translations } from "ui/Scripts/source/models/translations";
import { FlagEn, FlagFi, FlagSv } from "../../../icons";
import * as Styled from "./LanguageToggle.styles";

const getLanguageLabel = (cultureCode: string) => {
    switch (cultureCode) {
    case "sv":
    case "sv-FI":
        return `${Translations.LanguageNonLocalSwedish} (${cultureCode.split("-")[0]})`;
    case "fi":
        return `${Translations.LanguageNonLocalFinnish} (${cultureCode})`;
    case "en-US":
    case "en":
        return `${Translations.LanguageNonLocalEnglish} (${cultureCode.split("-")[0]})`;
    }
};

const LanguageIcon = ({ culture = "fi" }: { culture: string }) => {
    switch (culture) {
    case "fi":
        return <FlagFi />;
    case "en":
    case "en-US":
        return <FlagEn />;
    case "sv":
    case "sv-FI":
        return <FlagSv />;
    default:
        return <></>;
    }
};

interface LanguageToggleProps {
    languages: string[];
    activeLanguage: string;
    onLanguageToggle: (languageCode: string) => void;
}

export const LanguageToggle = ({ languages, activeLanguage, onLanguageToggle }: LanguageToggleProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetCulture = (culture: string) => {
        if (culture !== activeLanguage) {
            onLanguageToggle(culture);
        }
        setAnchorEl(null);
    };

    return (
        <>
            <Styled.ToggleButton onClick={handleClick} aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
                <Box display="flex" width={"100%"} justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <Styled.FlagIcon>
                            <LanguageIcon culture={activeLanguage} />
                        </Styled.FlagIcon>
                        {getLanguageLabel(activeLanguage)}
                    </Box>
                    <Styled.ArrowIcon open={open}></Styled.ArrowIcon>
                </Box>
            </Styled.ToggleButton>
            <Styled.Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ "aria-labelledby": "basic-button", "sx": { width: "176px" } }} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
                {languages.map((language) => (
                    <MenuItem key={language} onClick={() => handleSetCulture(language)}>
                        <Box display="flex" width={"100%"} justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Styled.FlagIcon>
                                    <LanguageIcon culture={language} />
                                </Styled.FlagIcon>
                                {getLanguageLabel(language)}
                            </Box>
                            <div></div>
                        </Box>
                    </MenuItem>
                ))}
            </Styled.Menu>
        </>
    );
};
