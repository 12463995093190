import { Action, ThunkAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { alert } from "./features/alert";
import { confirmation } from "./features/confirmation";
import { fetchCount } from "./features/fetchCount";
import { front } from "./features/front";
import { whistleBlow } from "./features/whistleBlow";

const rootReducer = combineReducers({ fetchCount, alert, confirmation, front, whistleBlow });

const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;

export default store;
