import { Base } from "ui/Scripts/source/framework/base";
import { IApiResponse } from "ui/Scripts/source/models/common/apiResponse";
import { IEData } from "ui/Scripts/source/models/common/eData";
import { FileAttachment } from "ui/Scripts/source/models/common/fileAttachment";
import { ConfirmationDialogResult, FileType } from "ui/Scripts/source/models/enums";
import { Translations } from "ui/Scripts/source/models/translations";
import { IFrontPage } from "../models/front/frontPage";
import * as baseService from "../models/services/baseService";
import { store } from "../models/store";
import { alertErrorShown, alertInfoCleared, alertInfoShown, alertSuccessShown } from "../models/store/features/alert";
import { confirmationCleared, confirmationSet, setConfirmation } from "../models/store/features/confirmation";
import { fetchEnded, fetchStarted } from "../models/store/features/fetchCount";
import { ConfirmationDialogType } from "../models/store/storeTypes";

//AppUtils
export class AppUtils {
    static showSuccessMessage(message: string) {
        store.dispatch(alertSuccessShown(message));
    }

    static showInfoMessage(message: string) {
        store.dispatch(alertInfoShown(message));
    }

    static showErrorMessage(message: string) {
        store.dispatch(alertErrorShown(message));
    }

    static showApiErrorMessage(e) {
        AppUtils.showErrorMessage(baseService.getErrorMessageFromError(e));
    }

    static clearInfoMessage() {
        store.dispatch(alertInfoCleared());
    }

    static showConfirmationDialog(message: string, showCancelButton: boolean = false, yesConfirmationText: string = null): Promise<ConfirmationDialogResult> {
        return new Promise<ConfirmationDialogResult>((resolve) => {
            store.dispatch(confirmationSet(setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, message,
                () => {
                    store.dispatch(confirmationCleared());
                    resolve(ConfirmationDialogResult.Yes);
                },
                () => {
                    store.dispatch(confirmationCleared());
                    resolve(ConfirmationDialogResult.No);
                },
                showCancelButton
                    ? () => {
                        store.dispatch(confirmationCleared());
                        resolve(ConfirmationDialogResult.Cancel);
                    }
                    : null
                , yesConfirmationText
            )));
        });
    }
    
    static isString(data: any): data is string {
        return typeof data === "string";
    }

    static isIApiResponse(data: any): data is IApiResponse {
        if (AppUtils.isString(data)) return false;
        return data && "message" in data;
    }

    static async callService<T>(serviceCall: () => Promise<T>, showSuccessMessage: boolean = true, disableLoadIndicator: boolean = false, onFinally: () => void = null): Promise<T> {
        if (!disableLoadIndicator) {
            store.dispatch(fetchStarted());
        }
        try {
            const result = await serviceCall();
            if (showSuccessMessage) {
                if (AppUtils.isIApiResponse(result) && result.message) {
                    AppUtils.showSuccessMessage(result.message);
                } else if (AppUtils.isString(result) && result) {
                    AppUtils.showSuccessMessage(result);
                }
            }
            return result;
        } catch (e) {
            AppUtils.showApiErrorMessage(e);
            return Base.getPromiseResult<T>(null);
        } finally {
            if (!disableLoadIndicator) {
                store.dispatch(fetchEnded());
            }
            if (onFinally) {
                onFinally();
            }
        }
    }

    static async validate(checkErrors: () => Promise<boolean>, getWarnings: () => Promise<string>, confirmationMessage: string = null): Promise<boolean> {
        if (!await checkErrors()) return false;
        const warnings = await getWarnings();
        if (!warnings) {
            return true;
        }
        return await AppUtils.showConfirmationDialog(warnings + Base.lf + (confirmationMessage || Translations.DoYouReallyWantToSave)) === ConfirmationDialogResult.Yes;
    }
    
    static validateFileUploadSize(fileSizeKb: number, errorMessage: string = null, maxUploadSize: number = null): boolean {
        const maxFileUploadSize = maxUploadSize ?? appConfig.maxFileUploadSize;
        if (fileSizeKb > maxFileUploadSize) {
            AppUtils.showErrorMessage(String.format(errorMessage ?? Translations.MaximumSizeOfFilesThatCanBeAddedInBatchIsParameterMB, (maxFileUploadSize / 1024).toLocaleFixed(0)));
            return false;
        }
        return true;
    }

    static validateFileAttachment(fileAttachment: FileAttachment, errorMessage: string = null, maxUploadSize: number = null): boolean {
        if (!fileAttachment) return false;
        return AppUtils.validateFileUploadSize(fileAttachment.size / 1024, errorMessage, maxUploadSize);
    }

    static validateFileAttachments(fileAttachments: FileAttachment[], errorMessage: string = null, maxUploadSize: number = null): boolean {
        if (!fileAttachments || fileAttachments.length < 1) return true;
        let fileSizeKb = 0;
        for (const fileAttachment of fileAttachments) {
            if (!fileAttachment) continue;
            fileSizeKb = fileSizeKb + fileAttachment.size / 1024;
        }
        return AppUtils.validateFileUploadSize(fileSizeKb, errorMessage, maxUploadSize);
    }

    static getFrontPage(): IFrontPage {
        const appState = store.getState();
        if (!appState?.front?.frontPage) return null;
        return appState.front.frontPage;
    }

    static getEData(): IEData {
        return AppUtils.getFrontPage()?.eData;
    }

    static checkAttachmentNames (attachments: FileAttachment[]): boolean {
        const invalidAttachmentNames: string[] = [];
        for (const attachment of attachments) {
            if (attachment.type !== FileType.Image && attachment.type !== FileType.Pdf) {
                invalidAttachmentNames.push(attachment.name);
            }
        }
        if (invalidAttachmentNames.length < 1) return true;
        AppUtils.showErrorMessage(String.format(Translations.OnlyImageAndPdfAttachmentsAreAllowedInvalidAttachmentParamater, invalidAttachmentNames.join(", ")));
        return false;
    }

    static filterAttachmentDuplicates (attachments: FileAttachment[], newAttachments: FileAttachment[]) {
        return newAttachments.filter(attachment => !attachments.map(x => x.name).includes(attachment.name));
    }
}