/// <reference types="react-scripts" />
import { ThemeProvider } from "@emotion/react";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/se";
import * as React from "react";
import { Dispatch } from "react";
import * as ReactDOM from "react-dom/client";
import { Provider, connect } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "ui/Js/array.js";
import "ui/Js/date.js";
import "ui/Js/fetch.js";
import "ui/Js/number.js";
import "ui/Js/string.js";
import { Base } from "ui/Scripts/source/framework/base";
import { Translations } from "ui/Scripts/source/models/translations";
import { store } from "../../models/store";
import { alertCleared, alertInfoCleared, alertSuccessCleared } from "../../models/store/features/alert";
import { confirmationCleared } from "../../models/store/features/confirmation";
import { IAlertState, IApplicationState, IConfirmationState } from "../../models/store/storeTypes";
import { muiTheme } from "../../shared/styles";
import { Front } from "../Front";
import { Layout } from "../Layout";
import { AlertBox } from "../framework/alertBox";
import { ConfirmationDialog } from "../framework/dialog";
import { NewWhistleBlow } from "../whistleBlow/NewWhistleBlow";
import { OpenWhistleBlow } from "../whistleBlow/OpenWhistleBlow";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../../../../node_modules/react-datetime/css/react-datetime.css";

export interface IAppOwnProps {
}

export interface IAppStateProps {
    fetchCount: number,
    alert: IAlertState;
    confirmation: IConfirmationState;
}

export interface IAppDispatchProps {
    clearAlerts: () => void;
    clearSuccess: () => void;
    clearInfo: () => void;
    clearConfirmation: () => void;
}

type AppProps = IAppOwnProps & IAppStateProps & IAppDispatchProps;

export const App = ({ alert, clearAlerts, clearConfirmation, clearInfo, clearSuccess, confirmation, fetchCount }: AppProps) => {
    let successTimeOut: number;
    let infoTimeOut: number;
    let errorTimeOut: number;

    React.useEffect(() => {
        Translations.setCulture(appConfig.culture);

        return () => {
            clearTimeout(successTimeOut);
            clearTimeout(infoTimeOut);
            clearTimeout(errorTimeOut);
        };
    }, []);

    const getShowSuccessMessage = (): boolean => {
        const result = alert && !!alert.message;
        if (result) {
            clearTimeout(successTimeOut);
            successTimeOut = window.setTimeout(() => {
                clearSuccess();
            }, 2500);
        }
        return result;
    };

    const getShowInfoMessage = (): boolean => {
        const result = alert && !!alert.info;
        if (result) {
            clearTimeout(infoTimeOut);
            infoTimeOut = window.setTimeout(() => {
                clearInfo();
            }, 10000);
        }
        return result;
    };

    const getShowErrorMessage = (): boolean => {
        const result = alert && !!alert.error;
        if (result) {
            clearTimeout(errorTimeOut);
            errorTimeOut = window.setTimeout(() => {
                clearAlerts();
            }, 7500);
        }
        return result;
    };

    const getShowLoadingImage = (): boolean => {
        return fetchCount > 0;
    };

    const handleSuccessClick = () => {
        clearTimeout(successTimeOut);
        clearSuccess();
    };

    const handleInfoClick = () => {
        clearInfo();
    };

    const handleErrorClick = () => {
        clearTimeout(errorTimeOut);
        clearAlerts();
    };

    const showSuccessMessage = getShowSuccessMessage();
    const showInfoMessage = getShowInfoMessage();
    const showErrorMessage = getShowErrorMessage();
    const showLoadingImage = getShowLoadingImage();
        
    return (
        <>
            {showSuccessMessage &&
                    <AlertBox
                        success={true}
                        containerClasses="alertContainer"
                        classes="globalSuccess"
                        message={alert.message}
                        onClick={handleSuccessClick}
                    />
            }
            {showInfoMessage &&
                    <AlertBox
                        info={true}
                        containerClasses="alertContainer"
                        classes="globalInfo"
                        message={alert.info}
                        onClick={handleInfoClick}
                    />
            }
            {showErrorMessage &&
                    <AlertBox
                        error={true}
                        containerClasses="alertContainer"
                        classes="globalError"
                        message={alert.error}
                        onClick={handleErrorClick}
                    />
            }
            {showLoadingImage &&
                    <Backdrop open sx={{ color: "#fff", zIndex: 9999 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            }
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Navigate to="/unknown" />}/>
                    <Route path="/:pageName/new" element={<NewWhistleBlow />}/>
                    <Route path="/:pageName/open" element={<OpenWhistleBlow />}/>
                    <Route path="/:pageName/:language?" element={<Front />}/>
                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
            </Routes>
            {confirmation.show &&
                    <ConfirmationDialog
                        type={confirmation.type}
                        title={confirmation.title}
                        text={!Base.isFormattedText(confirmation.text) ? confirmation.text : <div dangerouslySetInnerHTML={{ __html: Base.getFormattedText(confirmation.text) }}></div>}
                        yesConfirmationText={confirmation.yesConfirmationText}
                        onYes={confirmation.onYes}
                        onNo={confirmation.onNo}
                        onCancel={confirmation.onCancel}
                    />
            }
        </>
    );
};

export function mapStateToProps(state: IApplicationState): IAppStateProps {
    return {
        fetchCount: state.fetchCount,
        alert: state.alert,
        confirmation: state.confirmation,
    };
}

export function mapDispatchToProps(dispatch: Dispatch<any>): IAppDispatchProps {
    return {
        clearAlerts: () => dispatch(alertCleared()),
        clearSuccess: () => dispatch(alertSuccessCleared()),
        clearInfo: () => dispatch(alertInfoCleared()),
        clearConfirmation: () => dispatch(confirmationCleared()),
    };
}

export const AppConnected = connect<IAppStateProps, IAppDispatchProps, IAppOwnProps>(mapStateToProps, mapDispatchToProps)(App);

if (!window.Promise) {
    window.Promise = Promise;
}

if (!("geolocation" in navigator)) {
    console.log("geolocation IS NOT available");
}

// Registering service worker
if (!("serviceWorker" in navigator)) {
    console.log("serviceWorker IS NOT available");
}

const root = ReactDOM.createRoot(document.getElementById("appContent") as HTMLElement);

root.render(
    <BrowserRouter basename={appConfig.rootUrl}>
        <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Provider store={store}>
                <AppConnected />
            </Provider>
        </ThemeProvider>
    </BrowserRouter>
);
