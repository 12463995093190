import { Base } from "../../framework/base";

export interface IEditItem {
    id: string;
    rowId: string;

    isNew(): boolean;
    getHash(): string;
}

export class EditItem implements IEditItem {
    id: string;
    rowId: string;

    constructor();
    constructor(obj: IEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.rowId = obj && obj.rowId || "";
    }

    isNew(): boolean {
        return !this.rowId;
    }

    getHash(): string {
        return (this.id ?? "") + "#" + (this.rowId ?? "");
    }
    
    static getHash(items: IEditItem[]): string {
        if (!items || items.length < 1) return "";
        return Base.joinSuffix(items.map(i => i.getHash()), "$");
    }

    static setItemIdsFromIds(items: IEditItem[], ids: string[]) {
        if (items.length < 0 || ids.length < 0) return;
        for (let i = 0; i < Math.min(items.length, ids.length); i++) {
            items[i].id = ids[i];
        }
    }
}