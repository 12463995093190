/* global GeolocationPositionError, Geolocation, google, CustomModels */
import * as moment from "moment";
import { Translations } from "../models/translations";

declare const fileExtensions: CustomModels.FileExtensions;

export interface IIdItem {
    id: string;
}

export class Base {
    static oneHourInMs = 60 * 60 * 1000;

    static logTest(line: string) {
        console.log("log line: " + line);
    }

    static isNullOrUndefined(test: any): boolean {
        return test === null || test === undefined;
    }

    static isNullOrEmpty(test: string): boolean {
        return test === null || test === undefined || test === "";
    }

    static nullToEmpty(test: string): string {
        return test !== null && test !== undefined ? test : "";
    }

    static isInteger(value: any): boolean {
        return typeof value === "number" &&
            isFinite(value) &&
            Math.floor(value) === value;
    };

    static isString(data: any): data is string {
        return typeof data === "string";
    }

    static getRandomIntInteger(maxValue: number): number {
        return Math.floor(Math.random() * maxValue);
    }

    // Round away from zero
    static roundToDecimals(value: number, decimals: number): number {
        const dec = Math.pow(10, decimals ?? 0);
        const sign = value < 0 ? -1 : 1;
        return Math.round((value + sign * Number.EPSILON) * dec) / dec;
    }

    //static isString(value: any): boolean {
    //    return typeof value === "string";
    //}

    static intToFileSizeStr(n: number, addBrackets: boolean = true) :string {
        const kb = 1024;
        const mb = kb * 1024;
        if (n < mb) return (addBrackets ? "(" : "") + (`${(n / kb).toFixed(1)} KB`) + (addBrackets ? ")" : "");
        return (addBrackets ? "(" : "") + (`${(n / mb).toFixed(1)} MB`) + (addBrackets ? ")" : "");
    }

    //------------------------------
    //Guid
    //------------------------------
    /* exported getGUID*/

    static emptyGuid = "00000000-0000-0000-0000-000000000000";

    static getGuid(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }).toUpperCase();
    }

    static guidToArray(id: string): Uint8Array {
        let v;
        const result = new Uint8Array(16);
        if (!id || id.length !== 36) return result;
        // Parse ########-....-....-....-............
        result[0] = (v = parseInt(id.slice(0, 8), 16)) >>> 24;
        result[1] = (v >>> 16) & 0xff;
        result[2] = (v >>> 8) & 0xff;
        result[3] = v & 0xff;
        // Parse ........-####-....-....-............
        result[4] = (v = parseInt(id.slice(9, 13), 16)) >>> 8;
        result[5] = v & 0xff;
        // Parse ........-....-####-....-............
        result[6] = (v = parseInt(id.slice(14, 18), 16)) >>> 8;
        result[7] = v & 0xff;
        // Parse ........-....-....-####-............
        result[8] = (v = parseInt(id.slice(19, 23), 16)) >>> 8;
        result[9] = v & 0xff;
        // Parse ........-....-....-....-############
        // (Use "/" to avoid 32-bit truncation when bit-shifting high-order bytes)
        result[10] = ((v = parseInt(id.slice(24, 36), 16)) / 0x10000000000) & 0xff;
        result[11] = (v / 0x100000000) & 0xff;
        result[12] = (v >>> 24) & 0xff;
        result[13] = (v >>> 16) & 0xff;
        result[14] = (v >>> 8) & 0xff;
        result[15] = v & 0xff;
        return result;
    }

    static guidToNumber(id: string, size: number): number {
        const a = Base.guidToArray(id);
        let result = 0;
        for (let i = 0; i < a.length; i++) {
            result = (result + (i + 1) * a[i]) % size;
        }
        return result;
    }

    //------------------------------
    //String
    //------------------------------
    static strToHashNumber(str: string, size: number): number {
        if (!str) return 0;
        let result = 0;
        for (let i = 0; i < str.length; i++) {
            result = (result + (i + 1) * str.charCodeAt(i)) % size;
        }
        return result;
    }
 
    static padZeros(str: string, zeros: number): string {
        return ("00000000000000000000" + str).slice(-zeros);
    }

    static limitStrLength(str: string, maxLength: number, addEllipsis: boolean = false): string {
        if (!str || str.length <= maxLength) return str;
        return str.slice(0, maxLength) + (addEllipsis ? "..." : "");
    }

    static joinSuffix(arr: string[], separator?: string): string {
        if (!arr || arr.length < 1) return "";
        const sep = separator ?? ",";
        return arr.join(sep) + sep;
    }

    //------------------------------
    //Number
    //------------------------------
    static strToInteger(str: string, min: number = null, max: number = null): number {
        const minValue = !Base.isNullOrUndefined(min) ? min : 0;
        if (Base.isNullOrEmpty(str)) {
            return minValue;
        }
        let value = str.toInteger(minValue < 0, minValue);
        if (!Base.isNullOrUndefined(max)) {
            value = Math.min(value, max);
        }
        return value;
    }

    static strToNumber(str: string, min: number = null, max: number = null): number {
        const minValue = !Base.isNullOrUndefined(min) ? min : 0;
        if (Base.isNullOrEmpty(str)) {
            return minValue;
        }
        let value = str.toDecimal(minValue < 0, true);
        if (!Base.isNullOrUndefined(min)) {
            value = Math.max(value, min);
        }
        if (!Base.isNullOrUndefined(max)) {
            value = Math.min(value, max);
        }
        return value;
    }

    //------------------------------
    //Boolean
    //------------------------------
    static strToBool(str: string): boolean {
        return !Base.isNullOrEmpty(str) && (str.equalIgnoreCase("true") || str.equalIgnoreCase("1"));
    }

    //------------------------------
    //Times and Dates
    //------------------------------
    static timeHasValue(time: number): boolean {
        return !this.isNullOrUndefined(time) && time !== 0 && !isNaN(time);
    }

    static timeToDateStr(value: moment.Moment | number): string {
        if (this.isNullOrUndefined(value)) return "";
        let m: moment.Moment;
        if (typeof value === "number") {
            if (!this.timeHasValue(value)) {
                return "";
            }
            m = moment(value);
        } else {
            m = value;
        }
        return m.format("D.M.YYYY");
    }

    static timeToTimeStr(value: moment.Moment | number): string {
        if (this.isNullOrUndefined(value)) return "";
        let m: moment.Moment;
        if (typeof value === "number") {
            if (!this.timeHasValue(value)) {
                return "";
            }
            m = moment(value);
        } else {
            m = value;
        }
        return m.format("H:mm");
    }

    static timeToDateTimeStr(time: number, includeSeconds: boolean = false): string {
        if (!this.timeHasValue(time)) return "";
        return moment(time).format("D.M.YYYY H:mm" + (includeSeconds ? ":ss" : ""));
    }

    static timeToWeekdayStr(time: number): string {
        if (!this.timeHasValue(time)) return "";
        return moment(time).format("dddd");
    }

    static utcTimeToDateStr(value: moment.Moment | number): string {
        if (this.isNullOrUndefined(value)) return "";
        let m: moment.Moment;
        if (typeof value === "number") {
            if (isNaN(value)) {
                return "";
            }
            m = moment.utc(value);
        } else {
            m = moment.utc([value.year(), value.month(), value.date(), 0, 0, 0, 0]);
        }
        return m.format("D.M.YYYY");
    }

    static utcTimeToTimeStr(value: moment.Moment | number): string {
        if (this.isNullOrUndefined(value)) return "";
        let m: moment.Moment;
        if (typeof value === "number") {
            if (isNaN(value)) {
                return "";
            }
            m = moment.utc(value);
        } else {
            m = moment.utc([0, 0, 0, value.hour(), value.minutes(), 0, 0]);
        }
        return m.format("H:mm");
    }
    
    static getNowTicks(): number {
        const date = new Date();
        return date.getTime();
    }

    static dateToDatePart(date: Date): Date {
        if (Base.isNullOrUndefined(date)) return null;
        const y = date.getFullYear();
        const m = date.getMonth();
        const d = date.getDate();
        return new Date(y, m, d, 0, 0, 0, 0);
    }

    static getNowDateTicks(): number {
        return Base.dateToDatePart(new Date()).getTime();
    }

    static dateToUtcDate(date: Date): Date {
        if (Base.isNullOrUndefined(date)) return null;
        const y = date.getFullYear();
        const m = date.getMonth();
        const d = date.getDate();
        return new Date(Date.UTC(y, m, d, 0, 0, 0, 0));
    }
    
    static getNowUtcDate(): Date {
        return Base.dateToUtcDate(new Date());
    }

    static getNowUtcTime(): Date {
        const date = new Date();
        const h = date.getHours();
        const n = date.getMinutes();
        return new Date(Date.UTC(0, 0, 0, h, n, 0, 0));
    }

    static getNowUtcDateTime(): Date {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const d = date.getDate();
        const h = date.getHours();
        const n = date.getMinutes();
        return new Date(Date.UTC(y, m, d, h, n, 0, 0));
    }

    static roundDateMinutes(date: Date, minRound): Date {
        if (minRound < 1.01) return date;
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const rounded = Math.round((hours * 60 + minutes) / minRound) * minRound;
        date.setHours(Math.floor(rounded / 60));
        date.setMinutes(rounded % 60);
        date.setSeconds(0);
        return date;
    }

    static roundTimeMinutes(time: number, minRound): number {
        return Base.roundDateMinutes(new Date(time), minRound).getTime();
    }

    static getTimeSpanStr(str: string): string {
        const timeParts = str.parseTimeParts(null, 1000000);
        return timeParts.hour + ":" + Base.padZeros(timeParts.minute.toString(10), 2);
    }

    static timeSpanToString(timeSpan: number): string {
        if (Base.isNullOrUndefined(timeSpan) || isNaN(timeSpan)) return "";
        const isPositive = timeSpan >= 0;
        const absTimeSpan = Math.abs(timeSpan);
        const hour = Math.floor(absTimeSpan);
        const minute = Math.round((absTimeSpan - hour) * 60);
        return (isPositive ? "" : "-") + hour.toString(10) + ":" + Base.padZeros(minute.toString(10), 2);
    }

    static utcDateAndTimeDiffInMinutes(date1: Date, time1: Date, date2: Date, time2: Date): number {
        if (Base.isNullOrUndefined(date1) || Base.isNullOrUndefined(time1) || Base.isNullOrUndefined(date2) || Base.isNullOrUndefined(time2)) return 0;
        const mDate1 = moment({ y: date1.getUTCFullYear(), M: date1.getUTCMonth(), d: date1.getUTCDate(), h: time1.getUTCHours(), m: time1.getUTCMinutes(), s: time1.getUTCSeconds(), ms: time1.getUTCMilliseconds() });
        const mDate2 = moment({ y: date2.getUTCFullYear(), M: date2.getUTCMonth(), d: date2.getUTCDate(), h: time2.getUTCHours(), m: time2.getUTCMinutes(), s: time2.getUTCSeconds(), ms: time2.getUTCMilliseconds() });
        return mDate2.diff(mDate1, "minutes");
    }

    static utcDateAndTimeDiffInTimeSpan(date1: Date, time1: Date, date2: Date, time2: Date): number {
        return Base.utcDateAndTimeDiffInMinutes(date1, time1, date2, time2) / 60;
    }

    static minutesToDateStr(minutes: number): string {
        if (Base.isNullOrUndefined(minutes)) return "";
        const hour = Math.floor(minutes / 60);
        const min = minutes - hour * 60;
        return hour.toString(10) + ":" + Base.padZeros(min.toString(10), 2);
    }

    static timeToFromNow(time: number) {
        return moment(time).fromNow();
    }

    static getDateTime(dateStr: string, timeStr: string) {
        let result: Date = null;
        if (dateStr) {
            result = dateStr.toDate();
            if (timeStr) {
                const startTime = timeStr.toTime();
                result = result.addHours(startTime.getHours()).addMinutes(startTime.getMinutes());
            }
        }
        return result;
    }

    static getTimeStamp(includeMs: boolean = true): string {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const h = date.getHours();
        const n = date.getMinutes();
        const s = date.getSeconds();
        const z = date.getMilliseconds();
        return y.toString(10) + (m <= 9 ? "0" : "") + m.toString(10) + (d <= 9 ? "0" : "") + d.toString(10) +
            (h <= 9 ? "0" : "") + h.toString(10) + (n <= 9 ? "0" : "") + n.toString(10) + (s <= 9 ? "0" : "") + s.toString(10) +
            (includeMs ? (z <= 9 ? "00" : (z <= 99 ? "0" : "")) + z.toString(10) : "");
    }

    //------------------------------
    //Formatted Text
    //------------------------------
    static lf = "#LF#";

    static isFormattedText(str: string): boolean {
        if (Base.isNullOrEmpty(str) || !str.indexOf) return false;
        return str.indexOf(Base.lf) > -1;
    }

    static getFormattedText(str: string): string {
        if (Base.isNullOrEmpty(str)) return str;
        return str.replace(/#LF#/gi, "<br/>");
    }
    
    //------------------------------
    //Comparison
    //------------------------------
    static strCompare(a: string, b: string): number {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return 0;
        if (Base.isNullOrUndefined(a)) return 1;
        if (Base.isNullOrUndefined(b)) return -1;
        return a.localeCompare(b, "fi");
    }

    static numCompare(a: number, b: number): number {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return 0;
        if (Base.isNullOrUndefined(a)) return 1;
        if (Base.isNullOrUndefined(b)) return -1;
        return a < b ? -1 : (a > b ? 1 : 0);
    }

    static boolCompare(a: boolean, b: boolean): number {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return 0;
        if (Base.isNullOrUndefined(a)) return 1;
        if (Base.isNullOrUndefined(b)) return -1;
        const aInt = a ? 1 : 0;
        const bInt = b ? 1 : 0;
        return aInt < bInt ? -1 : (aInt > bInt ? 1 : 0);
    }

    static equalInt(a: number, b: number): boolean {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return true;
        if (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) return false;
        return Math.abs(a - b) < 0.5;
    }

    //------------------------------
    //Filetypes
    //------------------------------
    static getFileType(name: string): number {
        if (Base.isNullOrEmpty(name) || Base.isNullOrUndefined(fileExtensions)) return 0;
        const index = name.lastIndexOf(".");
        if (index < 0) return 0;
        let ext = name.substring(index);
        if (Base.isNullOrEmpty(ext)) return 0;
        ext = ext.toUpperCase();
        if (fileExtensions.wordExtensions.indexOf(ext) !== -1) return 1;
        if (fileExtensions.excelExtensions.indexOf(ext) !== -1) return 2;
        if (fileExtensions.powerPointExtensions.indexOf(ext) !== -1) return 3;
        if (fileExtensions.pdfExtensions.indexOf(ext) !== -1) return 4;
        if (fileExtensions.imageExtensions.indexOf(ext) !== -1) return 5;
        if (fileExtensions.archiveExtensions.indexOf(ext) !== -1) return 6;
        if (fileExtensions.textExtensions.indexOf(ext) !== -1) return 7;
        if (fileExtensions.htmlExtensions.indexOf(ext) !== -1) return 8;
        return 0;
    }

    static isImageFile(file: File): boolean {
        return !Base.isNullOrUndefined(file) && Base.getFileType(file.name) === 5;
    }

    //------------------------------
    //Generics
    //------------------------------
    static getTypedArray<T>(items: any, type: (new (...args: any[]) => T)): T[] {
        const result: T[] = [];
        if (items) {
            for (let i = 0; i < items.length; i++) {
                /* eslint-disable new-cap */
                result.push(new type(items[i]));
                /* eslint-enable new-cap */
            }
        }
        return result;
    }

    static getPromiseResult<T>(value: T): Promise<T> {
        return new Promise<T>((resolve) => { resolve(value); });
    }

    static moveToFirst<T>(items: T[], indexA: number): T[] {
        if (!items || !indexA || indexA <= 0 || items.length < 2 || indexA >= items.length) return items;
        const a = items[indexA];
        items.splice(indexA, 1);
        items.unshift(a);
        return items;
    }

    static swapArrayItems<T>(items: T[], indexA: number, indexB: number): T[] {
        if (!items || Base.isNullOrUndefined(indexA) || Base.isNullOrUndefined(indexB) || indexA === indexB || indexA < 0 || indexB < 0 || items.length < 2 || indexA >= items.length || indexB >= items.length) return items;
        const a = items[indexA];
        items[indexA] = items[indexB];
        items[indexB] = a;
        return items;
    }

    //!!! USE ONLY FOR SIMPLE TYPES
    private static getUniqueItems<T>(items: T[]): T[] {
        return [...Array.from(new Set(items))];
    }

    // ------------------------------
    // Array
    // ------------------------------
    static getUniqueStringItems(items: string[]): string[] {
        return Base.getUniqueItems<string>(items);
    }

    static getUniqueNumberItems(items: number[]): number[] {
        return Base.getUniqueItems<number>(items);
    }

    static getStringWithSeparators(values: string[], separator: string, maxValueCount: number = -1): string {
        if (!values || values.length < 1 || Base.isNullOrUndefined(separator)) return "";
        const maxCount = maxValueCount && maxValueCount > 0 ? maxValueCount : values.length;
        let result = "";
        for (let i = 0; i < maxCount; i++) {
            if (!values[i]) continue;
            result = result + values[i] + separator;
        }
        if (result.length > 0) {
            result = result.substr(0, result.length - separator.length);
        }
        return result;
    }

    static equalArrays(arr1: any[], arr2: any[]): boolean {
        const a1 = arr1 ?? [];
        const a2 = arr2 ?? [];
        if (a1.length !== a2.length) return false;
        for (let i = 0; i < a1.length; ++i) {
            if (a1[i] !== a2[i]) return false;
        }
        return true;
    }

    static equalStrArrays(arr1: string[], arr2: string[]): boolean {
        return Base.equalArrays(arr1, arr2);
    }

    static maxVal(items: number[], defaultValue: number = 0): number {
        if (!items || items.length < 0) return defaultValue;
        return Math.max(...items);
    }

    //------------------------------
    //IIdItem
    //------------------------------
    static getToggledMultiSelectIds(items: IIdItem[], selectedIds: string[], toggleId: string, checked: boolean, allId: string = ""): string[] {
        let result = selectedIds.filter(i => i !== allId);
        if (toggleId === allId) {
            if (checked) {
                result = [allId];
            } else if (result.length < 1 && items.length > 1) {
                result.push(items[1].id);
            }
        } else {
            if (checked) {
                if (result.indexOf(toggleId) < 0) {
                    result.push(toggleId);
                }
            } else {
                result = result.filter(i => i !== toggleId);
            }
        }
        if (result.length < 1) {
            result.push(allId);
        }
        return result;
    };

    static keepAvailableSelectedIds(items: IIdItem[], selectedIds: string[], allValue: string = Base.emptyGuid): string[] {
        let result = selectedIds.slice(0);
        if (result.length > 0 && result[0] !== allValue) {
            const unitIds = items.map(i => i.id);
            result = result.filter(i => unitIds.indexOf(i) > -1);
        }
        return result;
    }

    //------------------------------
    //UserCode
    //------------------------------
    static isValidUserCode(code: string): boolean {
        if (!code) return false;
        if (code.length < 8) return false;
        if (/\s/.test(code)) return false; //No whitespaces allowed
        return true;
    }

    //------------------------------
    //Password
    //------------------------------
    //static createDefaultPassword(minPasswordLength: number) {
    //    //const specials = '!@#$%^&*()_+{}:"<>?\|[];\',./`~';
    //    const specials = "_-.";
    //    const lowercase = "abcdefghijkmnopqrstuvwxyz";
    //    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //    const numbers = "123456789";
    //    const all = specials + lowercase + uppercase + numbers;
    //    let password = "";
    //    password += specials.pick(2);
    //    password += lowercase.pick(2);
    //    password += uppercase.pick(2);
    //    password += numbers.pick(2);
    //    password += all.pick(20, 30);
    //    return password.shuffle();
    //}

    static isValidPassword(password: string, minPasswordLength: number): string {
        if (!password || password.length < minPasswordLength) {
            return Translations.PasswordIsTooShort;
        }
        if (!/[A-Z]/.test(password) && !/\u00c4/.test(password) && !/\u00c5/.test(password) && !/\u00c6/.test(password) && !/\u00d6/.test(password)) { //Uppercase letter required
            return Translations.PasswordMustContainUppercaseChar;
        }
        if (!/[a-z]/.test(password) && !/\u00e4/.test(password) && !/\u00e5/.test(password) && !/\u00e6/.test(password) && !/\u00f6/.test(password)) { //Lowercase letter required
            return Translations.PasswordMustContainLowercaseChar;
        }
        if (!/[0-9]/.test(password) && !/[!@#$%^&*()_+{}:"<>?|[\];\\',./`~=]/.test(password)) { //Number or special character required
            return Translations.PasswordMustContainDigitOrSpecialCharacter;
        }
        return "";
    }

    //------------------------------
    //Email
    //------------------------------
    private static getEmailAddresses(addresses: string): string[] {
        if (!addresses) {
            return [addresses ?? ""];
        }
        if (addresses.indexOf(";") > -1) {
            return addresses.split(";");
        }
        if (addresses.indexOf(",") > -1) {
            return addresses.split(",");
        }
        return [addresses];
    }

    //https://stackoverflow.com/questions/2049502/what-characters-are-allowed-in-an-email-address
    //https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
    //https://gist.github.com/gregseth/5582254
    //https://www.regular-expressions.info/email.html
    private static isValidEmailAddressSingle(address: string): boolean {
        //RFC 2822 Simpler version
        if (!address) return true;
        if (/[\u00c4\u00c5\u00c6\u00d6\u00e4\u00e5\u00e6\u00f6]/.test(address)) return false; //Do not allow äåöÄÅÖ
        if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(address)) return false;
        //Office365 (Error: 5.1.6 Recipient addresses in single label domains not accepted): Check single label domains
        //Customer: no two @ marks
        const firstAtIndex = address.indexOf("@");
        const lastAtIndex = address.lastIndexOf("@");
        const dotIndex = address.lastIndexOf(".");
        return lastAtIndex >= 1 && dotIndex >= 3 && dotIndex > lastAtIndex && firstAtIndex === lastAtIndex;
        //return true;
        //RFC 5322
        //if (isNullOrEmpty(address)) return true;
        //if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(address)) return false;
        //return true;
    }

    static isValidEmailAddress(emailAddresses: string): boolean {
        const addresses = Base.getEmailAddresses(emailAddresses);
        for (const address of addresses) {
            if (!Base.isValidEmailAddressSingle(address)) {
                return false;
            }
        }
        return true;
    }

    //------------------------------
    //Bank
    //------------------------------
    static isValidFinnishBicCode(str: string): boolean {
        if (!str) return false;
        const patt = /^([a-zA-Z]){4}FI([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
        return patt.test(str.toUpperCase());
    }

    static replaceSepaCharacters(str: string): string {
        if (!str) return str;
        const chars = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        const codes = ["10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35"];
        let result = "";
        let i;
        for (i = 0; i < str.length; i++) {
            const chr = str.charAt(i);
            const j = chars.indexOf(chr);
            if (j > -1) {
                result = result + codes[j];
            } else {
                result = result + chr;
            }
        }
        return result;
    }

    static getModulo97(str: string): number {
        if (!str) return 0;
        let bigNumber = str;
        let oldModulo = "";
        while (bigNumber.length > 0) {
            const smallNumber = bigNumber.substr(0, Math.min(7, bigNumber.length));
            if (bigNumber.length > 7) {
                bigNumber = bigNumber.substr(7);
            } else {
                bigNumber = "";
            }
            const tempNumber = parseInt(oldModulo + smallNumber, 10);
            oldModulo = (tempNumber % 97).toString();
        }
        return parseInt(oldModulo, 10);
    }

    static isValidIbanNumber(str: string): boolean {
        if (!str) return false;
        let iban = str.toUpperCase();
        if (iban.length > 4) {
            iban = iban.substr(4) + iban.substr(0, 4);
        }
        iban = Base.replaceSepaCharacters(iban).numberString(false);
        return Base.getModulo97(iban) === 1;
    }

    static formatIbanNumber(str: string): string {
        if (!str) return "";
        const temp = str.toUpperCase().replace(/[^0-9A-Z]/g, "");
        let result = "";
        let i: number;
        let j = 0;
        for (i = 0; i < temp.length; i++) {
            result = result + temp.charAt(i);
            if (j === 3) {
                result = result + " ";
                j = 0;
            } else {
                j = j + 1;
            }
        }
        return result.trim();
    }

    //------------------------------
    //CkEditor
    //------------------------------
    static getCultureSimple(culture: string): string {
        if (!culture) return "fi";
        const index = culture.indexOf("-");
        if (index > -1) {
            return culture.substring(0, index);
        }
        return "fi";
    }
    
    static setCkEditorStyles(container: HTMLDivElement, documentMode: boolean) {
        if (!container) return;
        const iframes = container.getElementsByClassName("cke_wysiwyg_frame");
        if (iframes.length < 1) return;
        const iframe = iframes[0] as HTMLIFrameElement;
        if (!iframe.contentDocument) return;
        const html = iframe.contentDocument.documentElement;
        const body = iframe.contentDocument.body;
        if (documentMode) {
            html.style.backgroundColor = "#f8f8f8";
            body.style.fontFamily = "Arial";
            body.style.fontSize = "14px";
            body.style.padding = "20px";
            body.style.maxWidth = "672px";
            body.style.backgroundColor = "#fff";
        } else {
            html.style.backgroundColor = "";
            body.style.fontFamily = "Arial";
            body.style.fontSize = "14px";
            body.style.padding = "";
            body.style.maxWidth = "";
            body.style.backgroundColor = "#";
        }
    }

    //------------------------------
    //Media
    //------------------------------
    static async getCameraIds(): Promise<string[]> {
        if (!(("mediaDevices" in navigator) && ("enumerateDevices" in (<any>navigator).mediaDevices))) {
            return new Promise<string[]>((resolve) => { resolve([]); });
        }
        try {
            const deviceInfos = await (navigator as any).mediaDevices.enumerateDevices();
            const result: string[] = [];
            //console.log("deviceInfos", deviceInfos);
            for (let i = 0; i < deviceInfos.length; i++) {
                if (deviceInfos[i].kind === "video") {
                    deviceInfos[i].kind = "videoinput";
                }
                if (deviceInfos[i].kind === "videoinput") {
                    result.push(deviceInfos[i].deviceId);
                }
            }
            return result;
        } catch (e) {
            console.log("getCameraIds", e);
        }
    }

    static getDataUriSize(dataUri: string): number {
        if (Base.isNullOrEmpty(dataUri)) return 0;
        const byteString = window.atob(dataUri.split(",")[1]);
        return byteString.length;
    }

    static base64ToBlob(base64: string, contentType: string): Blob {
        const byteString = window.atob(base64);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: contentType });
    }

    static dataUriToBlob(dataUri: string): Blob {
        return Base.base64ToBlob(dataUri.split(",")[1], dataUri.split(",")[0].split(":")[1].split(";")[0]);
    }

    static blobToFile(blob: Blob, fileName: string): File {
        const b = <any>blob;
        b.lastModified = (new Date()).getTime();
        b.name = fileName;
        return <File>b;
    }

    static blobToBase64(file: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    }

    static blobToBase64WithoutHeader(file: Blob): Promise<string> {
        return Base.blobToBase64(file)
            .then(dataUri => dataUri.split(",").pop());
    }

    //------------------------------
    //Location
    //------------------------------
    private static googleGeocoder: google.maps.Geocoder = null;

    static getLocation(googleApiKey: string, callback: (latitude: number, longitude: number, locationName: string, positionErrorCode: number) => void) {
        try {
            if ("geolocation" in navigator) {
                (navigator.geolocation as Geolocation).getCurrentPosition((position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    if (!Base.isNullOrUndefined(latitude) && !Base.isNullOrUndefined(longitude) && !isNaN(latitude) && !isNaN(longitude)) {
                        if (!Base.isNullOrEmpty(googleApiKey)) {
                            if (Base.isNullOrUndefined(this.googleGeocoder)) {
                                this.googleGeocoder = new google.maps.Geocoder();
                            }
                            this.googleGeocoder.geocode({ "location": new google.maps.LatLng(latitude, longitude) }, (results, status) => {
                                if (status === google.maps.GeocoderStatus.OK && results.length > 0 && !Base.isNullOrUndefined(results[0])) {
                                    callback(latitude, longitude, results[0].formatted_address, 0);
                                } else {
                                    callback(latitude, longitude, "", 0);
                                    console.log("googleGeocoder.geocode FAILED", status);
                                }
                            });
                        } else {
                            callback(latitude, longitude, "", 0);
                        }
                    }
                }, (error: GeolocationPositionError) => {
                    console.log("getCurrentPosition FAILED", error);
                    callback(null, null, null, error.code);
                }, { enableHighAccuracy: true, timeout: 60000, maximumAge: 0 });
            } else {
                console.log("geolocation DOES NOT exist in navigator");
            }
        } catch (e) {
            console.log("getLocation FAILED! Unknown error", e);
        }
    }

    //REST API CALL CANNOT BE LIMITED BY REFERER
    //static getLocation(googleApiKey: string, culture: string, callback: (latitude: number, longitude: number, locationName: string, positionErrorCode: number) => void) {
    //    if ("geolocation" in navigator) {
    //        (navigator.geolocation as Geolocation).getCurrentPosition((position) => {
    //            const latitude = position.coords.latitude;
    //            const longitude = position.coords.longitude;
    //            if (!Base.isNullOrUndefined(latitude) && !Base.isNullOrUndefined(longitude) && !isNaN(latitude) && !isNaN(longitude)) {
    //                //google api, do not cache
    //                if (!Base.isNullOrEmpty(googleApiKey)) {
    //                    const httpRequest = new XMLHttpRequest();
    //                    const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude.toFixed(6) + "," + longitude.toFixed(6) + "&language=" + culture + "&result_type=street_address&key=" + googleApiKey;
    //                    httpRequest.onreadystatechange = function() {
    //                        if (this.readyState === 4 && this.status === 200) {
    //                            const json = JSON.parse(this.responseText);
    //                            if (json.status === "OK" && json.results.length > 0) {
    //                                callback(latitude, longitude, json.results[0].formatted_address, 0);
    //                            } else {
    //                                callback(latitude, longitude, "", 0);
    //                            }
    //                        }
    //                    };
    //                    httpRequest.open("GET", url, true);
    //                    httpRequest.setRequestHeader("Accept", "application/json");
    //                    httpRequest.send();
    //                } else {
    //                    callback(latitude, longitude, "", 0);
    //                }
    //            }
    //        }, (error: PositionError) => {
    //            console.log("getCurrentPosition FAILED", error);
    //            callback(null, null, null, error.code);
    //        }, { enableHighAccuracy: true, timeout: 60000, maximumAge: 15000 });
    //    }
    //}

    static openLocationInMaps(latitude: number, longitude: number) {
        if (Base.isNullOrUndefined(latitude) || Base.isNullOrUndefined(longitude)) return;
        window.open("https://www.google.com/maps/search/?api=1&query=" + latitude.toFixed(6) + "," + longitude.toFixed(6) + "&zoom=12");
    }

    // ------------------------------
    // List Utils
    // ------------------------------
    static getListItems<T extends IIdItem>(stateItems: T[], stateSelectedId: string, stateCheckedIds: string[], dbItems: T[], resetItems: boolean, refreshList: boolean): { items: T[]; selectedId: string; checkedIds: string[]; } {
        let items: T[];
        if (!resetItems && !refreshList) {
            const oldIds = {};
            for (let j = 0; j < stateItems.length; j++) {
                oldIds[stateItems[j].id] = true;
            }
            const oldItems = stateItems.slice(0);
            const newItems = dbItems.filter(i => Object.prototype.isPrototypeOf.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
            items = [...oldItems, ...newItems];
        } else {
            items = dbItems;
        }
        return {
            items,
            selectedId: items.findIndex(i => i.id === stateSelectedId) > -1 ? stateSelectedId : "",
            checkedIds: items.filter(i => stateCheckedIds.indexOf(i.id) > -1).map(i => i.id)
        };
    }

    static getSelectedIds(selectedId: string, checkedIds: string[]): string[] {
        let result = [];
        if (checkedIds && checkedIds.length > 0) {
            result = checkedIds.slice(0);
        } else if (selectedId) {
            result.push(selectedId);
        }
        return result;
    };

    //------------------------------
    //Json
    //------------------------------
    static isJsonString(str: string): boolean {
        if (Base.isNullOrEmpty(str)) return false;
        try {
            const obj = JSON.parse(str);
            if (obj && typeof obj === "object") {
                return true;
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    //https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    static b64EncodeUnicode(str: string) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode(parseInt("0x" + p1));
            }));
    }

    static debounce<F extends Function>(func:F, wait:number):F {
        let timeoutId:number;
        if (!Number.isInteger(wait)) {
            console.log("Called debounce without a valid number");
            wait = 300;
        }
        // conversion through any necessary as it wont satisfy criteria otherwise
        return <F><any> function(this:any, ...args: any[]) {
            clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                func.apply(this, args);
            }, wait);
        };
    };

    static timeout(wait: number): Promise<void> {
        return new Promise(resolve => window.setTimeout(resolve, wait));
    }

    //------------------------------
    //WebPush
    //------------------------------
    static urlBase64ToUint8Array(base64Str: string): Uint8Array {
        const padding = "=".repeat((4 - base64Str.length % 4) % 4);
        const base64 = (base64Str + padding).replace(/-/g, "+").replace(/_/g, "/");
        const rawData = window.atob(base64);
        const result = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            result[i] = rawData.charCodeAt(i);
        }
        return result;
    }

    //------------------------------
    //Links
    //------------------------------
    static sanitizeHtml(txt: string): string {
        const temp = document.createElement("div");
        temp.textContent = txt;
        return temp.innerHTML;
    }

    //Handles only https-urls and sanitizes content
    static linkifyText(txt: string): string {
        if (Base.isNullOrEmpty(txt)) return "";
        const urlPattern = /\b(?:https):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;
        const temps: string[] = [];
        const links: string[] = [];
        let result = txt.replace(urlPattern, (str) => {
            const replacement = Base.getGuid();
            temps.push(replacement);
            links.push('<a href="' + str + '" target="_blank">' + Base.sanitizeHtml(str) + "</a>");
            return replacement;
        });
        result = Base.sanitizeHtml(result);
        for (let i = 0; i < temps.length; i++) {
            result = result.replace(temps[i], links[i]);
        }
        return result;
    }

    //------------------------------
    //Encryption
    //------------------------------
    static arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    static base64ToArrayBuffer(base64: string): ArrayBuffer {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    static async encryptString(str: string, base64key: string, base64iv: string): Promise<string> {
        const iv = Base.base64ToArrayBuffer(base64iv);
        const algorithm = {
            iv,
            name: "AES-CBC",
        };
        const key = Base.base64ToArrayBuffer(base64key);
        const importedKey = await window.crypto.subtle.importKey("raw", key, algorithm, true, ["encrypt", "decrypt"]);
        const encoder = new TextEncoder();
        const data = encoder.encode(str);
        const encrypted = await window.crypto.subtle.encrypt(
            algorithm,
            importedKey,
            data,
        );
        return Base.arrayBufferToBase64(encrypted);
    }

    static async decryptString(base64str: string, base64key: string, base64iv: string): Promise<string> {
        const iv = Base.base64ToArrayBuffer(base64iv);
        const algorithm = {
            iv,
            name: "AES-CBC",
        };
        const key = Base.base64ToArrayBuffer(base64key);
        const importedKey = await window.crypto.subtle.importKey("raw", key, algorithm, true, ["encrypt", "decrypt"]);
        const data = Base.base64ToArrayBuffer(base64str);
        const decrypted = await window.crypto.subtle.decrypt(
            algorithm,
            importedKey,
            data,
        );
        const decoder = new TextDecoder("utf-8");
        return decoder.decode(decrypted);
    }

    //------------------------------
    //Colors
    //------------------------------
    static black = "#000000";
    static white = "#FFFFFF";
    
    static hexToRgb(hex: string): { r: number, g: number, b: number } {
        if (hex.indexOf("#") === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error("Invalid HEX color.");
        }
        return {
            r: parseInt(hex.slice(0, 2), 16),
            g: parseInt(hex.slice(2, 4), 16),
            b: parseInt(hex.slice(4, 6), 16)
        };
    }

    static invertColor(hex: string, bw = true): string {
        if (!hex) return Base.black;
        const rgb = Base.hexToRgb(hex);
        if (bw) {
            // https://stackoverflow.com/a/3943023/112731
            return (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186
                ? Base.black
                : Base.white;
        }
        // pad each with zeros and return
        return "#" + Base.padZeros((255 - rgb.r).toString(16), 2) + Base.padZeros((255 - rgb.g).toString(16), 2) + Base.padZeros((255 - rgb.b).toString(16), 2);
    }

    static isWhite(hex: string): boolean {
        console.log("hex", hex);
        if (!hex) return false;
        const rgb = Base.hexToRgb(hex);
        console.log("rgb", rgb);
        return rgb.r > 254 && rgb.g > 254 && rgb.b > 254;
    }
}

//***********************************************************************
//Moment locale FI import from moment/locale/fi did not work
//***********************************************************************
const numbersPast = "nolla yksi kaksi kolme neljä viisi kuusi seitsemän kahdeksan yhdeksän".split(" ");
const numbersFuture = [
    "nolla", "yhden", "kahden", "kolmen", "neljän", "viiden", "kuuden",
    numbersPast[7], numbersPast[8], numbersPast[9]
];
function translate(number, withoutSuffix, key, isFuture) {
    let result = "";
    switch (key) {
    case "s":
        return isFuture ? "muutaman sekunnin" : "muutama sekunti";
    case "ss":
        return isFuture ? "sekunnin" : "sekuntia";
    case "m":
        return isFuture ? "minuutin" : "minuutti";
    case "mm":
        result = isFuture ? "minuutin" : "minuuttia";
        break;
    case "h":
        return isFuture ? "tunnin" : "tunti";
    case "hh":
        result = isFuture ? "tunnin" : "tuntia";
        break;
    case "d":
        return isFuture ? "päivän" : "päivä";
    case "dd":
        result = isFuture ? "päivän" : "päivää";
        break;
    case "M":
        return isFuture ? "kuukauden" : "kuukausi";
    case "MM":
        result = isFuture ? "kuukauden" : "kuukautta";
        break;
    case "y":
        return isFuture ? "vuoden" : "vuosi";
    case "yy":
        result = isFuture ? "vuoden" : "vuotta";
        break;
    }
    result = verbalNumber(number, isFuture) + " " + result;
    return result;
}
function verbalNumber(number, isFuture) {
    return number < 10 ? (isFuture ? numbersFuture[number] : numbersPast[number]) : number;
}
moment.defineLocale("fi", {
    months: "tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu".split("_"),
    monthsShort: "tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu".split("_"),
    weekdays: "sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai".split("_"),
    weekdaysShort: "su_ma_ti_ke_to_pe_la".split("_"),
    weekdaysMin: "su_ma_ti_ke_to_pe_la".split("_"),
    longDateFormat: {
        LT: "HH.mm",
        LTS: "HH.mm.ss",
        L: "DD.MM.YYYY",
        LL: "Do MMMM[ta] YYYY",
        LLL: "Do MMMM[ta] YYYY, [klo] HH.mm",
        LLLL: "dddd, Do MMMM[ta] YYYY, [klo] HH.mm",
        l: "D.M.YYYY",
        ll: "Do MMM YYYY",
        lll: "Do MMM YYYY, [klo] HH.mm",
        llll: "ddd, Do MMM YYYY, [klo] HH.mm"
    },
    calendar: {
        sameDay: "[tänään] [klo] LT",
        nextDay: "[huomenna] [klo] LT",
        nextWeek: "dddd [klo] LT",
        lastDay: "[eilen] [klo] LT",
        lastWeek: "[viime] dddd[na] [klo] LT",
        sameElse: "L"
    },
    relativeTime: {
        future: "%s päästä",
        past: "%s sitten",
        s: translate,
        ss: translate,
        m: translate,
        mm: translate,
        h: translate,
        hh: translate,
        d: translate,
        dd: translate,
        M: translate,
        MM: translate,
        y: translate,
        yy: translate
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: (n: number) => { return n + "."; },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
});