import * as React from "react";
import { Translations } from "ui/Scripts/source/models/translations";
import { IWhistleBlowInformantMessage } from "ui/Scripts/source/models/whistleBlow/whistleBlowInformantMessage";
import * as whistleBlowService from "../../../models/services/whistleBlowService";
import { AlertBox } from "../../framework/alertBox";
import { WhistleBlowMessageListLine } from "../WhistleBlowMessageListLine";
import { WhistleBlowMessageListNew } from "../WhistleBlowMessageListNew";
import * as Styled from "./WhistleBlowMessageList.styles";

//WhistleBlowMessageList
export interface IWhistleBlowMessageListProp {
    classes?: string;
    companyId: string;
    companyName: string;
    whistleBlowId: string;
    maxAttachmentsTotalSize: number;
    messages: IWhistleBlowInformantMessage[];
    onShowCamera: (value: boolean) => void;
    onWhistleBlowMessageSaved: (item: IWhistleBlowInformantMessage) => void;
    onWhistleBlowMessageIsEmpty: (value: boolean) => void;
}

interface IWhistleBlowMessageListState {
    selectedId: string;
    observer: IntersectionObserver;
}

export const WhistleBlowMessageList = ({ companyId, companyName, maxAttachmentsTotalSize, messages, onShowCamera, onWhistleBlowMessageIsEmpty, onWhistleBlowMessageSaved, whistleBlowId, classes }: IWhistleBlowMessageListProp) => {
    const containerDiv = React.useRef<HTMLDivElement>(null);
    let readReceiptProcessingIds: string[] = [];
    const readReceiptProcessedIds: string[] = [];

    const [state, setState] = React.useState<IWhistleBlowMessageListState>({
        selectedId: null,
        observer: null
    });

    const intersectionCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        for (const entry of entries) {
            if (!entry.isIntersecting || entry.intersectionRatio < 0.75) continue;
            const line = entry.target as HTMLDivElement;
            if (!line) continue;
            //line.style.backgroundColor = "#FFD800"
            const id = line.getAttribute("data-id");
            if (!id) return;
            const item = messages.find(i => i.id === id);
            if (!item || item.myMessage || item.isRead) continue;
            const processing = readReceiptProcessingIds.indexOf(id) > -1;
            if (processing) continue;
            const processed = readReceiptProcessedIds.indexOf(id) > -1;
            if (processed) continue;
            readReceiptProcessingIds.push(id);
            whistleBlowService.sendWhistleBlowMessageReadReceipts(companyId, [id])
                .then(result => {
                    if (result) {
                        readReceiptProcessedIds.push(id);
                    }
                })
                .finally(() => {
                    readReceiptProcessingIds = readReceiptProcessingIds.filter(i => i !== id);
                });
        }
    };

    const createObserver = (): IntersectionObserver => {
        const observer = new IntersectionObserver(intersectionCallback, {
            root: containerDiv.current,
            rootMargin: "0px",
            threshold: 0.9
        });
        setState((old) => ({
            ...old,
            observer
        }));
        return observer;
    };

    const removeObserver = () => {
        if (!state.observer) return;
        state.observer.disconnect();
        setState((old) => ({
            ...old,
            observer: null
        }));
    };

    const scrollToBottom = () => {
        containerDiv.current.scrollTo({
            top: containerDiv.current.scrollHeight,
            left: 0,
            behavior: "auto"
        });
    };

    React.useEffect(() => {
        createObserver();
        scrollToBottom();

        return () => {
            removeObserver();
        };
    }, []);

    const handleMessageClick = (id: string) => {
        setState((old) => ({
            ...old,
            selectedId: id
        }));
    };

    const handleWhistleBlowMessageSaved = (item: IWhistleBlowInformantMessage) => {
        onWhistleBlowMessageSaved(item);
        window.setTimeout(() => {
            scrollToBottom();
        }, 50);
    };

    return (
        <Styled.Container display="flex" flexDirection="column">
            <Styled.MessageContainer display="flex" flexDirection="column" ref={containerDiv}>
                {messages.length < 1 &&
                    <AlertBox
                        info={true}
                        message={Translations.WhistleBlowDoesNotContainAnyMessage}
                        onClick={null}
                    />
                }
                {messages.map((message) =>
                    <WhistleBlowMessageListLine
                        key={message.id}
                        companyId={companyId}
                        companyName={companyName}
                        whistleBlowId={whistleBlowId}
                        observer={state.observer}
                        message={message}
                        selectedId={state.selectedId}
                        onClick={handleMessageClick}
                    />
                )}
            </Styled.MessageContainer>
            <WhistleBlowMessageListNew
                companyId={companyId}
                whistleBlowId={whistleBlowId}
                maxAttachmentsTotalSize={maxAttachmentsTotalSize}
                onShowCamera={onShowCamera}
                onWhistleBlowMessageSaved={handleWhistleBlowMessageSaved}
                onWhistleBlowMessageIsEmpty={onWhistleBlowMessageIsEmpty}
            />
        </Styled.Container>
    );
};