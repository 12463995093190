import styled from "@emotion/styled";
import { FileType } from "ui/Scripts/source/models/enums";

export const Container = styled.div`
    position: relative;
`;

const getBannerColor = (status: FileType) => {
    switch (status) {
    case FileType.Pdf:
        return "#D03E3E";
    case FileType.Image:
        return "#156DEE";
    default:
        return "#0A823A";
    }
};

export const Label = styled.div<{ fileType: FileType }>`
    position: absolute;
    width: 22px;
    height: 10px;
    top: 8px;
    font-size: 10px;
    line-height: 11px;
    color: #fff;
    text-align: center;
    background: ${({ fileType }) => getBannerColor(fileType)}
`;
