import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfirmationDialogType, IConfirmationSet, IConfirmationState } from "../../storeTypes";
import * as storeTypes from "../../storeTypes";

const initialState: IConfirmationState = {
    type: ConfirmationDialogType.Warning,
    title: null,
    text: null,
    show: false,
    yesConfirmationText: null,
    onYes: null,
    onNo: null,
    onCancel: null
};

const confirmationSlice = createSlice({
    name: "confirmation",
    initialState,
    reducers: {
        confirmationSet(state: IConfirmationState, action: PayloadAction<IConfirmationSet>) {
            state.type = action.payload.dialogType;
            state.title = action.payload.title;
            state.text = action.payload.text;
            state.show = action.payload.show;
            state.onYes = action.payload.onYes;
            state.onNo = action.payload.onNo;
            state.onCancel = action.payload.onCancel;
            state.yesConfirmationText = action.payload.yesConfirmationText;
        },
        confirmationCleared(state: IConfirmationState, _action: PayloadAction<void>) {
            state.show = false;
        },
    },
});

// Confirmation
export const setConfirmation = (type: storeTypes.ConfirmationDialogType, title: string, text: string, onYes: storeTypes.IConfirmationCallback, onNo: storeTypes.IConfirmationCallback, onCancel: storeTypes.IConfirmationCallback = null, yesConfirmationText: string = null): storeTypes.IConfirmationSet => ({
    type: storeTypes.CONFIRMATION_SET,
    dialogType: type,
    title,
    text,
    show: true,
    yesConfirmationText,
    onYes,
    onNo,
    onCancel
});

export const { confirmationSet, confirmationCleared } = confirmationSlice.actions;

export default confirmationSlice.reducer;
