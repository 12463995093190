import AddIcon from "@mui/icons-material/Add";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Box, Link, SvgIcon, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Translations } from "ui/Scripts/source/models/translations";
import { AppUtils } from "../../framework/appUtils";
import { IFrontPage } from "../../models/front/frontPage";
import { AppDispatch, RootState } from "../../models/store";
// import * as storeEffects from "../../models/store/storeEffects";
import { getFront } from "../../models/store/features/front/frontSlice";
import { ErrorView } from "../framework/ErrorView";
import { OpenWhistleBlowDialog } from "../whistleBlow/OpenWhistleBlowDialog";
import * as Styled from "./Front.styles";

/* eslint-disable no-unused-vars */
export const enum FrontPageType {
    Info = "0",
    Instructions = "1"
}
/* eslint-enable no-unused-vars */

// Front
export interface IFrontStateProps {
    dataId: number;
    frontPage: IFrontPage
}

interface IFrontState {
    activeFrontPage: FrontPageType;
    showOpenWhistleBlowDialog: boolean;
}

export const Front = () => {
    const { pageName } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const frontPage = useSelector((state: RootState) => state.front.frontPage);
    const dataId = useSelector((state: RootState) => state.front.dataId);
    
    const [state, setState] = useState<IFrontState>({
        activeFrontPage: FrontPageType.Info,
        showOpenWhistleBlowDialog: false
    });
    
    useEffect(() => {
        AppUtils.clearInfoMessage();
        dispatch(getFront());
    }, []);

    const handleChangePage = (_event: React.SyntheticEvent, activeFrontPage: FrontPageType) => {
        setState((oldState) => ({ ...oldState, activeFrontPage }));
    };

    const handleCreateWhistleBlowReport = () => {
        navigate("/" + pageName + "/new");
    };

    const handleFollowWhistleBlowReport = () => {
        setState((oldState) => ({
            ...oldState,
            showOpenWhistleBlowDialog: true
        }));
    };

    const handleOpenWhistleBlowDialogOk = () => {
        setState((oldState) => ({
            ...oldState,
            showOpenWhistleBlowDialog: false
        }));
        navigate("/" + pageName + "/open");
    };

    const handleOpenWhistleBlowDialogCancel = () => {
        setState((oldState) => ({
            ...oldState,
            showOpenWhistleBlowDialog: false
        }));
    };

    if (!frontPage) {
        return (
            <>{dataId > 0 && <ErrorView title={Translations.WhistleBlowChannelWasNotFound} subtitle={Translations.WhistleBlowChannelWasNotFoundExplanation} />}</>
        );
    }
    const language = frontPage.languages.find(i => i.cultureCode === appConfig.culture) ?? frontPage.languages[0];
    const hasPages = !!language.pageInfo;
    
    return (
        <>
            <Styled.Container>
                <Styled.ContentContainer>
                    {hasPages &&
                        <Tabs value={state.activeFrontPage} onChange={handleChangePage} aria-label="tabs">
                            <Tab label={Translations.WhistleBlowChannel} value={FrontPageType.Info} icon={<HomeOutlinedIcon fontSize="small" />} iconPosition="start" />
                            <Tab label={Translations.WhistleBlowInformantInstuctions} value={FrontPageType.Instructions} icon={<FileCopyOutlinedIcon fontSize="small" />} iconPosition="start" />
                        </Tabs>
                    }
                    <Styled.Scrollable elevation={3}>
                        <Styled.TabContent dangerouslySetInnerHTML={{ __html: state.activeFrontPage === FrontPageType.Info || !hasPages ? language.pageText : language.pageInfo }}></Styled.TabContent>
                    </Styled.Scrollable>
                </Styled.ContentContainer>
                <Styled.ButtonContainer>
                    <Styled.Button onClick={handleCreateWhistleBlowReport} variant="contained"startIcon={<AddIcon />}>
                        {Translations.WhistleBlowNewReport}
                    </Styled.Button>
                    <Styled.ButtonOutlined onClick={handleFollowWhistleBlowReport} variant="outlined">
                        {Translations.WhistleBlowFollowReport}
                    </Styled.ButtonOutlined>
                    {frontPage.privacyPolicyUrl && (
                        <Link href={frontPage?.privacyPolicyUrl} target="_blank" rel="noopener noreferrer" variant="body2">
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <SvgIcon style={{ marginRight: "8px" }}><LinkOutlinedIcon /></SvgIcon>
                                {Translations.PrivacyPolicy}
                            </Box>
                        </Link>
                    )}
                </Styled.ButtonContainer>
            </Styled.Container>
            <OpenWhistleBlowDialog open={state.showOpenWhistleBlowDialog} companyId={frontPage.companyId} onOk={handleOpenWhistleBlowDialogOk} onCancel={handleOpenWhistleBlowDialogCancel} />
        </>
    );
};