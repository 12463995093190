import { ApiSuccess } from "ui/Scripts/source/models/common/apiSuccess";
import { FrontPage } from "../front/frontPage";
import { postEncApiCallInitial, postEncApiCall } from "./baseService";

export interface IOtpSuccess {
    otp: string;
}

export class OtpSuccess implements IOtpSuccess {
    otp: string;

    constructor();
    constructor(obj: IOtpSuccess);
    constructor(obj?: any) {
        this.otp = obj && obj.otp || "";
    }
}

export const setCulture = (culture: string): Promise<ApiSuccess> => {
    return postEncApiCall("api/front/setculture", ApiSuccess, {
        culture
    });
};

export const getFrontPage = (): Promise<FrontPage> => {
    return postEncApiCallInitial<FrontPage>("api/front", FrontPage);
};
