import downloadjs from "downloadjs";
import * as React from "react";
import { AttachmentIcon } from "../../../components/framework/AttachmentIcon";
import { Base } from "ui/Scripts/source/framework/base";
import { IFileAttachment } from "ui/Scripts/source/models/common/fileAttachment";
import { Translations } from "ui/Scripts/source/models/translations";
import { IWhistleBlowInformantMessage } from "ui/Scripts/source/models/whistleBlow/whistleBlowInformantMessage";
import { AppUtils } from "../../../framework/appUtils";
import * as whistleBlowService from "../../../models/services/whistleBlowService";
import * as Styled from "./WhistleBlowMessageListLine.styles";

// WhistleBlowMessageListLineAttachmentImage
//export interface IWhistleBlowMessageListLineAttachmentImageProp {
//    attachment: IFileAttachment;
//    selectedId: string;
//    onClick: (id: string) => void;
//}

//export class WhistleBlowMessageListLineAttachmentImage extends React.Component<IWhistleBlowMessageListLineAttachmentImageProp, {}> {
//    render() {
//        const props = this.props;
//        const attachment = props.attachment;
//        return (
//            <div className={"attachmentImage" + (attachment.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(attachment.id); }} title={attachment.name + " (" + Base.intToFileSizeStr(attachment.size, false) + ", " + Base.timeToDateStr(attachment.modifyDate) + ")"}>
//                <img src={attachment.thumbnail} alt={attachment.name}/>
//            </div>
//        );
//    }
//}

// WhistleBlowMessageListLineAttachment
export interface IWhistleBlowMessageListLineAttachmentProp {
    attachment: IFileAttachment;
    selectedId: string;
    onClick: (id: string) => void;
}

export const WhistleBlowMessageListLineAttachment = ({ attachment, onClick, selectedId }: IWhistleBlowMessageListLineAttachmentProp) => {
    return (
        <Styled.MessageAttachment display="flex" alignItems="center" onClick={(e) => { e.stopPropagation(); onClick(attachment.id); }} title={attachment.name + " (" + Base.intToFileSizeStr(attachment.size, false) + ", " + Base.timeToDateStr(attachment.modifyDate) + ")"}>
            <AttachmentIcon fileType={attachment.type} color="#E9F0FA" strokeColor="#848688" />
            <Styled.AttachmentTitle variant="caption">{attachment.name + " (" + Base.intToFileSizeStr(attachment.size, false) + ")"}</Styled.AttachmentTitle>
        </Styled.MessageAttachment>
    );
};

//WhistleBlowMessageListLine
export interface IWhistleBlowMessageListLineProp {
    companyId: string;
    companyName: string;
    whistleBlowId: string;
    observer: IntersectionObserver;
    message: IWhistleBlowInformantMessage;
    selectedId: string;
    onClick: (id: string) => void;
}

export const WhistleBlowMessageListLine = ({ companyId, companyName, message, observer, onClick, selectedId, whistleBlowId }: IWhistleBlowMessageListLineProp) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const registerToObserver = () => {
        if (!observer || !ref.current || !message || message.myMessage) return;
        observer.observe(ref.current);
    };

    const unregisterFromObserver = () => {
        if (!observer || !ref.current) return;
        observer.unobserve(ref.current);
    };

    React.useEffect(() => {
        registerToObserver();

        return () => {
            unregisterFromObserver();
        };
    }, []);

    const handleAttachmentClick = async (id: string) => {
        if (!id) return;
        const attachment = message.attachments.find(i => i.id === id);
        if (!attachment) return;
        if (attachment.file) {
            downloadjs(attachment.file, attachment.name);
            return;
        }
        if (attachment?.isNew && attachment?.isNew()) return;
        const fileName = await AppUtils.callService(() => whistleBlowService.getAttachment(companyId, whistleBlowId, message.id, id));
        if (!fileName) return;
        AppUtils.showSuccessMessage(String.format(Translations.AttachmentParameterDownloaded, fileName));
    };
    
    //const imageAttachments = message.attachments.filter(i => i.type === FileType.Image);
    //const fileAttachments = message.attachments.filter(i => i.type !== FileType.Image);
    return (
        <Styled.Message message={message.myMessage ? "true" : "false"} ref={ref} display="flex" data-id={message.id}>
            <Styled.MessageBox message={message.myMessage ? "true" : "false"} display="flex" flexDirection="column" onClick={(e) => { e.stopPropagation(); onClick(message.id); }}>
                {/*{imageAttachments.length > 0 &&*/}
                {/*    <div className="wbAttachmentImages">*/}
                {/*        {imageAttachments.map((attachment) =>*/}
                {/*            <WhistleBlowMessageListLineAttachmentImage*/}
                {/*                key={attachment.id}*/}
                {/*                attachment={attachment}*/}
                {/*                selectedId={""}*/}
                {/*                onClick={this.handleAttachmentClick}*/}
                {/*            />*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*}*/}
                {message.myMessage ? (<Styled.MessageTipRight />) : (<Styled.MessageTipLeft />)}
                {message.attachments.length > 0 &&
                    <div className="wbAttachments">
                        {message.attachments.map((attachment) =>
                            <WhistleBlowMessageListLineAttachment
                                key={attachment.id}
                                attachment={attachment}
                                selectedId={""}
                                onClick={() => handleAttachmentClick(attachment.id)}
                            />
                        )}
                    </div>
                }
                <Styled.Sender variant="subtitle2">{message.myMessage ? Translations.WhistleBlowInformant : companyName}</Styled.Sender>
                <Styled.MessageText variant="body2">{message.content}</Styled.MessageText>
                <Styled.Timestamp variant="caption">{Base.timeToDateTimeStr(message.date)}</Styled.Timestamp>
            </Styled.MessageBox>
        </Styled.Message>
    );
};