/* global HeadersInit, RequestInit */
import { Base } from "ui/Scripts/source/framework/base";
import { ApiError, IApiError } from "ui/Scripts/source/models/common/apiError";
import downloadjs from "downloadjs";
import { AppUtils } from "../../framework/appUtils";
import { EncryptedRequest } from "ui/Scripts/source/models/common/encryptedRequest";
import { EncryptedResponse } from "ui/Scripts/source/models/common/encryptedResponse";
import { FileDataUrlResponse } from "ui/Scripts/source/models/common/fileDataUrlResponse";
import { IHasEData } from "ui/Scripts/source/models/common/eData";

export function isInstanceOfIApiError(object: any): object is IApiError {
    return object && !(typeof(object) === "string") && "status" in object && "message" in object && typeof(object.status) === "number" && typeof(object.message) === "string";
}

export function getErrorMessageFromError(error: any): string {
    if (isInstanceOfIApiError(error)) {
        return error.message;
    } else {
        return error;
    }
}

function getErrorMessageFromEncryptedJson(json: any): Promise<string> {
    const eData = AppUtils.getEData();
    if (!eData) return Base.getPromiseResult("");
    const encResponse = new EncryptedResponse(json);
    if (!encResponse.d) {
        return Base.getPromiseResult("");
    }
    return encResponse.getDecryptedObject(eData, ApiError)
        .then(apiError => {
            return apiError?.message;
        })
        .catch((e) => {
            console.log("error", e);
            return "";
        });
}

function getErrorMessageFromBody(text: string): Promise<string> {
    if (Base.isJsonString(text)) {
        const json = JSON.parse(text);
        return getErrorMessageFromEncryptedJson(json)
            .then(message => {
                if (message) return message;
                return !Base.isNullOrEmpty(json.message) ? json.message : (!Base.isNullOrEmpty(json.Message) ? json.Message : text);
            });
    } else {
        return Base.getPromiseResult(text);
    }
}

function getJsonHeaders(body: string, customHeaders: any): HeadersInit {
    let result = {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=utf-8",
        "Content-Length": (body ? body.length : 0).toString(10)
    };
    if (customHeaders) {
        result = { ...result, ...customHeaders };
    }
    return result;
}

function getFormDataHeaders(customHeaders: any): HeadersInit {
    let result = {
        "Accept": "application/json"
    };
    if (customHeaders) {
        result = { ...result, ...customHeaders };
    }
    return result;
}

export function getJsonRequestInit(signal: AbortSignal = null, method: string = "GET", body: string = null, customHeaders: any = null): RequestInit {
    return body
        ? {
            headers: getJsonHeaders(body, customHeaders),
            body,
            method,
            signal
        }
        : {
            headers: getJsonHeaders(null, customHeaders),
            method,
            signal
        };
}

export function getFormDataRequestInit(signal: AbortSignal = null, method: string = "GET", body: FormData = null, customHeaders: any = null): RequestInit {
    return {
        headers: getFormDataHeaders(customHeaders),
        body,
        method,
        signal
    };
}

export function handleResponse(response: Response): any {
    console.log(response);
    return new Promise<void>((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message
            const error = new ApiError();
            error.status = response.status;
            response.text().then(text => {
                getErrorMessageFromBody(text)
                    .then(message => {
                        error.message = message;
                        reject(error);
                    });
            });
        }
    });
}

export function handleError(error) {
    console.log(error);
    const isAborted = error && error.name === "AbortError";
    return Promise.reject(isAborted ? null : error && error.message);
}

// #region AbortManager
class AbortItem {
    id: string;
    controller: AbortController;

    constructor() {
        this.id = Base.getGuid();
        this.controller = new AbortController();
    }
}

class AbortManager {
    items: AbortItem[];

    constructor() {
        this.items = [];
    }
    
    get(): AbortItem {
        const result = new AbortItem();
        this.items.push(result);
        return result;
    }

    remove(id: string) {
        this.items = this.items.filter(i => i.id !== id);
    }

    abort(id: string) {
        if (!id) return;
        const item = this.items.find(i => i.id !== id);
        if (!item) return;
        item.controller.abort();
    }

    abortAll() {
        for (const item of this.items) {
            item.controller.abort();
        }
    }

    clean() {
        this.items = [];
    }
}

const abortManager = new AbortManager();

export const abortRequest = (abortId: string) => {
    abortManager.abort(abortId);
};
// #endregion AbortManager

export const postEncApiCallInitial = async <T extends IHasEData>(url: string, type: (new (...args: any[]) => T), obj: any = null, customHeaders: any = null, setAbortItem: (id: string) => void = null): Promise<T> => {
    const item = abortManager.get();
    if (setAbortItem) {
        setAbortItem(item.id);
    }
    try {
        const json = await handleResponse(await fetch(appConfig.rootUrl + url, getJsonRequestInit(item.controller.signal, "POST", JSON.stringify(obj), customHeaders)));
        const encResponse = new EncryptedResponse(json);
        const result = await encResponse.getDecryptedObject(encResponse.eData, type);
        result.eData = encResponse.eData;
        //console.log("result", result)
        return result;
    } catch (e) {
        return handleError(e);
    } finally {
        abortManager.remove(item.id);
    }
};

export const postEncApiCall = async <T>(url: string, type: (new (...args: any[]) => T), obj: any = null, customHeaders: any = null, setAbortItem: (id: string) => void = null): Promise<T> => {
    const eData = AppUtils.getEData();
    if (!eData) return null;
    const item = abortManager.get();
    if (setAbortItem) {
        setAbortItem(item.id);
    }
    try {
        //console.log("obj", obj)
        const encRequest = await EncryptedRequest.createEncryptedRequest(eData, obj);
        //console.log("encRequest", encRequest)
        const json = await handleResponse(await fetch(appConfig.rootUrl + url, getJsonRequestInit(item.controller.signal, "POST", JSON.stringify(encRequest), customHeaders)));
        const encResponse = new EncryptedResponse(json);
        //console.log("encResponse", encResponse)
        const result = await encResponse.getDecryptedObject(eData, type);
        //console.log("result", result)
        return result;
    } catch (e) {
        return handleError(e);
    } finally {
        abortManager.remove(item.id);
    }
};

export const postEncFileApiCall = async (url: string, obj: any = null, customHeaders: any = null, setAbortItem: (id: string) => void = null): Promise<string> => {
    const response = await postEncApiCall(url, FileDataUrlResponse, obj, customHeaders, setAbortItem);
    if (response.isSuccess()) {
        downloadjs(Base.dataUriToBlob(response.dataUrl), response.name, "application/octet-stream");
        return response.name;
    }
    return Promise.reject(response.errorMessage);
};
