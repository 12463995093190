export interface IFrontPageLanguageData {
    cultureCode: string;
    pageText: string;
    pageInfo: string;
}

export class FrontPageLanguageData implements IFrontPageLanguageData {
    cultureCode: string;
    pageText: string;
    pageInfo: string;

    constructor();
    constructor(obj: IFrontPageLanguageData);
    constructor(obj?: any) {
        this.cultureCode = obj && obj.cultureCode || "";
        this.pageText = obj && obj.pageText || "";
        this.pageInfo = obj && obj.pageInfo || "";
    }
}