import styled from "@emotion/styled";
import { TextField, Typography } from "@mui/material";

export const Label = styled(Typography)`
    margin-bottom: 1rem;
    min-width: 500px;
`;

export const PasswordInput = styled(TextField)`
    margin-top: 8px;
    width: 100%;
`;