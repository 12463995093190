import styled from "@emotion/styled";
import { ListItem, Button as MuiButton, Typography } from "@mui/material";

export const FileDropzone = styled.div`
    width: 100%;
    padding: 2rem 1rem 1.5rem 1rem;
    background: #F1F7FF;
    border: 2px dashed #7197CD;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const Title = styled(Typography)`
    font-weight: 600;
`;

export const Subtitle = styled(Typography)`
    margin: 1rem 0;
    color: #667382;
`;

export const Button = styled(MuiButton)`
    background: #fff;
`;

export const FileInfo = styled(Typography)`
    margin-left: .5rem;
    color: #71839B;
`;

export const AttachmentListItem = styled(ListItem)`
    padding: 0;
`;