import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorView } from "source/components/framework/ErrorView";
import { Base } from "ui/Scripts/source/framework/base";
import { FileAttachment, IFileAttachment } from "ui/Scripts/source/models/common/fileAttachment";
import { SaveAttachmentBase64 } from "ui/Scripts/source/models/common/saveAttachmentBase64";
import { Translations } from "ui/Scripts/source/models/translations";
import { WhistleBlowSaveSuccess } from "ui/Scripts/source/models/whistleBlow/whistleBlowSaveSuccess";
import { AppUtils } from "../../../framework/appUtils";
import * as whistleBlowService from "../../../models/services/whistleBlowService";
import { AppDispatch, RootState } from "../../../models/store";
import { getFront } from "../../../models/store/features/front/frontSlice";
import { FormField } from "../../framework/FormField";
import { WhistleBlowAttachmentList } from "../WhistleBlowAttachmentList";
import * as Styled from "./NewWhistleBlow.styles";

// NewWhistleBlow
interface INewWhistleBlowState {
    anonymous: boolean;
    attachments: IFileAttachment[];
    cameraVisible: boolean;
    //After save
    showInformantPassword: boolean;
    informantPassword: string;
    informantPasswordHasBeenStored: boolean;
}

interface IWhistleBlowFormData {
    title: string;
    description: string;
    date: number;
    time: number;
    informantName: string;
    informantEmail: string;
    informantPhone: string;
    attachments: SaveAttachmentBase64[];
}

export const NewWhistleBlow = () => {
    const { pageName } = useParams();
    const navigate = useNavigate();
    const passwordInput = React.useRef<HTMLInputElement>();
    const frontPage = useSelector((state: RootState) => state.front.frontPage);
    const dataId = useSelector((state: RootState) => state.front.dataId);
    const dispatch = useDispatch<AppDispatch>();

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<IWhistleBlowFormData>();

    const activeCultureSimple = Base.getCultureSimple(appConfig.culture);

    const attachments = React.useRef<FileAttachment[]>([]);
    
    const [state, setState] = React.useState<INewWhistleBlowState>({
        anonymous: true,
        attachments: [],
        cameraVisible: false,
        //After save
        showInformantPassword: false,
        informantPassword: "",
        informantPasswordHasBeenStored: false
    });

    React.useEffect(() => {
        dispatch(getFront());
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    // #region Attachments
    const handleAttachmentsRemoved = (ids: string[]) => {
        if (!ids || ids.length < 1) return;
        attachments.current = attachments.current.filter(i => ids.indexOf(i.id) < 0);
        setState((old) => ({ ...old, attachments: attachments.current.filter(i => ids.indexOf(i.id) < 0) }));
    };

    const handleAttachmentsAdded = (attach: IFileAttachment[]) => {
        if (!attach || attach.length < 1) return;
        const newAttachments = attachments.current.concat(AppUtils.filterAttachmentDuplicates(attachments.current, attach));
        if (!AppUtils.validateFileAttachments(newAttachments, Translations.MaximumTotalSizeOfAttachmentsIsParameterMB, appConfig.maxFileUploadSize)) return;
        FileAttachment.sortFileAttachments(newAttachments);
        attachments.current = newAttachments;
        setState((old) => ({ ...old, attachments: newAttachments }));
    };
    // #endregion Attachments

    const onSubmit: SubmitHandler<IWhistleBlowFormData> = async (data) => {
        const result = await saveToDb(data);
        if (!result) return;
        setState((old) => ({
            ...old,
            showInformantPassword: true,
            informantPassword: result.informantPassword
        }));
    };

    const saveToDb = async (data: IWhistleBlowFormData): Promise<WhistleBlowSaveSuccess> => {
        const dateTime = Base.getDateTime(Base.timeToDateStr(data.date), Base.timeToTimeStr(data.time)).getTime();
        if (dateTime > Date.now()) {
            setError("date", { message: Translations.WhistleBlowDateAndTimeCannotBeInFuture });
            setError("time", { message: Translations.WhistleBlowDateAndTimeCannotBeInFuture });
            return;
        }
        //Call server
        const request = {
            companyId: frontPage.companyId,
            date: dateTime,
            title: data.title,
            description: data.description,
            anonymous: state.anonymous,
            informantName: data.informantName,
            informantEmail: data.informantEmail,
            informantPhone: data.informantPhone,
            attachments: await SaveAttachmentBase64.fromFileAttachments(attachments.current.filter(i => !!i.file))
        };

        const result = await AppUtils.callService(() => whistleBlowService.saveWhistleBlow(request));
        if (!result) return null;
        return result;
    };

    const handleCancelWhistleBlowReport = () => {
        navigate("/" + pageName);
    };

    const handleWhistleBlowConfirmPassword = async () => {
        const result = await AppUtils.callService(() => whistleBlowService.confirmWhistleBlowPassword(frontPage.companyId, state.informantPassword));
        if (!result) return null;
        AppUtils.showSuccessMessage(Translations.WhistleBlowPasswordConfirmed);
        navigate("/" + pageName);
    };

    // #region InformantPassword
    const handleCopyInformantPasswordToClipboard = () => {
        if (!passwordInput.current) return;
        navigator.clipboard.writeText(state.informantPassword);
        AppUtils.showInfoMessage(Translations.PasswordHasBeenCopiedToClipboard);
    };
    // #enndregion InformantPassword

    const getLanguageLocale = (culture: string) => {
        switch (culture) {
        case "fi":
            return "fi";
        case "en":
        case "en-US":
            return "en";
        case "sv":
            return "se";
        }
    };

    if (!frontPage) {
        return (
            <>
                {dataId > 0 && (
                    <ErrorView title={Translations.WhistleBlowChannelWasNotFound} subtitle={Translations.WhistleBlowChannelWasNotFoundExplanation}>
                        <Button
                            variant="outlined"
                            onClick={handleCancelWhistleBlowReport}
                        >{Translations.Close}</Button>
                    </ErrorView>
                )}
            </>
        );
    }
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={getLanguageLocale(activeCultureSimple)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Styled.Container elevation={3}>
                    {!state.showInformantPassword &&
                    <div>
                        {!state.cameraVisible &&
                            <>
                                <Typography variant="h5">{Translations.WhistleBlowNewReport}</Typography>
                                <Typography variant="body2" marginTop={".5rem"}>{Translations.WhistleBlowInformantHint}</Typography>
                                <Styled.InfromatioBox display="flex" alignItems="center">
                                    <InfoOutlinedIcon color="info" />
                                    <Typography variant="body2" marginLeft={"1rem"}>{Translations.WhistleBlowInformantButtonHint}</Typography>
                                </Styled.InfromatioBox>
                                <FormField title={Translations.WhistleBlowInformantTitle} required error={errors?.title ? errors.title.message : null}>
                                    <Controller
                                        name="title"
                                        defaultValue=""
                                        control={control}
                                        rules={{ required: Translations.TitleMustBeDefined }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField size="small" onChange={onChange} value={value} error={!!error} variant="outlined" autoComplete="off" />
                                        )}
                                    />
                                </FormField>
                                <FormField title={Translations.WhistleBlowInformantDateAndTime} required error={errors?.date?.message || errors?.time?.message}>
                                    <Box display="flex">
                                        <Controller
                                            name="date"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: Translations.DateMustBeDefined }}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <DatePicker onChange={onChange} value={value} slotProps={{ textField: { error: !!error, size: "small" } }} />
                                            )}
                                        />
                                        <Box marginLeft="1rem">
                                            <Controller
                                                name="time"
                                                control={control}
                                                defaultValue={null}
                                                rules={{ required: Translations.TimeMustBeDefined }}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <TimePicker onChange={onChange} value={value} slotProps={{ textField: { error: !!error, size: "small" } }} />
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                </FormField>
                                <FormField title={Translations.WhistleBlowInformantDescription} required error={errors?.description?.message}>
                                    <Controller
                                        name="description"
                                        control={control}
                                        rules={{ required: Translations.DescriptionMustBeDefined }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField size="small" onChange={onChange} value={value} title={Translations.WhistleBlowInformantDescription} multiline rows={5} autoComplete="off" error={!!error} variant="outlined" />
                                        )}
                                    />
                                </FormField>
                            </>
                        }
                        <WhistleBlowAttachmentList
                            companyId={frontPage.companyId}
                            whistleBlowId=""
                            accept="image/*,.pdf"
                            attachments={state.attachments}
                            maxAttachmentsTotalSize={frontPage.maxAttachmentsTotalSize}
                            onShowCamera={value => setState((old) => ({ ...old, cameraVisible: value }))}
                            onRemoved={handleAttachmentsRemoved}
                            onAdded={handleAttachmentsAdded}
                        />
                        {!state.cameraVisible &&
                            <FormField title={Translations.ChooseHowYouWantToReport} required>
                                <RadioGroup value={state.anonymous} onChange={() => setState((old) => ({ ...old, anonymous: !old.anonymous }))}>
                                    <FormControlLabel label={Translations.WhistleBlowAnonymousReport} value={true} control={
                                        <Radio />
                                    } />
                                    <Styled.RadioHelperText variant="body2">{Translations.WhistleBlowAnonymousReportInfo}</Styled.RadioHelperText>
                                    <FormControlLabel label={Translations.WhistleBlowNonAnonymousReport} value={false} control={
                                        <Radio />
                                    } />
                                    <Styled.RadioHelperText variant="body2">{Translations.WhistleBlowNonAnonymousReportInfo}</Styled.RadioHelperText>
                                </RadioGroup>
                            </FormField>
                        }
                        {!state.cameraVisible && !state.anonymous &&
                            <>
                                <FormField title={Translations.Name}>
                                    <Controller
                                        name="informantName"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField variant="outlined" type="text" value={value} autoComplete="off" onChange={onChange} size="small" fullWidth />
                                        )}
                                    />
                                </FormField>
                                <FormField title={Translations.EmailAddress}>
                                    <Controller
                                        name="informantEmail"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField variant="outlined" type="text" value={value} autoComplete="off" onChange={onChange} size="small" fullWidth />
                                        )}
                                    />
                                </FormField>
                                <FormField title={Translations.Phone}>
                                    <Controller
                                        name="informantPhone"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField variant="outlined" type="text" value={value} autoComplete="off" onChange={onChange} size="small" fullWidth />
                                        )}
                                    />
                                </FormField>
                            </>
                        }
                    </div>
                    }
                    {state.showInformantPassword &&
                    <div>
                        <Typography variant="h5">{Translations.WhistleBlowReportHasBeenSaved}</Typography>
                        <Typography variant="body2" marginTop={".5rem"}>{Translations.WhistleBlowReportHasBeenSavedTopText}</Typography>
                        <FormField title={Translations.Password}>
                            <FormGroup row>
                                <TextField type="text" title={Translations.Password} disabled={true} value={state.informantPassword} ref={passwordInput} fullWidth size="small" />
                                <Styled.CopyButton
                                    variant="contained"
                                    onClick={handleCopyInformantPasswordToClipboard}
                                >{Translations.Copy}</Styled.CopyButton>
                            </FormGroup>
                        </FormField>
                        <Typography variant="body2" margin={".5rem 0 1rem 0"}>{Translations.WhistleBlowReportHasBeenSavedBottomText}</Typography>
                        <FormControl>
                            <FormControlLabel componentsProps={{ typography: { variant: "body2" } }} label={Translations.WhistleBlowReportIHaveSavedThePassword} control={
                                <Checkbox checked={state.informantPasswordHasBeenStored} onChange={() => setState((old) => ({ ...old, informantPasswordHasBeenStored: !old.informantPasswordHasBeenStored }))} />
                            } />
                        </FormControl>
                    </div>
                    }
                    <Box display="flex" flexDirection="row-reverse" marginTop="2.5rem">
                        {!state.showInformantPassword &&
                        <Box marginLeft="1.5rem">
                            <Button
                                variant="contained"
                                startIcon={<CheckCircleOutlinedIcon />}
                                type="submit"
                            >{Translations.WhistleBlowSendReport}</Button>
                        </Box>
                        }
                        {!state.showInformantPassword &&
                        <Button
                            variant="outlined"
                            title={Translations.Cancel}
                            onClick={handleCancelWhistleBlowReport}
                        >{Translations.Cancel}</Button>
                        }
                        {state.showInformantPassword &&
                        <Button
                            disabled={!state.informantPasswordHasBeenStored}
                            variant="contained"
                            title={Translations.Close}
                            onClick={handleWhistleBlowConfirmPassword}
                        >{Translations.Close}</Button>
                        }
                    </Box>
                </Styled.Container>
            </form>
        </LocalizationProvider>
    );
};