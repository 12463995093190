import { Base } from "../../framework/base";
import { FileAttachment, IFileAttachment } from "../common/fileAttachment";
import { EnumHelper, WhistleBlowResolution, WhistleBlowState } from "../enums";
import { IWhistleBlowInformantMessage, WhistleBlowInformantMessage } from "./whistleBlowInformantMessage";

export interface IWhistleBlowInformantEditItem {
    id: string;
    createDate: number;
    date: number;
    title: string;
    description: string;
    anonymous: boolean;
    informantName: string;
    informantEmail: string;
    informantPhone: string;
    state: WhistleBlowState;
    resolution: WhistleBlowResolution;
    resolutionComment: string;
    messages: IWhistleBlowInformantMessage[];
    attachments: IFileAttachment[];

    stateStr: string;
    resolutionStr: string;
}

export class WhistleBlowInformantEditItem implements IWhistleBlowInformantEditItem {
    id: string;
    createDate: number;
    date: number;
    title: string;
    description: string;
    anonymous: boolean;
    informantName: string;
    informantEmail: string;
    informantPhone: string;
    state: WhistleBlowState;
    resolution: WhistleBlowResolution;
    resolutionComment: string;
    messages: WhistleBlowInformantMessage[];
    attachments: FileAttachment[];

    stateStr: string;
    resolutionStr: string;

    constructor();
    constructor(obj: IWhistleBlowInformantEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.createDate = obj && obj.createDate || 0;
        this.date = obj && obj.date || 0;
        this.title = obj && obj.title || "";
        this.description = obj && obj.description || "";
        this.anonymous = obj && obj.anonymous || false;
        this.informantName = obj && obj.informantName || "";
        this.informantEmail = obj && obj.informantEmail || "";
        this.informantPhone = obj && obj.informantPhone || "";
        this.state = obj && obj.state || WhistleBlowState.New;
        this.resolution = obj && obj.resolution || WhistleBlowResolution.Unspecified;
        this.resolutionComment = obj && obj.resolutionComment || "";
        this.messages = Base.getTypedArray(obj ? obj.messages : null, WhistleBlowInformantMessage);
        this.attachments = Base.getTypedArray(obj ? obj.attachments : null, FileAttachment);
        this.stateStr = EnumHelper.WhistleBlowStateToStr(this.state);
        this.resolutionStr = EnumHelper.WhistleBlowResolutionToStr(this.resolution);
        WhistleBlowInformantMessage.sortWhistleBlowInformantMessages(this.messages);
        FileAttachment.sortFileAttachments(this.attachments);
    }
}