import * as React from "react";

export const FlagSv = ({
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g id="surface1">
            <path fill="#006AA7" d="M 10.320312 22.628906 C 10.90625 22.726562 11.503906 22.777344 12.117188 22.777344 C 17.320312 22.777344 21.65625 19.082031 22.648438 14.171875 L 10.320312 14.171875 Z M 10.320312 22.628906 "/>
            <path fill="#006AA7" d="M 1.585938 14.171875 C 2.140625 16.929688 3.753906 19.304688 5.980469 20.855469 L 5.980469 14.171875 Z M 1.585938 14.171875 "/>
            <path fill="#006AA7" d="M 5.980469 3.210938 C 3.753906 4.761719 2.140625 7.136719 1.585938 9.894531 L 5.980469 9.894531 Z M 5.980469 3.210938 "/>
            <path fill="#006AA7" d="M 22.648438 9.894531 C 21.65625 4.984375 17.320312 1.285156 12.117188 1.285156 C 11.503906 1.285156 10.90625 1.339844 10.320312 1.4375 L 10.320312 9.894531 Z M 22.648438 9.894531 "/>
            <path fill="#FECC00" d="M 5.980469 9.894531 L 1.585938 9.894531 C 1.441406 10.585938 1.371094 11.300781 1.371094 12.03125 C 1.371094 12.765625 1.441406 13.480469 1.585938 14.171875 L 5.980469 14.171875 Z M 5.980469 9.894531 "/>
            <path fill="#FECC00" d="M 22.648438 9.894531 L 10.320312 9.894531 L 10.320312 14.171875 L 22.648438 14.171875 C 22.789062 13.480469 22.863281 12.765625 22.863281 12.03125 C 22.863281 11.300781 22.789062 10.585938 22.648438 9.894531 Z M 22.648438 9.894531 "/>
            <path fill="#FECC00" d="M 10.320312 9.894531 L 10.320312 1.4375 C 8.726562 1.703125 7.253906 2.320312 5.980469 3.210938 L 5.980469 9.894531 Z M 10.320312 9.894531 "/>
            <path fill="#FECC00" d="M 5.980469 14.171875 L 5.980469 20.855469 C 7.253906 21.742188 8.726562 22.359375 10.320312 22.628906 L 10.320312 14.171875 Z M 5.980469 14.171875 "/>
            <path fill="#FECC00" d="M 5.980469 9.894531 L 10.320312 9.894531 L 10.320312 14.171875 L 5.980469 14.171875 Z M 5.980469 9.894531 "/>
        </g>
    </svg>
);
