﻿(function (window) {
    //Polyfill
    if (!Date.now) {
        Date.now = function () {
            return new Date().getTime();
        };
    }

    $date_type = Date;
    $date_type.__typeName = "Number";
    $date_type.__class = true;
    $date_prototype = $date_type.prototype;
    
    $date_prototype.addDays = function (days) {
        if (days === null || days === undefined) {
            days = 1;
        }
        var result = new Date(this.valueOf());
        result.setDate(result.getDate() + days);
        return result;
    };

    $date_prototype.addHours = function (hours) {
        if (hours === null || hours === undefined) {
            hours = 1;
        }
        var result = new Date(this.valueOf());
        result.setHours(result.getHours() + hours);
        return result;
    };

    $date_prototype.addMinutes = function (minutes) {
        if (minutes === null || minutes === undefined) {
            minutes = 1;
        }
        var result = new Date(this.valueOf());
        result.setMinutes(result.getMinutes() + minutes);
        return result;
    };

    $date_prototype.firstDayOfWeek = function () {
        var result = new Date(this.valueOf());
        var dayOfWeek = this.getDay();
        result.setDate(result.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
        result.setHours(0, 0, 0, 0);
        return result;
    };
})(typeof window !== "undefined" ? window : this);
