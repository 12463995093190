import styled from "@emotion/styled";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Button, Menu as MuiMenu, SvgIcon } from "@mui/material";

export const ToggleButton = styled(Button)`
    border: 1px solid;
    text-transform: none;
    font-size: 1rem;
    padding: .5rem .625rem;
    line-height: 1;
    width: 176px;
    background: #fff;
`;

export const Menu = styled(MuiMenu)`
    padding: .5rem 0;
`;

export const FlagIcon = styled(SvgIcon)`
    margin-right: .5rem;
    width: 20px;
    height: 20px;
`;

export const ArrowIcon = styled(ExpandMoreOutlinedIcon)<{ open: boolean }>`
    transform: rotate(${({ open }) => open ? "180deg" : "0"});
    transition: transform 0.2s;
`;