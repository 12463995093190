import styled from "@emotion/styled";
import { Button as MuiButton, Link, Paper } from "@mui/material";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem;
    flex-grow: 2;
`;

export const ContentContainer = styled.div`
    width: 100%;

    @media screen and (min-width: 900px) {
        width: calc(100% - 218px);
        padding-right: 2.5rem;
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 1rem;

    @media screen and (min-width: 900px) {
        width: 218px;
        margin-top: 4.25rem;
    }
`;

export const Button = styled(MuiButton)`
    margin-bottom: 1.5rem;
    margin-right: 1rem;

    @media screen and (min-width: 900px) {
        width: 100%;
        margin-right: 0;
    }
`;

export const ButtonOutlined = styled(Button)`
    background: #fff;
`;

export const FrontPageLink = styled(Link)`
    padding: 1rem 0 2rem 0;

    @media screen and (min-width: 900px) {
        width: 100%;
        margin-right: 0;
    }
`;

export const Scrollable = styled(Paper)`
    width: 100%;
    margin-top: 1rem; 
    padding: 2rem 2.5rem;

    @media screen and (min-width: 900px) {
        max-height: calc(100vh - 268px);
        overflow-y: auto;
    }
`;

export const TabContent = styled.div`
    strong {
        font-size: 24px;
        font-family: 'Inter', sans-serif !important;
        font-weight: 600;

        span {
            font-size: 20px !important;
            font-weight: 600;
        }
    }
`;
